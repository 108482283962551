import React from 'react';
import moment from "moment";
import {Link, Outlet} from "react-router-dom";
import BookmakerListContainer from "../../containers/BookmakerListContainer";

const Bookmaker = () => {
    return (
        <div>
            <div style={{
                paddingLeft: "10px",
                paddingTop: "10px",
            }}>
                <p>
                    북마커확인
                </p>
            </div>
            <BookmakerListContainer />


        </div>

    )
}

export default Bookmaker;