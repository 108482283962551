import {call, put, take,} from "redux-saga/effects";
import {fetchDepositComplete} from "../../../lib/api";
import {failedGetDepositComplete,setDepositComplete} from "../../reducers/money/depositComplete";

export function* getDepositCompleteSaga(action) {
    try{
        const res = yield call(
            fetchDepositComplete,
            action.payload.page, action.payload.pageSize, action.payload.query,
            action.payload.order, action.payload.startDate, action.payload.endDate
        )
        yield put(setDepositComplete(res.data))
    }
    catch (error) {
        console.log(error)
        yield put(failedGetDepositComplete(error))
    }
}