import {useDispatch, useSelector} from "react-redux"
import React, {useEffect, useState} from "react"

import Pagination from "../../components/common/Pagination";
import {getMemberUser} from "../../redux/reducers/user/member";
import MemberList from "../../components/user/Member";
import * as XLSX from "xlsx";
import typeCase from "../../util/typeCase";
import moment from "moment/moment";

const MemberListContainer = () => {
    // useDispatch: 컴포넌트 내부에서 스토어의 내장 함수 dispatch를 사용할 수 있게 해주는 hook 이다.
    // useSelector: connect 함수를 대신하여 스토어 상태를 조회
    const dispatch = useDispatch()
    const initialState = useSelector((state) => state.member)
    const [searchQuery, setSearchQuery] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    // 브라우저 상에 컴포넌트가 나타날 때 상품 목록을 조회하는 함수를 실행
    useEffect(() => {
        dispatch(getMemberUser({
            page: initialState.pages? initialState.pages : 1,
            pageSize: initialState.pageSizes? initialState.pageSizes : 5,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
        }))
    }, [dispatch])

    const handlePageChange = (page) => {
        dispatch(getMemberUser({
            page: page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
        }))
    }

    const changeQuery = (value) => {
        setSearchQuery(value)
    }

    const inputPress = (e) => {
        if(e.key === "Enter") {
            onSearch(e.target.value)
        }
    }
    const onSearch = (query) => {
        setSearchQuery(query)
        dispatch(getMemberUser({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: query,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
        }))
    }
    const onClickSearch = (e) => {

        dispatch(getMemberUser({
            page: initialState.pages? initialState.pages : 1,
            pageSize: initialState.pageSizes? initialState.pageSizes : 5,
            //page: 2,
            //pageSize: 3,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
        }))
    }

    const onRefresh = () => {
        dispatch(getMemberUser({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
        }))
    }


    const exportExcel = ()=>{

        //alert('initialState.totalList <>>>> '+initialState.totalList);

        let excelList = [];
        // JSON 형태의 값들을 for 문을 통해 배열에 추가합니다.
        for (let i = 0; i < initialState.totalList.length; i++) {


            let jsonObject = { // JSON 객체를 생성합니다.
                파트너:typeCase.userType(initialState.totalList[i].user_type),
                추천:'-',
                상태:typeCase.userState(initialState.totalList[i].user_state),
                등급:typeCase.userState(initialState.totalList[i].level),
                회원ID:initialState.totalList[i].id? initialState.totalList[i].id :'',
                닉네임:initialState.totalList[i].nickname? initialState.totalList[i].nickname+"("+initialState.totalList[i].comment+")" : '',
                예금주:initialState.totalList[i].name? initialState.totalList[i].name :'',
                보유머니:initialState.totalList[i].money? initialState.totalList[i].money :'0',
                포인트:initialState.totalList[i].point? initialState.totalList[i].point :'0',
                충전:initialState.totalList[i].deposit_total? initialState.totalList[i].deposit_total :'0',
                환전:initialState.totalList[i].withdraw_total? initialState.totalList[i].withdraw_total :'0',
                충전차액:initialState.totalList[i].total_amount? initialState.totalList[i].total_amount :'0',
                이달수익:initialState.totalList[i].month_amount? initialState.totalList[i].month_amount :'0',
                접속수:initialState.totalList[i].login_count? initialState.totalList[i].login_count :'0',
                최근충전:initialState.totalList[i].recently_deposit? moment(initialState.totalList[i].recently_deposit).format("YYYY-MM-DD HH:mm:ss") :'-',
                최근접속일:initialState.totalList[i].login_createdAt? moment(initialState.totalList[i].login_createdAt).format("YYYY-MM-DD HH:mm:ss") :'-',
                최근도메인:initialState.totalList[i].login_domain? initialState.totalList[i].login_domain :'',
                가입일:initialState.totalList[i].createdAt? moment(initialState.totalList[i].createdAt).format("YYYY-MM-DD HH:mm:ss") :'-',
                관리자:'관리자',

            }
            excelList.push(jsonObject); // 배열에 JSON 객체를 추가합니다.
        }
        console.log(excelList);
        const workSheet = XLSX.utils.json_to_sheet(excelList);
        const workBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, workSheet, '회원관리');
        XLSX.writeFile(workBook, '회원관리.xlsx');
    }




    const startIndex = ((initialState.page - 1) * initialState.pageSize) + 1;
    const currentMaxCount = initialState.totalCount < (startIndex + initialState.pageSize - 1)? initialState.totalCount : (startIndex + initialState.pageSize - 1)



    return <>
        <p className="h5 ps-2">{ startIndex + "~" + currentMaxCount + " / " + initialState.totalCount}</p>
        <div className="container-fluid p-0 mx-1" style={{
            display: "flex"
        }}>

        </div>
        <div className="container-fluid m-1 p-0" style={{
            display: "flex"
        }}>
            <select
                className="form-select me-1"
                defaultValue={100}
                style={{
                    width: "100px"
                }}
                value={initialState.pageSize === "999"? "모두" : initialState.pageSize}
                // onChange={(e) => {
                //     dispatch(getNotice{
                //         page: initialState.page,
                //         pageSize: parseInt(e.target.value),
                //         order: initialState.order,
                //         query: searchQuery,
                //         startDate: startDate? startDate : initialState.startDate,
                //         endDate: endDate? endDate : initialState.endDate,
                //     }))
                // }}
            >
                <option value={50}>50</option>
                <option value={100}>100</option>
                <option value={200}>200</option>
                <option value={999}>모두</option>
            </select>
            <select
                className="form-select me-1"
                defaultValue={0}
                style={{
                    width: "200px"
                }}
                value={initialState.order === 0? 0 : initialState.order}
                onChange={e =>
                    dispatch(getMemberUser({
                        page: initialState.page,
                        pageSize: initialState.pageSize,
                        order: parseInt(e.target.value),
                        query: searchQuery,
                        startDate: startDate? startDate : initialState.startDate,
                        endDate: endDate? endDate : initialState.endDate,
                    }))
                }
            >
                <option value={0}>최근접속순</option>
                <option value={1}>최근충전순</option>
            </select>
            <div className="input-group me-1" style={{
                display: "flex",
                width: "300px"
            }}>
                <input style={{
                    width: "220px"
                }} type="text" className="form-control" id="search_edit" placeholder="회원ID,닉네임을 입력하세요." onBlur={(e) => {
                    changeQuery(e.target.value)
                }} onKeyDown={inputPress} />
                <button style={{
                    width: "80px"
                }} type="submit" className="btn btn-primary" onClick={onClickSearch}>검색</button>
            </div>
            <button style={{
                width: "80px"
            }} type="submit" className="btn btn-primary" onClick={exportExcel}>엑셀</button>

        </div>
        <MemberList member={initialState.member} onRefresh={onRefresh} />
        <Pagination totalCount={initialState.totalCount} pageSize={initialState.pageSize} currentPage={initialState.page} onPageChange={handlePageChange}/>
    </>





}
export default MemberListContainer