import {call, put, take,} from "redux-saga/effects";
import {fetchStats} from "../../lib/api";
import {failedGetStats, setStats} from "../reducers/stat";

export function* getStatsSaga(action) {
    try{
        const res = yield call(fetchStats,
            action.payload.userState, action.payload.startDate, action.payload.endDate
        );
        yield put(setStats(res.data))
    }
    catch (error) {
        console.log(error)
        yield put(failedGetStats(error))
    }
}