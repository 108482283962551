import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import typeCase from "../../util/typeCase";
import {getPageAphAthrReducer,setMenuList,setSelectedRow,getPageAphAthrMenuListSettingReducer} from "../../redux/reducers/setting/PageAphAthrReducer";
import Pagination from "../../components/common/Pagination";
import axios from "axios";

const PageAphAthrComponent = ({initialState}) => {
    const dispatch = useDispatch()
    const initialState2 = useSelector((state) => state.PageAphAthrReducer)

    const menuCheckHandler = (checked,index,subMenu) => {
        // menuList를 복사하여 새로운 배열을 생성
        console.log("##subMenu##",index,subMenu);
        const updatedMenuList = [...initialState.menuList];
        let newlclMenuId2Value = subMenu.lcl_menu_id1 !== null ? subMenu.lcl_menu_id1 : '';
        let newMclMenuId2Value = subMenu.mcl_menu_id1 !== null ? subMenu.mcl_menu_id1 : '';

        const rowIndex = updatedMenuList.findIndex((item) => item.mcl_menu_id1 === newMclMenuId2Value);

        if(subMenu.mcl_menu_id2 !==null && subMenu.mcl_menu_id2 !==''){
            newlclMenuId2Value = '';
            newMclMenuId2Value = '';
        }

        console.log("##rowIndex##",rowIndex);
        console.log("##newMclMenuId2Value##",newMclMenuId2Value);

        // 새로운 배열을 생성하고 변경 내용을 적용
        const updatedMenuListCopy = updatedMenuList.map((item, itemIndex) => {
            if (itemIndex === rowIndex) {
                // rowIndex에 해당하는 항목을 변경
                return {
                    ...item, // 기존 항목의 내용 복사
                    lcl_menu_id2: newlclMenuId2Value, // mcl_menu_id2 업데이트
                    mcl_menu_id2: newMclMenuId2Value, // mcl_menu_id2 업데이트
                };
            }
            return item; // 변경하지 않을 항목은 그대로 반환
        });

        // dispatch를 사용하여 액션을 보내고 Redux 스토어에 새로운 menuList를 저장
        dispatch(setMenuList(updatedMenuListCopy));
    }

    const handlePageChange = (page) => {
        dispatch(getPageAphAthrReducer({
            page: page,
            pageSize: initialState.pageSize,
        }))
    }

    const handleRowClick = (index,adminNo) => {
        dispatch(setSelectedRow(index))
        if(initialState.menuList !== null) {
            dispatch(getPageAphAthrMenuListSettingReducer({
                selectedAdminNo : adminNo
            }))
        }
    }

    const handleRegister = () => {
        console.log("initialState.selectedAdminNo",initialState.selectedAdminNo);
        const inputMenuList = initialState.menuList
        console.log("inputMenuList",inputMenuList);

        axios.post('/admin/insertMenuAthrSetting', {
            adminNo : initialState.selectedAdminNo,
            inputMenuList: inputMenuList,
        }).then((res) => {
            if(res.data.isInserted) {
                alert('등록완료')
            }
            else {
                alert('등록실패')
            }
        })
    }

    return (
        <div className="m-2">
            <div className="card-body">
                <div>
                    <div className="container-fluid">
                        <div className="d-flex">
                            <div className="w-25">
                                <div className="m-2 d-flex">
                                    <span>운영자목록</span>
                                    <span className="ms-3">관리자수 : {initialState.totalCount}</span>
                                </div>
                                <div>
                                    <table className="table table-bordered table-sm text-center align-middle">
                                        <thead className="table-dark">
                                        <tr>
                                            <th>아이디</th>
                                            <th>사용권한</th>
                                        </tr>
                                        </thead>
                                        {initialState.adminList && initialState.adminList.map((admin, index) => (
                                            <tbody key={admin.no} className="table-bordered border">
                                            <tr onClick={() => handleRowClick(index,admin.no)} style={{ background: initialState.selectedRow === index ? 'lightblue' : 'white' }}>
                                                <td>{admin.id}</td>
                                                <td>{typeCase.adminState(admin.level)}</td>
                                            </tr>
                                            </tbody>
                                        ))}
                                    </table>
                                </div>
                                <Pagination totalCount={initialState.totalCount} pageSize={initialState.pageSize} currentPage={initialState.page} onPageChange={handlePageChange}/>
                            </div>
                            <div className="w-75 ms-2">
                                <div className="m-2 d-flex">
                                    <span>페이지목록</span>
                                </div>
                                <div>
                                    <table className="table table-bordered table-sm text-center align-middle settingPage">
                                        <thead className="table-dark">
                                        <tr>
                                            <th>메뉴</th>
                                            <th>
                                                서브 메뉴
                                            </th>
                                        </tr>
                                        </thead>
                                        {initialState2.lclmenuList&&initialState2.lclmenuList.map((lclMenu, index) => (
                                            <tbody key={lclMenu.lcl_menu_id}>
                                            <tr>
                                                <td>{lclMenu.lcl_menu_nm}</td>

                                                <td className="d-flex">
                                                    {initialState2.menuList&&initialState2.menuList.filter((item) => item.lcl_menu_id1 === lclMenu.lcl_menu_id).map((subMenu, index) => (
                                                        <div key={index} className="form-check me-3">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                value={subMenu.mcl_menu_id2 || ''}
                                                                checked = {subMenu.mcl_menu_id2 !== null && subMenu.mcl_menu_id2 !==''}
                                                                onChange={(e) => menuCheckHandler(e.currentTarget.checked,index,subMenu)}
                                                            />
                                                            <label className="form-check-label"> {subMenu.mcl_menu_nm1}</label>
                                                        </div>
                                                    ))}
                                                </td>

                                            </tr>
                                            </tbody>
                                        ))}
                                    </table>
                                </div>
                                <div className="d-flex justify-content-end">
                                    <button className="btn btn-primary" onClick={handleRegister}>저장</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default PageAphAthrComponent;