import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import axios from "axios";
import imageCompression from "browser-image-compression";
import commonInfo from "../../config/commonInfo";
import moment from "moment/moment";
import {useDispatch, useSelector} from "react-redux";
import Switch from 'react-switch';
import {setNationSwitchStatesReducer} from "../../redux/reducers/match/basicSettingPage";


const BasicSettingPage = ({basicSettingPage, basicSettingPage2, basicSettingPage3, onRefresh, initialState}) => {


    const [isEnabled, setIsEnabled] = useState(false);
    const [updates, setUpdates] = useState([]);
    const [checkMsg, setCheckMsg] = useState([]);

    const onCheckMsgHandler = (e) =>{
        setCheckMsg(e.currentTarget.value);
    }


    const dispatch = useDispatch()

    const handleToggle = (code, no) => {

        //alert("code :: "+code);
        //alert("no :: "+no-1);

        dispatch(setNationSwitchStatesReducer(code));
        //alert("no>>>>> "+initialState.nationSwitchStates[code]) ;
        //alert("sss >> "+initialState.a1);


    };

    function sportsType(type) {
        switch (type) {
            //case 0 : return "레이어"
            case 1 : return "축구"
            case 2 : return "농구"
            case 3 : return "야구"
            case 4 : return "배구"
            case 5 : return "아이스 하키"
            case 6 : return "미식축구"
            case 7 : return "e스포츠"
            default: break;
        }
    }

    function markType(type) {
        switch (type) {
            case 1 : return "국내형"
            case 2 : return "해외형"
            case 3 : return "인플레이"
            default: break;
        }
    }



    const basicSettingPageUpdate = (toggleNo, no) => {

        let sportsCd = "";
        let useYn = "";
        let checkYn = "";
        let checkMsg = "";
        let sort = "";

        sportsCd = toggleNo;
        useYn = (initialState.nationSwitchStates["a"+toggleNo] ? "Y":"N");
        checkYn = (initialState.nationSwitchStates["b"+toggleNo] ? "Y":"N");
        checkMsg = document.getElementById("check_msg"+no).value;
        sort = document.getElementById("sort"+no).value;

        if (window.confirm("변경 하시겠습니까?"))
        {
            axios.post('/admin/basicSettingPage/update', {
                    sports_cd: sportsCd,
                    use_yn: useYn,
                    check_yn: checkYn,
                    check_msg: checkMsg,
                    sort: sort

                }
            ).then((res) => {
                alert(res.data.success? "변경완료" : "변경실패")
                onRefresh();
            })
        }

    }


    const basicSettingPageUpdate2 = (toggleNo, no) => {

        let markCd = "";
        let useYn = "";
        let checkYn = "";
        let gameExpose = "";
        let dividendYn = "";
        let checkMsg = "";
        let exposedCnt = "";
        let exposedTime = "";

        markCd = toggleNo;
        useYn = (initialState.nationSwitchStates["c"+toggleNo] ? "Y":"N");
        checkYn = (initialState.nationSwitchStates["d"+toggleNo] ? "Y":"N");
        gameExpose = (initialState.nationSwitchStates["e"+toggleNo] ? "Y":"N");
        dividendYn = (initialState.nationSwitchStates["f"+toggleNo] ? "Y":"N");
        checkMsg = document.getElementById("check_msg2"+no).value;
        exposedCnt = document.getElementById("exposed_cnt"+no).value;
        exposedTime = document.getElementById("exposed_time"+no).value;

        if (window.confirm("변경 하시겠습니까?"))
        {

            axios.post('/admin/basicSettingPage/update2', {
                    mark_cd: markCd,
                    use_yn: useYn,
                    check_yn: checkYn,
                    game_expose: gameExpose,
                    dividend_yn: dividendYn,
                    check_msg: checkMsg,
                    exposed_cnt: exposedCnt,
                    exposed_time: exposedTime,
                }
            ).then((res) => {
                alert(res.data.success? "변경완료" : "변경실패")
                onRefresh();
            })
        }

    }


    const basicSettingPageUpdate3 = (toggleNo, no) => {


        let sportsCd = "";
        let mininumDividend = "";
        let handicapDividend = "";
        let pointLimit = "";
        let mininumDividend2 = "";
        let underoverDividend = "";
        let pointLimit2 = "";
        let inflationMininumDividend = "";
        let inflationHandicapDividend = "";
        let inflationPointLimit = "";
        let inflationMininumDividend2 = "";
        let inflationUnderoverDividend = "";
        let inflationPointLimit2 = "";
        let inflationTime = "";


        sportsCd = toggleNo;
        mininumDividend = document.getElementById("mininum_dividend"+no).value;
        handicapDividend = document.getElementById("handicap_dividend"+no).value;
        pointLimit = document.getElementById("point_limit"+no).value;
        mininumDividend2 = document.getElementById("mininum_dividend_2"+no).value;
        underoverDividend = document.getElementById("underover_dividend"+no).value;
        pointLimit2 = document.getElementById("point_limit_2"+no).value;
        inflationMininumDividend = document.getElementById("inflation_mininum_dividend"+no).value;
        inflationHandicapDividend = document.getElementById("inflation_handicap_dividend"+no).value;
        inflationPointLimit = document.getElementById("inflation_point_limit"+no).value;
        inflationMininumDividend2 = document.getElementById("inflation_mininum_dividend_2"+no).value;
        inflationUnderoverDividend = document.getElementById("inflation_underover_dividend"+no).value;
        inflationPointLimit2 = document.getElementById("inflation_point_limit_2"+no).value;
        inflationTime = document.getElementById("inflation_time"+no).value;


        if (window.confirm("변경 하시겠습니까?"))
        {

            axios.post('/admin/basicSettingPage/update3', {
                    sports_cd : sportsCd,
                    mininum_dividend : mininumDividend,
                    handicap_dividend : handicapDividend,
                    point_limit : pointLimit,
                    mininum_dividend_2 : mininumDividend2,
                    underover_dividend : underoverDividend,
                    point_limit_2 : pointLimit2,
                    inflation_mininum_dividend : inflationMininumDividend,
                    inflation_handicap_dividend : inflationHandicapDividend,
                    inflation_point_limit : inflationPointLimit,
                    inflation_mininum_dividend_2 : inflationMininumDividend2,
                    inflation_underover_dividend : inflationUnderoverDividend,
                    inflation_point_limit_2 : inflationPointLimit2,
                    inflation_time : inflationTime
                }
            ).then((res) => {
                alert(res.data.success? "변경완료" : "변경실패")
                onRefresh();
            })
        }

    }



    return (
        <div>
            <div style={{
                paddingLeft: "1px"
            }}>

                <div className="card" style={{
                    margin: "1px"
                }}>

                    <div className="card-body">
                        <div>
                            <div>
                                <table className="table table-bordered table-sm text-center align-middle">
                                    <thead className="table-dark">
                                    <tr>
                                        <td style={{width: "10%"}}>종목</td>
                                        <td style={{width: "10%"}}>사용</td>
                                        <td style={{width: "10%"}}>점검</td>
                                        <td style={{width: "60%"}}>점검 메시지</td>
                                        <td style={{width: "5%"}}>정렬</td>
                                        <td style={{width: "5%"}}></td>
                                    </tr>
                                    </thead>
                                    <tbody>

                                    {basicSettingPage.map((basicSettingPage, index) => (
                                        <tr id={"collapse2" + index}>
                                            <td>{sportsType(basicSettingPage.sports_cd)}</td>
                                            <td align={"center"}>
                                                <Switch
                                                    id={"a"+basicSettingPage.sports_cd}
                                                    onChange={() => handleToggle("a"+basicSettingPage.sports_cd, basicSettingPage.sports_cd)  }
                                                    checked={initialState.nationSwitchStates["a"+basicSettingPage.sports_cd]}
                                                    onColor="#2196F3"
                                                />
                                            </td>
                                            <td align={"center"}>
                                                <Switch
                                                    id={"b"+basicSettingPage.sports_cd}
                                                    onChange={() => {
                                                        handleToggle("b"+basicSettingPage.sports_cd, basicSettingPage.sports_cd)
                                                        }
                                                    }
                                                    checked={initialState.nationSwitchStates["b"+basicSettingPage.sports_cd]}
                                                    onColor="#2196F3"
                                                />
                                            </td>
                                            <td>
                                                <input style={{
                                                    width: "100%"
                                                }} type="text" className="form-control" id={"check_msg" + index} placeholder="현재 점검중입니다. 잠시후에 다시 이용해주세요." defaultValue={basicSettingPage.check_msg}  onChange={onCheckMsgHandler} />
                                            </td>
                                            <td align={"center"}>
                                                <select
                                                    className="form-select me-1"
                                                    defaultValue={basicSettingPage.sort}
                                                    id= {"sort" + index}
                                                    style={{
                                                        width: "100px"
                                                    }}
                                                >
                                                    <option value={1}>1번째</option>
                                                    <option value={2}>2번째</option>
                                                    <option value={3}>3번째</option>
                                                    <option value={4}>4번째</option>
                                                    <option value={5}>5번째</option>
                                                    <option value={6}>6번째</option>
                                                    <option value={7}>7번째</option>
                                                    <option value={8}>8번째</option>
                                                    <option value={9}>9번째</option>
                                                </select>
                                            </td>
                                            <td style={{width: "5%"}} align={"center"}>
                                                <button className="btn btn-outline-secondary" type="button"
                                                        id="button-addon2"
                                                        onClick={()=> {
                                                            basicSettingPageUpdate(basicSettingPage.sports_cd, basicSettingPage.sports_cd-1)
                                                        }}
                                                >변경
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>

                                </table>
                            </div>

                            <div>
                                <table className="table table-bordered table-sm text-center align-middle">
                                    <thead className="table-dark">
                                    <tr>
                                        <td style={{width: "10%"}}>표기</td>
                                        <td style={{width: "5%"}}>사용</td>
                                        <td style={{width: "5%"}}>점검</td>
                                        <td style={{width: "13%"}}>로그인/로그아웃 경기 노출</td>
                                        <td style={{width: "7%"}}>합배당 사용</td>
                                        <td style={{width: "*%"}} >점검 메시지</td>
                                        <td style={{width: "10%"}} >한페이지 경기 노출 수</td>
                                        <td style={{width: "10%"}} >시작전 경기 노출 시간</td>
                                        <td style={{width: "5%"}}></td>
                                    </tr>
                                    </thead>
                                    <tbody>


                                    {basicSettingPage2.map((basicSettingPage2, index) => (
                                        <tr>
                                            <td align={"center"}>{markType(basicSettingPage2.mark_cd)}</td>
                                            <td align={"center"}>
                                                <Switch
                                                    id={"c"+basicSettingPage2.mark_cd}
                                                    onChange={() => handleToggle("c"+basicSettingPage2.mark_cd, basicSettingPage2.mark_cd)  }
                                                    checked={initialState.nationSwitchStates["c"+basicSettingPage2.mark_cd]}
                                                    onColor="#2196F3"
                                                />
                                            </td>
                                            <td align={"center"}>
                                                <Switch
                                                    id={"d"+basicSettingPage2.mark_cd}
                                                    onChange={() => handleToggle("d"+basicSettingPage2.mark_cd, basicSettingPage2.mark_cd)  }
                                                    checked={initialState.nationSwitchStates["d"+basicSettingPage2.mark_cd]}
                                                    onColor="#2196F3"
                                                />
                                            </td>
                                            <td align={"center"}>
                                                <td align={"center"}>
                                                    <Switch
                                                        id={"e"+basicSettingPage2.mark_cd}
                                                        onChange={() => handleToggle("e"+basicSettingPage2.mark_cd, basicSettingPage2.mark_cd)  }
                                                        checked={initialState.nationSwitchStates["e"+basicSettingPage2.mark_cd]}
                                                        onColor="#2196F3"
                                                    />
                                                </td>
                                            </td>
                                            <td align={"center"}>
                                                <td align={"center"}>
                                                    {basicSettingPage2.mark_cd > 2 && (
                                                        <Switch
                                                            id={"f"+basicSettingPage2.mark_cd}
                                                            onChange={() => handleToggle("f"+basicSettingPage2.mark_cd, basicSettingPage2.mark_cd)  }
                                                            checked={initialState.nationSwitchStates["f"+basicSettingPage2.mark_cd]}
                                                            onColor="#2196F3"
                                                        />
                                                    )}
                                                </td>
                                            </td>
                                            <td>
                                                <input style={{
                                                    width: "100%"
                                                }} type="text" className="form-control" id={"check_msg2" + index} placeholder="현재 점검중입니다. 잠시후에 다시 이용해주세요." defaultValue={basicSettingPage2.check_msg}  onChange={onCheckMsgHandler} />
                                            </td>
                                            <td>
                                                <input style={{
                                                    width: "100%"
                                                }} type="text" className="form-control" id={"exposed_cnt" + index} placeholder="0" defaultValue={basicSettingPage2.exposed_cnt} />
                                            </td>
                                            <td>
                                                <input style={{
                                                    width: "100%"
                                                }} type="text" className="form-control" id={"exposed_time" + index} placeholder="0" defaultValue={basicSettingPage2.exposed_time} />
                                            </td>
                                            <td style={{width: "5%"}} align={"center"}>
                                                <button className="btn btn-outline-secondary" type="button"
                                                        id="button-addon2"
                                                        onClick={()=> {
                                                            basicSettingPageUpdate2(basicSettingPage2.mark_cd, basicSettingPage2.mark_cd-1)
                                                        }}
                                                >변경
                                                </button>
                                            </td>
                                        </tr>
                                    ))}

                                    </tbody>

                                </table>
                            </div>

                            <div>
                                <table className="table table-bordered table-sm text-center align-middle">
                                    <thead className="table-dark">
                                    <tr>
                                        <td style={{width: "46.5%"}} colSpan="7" >국내형 해외형</td>
                                        <td style={{width: "0.1%", backgroundColor:"white"}} rowspan="2"></td>
                                        <td style={{width: "46.5%"}} colSpan="7">인플레이</td>
                                        <td style={{width: "6%"}}></td>
                                    </tr>
                                    <tr>
                                        <td style={{width: "6%"}}>종목</td>
                                        <td style={{width: "6%"}}>최소 노출 배당</td>
                                        <td style={{width: "6%"}}>핸디캡 배당차이 제한</td>
                                        <td style={{width: "6%"}}>기준점 제한</td>
                                        <td style={{width: "6%"}}>최소 노출 배당</td>
                                        <td style={{width: "6%"}}>언더오버 배당차이 제한</td>
                                        <td style={{width: "6%"}}>기준점 제한</td>
                                        <td style={{width: "6%"}}>최소 노출 배당</td>
                                        <td style={{width: "6%"}}>핸디캡 배당차이 제한</td>
                                        <td style={{width: "6%"}}>기준점 제한</td>
                                        <td style={{width: "6%"}}>최소 노출 배당</td>
                                        <td style={{width: "6%"}}>언더오버 배당차이 제한</td>
                                        <td style={{width: "6%"}}>기준점 제한</td>
                                        <td style={{width: "6%"}}></td>
                                        <td style={{width: "6%"}}></td>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {basicSettingPage3.map((basicSettingPage3, index) => (
                                        <tr>
                                            <td>{sportsType(basicSettingPage3.sports_cd)}</td>
                                            <td>
                                                <input style={{
                                                    width: "50%"
                                                }} type="text" id={"mininum_dividend" + index} placeholder="0" defaultValue={basicSettingPage3.mininum_dividend} />
                                                <input type="button" value="배당"/>
                                            </td>
                                            <td>
                                                <input style={{
                                                    width: "50%"
                                                }} type="text" id={"handicap_dividend" + index} placeholder="0" defaultValue={basicSettingPage3.handicap_dividend} />
                                                <input type="button" value="배당"/>
                                            </td>
                                            <td>
                                                <select
                                                    id= {"point_limit" + index}
                                                    className="form-select me-1"
                                                    defaultValue={basicSettingPage3.point_limit}
                                                    style={{
                                                        width: "100px"
                                                    }}
                                                >
                                                    <option value={0}>0 만사용</option>
                                                    <option value={1}>.5 만사용</option>
                                                    <option value={2}>모두사용</option>
                                                </select>
                                            </td>
                                            <td>
                                                <input style={{
                                                    width: "50%"
                                                }} type="text" id={"mininum_dividend_2" + index} placeholder="0" defaultValue={basicSettingPage3.mininum_dividend_2} />
                                                <input type="button" value="배당"/>
                                            </td>
                                            <td>
                                                <input style={{
                                                    width: "50%"
                                                }} type="text" id={"underover_dividend" + index} placeholder="0" defaultValue={basicSettingPage3.underover_dividend} />
                                                <input type="button" value="배당"/>
                                            </td>
                                            <td>
                                                <select
                                                    id= {"point_limit_2" + index}
                                                    className="form-select me-1"
                                                    defaultValue={basicSettingPage3.point_limit_2}
                                                    style={{
                                                        width: "100px"
                                                    }}
                                                >
                                                    <option value={0}>0 만사용</option>
                                                    <option value={1}>.5 만사용</option>
                                                    <option value={2}>모두사용</option>
                                                </select>
                                            </td>
                                            <td style={{width: "0.1%", backgroundColor:"black"}}></td>
                                            <td>
                                                <input style={{
                                                    width: "50%"
                                                }} type="text" id={"inflation_mininum_dividend" + index} placeholder="0" defaultValue={basicSettingPage3.inflation_mininum_dividend} />
                                                <input type="button" value="배당"/>
                                            </td>
                                            <td>
                                                <input style={{
                                                    width: "50%"
                                                }} type="text" id={"inflation_handicap_dividend" + index} placeholder="0" defaultValue={basicSettingPage3.inflation_handicap_dividend} />
                                                <input type="button" value="배당"/>
                                            </td>
                                            <td>
                                                <select
                                                    id= {"inflation_point_limit" + index}
                                                    className="form-select me-1"
                                                    defaultValue={basicSettingPage3.inflation_point_limit}
                                                    style={{
                                                        width: "100px"
                                                    }}
                                                >
                                                    <option value={0}>0 만사용</option>
                                                    <option value={1}>.5 만사용</option>
                                                    <option value={2}>모두사용</option>
                                                </select>
                                            </td>
                                            <td>
                                                <input style={{
                                                    width: "50%"
                                                }} type="text" id={"inflation_mininum_dividend_2" + index} placeholder="0" defaultValue={basicSettingPage3.inflation_mininum_dividend_2} />
                                                <input type="button" value="배당"/>
                                            </td>
                                            <td>
                                                <input style={{
                                                    width: "50%"
                                                }} type="text" id={"inflation_underover_dividend" + index} placeholder="0" defaultValue={basicSettingPage3.inflation_underover_dividend} />
                                                <input type="button" value="배당"/>
                                            </td>
                                            <td>
                                                <select
                                                    id= {"inflation_point_limit_2" + index}
                                                    className="form-select me-1"
                                                    defaultValue={basicSettingPage3.inflation_point_limit_2}
                                                    style={{
                                                        width: "100px"
                                                    }}
                                                >
                                                    <option value={0}>0 만사용</option>
                                                    <option value={1}>.5 만사용</option>
                                                    <option value={2}>모두사용</option>
                                                </select>
                                            </td>
                                            <td>
                                                <input style={{
                                                    width: "50%"
                                                }} type="text" id={"inflation_time" + index} placeholder="0" defaultValue={basicSettingPage3.inflation_time} />
                                                <input type="button" value="초"/>
                                            </td>
                                            <td align={"center"}>
                                                <button className="btn btn-outline-secondary" type="button"
                                                        id="button-addon2"
                                                        onClick={()=> {
                                                            basicSettingPageUpdate3(basicSettingPage3.sports_cd, basicSettingPage3.sports_cd-1)
                                                        }}
                                                >변경
                                                </button>
                                            </td>
                                        </tr>
                                    ))}



                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>


                </div>
            </div>
        </div>

    )
}
export default BasicSettingPage;