import {createSlice} from "@reduxjs/toolkit"

export const MoneyLogMngReducerSlice = createSlice({
    name: 'MoneyLogMngReducer',
    initialState: {
        isLoading: false,
        error: null,
        moneyLogMngList: [],
        totalList: [],
        page: 1,
        pageSize: 20,
        totalCount: 0,
        moneyLogListCount: 0,
        order: 0,
        selectedNos: [],
        totalSelectedMoney : 0,
        IsOpen : false
    },
    reducers: {
        getMoneyLogMngReducer(state, action) {
            console.log("#reducers getMoneyLogMngReducer start#",action.payload.pageSize,action.payload.page);
            state.isLoading = true;
            state.page = action.payload.pageSize === 999? 1: action.payload.page;
            state.pageSize = action.payload.pageSize;
            state.order = action.payload.order;
            state.query = action.payload.query
            state.startDate = action.payload.startDate;
            state.endDate = action.payload.endDate;
        },
        setMoneyLogMngReducer(state, action) {
            console.log("#reducers setMoneyLogMngReducer start#",action.payload.moneyLogList);
            if((state.page -1) * state.pageSize > action.payload.totalCount) {
                state.page = 1
            }
            state.isLoading = false;
            state.totalList = action.payload.totalList;
            state.totalCount = action.payload.totalCount;
            state.moneyLogMngList = action.payload.moneyLogList;
            state.moneyLogListCount = action.payload.moneyLogListCount;
            //초기화
            state.selectedNos = [];
            state.totalSelectedMoney = 0;
        },
        failedGetMoneyLogMngReducer(state, action) {
            console.log("#reducers failedGetMoneyLogMngReducer start#");
            state.isLoading = false;
            state.error = action.payload;
        },
        setSelectedNos(state, action) {
            state.selectedNos = action.payload;
        },
        setTotalSelectedMoney(state, action) {
            state.totalSelectedMoney = action.payload;
        },
    }
});

export const { getMoneyLogMngReducer, setMoneyLogMngReducer, failedGetMoneyLogMngReducer,setSelectedNos,setTotalSelectedMoney } = MoneyLogMngReducerSlice.actions;

export default MoneyLogMngReducerSlice.reducer;