import React, {useEffect, useState} from "react";
import axios from "axios";
import moment from "moment";
import typeCase from "../../util/typeCase"

const MemberLoginLog = ({setMemberLoginLog, userNo}) => {

    const [loginLog, setLoginLog] = useState([])
    const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().add(1, "day").format('YYYY-MM-DD'));
    const [query, setQuery] = useState("");

    const getNo = () => {
        axios.post('/admin/login_log/get_no', {
            user_no: userNo,
        }).then((res) => {
            setLoginLog(res.data.loginLog)
        })

    }
    useEffect(getNo, [])

    const startDateHandler = (e) => {
        setStartDate(e.currentTarget.value)
    }
    const endDateHandler = (e) => {
        setEndDate(e.currentTarget.value)
    }

    const searchHandler = (query, startDate, endDate) => {
        axios.post('/admin/login_log/query', {
            start_date: startDate,
            end_date: endDate,
            query: query,
            device: 10,
        }).then((res) => {
            if (res.data.loginLogList !== null) {
                setLoginLog(res.data.loginLogList)
            } else {
                alert("실패")
            }
        })
    }
    const handleCancel = () => {
        setMemberLoginLog(false);
    }

    return (
        <div className="memberLog_background" onClick={handleCancel}>
            <div className="log_modal_box" onClick={(event => event.stopPropagation())}>
                <div className="member_Log">
                    <div className="loginLog">
                        <div className="title p-2" style={{
                            textAlign: "left",
                            fontSize: "25px"
                        }}>
                            접속기록
                        </div>
                        <div className="d-flex justify-content-end">
                            <div className="d-flex">
                                <input type="date" className="form-control mx-1" value={startDate} onChange={startDateHandler} style={{
                                    width: "150px",
                                    height: "40px"
                                }}/>
                                <span className="mx-1 my-auto">~</span>
                                <input type="date" className="form-control mx-1" value={endDate} onChange={endDateHandler} style={{
                                    width: "150px",
                                    height: "40px"
                                }}/>

                                <div className=" justify-content-end me-2" style={{
                                    height: "40px",
                                }}>
                                    <button className="btn btn-secondary" type="button" id="button-addon2"
                                            onClick={() => searchHandler(query, startDate, endDate)}>검색
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className="content p-2">
                            <div className="modalOverflow">
                                <table className="table table-bordered table-sm text-center align-middle">
                                    <thead className="table-dark">
                                    <tr>
                                        <th>아이디</th>
                                        <th>접속장비</th>
                                        <th>접속도메인</th>
                                        <th>IP</th>
                                        <th>접속시간</th>
                                    </tr>
                                    </thead>
                                    {loginLog.map((loginLog,index) => (
                                        <tbody key={loginLog.no}>
                                            <tr>
                                                <td>{loginLog.id}</td>
                                                <td>{typeCase.deviceType(loginLog.device)}</td>
                                                <td>{loginLog.domain}</td>
                                                <td>{loginLog.ip}</td>
                                                <td>{moment(loginLog.createdAt).format("YYYY-MM-DD HH:mm")}</td>
                                            </tr>
                                        </tbody>
                                        ))
                                    }
                                </table>

                            </div>

                        </div>


                    </div>
                    <div className="member_Log_close" onClick={handleCancel}>
                        <button className="btn btn-primary">확인</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MemberLoginLog;