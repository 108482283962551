import React from "react";
import StatContainer from "../../containers/StatContainer";

const Total = () => {
    return (
        <div>
            <h1>통합세부내역</h1>
            <StatContainer />
        </div>
    )

}

export default Total;