import JoinListContainer from "../../containers/user/JoinListContainer";
import React from "react";

const JoinListPage = () => {
    return (
        <div>
            <JoinListContainer />
        </div>

    )
}
export default JoinListPage;