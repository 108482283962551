import React, {forwardRef, useEffect} from 'react';
import TeamListApiSettingComponent from "../../components/match/TeamListApiSettingComponent";
import Pagination from "../../components/common/Pagination";
import {
    getTeamApiSettingInfoListReducer,
    setTeamNm,
    setTeamUseYn
} from "../../redux/reducers/match/teamApiSettingReducer";
import {useDispatch, useSelector} from "react-redux";
import {getLeagueApiSettingInfoListReducer} from "../../redux/reducers/match/leagueApiSettingReducer";
import {setNationNm, setNationUseYn} from "../../redux/reducers/match/nationApiSettingReducer";


const TeamListApiSettingContainer = forwardRef(({},ref) => {
    const dispatch = useDispatch()
    const initialState = useSelector((state) => state.teamApiSettingReducer)

    const getTeamList = () => {
        if(initialState.teamList !== null) {
            dispatch(getTeamApiSettingInfoListReducer({
                teamPage: initialState.teamPage,
                teamPageSize: initialState.teamPageSize,
                leagueNo: initialState.leagueNo,
                teamUseYn: initialState.teamUseYn,
                teamNm: initialState.teamNm,
            }))
        }
    }
    useEffect(() => {
        if (initialState.teamList === null) {
            getTeamList();
        }
    }, [dispatch, initialState.teamList]);

    React.useImperativeHandle(ref, () => ({
        getChildTeamInfo: (leagueNo) => {
            console.log('자식 컴포넌트 함수 호출');
            dispatch(getTeamApiSettingInfoListReducer({
                teamPage: 1,
                teamPageSize: initialState.teamPageSize,
                leagueNo: leagueNo,
                teamUseYn: initialState.teamUseYn,
                teamNm: initialState.teamNm,
            }))
        },
    }), []); //

    const handlePageChange = (page) => {
        dispatch(getTeamApiSettingInfoListReducer({
            teamPage: page,
            teamPageSize: initialState.teamPageSize,
            leagueNo: initialState.leagueNo,
            teamUseYn: initialState.teamUseYn,
            teamNm: initialState.teamNm,
        }))
    }
    const useOption = [
        {value: "", name: "전체"},
        {value: 'Y', name: "사용"},
        {value: 'N', name: "미사용"},
    ]

    const query = (teamUseYn) => {
        dispatch(getTeamApiSettingInfoListReducer({
            teamPage: 1,
            teamPageSize: initialState.teamPageSize,
            leagueNo: initialState.leagueNo,
            teamUseYn: teamUseYn,
            teamNm: initialState.teamNm,
        }))
    }

    const inputPress = (e) => {
        if(e.key === "Enter") {
            query(initialState.teamUseYn)
        }
    }

    const teamNmHandler = (e) => {
        dispatch(setTeamNm(e.target.value))
    }

    const changeUseYn = (e) => {
        dispatch(setTeamUseYn(e.target.value))
        console.log(e.target.value)
        query(e.target.value)
    }
    return (
        <div>
            <div className="container-fluid m-1 p-1" style={{
                display: "flex"
            }}>
                <div className="input-group me-1" style={{
                    display: "flex",
                    width: "1000px",
                    alignItems: "center"
                }}>
                    <p className="me-2" style={{width: "60px", margin: 0}}>사용여부</p>
                    <select className="form-select me-4"
                            style={{width: "50px"}} onChange={changeUseYn}>
                        {useOption.map((item) => (
                            <option key={item.value} value={item.value}>{item.name}</option>
                        ))}
                    </select>
                    <input style={{
                        width: "100px"
                    }} type="text" className="form-control" id="search_edit" placeholder="팀명" onChange={teamNmHandler} onKeyDown={inputPress} />
                    <button style={{
                        width: "80px",borderTopRightRadius: "5px" ,borderBottomRightRadius: "5px", // 오른쪽 하단 모서리
                    }} type="submit" className="btn btn-primary" onClick={() => query(initialState.teamUseYn)}>검색</button>
                    <button style={{width: "100px",backgroundColor : "green" ,borderColor : "green",borderRadius: "5px"}} type="submit" className="btn btn-primary mx-1" >가져오기</button>
                </div>
            </div>
            <TeamListApiSettingComponent initialState = {initialState} query = {query}/>
            <div className="d-flex justify-content-center"> {/* text-center 클래스 추가 */}
                {initialState.totalTeamCount && initialState.totalTeamCount > 0 ?
                <Pagination totalCount={initialState.totalTeamCount} pageSize={initialState.teamPageSize} currentPage={initialState.teamPage} onPageChange={handlePageChange}/>
                    :""}
            </div>
        </div>
    );
});

export default TeamListApiSettingContainer;