import React, {useState} from 'react';
import {Link, Outlet, useNavigate} from "react-router-dom";

const Setting = () => {

    const navigate = useNavigate();
    const [activeNav, setActiveNav] = useState();
    const ontoggle = (index, address) => {
        setActiveNav(index)
        navigate(address)
    }
    const menuArr = [
        {name:"환경설정", address:"/setting/adminSetting"},
        {name:"페이지접근권한", address:"/setting/adminPage"},
        {name:"평생도메인", address:"/setting/foreverDomain"},
        {name:"등급관리", address:"/setting/grade"},
        {name:"포인트관리", address:"/setting/pointSetting"},
        {name:"가입코드관리", address:"/setting/JoinCode"},
    ]
    return (
        <div>
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
                <div className="collapse navbar-collapse" style={{
                    marginLeft: "290px",
                    height: "40px"
                }}>
                    <ul className="navbar-nav text-center">
                        {menuArr.map((menu,index) => (
                            <li key={index} className="mx-2" onClick={() => ontoggle(index, menu.address)}>
                                <div className={activeNav === index ? "nav-item active" : "nav-item"} style={{cursor: "pointer"}}>
                                    {menu.name}
                                </div>
                            </li>
                        ))}

                    </ul>
                </div>

            </nav>
            <div style={{
                paddingLeft: "10px"
            }}>
                <Outlet />
            </div>

        </div>

    )
}

export default Setting;