import React from 'react';

const CasinoStatistic = () => {
    return (
        <div className="m-2">
            <div className="card-body">
                <div>
                    <div className="container-fluid">
                        <div className="row bg-light align-items-center" >
                            <div className="col-6">
                                에볼루션 정산
                            </div>

                            <div className="col align-items-center justify-content-end d-flex" style={{
                                height: "50px"
                            }}>
                                <input type="date" className="form-control mx-1" value="" style={{
                                    width: "150px",
                                    height: "30px"
                                }}/>
                                <span className="mx-1">~</span>
                                <input type="date" className="form-control mx-1" value="" style={{
                                    width: "150px",
                                    height: "30px"
                                }}/>

                                <button className="btn btn-outline-secondary" type="button"
                                        id="button-addon2">검색
                                </button>
                            </div>

                        </div>
                    </div>
                    <div>
                        <table className="table table-bordered table-sm text-center align-middle">
                            <thead className="table-dark">
                            <tr>
                                <td>날짜</td>
                                <td>카지노 충전</td>
                                <td>카지노 환전</td>
                                <td>카지노 충환차액</td>
                                <td>카지노 배팅</td>
                                <td>카지노 당첨</td>
                                <td>카지노 손익</td>
                                <td>카지노 접속수</td>
                                <td>본사 카지노 충전</td>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>2022-03-20</td>
                                <td>123,123,123</td>
                                <td>123,123,123</td>
                                <td>123,123,123</td>
                                <td>123,123,123</td>
                                <td>123,123,123</td>
                                <td>123,123,123</td>
                                <td>234</td>
                                <td>
                                    <input type="text" className="form-control" value="" style={{
                                        height: "30px"
                                    }}/>
                                </td>
                            </tr>
                            <tr>
                                <td>2022-03-20</td>
                                <td>123,123,123</td>
                                <td>123,123,123</td>
                                <td>123,123,123</td>
                                <td>123,123,123</td>
                                <td>123,123,123</td>
                                <td>123,123,123</td>
                                <td>234</td>
                                <td>
                                    <input type="text" className="form-control" value="" style={{
                                        height: "30px"
                                    }}/>
                                </td>
                            </tr>
                            </tbody>

                        </table>

                    </div>

                </div>
            </div>
        </div>
    )
}

export default CasinoStatistic;