import {useEffect, useState} from "react";
import axios from "axios";
import {useDispatch} from "react-redux";
import {setUserInfo} from "../redux/reducers/userInfo"

const LoginModal = () => {
    const dispatch = useDispatch();
    // const userInfo = useSelector((state) => state.userInfo)

    const [id, setId] = useState("");
    const [pw, setPw] = useState("");
    const [ip, setIp] = useState("");
    const [country, setCountry] = useState("");
    const [devices, setDevices] = useState("");
    const domainName =  window.location.hostname;
    const userAgent = navigator.userAgent;

    const getDevice = () => {
        axios.get('https://api.ip.pe.kr/json/')
            .then((res) => {
                let checkDevice = "";
                if ( userAgent.indexOf("Android") !== -1) {
                    checkDevice = 1
                } else if( userAgent.indexOf("iPhone") !== -1 ) {
                    checkDevice = 2
                } else {
                    checkDevice = 0
                }
                setDevices(checkDevice)
                setIp(res.data.ip)
                setCountry(res.data.country_name.ko)
            })
    }
    useEffect(getDevice, [])

    const onIdHandler = (e) => {
        setId(e.currentTarget.value);
    }

    const onPwHandler = (e) => {
        setPw(e.currentTarget.value);
    }

    const onSubmitHandler = (e) => {
        const loginData = {
            id: id,
            pw: pw,
            domain: domainName,
            ip: ip,
            country: country,
            device: devices,
        }
        axios.post('/admin/admin/login', loginData).then((res) => {
            if(res.status === 200) {
                //console.log(res.data)
                dispatch(setUserInfo(res.data))
                window.location.href="/"
            }
        })
    }
    const onCheckEnter = (e) => {
        if(e.key === 'Enter') {
            onSubmitHandler()
        }
    }

    return (
        <div className="modal_background">
            <div className="modal_box">
                <div className="buttonArea">
                    <div className="text-center">
                        <div className="login_join">
                            <div className="d-flex">
                                <input type="text" maxLength="12" value={id} onChange={onIdHandler}
                                       placeholder="ID"/>
                                <input type="password" maxLength="12" value={pw} onChange={onPwHandler}
                                       placeholder="PW" onKeyDown={onCheckEnter}/>
                            </div>
                            <div>
                                <button onClick={onSubmitHandler} style={{
                                    width: "100%"
                                }}>
                                    로그인
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LoginModal;