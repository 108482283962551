import {call, put} from "redux-saga/effects";
import {setTeamApiSettingInfoListReducer,failedGetTeamApiSettingInfoListReducer}  from "../../reducers/match/teamApiSettingReducer";
import {fetchTeamApiSettingInfoList} from "../../../lib/api";

//팀 API설정리스트
export function* getTeamApiSettingInfoListSaga(action) {
    try{
        console.log("##getTeamApiSettingInfoListSaga#",action.payload.teamPage,action.payload.teamPageSize,action.payload.leagueNo,action.payload.teamUseYn,action.payload.teamNm)
        const res = yield call(fetchTeamApiSettingInfoList,action.payload.teamPage,action.payload.teamPageSize,action.payload.leagueNo,action.payload.teamUseYn,action.payload.teamNm);
        yield put(setTeamApiSettingInfoListReducer(res.data))
    }
    catch (error) {
        console.log(error)
        yield put(failedGetTeamApiSettingInfoListReducer(error))
    }
}