import {call, put, take,} from "redux-saga/effects";
import {closeChannel, createSocketChannel} from "../../channel/createSocketChannel";
import socket from "../../../config/socket";
import {
    changeActiveMatch, failedGetActiveFixture, failedGetActiveFixtureDetail,
    getActiveFixtureDetail,
    pushActiveMatchTask,
    setActiveFixture, setCurrentFixture
} from "../../reducers/match/activeMatch";
import {fetchActiveFixture, fetchFixtureDetail} from "../../../lib/api";

export function* getActiveFixtureSaga(action) {
    try {
        const res = yield call(fetchActiveFixture,
            action.payload.page, action.payload.pageSize, action.payload.sport,
            action.payload.provider, action.payload.league, action.payload.query);
        yield put(setActiveFixture(res.data));
        if(res.data.result?.length > 0) {
            yield put(getActiveFixtureDetail(res.data.result[0].fixture_id))
        }
        else {
            yield put(setCurrentFixture(null))
        }
    } catch(error) {
        console.log(error)
        yield put(failedGetActiveFixture(error));
    }
}

export function* getActiveFixtureDetailSaga(action) {
    try {
        const res = yield call(fetchFixtureDetail, action.payload, 1)
        yield put(setCurrentFixture(res.data))
    } catch(error) {
        console.log(error)
        yield put(failedGetActiveFixtureDetail(error))
    }
}

export function* waitActiveMatchTaskSaga() {
    let channel;
    try {
        channel = yield call(createSocketChannel, "activeMatch");
        while(true) {
            const task = yield take(channel);
            yield onTaskSaga(task)
        }
    } catch (e) {
        console.log(e, "error");
    } finally {
        socket.close();
        closeChannel(channel)
    }
}

function* onTaskSaga(task) {
    yield put(pushActiveMatchTask(task));
    yield put(changeActiveMatch(task));
}

export function* changeActiveMatchSaga() {
    //yield put(changeSport(changedItem))
}

