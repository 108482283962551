import React, {useState} from "react";
import moment from "moment/moment";
import {useDispatch, useSelector} from "react-redux";
import {getCloseFixtureDetail, setBetInputs, setCheckedInputs, setCheckedDetailInputs} from "../../redux/reducers/match/closeMatch";
import axios from "axios";
import common from "../../util/common";
import {NumericFormat} from "react-number-format";

const CloseMatchList = ({matches, onRefresh}) => {
    const dispatch = useDispatch()
    const [isEdit, setIsEdit] = useState(false)
    const [isPreview, setIsPreview] = useState(false)
    const [home, setHome] = useState(null)
    const [away, setAway] = useState(null)
    const [previewRatios, setPreviewRatios] = useState([])
    const userInfo = useSelector((state) => state.userInfo);

    const changeHandler = (checked, id, index) => {
        if(checked) {
            dispatch(setCheckedInputs([...matches.checkedInputs, id]));
        }
        else {
            dispatch(setCheckedInputs(matches.checkedInputs.filter((el) => el !== id)));
        }
    };
    const changeDetailHandler = (checked, id) => {
        if(checked) {
            dispatch(setCheckedDetailInputs([...matches.checkedDetailInputs, id]));
        }
        else {
            dispatch(setCheckedDetailInputs(matches.checkedDetailInputs.filter((el) => el !== id)));
        }
    };

    const allChangeHandler = (e) => {
        const check_ids = []
        for(let index = 0; index < matches.low_ratios.length; index++) {
            check_ids.push(matches.low_ratios[index].fixture_id)
        }
        dispatch(setCheckedInputs(e.target.checked? check_ids : []));
    }

    const changeBet = (e, id, index, type) => {
        if(e.target.value !== e.target.defaultValue) {
            e.target.style.color = "green"
        }
        else {
            e.target.style.color = "#555"
        }
        let value;
        if([1,2,3].includes(type)) {
            value = parseFloat(e.target.value)
        }
        else {
            value = e.target.value
        }
        const data = {
            bet1: matches.fixtures[index].bet1,
            bet2: matches.fixtures[index].bet2,
            bet3: matches.fixtures[index].bet3,
            home_score: matches.fixtures[index].home_score,
            away_score: matches.fixtures[index].away_score,
        }

        const betInputs2 = matches.betInputs.filter((el) => el.id !== id);

        const betInputs3 = matches.betInputs.filter((el) => el.id === id && el.is_edited === 1);
        let inputs
        if(betInputs3.length > 0) {
            if(type === 1) {
                inputs = {id: id, bet1: value, bet2: betInputs3[0].bet2, bet3: betInputs3[0].bet3, home_score: betInputs3[0].home_score, away_score: betInputs3[0].away_score}
            }
            else if(type === 2) {
                inputs = {id: id, bet2: value, bet1: betInputs3[0].bet1, bet3: betInputs3[0].bet3, home_score: betInputs3[0].home_score, away_score: betInputs3[0].away_score}
            }
            else if(type === 3) {
                inputs = {id: id, bet3: value, bet2: betInputs3[0].bet2, bet1: betInputs3[0].bet1, home_score: betInputs3[0].home_score, away_score: betInputs3[0].away_score}
            }
            else if(type === 4) {
                inputs = {id: id, bet3: betInputs3[0].bet3, bet2: betInputs3[0].bet2, bet1: betInputs3[0].bet1, home_score: value, away_score: betInputs3[0].away_score}
            }
            else if(type === 5) {
                inputs = {id: id, bet3: betInputs3[0].bet3, bet2: betInputs3[0].bet2, bet1: betInputs3[0].bet1, home_score: betInputs3[0].home_score, away_score: value}
            }
        }
        else {
            if(type === 1) {
                inputs = {id: id, bet1: value, bet2: data.bet2, bet3: data.bet3, home_score: data.home_score, away_score: data.away_score}
            }
            else if(type === 2) {
                inputs = {id: id, bet2: value, bet1: data.bet1, bet3: data.bet3, home_score: data.home_score, away_score: data.away_score}
            }
            else if(type === 3) {
                inputs = {id: id, bet3: value, bet2: data.bet2, bet1: data.bet1, home_score: data.home_score, away_score: data.away_score}
            }
            else if(type === 4) {
                inputs = {id: id, bet3: data.bet3, bet2: data.bet2, bet1: data.bet1, home_score: value, away_score: data.away_score}
            }
            else if(type === 5) {
                inputs = {id: id, bet3: data.bet3, bet2: data.bet2, bet1: data.bet1, home_score: data.home_score, away_score: value}
            }
        }
        inputs.is_edited = 1;
        dispatch(setBetInputs([...betInputs2, inputs]))

        //체크처리
        const checkedInput2 = matches.checkedInputs.filter((el) => el !== id)
        dispatch(setCheckedInputs([...checkedInput2, id]));
    }

    const editRefundMatch = (fixtureIds) => {
        console.log(fixtureIds)
        axios.post('/admin/editRefundMatch', {
            adminNo: userInfo.userNo,
            fixtureIds: fixtureIds
        }).then((res) => {
            if(res.status === 200) {
                alert(res.data.result + "개 수정완료");
                onRefresh();
            }
        })
    }
    const editActiveMatch = (fixtureIds) => {
        console.log(fixtureIds)
        axios.post('/admin/editActiveMatch', {
            adminNo: userInfo.userNo,
            fixtureIds: fixtureIds
        }).then((res) => {
            if(res.status === 200) {
                alert(res.data.result + "개 수정완료");
                onRefresh();
            }
        })
    }


    function getSettlement(fixture) {
        let settlement = "진행중"
        if(fixture.market_baseline === null && !fixture.market.includes("Correct Score")) {
            if(fixture.bet2) {
                if(fixture.bet1_settlement === 2) {
                    settlement = "승"
                }
                else if(fixture.bet2_settlement === 2) {
                    settlement = "무"
                }
                else if(fixture.bet3_settlement === 2) {
                    settlement = "패"
                }
                else if(fixture.bet1_settlement === 3) {
                    settlement = "적특"
                }
            }
            else {
                if(fixture.home.includes("[홀]")) {
                    if(fixture.bet1_settlement === 2) {
                        settlement = "홀"
                    }
                    else if(fixture.bet3_settlement === 2) {
                        settlement = "짝"
                    }
                    else if(fixture.bet1_settlement === 3) {
                        settlement = "적특"
                    }

                }
                else {
                    if(fixture.bet1_settlement === 2) {
                        settlement = "승"
                    }
                    else if(fixture.bet3_settlement === 2) {
                        settlement = "패"
                    }
                    else if(fixture.bet1_settlement === 3) {
                        settlement = "적특"
                    }

                }
            }
        }
        else if(fixture.market_baseline !== null) {
            if(fixture.home.includes("오버")) {
                if(fixture.bet1_settlement === 2) {
                    settlement = "오버"
                }
                else if(fixture.bet3_settlement === 2) {
                    settlement = "언더"
                }
                else if(fixture.bet1_settlement === 3) {
                    settlement = "적특"
                }
            }
            else {
                if(fixture.bet1_settlement === 2) {
                    settlement = "승"
                }
                else if(fixture.bet3_settlement === 2) {
                    settlement = "패"
                }
                else if(fixture.bet1_settlement === 3) {
                    settlement = "적특"
                }
            }

        }
        else if(fixture.market.includes("Correct Score")) {
            if(fixture.bet1_settlement === 2) {
                settlement = "적중"
            }
            else if(fixture.bet1_settlement === 1) {
                settlement = "미적중"
            }
            else if(fixture.bet1_settlement === 3) {
                settlement = "적특"
            }
        }

        return settlement;
    }

    function getDetail(ratios) {
        const ratiosByMarket = common.groupBy(ratios, "market_id")
        const keys = Object.keys(ratiosByMarket)
        const result = []
        for(let i = 0; i < keys.length; i++) {

            //마켓아이디 처리
            const marketId = keys[i]

            const arr = ratiosByMarket[marketId]
            // 마켓아이디별 픽스쳐처리
            const trs = []
            for(const fixture of arr) {
                const settle = getSettlement(fixture)
                let settleClass = ""
                if(settle === "적특") {
                    settleClass = "table-danger"
                }
                else if(settle === "진행중") {
                    settleClass = "table-primary"
                }
                trs.push(<tr>
                    {!isEdit && (<td>
                        <input type="checkbox" name={fixture.id} id={fixture.id} value="true"
                               checked={matches.checkedDetailInputs.includes(fixture.id)}
                               onChange={(e) => {changeDetailHandler(e.currentTarget.checked, fixture.id)}}
                        />
                    </td>)}
                    <td className={fixture.bet1_settlement === 2 && "table-success"}>
                        <div>
                            <p>{fixture.bet1}</p>
                            {fixture.total && (<p><NumericFormat value={fixture.total.sumExtraMoney1} displayType={'text'} thousandSeparator={true}/><span>{` (${fixture.total.betCount1})`}</span></p>)}
                        </div>
                    </td>
                    <td className={fixture.market_baseline === null && (fixture.bet2_settlement  === 2 && "table-success")}>
                        <div>
                            <p>{fixture.bet2}</p>
                            {fixture.market_baseline === null && fixture.total && (
                                <p>
                                    <NumericFormat value={fixture.total.sumExtraMoney2} displayType={'text'} thousandSeparator={true}/><span>{` (${fixture.total.betCount2})`}</span>
                                </p>
                            )}
                        </div>
                    </td>
                    <td className={fixture.bet3_settlement  === 2 && "table-success"}>
                        <div>
                            <p>{fixture.bet3}</p>
                            {fixture.total && (<p><NumericFormat value={fixture.total.sumExtraMoney3} displayType={'text'} thousandSeparator={true}/><span>{` (${fixture.total.betCount3})`}</span></p>)}
                        </div>
                    </td>
                    <td className={settleClass}>{settle}</td>
                </tr>)
            }

            const tableTemp = <div>
                <p className="h4 text-bg-dark mb-0 p-2">{arr[0].market}</p>
                <table className="table table-bordered table-hover text-center">
                    <thead className="table-secondary">
                    <tr>
                        {!isEdit && (<th></th>)}
                        <th style={{
                            width: "35%"
                        }}>{arr[0].home}</th>
                        <th style={{
                            width: "13%"
                        }}>{arr[0].market_id === 1? "무" : "기준점"}</th>
                        <th style={{
                            width: "35%"
                        }}>{arr[0].away}</th>
                        <th style={{
                            width: "auto"
                        }}>당첨여부</th>
                    </tr>
                    </thead>
                    <tbody>
                    {trs}
                    </tbody>
                </table>
            </div>

            result.push(tableTemp)
        }

        return result
    }

    function resultPreview(fixture) {
        const ratios = fixture.ratios
        const editedRatios = []
        for(const ratio of ratios) {
            const editedRatio = Object.assign({}, ratio)
            //세방향일때
            if([1].includes(ratio.market_id)) {
                if(home > away) {
                    editedRatio.bet1_settlement = 2
                    editedRatio.bet2_settlement = 1
                    editedRatio.bet3_settlement = 1
                }
                else if(home < away) {
                    editedRatio.bet1_settlement = 1
                    editedRatio.bet2_settlement = 1
                    editedRatio.bet3_settlement = 2
                }
                else {
                    editedRatio.bet1_settlement = 1
                    editedRatio.bet2_settlement = 2
                    editedRatio.bet3_settlement = 1
                }
                editedRatio.bet1_status = 3
                editedRatio.bet2_status = 3
                editedRatio.bet3_status = 3
                editedRatio.home_score = home
                editedRatio.away_score = away
            }
            // O/U
            else if([2].includes(ratio.market_id)) {
                if((home + away) > parseFloat(ratio.market_baseline)) {
                    editedRatio.bet1_settlement = 2
                    editedRatio.bet3_settlement = 1
                }
                else if((home + away) < parseFloat(ratio.market_baseline)) {
                    editedRatio.bet1_settlement = 1
                    editedRatio.bet3_settlement = 2
                }
                else {
                    editedRatio.bet1_settlement = 3
                    editedRatio.bet3_settlement = 3
                }
                editedRatio.bet1_status = 3
                editedRatio.bet3_status = 3
                editedRatio.home_score = home
                editedRatio.away_score = away
            }
            // Handicap
            else if([3].includes(ratio.market_id)) {
                const add = Number(ratio.market_baseline.replace(" (0-0)", ""))
                if(home + add > away) {
                    editedRatio.bet1_settlement = 2
                    editedRatio.bet3_settlement = 1
                }
                else if(home + add < away) {
                    editedRatio.bet1_settlement = 1
                    editedRatio.bet3_settlement = 2
                }
                else {
                    editedRatio.bet1_settlement = 3
                    editedRatio.bet3_settlement = 3
                }
                editedRatio.bet1_status = 3
                editedRatio.bet3_status = 3
                editedRatio.home_score = home
                editedRatio.away_score = away

            }
            editedRatio.status = 4
            editedRatios.push(editedRatio)
        }
        setPreviewRatios(editedRatios)
        setIsPreview(true)
    }

    function editResult() {
        axios.post('/admin/editResult', {
            ratios: previewRatios,
            adminNo: userInfo.userNo
        }).then((res) => {
            if(res.status === 200) {
                alert(res.data.result + "개 수정완료");
                onRefresh()
            }
        })
    }

    function activeDetail(ratioIds) {
        axios.post('/admin/editActiveDetail', {
            adminNo: userInfo.userNo,
            ratioIds: ratioIds
        }).then((res) => {
            if(res.status === 200) {
                alert(res.data.result + "개 수정완료");
                onRefresh()
            }
        })
    }

    return (
        <div className="container-fluid p-1 m-0">

            <div className="mb-1">
                <button className="btn btn-dark me-1" onClick={() => {editRefundMatch(matches.checkedInputs)}} >선택경기적특</button>
                <button className="btn btn-primary me-1" onClick={() => {editActiveMatch(matches.checkedInputs)}} >선택경기활성</button>
            </div>


            <div className="d-flex">
                <div className="" style={{
                    width: "50%",
                    height: "1200px",
                    overflowY: "scroll",
                }}>
                    {matches.low_ratios && (
                        <table className="table table-bordered table-hover text-center">
                            <thead className="table-dark">
                            <tr>
                                <th className="text-center">
                                    <input
                                        type="checkbox"
                                        checked={
                                            matches.checkedInputs.length === matches.low_ratios.length
                                        }
                                        onChange={(e) => {allChangeHandler(e)}}
                                    />
                                </th>
                                <th>종목</th>
                                <th>일정</th>
                                <th>리그</th>
                                <th>홈팀</th>
                                <th>원정팀</th>
                                <th>진행배팅금</th>
                                <th>FixID</th>
                                <th>수정시간</th>
                            </tr>
                            </thead>
                            <tbody>
                            {matches.isLoading && "로딩중..."}
                            {!matches.isLoading && !matches.low_ratios.length > 0 && (
                                <tr>
                                    <td colSpan="3">목록이 비어있습니다.</td>
                                </tr>
                            )}
                            {!matches.isLoading && !!matches.low_ratios.length && matches.low_ratios.map((item, index) => (
                                <tr className={matches.checkedInputs.includes(item.id) && "info"} onClick={() => {
                                    dispatch(getCloseFixtureDetail(item.fixture_id))
                                    setIsEdit(false)
                                    setIsPreview(false)
                                    setHome(null)
                                    setAway(null)
                                }}>
                                    <td>
                                        <input type="checkbox" name={item.fixture_id} id={item.fixture_id} value="true"
                                               checked={matches.checkedInputs.includes(item.fixture_id)}
                                               onChange={(e) => {changeHandler(e.currentTarget.checked, item.fixture_id, index)}}
                                        />
                                    </td>
                                    <td>{`${item.sport}\n(${item.mCount})`}</td>
                                    <td>{moment(item.start_date).format("YYYY-MM-DD HH:mm")}</td>
                                    <td>{item.league}</td>
                                    <td>
                                        {item.home}
                                    </td>
                                    {/*<td>
                                <p>{matches.originFixtures.filter(item => item.id === item.arr[0].id).length > 0?
                                    (<span style={{
                                        color: matches.originFixtures.filter(item => item.id === item.arr[0].id)[0].bet1 < item.arr[0].bet1?
                                            "blue"
                                            : "red",
                                        textDecoration: item.arr[0].bet1_status !== 1 && "line-through"
                                    }}>{item.arr[0].bet1}</span>)
                                    : (<span style={{
                                        textDecoration: item.arr[0].bet1_status !== 1 && "line-through"
                                    }}>{item.arr[0].bet1}
                                </span>)}
                                </p>

                            </td>
                            {item.arr[0].market_id === 1 && [6046, 35232, 530129].includes(item.arr[0].sport_id)?
                                (<td>
                                    <p>{matches.originFixtures.filter(item => item.id === item.arr[0].id).length > 0?
                                        (<span style={{
                                            color: matches.originFixtures.filter(item => item.id === item.arr[0].id)[0].bet2 < item.arr[0].bet2?
                                                "blue"
                                                : "red",
                                            textDecoration: item.arr[0].bet2_status !== 1 && "line-through",
                                        }}>
                                        {item.arr[0].bet2}
                                    </span>)
                                        : (<span style={{
                                            textDecoration: item.arr[0].bet2_status !== 1 && "line-through",
                                        }}>
                                    {item.arr[0].bet2}
                                    </span>)}
                                    </p>
                                </td>)
                                : ![1,5,52].includes(item.arr[0].market_id)?
                                    (<td>{item.arr[0].bet2}</td>)
                                    : (<td>VS</td>)}
                            <td>
                                <p>{matches.originFixtures.filter(item => item.id === item.arr[0].id).length > 0?
                                    (<span style={{
                                        color: matches.originFixtures.filter(item => item.id === item.arr[0].id)[0].bet3 < item.arr[0].bet3?
                                            "blue"
                                            : "red",
                                        textDecoration: item.arr[0].bet3_status !== 1 && "line-through",
                                    }}>
                                    {item.arr[0].bet3}
                                </span>)
                                    : (<span style={{
                                        textDecoration: item.arr[0].bet3_status !== 1 && "line-through"
                                    }}>
                                    {item.arr[0].bet3}
                                </span>)}
                                </p>

                            </td>*/}
                                    <td>{item.away}</td>
                                    <td>
                                        {item.total && (
                                            <div style={{
                                                width: "100px",
                                                justifyContent: "center"
                                            }}>
                                                <p><NumericFormat value={item.total.sumExtraMoney} displayType={'text'} thousandSeparator={true}/><span>{` (${item.total.betCount})`}</span></p>
                                            </div>
                                        )}

                                    </td>
                                    <td>{item.fixture_id}</td>
                                    <td>{moment(item["match.fixture.updatedAt"]).format("YYYY-MM-DD HH:mm")}</td>
                                    {/*<td>
                            <input
                                name={index}
                                type="text"
                                defaultValue={league.name_kor}
                                onBlur={(e) => {
                                    changeName(e.currentTarget.value, league.id, index)
                                }}
                            />
                        </td>*/}

                                </tr>
                            ))}
                            </tbody>
                        </table>
                    )}
                </div>
                <div className="" style={{
                    width: "50%",
                    height: "1200px",
                    overflowY: "scroll",
                }}>
                    {matches.isLoadingDetail && "로딩중..."}
                    {!matches.isLoadingDetail && matches.currentFixture === null && "선택된 경기가 없습니다."}
                    {!matches.isLoadingDetail && matches.currentFixture !== null && (
                        <div>
                            <div className="text-bg-dark p-2 mb-5">
                                <p>{`${matches.currentFixture.ratios[0].sport} - ${matches.currentFixture.ratios[0].league}`}</p>
                                <div className="text-center align-middle" style={{
                                    height: "100px",
                                    display: "flex",
                                    justifyContent: "center"
                                }}>
                                    <div style={{
                                        width: "33.3%",
                                        alignSelf: "center"
                                    }}><p>{`${matches.currentFixture.ratios[0].home}`}</p></div>
                                    <div style={{
                                        width: "33.3%",
                                        alignSelf: "center",
                                    }}>
                                        <p className="m-0">{`${moment(matches.currentFixture.ratios[0].start_date).format("YYYY-MM-DD HH:mm")}`}</p>
                                        <p className="m-0">{`${matches.currentFixture.livescore?.scoreboard.home.score} : ${matches.currentFixture.livescore?.scoreboard.away.score}`}</p>
                                    </div>
                                    <div style={{
                                        width: "33.3%",
                                        alignSelf: "center"
                                    }}>{`${matches.currentFixture.ratios[0].away}`}</div>

                                </div>
                                <div>
                                    {!isEdit && (<button className="btn btn-primary me-1" onClick={() => {activeDetail(matches.checkedDetailInputs)}} >선택게임활성</button>)}
                                    {!isEdit && (
                                        <button className="btn btn-secondary me-1" onClick={() => {
                                            setIsEdit(true)
                                        }} >수동결과</button>
                                    )}
                                    {isEdit && (
                                        <div style={{
                                            display: "flex"
                                        }}>
                                            <input style={{
                                                width: "220px"
                                            }} type="number" className="form-control" id="home_txt" placeholder="홈" onBlur={(e) => {
                                                setHome(parseInt(e.target.value))
                                            }} />
                                            <input style={{
                                                width: "220px"
                                            }} type="number" className="form-control" id="away_txt" placeholder="원정" onBlur={(e) => {
                                                setAway(parseInt(e.target.value))
                                            }} />
                                            <button className="btn btn-secondary me-1" onClick={() => {
                                                if(home !== null && away !== null) {
                                                    resultPreview(matches.currentFixture)
                                                }
                                                else {
                                                    alert("홈,원정 스코어 숫자를 넣어주세요.")
                                                }
                                            }} >미리보기</button>
                                        </div>
                                    )}
                                </div>

                            </div>
                            {!isPreview && getDetail(matches.currentFixture.ratios)}
                            {isPreview && getDetail(previewRatios)}
                            {isPreview && (
                                <div>
                                    <button className="btn btn-secondary me-1" onClick={() => {
                                        editResult()
                                    }} >마감처리</button>
                                </div>
                            )}

                        </div>
                    )}

                </div>
            </div>

        </div>
    );

}

export default CloseMatchList;