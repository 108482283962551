import {useDispatch, useSelector} from "react-redux"
import React, {useEffect, useState} from "react"
import {getMarket, waitMarketTask} from "../redux/reducers/match/market";
import MarketList from "../components/match/MarketList";
import Pagination from "../components/common/Pagination";

const MarketListContainer = () => {
    // useDispatch: 컴포넌트 내부에서 스토어의 내장 함수 dispatch를 사용할 수 있게 해주는 hook 이다.
    // useSelector: connect 함수를 대신하여 스토어 상태를 조회
    const dispatch = useDispatch()
    const markets = useSelector((state) => state.markets)
    const [searchQuery, setSearchQuery] = useState("");

    // 브라우저 상에 컴포넌트가 나타날 때 상품 목록을 조회하는 함수를 실행
    useEffect(() => {
        dispatch(getMarket({
            page: markets.page,
            pageSize: markets.pageSize,
            is_active: 0,
            query: searchQuery,
        }))
        dispatch(waitMarketTask())
    }, [dispatch])

    const handlePageChange = (page) => {
        dispatch(getMarket({
            page: page,
            pageSize: markets.pageSize,
            is_active: markets.is_active,
            query: searchQuery,
        }))
    }

    const changeQuery = (value) => {
        setSearchQuery(value)
    }

    const inputPress = (e) => {
        if(e.key === "Enter") {
            onSearch(e.target.value)
        }
    }
    const onSearch = (query) => {
        setSearchQuery(query)
        dispatch(getMarket({
            page: markets.page,
            pageSize: markets.pageSize,
            is_active: markets.is_active,
            query: query,
        }))
    }
    const onClickSearch = (e) => {

        dispatch(getMarket({
            page: markets.page,
            pageSize: markets.pageSize,
            is_active: markets.is_active,
            query: searchQuery,
        }))
    }

    return <>
        <div style={{
            display: "flex"
        }}>
            <select
                style={{
                    width: "100px"
                }}
                className="form-control"
                defaultValue={0}
                value={markets.is_active === "0"? "전체" : markets.is_active}
                onChange={e =>
                    dispatch(getMarket({
                        is_active: e.target.value,
                        page: markets.page,
                        pageSize: markets.pageSize,
                        query: searchQuery,
                    }))
                }
            >
                <option value={0}>전체</option>
                <option value={1}>사용</option>
                <option value={2}>미사용</option>

            </select>
            <select
                style={{
                    width: "100px"
                }}
                className="form-control"
                defaultValue={100}
                value={markets.pageSize === "999"? "모두" : markets.pageSize}
                onChange={e =>
                    dispatch(getMarket({
                        page: markets.page,
                        pageSize: parseInt(e.target.value),
                        is_active: markets.is_active,
                        query: searchQuery,
                    }))
                }
            >
                <option value={50}>50</option>
                <option value={100}>100</option>
                <option value={200}>200</option>
                <option value={999}>모두</option>
            </select>
            <div style={{
                display: "flex",
                width: "300px"
            }}>
                <input style={{
                    width: "220px"
                }} type="text" className="form-control" id="search_edit" placeholder="마켓명, ID를 입력하세요" onBlur={(e) => {
                    changeQuery(e.target.value)
                }} onKeyDown={inputPress} />
                <button style={{
                    width: "80px"
                }} type="submit" className="btn btn-primary" onClick={onClickSearch}>검색</button>
            </div>
        </div>

        <MarketList markets={markets} />
        <Pagination totalCount={markets.totalCount} pageSize={markets.pageSize} currentPage={markets.page} onPageChange={handlePageChange}/>
    </>

}
export default MarketListContainer