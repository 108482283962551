import {call, put} from "redux-saga/effects";
import {failedGetDailyFeeReducer, setDailyFeeReducer} from "../../reducers/agent/DailyFeeReducer";
import {fetchDailyFee} from "../../../lib/api";

export function* getDailyFeeSaga(action) {
    try{
        console.log("##getDailyFeeSaga#",action.payload.page,action.payload.pageSize, action.payload.order, action.payload.storeName, action.payload.storeCode, action.payload.startDt, action.payload.endDt, action.payload.comboYn)
        const res = yield call(
            fetchDailyFee,
            action.payload.page,
            action.payload.pageSize,
            action.payload.order,
            action.payload.storeName,
            action.payload.storeCode,
            action.payload.startDt,
            action.payload.endDt,
            action.payload.comboYn,
        );
        console.log("##setDailyFeeSaga#",res.data)
        yield put(setDailyFeeReducer(res.data))
    }
    catch (error) {
        console.log(error)
        yield put(failedGetDailyFeeReducer(error))
    }
}