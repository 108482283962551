import React from 'react';
import {Link} from "react-router-dom";

const DepositMSG = () => {
    return (
        <div>
            <div className="card-body">
                <div>
                    <div className="container-fluid">
                        <div className="row bg-light align-items-center" >
                            <div className="col-6">
                                충전대기
                            </div>

                            <div className="col align-items-center justify-content-end" style={{
                                display:"flex",
                                height: "50px"
                            }}>
                                <button className="btn btn-primary" type="button"
                                        id="button-addon2">은행등록
                                </button>
                            </div>

                        </div>



                    </div>
                    <div>
                        <table className="table table-bordered table-sm text-center align-middle">
                            <thead className="table-dark">
                            <tr>
                                <td>은행명</td>
                                <td>계좌번호</td>
                                <td>예금주</td>
                                <td>입금액</td>
                                <td>수신시간</td>
                                <td>상태</td>
                                <td>관리자</td>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>하나은행</td>
                                <td>243****27500</td>
                                <td>
                                    <Link className="nav-link" to="#collapse1" data-bs-toggle="collapse"
                                          aria-expanded="false" aria-controls="collapse1">
                                        테스트유저
                                    </Link>
                                </td>
                                <td>110,000</td>
                                <td>2022-03-20 23:17:59</td>
                                <td>대기 | 완료 </td>
                                <td>admin</td>
                            </tr>
                            <tr className="collapse" id="collapse1">
                                <td colSpan="7">
                                    <div className="container-fluid">
                                        <div className="row border">
                                            <div className="col-1 bg-light align-text-middle">
                                                <p>가입정보</p>
                                            </div>
                                            <div className="col">
                                                <div style={{
                                                    display: "flex"
                                                }}>
                                                    <div className="row">
                                                        <label className="col col-form-label">매장명</label>
                                                        <div className="col">
                                                            <input readOnly="true" type="text" className="form-control" value="본사" />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <label className="col col-form-label">매장코드</label>
                                                        <div className="col">
                                                            <input type="text" className="form-control" value="AA01" />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <label className="col col-form-label">파트너ID</label>
                                                        <div className="col">
                                                            <input type="text" className="form-control" value="bonsa" />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <label className="col col-form-label">가입코드</label>
                                                        <div className="col">
                                                            <input readOnly="true" type="text" className="form-control" value="bonsa" />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <label className="col col-form-label">가입일</label>
                                                        <div className="col">
                                                            <input readOnly="true" type="text" className="form-control" value="2021-10-11" />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <label className="col col-form-label">가입도메인</label>
                                                        <div className="col">
                                                            <input readOnly="true" type="text" className="form-control" value="bonsa.com" />
                                                        </div>
                                                    </div>
                                                    <p className="text-danger">[쪽지]</p>

                                                </div>

                                            </div>
                                        </div>
                                        <div className="row border">
                                            <div className="col-1 bg-light">
                                                <p>회원정보</p>
                                                <button type="button" className="btn btn-danger btn-sm" style={{
                                                    width: "80px",
                                                    height: "30px"
                                                }}>로그아웃</button>
                                            </div>
                                            <div className="col">
                                                <div style={{
                                                    display: "flex"
                                                }}>
                                                    <div className="row">
                                                        <label className="col col-form-label">아이디</label>
                                                        <div className="col">
                                                            <input readOnly="true" type="text" className="form-control" value="test01" />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <label className="col col-form-label">닉네임</label>
                                                        <div className="col">
                                                            <input readOnly="true" type="text" className="form-control" value="프로토" />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <label className="col col-form-label">예금주</label>
                                                        <div className="col">
                                                            <input readOnly="true" type="text" className="form-control" value="홍길동" />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <label className="col col-form-label">은행명</label>
                                                        <div className="col">
                                                            <select className="form-select"  style={{
                                                                width: "130px",
                                                                height: "40px",
                                                            }}>
                                                                <option selected>하나은행</option>
                                                                <option value="1">국민은행</option>
                                                                <option value="2">신한은행</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <label className="col col-form-label">계좌번호</label>
                                                        <div className="col">
                                                            <input type="text" className="form-control" value="" />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <label className="col col-form-label">계좌변경</label>
                                                        <div className="col">
                                                            <input readOnly="true" type="text" className="form-control" value="118" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={{
                                                    display: "flex"
                                                }}>
                                                    <div className="row">
                                                        <label className="col col-form-label">비밀번호</label>
                                                        <div className="col">
                                                            <input type="text" className="form-control" value="" />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <label className="col col-form-label">환전비밀번호</label>
                                                        <div className="col">
                                                            <input type="text" className="form-control" value="" />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <label className="col col-form-label">등급</label>
                                                        <div className="col">
                                                            <select className="form-select"  style={{
                                                                width: "80px",
                                                                height: "40px",
                                                            }}>
                                                                <option selected>VIP</option>
                                                                <option value="1">1</option>
                                                                <option value="2">2</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <label className="col col-form-label">상태</label>
                                                        <div className="col">
                                                            <select className="form-select"  style={{
                                                                width: "80px",
                                                                height: "40px",
                                                            }}>
                                                                <option selected>정상</option>
                                                                <option value="1">휴면</option>
                                                                <option value="2">탈퇴</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <label className="col col-form-label">전화번호</label>
                                                        <div className="col">
                                                            <select className="form-select"  style={{
                                                                width: "120px",
                                                                height: "40px",
                                                            }}>
                                                                <option selected>알뜰 SKT</option>
                                                                <option value="1">KT</option>
                                                                <option value="2">LG</option>
                                                            </select>
                                                        </div>
                                                        <div className="col">
                                                            <input type="text" className="form-control" value="" />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <label className="col col-form-label">생년월일</label>
                                                        <div className="col">
                                                            <input type="text" className="form-control" value="" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={{
                                                    display: "flex"
                                                }}>
                                                    <div className="row">
                                                        <label className="col col-form-label">추천인코드</label>
                                                        <div className="col">
                                                            <input type="text" className="form-control" value="" />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <label className="col col-form-label">최근 접속일</label>
                                                        <div className="col">
                                                            <input readOnly="true" type="text" className="form-control" value="2021-10-11" />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <label className="col col-form-label">최근 도메인</label>
                                                        <div className="col">
                                                            <input readOnly="true" type="text" className="form-control" value="bonsa.com" />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <label className="col col-form-label">최근 아이피</label>
                                                        <div className="col">
                                                            <input readOnly="true" type="text" className="form-control" value="127.0.0.1" />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <label className="col col-form-label">접속수</label>
                                                        <div className="col">
                                                            <input readOnly="true" type="text" className="form-control" value="118" />
                                                        </div>
                                                    </div>
                                                    <p className="text-success">[접속기록]</p>
                                                    <p className="text-danger">[수정로그]</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row border">
                                            <div className="col-1 bg-light">
                                                메모
                                            </div>
                                            <div className="col"style={{
                                                display: "flex"
                                            }}>

                                                <label className="form-label">가입경로</label>
                                                <div className="form-check mx-2">
                                                    <input className="form-check-input" type="radio"
                                                           name="flexRadioDefault" id="flexRadioDefault1" />
                                                        <label className="form-check-label"
                                                               htmlFor="flexRadioDefault1">
                                                            파트너
                                                        </label>
                                                </div>
                                                <div className="form-check me-2" style={{
                                                    display: "flex"
                                                }}>
                                                    <input className="form-check-input me-1" type="radio"
                                                           name="flexRadioDefault" id="flexRadioDefault2"
                                                           checked />
                                                        <label className="form-check-label me-1"
                                                               htmlFor="flexRadioDefault2">
                                                            회원
                                                        </label>
                                                    <input type="text" className="form-control" value="" style={{
                                                        width: "150px",
                                                        height: "30px"
                                                    }}/>
                                                </div>
                                                <label className="form-label mx-2">본인인증</label>
                                                <input type="text" className="form-control" value="" style={{
                                                    width: "150px",
                                                    height: "30px"
                                                }}/>

                                                <label className="form-label mx-2">이용종목</label>
                                                <input type="text" className="form-control" value="" style={{
                                                    width: "150px",
                                                    height: "30px"
                                                }}/>

                                                <label className="form-label mx-2">정보조회</label>
                                                <input type="text" className="form-control" value="" style={{
                                                    width: "350px",
                                                    height: "30px"
                                                }}/>

                                                <p className="text-danger ms-2">[메모]</p>

                                            </div>
                                        </div>
                                        <div className="row border">
                                            <div className="col-1 bg-light">
                                                배팅현황
                                            </div>
                                            <div className="col">
                                                <div className="row">
                                                    <div className="col" style={{
                                                        display: "flex"
                                                    }}>
                                                        <p className="text-success">배팅전체</p>
                                                        <span className="mx-1">|</span>
                                                        <p className="text-primary">배팅진행중</p>
                                                        <span className="mx-1">|</span>
                                                        <p className="text-primary">배팅완료</p>
                                                        <span className="mx-1">|</span>
                                                        <p className="text-primary">당첨</p>
                                                        <span className="mx-1">|</span>
                                                        <p className="text-primary">미당첨</p>
                                                        <span className="mx-1">|</span>
                                                        <p className="text-primary">배팅취소</p>
                                                        <span className="mx-1">|</span>
                                                        <p className="text-primary">세부내역</p>
                                                    </div>

                                                    <div className="col" style={{
                                                        display: "flex"
                                                    }}>
                                                        <p className="text-primary">[단폴더 : 1회]</p>
                                                        <span className="mx-1">|</span>
                                                        <p className="text-primary">[2폴더 : 0회]</p>
                                                        <span className="mx-1">|</span>
                                                        <p className="text-primary">[3폴더 : 0회]</p>
                                                        <span className="mx-1">|</span>
                                                        <p className="text-primary">[4폴더이상 : 0회]</p>
                                                        <span className="mx-1">|</span>
                                                        <p className="text-primary">[<span className="text-success">미니게임</span> : 1회]</p>
                                                        <span className="mx-1">|</span>
                                                        <p className="text-primary">[<span className="text-success">로투스</span> : 1회]</p>
                                                        <span className="mx-1">|</span>
                                                        <p className="text-primary">[<span className="text-success">가상게임</span> : 1회]</p>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="row border">
                                            <div className="col-1 bg-light">
                                                보유현황
                                            </div>
                                            <div className="col" style={{
                                                display: "flex"
                                            }}>

                                                <label className="form-label me-2">보유머니</label>
                                                <input readOnly="true" type="text" className="form-control" value="123,123,123" style={{
                                                    width: "150px",
                                                    height: "30px"
                                                }} />
                                                <p className="text-primary ms-1">[관리]</p>

                                                <label className="form-label mx-2">포인트</label>
                                                <input readOnly="true" type="text" className="form-control" value="123,123" style={{
                                                    width: "100px",
                                                    height: "30px"
                                                }}/>
                                                <p className="text-primary ms-1">[관리]</p>

                                                <label className="form-label mx-2">충전</label>
                                                <input readOnly="true" type="text" className="form-control" value="123,123,123" style={{
                                                    width: "150px",
                                                    height: "30px"
                                                }} />
                                                <p>(10,000)</p>
                                                <p className="text-primary ms-1">[관리]</p>

                                                <label className="form-label mx-2">환전</label>
                                                <input readOnly="true" type="text" className="form-control" value="123,123,123" style={{
                                                    width: "150px",
                                                    height: "30px"
                                                }} />
                                                <p>(10,000)</p>
                                                <p className="text-primary ms-1">[관리]</p>

                                                <label className="form-label mx-2">정산</label>
                                                <input readOnly="true" type="text" className="form-control" value="123,123,123" style={{
                                                    width: "150px",
                                                    height: "30px"
                                                }} />
                                                <p className="text-success mx-1">[입출금내역]</p>
                                                <p className="text-primary mx-1">[전체내역]</p>
                                            </div>
                                        </div>
                                        <div className="row border">
                                            <div className="col-1 bg-light">
                                                종목별 충전
                                            </div>
                                            <div className="col" style={{
                                                display: "flex"
                                            }}>
                                                <label className="form-label mx-4">스포츠 충전</label>
                                                <input readOnly="true" type="text" className="form-control" value="123,123,123" style={{
                                                    width: "150px",
                                                    height: "30px"
                                                }} />
                                                <label className="form-label mx-4">미니게임 충전</label>
                                                <input readOnly="true" type="text" className="form-control" value="123,123,123" style={{
                                                    width: "150px",
                                                    height: "30px"
                                                }} />
                                                <label className="form-label mx-4">카지노 충전</label>
                                                <input readOnly="true" type="text" className="form-control" value="123,123,123" style={{
                                                    width: "150px",
                                                    height: "30px"
                                                }} />
                                                <label className="form-label mx-4">최근충전</label>
                                                <input readOnly="true" type="text" className="form-control" value="300,000" style={{
                                                    width: "150px",
                                                    height: "30px"
                                                }} />

                                                <p className="text-danger mx-1">미니게임 2021-10-11 15:00</p>
                                            </div>
                                        </div>
                                        <div className="row border">
                                            <div className="col-1 bg-light">
                                                스포츠배팅
                                            </div>
                                            <div className="col" style={{
                                                display: "flex"
                                            }}>
                                                <label className="form-label mx-4">스포츠충전</label>
                                                <input readOnly="true" type="text" className="form-control" value="123,123,123" style={{
                                                    width: "150px",
                                                    height: "30px"
                                                }} />
                                                <label className="form-label mx-4">스포츠당첨</label>
                                                <input readOnly="true" type="text" className="form-control" value="123,123,123" style={{
                                                    width: "150px",
                                                    height: "30px"
                                                }} />
                                                <label className="form-label mx-4">스포츠낙첨</label>
                                                <input readOnly="true" type="text" className="form-control" value="123,123,123" style={{
                                                    width: "150px",
                                                    height: "30px"
                                                }} />
                                                <label className="form-label mx-4">스포츠정산</label>
                                                <input readOnly="true" type="text" className="form-control" value="123,123,123" style={{
                                                    width: "150px",
                                                    height: "30px"
                                                }} />
                                                <label className="form-label mx-4">스포츠승률</label>
                                                <input readOnly="true" type="text" className="form-control" value="100%" style={{
                                                    width: "150px",
                                                    height: "30px"
                                                }} />
                                            </div>
                                        </div>
                                        <div className="row border">
                                            <div className="col-1 bg-light">
                                                미니게임배팅
                                            </div>
                                            <div className="col" style={{
                                                display: "flex"
                                            }}>
                                                <label className="form-label mx-4">미니충전</label>
                                                <input readOnly="true" type="text" className="form-control" value="123,123,123" style={{
                                                    width: "150px",
                                                    height: "30px"
                                                }} />
                                                <label className="form-label mx-4">미니당첨</label>
                                                <input readOnly="true" type="text" className="form-control" value="123,123,123" style={{
                                                    width: "150px",
                                                    height: "30px"
                                                }} />
                                                <label className="form-label mx-4">미니낙첨</label>
                                                <input readOnly="true" type="text" className="form-control" value="123,123,123" style={{
                                                    width: "150px",
                                                    height: "30px"
                                                }} />
                                                <label className="form-label mx-4">미니정산</label>
                                                <input readOnly="true" type="text" className="form-control" value="123,123,123" style={{
                                                    width: "150px",
                                                    height: "30px"
                                                }} />
                                                <label className="form-label mx-4">미니승률</label>
                                                <input readOnly="true" type="text" className="form-control" value="100%" style={{
                                                    width: "150px",
                                                    height: "30px"
                                                }} />
                                            </div>
                                        </div>
                                        <div className="row border">
                                            <div className="col-1 bg-light">
                                                카지노
                                            </div>
                                            <div className="col" style={{
                                                display: "flex"
                                            }}>
                                                <input className="form-check-input" type="checkbox" value=""/>
                                                <label className="form-label mx-1">차단</label>

                                                <label className="form-label mx-4">카지노충전</label>
                                                <input readOnly="true" type="text" className="form-control" value="123,123,123" style={{
                                                    width: "150px",
                                                    height: "30px"
                                                }} />
                                                <label className="form-label mx-4">카지노당첨</label>
                                                <input readOnly="true" type="text" className="form-control" value="123,123,123" style={{
                                                    width: "150px",
                                                    height: "30px"
                                                }} />
                                                <label className="form-label mx-4">카지노낙첨</label>
                                                <input readOnly="true" type="text" className="form-control" value="123,123,123" style={{
                                                    width: "150px",
                                                    height: "30px"
                                                }} />
                                                <label className="form-label mx-4">에볼루션</label>
                                                <input readOnly="true" type="text" className="form-control" value="bonsa" style={{
                                                    width: "150px",
                                                    height: "30px"
                                                }} />
                                            </div>
                                        </div>
                                        <div className="row border">
                                            <div className="col-1 bg-light">
                                                이용차단
                                            </div>
                                            <div className="col" style={{
                                                display: "flex"
                                            }}>
                                                <input className="form-check-input" type="checkbox" value=""/>
                                                <label className="form-label mx-1">전체</label>
                                                <input className="form-check-input ms-3" type="checkbox" value=""/>
                                                <label className="form-label text-secondary mx-1">게시판</label>
                                                <input className="form-check-input ms-3" type="checkbox" value=""/>
                                                <label className="form-label text-secondary mx-1">고객센터</label>

                                                <span className="mx-5">|</span>

                                                <label className="form-label mx-1">크로스</label>
                                                <input className="form-check-input" type="checkbox" value=""/>
                                                <label className="form-label mx-1">단폴더</label>
                                                <input className="form-check-input" type="checkbox" value=""/>
                                                <label className="form-label mx-1">두폴더</label>
                                                <input className="form-check-input" type="checkbox" value=""/>
                                                <label className="form-label mx-1">세폴더</label>

                                                <span className="mx-5">|</span>

                                                <label className="form-label mx-1">스페셜</label>
                                                <input className="form-check-input" type="checkbox" value=""/>
                                                <label className="form-label mx-1">단폴더</label>
                                                <input className="form-check-input" type="checkbox" value=""/>
                                                <label className="form-label mx-1">두폴더</label>
                                                <input className="form-check-input" type="checkbox" value=""/>
                                                <label className="form-label mx-1">세폴더</label>

                                                <span className="mx-5">|</span>

                                                <label className="form-label mx-1">라이브</label>
                                                <input className="form-check-input" type="checkbox" value=""/>
                                                <label className="form-label mx-1">단폴더</label>
                                                <input className="form-check-input" type="checkbox" value=""/>
                                                <label className="form-label mx-1">두폴더</label>
                                                <input className="form-check-input" type="checkbox" value=""/>
                                                <label className="form-label mx-1">세폴더</label>

                                                <span className="mx-5">|</span>

                                                <label className="form-label me-2">스포츠배당</label>
                                                <input type="text" className="form-control" value="" style={{
                                                    width: "80px",
                                                    height: "30px"
                                                }} />
                                            </div>
                                        </div>
                                        <div className="row border">
                                            <div className="col-1 bg-light">
                                                미니게임차단
                                            </div>
                                            <div className="col">
                                                <div style={{
                                                    display: "flex"
                                                }}>
                                                    <input className="form-check-input" type="checkbox" value=""/>
                                                    <label className="form-label text-secondary mx-1">파워볼</label>
                                                    <input className="form-check-input ms-4" type="checkbox" value=""/>
                                                    <label className="form-label text-secondary mx-1">BET365가상축구</label>
                                                    <input className="form-check-input ms-4" type="checkbox" value=""/>
                                                    <label className="form-label text-secondary mx-1">파워사다리</label>
                                                    <input className="form-check-input ms-4" type="checkbox" value=""/>
                                                    <label className="form-label text-secondary mx-1">로투스홀짝</label>
                                                    <input className="form-check-input ms-4" type="checkbox" value=""/>
                                                    <label className="form-label text-secondary mx-1">로투스바카라</label>
                                                    <input className="form-check-input ms-4" type="checkbox" value=""/>
                                                    <label className="form-label text-secondary mx-1">로투스바카라II</label>
                                                    <input className="form-check-input ms-4" type="checkbox" value=""/>
                                                    <label className="form-label text-secondary mx-1">드래곤타이거</label>
                                                    <input className="form-check-input ms-4" type="checkbox" value=""/>
                                                    <label className="form-label text-secondary mx-1">오리지널용호</label>
                                                    <input className="form-check-input ms-4" type="checkbox" value=""/>
                                                    <label className="form-label text-secondary mx-1">룰렛</label>
                                                    <input className="form-check-input ms-4" type="checkbox" value=""/>
                                                    <label className="form-label text-secondary mx-1">홀덤</label>
                                                </div>
                                                <div style={{
                                                    display: "flex"
                                                }}>
                                                    <input className="form-check-input" type="checkbox" value=""/>
                                                    <label className="form-label text-secondary mx-1">로투스사다리</label>
                                                    <input className="form-check-input ms-4" type="checkbox" value=""/>
                                                    <label className="form-label text-secondary mx-1">BET365개경주</label>
                                                    <input className="form-check-input ms-4" type="checkbox" value=""/>
                                                    <label className="form-label text-secondary mx-1">로투스파워볼</label>
                                                    <input className="form-check-input ms-4" type="checkbox" value=""/>
                                                    <label className="form-label text-secondary mx-1">뱃페어가상축구</label>
                                                    <input className="form-check-input ms-4" type="checkbox" value=""/>
                                                    <label className="form-label text-secondary mx-1">BET365경마</label>
                                                </div>


                                            </div>
                                        </div>
                                        <div className="row border">
                                            <div className="col-1 bg-light">
                                                개별 배팅상한
                                            </div>
                                            <div className="col" style={{
                                                display: "flex"
                                            }}>
                                                <label className="form-label me-1">단폴더</label>
                                                <input type="text" className="form-control" value="" style={{
                                                    width: "100px",
                                                    height: "30px"
                                                }} />
                                                <label className="form-label ms-2">두폴더</label>
                                                <input type="text" className="form-control ms-1" value="" style={{
                                                    width: "100px",
                                                    height: "30px"
                                                }} />
                                                <label className="form-label ms-2">세폴더</label>
                                                <input type="text" className="form-control ms-1" value="" style={{
                                                    width: "100px",
                                                    height: "30px"
                                                }} />
                                                <label className="form-label ms-2">스포츠</label>
                                                <input type="text" className="form-control ms-1" value="" style={{
                                                    width: "100px",
                                                    height: "30px"
                                                }} />
                                                <label className="form-label ms-2">스페셜</label>
                                                <input type="text" className="form-control ms-1" value="" style={{
                                                    width: "100px",
                                                    height: "30px"
                                                }} />
                                                <label className="form-label ms-2">라이브</label>
                                                <input type="text" className="form-control ms-1" value="" style={{
                                                    width: "100px",
                                                    height: "30px"
                                                }} />
                                                <label className="form-label ms-2">미니게임</label>
                                                <input type="text" className="form-control ms-1" value="" style={{
                                                    width: "100px",
                                                    height: "30px"
                                                }} />
                                            </div>
                                        </div>
                                        <div className="row border">
                                            <div className="col-1 bg-light">
                                                개별 당첨상한
                                            </div>
                                            <div className="col" style={{
                                                display: "flex"
                                            }}>
                                                <label className="form-label me-1">단폴더</label>
                                                <input type="text" className="form-control" value="" style={{
                                                    width: "100px",
                                                    height: "30px"
                                                }} />
                                                <label className="form-label ms-2">두폴더</label>
                                                <input type="text" className="form-control ms-1" value="" style={{
                                                    width: "100px",
                                                    height: "30px"
                                                }} />
                                                <label className="form-label ms-2">세폴더</label>
                                                <input type="text" className="form-control ms-1" value="" style={{
                                                    width: "100px",
                                                    height: "30px"
                                                }} />
                                                <label className="form-label ms-2">스포츠</label>
                                                <input type="text" className="form-control ms-1" value="" style={{
                                                    width: "100px",
                                                    height: "30px"
                                                }} />
                                                <label className="form-label ms-2">스페셜</label>
                                                <input type="text" className="form-control ms-1" value="" style={{
                                                    width: "100px",
                                                    height: "30px"
                                                }} />
                                                <label className="form-label ms-2">라이브</label>
                                                <input type="text" className="form-control ms-1" value="" style={{
                                                    width: "100px",
                                                    height: "30px"
                                                }} />
                                                <label className="form-label ms-2">미니게임</label>
                                                <input type="text" className="form-control ms-1" value="" style={{
                                                    width: "100px",
                                                    height: "30px"
                                                }} />
                                            </div>
                                        </div>
                                        <div className="row border">
                                            <div className="col-1 bg-light">
                                                개별 충전
                                            </div>
                                            <div className="col" style={{
                                                display: "flex"
                                            }}>
                                                <label className="form-label">충전</label>
                                                <div className="form-check mx-2">
                                                    <input className="form-check-input" type="radio"
                                                           name="flexRadioDefault2" id="flexRadio1" />
                                                    <label className="form-check-label"
                                                           htmlFor="flexRadioDefault1">
                                                        지급
                                                    </label>
                                                </div>
                                                <div className="form-check me-2" style={{
                                                    display: "flex"
                                                }}>
                                                    <input className="form-check-input me-1" type="radio"
                                                           name="flexRadioDefault2" id="flexRadio2"
                                                           checked />
                                                    <label className="form-check-label"
                                                           htmlFor="flexRadioDefault2">
                                                        미지급
                                                    </label>
                                                </div>

                                                <span className="mx-3">|</span>

                                                <div className="form-check mx-2">
                                                    <input className="form-check-input" type="radio"
                                                           name="flexRadioDefault3" id="flexRadio3" />
                                                    <label className="form-check-label"
                                                           htmlFor="flexRadioDefault1">
                                                        미사용
                                                    </label>
                                                </div>
                                                <div className="form-check me-2" style={{
                                                    display: "flex"
                                                }}>
                                                    <input className="form-check-input me-1" type="radio"
                                                           name="flexRadioDefault3" id="flexRadio4"/>
                                                    <label className="form-check-label"
                                                           htmlFor="flexRadioDefault2">
                                                        회 사용
                                                    </label>
                                                    <input type="text" className="form-control mx-1" value="" style={{
                                                        width: "50px",
                                                        height: "30px"
                                                    }}/>
                                                    <label className="form-check-label"
                                                           htmlFor="flexRadioDefault2">
                                                        회
                                                    </label>
                                                </div>
                                                <div className="form-check me-2" style={{
                                                    display: "flex"
                                                }}>
                                                    <input className="form-check-input me-1" type="radio"
                                                           name="flexRadioDefault3" id="flexRadio5"
                                                            />
                                                    <label className="form-check-label"
                                                           htmlFor="flexRadioDefault2">
                                                        기간사용
                                                    </label>
                                                    <input type="date" className="form-control mx-1" value="" style={{
                                                        width: "150px",
                                                        height: "30px"
                                                    }}/>
                                                    <input type="time" className="form-control mx-1" value="" style={{
                                                        width: "150px",
                                                        height: "30px"
                                                    }}/>
                                                    <span className="mx-1">~</span>
                                                    <input type="date" className="form-control mx-1" value="" style={{
                                                        width: "150px",
                                                        height: "30px"
                                                    }}/>
                                                    <input type="time" className="form-control mx-1" value="" style={{
                                                        width: "150px",
                                                        height: "30px"
                                                    }}/>
                                                </div>

                                                <label className="form-label ms-2">충전</label>
                                                <input type="text" className="form-control ms-1" value="0" style={{
                                                    width: "80px",
                                                    height: "30px"
                                                }} />
                                                <span className="mx-1">%</span>
                                            </div>
                                        </div>
                                        <div className="row border">
                                            <div className="col-1 bg-light">
                                                개별 전용계좌
                                            </div>
                                            <div className="col" style={{
                                                display: "flex"
                                            }}>
                                                <div className="form-check me-1">
                                                    <input className="form-check-input" type="radio"
                                                           name="flexRadioDefault5" id="flexRadio11" />
                                                    <label className="form-check-label"
                                                           htmlFor="flexRadioDefault1">
                                                        미사용
                                                    </label>
                                                </div>
                                                <div className="form-check me-2" style={{
                                                    display: "flex"
                                                }}>
                                                    <input className="form-check-input me-1" type="radio"
                                                           name="flexRadioDefault5" id="flexRadio12"
                                                           checked />
                                                    <label className="form-check-label"
                                                           htmlFor="flexRadioDefault2">
                                                        사용
                                                    </label>
                                                </div>

                                                <label className="form-label ms-5">예금주</label>
                                                <input type="text" className="form-control ms-1" value="홍길동" style={{
                                                    width: "180px",
                                                    height: "30px"
                                                }} />
                                                <label className="form-label ms-5">은행명</label>
                                                <input type="text" className="form-control ms-1" value="하나은행" style={{
                                                    width: "180px",
                                                    height: "30px"
                                                }} />
                                                <label className="form-label ms-5">계좌번호</label>
                                                <input type="text" className="form-control ms-1" value="123451235123213" style={{
                                                    width: "250px",
                                                    height: "30px"
                                                }} />

                                            </div>
                                        </div>
                                        <div className="row border">
                                            <div className="col-1 bg-light">
                                                추천인
                                            </div>
                                            <div className="col" style={{
                                                display: "flex"
                                            }}>
                                                <input readOnly="true" type="text" className="form-control" value="118" style={{
                                                    width: "80px",
                                                    height: "30px"
                                                }}/>
                                                <p className="text-primary ms-1">[관리]</p>

                                                <div className="form-check mx-2">
                                                    <input className="form-check-input" type="radio"
                                                           name="flexRadio44" id="flexRad11" />
                                                    <label className="form-check-label"
                                                           htmlFor="flexRad11">
                                                        가능
                                                    </label>
                                                </div>
                                                <div className="form-check me-2" style={{
                                                    display: "flex"
                                                }}>
                                                    <input className="form-check-input me-1" type="radio"
                                                           name="flexRadio44" id="flexRad22"
                                                           checked />
                                                    <label className="form-check-label"
                                                           htmlFor="flexRad22">
                                                        불가능
                                                    </label>
                                                </div>

                                                <span className="mx-3">|</span>

                                                <div className="form-check mx-2">
                                                    <input className="form-check-input" type="radio"
                                                           name="flexRadio45" id="flexRad3" />
                                                    <label className="form-check-label"
                                                           htmlFor="flexRad3">
                                                        낙첨수수료
                                                    </label>
                                                </div>
                                                <span className="mx-3">|</span>
                                                <div className="form-check me-2" style={{
                                                    display: "flex"
                                                }}>
                                                    <input className="form-check-input me-1" type="radio"
                                                           name="flexRadio45" id="flexRad4"/>
                                                    <label className="form-check-label"
                                                           htmlFor="flexRad4">
                                                        배팅수수료
                                                    </label>
                                                    <label className="form-label ms-2">스포츠</label>
                                                    <input type="text" className="form-control ms-1" value="0" style={{
                                                        width: "80px",
                                                        height: "30px"
                                                    }} />
                                                    <span className="mx-1">%</span>
                                                    <label className="form-label ms-2">미니게임</label>
                                                    <input type="text" className="form-control ms-1" value="0" style={{
                                                        width: "80px",
                                                        height: "30px"
                                                    }} />
                                                    <span className="mx-1">%</span>
                                                </div>
                                                <span className="mx-3">|</span>
                                                <div className="form-check me-2" style={{
                                                    display: "flex"
                                                }}>
                                                    <input className="form-check-input me-1" type="radio"
                                                           name="flexRadio45" id="flexRad5"/>
                                                    <label className="form-check-label"
                                                           htmlFor="flexRad5">
                                                        충/환 죽장
                                                    </label>
                                                    <input type="text" className="form-control ms-1" value="0" style={{
                                                        width: "80px",
                                                        height: "30px"
                                                    }} />
                                                    <span className="mx-1">%</span>
                                                </div>
                                                <span className="mx-3">|</span>

                                                <div className="form-check me-2" style={{
                                                    display: "flex"
                                                }}>
                                                    <input className="form-check-input me-1" type="radio"
                                                           name="flexRadio45" id="flexRad6"/>
                                                    <label className="form-check-label"
                                                           htmlFor="flexRad6">
                                                        보유+P
                                                    </label>
                                                </div>

                                                <div className="form-check me-2" style={{
                                                    display: "flex"
                                                }}>
                                                    <input className="form-check-input me-1" type="radio"
                                                           name="flexRadio45" id="flexRad7"/>
                                                    <label className="form-check-label"
                                                           htmlFor="flexRad7">
                                                        보유+P+충/환
                                                    </label>
                                                </div>

                                                <div className="form-check me-2" style={{
                                                    display: "flex"
                                                }}>
                                                    <input className="form-check-input me-1" type="radio"
                                                           name="flexRadio45" id="flexRad8"/>
                                                    <label className="form-check-label"
                                                           htmlFor="flexRad8">
                                                        보유+P+충/환+배팅
                                                    </label>
                                                </div>


                                            </div>
                                        </div>
                                        <div className="row border">
                                            <div className="col-1 bg-light">
                                                관리자
                                            </div>
                                            <div className="col" style={{
                                                display: "flex"
                                            }}>
                                                <label className="form-label">비밀번호</label>
                                                <input type="text" className="form-control ms-1" value="" style={{
                                                    width: "250px",
                                                    height: "30px"
                                                }} />
                                                <button type="button" className="btn btn-primary btn-sm ms-1" style={{
                                                    width: "100px",
                                                    height: "30px"
                                                }}>저장</button>

                                                <label className="form-label ms-3">마지막저장</label>
                                                <input readOnly="true" type="text" className="form-control ms-1" value="2021-10-11 15:38:00" style={{
                                                    width: "250px",
                                                    height: "30px"
                                                }} />

                                                <label className="form-label ms-3">관리자</label>
                                                <input readOnly="true" type="text" className="form-control ms-1" value="admin" style={{
                                                    width: "250px",
                                                    height: "30px"
                                                }} />

                                                <span className="text-danger mx-3">[수정로그]</span>

                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <table className="table table-bordered table-sm text-center align-middle">
                                                    <thead className="table-secondary">
                                                    <tr>
                                                        <td colSpan="4">최근충전</td>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr>
                                                        <td>요청시간</td>
                                                        <td>처리시간</td>
                                                        <td>금액</td>
                                                        <td>상태</td>
                                                    </tr>
                                                    <tr>
                                                        <td>2021-10-12 10:00:15</td>
                                                        <td>2021-10-12 10:00:30</td>
                                                        <td>1,000,000</td>
                                                        <td>승인</td>
                                                    </tr>
                                                    <tr>
                                                        <td>2021-10-12 10:00:15</td>
                                                        <td>2021-10-12 10:00:30</td>
                                                        <td>1,000,000</td>
                                                        <td>승인</td>
                                                    </tr>
                                                    <tr>
                                                        <td>2021-10-12 10:00:15</td>
                                                        <td>2021-10-12 10:00:30</td>
                                                        <td>1,000,000</td>
                                                        <td>승인</td>
                                                    </tr>
                                                    <tr>
                                                        <td>2021-10-12 10:00:15</td>
                                                        <td>2021-10-12 10:00:30</td>
                                                        <td>1,000,000</td>
                                                        <td>승인</td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="col">
                                                <table className="table table-bordered table-sm text-center align-middle">
                                                    <thead className="table-secondary">
                                                    <tr>
                                                        <td colSpan="4">최근환전</td>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr>
                                                        <td>요청시간</td>
                                                        <td>처리시간</td>
                                                        <td>금액</td>
                                                        <td>상태</td>
                                                    </tr>
                                                    <tr>
                                                        <td>2021-10-12 10:00:15</td>
                                                        <td>2021-10-12 10:00:30</td>
                                                        <td>1,000,000</td>
                                                        <td>승인</td>
                                                    </tr>
                                                    <tr>
                                                        <td>2021-10-12 10:00:15</td>
                                                        <td>2021-10-12 10:00:30</td>
                                                        <td>1,000,000</td>
                                                        <td>승인</td>
                                                    </tr>
                                                    <tr>
                                                        <td>2021-10-12 10:00:15</td>
                                                        <td>2021-10-12 10:00:30</td>
                                                        <td>1,000,000</td>
                                                        <td>승인</td>
                                                    </tr>
                                                    <tr>
                                                        <td>2021-10-12 10:00:15</td>
                                                        <td>2021-10-12 10:00:30</td>
                                                        <td>1,000,000</td>
                                                        <td>승인</td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="col">
                                                <table className="table table-bordered table-sm text-center align-middle">
                                                    <thead className="table-secondary">
                                                    <tr>
                                                        <td colSpan="4">로그인내역</td>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr>
                                                        <td>로그인시간</td>
                                                        <td>도메인</td>
                                                        <td>IP</td>
                                                        <td>타입</td>
                                                    </tr>
                                                    <tr>
                                                        <td>2021-10-12 10:00:15</td>
                                                        <td>asfsfsdf.com</td>
                                                        <td>127.0.0.1 국기</td>
                                                        <td>PC</td>
                                                    </tr>
                                                    <tr>
                                                        <td>2021-10-12 10:00:15</td>
                                                        <td>asfsfsdf.com</td>
                                                        <td>127.0.0.1 국기</td>
                                                        <td>M-A</td>
                                                    </tr>
                                                    <tr>
                                                        <td>2021-10-12 10:00:15</td>
                                                        <td>asfsfsdf.com</td>
                                                        <td>127.0.0.1 국기</td>
                                                        <td>M-I</td>
                                                    </tr>
                                                    <tr>
                                                        <td>2021-10-12 10:00:15</td>
                                                        <td>asfsfsdf.com</td>
                                                        <td>127.0.0.1 국기</td>
                                                        <td>M-A</td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>하나은행</td>
                                <td>243****27500</td>
                                <td>
                                    <Link className="nav-link" to="#collapse1" data-bs-toggle="collapse"
                                          aria-expanded="false" aria-controls="collapse1">
                                        테스트유저
                                    </Link>
                                </td>
                                <td>110,000</td>
                                <td>2022-03-20 23:17:59</td>
                                <td>대기중 </td>
                                <td>admin</td>
                            </tr>
                            </tbody>

                        </table>

                    </div>
                </div>
                <div>
                    <div className="container-fluid">
                        <div className="row bg-light align-items-center" >
                            <div className="col-8">
                                충전완료
                            </div>

                            <div className="col align-items-center justify-content-end" style={{
                                display:"flex",
                                height: "50px"
                            }}>
                                <input type="date" className="form-control mx-1" value="" style={{
                                    width: "150px",
                                    height: "40px"
                                }}/>
                                <span className="mx-1">~</span>
                                <input type="date" className="form-control mx-1" value="" style={{
                                    width: "150px",
                                    height: "40px"
                                }}/>

                                <select className="form-select"  style={{
                                    width: "150px",
                                    height: "40px",
                                }}>
                                    <option selected>분류선택</option>
                                    <option value="1">충전신청</option>
                                    <option value="2">충전대기</option>
                                    <option value="3">충전완료</option>
                                    <option value="4">충전취소</option>
                                </select>
                                <select className="form-select"  style={{
                                    width: "100px",
                                    height: "40px",
                                }}>
                                    <option selected>별명</option>
                                    <option value="1">별명</option>
                                    <option value="2">회원ID</option>
                                    <option value="3">입금자</option>
                                    <option value="4">총판ID</option>
                                </select>
                                <div className="input-group align-items-end" style={{
                                    width: "150px",
                                    height: "40px",
                                }}>
                                    <input type="text" className="form-control" placeholder=""
                                           aria-describedby="button-addon2"/>
                                    <button className="btn btn-outline-secondary" type="button"
                                            id="button-addon2">검색
                                    </button>
                                </div>

                                <button className="btn btn-outline-secondary" type="button"
                                        id="button-addon2">엑셀출력
                                </button>
                            </div>

                        </div>



                    </div>
                    <div>
                        <table className="table table-bordered table-sm text-center align-middle">
                            <thead className="table-dark">
                            <tr>
                                <td>은행명</td>
                                <td>계좌번호</td>
                                <td>예금주</td>
                                <td>입금액</td>
                                <td>수신시간</td>
                                <td>처리시간</td>
                                <td>관리자</td>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>하나은행</td>
                                <td>123****27000</td>
                                <td>
                                    <Link className="nav-link" to="#collapse1" data-bs-toggle="collapse"
                                          aria-expanded="false" aria-controls="collapse1">
                                        테스트유저
                                    </Link>
                                </td>
                                <td>110,000</td>
                                <td>2022-03-20 23:17:59</td>
                                <td>2022-03-20 23:17:59</td>
                                <td>admin</td>
                            </tr>
                            <tr className="collapse" id="collapse1">
                                <td colSpan="7">
                                    <div className="container-fluid">
                                        <div className="row border">
                                            <div className="col-1 bg-light align-text-middle">
                                                <p>가입정보</p>
                                            </div>
                                            <div className="col">
                                                <div style={{
                                                    display: "flex"
                                                }}>
                                                    <div className="row">
                                                        <label className="col col-form-label">매장명</label>
                                                        <div className="col">
                                                            <input readOnly="true" type="text" className="form-control" value="본사" />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <label className="col col-form-label">매장코드</label>
                                                        <div className="col">
                                                            <input type="text" className="form-control" value="AA01" />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <label className="col col-form-label">파트너ID</label>
                                                        <div className="col">
                                                            <input type="text" className="form-control" value="bonsa" />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <label className="col col-form-label">가입코드</label>
                                                        <div className="col">
                                                            <input readOnly="true" type="text" className="form-control" value="bonsa" />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <label className="col col-form-label">가입일</label>
                                                        <div className="col">
                                                            <input readOnly="true" type="text" className="form-control" value="2021-10-11" />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <label className="col col-form-label">가입도메인</label>
                                                        <div className="col">
                                                            <input readOnly="true" type="text" className="form-control" value="bonsa.com" />
                                                        </div>
                                                    </div>
                                                    <p className="text-danger">[쪽지]</p>

                                                </div>

                                            </div>
                                        </div>
                                        <div className="row border">
                                            <div className="col-1 bg-light">
                                                <p>회원정보</p>
                                                <button type="button" className="btn btn-danger btn-sm" style={{
                                                    width: "80px",
                                                    height: "30px"
                                                }}>로그아웃</button>
                                            </div>
                                            <div className="col">
                                                <div style={{
                                                    display: "flex"
                                                }}>
                                                    <div className="row">
                                                        <label className="col col-form-label">아이디</label>
                                                        <div className="col">
                                                            <input readOnly="true" type="text" className="form-control" value="test01" />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <label className="col col-form-label">닉네임</label>
                                                        <div className="col">
                                                            <input readOnly="true" type="text" className="form-control" value="프로토" />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <label className="col col-form-label">예금주</label>
                                                        <div className="col">
                                                            <input readOnly="true" type="text" className="form-control" value="홍길동" />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <label className="col col-form-label">은행명</label>
                                                        <div className="col">
                                                            <select className="form-select"  style={{
                                                                width: "130px",
                                                                height: "40px",
                                                            }}>
                                                                <option selected>하나은행</option>
                                                                <option value="1">국민은행</option>
                                                                <option value="2">신한은행</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <label className="col col-form-label">계좌번호</label>
                                                        <div className="col">
                                                            <input type="text" className="form-control" value="" />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <label className="col col-form-label">계좌변경</label>
                                                        <div className="col">
                                                            <input readOnly="true" type="text" className="form-control" value="118" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={{
                                                    display: "flex"
                                                }}>
                                                    <div className="row">
                                                        <label className="col col-form-label">비밀번호</label>
                                                        <div className="col">
                                                            <input type="text" className="form-control" value="" />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <label className="col col-form-label">환전비밀번호</label>
                                                        <div className="col">
                                                            <input type="text" className="form-control" value="" />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <label className="col col-form-label">등급</label>
                                                        <div className="col">
                                                            <select className="form-select"  style={{
                                                                width: "80px",
                                                                height: "40px",
                                                            }}>
                                                                <option selected>VIP</option>
                                                                <option value="1">1</option>
                                                                <option value="2">2</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <label className="col col-form-label">상태</label>
                                                        <div className="col">
                                                            <select className="form-select"  style={{
                                                                width: "80px",
                                                                height: "40px",
                                                            }}>
                                                                <option selected>정상</option>
                                                                <option value="1">휴면</option>
                                                                <option value="2">탈퇴</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <label className="col col-form-label">전화번호</label>
                                                        <div className="col">
                                                            <select className="form-select"  style={{
                                                                width: "120px",
                                                                height: "40px",
                                                            }}>
                                                                <option selected>알뜰 SKT</option>
                                                                <option value="1">KT</option>
                                                                <option value="2">LG</option>
                                                            </select>
                                                        </div>
                                                        <div className="col">
                                                            <input type="text" className="form-control" value="" />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <label className="col col-form-label">생년월일</label>
                                                        <div className="col">
                                                            <input type="text" className="form-control" value="" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={{
                                                    display: "flex"
                                                }}>
                                                    <div className="row">
                                                        <label className="col col-form-label">추천인코드</label>
                                                        <div className="col">
                                                            <input type="text" className="form-control" value="" />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <label className="col col-form-label">최근 접속일</label>
                                                        <div className="col">
                                                            <input readOnly="true" type="text" className="form-control" value="2021-10-11" />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <label className="col col-form-label">최근 도메인</label>
                                                        <div className="col">
                                                            <input readOnly="true" type="text" className="form-control" value="bonsa.com" />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <label className="col col-form-label">최근 아이피</label>
                                                        <div className="col">
                                                            <input readOnly="true" type="text" className="form-control" value="127.0.0.1" />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <label className="col col-form-label">접속수</label>
                                                        <div className="col">
                                                            <input readOnly="true" type="text" className="form-control" value="118" />
                                                        </div>
                                                    </div>
                                                    <p className="text-success">[접속기록]</p>
                                                    <p className="text-danger">[수정로그]</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row border">
                                            <div className="col-1 bg-light">
                                                메모
                                            </div>
                                            <div className="col"style={{
                                                display: "flex"
                                            }}>

                                                <label className="form-label">가입경로</label>
                                                <div className="form-check mx-2">
                                                    <input className="form-check-input" type="radio"
                                                           name="flexRadioDefault" id="flexRadioDefault1" />
                                                    <label className="form-check-label"
                                                           htmlFor="flexRadioDefault1">
                                                        파트너
                                                    </label>
                                                </div>
                                                <div className="form-check me-2" style={{
                                                    display: "flex"
                                                }}>
                                                    <input className="form-check-input me-1" type="radio"
                                                           name="flexRadioDefault" id="flexRadioDefault2"
                                                           checked />
                                                    <label className="form-check-label me-1"
                                                           htmlFor="flexRadioDefault2">
                                                        회원
                                                    </label>
                                                    <input type="text" className="form-control" value="" style={{
                                                        width: "150px",
                                                        height: "30px"
                                                    }}/>
                                                </div>
                                                <label className="form-label mx-2">본인인증</label>
                                                <input type="text" className="form-control" value="" style={{
                                                    width: "150px",
                                                    height: "30px"
                                                }}/>

                                                <label className="form-label mx-2">이용종목</label>
                                                <input type="text" className="form-control" value="" style={{
                                                    width: "150px",
                                                    height: "30px"
                                                }}/>

                                                <label className="form-label mx-2">정보조회</label>
                                                <input type="text" className="form-control" value="" style={{
                                                    width: "350px",
                                                    height: "30px"
                                                }}/>

                                                <p className="text-danger ms-2">[메모]</p>

                                            </div>
                                        </div>
                                        <div className="row border">
                                            <div className="col-1 bg-light">
                                                배팅현황
                                            </div>
                                            <div className="col">
                                                <div className="row">
                                                    <div className="col" style={{
                                                        display: "flex"
                                                    }}>
                                                        <p className="text-success">배팅전체</p>
                                                        <span className="mx-1">|</span>
                                                        <p className="text-primary">배팅진행중</p>
                                                        <span className="mx-1">|</span>
                                                        <p className="text-primary">배팅완료</p>
                                                        <span className="mx-1">|</span>
                                                        <p className="text-primary">당첨</p>
                                                        <span className="mx-1">|</span>
                                                        <p className="text-primary">미당첨</p>
                                                        <span className="mx-1">|</span>
                                                        <p className="text-primary">배팅취소</p>
                                                        <span className="mx-1">|</span>
                                                        <p className="text-primary">세부내역</p>
                                                    </div>

                                                    <div className="col" style={{
                                                        display: "flex"
                                                    }}>
                                                        <p className="text-primary">[단폴더 : 1회]</p>
                                                        <span className="mx-1">|</span>
                                                        <p className="text-primary">[2폴더 : 0회]</p>
                                                        <span className="mx-1">|</span>
                                                        <p className="text-primary">[3폴더 : 0회]</p>
                                                        <span className="mx-1">|</span>
                                                        <p className="text-primary">[4폴더이상 : 0회]</p>
                                                        <span className="mx-1">|</span>
                                                        <p className="text-primary">[<span className="text-success">미니게임</span> : 1회]</p>
                                                        <span className="mx-1">|</span>
                                                        <p className="text-primary">[<span className="text-success">로투스</span> : 1회]</p>
                                                        <span className="mx-1">|</span>
                                                        <p className="text-primary">[<span className="text-success">가상게임</span> : 1회]</p>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="row border">
                                            <div className="col-1 bg-light">
                                                보유현황
                                            </div>
                                            <div className="col" style={{
                                                display: "flex"
                                            }}>

                                                <label className="form-label me-2">보유머니</label>
                                                <input readOnly="true" type="text" className="form-control" value="123,123,123" style={{
                                                    width: "150px",
                                                    height: "30px"
                                                }} />
                                                <p className="text-primary ms-1">[관리]</p>

                                                <label className="form-label mx-2">포인트</label>
                                                <input readOnly="true" type="text" className="form-control" value="123,123" style={{
                                                    width: "100px",
                                                    height: "30px"
                                                }}/>
                                                <p className="text-primary ms-1">[관리]</p>

                                                <label className="form-label mx-2">충전</label>
                                                <input readOnly="true" type="text" className="form-control" value="123,123,123" style={{
                                                    width: "150px",
                                                    height: "30px"
                                                }} />
                                                <p>(10,000)</p>
                                                <p className="text-primary ms-1">[관리]</p>

                                                <label className="form-label mx-2">환전</label>
                                                <input readOnly="true" type="text" className="form-control" value="123,123,123" style={{
                                                    width: "150px",
                                                    height: "30px"
                                                }} />
                                                <p>(10,000)</p>
                                                <p className="text-primary ms-1">[관리]</p>

                                                <label className="form-label mx-2">정산</label>
                                                <input readOnly="true" type="text" className="form-control" value="123,123,123" style={{
                                                    width: "150px",
                                                    height: "30px"
                                                }} />
                                                <p className="text-success mx-1">[입출금내역]</p>
                                                <p className="text-primary mx-1">[전체내역]</p>
                                            </div>
                                        </div>
                                        <div className="row border">
                                            <div className="col-1 bg-light">
                                                종목별 충전
                                            </div>
                                            <div className="col" style={{
                                                display: "flex"
                                            }}>
                                                <label className="form-label mx-4">스포츠 충전</label>
                                                <input readOnly="true" type="text" className="form-control" value="123,123,123" style={{
                                                    width: "150px",
                                                    height: "30px"
                                                }} />
                                                <label className="form-label mx-4">미니게임 충전</label>
                                                <input readOnly="true" type="text" className="form-control" value="123,123,123" style={{
                                                    width: "150px",
                                                    height: "30px"
                                                }} />
                                                <label className="form-label mx-4">카지노 충전</label>
                                                <input readOnly="true" type="text" className="form-control" value="123,123,123" style={{
                                                    width: "150px",
                                                    height: "30px"
                                                }} />
                                                <label className="form-label mx-4">최근충전</label>
                                                <input readOnly="true" type="text" className="form-control" value="300,000" style={{
                                                    width: "150px",
                                                    height: "30px"
                                                }} />

                                                <p className="text-danger mx-1">미니게임 2021-10-11 15:00</p>
                                            </div>
                                        </div>
                                        <div className="row border">
                                            <div className="col-1 bg-light">
                                                스포츠배팅
                                            </div>
                                            <div className="col" style={{
                                                display: "flex"
                                            }}>
                                                <label className="form-label mx-4">스포츠충전</label>
                                                <input readOnly="true" type="text" className="form-control" value="123,123,123" style={{
                                                    width: "150px",
                                                    height: "30px"
                                                }} />
                                                <label className="form-label mx-4">스포츠당첨</label>
                                                <input readOnly="true" type="text" className="form-control" value="123,123,123" style={{
                                                    width: "150px",
                                                    height: "30px"
                                                }} />
                                                <label className="form-label mx-4">스포츠낙첨</label>
                                                <input readOnly="true" type="text" className="form-control" value="123,123,123" style={{
                                                    width: "150px",
                                                    height: "30px"
                                                }} />
                                                <label className="form-label mx-4">스포츠정산</label>
                                                <input readOnly="true" type="text" className="form-control" value="123,123,123" style={{
                                                    width: "150px",
                                                    height: "30px"
                                                }} />
                                                <label className="form-label mx-4">스포츠승률</label>
                                                <input readOnly="true" type="text" className="form-control" value="100%" style={{
                                                    width: "150px",
                                                    height: "30px"
                                                }} />
                                            </div>
                                        </div>
                                        <div className="row border">
                                            <div className="col-1 bg-light">
                                                미니게임배팅
                                            </div>
                                            <div className="col" style={{
                                                display: "flex"
                                            }}>
                                                <label className="form-label mx-4">미니충전</label>
                                                <input readOnly="true" type="text" className="form-control" value="123,123,123" style={{
                                                    width: "150px",
                                                    height: "30px"
                                                }} />
                                                <label className="form-label mx-4">미니당첨</label>
                                                <input readOnly="true" type="text" className="form-control" value="123,123,123" style={{
                                                    width: "150px",
                                                    height: "30px"
                                                }} />
                                                <label className="form-label mx-4">미니낙첨</label>
                                                <input readOnly="true" type="text" className="form-control" value="123,123,123" style={{
                                                    width: "150px",
                                                    height: "30px"
                                                }} />
                                                <label className="form-label mx-4">미니정산</label>
                                                <input readOnly="true" type="text" className="form-control" value="123,123,123" style={{
                                                    width: "150px",
                                                    height: "30px"
                                                }} />
                                                <label className="form-label mx-4">미니승률</label>
                                                <input readOnly="true" type="text" className="form-control" value="100%" style={{
                                                    width: "150px",
                                                    height: "30px"
                                                }} />
                                            </div>
                                        </div>
                                        <div className="row border">
                                            <div className="col-1 bg-light">
                                                카지노
                                            </div>
                                            <div className="col" style={{
                                                display: "flex"
                                            }}>
                                                <input className="form-check-input" type="checkbox" value=""/>
                                                <label className="form-label mx-1">차단</label>

                                                <label className="form-label mx-4">카지노충전</label>
                                                <input readOnly="true" type="text" className="form-control" value="123,123,123" style={{
                                                    width: "150px",
                                                    height: "30px"
                                                }} />
                                                <label className="form-label mx-4">카지노당첨</label>
                                                <input readOnly="true" type="text" className="form-control" value="123,123,123" style={{
                                                    width: "150px",
                                                    height: "30px"
                                                }} />
                                                <label className="form-label mx-4">카지노낙첨</label>
                                                <input readOnly="true" type="text" className="form-control" value="123,123,123" style={{
                                                    width: "150px",
                                                    height: "30px"
                                                }} />
                                                <label className="form-label mx-4">에볼루션</label>
                                                <input readOnly="true" type="text" className="form-control" value="bonsa" style={{
                                                    width: "150px",
                                                    height: "30px"
                                                }} />
                                            </div>
                                        </div>
                                        <div className="row border">
                                            <div className="col-1 bg-light">
                                                이용차단
                                            </div>
                                            <div className="col" style={{
                                                display: "flex"
                                            }}>
                                                <input className="form-check-input" type="checkbox" value=""/>
                                                <label className="form-label mx-1">전체</label>
                                                <input className="form-check-input ms-3" type="checkbox" value=""/>
                                                <label className="form-label text-secondary mx-1">게시판</label>
                                                <input className="form-check-input ms-3" type="checkbox" value=""/>
                                                <label className="form-label text-secondary mx-1">고객센터</label>

                                                <span className="mx-5">|</span>

                                                <label className="form-label mx-1">크로스</label>
                                                <input className="form-check-input" type="checkbox" value=""/>
                                                <label className="form-label mx-1">단폴더</label>
                                                <input className="form-check-input" type="checkbox" value=""/>
                                                <label className="form-label mx-1">두폴더</label>
                                                <input className="form-check-input" type="checkbox" value=""/>
                                                <label className="form-label mx-1">세폴더</label>

                                                <span className="mx-5">|</span>

                                                <label className="form-label mx-1">스페셜</label>
                                                <input className="form-check-input" type="checkbox" value=""/>
                                                <label className="form-label mx-1">단폴더</label>
                                                <input className="form-check-input" type="checkbox" value=""/>
                                                <label className="form-label mx-1">두폴더</label>
                                                <input className="form-check-input" type="checkbox" value=""/>
                                                <label className="form-label mx-1">세폴더</label>

                                                <span className="mx-5">|</span>

                                                <label className="form-label mx-1">라이브</label>
                                                <input className="form-check-input" type="checkbox" value=""/>
                                                <label className="form-label mx-1">단폴더</label>
                                                <input className="form-check-input" type="checkbox" value=""/>
                                                <label className="form-label mx-1">두폴더</label>
                                                <input className="form-check-input" type="checkbox" value=""/>
                                                <label className="form-label mx-1">세폴더</label>

                                                <span className="mx-5">|</span>

                                                <label className="form-label me-2">스포츠배당</label>
                                                <input type="text" className="form-control" value="" style={{
                                                    width: "80px",
                                                    height: "30px"
                                                }} />
                                            </div>
                                        </div>
                                        <div className="row border">
                                            <div className="col-1 bg-light">
                                                미니게임차단
                                            </div>
                                            <div className="col">
                                                <div style={{
                                                    display: "flex"
                                                }}>
                                                    <input className="form-check-input" type="checkbox" value=""/>
                                                    <label className="form-label text-secondary mx-1">파워볼</label>
                                                    <input className="form-check-input ms-4" type="checkbox" value=""/>
                                                    <label className="form-label text-secondary mx-1">BET365가상축구</label>
                                                    <input className="form-check-input ms-4" type="checkbox" value=""/>
                                                    <label className="form-label text-secondary mx-1">파워사다리</label>
                                                    <input className="form-check-input ms-4" type="checkbox" value=""/>
                                                    <label className="form-label text-secondary mx-1">로투스홀짝</label>
                                                    <input className="form-check-input ms-4" type="checkbox" value=""/>
                                                    <label className="form-label text-secondary mx-1">로투스바카라</label>
                                                    <input className="form-check-input ms-4" type="checkbox" value=""/>
                                                    <label className="form-label text-secondary mx-1">로투스바카라II</label>
                                                    <input className="form-check-input ms-4" type="checkbox" value=""/>
                                                    <label className="form-label text-secondary mx-1">드래곤타이거</label>
                                                    <input className="form-check-input ms-4" type="checkbox" value=""/>
                                                    <label className="form-label text-secondary mx-1">오리지널용호</label>
                                                    <input className="form-check-input ms-4" type="checkbox" value=""/>
                                                    <label className="form-label text-secondary mx-1">룰렛</label>
                                                    <input className="form-check-input ms-4" type="checkbox" value=""/>
                                                    <label className="form-label text-secondary mx-1">홀덤</label>
                                                </div>
                                                <div style={{
                                                    display: "flex"
                                                }}>
                                                    <input className="form-check-input" type="checkbox" value=""/>
                                                    <label className="form-label text-secondary mx-1">로투스사다리</label>
                                                    <input className="form-check-input ms-4" type="checkbox" value=""/>
                                                    <label className="form-label text-secondary mx-1">BET365개경주</label>
                                                    <input className="form-check-input ms-4" type="checkbox" value=""/>
                                                    <label className="form-label text-secondary mx-1">로투스파워볼</label>
                                                    <input className="form-check-input ms-4" type="checkbox" value=""/>
                                                    <label className="form-label text-secondary mx-1">뱃페어가상축구</label>
                                                    <input className="form-check-input ms-4" type="checkbox" value=""/>
                                                    <label className="form-label text-secondary mx-1">BET365경마</label>
                                                </div>


                                            </div>
                                        </div>
                                        <div className="row border">
                                            <div className="col-1 bg-light">
                                                개별 배팅상한
                                            </div>
                                            <div className="col" style={{
                                                display: "flex"
                                            }}>
                                                <label className="form-label me-1">단폴더</label>
                                                <input type="text" className="form-control" value="" style={{
                                                    width: "100px",
                                                    height: "30px"
                                                }} />
                                                <label className="form-label ms-2">두폴더</label>
                                                <input type="text" className="form-control ms-1" value="" style={{
                                                    width: "100px",
                                                    height: "30px"
                                                }} />
                                                <label className="form-label ms-2">세폴더</label>
                                                <input type="text" className="form-control ms-1" value="" style={{
                                                    width: "100px",
                                                    height: "30px"
                                                }} />
                                                <label className="form-label ms-2">스포츠</label>
                                                <input type="text" className="form-control ms-1" value="" style={{
                                                    width: "100px",
                                                    height: "30px"
                                                }} />
                                                <label className="form-label ms-2">스페셜</label>
                                                <input type="text" className="form-control ms-1" value="" style={{
                                                    width: "100px",
                                                    height: "30px"
                                                }} />
                                                <label className="form-label ms-2">라이브</label>
                                                <input type="text" className="form-control ms-1" value="" style={{
                                                    width: "100px",
                                                    height: "30px"
                                                }} />
                                                <label className="form-label ms-2">미니게임</label>
                                                <input type="text" className="form-control ms-1" value="" style={{
                                                    width: "100px",
                                                    height: "30px"
                                                }} />
                                            </div>
                                        </div>
                                        <div className="row border">
                                            <div className="col-1 bg-light">
                                                개별 당첨상한
                                            </div>
                                            <div className="col" style={{
                                                display: "flex"
                                            }}>
                                                <label className="form-label me-1">단폴더</label>
                                                <input type="text" className="form-control" value="" style={{
                                                    width: "100px",
                                                    height: "30px"
                                                }} />
                                                <label className="form-label ms-2">두폴더</label>
                                                <input type="text" className="form-control ms-1" value="" style={{
                                                    width: "100px",
                                                    height: "30px"
                                                }} />
                                                <label className="form-label ms-2">세폴더</label>
                                                <input type="text" className="form-control ms-1" value="" style={{
                                                    width: "100px",
                                                    height: "30px"
                                                }} />
                                                <label className="form-label ms-2">스포츠</label>
                                                <input type="text" className="form-control ms-1" value="" style={{
                                                    width: "100px",
                                                    height: "30px"
                                                }} />
                                                <label className="form-label ms-2">스페셜</label>
                                                <input type="text" className="form-control ms-1" value="" style={{
                                                    width: "100px",
                                                    height: "30px"
                                                }} />
                                                <label className="form-label ms-2">라이브</label>
                                                <input type="text" className="form-control ms-1" value="" style={{
                                                    width: "100px",
                                                    height: "30px"
                                                }} />
                                                <label className="form-label ms-2">미니게임</label>
                                                <input type="text" className="form-control ms-1" value="" style={{
                                                    width: "100px",
                                                    height: "30px"
                                                }} />
                                            </div>
                                        </div>
                                        <div className="row border">
                                            <div className="col-1 bg-light">
                                                개별 충전
                                            </div>
                                            <div className="col" style={{
                                                display: "flex"
                                            }}>
                                                <label className="form-label">충전</label>
                                                <div className="form-check mx-2">
                                                    <input className="form-check-input" type="radio"
                                                           name="flexRadioDefault2" id="flexRadio1" />
                                                    <label className="form-check-label"
                                                           htmlFor="flexRadioDefault1">
                                                        지급
                                                    </label>
                                                </div>
                                                <div className="form-check me-2" style={{
                                                    display: "flex"
                                                }}>
                                                    <input className="form-check-input me-1" type="radio"
                                                           name="flexRadioDefault2" id="flexRadio2"
                                                           checked />
                                                    <label className="form-check-label"
                                                           htmlFor="flexRadioDefault2">
                                                        미지급
                                                    </label>
                                                </div>

                                                <span className="mx-3">|</span>

                                                <div className="form-check mx-2">
                                                    <input className="form-check-input" type="radio"
                                                           name="flexRadioDefault3" id="flexRadio3" />
                                                    <label className="form-check-label"
                                                           htmlFor="flexRadioDefault1">
                                                        미사용
                                                    </label>
                                                </div>
                                                <div className="form-check me-2" style={{
                                                    display: "flex"
                                                }}>
                                                    <input className="form-check-input me-1" type="radio"
                                                           name="flexRadioDefault3" id="flexRadio4"/>
                                                    <label className="form-check-label"
                                                           htmlFor="flexRadioDefault2">
                                                        회 사용
                                                    </label>
                                                    <input type="text" className="form-control mx-1" value="" style={{
                                                        width: "50px",
                                                        height: "30px"
                                                    }}/>
                                                    <label className="form-check-label"
                                                           htmlFor="flexRadioDefault2">
                                                        회
                                                    </label>
                                                </div>
                                                <div className="form-check me-2" style={{
                                                    display: "flex"
                                                }}>
                                                    <input className="form-check-input me-1" type="radio"
                                                           name="flexRadioDefault3" id="flexRadio5"
                                                    />
                                                    <label className="form-check-label"
                                                           htmlFor="flexRadioDefault2">
                                                        기간사용
                                                    </label>
                                                    <input type="date" className="form-control mx-1" value="" style={{
                                                        width: "150px",
                                                        height: "30px"
                                                    }}/>
                                                    <input type="time" className="form-control mx-1" value="" style={{
                                                        width: "150px",
                                                        height: "30px"
                                                    }}/>
                                                    <span className="mx-1">~</span>
                                                    <input type="date" className="form-control mx-1" value="" style={{
                                                        width: "150px",
                                                        height: "30px"
                                                    }}/>
                                                    <input type="time" className="form-control mx-1" value="" style={{
                                                        width: "150px",
                                                        height: "30px"
                                                    }}/>
                                                </div>

                                                <label className="form-label ms-2">충전</label>
                                                <input type="text" className="form-control ms-1" value="0" style={{
                                                    width: "80px",
                                                    height: "30px"
                                                }} />
                                                <span className="mx-1">%</span>
                                            </div>
                                        </div>
                                        <div className="row border">
                                            <div className="col-1 bg-light">
                                                개별 전용계좌
                                            </div>
                                            <div className="col" style={{
                                                display: "flex"
                                            }}>
                                                <div className="form-check me-1">
                                                    <input className="form-check-input" type="radio"
                                                           name="flexRadioDefault5" id="flexRadio11" />
                                                    <label className="form-check-label"
                                                           htmlFor="flexRadioDefault1">
                                                        미사용
                                                    </label>
                                                </div>
                                                <div className="form-check me-2" style={{
                                                    display: "flex"
                                                }}>
                                                    <input className="form-check-input me-1" type="radio"
                                                           name="flexRadioDefault5" id="flexRadio12"
                                                           checked />
                                                    <label className="form-check-label"
                                                           htmlFor="flexRadioDefault2">
                                                        사용
                                                    </label>
                                                </div>

                                                <label className="form-label ms-5">예금주</label>
                                                <input type="text" className="form-control ms-1" value="홍길동" style={{
                                                    width: "180px",
                                                    height: "30px"
                                                }} />
                                                <label className="form-label ms-5">은행명</label>
                                                <input type="text" className="form-control ms-1" value="하나은행" style={{
                                                    width: "180px",
                                                    height: "30px"
                                                }} />
                                                <label className="form-label ms-5">계좌번호</label>
                                                <input type="text" className="form-control ms-1" value="123451235123213" style={{
                                                    width: "250px",
                                                    height: "30px"
                                                }} />

                                            </div>
                                        </div>
                                        <div className="row border">
                                            <div className="col-1 bg-light">
                                                추천인
                                            </div>
                                            <div className="col" style={{
                                                display: "flex"
                                            }}>
                                                <input readOnly="true" type="text" className="form-control" value="118" style={{
                                                    width: "80px",
                                                    height: "30px"
                                                }}/>
                                                <p className="text-primary ms-1">[관리]</p>

                                                <div className="form-check mx-2">
                                                    <input className="form-check-input" type="radio"
                                                           name="flexRadio44" id="flexRad11" />
                                                    <label className="form-check-label"
                                                           htmlFor="flexRad11">
                                                        가능
                                                    </label>
                                                </div>
                                                <div className="form-check me-2" style={{
                                                    display: "flex"
                                                }}>
                                                    <input className="form-check-input me-1" type="radio"
                                                           name="flexRadio44" id="flexRad22"
                                                           checked />
                                                    <label className="form-check-label"
                                                           htmlFor="flexRad22">
                                                        불가능
                                                    </label>
                                                </div>

                                                <span className="mx-3">|</span>

                                                <div className="form-check mx-2">
                                                    <input className="form-check-input" type="radio"
                                                           name="flexRadio45" id="flexRad3" />
                                                    <label className="form-check-label"
                                                           htmlFor="flexRad3">
                                                        낙첨수수료
                                                    </label>
                                                </div>
                                                <span className="mx-3">|</span>
                                                <div className="form-check me-2" style={{
                                                    display: "flex"
                                                }}>
                                                    <input className="form-check-input me-1" type="radio"
                                                           name="flexRadio45" id="flexRad4"/>
                                                    <label className="form-check-label"
                                                           htmlFor="flexRad4">
                                                        배팅수수료
                                                    </label>
                                                    <label className="form-label ms-2">스포츠</label>
                                                    <input type="text" className="form-control ms-1" value="0" style={{
                                                        width: "80px",
                                                        height: "30px"
                                                    }} />
                                                    <span className="mx-1">%</span>
                                                    <label className="form-label ms-2">미니게임</label>
                                                    <input type="text" className="form-control ms-1" value="0" style={{
                                                        width: "80px",
                                                        height: "30px"
                                                    }} />
                                                    <span className="mx-1">%</span>
                                                </div>
                                                <span className="mx-3">|</span>
                                                <div className="form-check me-2" style={{
                                                    display: "flex"
                                                }}>
                                                    <input className="form-check-input me-1" type="radio"
                                                           name="flexRadio45" id="flexRad5"/>
                                                    <label className="form-check-label"
                                                           htmlFor="flexRad5">
                                                        충/환 죽장
                                                    </label>
                                                    <input type="text" className="form-control ms-1" value="0" style={{
                                                        width: "80px",
                                                        height: "30px"
                                                    }} />
                                                    <span className="mx-1">%</span>
                                                </div>
                                                <span className="mx-3">|</span>

                                                <div className="form-check me-2" style={{
                                                    display: "flex"
                                                }}>
                                                    <input className="form-check-input me-1" type="radio"
                                                           name="flexRadio45" id="flexRad6"/>
                                                    <label className="form-check-label"
                                                           htmlFor="flexRad6">
                                                        보유+P
                                                    </label>
                                                </div>

                                                <div className="form-check me-2" style={{
                                                    display: "flex"
                                                }}>
                                                    <input className="form-check-input me-1" type="radio"
                                                           name="flexRadio45" id="flexRad7"/>
                                                    <label className="form-check-label"
                                                           htmlFor="flexRad7">
                                                        보유+P+충/환
                                                    </label>
                                                </div>

                                                <div className="form-check me-2" style={{
                                                    display: "flex"
                                                }}>
                                                    <input className="form-check-input me-1" type="radio"
                                                           name="flexRadio45" id="flexRad8"/>
                                                    <label className="form-check-label"
                                                           htmlFor="flexRad8">
                                                        보유+P+충/환+배팅
                                                    </label>
                                                </div>


                                            </div>
                                        </div>
                                        <div className="row border">
                                            <div className="col-1 bg-light">
                                                관리자
                                            </div>
                                            <div className="col" style={{
                                                display: "flex"
                                            }}>
                                                <label className="form-label">비밀번호</label>
                                                <input type="text" className="form-control ms-1" value="" style={{
                                                    width: "250px",
                                                    height: "30px"
                                                }} />
                                                <button type="button" className="btn btn-primary btn-sm ms-1" style={{
                                                    width: "100px",
                                                    height: "30px"
                                                }}>저장</button>

                                                <label className="form-label ms-3">마지막저장</label>
                                                <input readOnly="true" type="text" className="form-control ms-1" value="2021-10-11 15:38:00" style={{
                                                    width: "250px",
                                                    height: "30px"
                                                }} />

                                                <label className="form-label ms-3">관리자</label>
                                                <input readOnly="true" type="text" className="form-control ms-1" value="admin" style={{
                                                    width: "250px",
                                                    height: "30px"
                                                }} />

                                                <span className="text-danger mx-3">[수정로그]</span>

                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <table className="table table-bordered table-sm text-center align-middle">
                                                    <thead className="table-secondary">
                                                    <tr>
                                                        <td colSpan="4">최근충전</td>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr>
                                                        <td>요청시간</td>
                                                        <td>처리시간</td>
                                                        <td>금액</td>
                                                        <td>상태</td>
                                                    </tr>
                                                    <tr>
                                                        <td>2021-10-12 10:00:15</td>
                                                        <td>2021-10-12 10:00:30</td>
                                                        <td>1,000,000</td>
                                                        <td>승인</td>
                                                    </tr>
                                                    <tr>
                                                        <td>2021-10-12 10:00:15</td>
                                                        <td>2021-10-12 10:00:30</td>
                                                        <td>1,000,000</td>
                                                        <td>승인</td>
                                                    </tr>
                                                    <tr>
                                                        <td>2021-10-12 10:00:15</td>
                                                        <td>2021-10-12 10:00:30</td>
                                                        <td>1,000,000</td>
                                                        <td>승인</td>
                                                    </tr>
                                                    <tr>
                                                        <td>2021-10-12 10:00:15</td>
                                                        <td>2021-10-12 10:00:30</td>
                                                        <td>1,000,000</td>
                                                        <td>승인</td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="col">
                                                <table className="table table-bordered table-sm text-center align-middle">
                                                    <thead className="table-secondary">
                                                    <tr>
                                                        <td colSpan="4">최근환전</td>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr>
                                                        <td>요청시간</td>
                                                        <td>처리시간</td>
                                                        <td>금액</td>
                                                        <td>상태</td>
                                                    </tr>
                                                    <tr>
                                                        <td>2021-10-12 10:00:15</td>
                                                        <td>2021-10-12 10:00:30</td>
                                                        <td>1,000,000</td>
                                                        <td>승인</td>
                                                    </tr>
                                                    <tr>
                                                        <td>2021-10-12 10:00:15</td>
                                                        <td>2021-10-12 10:00:30</td>
                                                        <td>1,000,000</td>
                                                        <td>승인</td>
                                                    </tr>
                                                    <tr>
                                                        <td>2021-10-12 10:00:15</td>
                                                        <td>2021-10-12 10:00:30</td>
                                                        <td>1,000,000</td>
                                                        <td>승인</td>
                                                    </tr>
                                                    <tr>
                                                        <td>2021-10-12 10:00:15</td>
                                                        <td>2021-10-12 10:00:30</td>
                                                        <td>1,000,000</td>
                                                        <td>승인</td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="col">
                                                <table className="table table-bordered table-sm text-center align-middle">
                                                    <thead className="table-secondary">
                                                    <tr>
                                                        <td colSpan="4">로그인내역</td>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr>
                                                        <td>로그인시간</td>
                                                        <td>도메인</td>
                                                        <td>IP</td>
                                                        <td>타입</td>
                                                    </tr>
                                                    <tr>
                                                        <td>2021-10-12 10:00:15</td>
                                                        <td>asfsfsdf.com</td>
                                                        <td>127.0.0.1 국기</td>
                                                        <td>PC</td>
                                                    </tr>
                                                    <tr>
                                                        <td>2021-10-12 10:00:15</td>
                                                        <td>asfsfsdf.com</td>
                                                        <td>127.0.0.1 국기</td>
                                                        <td>M-A</td>
                                                    </tr>
                                                    <tr>
                                                        <td>2021-10-12 10:00:15</td>
                                                        <td>asfsfsdf.com</td>
                                                        <td>127.0.0.1 국기</td>
                                                        <td>M-I</td>
                                                    </tr>
                                                    <tr>
                                                        <td>2021-10-12 10:00:15</td>
                                                        <td>asfsfsdf.com</td>
                                                        <td>127.0.0.1 국기</td>
                                                        <td>M-A</td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>하나은행</td>
                                <td>123****27000</td>
                                <td>
                                    <Link className="nav-link" to="#collapse1" data-bs-toggle="collapse"
                                          aria-expanded="false" aria-controls="collapse1">
                                        테스트유저
                                    </Link>
                                </td>
                                <td>110,000</td>
                                <td>2022-03-20 23:17:59</td>
                                <td>2022-03-20 23:17:59</td>
                                <td>admin</td>
                            </tr>
                            </tbody>

                        </table>

                    </div>
                </div>
            </div>
        </div>

    )
}

export default DepositMSG;