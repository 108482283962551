import React from 'react';
import WithdrawCompleteListContainer from "../../containers/money/WithdrawCompleteListContainer";
const WithdrawCompletePage = () => {
    return (
        <div>
            <h1>환전완료</h1>
            <WithdrawCompleteListContainer />
        </div>

    )
}

export default WithdrawCompletePage;