import React, {useEffect, useState} from 'react';
import PartnerAddComponent from "../../components/PartnerAddComponent";
import AgentListComponent from "../../components/agent/AgentListComponent";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {getAgentListReducer,setIsOpen,setQuery,setPartnerNos} from "../../redux/reducers/agent/AgentListReducer";
import Pagination from "../../components/common/Pagination";

const AgentListContainer = () => {

    const dispatch = useDispatch()
    const initialState = useSelector((state) => state.AgentListReducer)
    const userInfo = useSelector((state) => state.userInfo);

    const getAllList = () => {
        if(initialState.totalList !== null) {
            dispatch(getAgentListReducer({
                page: initialState.page,
                pageSize: initialState.pageSize,
                order: initialState.order,
                query: initialState.query,
            }))
        }
    }
    useEffect(getAllList, [dispatch])

    const handleClickOpen = () => {
        dispatch(setIsOpen(true))
    }
    const handleClickClose = () => {
        dispatch(setIsOpen(false))
        getAllList()
    }

    const queryHandler = (e) => {
        dispatch(setQuery(e.currentTarget.value))
    }
    const inputPress = (e) => {
        if(e.key === "Enter") {
            searchHandler(initialState.query)
        }
    }
    const searchHandler = (query) => {
        dispatch(getAgentListReducer({
            page: 1,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: initialState.query,
        }))
    }

    const allCheckHandler = (e) => {
        if(initialState.partnerList.length > 0 && e.currentTarget.value) {
            const checks = [];
            let total = 0;
            for(let i = 0; i < initialState.partnerList.length; i++) {
                checks.push(initialState.partnerList[i].no)
            }
            dispatch(setPartnerNos(e.target.checked? checks : []))
        }
    }

    const checkHandler = (checked, no) => {
        let partnerNos2 = []
        if(checked) {
            partnerNos2 = [...initialState.partnerNos, no]
            dispatch(setPartnerNos([...initialState.partnerNos, no]))
        }
        else {
            partnerNos2 = initialState.partnerNos.filter((id) => id !== no)
            dispatch(setPartnerNos(initialState.partnerNos.filter((id) => id !== no)))
        }
        let total = 0;
        for(let i = 0; i < partnerNos2.length; i++) {
            total += initialState.partnerList.filter((id) => id.no === partnerNos2[i])[0]
        }
        //console.log(total)
    }

    const partnerDelete = (e) => {
        if(initialState.partnerNos.length > 0) {
            axios.post('/admin/partner/delete', {
                partnerNos: initialState.partnerNos
            }).then((res) => {
                alert(res.data.isDeleted? "삭제완료" : "삭제실패")
                getAllList()
            })
        }
        else {
            alert('선택해주세요')
        }
    }

    const handlePageChange = (page) => {
        dispatch(getAgentListReducer({
            page: page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: initialState.query,
        }))
    }
    const partnerTypeBgColor = (state) => {
        switch(state) {
            case 0 :
                return "bgComplete"
            case 1 :
                return "bgRequest"
            case 2 :
                return "bgWait"
            case 3 :
                return "bgCancel"
            default : return state
        }
    }


    return (
        <div className="m-2">

            <div className="card-body">
                <div>
                    <div className="container-fluid">
                        <div className="row bg-light align-items-center" >

                            <div className="col align-items-center justify-content-end d-flex" style={{
                                height: "50px"
                            }}>
                                <div className="input-group align-items-end" style={{
                                    width: "250px",
                                    height: "40px",
                                }}>
                                    <input type="text" className="form-control" placeholder=""
                                           aria-describedby="button-addon2" value={initialState.query} onChange={queryHandler} onKeyDown={inputPress}/>
                                    <button className="btn btn-outline-secondary" type="button" id="button-addon2"
                                            onClick={() => searchHandler(initialState.query)}>검색
                                    </button>
                                </div>

                                <button className="btn btn-outline-secondary" type="button"
                                        id="button-addon2">엑셀출력
                                </button>
                            </div>

                        </div>
                        <div className="my-2 d-flex justify-content-between">

                            <span>(총파트너 : {initialState.totalCount})</span>

                            <div className="justify-content-end">
                                <span className="me-4">수익배당 : 0 ㅣ 배팅배당 : 0 </span>

                                <button className="btn btn-outline-secondary" type="button" style={{
                                    width: "150px", height: "40px",}} onClick={handleClickOpen}>가맹점등록
                                </button>
                                {initialState.isOpen && <PartnerAddComponent open={initialState.isOpen} onClose={handleClickClose} />}

                                {userInfo.level ===2?
                                    <button className="btn btn-outline-danger ms-4" type="button"
                                            id="button-addon2" onClick={partnerDelete}>선택 삭제
                                    </button>
                                    :<></>}
                            </div>

                        </div>

                    </div>
                    <AgentListComponent initialState = {initialState}/>
                    <div className="d-flex justify-content-center"> {/* text-center 클래스 추가 */}
                        <Pagination totalCount={initialState.totalCount} pageSize={initialState.pageSize} currentPage={initialState.page} onPageChange={handlePageChange} />
                    </div>
                </div>
            </div>

        </div>

    )
}

export default AgentListContainer;