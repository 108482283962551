import React from 'react';
import Switch from 'react-switch';
import {useDispatch} from "react-redux";
import {setLeagueSwitchStatesReducer, setLeagueListChange,setSelectedRow} from "../../redux/reducers/match/leagueApiSettingReducer";
import axios from "axios";

const LeagueListApiSettingComponent = ({initialState,getTeamInfo,query}) => {
    const dispatch = useDispatch()

    console.log('initialState.leagueList:', initialState.leagueList); // 이 부분에 console.log 추가
    const handleToggle = (code) => {
        dispatch(setLeagueSwitchStatesReducer(code))
    };

    const handleChangeNm = (e,index) => {
        const newValue = e.currentTarget.value;
        const updatedLeagueList = [...initialState.leagueList]; // Create a copy of the array
        updatedLeagueList[index] = { ...updatedLeagueList[index], league_kor_nm: newValue };
        dispatch(setLeagueListChange(updatedLeagueList)); // Update the state with the new array
    }

    const handleUpdate = (leagueList) => {
        if (window.confirm("변경 하시겠습니까?")) {
            const no = leagueList.no;
            const leagueKorNm = leagueList.league_kor_nm;
            const useYn = leagueList.use_yn;

            axios.post("/admin/leagueApiSettingInfoApi/update", {
                no : no,
                leagueKorNm : leagueKorNm,
                useYn : useYn,
            }).then((res) => {
                if(res.data.isLeagueUpdated){
                    alert("변경되었습니다.");
                    query(initialState.leagueUseYn);
                }else{
                    alert("변경실패.");
                }
            })
        }
    }

    const changeRowColor = (index) => {
        dispatch(setSelectedRow(index));
    }
    return (
        <div className="m-2">
            <div className="card-body">
                <div>
                    <table className="table table-bordered table-sm text-center align-middle">
                        <thead className="table-dark">
                        <tr>
                            <th style={{width: "10%"}}>코드</th>
                            <th style={{width: "45%"}}>리그명</th>
                            <th style={{width: "5%"}}>영문</th>
                            <th style={{width: "15%"}}>사용여부</th>
                            <th style={{width: "25%"}}>-</th>
                        </tr>
                        </thead>
                        {initialState.leagueList && initialState.leagueList.length > 0 ?
                            initialState.leagueList.map((List, index) => (
                        <tbody key={List.no} style={{ backgroundColor: index === initialState.selectedRow ? '#ead980' : 'transparent' }}>
                        <tr>
                            <td>{List.league_cd}</td>
                            <td style={{ textAlign: 'center',paddingLeft: '10px' }}>
                                <img src={process.env.PUBLIC_URL +List.league_img_file_path} /><br />
                                <input type="text" style={{ width: '100%' }} value={List.league_kor_nm} onChange={(e) => handleChangeNm(e, index)}/></td>
                            <td>{List.league_eng_nm}</td>
                            <td>
                                <Switch
                                    onChange={() => handleToggle(List.no)}
                                    checked={List.use_yn ==='Y'}
                                    onColor="#2196F3"
                                />
                            </td>
                            <td> <button className="my-auto btn btn-sm btn-primary w-50" onClick={() => handleUpdate(List)}>변경</button><br/>
                                <button className="btn btn-sm btn-danger w-50" onClick={() => {getTeamInfo(List.no); changeRowColor(index);}}>팀정보</button>
                            </td>
                        </tr>
                        </tbody>
                            ))
                            :
                            <tbody>
                            <tr className="table-secondary">
                                <td colSpan= "8"><font size= '3'> 조회된 데이터가 없습니다.</font></td>
                            </tr>
                            </tbody>
                        }
                    </table>
                </div>
            </div>
        </div>
    );
};

export default LeagueListApiSettingComponent;