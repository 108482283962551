import {useDispatch, useSelector} from "react-redux"
import React, {useEffect, useState} from "react"
import PointAddComponent from "../../components/PointAddComponent";
import PointLogMngListComponent from "../../components/money/PointLogMngListComponent";
import Pagination from "../../components/common/Pagination";
import {getPointLogMngReducer} from "../../redux/reducers/money/PointLogMngReducer";
import moment from "moment/moment";
import {NumericFormat} from "react-number-format";
import * as XLSX from 'xlsx';
import {getMoneyLogMngReducer} from "../../redux/reducers/money/MoneyLogMngReducer";


const PointLogMngListContainer = () => {
    const dispatch = useDispatch()
    const initialState = useSelector((state) => state.PointLogMngReducer)
    const [query, setQuery] = useState("");
    const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().add(1, "day").format('YYYY-MM-DD'));

    useEffect(() => {
        dispatch(getPointLogMngReducer({
            page:initialState.page,
            pageSize: initialState.pageSize,
            query: query,
            startDate: startDate,
            endDate: endDate,
        }))
    }, [dispatch])

    const startDateHandler = (e) => {
        setStartDate(e.currentTarget.value)
    }
    const endDateHandler = (e) => {
        setEndDate(e.currentTarget.value)
    }

    const queryHandler = (e) => {
        setQuery(e.target.value)
    }
    const inputPress = (e) => {
        if(e.key === "Enter") {
            searchHandler(query, startDate, endDate)
        }
    }

    const searchHandler = (query, startDate, endDate) => {
        dispatch(getPointLogMngReducer({
            page:1,
            pageSize: initialState.pageSize,
            query:query,
            startDate:startDate,
            endDate:endDate
        }))
    }

    const handlePageChange = (page) => {
        dispatch(getPointLogMngReducer({
            page: page,
            pageSize: initialState.pageSize,
            startDate:startDate,
            endDate:endDate,
            query: query,
        }))
    }

    const allList = () => {
        dispatch(getPointLogMngReducer({
            page: initialState.page,
            pageSize: initialState.pageSize,
            query: query,
            startDate: startDate,
            endDate: endDate,
        }))
    }

    const exportExcel = ()=>{
        console.log("##exportExcel 수정##",initialState.totalList);

        let excelList = [];
        // JSON 형태의 값들을 for 문을 통해 배열에 추가합니다.

        for (let i = 0; i < initialState.totalList.length; i++) {
            if (initialState.totalList) {
                let jsonObject = {} // JSON 객체를 생성합니다.
                let jsonUser = {}
                let jsonAdmin = {}
                if(initialState.totalList[i].user){
                    jsonUser = {
                        회원id: initialState.totalList[i].user.id ,
                        닉네임: initialState.totalList[i].user.nickname
                    }
                }
                if(initialState.totalList[i].admin){
                    jsonAdmin = {
                        관리자: initialState.totalList[i].admin.id
                    }
                }
                jsonObject = { // JSON 객체를 생성합니다.
                    ...jsonUser,
                    ...jsonAdmin,
                    증가:'',
                    감소:'',
                    설명:initialState.totalList[i].cont,
                    잔여포인트:initialState.totalList ? initialState.totalList[i].point : '',
                    시간:initialState.totalList ? initialState.totalList[i].createdAt : '',
                }
                excelList.push(jsonObject); // 배열에 JSON 객체를 추가합니다.
            }
        }
        console.log(excelList);

        const workSheet = XLSX.utils.json_to_sheet(excelList);
        const workBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, workSheet, '포인트관리 리스트');
        XLSX.writeFile(workBook, '포인트관리 리스트.xlsx');
    }
    return <>
        <div className="m-2">

            <div className="card-body">
                <div>
                    <div className="container-fluid">
                        <div className="row bg-light align-items-center" >
                            <div className="col align-items-center justify-content-end d-flex" style={{
                                height: "50px"}}>

                                <input type="date" className="form-control mx-1" value={startDate} onChange={startDateHandler} style={{
                                    width: "150px",
                                    height: "40px"
                                }}/>
                                <span className="mx-1">~</span>
                                <input type="date" className="form-control mx-1" value={endDate} onChange={endDateHandler} style={{
                                    width: "150px",
                                    height: "40px"
                                }}/>

                                <div className="input-group align-items-end" style={{
                                    width: "20%",
                                    height: "40px",
                                }}>
                                    <input type="text" className="form-control" placeholder="검색"
                                           id="search_edit"
                                           value={query} onChange={queryHandler} onKeyDown={inputPress}/>
                                    <button className="btn btn-outline-secondary" type="button"
                                            id="button-addon2" onClick={() => searchHandler(query, startDate, endDate)}>검색
                                    </button>
                                    <button type="button" className="btn btn-outline-secondary mx-1" onClick={exportExcel} >엑셀출력</button>
                                </div>
                            <select className="form-select mx-1" style={{
                                width: "120px",
                                height: "40px"
                            }}
                                    value={initialState.pageSize === "999" ? "모두" : initialState.pageSize}
                                    onChange={(e) => {
                                        dispatch(getPointLogMngReducer({
                                            page:1,
                                            pageSize: parseInt(e.target.value),
                                            order: initialState.order,
                                            query: query,
                                            startDate: startDate,
                                            endDate: endDate,
                                        }))
                                    }}
                            >
                                <option value={20}>20개씩</option>
                                <option value={50}>50개씩</option>
                                <option value={100}>100개씩</option>
                                <option value={200}>200개씩</option>
                                <option value={999}>모두</option>
                            </select>
                            <select className="form-select"  style={{
                                width: "120px",
                                height: "40px"
                            }}
                                    value={initialState.order === 0? 0 : initialState.order}
                                    onChange={(e) => {
                                        dispatch(getPointLogMngReducer({
                                            page:1,
                                            pageSize: initialState.pageSize,
                                            order: parseInt(e.target.value),
                                            query: query,
                                            startDate: startDate,
                                            endDate: endDate,
                                        }))
                                    }}
                            >
                                <option value={0}>시간순</option>
                            </select>
                            </div>
                        </div>

                        <div className="my-2 d-flex">
                            <span onClick={allList} style={{cursor: "pointer"}}>모두 보기</span>
                        </div>
                        <div className="my-2 d-flex">
                            <div className="col my-auto d-flex">
                                <span className="text-danger">선택포인트 :
                                    <NumericFormat className="ms-1" value={initialState.totalSelectedPoint} displayType={'text'} thousandSeparator={true}/>
                                </span>
                            </div>

                            <div className="col justify-content-end d-flex">
                                <button type="button" className="btn btn-outline-secondary" data-bs-toggle="modal" data-bs-target="#move_point" style={{width: "150px"}}>
                                    포인트관리
                                </button>
                                <PointAddComponent/>
                            </div>
                        </div>

                    </div>
                    <PointLogMngListComponent initialState={initialState} />
                    <Pagination totalCount={initialState.totalCount} pageSize={initialState.pageSize} currentPage={initialState.page} onPageChange={handlePageChange}/>
                </div>
            </div>
        </div>
    </>





}
export default PointLogMngListContainer