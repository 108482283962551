import {call, put} from "redux-saga/effects"
import {setUserInfo, failedLogin} from "../reducers/userInfo"
import {requestLogin} from "../../lib/api";

export function* loginSaga(action) {
    try {
        const res = yield call(requestLogin, action.payload.id, action.payload.pw)
        yield put(setUserInfo(res.data))
    } catch(error) {
        console.log(error)
        yield failedLogin(error);
    }
}

export function* setUserInfoSaga() {

}