import React from 'react';
import SportsMarketListSettingPageContainer from "../../containers/match/SportsMarketListSettingPageContainer";


const SportsMarketListSettingPage = () => {
    return (
        <div>
            <h1>스포츠 종목별 마켓설정</h1>
            <SportsMarketListSettingPageContainer />
        </div>
    );
};

export default SportsMarketListSettingPage;