import {call, put} from "redux-saga/effects";
import {setNationApiSettingInfoListReducer,failedGetNationApiSettingInfoListReducer}  from "../../reducers/match/nationApiSettingReducer";
import {fetchNationApiSettingInfoList} from "../../../lib/api";

//국가 API설정리스트
export function* getNationApiSettingInfoListSaga(action) {
    try{
        console.log("##getNationApiSettingInfoListSaga#")
        const res = yield call(fetchNationApiSettingInfoList,action.payload.nationPage,action.payload.nationPageSize,action.payload.sportsNo,action.payload.nationUseYn,action.payload.nationNm);
        yield put(setNationApiSettingInfoListReducer(res.data))
    }
    catch (error) {
        console.log(error)
        yield put(failedGetNationApiSettingInfoListReducer(error))
    }
}