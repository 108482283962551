import {call, put} from "redux-saga/effects";
import {fetchAdminFailLog} from "../../../lib/api";
import {failedAdminFailLogReducer, setAdminFailLogReducer} from "../../reducers/user/AdminFailLogReducer";

export function* getAdminFailLogSaga(action) {
    console.log("##getAdminFailLogSaga##");
    try{
        const res = yield call(fetchAdminFailLog,action.payload.page,action.payload.pageSize,action.payload.order,action.payload.startDate,action.payload.endDate,action.payload.query,action.payload.device);
        yield put(setAdminFailLogReducer(res.data))
    }
    catch (error) {
        console.log(error)
        yield put(failedAdminFailLogReducer(error))
    }
}
