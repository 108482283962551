import React, {useState} from "react";
import axios from "axios";
import {useSelector} from "react-redux";
import {NumericFormat} from "react-number-format";

const MoneyAddComponent = () => {

    const [id, setId] = useState([]);
    const [movedMoney, setMoveMoney] = useState();
    const [moneyType, setMoneyType] = useState(0);
    const [cont, setCont] = useState("");
    const [informationList, setInformationList] = useState([])

    const userInfo = useSelector((state) => state.userInfo);

    const idChangeHandler = (e) => {
        setId(e.currentTarget.value)
        userInformation(e.currentTarget.value)
    }
    const moneyTypeChangeHandler = (type) => {
        setMoneyType(type)
    }
    const movedMoneyChangeHandler = (e) => {
        setMoveMoney(e.currentTarget.value)
    }

    const contChangeHandler = (e) => {
        setCont(e.currentTarget.value)
    }

    const userInformation = (id) => {
        axios.post('/admin/money_log/information', {
            id: id
        }).then((res) =>{
            if (res.data.information !== null) {
                setInformationList(res.data.information)
            }
        })
    }
    const handleRegister = () => {
        axios.post('/admin/money_log/insert',{
            adminNo: userInfo.userNo,
            id: id,
            money_type: moneyType,
            moved_money: movedMoney,
            cont: cont
        }).then((res) => {
            if(res.data.isInserted) {
                alert('완료')
            }
            else {
                alert('실패')
            }
        })
    }
    return (
        <div className="modal fade" id="move_money" tabIndex="-1" aria-hidden="true" >
            <div className="modal-dialog" >
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" >머니관리</h1>
                    </div>
                    <div className="modal-body">
                        <div className="mb-2">회원정보</div>
                        <div className="mb-2 d-flex">
                            닉네임 : <b className="me-2">{informationList.nickname}</b>
                            보유머니 : <b className="me-2"><NumericFormat value={informationList.money} displayType={'text'} thousandSeparator={true} /></b>
                            보유포인트 : <b className="me-2"><NumericFormat value={informationList.point} displayType={'text'} thousandSeparator={true} /></b>
                        </div>
                        <div className="mb-4  borderBottom-1 d-flex">
                            예금주 : <b className="me-2">{informationList.name}</b>
                            은행 : <b className="me-2">{informationList.bank}</b>
                            계좌번호 : <b className="me-2">{informationList.withdraw_account}</b>
                        </div>
                        <div className="mb-2 d-flex">
                            <label className="col-form-label" style={{width: "10%"}}>ID</label>
                            <input className="form-control" maxLength="10" type="text" placeholder="아이디" value={id} onChange={idChangeHandler}  />
                        </div>
                        <div className="mb-2 d-flex">
                            <label className="col-form-label" style={{width: "10%"}}>방식</label>
                            <div className="form-check form-check-inline my-auto">
                                <input className="form-check-input" type="radio" name="moneyType" id="inlineRadio1" value={moneyType}
                                       onChange={() => moneyTypeChangeHandler(0)} />
                                    <label className="form-check-label" htmlFor="inlineRadio1">머니지급</label>
                            </div>
                            <div className="form-check form-check-inline my-auto">
                                <input className="form-check-input"  type="radio" name="moneyType" id="inlineRadio2" value={moneyType}
                                       onChange={() => moneyTypeChangeHandler(1)} />
                                    <label className="form-check-label" htmlFor="inlineRadio2">머니차감</label>
                            </div>
                        </div>
                        <div className="mb-2 d-flex">
                            <label className="col-form-label" style={{width: "10%"}}>금액</label>
                            <input className="form-control" maxLength="12" type="text" placeholder="금액" value={movedMoney} onChange={movedMoneyChangeHandler}  />
                        </div>
                        <div className="mb-2 d-flex">
                            <label className="col-form-label" style={{width: "10%"}}>사유</label>
                            <input className="form-control" maxLength="200" type="text" placeholder="사유" value={cont} onChange={contChangeHandler}  />
                        </div>

                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">취소</button>
                        <button type="button" className="btn btn-primary" onClick={handleRegister}>등록</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MoneyAddComponent;