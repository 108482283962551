import {call, put} from "redux-saga/effects";
import {fetchHome, fetchHomeTable} from "../../../lib/api";
import {failedGetHomeStats, failedGetHomeTableStats, setHomeStats, setHomeTableStats} from "../../reducers/home/HomeReducer";

export function* getHomeStatsSaga() {
    console.log("##getHomeStatsSaga##");
    try{
        const res = yield call(fetchHome);
        yield put(setHomeStats(res.data))
    }
    catch (error) {
        console.log(error)
        yield put(failedGetHomeStats(error))
    }
}
export function* getHomeTableStatsSaga(action) {
    console.log("##getHomeTableStatsSaga##");
    try{
        const res = yield call(fetchHomeTable,
            action.payload.startDate, action.payload.endDate
        );
        yield put(setHomeTableStats(res.data))
    }
    catch (error) {
        console.log(error)
        yield put(failedGetHomeTableStats(error))
    }
}