import {call, put, take,} from "redux-saga/effects";
import {fetchCloseBetting} from "../../../lib/api";
import {failedGetCloseBetting, setCloseBetting} from "../../reducers/betting/closeBetting";

export function* getCloseBettingSaga(action) {
    try{
        const res = yield call(fetchCloseBetting,
            action.payload.page, action.payload.pageSize, action.payload.query,
            action.payload.order,
        )
        yield put(setCloseBetting(res.data))
    }
    catch (error) {
        console.log(error)
        yield put(failedGetCloseBetting(error))
    }
}