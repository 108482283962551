import {call, put, take,} from "redux-saga/effects";
import {fetchAdmin, fetchMenuAthrSetting} from "../../../lib/api";
import {failedGetPageAphAthrReducer, setPageAphAthrReducer,setPageAphAthrMenuListSettingReducer,failedGetPageAphAthrMenuListSettingReducer} from "../../reducers/setting/PageAphAthrReducer";
export function* getPageAphAthrSaga(action) {
    try{
        const res = yield call(fetchAdmin,action.payload.page,action.payload.pageSize);
        yield put(setPageAphAthrReducer(res.data))
    }
    catch (error) {
        console.log(error)
        yield put(failedGetPageAphAthrReducer(error))
    }
}

export function* getPageAphAthrMenuListSettingSaga(action) {
    try{
        console.log("selectedAdminNo",action.payload.selectedAdminNo);
        const res = yield call(fetchMenuAthrSetting,action.payload.selectedAdminNo);
        yield put(setPageAphAthrMenuListSettingReducer(res.data))
    }
    catch (error) {
        console.log(error)
        yield put(failedGetPageAphAthrMenuListSettingReducer(error))
    }
}