import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getAdminFailLogReducer, setQuery, setStartDate,setEndDate} from "../../redux/reducers/user/AdminFailLogReducer";
import AdminFailLogComponent from "../../components/user/AdminFailLogComponent";
import Pagination from "../../components/common/Pagination";

const AdminFailLogContainer = () => {
    const dispatch = useDispatch()
    const initialState = useSelector((state) => state.AdminFailLogReducer)

    const userInfo = useSelector((state) => state.userInfo);
    const getFailList = () => {
        if(initialState.adminLoginFailList !== null) {
            dispatch(getAdminFailLogReducer({
                page: initialState.page,
                pageSize: initialState.pageSize,
                startDate: initialState.startDate,
                endDate: initialState.endDate,
                query: initialState.query,
                device: initialState.device,
            }))
        }
    }
    useEffect(getFailList, [dispatch])

    const startDateHandler = (e) => {
        dispatch(setStartDate(e.currentTarget.value))
    }
    const endDateHandler = (e) => {
        dispatch(setEndDate(e.currentTarget.value))
    }
    const queryHandler = (e) => {
        dispatch(setQuery(e.currentTarget.value))
    }
    const inputPress = (e) => {
        if (e.key === "Enter") {
            searchHandler(initialState.query, initialState.startDate, initialState.endDate, initialState.device)
        }
    }
    const searchHandler = (query, startDate, endDate, device) => {
        dispatch(getAdminFailLogReducer({
            page: 1,
            pageSize: initialState.pageSize,
            startDate: startDate,
            endDate: endDate,
            query: query,
            device: device,
        }))
    }

    const handlePageChange = (page) => {
        dispatch(getAdminFailLogReducer({
            page: page,
            pageSize: initialState.pageSize,
            startDate: initialState.startDate,
            endDate: initialState.endDate,
            query: initialState.query,
            device: initialState.device,
        }))
    }

    const allList = () => {
        dispatch(getAdminFailLogReducer({
            page: 1,
            pageSize: initialState.pageSize,
            startDate: initialState.startDate,
            endDate: initialState.endDate,
            query: initialState.query,
            device: 10,
        }))
    }

    return (
        <div className="m-2">
            <div className="card-body">
                <div>
                    <div className="container-fluid">
                        <div className="row bg-light align-items-center" style={{
                            height: "50px"
                        }}>
                            <div className="col align-items-center justify-content-end d-flex" style={{
                                height: "50px"
                            }}>
                                <input type="date" className="form-control mx-1" value={initialState.startDate}
                                       onChange={startDateHandler} style={{
                                    width: "150px",
                                    height: "40px"
                                }}/>
                                <span className="mx-1">~</span>
                                <input type="date" className="form-control mx-1" value={initialState.endDate}
                                       onChange={endDateHandler} style={{
                                    width: "150px",
                                    height: "40px"
                                }}/>

                                {/*</select>*/}
                                <div className="input-group align-items-end" style={{
                                    width: "30%",
                                }}>
                                    <input type="text" maxLength="30" className="form-control" placeholder="검색"
                                           aria-describedby="button-addon2" value={initialState.query} onChange={queryHandler}
                                           onKeyDown={inputPress}/>
                                    <button className="btn btn-outline-secondary" type="button" id="button-addon2"
                                            onClick={() => searchHandler(initialState.query, initialState.startDate, initialState.endDate, 10)}>검색
                                    </button>
                                    <button type="button" className="btn btn-outline-secondary mx-1" >엑셀출력</button>
                                </div>
                                <select className="form-select mx-1" style={{
                                    width: "120px",
                                    height: "40px"
                                }}
                                        value={initialState.pageSize === "999" ? "모두" : initialState.pageSize}
                                        onChange={(e) => {
                                            dispatch(getAdminFailLogReducer({
                                                page:1,
                                                pageSize: parseInt(e.target.value),
                                                order: initialState.order,
                                                startDate: initialState.startDate,
                                                endDate: initialState.endDate,
                                                query: initialState.query,
                                                device: initialState.device,

                                            }))
                                        }}
                                >
                                    <option value={20}>20개씩</option>
                                    <option value={50}>50개씩</option>
                                    <option value={100}>100개씩</option>
                                    <option value={200}>200개씩</option>
                                    <option value={999}>모두</option>
                                </select>
                                <select className="form-select"  style={{
                                    width: "120px",
                                    height: "40px"
                                }}
                                        value={initialState.order === 0? 0 : initialState.order}
                                        onChange={(e) => {
                                            dispatch(getAdminFailLogReducer({
                                                page:1,
                                                pageSize: initialState.pageSize,
                                                order: parseInt(e.target.value),
                                                startDate: initialState.startDate,
                                                endDate: initialState.endDate,
                                                query: initialState.query,
                                                device: initialState.device,

                                            }))
                                        }}
                                >
                                    <option value={0}>틀린시간순</option>
                                </select>
                            </div>

                        </div>
                        <div className="my-2 d-flex">
                            <span onClick={allList} style={{cursor: "pointer"}}>모두 보기</span>
                            <span className="mx-3" onClick={() => searchHandler(initialState.query, initialState.startDate, initialState.endDate, 0)}
                                  style={{cursor: "pointer"}}>PC</span>
                            <span className="me-3" onClick={() => searchHandler(initialState.query, initialState.startDate, initialState.endDate, 1)}
                                  style={{cursor: "pointer"}}>안드로이드</span>
                            <span onClick={() => searchHandler(initialState.query, initialState.startDate, initialState.endDate, 2)}
                                  style={{cursor: "pointer"}}>아이폰</span>
                        </div>
                        <div className="my-2 d-flex">
                            <span>(총접속오류 : {initialState.adminFailCount} 개)</span>
                        </div>
                    </div>
                   <AdminFailLogComponent initialState={initialState}/>
                    <Pagination totalCount={initialState.adminFailCount} pageSize={initialState.pageSize} currentPage={initialState.page} onPageChange={handlePageChange}/>
                </div>
            </div>
        </div>
    )
}

export default AdminFailLogContainer;