import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import JoinListComponent from "../../components/user/JoinListComponent";
import {getJoinListReducer,setYear,setMonth} from "../../redux/reducers/user/JoinListReducer";
import {getMoneyLogMngReducer} from "../../redux/reducers/money/MoneyLogMngReducer";
import moment from "moment";

const JoinListContainer = () => {
    const dispatch = useDispatch()
    const initialState = useSelector((state) => state.JoinListReducer)

    useEffect(() => {
        dispatch(getJoinListReducer({
            year: initialState.year,
            month: initialState.month,
            date: initialState.date,
        }))
    }, [dispatch])

    const searchHandler = () => {
        dispatch(getJoinListReducer({
            year: initialState.year,
            month: initialState.month,
            date: initialState.date,
            gb : 'M',
        }))
    }

    const yearSelect = [
        {value : moment().year(), name : moment().year()+"년"},
        {value : moment().add(1, 'year').year(), name : moment().add(1, 'year').year()+"년"},
        {value : moment().add(2, 'year').year(), name : moment().add(2, 'year').year()+"년"},
    ]
    const yearHandler = (e) => {
        dispatch(setYear(e.target.value));
    }
    const monthSelect = [
        {value : '01', name : "1월"},
        {value : '02', name : "2월"},
        {value : '03', name : "3월"},
        {value : '04', name : "4월"},
        {value : '05', name : "5월"},
        {value : '06', name : "6월"},
        {value : '07', name : "7월"},
        {value : '08', name : "8월"},
        {value : '09', name : "9월"},
        {value : '10', name : "10월"},
        {value : '11', name : "11월"},
        {value : '12', name : "12월"}
    ]
    const monthHandler = (e) => {
        dispatch(setMonth(e.target.value))
    }

    const dayList = (date) => {
        dispatch(getJoinListReducer({
            year: initialState.year,
            month: initialState.month,
            date: date,
        }))
    }

    return (
        <div className="m-2">

            <div className="card-body">
                <div className="container-fluid">
                    <div className="row bg-light align-items-center">
                        <div className="col align-items-center d-flex" style={{
                            height: "50px"
                        }}>가입통계
                        </div>

                    </div>
                    <div className="my-1 mt-1 align-items-center d-flex" >
                        <span onClick={() => dayList(0)} style={{cursor: "pointer"}}>일별</span>
                        <span className="mx-2">|</span>
                        <span onClick={() => dayList(1)} style={{cursor: "pointer"}}>월별</span>

                        <div className="col align-items-center justify-content-end d-flex" style={{
                            height: "50px"
                        }}>

                            <select className="form-select" onChange={yearHandler} value={initialState.year} style={{
                                width: "120px",
                                height: "40px"
                            }}>
                                {yearSelect.map((item, index) => (
                                    <option key={item.value} value={item.value}>{item.name}</option>
                                ))}
                            </select>

                            <select className="form-select" onChange={monthHandler} value={initialState.month} style={{
                                width: "100px",
                                height: "40px"
                            }}>
                                {monthSelect.map((item, index) => (
                                    <option key={item.value} value={item.value}>{item.name}</option>
                                ))}
                            </select>
                            <button className="btn btn-outline-secondary" type="button"
                                    id="button-addon2" onClick={() => searchHandler()}>검색
                            </button>
                        </div>
                    </div>

                </div>
                <JoinListComponent initialState = {initialState}/>
            </div>

        </div>

    )
}

export default JoinListContainer;