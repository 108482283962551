import React from 'react';
import DepositContainer from "../../containers/money/DepositListContainer";


const Deposit = () => {
    return (
        <div>
            <h1>충전관리</h1>
            <DepositContainer />
        </div>
    );
};

export default Deposit;