import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import moment from "moment/moment";
import {useSelector} from "react-redux";
import axios from "axios";




const Marquee = ({marquee, onRefresh}) => {
    const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().add(1, "day").format('YYYY-MM-DD'));
    const [marqueeList, setMarqueeList] = useState([]);
    const [marqueeNos, setMarqueeNos] = useState([]);
    const [title, setTitle] = useState([]);
    const [count, setCount] =useState(0);
    const [query, setQuery] = useState("");
    const [updates, setUpdates] = useState([]);
    const userInfo = useSelector((state) => state.userInfo);

    const initialState = useSelector((state) => state.marquee)

    const getMarqueeList = () => {
        axios.post('/admin/marquee/get_marquee_list', {
            adminNo: userInfo.userNo
        }).then((res) => {
            //console.log(res.data)
            if(res.data.marqueeList !== null) {
                const updates2 = []
                for(let i = 0; i < res.data.marqueeList.length; i++) {
                    const item = {
                        no: res.data.marqueeList[i].no,
                        title: res.data.marqueeList[i].title,
                    }
                    updates2.push(item)
                }
                setUpdates(updates2)
                setMarqueeList(res.data.marqueeList)
                setCount(res.data.count)
                setMarqueeNos([])
            }

        })
    }
    useEffect(getMarqueeList, [])

    const startDateHandler = (e) => {
        setStartDate(e.currentTarget.value)
    }
    const endDateHandler = (e) => {
        setEndDate(e.currentTarget.value)
    }
    const onTitleHandler = (e) =>{
        setTitle(e.currentTarget.value);
    }

    const allCheckHandler = (e) => {
        if(marqueeList.length > 0 && e.currentTarget.value) {
            const checks = [];
            for(let i = 0; i < marqueeList.length; i++) {
                checks.push(marqueeList[i].no)
                //console.log(marqueeList[i].no)
            }
            setMarqueeNos(e.target.checked? checks : [])
        }
    }
    const checkHandler = (checked, no) => {
        let marqueeNos2 = []
        if(checked) {
            marqueeNos2 = [...marqueeNos, no]
            setMarqueeNos([...marqueeNos, no])
        }
        else {
            marqueeNos2 = marqueeNos.filter((id) => id !== no)
            setMarqueeNos(marqueeNos.filter((id) => id !== no))
        }
        let totalCheck = 0;
        for(let i = 0; i < marqueeNos2.length; i++) {
            totalCheck += marqueeList.filter((id) => id.no === marqueeNos2[i])[0].no
        }
    }
    const updatesMarqueeTitleHandler = (value, no) =>{
        const updates2 = updates.filter((item) => item.no !== no)
        const beforeItem = updates.filter((item) => item.no === no)[0]
        beforeItem.title = value
        updates2.push(beforeItem)
        setUpdates(updates2)
        //console.log(updates2)
    }
    const marqueeInsert = (e) => {
        if (window.confirm("등록 하시겠습니까?")) {
            axios.post('/admin/marquee/insert', {
                title: title,
                adminNo: userInfo.userNo
            }).then((res) => {
                alert(res.data.isInserted? "등록완료" : "등록실패")
                getMarqueeList()
                onRefresh();
                setTitle([]);

                document.getElementById("button-addon3").click();
            })
        }

    }
    const marqueeUpdate = (no) => {
        const updatedItem = updates.filter((item) => item.no === no)[0]
        const marqueeUpdate = []
        marqueeUpdate.push(updatedItem)

        if (window.confirm("수정 하시겠습니까?")) {
            axios.post('/admin/marquee/update', {
                marqueeList: marqueeUpdate,
                adminNo: userInfo.userNo
            }).then((res) => {
                alert(res.data.success? "수정완료" : "수정실패")
                //getMarqueeList()
                onRefresh();
            })
        }


    }
    const marqueeDelete = (e) => {
        if(marqueeNos.length > 0) {
            if (window.confirm("삭제 하시겠습니까?")) {
                axios.post('/admin/marquee/delete', {
                    marqueeNos: marqueeNos
                }).then((res) => {
                    alert(res.data.isDeleted? "삭제완료" : "삭제실패")
                    //getMarqueeList()
                    onRefresh();
                })
            }



        }
        else {
            alert('선택해주세요')
        }
    }

    const queryHandler = (e) => {
        setQuery(e.target.value)
    }
    // const inputPress = (e) => {
    //     if(e.key === "Enter") {
    //         searchHandler(query, startDate, endDate)
    //     }
    // }
    //
    // const searchHandler = (query, startDate, endDate) => {
    //     axios.post('/admin/marquee/query', {
    //         start_date: startDate,
    //         end_date: endDate,
    //         query: query,
    //     }).then((res) => {
    //         if (res.data.marqueeList !== null) {
    //             setMarqueeList(res.data.marqueeList)
    //         }
    //     })
    // }

    return (
        <div className="m-2">

            <div className="card-body">
                <div>
                    <div className="container-fluid">

                        <div className="my-2 d-flex">
                            <span>(공지수 : {count} 개)</span>
                        </div>

                    </div>
                    <div>

                        <table className="table table-bordered table-sm text-center align-middle">
                            <thead className="table-dark">
                            <tr>
                                <th style={{width: "30px"}}>
                                    <input className="form-check-input" type="checkbox" checked={marqueeList.length === marqueeNos.length}
                                           onChange={allCheckHandler} id="flexCheckDefault"/>
                                </th>
                                <th style={{width: "4%"}}>번호</th>
                                <th>제목</th>
                                <th style={{width: "10%"}}>등록일시</th>
                                <th style={{width: "10%"}}>수정일시</th>
                                <th style={{width: "5%"}}>수정</th>
                                <th style={{width: "5%"}}>관리자</th>
                            </tr>
                            </thead>

                            {!marquee.length && (
                                <tbody key={marquee.no}>
                                <tr>
                                    <td colSpan="7">목록이 비어있습니다.</td>
                                </tr>
                                </tbody>
                            )}

                            {marquee.map((marquee, index) => (
                                <tbody key={marquee.no}>
                                <tr>
                                    <td>
                                        <input className="form-check-input" type="checkbox"
                                               checked={marqueeNos.filter((no) => no === marquee.no).length > 0}
                                               onChange={(e) => {checkHandler(e.currentTarget.checked, marquee.no)}}
                                        />
                                    </td>
                                    <td>{marquee.marquee_no}</td>
                                    <td>{marquee.title}</td>
                                    <td>{moment(marquee.createdAt).format("YYYY-MM-DD HH:mm:ss")}</td>
                                    <td>{moment(marquee.updatedAt).format("YYYY-MM-DD HH:mm:ss")}</td>
                                    <td>
                                        <button className="btn btn-outline-secondary ms-2" type="button"
                                                data-bs-toggle="collapse" data-bs-target={"#collapse2" + index}
                                                aria-expanded="false" aria-controls={"collapse2" + index} style={{
                                            width: "60px"
                                        }}>수정
                                        </button>
                                    </td>
                                    <td>{marquee.admin.id}</td>
                                </tr>

                                <tr className="collapse" id={"collapse2" + index}>
                                    <td colSpan="6">
                                        <div className="update">
                                            <div className="my-3" style={{
                                                display: "flex"
                                            }}>
                                                <label htmlFor="exampleFormControlInput1" className="form-label" style={{
                                                    width: "50px",
                                                    marginTop: "5px"
                                                }}>제목</label>
                                                <input type="text" className="form-control" maxLength="500" id="exampleFormControlInput1"
                                                       value={updates.filter((item) => item.no === marquee.no).length > 0? updates.filter((item) => item.no === marquee.no)[0].title : ""}
                                                       onChange={(e) => {updatesMarqueeTitleHandler(e.currentTarget.value, marquee.no)}}
                                                />
                                            </div>
                                            <div className="text-center">
                                                <button className="btn btn-outline-secondary" type="button"
                                                        id="button-addon2"
                                                        onClick={()=> {
                                                            marqueeUpdate(marquee.no)
                                                        }}
                                                >내용수정
                                                </button>
                                            </div>

                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            ))}
                        </table>
                    </div>

                    <div style={{
                        display: "flex"
                    }}>
                        <button className="btn btn-outline-secondary me-2" type="button"
                                data-bs-toggle="collapse" data-bs-target="#addCollapse"
                                aria-expanded="false" aria-controls="addCollapse" id="button-addon3">추가
                        </button>
                        <button className="btn btn-outline-secondary me-4" type="button"
                                id="button-addon2" onClick={marqueeDelete}>선택 삭제
                        </button>
                    </div>

                    <div id="addCollapse" className="add collapse">
                        <div className="my-3" style={{
                            display: "flex"
                        }}>
                            <label htmlFor="exampleFormControlInput1" className="form-label" style={{
                                width: "50px",
                                marginTop: "5px"
                            }}>제목</label>
                            <input type="text" className="form-control" maxLength="500" value={title} id="exampleFormControlInput1" onChange={onTitleHandler}/>
                        </div>
                        <div className="text-center">
                            <button className="btn btn-outline-secondary" type="button"
                                    id="button-addon2"
                                    onClick={marqueeInsert}
                            >내용등록
                            </button>
                        </div>
                    </div>

                </div>
            </div>

        </div>

    )
}

export default Marquee;