import {useDispatch, useSelector} from "react-redux"
import React, {useEffect, useState} from "react"
import {NumericFormat} from "react-number-format";
import moment from "moment/moment";
import {getWithdrawCompleteReducer} from "../../redux/reducers/money/WithdrawCompleteReducer";
import * as XLSX from "xlsx";
import axios from "axios";
import WithdrawCompleteListComponent from "../../components/money/WithdrawCompleteListComponent";
import Pagination from "../../components/common/Pagination";
import typeCase from "../../util/typeCase";

const WithdrawCompleteListContainer = () => {
    const dispatch = useDispatch()
    const initialState = useSelector((state) => state.WithdrawCompleteReducer)
    const userInfo = useSelector((state) => state.userInfo);
    const [query, setQuery] = useState("");
    const [startDate, setStartDate] = useState(moment().startOf("month").format("yyyy-MM-DD"));
    const [endDate, setEndDate] = useState(moment().endOf("month").format("yyyy-MM-DD"));

    useEffect(() => {
        console.log("#useEffect#",initialState.startDate)
        dispatch(getWithdrawCompleteReducer({
            page:initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: query,
            startDate: startDate,
            endDate: endDate,
        }))
    },[dispatch])

    const getAllList = () => {
        dispatch(getWithdrawCompleteReducer({
            page:initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: query,
            startDate: startDate,
            endDate: endDate,
        }))
    }

    const startDateHandler = (e) => {
        setStartDate(e.currentTarget.value)
    }
    const endDateHandler = (e) => {
        setEndDate(e.currentTarget.value)
    }

    const queryHandler = (e) => {
        setQuery(e.target.value)
    }
    const inputPress = (e) => {
        if(e.key === "Enter") {
            searchHandler(query, startDate, endDate)
        }
    }
    const searchHandler = (query, startDate, endDate) => {
        dispatch(getWithdrawCompleteReducer({
            page:1,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: query,
            startDate: startDate,
            endDate: endDate,
        }))
    }
    const handlePageChange = (page) => {
        dispatch(getWithdrawCompleteReducer({
            page: page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            startDate:startDate,
            endDate:endDate,
            query: query,
        }))
    }
    const exportExcel = ()=>{
        let excelList = [];
        // JSON 형태의 값들을 for 문을 통해 배열에 추가합니다.
        for (let i = 0; i < initialState.totalList.length; i++) {
            let jsonObject = { // JSON 객체를 생성합니다.
                파트너:'본사',
                상태:typeCase.userState(initialState.totalList[i].withdraw_state),
                등급:initialState.totalList[i].admin?typeCase.userState(initialState.totalList[i].admin.level):'',
                닉네임:initialState.totalList[i].user?initialState.totalList[i].user.nickname:'',
                충전:initialState.userDeposit.filter((el) => el.user_no === initialState.totalList[i].user_no).length > 0? initialState.userDeposit.filter((el) => el.user_no === initialState.totalList[i].user_no)[0].user_total : 0,
                환전:initialState.userWithdraw.filter((el) => el.user_no === initialState.totalList[i].user_no).length > 0? initialState.userWithdraw.filter((el) => el.user_no === initialState.totalList[i].user_no)[0].user_total : 0,
                충환차액:(initialState.userDeposit.filter((el) => el.user_no === initialState.totalList[i].user_no).length > 0? initialState.userDeposit.filter((el) => el.user_no === initialState.totalList[i].user_no)[0].user_total : 0)
                    -
                    (initialState.userWithdraw.filter((el) => el.user_no === initialState.totalList[i].user_no).length > 0? initialState.userWithdraw.filter((el) => el.user_no === initialState.totalList[i].user_no)[0].user_total : 0),
                이달수익:
                    (initialState.monthDeposit.filter((el) => el.user_no === initialState.totalList[i].user_no).length > 0? initialState.monthDeposit.filter((el) => el.user_no === initialState.totalList[i].user_no)[0].user_total : 0)
                    -
                    (initialState.monthWithdraw.filter((el) => el.user_no === initialState.totalList[i].user_no).length > 0? initialState.monthWithdraw.filter((el) => el.user_no === initialState.totalList[i].user_no)[0].user_total : 0),
                보유머니: initialState.totalList[i].user?initialState.totalList[i].user.money:0,
                예금주: initialState.totalList[i].user?initialState.totalList[i].user.name:'',
                은행: initialState.totalList[i].user?initialState.totalList[i].user.bank:'',
                계좌번호: initialState.totalList[i].user?initialState.totalList[i].user.withdraw_account:'',
                환전금액: initialState.totalList[i].request_money,
                요청시간: initialState.totalList[i].createdAt,
                처리시간: initialState.totalList[i].updatedAt,
                처리상태: typeCase.withdrawState(initialState.totalList[i].withdraw_state),
                충전구분: typeCase.depositType(initialState.totalList[i].withdraw_type),
                관리자: initialState.totalList[i].admin?initialState.totalList[i].admin.id:'',
            }
            excelList.push(jsonObject); // 배열에 JSON 객체를 추가합니다.
        }
        console.log(excelList);
        const workSheet = XLSX.utils.json_to_sheet(excelList);
        const workBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, workSheet, '환전완료 리스트');
        XLSX.writeFile(workBook, '환전완료 리스트.xlsx');
    }

    const updateState = (state, withdrawNos) => {
        if (state === 3) {
            if (window.confirm("취소하겠습니까?")) {
                if (withdrawNos.length > 0) {
                    axios.post('/admin/withdrawApi/change_state', {
                        adminNo: userInfo.userNo,
                        withdrawNos: withdrawNos,
                        state: state
                    }).then((res) => {
                        //console.log(res.data.result)
                        alert(res.data.isUpdated ? "완료" : "실패")
                        getAllList()
                    })
                } else {
                    alert('선택해주세요')
                }
            }
        } else {
            if (withdrawNos.length > 0) {
                axios.post('/admin/withdrawApi/change_state', {
                    adminNo: userInfo.userNo,
                    withdrawNos: withdrawNos,
                    state: state
                }).then((res) => {
                    //console.log(res.data.result)
                    alert(res.data.isUpdated ? "완료" : "실패")
                    getAllList()
                })
            }
        }
    }

    const allList = () => {
        getAllList()
    }
    return <>
        <div className="m-2">
            <div className="card-body">
                <div>
                    <div className="container-fluid">
                        <div className="row bg-light align-items-center" >
                            <div className="col align-items-center justify-content-end d-flex" style={{
                                height: "50px"
                            }}>
                                <input type="date" className="form-control mx-1" value={startDate} onChange={startDateHandler} style={{
                                    width: "150px",
                                    height: "40px"
                                }}/>
                                <span className="mx-1">~</span>
                                <input type="date" className="form-control mx-1" value={endDate} onChange={endDateHandler} style={{
                                    width: "150px",
                                    height: "40px"
                                }}/>

                                <div className="input-group align-items-end" style={{
                                    width: "20%",
                                    height: "40px",
                                }}>
                                    <input type="text" className="form-control" placeholder="검색"
                                           aria-describedby="button-addon2" value={query} onChange={queryHandler} onKeyDown={inputPress}/>
                                    <button className="btn btn-outline-secondary" type="button" id="button-addon2"
                                            onClick={()=> searchHandler(query, startDate, endDate)}>검색
                                    </button>
                                    <button className="btn btn-outline-secondary mx-1" type="button"
                                            id="button-addon2" onClick={exportExcel}>엑셀출력
                                    </button>
                                </div>
                                <select className="form-select mx-1" style={{
                                    width: "120px",
                                    height: "40px"
                                }}
                                        value={initialState.pageSize === "999" ? "모두" : initialState.pageSize}
                                        onChange={(e) => {
                                            dispatch(getWithdrawCompleteReducer({
                                                page:1,
                                                pageSize: parseInt(e.target.value),
                                                order: initialState.order,
                                                query: query,
                                                startDate: startDate,
                                                endDate: endDate,
                                            }))
                                        }}
                                >
                                    <option value={20}>20개씩</option>
                                    <option value={50}>50개씩</option>
                                    <option value={100}>100개씩</option>
                                    <option value={200}>200개씩</option>
                                    <option value={999}>모두</option>
                                </select>
                                <select className="form-select"  style={{
                                    width: "120px",
                                    height: "40px"
                                }}
                                        value={initialState.order === 0? 0 : initialState.order}
                                        onChange={(e) => {
                                            dispatch(getWithdrawCompleteReducer({
                                                page:1,
                                                pageSize: initialState.pageSize,
                                                order: parseInt(e.target.value),
                                                query: query,
                                                startDate: startDate,
                                                endDate: endDate,
                                            }))
                                        }}
                                >
                                    <option value={0}>요청시간순</option>
                                    <option value={1}>처리시간순</option>
                                </select>
                            </div>

                        </div>
                        <div className="row my-2">
                            <div className="col-5">
                                <span>전체환전신청 :
                                    <NumericFormat className="ms-1" value={initialState.withdrawTotal && initialState.withdrawTotal[0].withdrawTotal} displayType={'text'} thousandSeparator={true}/>
                                </span>
                            </div>

                        </div>
                        <div className="my-2" style={{
                            display: "flex"
                        }}>
                            <div className="col-5">
                                <h4 className="text-danger">선택금액 :
                                    <NumericFormat className="ms-1" value={initialState.totalSelectedMoney} displayType={'text'} thousandSeparator={true}/>
                                </h4>
                            </div>
                            <div className="col-7" style={{
                                textAlign: "right"
                            }}>
                                <span className="text-success">환전완료 금액 :
                                    <NumericFormat className="ms-1" value={initialState.withdrawStateSum && initialState.withdrawStateSum[2].withdrawStateSum} displayType={'text'} thousandSeparator={true}/>
                                </span>
                            </div>
                        </div>

                    </div>
                    <WithdrawCompleteListComponent initialState={initialState}  updateState ={updateState}/>
                    <Pagination totalCount={initialState.totalCount} pageSize={initialState.pageSize} currentPage={initialState.page} onPageChange={handlePageChange}/>
                </div>
            </div>
        </div>
    </>





}
export default WithdrawCompleteListContainer