import {call, put, take,} from "redux-saga/effects";
import {fetchEvent} from "../../../lib/api";
import {failedGetEvent, setEvent} from "../../reducers/board/event";

export function* getEventSaga(action) {
    try{
        const res = yield call(
            fetchEvent,
            action.payload.page, action.payload.pageSize, action.payload.query,
            action.payload.order, action.payload.startDate, action.payload.endDate
        )
        yield put(setEvent(res.data))
    }
    catch (error) {
        console.log(error)
        yield put(failedGetEvent(error))
    }
}