import {call, put, take,} from "redux-saga/effects";
import {fetchNowStatus, fetchTodayStatus} from "../../lib/api";
import {failedGetNowStatus, failedGetTodayStatus, setNowStatus, setTodayStatus} from "../reducers/status";

export function* getNowStatusSaga(action) {
    try{
        const res = yield call(fetchNowStatus,
            action.payload.adminNo
        );
        yield put(setNowStatus(res.data))
    }
    catch (error) {
        console.log(error)
        yield put(failedGetNowStatus(error))
    }
}
export function* getTodayStatusSaga(action) {
    try{
        const res = yield call(fetchTodayStatus,
            action.payload.adminNo
        );
        yield put(setTodayStatus(res.data))
    }
    catch (error) {
        console.log(error)
        yield put(failedGetTodayStatus(error))
    }
}