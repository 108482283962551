import React from 'react';
import moment from "moment";
import {Link, Outlet} from "react-router-dom";
import MarketListContainer from "../../containers/MarketListContainer";

const Market = () => {
    return (
        <div>
            <div style={{
                paddingLeft: "10px",
                paddingTop: "10px",
            }}>
                <p>
                    마켓ID확인
                </p>
            </div>
            <MarketListContainer />


        </div>

    )
}

export default Market;