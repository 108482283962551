import {call, put, take,} from "redux-saga/effects";
import {closeChannel, createSocketChannel} from "../../channel/createSocketChannel";
import socket from "../../../config/socket";
import {
    changeResultMatch,
    failedGetResultFixture,
    failedGetResultFixtureDetail,
    getResultFixtureDetail,
    pushResultMatchTask,
    setCurrentFixture,
    setResultFixture
} from "../../reducers/match/resultMatch";
import {fetchFixtureDetail, fetchResultFixture} from "../../../lib/api";

export function* getResultFixtureSaga(action) {
    try {
        const res = yield call(fetchResultFixture,
            action.payload.page, action.payload.pageSize, action.payload.sport,
            action.payload.provider, action.payload.league, action.payload.query,
            action.payload.startDate, action.payload.endDate);
        yield put(setResultFixture(res.data));
        if(res.data.result?.length > 0) {
            yield put(getResultFixtureDetail(res.data.result[0].fixture_id))
        }
        else {
            yield put(setCurrentFixture(null))
        }
    } catch(error) {
        console.log(error)
        yield put(failedGetResultFixture(error));
    }
}

export function* getResultFixtureDetailSaga(action) {
    try {
        const res = yield call(fetchFixtureDetail, action.payload, 4)
        yield put(setCurrentFixture(res.data))
    } catch(error) {
        console.log(error)
        yield put(failedGetResultFixtureDetail(error));
    }
}

export function* waitResultMatchTaskSaga() {
    let channel;
    try {
        channel = yield call(createSocketChannel, "resultMatch");
        while(true) {
            const task = yield take(channel);
            yield onTaskSaga(task)
        }
    } catch (e) {
        console.log(e, "error");
    } finally {
        socket.close();
        closeChannel(channel)
    }
}

function* onTaskSaga(task) {
    yield put(pushResultMatchTask(task));
    yield put(changeResultMatch(task));
}

export function* changeResultMatchSaga() {
    //yield put(changeSport(changedItem))
}

