import React, {useState} from 'react';
import {Link, Outlet, useNavigate} from "react-router-dom";

const Board = () => {

    const navigate = useNavigate();
    const [activeNav, setActiveNav] = useState();
    const ontoggle = (index, address) => {
        setActiveNav(index)
        navigate(address)
    }
    const menuArr = [
        {name:"공지게시판", address:"/board/notice"},
        {name:"고객센터", address:"/board/help"},
        {name:"고객센터-FAQ", address:"/board/faq"},
        {name:"흐르는공지", address:"/board/marquee"},
        {name:"팝업관리", address:"/board/popup"},
        {name:"이벤트", address:"/board/event"},
    ]

    return (
        <div>
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
                <div className="collapse navbar-collapse" style={{
                    marginLeft: "290px",
                    height: "40px"
                }}>
                    <ul className="navbar-nav text-center">
                        {menuArr.map((menu,index) => (
                            <li key={index} className="mx-2" onClick={() => ontoggle(index, menu.address)}>
                                <div className={activeNav === index ? "nav-item active" : "nav-item"} style={{cursor: "pointer"}}>
                                    {menu.name}
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>

            </nav>
            <div style={{
                paddingLeft: "10px"
            }}>
                <Outlet />
            </div>

        </div>

    )
}

export default Board;