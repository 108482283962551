import React from 'react';
import CombinationBettingListComponent from "../../components/match/CombinationBettingListComponent";


const CombinationBettingListContainer = () => {
    const sportsOption = [
        {value: "", name: "전체"},
        {value: 0, name: "축구"},
        {value: 1, name: "야구"},
        {value: 2, name: "농구"},
        {value: 3, name: "배구"},
        {value: 4, name: "미식축구"},
        {value: 5, name: "E 스포츠"},
        {value: 6, name: "아이스하키"},
    ]
    const typeOption = [
        {value: "", name: "전체"},
        {value: 0, name: "국내형"},
        {value: 1, name: "해외형"},
        {value: 2, name: "라이브"},
    ]
    return (
        <div>
            <div className="container-fluid m-1 p-1" style={{
                display: "flex"
            }}>
                <div className="input-group me-1" style={{
                    display: "flex",
                    width: "800px",
                    alignItems: "center"
                }}>
                    <p className="me-1" style={{width: "40px", margin: 0}}>타입</p>
                    <select className="form-select me-4"
                            style={{width: "50px"}}>
                        {typeOption.map((item) => (
                            <option key={item.value} value={item.value}>{item.name}</option>
                        ))}
                    </select>
                    <p className="me-1" style={{width: "40px", margin: 0}}>종목</p>
                    <select className="form-select me-4"
                            style={{width: "50px"}}>
                        {sportsOption.map((item) => (
                            <option key={item.value} value={item.value}>{item.name}</option>
                        ))}
                    </select>
                    <p className="me-2" style={{width: "60px", margin: 0}}>베팅조합</p>
                    <input style={{
                        width: "220px"
                    }} type="text" className="form-control" id="search_edit" placeholder="베팅조합"  />
                    <button style={{
                        width: "80px"
                    }} type="submit" className="btn btn-primary" >검색</button>
                </div>
            </div>
            <CombinationBettingListComponent/>
        </div>
    );
};

export default CombinationBettingListContainer;