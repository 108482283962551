import React, {forwardRef, useEffect,useRef } from 'react';
import LeagueListApiSettingComponent from "../../components/match/LeagueListApiSettingComponent";
import Pagination from "../../components/common/Pagination";
import {
    getLeagueApiSettingInfoListReducer,
    setLeagueNm,
    setLeagueUseYn
} from "../../redux/reducers/match/leagueApiSettingReducer";
import {useDispatch, useSelector} from "react-redux";
import {resetTeamApiSettingInfoListReducer} from "../../redux/reducers/match/teamApiSettingReducer";

const LeagueListApiSettingContainer = forwardRef(({getTeamInfo},ref) => {
    const dispatch = useDispatch()
    const initialState = useSelector((state) => state.leagueApiSettingReducer)

    const getLeagueList = () => {
        if(initialState.leagueList !== null) {
            dispatch(getLeagueApiSettingInfoListReducer({
                leaguePage: initialState.leaguePage,
                leaguePageSize: initialState.leaguePageSize,
                nationNo: initialState.nationNo,
                leagueUseYn: initialState.leagueUseYn,
                leagueNm: initialState.leagueNm,
            }))
        }
    }
    useEffect(() => {
        if (initialState.leagueList === null) {
            getLeagueList();
        }
    }, [dispatch, initialState.leagueList]);

    const myInputRef = useRef(null);

    React.useImperativeHandle(ref, () => ({
        getChildLeagueInfo: (nationNo) => {
            console.log('자식 컴포넌트 함수 호출');
            dispatch(getLeagueApiSettingInfoListReducer({
                leaguePage: 1,
                leaguePageSize: initialState.leaguePageSize,
                nationNo: nationNo,
                leagueUseYn: initialState.leagueUseYn,
                leagueNm: initialState.leagueNm,
            }))
        },
        focusLeague : ()=>{
            if (myInputRef.current) {
                myInputRef.current.focus();
            }
        }
    }), []); //

    const handlePageChange = (page) => {
        dispatch(getLeagueApiSettingInfoListReducer({
            leaguePage: page,
            leaguePageSize: initialState.leaguePageSize,
            nationNo: initialState.nationNo,
            leagueUseYn: initialState.leagueUseYn,
            leagueNm: initialState.leagueNm,
        }))
    }

    const useOption = [
        {value: "", name: "전체"},
        {value: 'Y', name: "사용"},
        {value: 'N', name: "미사용"},
    ]

    const query = (leagueUseYn) => {
        dispatch(getLeagueApiSettingInfoListReducer({
            leaguePage: 1,
            leaguePageSize: initialState.leaguePageSize,
            nationNo: initialState.nationNo,
            leagueUseYn: leagueUseYn,
            leagueNm: initialState.leagueNm,
        }))
        dispatch(resetTeamApiSettingInfoListReducer())//팀목록초기화
    }

    const inputPress = (e) => {
        if(e.key === "Enter") {
            query(initialState.leagueUseYn)
        }
    }

    const leagueNmHandler = (e) => {
        dispatch(setLeagueNm(e.target.value))
    }

    const changeUseYn = (e) => {
        dispatch(setLeagueUseYn(e.target.value))
        console.log(e.target.value)
        query(e.target.value)
    }
    return (
        <div>
            <div className="container-fluid m-1 p-1" style={{
                display: "flex"
            }}>
                <div className="input-group me-1" style={{
                    display: "flex",
                    width: "800px",
                    alignItems: "center"
                }}>
                    <p className="me-2" style={{width: "60px", margin: 0}}>사용여부</p>
                    <select className="form-select me-4"
                            style={{width: "50px"}} onChange={changeUseYn}>
                        {useOption.map((item) => (
                            <option key={item.value} value={item.value}>{item.name}</option>
                        ))}
                    </select>
                    <input ref={myInputRef} style={{
                        width: "100px"
                    }} type="text" className="form-control" id="search_edit" placeholder="리그명"  onChange={leagueNmHandler} onKeyDown={inputPress}/>
                    <button style={{
                        width: "80px", borderTopRightRadius: "5px" ,borderBottomRightRadius: "5px"
                    }} type="submit" className="btn btn-primary" onClick={() => query(initialState.nationUseYn)}>검색</button>
                    <button style={{width: "100px",backgroundColor : "green" ,borderColor : "green",borderRadius: "5px"}} type="submit" className="btn btn-primary mx-1" >가져오기</button>
                </div>
            </div>
            <LeagueListApiSettingComponent initialState = {initialState} getTeamInfo={getTeamInfo} query={query}/>
            <div className="d-flex justify-content-center"> {/* text-center 클래스 추가 */}
                {initialState.totalLeagueCount && initialState.totalLeagueCount > 0 ?
                <Pagination totalCount={initialState.totalLeagueCount} pageSize={initialState.leaguePageSize} currentPage={initialState.leaguePage} onPageChange={handlePageChange} />
                    :""}
            </div>
        </div>
    );
});

export default LeagueListApiSettingContainer;