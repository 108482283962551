import React, {useState} from "react";
import axios from "axios";
import {useSelector} from "react-redux";
import {NumericFormat} from "react-number-format";

const PointAddComponent = () => {

    const [id, setId] = useState([]);
    const [movePoint, setMovePoint] = useState();
    const [pointType, setPointType] = useState(0);
    const [cont, setCont] = useState([]);
    const [informationList, setInformationList] = useState([])

    const userInfo = useSelector((state) => state.userInfo);

    const idChangeHandler = (e) => {
        setId(e.currentTarget.value)
        userInformation(e.currentTarget.value)
    }
    const pointTypeChangeHandler = (type) => {
        setPointType(type)
    }
    const movePointChangeHandler = (e) => {
        setMovePoint(e.currentTarget.value)
    }
    const contChangeHandler = (e) => {
        setCont(e.currentTarget.value)
    }

    const userInformation = (id) => {
        axios.post('/admin/point_log/information', {
            id: id
        }).then((res) =>{
            if (res.data.information !== null) {
                setInformationList(res.data.information)
            }
        })
    }
    const handleRegister = () => {
        axios.post('/admin/point_log/insert',{
            adminNo: userInfo.userNo,
            id: id,
            nickname: informationList.nickname,
            point_type: pointType,
            moved_point: movePoint,
            cont: cont
        }).then((res) => {
            if(res.data.isInserted) {
                alert('완료')
            }
            else {
                alert('실패')
            }
        })
    }
    return (
        <div className="modal fade" id="move_point" tabIndex="-1" aria-hidden="true" >
            <div className="modal-dialog" >
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" >머니관리</h1>
                    </div>
                    <div className="modal-body">
                        <div className="mb-2">회원정보</div>
                        <div className="mb-2 d-flex">
                            닉네임 : <b className="me-2">{informationList.nickname}</b>
                            보유머니 : <b className="me-2"><NumericFormat value={informationList.money} displayType={'text'} thousandSeparator={true} /></b>
                            보유포인트 : <b className="me-2"><NumericFormat value={informationList.point} displayType={'text'} thousandSeparator={true} /></b>
                        </div>
                        <div className="mb-4  borderBottom-1 d-flex">
                            예금주 : <b className="me-2">{informationList.name}</b>
                            은행 : <b className="me-2">{informationList.bank}</b>
                            계좌번호 : <b className="me-2">{informationList.withdraw_account}</b>
                        </div>
                        <div className="mb-2 d-flex">
                            <label className="col-form-label" style={{width: "14%"}}>ID</label>
                            <input className="form-control" maxLength="10" type="text" placeholder="아이디" value={id} onChange={idChangeHandler}  />
                        </div>
                        <div className="mb-2 d-flex">
                            <label className="col-form-label" style={{width: "14%"}}>방식</label>
                            <div className="form-check form-check-inline my-auto">
                                <input className="form-check-input" type="radio" name="moneyType" id="inlineRadio1" value={pointType}
                                       onChange={() => pointTypeChangeHandler(0)} />
                                <label className="form-check-label" htmlFor="inlineRadio1">포인트지급</label>
                            </div>
                            <div className="form-check form-check-inline my-auto">
                                <input className="form-check-input"  type="radio" name="moneyType" id="inlineRadio2" value={pointType}
                                       onChange={() => pointTypeChangeHandler(1)} />
                                <label className="form-check-label" htmlFor="inlineRadio2">포인트차감</label>
                            </div>
                        </div>
                        <div className="mb-2 d-flex">
                            <label className="col-form-label" style={{width: "14%"}}>포인트</label>
                            <input className="form-control" maxLength="12" type="text" placeholder="포인트" value={movePoint} onChange={movePointChangeHandler}  />
                        </div>
                        <div className="mb-2 d-flex">
                            <label className="col-form-label" style={{width: "14%"}}>사유</label>
                            <input className="form-control" maxLength="200" type="text" placeholder="사유" value={cont} onChange={contChangeHandler}  />
                        </div>

                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">취소</button>
                        <button type="button" className="btn btn-primary" onClick={handleRegister}>등록</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PointAddComponent;