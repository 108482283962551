import React, {useState} from 'react';
import soccer from "../../images/sports/soccer.jpg";
import basketball from "../../images/sports/basketball.jpg";
import baseball from "../../images/sports/baseball.jpg";
import vollyball from "../../images/sports/vollyball.jpg";
import icehocky from "../../images/sports/icehocky.jpg";
import americanfootball from "../../images/sports/americanfootball.jpg";
import esports from "../../images/sports/esports.jpg";
import korea from "../../images/nation/korea.jpg";
import Switch from "react-switch";
import {setTeamSwitchStatesReducer,setTeamListChange} from "../../redux/reducers/match/teamApiSettingReducer";
import {useDispatch} from "react-redux";
import {setLeagueListChange} from "../../redux/reducers/match/leagueApiSettingReducer";
import axios from "axios";

const TeamListApiSettingComponent = ({initialState,query}) => {
    console.log("##initialState.teamList##",initialState.teamList);
    const dispatch = useDispatch()
    const handleToggle = (code) => {
        dispatch(setTeamSwitchStatesReducer(code))
    };
    const handleChangeNm = (e,index) => {
        const newValue = e.currentTarget.value;
        const updatedTeamList = [...initialState.teamList]; // Create a copy of the array
        updatedTeamList[index] = { ...updatedTeamList[index], team_kor_nm: newValue };
        dispatch(setTeamListChange(updatedTeamList)); // Update the state with the new array
    }

    const handleUpdate = (teamList) => {
        if (window.confirm("변경 하시겠습니까?")) {
            const no = teamList.no;
            const teamKorNm = teamList.team_kor_nm;
            const useYn = teamList.use_yn;

            axios.post("/admin/teamApiSettingInfoApi/update", {
                no : no,
                teamKorNm : teamKorNm,
                useYn : useYn,
            }).then((res) => {
                if(res.data.isTeamUpdated){
                    alert("변경되었습니다.");
                    query(initialState.teamUseYn);
                }else{
                    alert("변경실패.");
                }
            })
        }
    }
    return (
        <div className="m-2">
            <div className="card-body">
                <div>
                    <table className="table table-bordered table-sm text-center align-middle">
                        <thead className="table-dark">
                        <tr>
                            <th style={{width: "10%"}}>코드</th>
                            <th style={{width: "35%"}}>팀명</th>
                            <th style={{width: "5%"}}>영문</th>
                            <th style={{width: "25%"}}>사용여부</th>
                            <th style={{width: "20%"}}>-</th>
                        </tr>
                        </thead>
                        {initialState.teamList && initialState.teamList.length > 0 ?
                            initialState.teamList.map((List, index) => (
                        <tbody key={List.no}>
                        <tr>
                            <td>{List.team_cd}</td>
                            <td style={{ textAlign: 'left',paddingLeft: '10px' }}><input type="text" style={{ width: '100%' }} value={List.team_kor_nm} onChange={(e) => handleChangeNm(e, index)}/></td>
                            <td>{List.team_eng_nm}</td>
                            <td>
                                <Switch
                                    onChange={() => handleToggle(List.no)}
                                    checked={List.use_yn ==='Y'}
                                    onColor="#2196F3"
                                />
                            </td>
                            <td> <button className="my-auto btn btn-sm btn-primary w-50" onClick={() => handleUpdate(List)}>변경</button>
                            </td>
                        </tr>
                        </tbody>
                            ))
                            :
                            <tbody>
                            <tr className="table-secondary">
                                <td colSpan= "8"><font size= '3'> 조회된 데이터가 없습니다.</font></td>
                            </tr>
                            </tbody>
                        }
                    </table>
                </div>
            </div>
        </div>
    );
};

export default TeamListApiSettingComponent;