import React from "react";
import moment from "moment";
import {setTotalSelectedMoney, setSelectedNos} from "../../redux/reducers/money/MoneyLogMngReducer";
import {useDispatch} from "react-redux";
import {NumericFormat} from "react-number-format";

const MoneyLogMngListComponent = ({initialState, onRefresh}) => {
    const dispatch = useDispatch()
    const allCheckHandler = (e) => {
        if(initialState.moneyLogMngList.length > 0 && e.currentTarget.value) {
            const checks = [];
            let total = 0;
            for(let i = 0; i < initialState.moneyLogMngList.length; i++) {
                checks.push(initialState.moneyLogMngList[i].no)
                total += initialState.moneyLogMngList[i].money
            }
            dispatch(setSelectedNos(e.target.checked? checks : []));
            dispatch(setTotalSelectedMoney(e.target.checked? total : 0));
        }
    }
    const checkHandler = (checked, no) => {
        let selectedNos2 = []
        if(checked) {
            selectedNos2 = [...initialState.selectedNos, no]
            dispatch(setSelectedNos([...initialState.selectedNos, no]))
        }
        else {
            selectedNos2 = initialState.selectedNos.filter((id) => id !== no)
            dispatch(setSelectedNos(initialState.selectedNos.filter((id) => id !== no)))
        }
        let total = 0;
        for(let i = 0; i < selectedNos2.length; i++) {
            total += initialState.moneyLogMngList.filter((id) => id.no === selectedNos2[i])[0].money
        }
        dispatch(setTotalSelectedMoney(total));
    }
    return (
        <div>
            <table className="table table-bordered table-sm text-center align-middle">
                <thead className="table-dark">
                <tr>
                    <th>
                        <input className="form-check-input" type="checkbox" checked={initialState.moneyLogListCount === initialState.selectedNos.length} onChange={allCheckHandler}
                               id="flexCheckDefault"/>
                    </th>
                    <th>회원ID(닉네임)</th>
                    <th>입금</th>
                    <th>출금</th>
                    <th style={{width: "50%"}}>설명</th>
                    <th>잔금</th>
                    <th style={{width: "6%"}}>시간</th>
                    <th>관리자</th>
                </tr>
                </thead>
                {initialState.totalCount > 0 ?
                    initialState.moneyLogMngList && initialState.moneyLogMngList.map((money, index) => (
                    <tbody key={money.no}>
                    <tr>
                        <td>
                            <input className="form-check-input" type="checkbox" checked={initialState.selectedNos.filter((no) => no === money.no).length > 0} onChange={(e) => {
                            checkHandler(e.currentTarget.checked, money.no)
                            }}/>
                        </td>
                        <td>{money.user.id}({money.user.nickname})</td>
                        <td><NumericFormat value={money.deposit_money} displayType={'text'} thousandSeparator={true} /></td>
                        <td><NumericFormat value={money.withdraw_money} displayType={'text'} thousandSeparator={true} /></td>
                        <td>{money.cont}</td>
                        <td><NumericFormat value={money.balance} displayType={'text'} thousandSeparator={true} /></td>
                        <td style={{fontSize: "13px"}}>{moment(money.createdAt).format("YYYY-MM-DD HH:mm:ss")}</td>
                        <td>
                            {money.admin ?
                                money.admin.id : "환전신청"}
                        </td>
                    </tr>
                    </tbody>
                ))
                    :
                    <tbody>
                    <tr className="table-secondary">
                        <td colSpan= "8"><font size= '3'> 조회된 데이터가 없습니다.</font></td>
                    </tr>
                    </tbody>
                }
            </table>

        </div>
    );
}

export default MoneyLogMngListComponent;