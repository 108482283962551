import React, {useEffect, useState} from 'react';
import PageAphAthrContainer from "../../containers/setting/PageAphAthrContainer";

const AdminPage = () => {
    return (
        <div>
            <h6>페이지접근권한</h6>
            <PageAphAthrContainer/>
        </div>
    )
}

export default AdminPage;