import moment from "moment";
import {createSlice} from "@reduxjs/toolkit"


export const statusSlice = createSlice({
    name: 'status',
    initialState: {
        isLoading: false,
        error: null,
        now:null,
        isLoadingToday: false,
        todayError: null,
        today: null,
    },
    reducers: {
        getNowStatus(state, action) {
            state.isLoading = true;
        },
        setNowStatus(state, action) {
            state.isLoading = false;
            state.now = action.payload.result;
        },
        failedGetNowStatus(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        getTodayStatus(state, action) {
            state.isLoadingToday = true;
        },
        setTodayStatus(state, action) {
            state.isLoadingToday = false;
            state.today = action.payload.result;
        },
        failedGetTodayStatus(state, action) {
            state.isLoadingToday = false;
            state.todayError = action.payload;
        },
    }
});

export const {
    getNowStatus, setNowStatus, failedGetNowStatus,
    getTodayStatus, setTodayStatus, failedGetTodayStatus,
} = statusSlice.actions;

export default statusSlice.reducer;