import React, {useState} from "react";
import axios from "axios";
import moment from "moment/moment";
import {useDispatch} from "react-redux";
import {setCheckedInputs, setNameInputs, setUpdateData} from "../../redux/reducers/match/league";

const LeagueList = ({leagues}) => {
    const dispatch = useDispatch()

    const changeHandler = (checked, id, index) => {
        if (checked) {
            dispatch(setCheckedInputs([...leagues.checkedInputs, id]));
        } else {
            // 체크 해제
            dispatch(setCheckedInputs(leagues.checkedInputs.filter((el) => el !== id)));
        }
        const data = {
            no: leagues.leagues[index].no,
            id: leagues.leagues[index].id,
            name: leagues.leagues[index].name,
            name_kor: leagues.leagues[index].name_kor,
            is_active: leagues.leagues[index].is_active,
            is_used: leagues.leagues[index].is_used,
            createdAt: leagues.leagues[index].createdAt,
            updatedAt: leagues.leagues[index].updatedAt,
            sport_no: leagues.leagues[index].sport_no,
            location_no: leagues.leagues[index].location_no,
            location: leagues.leagues[index].location,
            sport: leagues.leagues[index].sport,
        }
        const updateData2 = leagues.updateData.filter((el) => el.id !== id)
        const updateData3 = leagues.updateData.filter((el) => el.id === id)
        if(updateData3.length > 0) {
            data.name_kor = updateData3[0].name_kor
        }
        data.is_active = checked;
        dispatch(setUpdateData([...updateData2, data]));
    };

    const allChangeHandler = (e) => {
        const data_arr = []
        const check_ids = []
        for(let i = 0; i < leagues.leagues.length; i++) {
            const data = {
                no: leagues.leagues[i].no,
                id: leagues.leagues[i].id,
                name: leagues.leagues[i].name,
                name_kor: leagues.leagues[i].name_kor,
                is_active: leagues.leagues[i].is_active,
                is_used: leagues.leagues[i].is_used,
                createdAt: leagues.leagues[i].createdAt,
                updatedAt: leagues.leagues[i].updatedAt,
                sport_no: leagues.leagues[i].sport_no,
                location_no: leagues.leagues[i].location_no,
                location: leagues.leagues[i].location,
                sport: leagues.leagues[i].sport,
            }
            data.is_active = e.target.checked;
            const updateData3 = leagues.updateData.filter((el) => el.id === data.id)
            if(updateData3.length > 0) {
                data.name_kor = updateData3[0].name_kor
            }
            data_arr.push(data);
            check_ids.push(data.id);
        }
        dispatch(setCheckedInputs(e.target.checked? check_ids : []));
        dispatch(setUpdateData(data_arr));
    }

    const changeName = (value, id, index) => {
        const data = {
            no: leagues.leagues[index].no,
            id: leagues.leagues[index].id,
            name: leagues.leagues[index].name,
            name_kor: leagues.leagues[index].name_kor,
            is_active: leagues.leagues[index].is_active,
            is_used: leagues.leagues[index].is_used,
            createdAt: leagues.leagues[index].createdAt,
            updatedAt: leagues.leagues[index].updatedAt,
            sport_no: leagues.leagues[index].sport_no,
            location_no: leagues.leagues[index].location_no,
            location: leagues.leagues[index].location,
            sport: leagues.leagues[index].sport,

        }

        const updateData2 = leagues.updateData.filter((el) => el.id !== id)
        const updateData3 = leagues.updateData.filter((el) => el.id === id)
        if(updateData3.length > 0) {
            data.is_active = updateData3[0].is_active
        }
        data.name_kor = value;
        dispatch(setUpdateData([...updateData2, data]));
        const nameInputs2 = leagues.nameInputs.filter((el) => el.id !== id)
        dispatch(setNameInputs([...nameInputs2, {id: id, name_kor: value}]))
    }

    const saveLeague = async () => {
        axios.post('/api/updateLeagues', leagues.updateData).then( (res) => {
                if(res.status === 200) {
                    dispatch(setUpdateData([]));
                    alert("저장완료");
                }
            }
        )
    }

    return (
        <div>
            <input type="button" value="저장" onClick={saveLeague} />
            {leagues._isLoading && "로딩중..."}
            {!leagues._isLoading && leagues.leagues && (
                <table className="table table-bordered table-hover text-center">
                <thead>
                <tr>
                    <th>생성일</th>
                    <th>종류</th>
                    <th>국가</th>
                    <th>리그ID</th>
                    <th>파싱 리그명</th>
                    <th>한글 리그명</th>
                    <th>
                        사용여부
                        <input
                            type="checkbox"
                            checked={
                                leagues.checkedInputs.length === leagues.leagues.length
                            }
                            onChange={(e) => {allChangeHandler(e)}}
                        />
                    </th>
                </tr>
                </thead>
                <tbody>
                    {!leagues.leagues.length && (
                        <tr>
                            <td colSpan="3">목록이 비어있습니다.</td>
                        </tr>
                    )}
                    {!!leagues.leagues.length && leagues.leagues.map((league, index) => (
                        <tr>
                            <td>{moment(league.updatedAt).format("YYYY-MM-DD HH:mm")}</td>
                            <td>{league.sport.name_kor? league.sport.name_kor: league.sport.name}</td>
                            <td>{league.location.name}</td>
                            <td>{league.id}</td>
                            <td>{league.name}</td>
                            <td>
                                <input
                                    name={index}
                                    type="text"
                                    value={leagues.nameInputs.filter((e) => e.id === league.id).length > 0 ? leagues.nameInputs.filter((e) => e.id === league.id)[0].name_kor: ""}
                                    onChange={(e) => {
                                        changeName(e.currentTarget.value, league.id, index)
                                    }}
                                />
                            </td>
                            <td>
                                <label>
                                    사용여부
                                    <input type="checkbox" name={league.id} id={league.id} value="true"
                                           checked={leagues.checkedInputs.includes(league.id)}
                                           onChange={(e) => {changeHandler(e.currentTarget.checked, league.id, index)}}
                                    />
                                </label>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            )}
        </div>
    );

}

export default LeagueList;