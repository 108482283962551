import {createSlice} from "@reduxjs/toolkit"
export const statsSlice = createSlice({
    name: 'marketApiSettingReducer',
    initialState: {
        marketSwitchStates : {},
        marketPage: 1,
        marketPageSize:20,
        marketUseYn: "",
        marketNm: "",
        marketList:[],
        totalMarketCount:0,
        isLoading: false,
        error: null,

    },
    reducers: {
        getMarketApiSettingInfoListReducer(state, action) {
            console.log("##getMarketApiSettingInfoListReducer##", action.payload.marketPage,action.payload.marketPageSize,action.payload.marketUseYn,action.payload.marketNm);
            state.isLoading = true;
            state.marketPage = action.payload.marketPage;
            state.marketPageSize = action.payload.marketPageSize;
            state.marketUseYn = action.payload.marketUseYn;
            state.marketNm = action.payload.marketNm;
        },
        setMarketApiSettingInfoListReducer(state, action) {
            console.log("#setMarketApiSettingInfoListReducer#", action.payload);
            if((state.marketPage -1) * state.marketPageSize > action.payload.totalMarketCount) {
                state.marketPage = 1
            }
            state.isLoading = false;
            state.marketList = action.payload.marketList;
            state.totalMarketCount = action.payload.totalMarketCount;
        },
        failedGetMarketApiSettingInfoListReducer(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        setMarketSwitchStatesReducer(state, action){
            console.log("##setMarketSwitchStatesReducer##", action.payload);
            const code = action.payload;
            const updatedMarketList = state.marketList.map((item) => {
                if (item.no === code) {
                    // 클릭된 국가의 use_yn 값을 변경
                    return {
                        ...item,
                        use_yn: item.use_yn === 'Y' ? 'N' : 'Y', // 'Y'와 'N'을 토글
                    };
                }
                return item;
            });
            state.marketList = updatedMarketList;
        },

        setMarketListChange(state, action) {
            state.marketList = action.payload;
        },

        //조회조건 사용여부
        setMarketUseYn(state,action){
            state.marketUseYn = action.payload;
        },

        //조회조건 마켓명
        setMarketNm(state,action){
            state.marketNm = action.payload;
        },
    }
});

export const {
    getMarketApiSettingInfoListReducer,
    setMarketApiSettingInfoListReducer,
    failedGetMarketApiSettingInfoListReducer,
    setMarketSwitchStatesReducer,
    setMarketListChange,
    setMarketUseYn,
    setMarketNm,
} = statsSlice.actions;

export default statsSlice.reducer;