import {createSlice} from "@reduxjs/toolkit"
export const statsSlice = createSlice({
    name: 'teamApiSettingReducer',
    initialState: {
        //팀
        teamSwitchStates : {},
        teamPage: 1,
        teamPageSize:20,
        leagueNo:0,
        teamUseYn: "",
        teamNm: "",
        teamList : [],
        totalTeamCount : 0,

        isLoading: false,
        error: null,

    },
    reducers: {
        getTeamApiSettingInfoListReducer(state, action) {
            console.log("##getTeamApiSettingInfoListReducer##", action.payload.teamPage,action.payload.teamPageSize,action.payload.leagueNo,action.payload.teamUseYn,action.payload.teamNm);
            state.isLoading = true;
            state.teamPage = action.payload.teamPage;
            state.teamPageSize = action.payload.teamPageSize;
            state.leagueNo = action.payload.leagueNo;
            state.teamUseYn = action.payload.teamUseYn;
            state.teamNm = action.payload.teamNm;
        },
        setTeamApiSettingInfoListReducer(state, action) {
            console.log("#setTeamApiSettingInfoListReducer#", action.payload);
            if((state.teamPage -1) * state.teamPageSize > action.payload.totalTeamCount) {
                state.teamPage = 1
            }
            state.isLoading = false;
            state.teamList = action.payload.teamList;
            state.totalTeamCount = action.payload.totalTeamCount;
        },
        failedGetTeamApiSettingInfoListReducer(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        setTeamSwitchStatesReducer(state, action){
            console.log("##setTeamSwitchStatesReducer##", action.payload);
            const code = action.payload;
            const updatedTeamList = state.teamList.map((item) => {
                if (item.no === code) {
                    // 클릭된 국가의 use_yn 값을 변경
                    return {
                        ...item,
                        use_yn: item.use_yn === 'Y' ? 'N' : 'Y', // 'Y'와 'N'을 토글
                    };
                }
                return item;
            });
            state.teamList = updatedTeamList;
        },
        setTeamListChange(state, action) {
            state.isLoading = false;
            state.teamList = action.payload;
        },
        //조회조건 사용여부
        setTeamUseYn(state,action){
            state.teamUseYn = action.payload;
        },

        //조회조건 팀명
        setTeamNm(state,action){
            state.teamNm = action.payload;
        },
        resetTeamApiSettingInfoListReducer(state, action) {
            state.teamSwitchStates = {};
            state.teamPage= 1;
            state.teamPageSize=20;
            state.leagueNo=0;
            state.teamUseYn= "";
            state.teamNm= "";
            state.teamList = [];
            state.totalTeamCount = 0;
            state.isLoading= false;
            state.error= null;
        },

    }
});

export const {
    getTeamApiSettingInfoListReducer,
    setTeamApiSettingInfoListReducer,
    failedGetTeamApiSettingInfoListReducer,
    setTeamSwitchStatesReducer,
    setTeamListChange,
    setTeamNm,
    setTeamUseYn,
    resetTeamApiSettingInfoListReducer,
} = statsSlice.actions;

export default statsSlice.reducer;