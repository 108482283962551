import React from 'react';
import moment from "moment";
import {Link, Outlet} from "react-router-dom";

const Statistic = () => {
    return (
        <div>
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
                <div className="collapse navbar-collapse" style={{
                    marginLeft: "290px",
                    height: "40px"
                }}>
                    <ul className="navbar-nav text-center">
                        <li className="nav-item">
                            <Link className="nav-link" aria-current="page" to="/statistic/total">통합세부내역</Link>
                        </li>
                    </ul>
                </div>

            </nav>
            <div style={{
                paddingLeft: "10px"
            }}>
                <Outlet />
            </div>

        </div>

    )
}

export default Statistic;