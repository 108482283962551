import {call, put, take,} from "redux-saga/effects";
import {fetchSport} from "../../../lib/api";
import {changeSport, failedGetSport, pushSportTask, setSport} from "../../reducers/match/sport";
import {closeChannel, createSocketChannel} from "../../channel/createSocketChannel";
import socket from "../../../config/socket";

export function* getSportSaga(action) {
    try {
        const res = yield call(fetchSport, action.payload.is_active, action.payload.page, action.payload.pageSize, action.payload.query);
        yield put(setSport(res.data));
    } catch(error) {
        console.log(error)
        yield put(failedGetSport(error));
    }
}

export function* waitSportTaskSaga() {
    let channel;
    try {
        channel = yield call(createSocketChannel, "sport");
        while(true) {
            const task = yield take(channel);
            yield onTaskSaga(task)
        }
    } catch (e) {
        console.log(e, "error");
    } finally {
        socket.close();
        closeChannel(channel)
    }
}

function* onTaskSaga(task) {
    yield put(pushSportTask(task));
    yield put(changeSport(task))
}

export function* changeSportSaga() {
    //yield put(changeSport(changedItem))
}
