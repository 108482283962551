import {useDispatch, useSelector} from "react-redux"
import React, {useEffect, useState} from "react"

import Pagination from "../../components/common/Pagination";
import {getAttend} from "../../redux/reducers/user/attend";
import AttendList from "../../components/user/Attend";
import typeCase from "../../util/typeCase";
import moment from "moment";
import * as XLSX from "xlsx";

const AttendListContainer = () => {
    // useDispatch: 컴포넌트 내부에서 스토어의 내장 함수 dispatch를 사용할 수 있게 해주는 hook 이다.
    // useSelector: connect 함수를 대신하여 스토어 상태를 조회
    const dispatch = useDispatch()
    const initialState = useSelector((state) => state.attend)
    const [searchQuery, setSearchQuery] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    // 브라우저 상에 컴포넌트가 나타날 때 상품 목록을 조회하는 함수를 실행
    useEffect(() => {
        dispatch(getAttend({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
        }))
    }, [dispatch])

    const handlePageChange = (page) => {
        dispatch(getAttend({
            page: page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
        }))
    }

    const changeQuery = (value) => {
        setSearchQuery(value)
    }

    const inputPress = (e) => {
        if(e.key === "Enter") {
            onSearch(e.target.value)
        }
    }
    const onSearch = (query) => {
        setSearchQuery(query)
        dispatch(getAttend({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: query,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
        }))
    }
    const onClickSearch = (e) => {

        dispatch(getAttend({
            page: initialState.pages? initialState.pages : 1,
            pageSize: initialState.pageSizes? initialState.pageSizes : 5,
            //page: 2,
            //pageSize: 3,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
        }))
    }

    const onRefresh = () => {
        dispatch(getAttend({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
        }))
    }


    const exportExcel = ()=>{

        //alert('initialState.totalList <>>>> '+initialState.totalList);

        let excelList = [];
        // JSON 형태의 값들을 for 문을 통해 배열에 추가합니다.
        for (let i = 0; i < initialState.totalList.length; i++) {


            let jsonObject = { // JSON 객체를 생성합니다.
                상태:getStatus(initialState.totalList[i].user.user_state),
                등급:getLevel(initialState.totalList[i].user.level),
                회원ID:initialState.totalList[i].id? initialState.totalList[i].id :'',
                닉네임:initialState.totalList[i].nickname? initialState.totalList[i].nickname :'',
                출석체크횟수:initialState.totalList[i].attendance_check? initialState.totalList[i].attendance_check :'',
                최초접속일:initialState.totalList[i].createdAt? moment(initialState.totalList[i].createdAt).format("YYYY-MM-DD HH:mm:ss") :'',
                최종접속일:initialState.totalList[i].updatedAt? moment(initialState.totalList[i].updatedAt).format("YYYY-MM-DD HH:mm:ss") :'',
            }
            excelList.push(jsonObject); // 배열에 JSON 객체를 추가합니다.
        }
        console.log(excelList);
        const workSheet = XLSX.utils.json_to_sheet(excelList);
        const workBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, workSheet, '출석부');
        XLSX.writeFile(workBook, '출석부.xlsx');
    }


    function getPartnerType (recommend_code) {
        switch (recommend_code) {
            case "0" : return "본사"
            case "1" : return "파트너"
            default : return recommend_code
        }
    }

    function getStatus(user_state) {
        switch (user_state) {
            case 0 : return "대기"
            case 1 : return "신규"
            case 2 : return "정상"
            case 3 : return "요주의"
            case 4 : return "악성"
            case 5 : return "미이용"
            case 6 : return "휴면"
            case 7 : return "탈퇴"
            case 8 : return "졸업"
            case 9 : return "블랙"
            default : return user_state
        }
    }
    function getLevel(level) {
        switch (level) {
            case 0 : return "1"
            case 1 : return "2"
            case 2 : return "3"
            case 3 : return "4"
            case 4 : return "5"
            case 5 : return "VIP"
            default : return level
        }
    }




    const startIndex = ((initialState.page - 1) * initialState.pageSize) + 1;
    const currentMaxCount = initialState.totalCount < (startIndex + initialState.pageSize - 1)? initialState.totalCount : (startIndex + initialState.pageSize - 1)



    return <>
        <p className="h5 ps-2">{ startIndex + "~" + currentMaxCount + " / " + initialState.totalCount}</p>
        <div className="container-fluid p-0 mx-1" style={{
            display: "flex"
        }}>
            <input style={{
                width: "220px"
            }} className="form-control" type="date" id="start_date" value={startDate? startDate : initialState.startDate} onChange={ event => {
                setStartDate(event.target.value)
            }}/>
            <big className="mx-2">~</big>
            <input style={{
                width: "220px"
            }} className="form-control" type="date" id="end_date" value={endDate? endDate : initialState.endDate} onChange={ event => {
                setEndDate(event.target.value)
            }}/>
        </div>
        <div className="container-fluid m-1 p-0" style={{
            display: "flex"
        }}>
            <select
                className="form-select me-1"
                defaultValue={100}
                style={{
                    width: "100px"
                }}
                value={initialState.pageSize === "999"? "모두" : initialState.pageSize}
                // onChange={(e) => {
                //     dispatch(getNotice{
                //         page: initialState.page,
                //         pageSize: parseInt(e.target.value),
                //         order: initialState.order,
                //         query: searchQuery,
                //         startDate: startDate? startDate : initialState.startDate,
                //         endDate: endDate? endDate : initialState.endDate,
                //     }))
                // }}
            >
                <option value={50}>50</option>
                <option value={100}>100</option>
                <option value={200}>200</option>
                <option value={999}>모두</option>
            </select>
            <select
                className="form-select me-1"
                defaultValue={0}
                style={{
                    width: "200px"
                }}
                value={initialState.order === 0? 0 : initialState.order}
                onChange={e =>
                    dispatch(getAttend({
                        page: initialState.page,
                        pageSize: initialState.pageSize,
                        order: parseInt(e.target.value),
                        query: searchQuery,
                        startDate: startDate? startDate : initialState.startDate,
                        endDate: endDate? endDate : initialState.endDate,
                    }))
                }
            >
                <option value={0}>회원ID순</option>
            </select>
            <div className="input-group me-1" style={{
                display: "flex",
                width: "400px"
            }}>
                <input style={{
                    width: "320px"
                }} type="text" className="form-control" id="search_edit" placeholder="회원ID,닉네임을 입력하세요." onBlur={(e) => {
                    changeQuery(e.target.value)
                }} onKeyDown={inputPress} />
                <button style={{
                    width: "80px"
                }} type="submit" className="btn btn-primary" onClick={onClickSearch}>검색</button>
            </div>
            <button style={{
                width: "80px"
            }} type="submit" className="btn btn-primary" onClick={exportExcel}>엑셀</button>
        </div>
        <AttendList attend={initialState.attend} onRefresh={onRefresh} />
        <Pagination totalCount={initialState.totalCount} pageSize={initialState.pageSize} currentPage={initialState.page} onPageChange={handlePageChange}/>
    </>





}
export default AttendListContainer