import React, {useState} from 'react';
import moment from "moment";
import ConnectListContainer from "../../containers/user/ConnectListContainer";

const ConnectListPage = () => {
    return (
        <div>
            <h1>접속중</h1>
            <ConnectListContainer />
        </div>

    )
}

export default ConnectListPage;