import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@material-ui/core";
import React, {useState} from "react";
import axios from "axios";
import {useSelector} from "react-redux";

const CancelBetDialog = (props) => {
    const { open, onClose, detail } = props
    const [cont, setCont] = useState([]);
    const userInfo = useSelector((state) => state.userInfo);

    const contChangeHandler = (e) => {
        setCont(e.currentTarget.value)
    }
    const handleCancel = () => {
        onClose();
    }

    const handleRegister = () => {
        axios.post('/admin/betting/refundBetDetail',{
            adminNo: userInfo.userNo,
            betDetailNo: detail.no,
            cont: cont,
        }).then((res) => {
            if(res.data.isInserted) {
                onClose();
                alert(`배팅상세번호: ${detail.no} 개별적특완료`)
            }
            else {
                alert(`배팅상세번호: ${detail.no} 개별적특실패`)
            }
        })
    }
    return (
        <Dialog open={open}>
            <DialogTitle>개별적특처리</DialogTitle>
            <DialogContent className="d-flex" style={{
                fontSize: "15px",
                width: "400px"
            }}>
                <p className="me-2">{`배팅상세번호 : ${detail.no}`}</p>
            </DialogContent>
            <DialogContent>
                <TextField
                    margin="dense"
                    id="cont"
                    label="적특사유"
                    type="text"
                    fullWidth
                    variant="standard"
                    onChange={contChangeHandler}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel}>취소</Button>
                <Button onClick={handleRegister}>등록</Button>
            </DialogActions>
        </Dialog>
    )
}

export default CancelBetDialog;