import React, {useEffect, useState} from 'react';
import moment from "moment";
import {useSelector} from "react-redux";
import axios from "axios";

const BlockList = ({block, onRefresh}) => {

    const [blockList, setBlockList] = useState([]);
    const [blockNos, setBlockNos] = useState([]);
    const [blockCount, setBlockCount] =useState(0);
    const [ip, setIp] = useState([]);
    const [country, setCountry] = useState([]);
    const [cont, setCont] = useState([]);
    const [startDate, setStartDate] = useState([]);
    const [endDate, setEndDate] = useState([]);
    const [query, setQuery] = useState("");

    const userInfo = useSelector((state) => state.userInfo);

    const getIpBlockList = () => {
        axios.post('/admin/ip_block/get_ip_block_list', {
            adminNo: userInfo.userNo
        }).then((res) => {
            //console.log(res.data)
            if(res.data.ipBlockList !== null) {
                setBlockList(res.data.ipBlockList)
                //console.log(res.data.ipBlockList)
                setBlockCount(res.data.ipBlockCount)
                setBlockNos([])
            }
        })
    }
    useEffect(getIpBlockList, [userInfo.userNo])
    const onIpHandler = (e) =>{
        setIp(e.currentTarget.value);
    }
    const onCountryHandler = (e) =>{
        setCountry(e.currentTarget.value);
    }
    const onContyHandler = (e) =>{
        setCont(e.currentTarget.value);
    }
    const onStartDateHandler = (e) =>{
        setStartDate(e.currentTarget.value);
    }
    const onEndDateHandler = (e) =>{
        setEndDate(e.currentTarget.value);
    }
    const queryHandler = (e) => {
        setQuery(e.target.value)
    }
    const inputPress = (e) => {
        if(e.key === "Enter") {
            searchHandler(e.target.value)
        }
    }
    const searchHandler = () => {
        axios.post('/admin/ip_block/query', {
            query: query
        }).then((res) => {
            if (res.data.ipBlockList !== null) {
                setBlockList(res.data.ipBlockList)
                //console.log(res.data.ipBlockList)
                setBlockCount(res.data.ipBlockCount)
            }
        })
    }
    const allList = () => {
        getIpBlockList()
    }

    const allCheckHandler = (e) => {
        if(blockList.length > 0 && e.currentTarget.value) {
            const checks = [];
            for(let i = 0; i < blockList.length; i++) {
                checks.push(blockList[i].no)
                console.log(blockList[i].no)
            }
            setBlockNos(e.target.checked? checks : [])
        }
    }
    const checkHandler = (checked, no) => {
        let blockNos2 = []
        if(checked) {
            blockNos2 = [...blockNos, no]
            setBlockNos([...blockNos, no])
        }
        else {
            blockNos2 = blockNos.filter((id) => id !== no)
            setBlockNos(blockNos.filter((id) => id !== no))
        }
        let totalCheck = 0;
        for(let i = 0; i < blockNos2.length; i++) {
            totalCheck += blockList.filter((id) => id.no === blockNos2[i])[0].no
        }
        console.log(totalCheck)
    }

    const blockInsert = (e) => {

        if( startDate.length < 1 )
        {
            alert('차단일시 필수값입니다.');
            return;
        }
        if( endDate.length < 1 )
        {
            alert('해제일시 필수값입니다.');
            return;
        }

        if( cont.length < 1 )
        {
            alert('차단사유 필수값입니다.');
            return;
        }

        if( country.length < 1 )
        {
            alert('국가 필수값입니다.');
            return;
        }

        if(ip_check(ip))
        {
            return;
        }

        if (window.confirm("등록 하시겠습니까?")) {

            axios.post('/admin/ip_block/insert', {
                ip: ip,
                country: country,
                cont: cont,
                start_date: startDate,
                end_date: endDate,
                admin_no:  userInfo.userNo
            }).then((res) => {
                if(res.data.isInserted) {
                    alert("등록완료")
                } else {
                    alert("등록실패")
                }
                getIpBlockList()
                onRefresh();

                setIp([]);
                setCountry([]);
                setCont([]);
                setStartDate([]);
                setEndDate([]);


            })
        }

    }
    const blockDelete = (e) => {

        if(blockNos.length > 0) {
            if (window.confirm("삭제 하시겠습니까?")) {
                axios.post('/admin/ip_block/delete', {
                    ipBlockNos: blockNos
                }).then((res) => {
                    alert(res.data.isDeleted? "삭제완료" : "삭제실패")
                    getIpBlockList()
                    onRefresh();
                })
            }

        }
        else {
            alert('선택해주세요')
        }
    }

    function ip_check(ip_addr){
        const filter = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/;

        if (filter.test(ip_addr) == true){
            return false;
        } else{
            alert("IP 주소형식이 옳바르지 않습니다.");
            return true;

        }

    }



    return (
        <div className="m-2">
            <div className="card-body">
                <div>
                    <div className="container-fluid">
                        <div className="d-flex">
                            <div className="my-2 d-flex">
                                <span>(차단내역 : {blockCount && blockCount[0].blockCount} 개)</span>

                            </div>
                            <div className="col align-items-center justify-content-end d-flex">
                                <button type="button" className="btn btn-secondary btn-sm " onClick={blockDelete} style={{
                                    alignSelf: "auto",
                                }}>삭제하기
                                </button>
                            </div>
                        </div>


                    </div>
                    <div>
                        <table className="table table-bordered table-sm text-center align-middle">
                            <thead className="table-dark">
                            <tr>
                                <th style={{width: "3%"}}>
                                    <input className="form-check-input" type="checkbox" checked={blockList.length === blockNos.length}
                                           onChange={allCheckHandler} id="flexCheckDefault"/>
                                </th>
                                <th style={{width: "7%"}}>IP</th>
                                <th style={{width: "10%"}}>국가</th>
                                <th style={{width: "30%"}}>차단사유</th>
                                <th style={{width: "7%"}}>차단일자</th>
                                <th style={{width: "7%"}}>해제일자</th>
                                <th style={{width: "10%"}}>등록일시</th>
                                <th style={{width: "5%"}}>관리자</th>
                            </tr>
                            </thead>

                            {!block.length && (
                                <tbody key={block.no}>
                                <tr>
                                    <td colSpan="8">목록이 비어있습니다.</td>
                                </tr>
                                </tbody>
                            )}

                            {block.map((block, index) => (
                                <tbody key={block.no}>
                                <tr>
                                    <td>
                                        <input className="form-check-input" type="checkbox"
                                               checked={blockNos.filter((no) => no === block.no).length > 0}
                                               onChange={(e) => {checkHandler(e.currentTarget.checked, block.no)}}
                                        />
                                    </td>
                                    <td>{block.ip}</td>
                                    <td>{block.country}</td>
                                    <td>{block.cont}</td>
                                    <td style={{width: "5%"}}>{moment(block.start_date).format("YYYY-MM-DD")} </td>
                                    <td style={{width: "5%"}}>{moment(block.end_date).format("YYYY-MM-DD")}</td>
                                    <td style={{width: "7%"}}>{moment(block.createdAt).format("YYYY-MM-DD hh:mm:ss")}</td>
                                    <td style={{width: "5%"}}>{block.admin.id}</td>
                                </tr>

                                </tbody>
                            ))}
                        </table>



                        <table className="table table-bordered table-sm text-center align-middle mt-5">
                            <thead className="table-dark">
                            <tr>
                                <td style={{width: "10%"}}>IP</td>
                                <td style={{width: "10%"}}>국가</td>
                                <td>차단사유</td>
                                <td style={{width: "7%"}}>차단일자</td>
                                <td style={{width: "7%"}}>해제일자</td>
                                <td style={{width: "5%"}}>등록</td>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>
                                    <input type="text" maxLength="50" className="form-control border border-dark" value={ip} onChange={onIpHandler}/>
                                </td>
                                <td>
                                    <input type="text" maxLength="20" className="form-control border border-dark" value={country} onChange={onCountryHandler}/>
                                </td>
                                <td>
                                    <input type="text" maxLength="50" className="form-control border border-dark" value={cont} onChange={onContyHandler}/>
                                </td>
                                <td>
                                    <input type="date" className="form-control" id="start" onChange={onStartDateHandler}
                                           value={startDate} min="2022-01-01" max="2025-12-31"/>
                                </td>
                                <td>
                                    <input type="date" className="form-control" id="start" onChange={onEndDateHandler}
                                           value={endDate} min="2022-01-01" max="2025-12-31"/>
                                </td>
                                <td>
                                    <button type="button" className="btn btn-secondary btn-sm" onClick={blockInsert}>등록</button>
                                </td>
                            </tr>
                            </tbody>

                        </table>

                    </div>

                </div>
            </div>
        </div>

    )
}

export default BlockList;