import moment from "moment";
import {createSlice} from "@reduxjs/toolkit"


export const statsSlice = createSlice({
    name: 'home',
    initialState: {
        isLoading: false,
        error: null,
        userState: 0,
        home:null,
        isLoadingTable: false,
        tableError: null,
        homeTable: null,
    },
    reducers: {
        getHomeStats(state, action) {
            state.isLoading = true;
        },
        setHomeStats(state, action) {
            state.isLoading = false;
            state.home = action.payload.result;
            console.log("#action.payload.result#",action.payload.result);
        },
        failedGetHomeStats(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        getHomeTableStats(state, action) {
            state.isLoadingTable = true;
            state.startDate = action.payload.startDate;
            state.endDate = action.payload.endDate;
        },
        setHomeTableStats(state, action) {
            console.log("##setHomeTableStats##",action.payload.result);
            state.isLoadingTable = false;
            state.homeTable = action.payload.result;
        },
        failedGetHomeTableStats(state, action) {
            state.isLoadingTable = false;
            state.tableError = action.payload;
        },
    }
});

export const {
    getHomeStats,
    setHomeStats,
    failedGetHomeStats,
    getHomeTableStats,
    setHomeTableStats,
    failedGetHomeTableStats,
} = statsSlice.actions;

export default statsSlice.reducer;