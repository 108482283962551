import React from 'react';
import {useDispatch} from "react-redux";

const DailyFeeComponent = ({initialState}) => {
    const dispatch = useDispatch()
    return (
        <div>
            <table className="table table-bordered table-sm text-center align-middle">
                <thead className="table-dark">
                <tr>
                    <td>매장명</td>
                    <td>매장코드</td>
                    <td>ID</td>
                    <td>차감</td>
                    <td>시간</td>
                </tr>
                </thead>
                {initialState.totalCount > 0 && initialState.partnerWithdrawList.map((List, index) => (
                    <tbody key={List.no}>
                    <tr>
                        <td>{List.partner && List.partner.store_name}</td>
                        <td>{List.partner && List.partner.store_code}</td>
                        <td>{List.partner && List.partner.id}</td>
                        <td className="text-danger">{List.withdraw_money}</td>
                        <td>{List.createdAt}</td>
                    </tr>
                    </tbody>
                ))}
            </table>
        </div>
    )
}

export default DailyFeeComponent;