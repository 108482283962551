import { createSlice } from "@reduxjs/toolkit";

export const sportSlice = createSlice({
    name: 'sport',
    initialState: {
        isLoading: false,
        sports: [],
        error: null,
        isConnect: false,
        tasks: [],
        is_active: 0,
        page: 1,
        pageSize: 100,
        totalCount: 0,
        query: "",
        checkedInputs: [],
        nameInputs: [],
        imageFiles: [],
        updateData: [],
    },
    reducers: {
        getSport(state, action) {
            state.isLoading = true;
            state.is_active = action.payload.is_active
            state.page = action.payload.pageSize === 999? 1: action.payload.page;
            state.pageSize = action.payload.pageSize;
            state.query = action.payload.query
        },
        setSport(state, action) {
            if((state.page -1) * state.pageSize > action.payload.totalCount) {
                state.page = 1
            }

            state.isLoading = false;
            state.sports = action.payload.result
            state.totalCount = action.payload.totalCount;
            const checks = [];
            const inputs = [];
            const images = [];
            for(const r of action.payload.result) {
                if(r.is_active) {
                    checks.push(r.id);
                }
                if(r.name_kor) {
                    inputs.push({id: r.id, name_kor: r.name_kor})
                }
                if(r.image) {
                    images.push({id: r.id, image: r.image, isChange: 0})
                }
            }
            state.checkedInputs = checks;
            state.nameInputs = inputs;
            state.imageFiles = images;
        },
        setCheckedInputs(state, action) {
            state.checkedInputs = action.payload;
        },
        setUpdateData(state, action) {
            state.updateData = action.payload;
        },
        setNameInputs(state, action) {
            state.nameInputs = action.payload;
        },
        setImageFiles(state, action) {
            state.imageFiles = action.payload;
        },
        failedGetSport(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        waitSportTask(state) {
            state.isConnect = true
        },
        pushSportTask(state, action) {
            state.tasks.push(action.payload)
        },
        changeSport(state, action) {
            //변경된 인자값의 인덱스넘버를 찾아야된다.
            const changedItem = action.payload
            const beforeArray = state.sports.filter((item) => item.id === changedItem.id)
            //찾은후 변경사항업데이트
            const index = state.sports.indexOf(beforeArray[0])
            if(index >= 0) {
                state.sports[index] = changedItem
            }
            state.tasks = []
        }
    },
});

export const { getSport, setSport, setCheckedInputs, setUpdateData, setNameInputs, setImageFiles, failedGetSport, waitSportTask, pushSportTask, changeSport } = sportSlice.actions;

export default sportSlice.reducer;