import React from 'react';
import HelpContainer from "../../containers/board/HelpListContainer";


const Help = () => {
    return (
        <div>
            <h1>고객센터</h1>
            <HelpContainer />
        </div>
    );
};

export default Help;