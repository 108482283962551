import React from "react";
import {NumericFormat} from "react-number-format";

const Stat = ({stat, onRefresh}) => {

    return (
        <>
            {stat && (
                <div>
                    <div>
                        <p>- 통합</p>
                        <table className="table table-bordered table-sm text-center align-middle my-2">
                            <thead className="table-dark">
                            <tr>
                                <td>보유머니</td>
                                <td>진행중</td>
                                <td>충전건수</td>
                                <td>충전금액</td>
                                <td>환전건수</td>
                                <td>환전금액</td>
                                <td className="text-danger">충환차액</td>
                                <td>총배팅금액</td>
                                <td>총당첨금액</td>
                                <td className="text-danger">총배팅차액</td>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>
                                    <NumericFormat value={stat.totalMoney} displayType={'text'} thousandSeparator={true} />
                                    <span>원</span>
                                </td>
                                <td>
                                    <NumericFormat value={stat.activeBetTotalMoney} displayType={'text'} thousandSeparator={true} />
                                    <span>원</span>
                                </td>
                                <td><span>{stat.depositCount}건</span></td>
                                <td>
                                    <NumericFormat value={stat.depositTotalMoney} displayType={'text'} thousandSeparator={true} />
                                    <span>원</span>
                                </td>
                                <td><span>{stat.withdrawCount}건</span></td>
                                <td>
                                    <NumericFormat value={stat.withdrawTotalMoney} displayType={'text'} thousandSeparator={true} />
                                    <span>원</span>
                                </td>
                                <td className={stat.depositTotalMoney - stat.withdrawTotalMoney > 0? "text-primary" : "text-danger"}>
                                    <NumericFormat value={stat.depositTotalMoney - stat.withdrawTotalMoney} displayType={'text'} thousandSeparator={true} />
                                    <span>원</span>
                                </td>
                                <td>
                                    <NumericFormat value={stat.betTotalMoney} displayType={'text'} thousandSeparator={true} />
                                    <span>원</span>
                                </td>
                                <td>
                                    <NumericFormat value={stat.betWinTotalMoney} displayType={'text'} thousandSeparator={true} />
                                    <span>원</span>
                                </td>
                                <td className={stat.betTotalMoney - stat.betWinTotalMoney > 0? "text-primary" : "text-danger"}>
                                    <NumericFormat value={stat.betTotalMoney - stat.betWinTotalMoney} displayType={'text'} thousandSeparator={true} />
                                    <span>원</span>
                                </td>
                            </tr>
                            </tbody>

                        </table>

                    </div>
                    <div className="my-3">
                        <p className="text-success">- 스포츠</p>
                        <table className="table table-bordered table-sm text-center align-middle my-2">
                            <thead className="table-success">
                            <tr>
                                <td>스포츠총배팅</td>
                                <td>스포츠총당첨</td>
                                <td className="text-danger">스포츠총차액</td>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>
                                    <NumericFormat value={stat.betTotalMoney} displayType={'text'} thousandSeparator={true} />
                                    <span>원</span>
                                </td>
                                <td>
                                    <NumericFormat value={stat.betWinTotalMoney} displayType={'text'} thousandSeparator={true} />
                                    <span>원</span>
                                </td>
                                <td className={stat.betTotalMoney - stat.betWinTotalMoney > 0? "text-primary" : "text-danger"}>
                                    <NumericFormat value={stat.betTotalMoney - stat.betWinTotalMoney} displayType={'text'} thousandSeparator={true} />
                                    <span>원</span>
                                </td>
                            </tr>
                            </tbody>

                        </table>
                        <table className="table table-bordered table-sm text-center align-middle my-2">
                            <thead className="table-success">
                            <tr>
                                <td>국내형스포츠배팅</td>
                                <td>국내형스포츠당첨</td>
                                <td className="text-danger">국내형스포츠차액</td>
                                <td>인플레이배팅</td>
                                <td>인플레이당첨</td>
                                <td className="text-danger">인플레이차액</td>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>
                                    <NumericFormat value={stat.betTotalMoney} displayType={'text'} thousandSeparator={true} />
                                    <span>원</span>
                                </td>
                                <td>
                                    <NumericFormat value={stat.betWinTotalMoney} displayType={'text'} thousandSeparator={true} />
                                    <span>원</span>
                                </td>
                                <td className={stat.betTotalMoney - stat.betWinTotalMoney > 0? "text-primary" : "text-danger"}>
                                    <NumericFormat value={stat.betTotalMoney - stat.betWinTotalMoney} displayType={'text'} thousandSeparator={true} />
                                    <span>원</span>
                                </td>
                                <td>0원</td>
                                <td>0원</td>
                                <td className="text-primary">0원</td>
                            </tr>
                            </tbody>

                        </table>

                    </div>
                    <div className="my-3">
                        <p className="text-warning">- 미니게임</p>
                    </div>
                    <div className="my-3">
                        <span className="text-secondary">- 가상게임</span>
                    </div>
                    <div className="my-3">
                        <span className="text-primary">- 본사카지노</span>
                    </div>
                    <div className="my-3">
                        <span className="text-primary">- 라이브카지노</span>
                    </div>
                </div>
            )}
        </>
    )
}
export default Stat;