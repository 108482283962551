import React from "react";
import typeCase from "../../util/typeCase";
import {NumericFormat} from "react-number-format";
import moment from "moment/moment";

const HomeEtcListComponent = ({initialState}) => {
    return (
        <div>
            <div className="row">
                <div className="col">
                    <p>충전신청</p>
                    <table className="table table-bordered table-sm text-center align-middle">
                        <thead className="table-dark">
                        <tr>
                            <td>닉네임</td>
                            <td>충전구분</td>
                            <td>충전금액</td>
                            <td>신청일시</td>
                            <td>상태</td>
                        </tr>
                        </thead>
                        {initialState.home.recently5Deposit.map((recently5Deposit, index) => (
                        <tbody key={recently5Deposit.no}>
                            <>
                                <tr>
                                    <td>{recently5Deposit.user.nickname}</td>
                                    <td>{typeCase.depositType(recently5Deposit.deposit_type)}</td>
                                    <td><NumericFormat value={recently5Deposit.request_money} displayType={'text'}
                                                       thousandSeparator={true}/></td>
                                    <td>{moment(recently5Deposit.createdAt).format("YYYY-MM-DD HH:mm")}</td>
                                    <td>{typeCase.depositState(recently5Deposit.deposit_state)}</td>
                                </tr>
                            </>
                        </tbody>
                        ))}
                    </table>
                </div>
                <div className="col">
                    <p>환전신청</p>
                    <table className="table table-bordered table-sm text-center align-middle">
                        <thead className="table-dark">
                        <tr>
                            <td>닉네임</td>
                            <td>연락처</td>
                            <td>환전금액</td>
                            <td>신청일시</td>
                            <td>상태</td>
                        </tr>
                        </thead>
                        {initialState.home.recently5Withdraw.map((recently5Withdraw, index) => (
                        <tbody key = {recently5Withdraw.no}>
                            <>
                                <tr>
                                    <td>{recently5Withdraw.user.nickname}</td>
                                    <td>{typeCase.depositType(recently5Withdraw.withdraw_type)}</td>
                                    <td><NumericFormat value={recently5Withdraw.request_money} displayType={'text'}
                                                       thousandSeparator={true}/></td>
                                    <td>{moment(recently5Withdraw.createdAt).format("YYYY-MM-DD HH:mm")}</td>
                                    <td>{typeCase.depositState(recently5Withdraw.withdraw_state)}</td>
                                </tr>
                            </>
                        </tbody>
                        ))}
                    </table>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <p>자유게시판</p>
                    <table className="table table-bordered table-sm text-center align-middle">
                        <thead className="table-dark">
                        <tr>
                            <td>아이디</td>
                            <td>제목</td>
                            <td>등록일시</td>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div className="col">
                    <p>고객센터</p>
                    <table className="table table-bordered table-sm text-center align-middle">
                        <thead className="table-dark">
                        <tr>
                            <td>닉네임</td>
                            <td>문의내용</td>
                            <td>등록일시</td>
                            <td>답변</td>
                        </tr>
                        </thead>
                        {initialState.home.recently5Help.map((recently5Help, index) => (
                        <tbody key={recently5Help.no}>
                            <>
                                <tr>
                                    <td>{recently5Help.user.nickname}</td>
                                    <td>{recently5Help.cont}</td>
                                    <td>{moment(recently5Help.createdAt).format("YYYY-MM-DD HH:mm")}</td>
                                    <td>{recently5Help.status}</td>
                                </tr>
                            </>
                        </tbody>
                        ))}
                    </table>
                </div>
            </div>
        </div>
    )
}

export default HomeEtcListComponent;