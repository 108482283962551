import moment from "moment";
import {createSlice} from "@reduxjs/toolkit"


export const statsSlice = createSlice({
    name: 'stat',
    initialState: {
        isLoading: false,
        error: null,
        userState: 0,
        startDate: moment().format('YYYY-MM-DD'),
        endDate: moment().add(1, "day").format('YYYY-MM-DD'),
        stat:null,
    },
    reducers: {
        getStats(state, action) {
            state.isLoading = true;
            state.userState = action.payload.userState;
            state.startDate = action.payload.startDate;
            state.endDate = action.payload.endDate;
        },
        setStats(state, action) {
            state.isLoading = false;
            state.stat = action.payload.result;
        },
        failedGetStats(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        }
    }
});

export const { getStats, setStats, failedGetStats } = statsSlice.actions;

export default statsSlice.reducer;