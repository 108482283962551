import React, {useEffect, useState} from 'react';
import moment from "moment";
import {useSelector} from "react-redux";
import axios from "axios";
import JoinCodeAddComponent from "../../components/JoinCodeAddComponent";

const JoinCode = ({joinCode, onRefresh}) => {

    const [codeList, setCodeList] = useState([]);
    const [codeNos, setCodeNos] = useState([]);
    const [count, setCount] = useState(0);

    const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().add(1, "day").format('YYYY-MM-DD'));
    const [query, setQuery] = useState("");
    const [isOpen, setIsOpen] = useState(false);

    const userInfo = useSelector((state) => state.userInfo);

    const getJoinCodeList = () => {
        axios.post('/admin/join_code/get_join_code_list', {
            adminNo: userInfo.userNo
        }).then((res) => {
            //console.log(res.data)
            if(res.data.joinCodeList !== null) {
                setCodeList(res.data.joinCodeList)
                //console.log(res.data.ipBlockList)
                setCount(res.data.count)
                setCodeNos([])
            }
        })
    }
    useEffect(getJoinCodeList, [userInfo.userNo])


    const startDateHandler = (e) =>{
        setStartDate(e.currentTarget.value);
    }
    const endDateHandler = (e) =>{
        setEndDate(e.currentTarget.value);
    }
    const queryHandler = (e) => {
        setQuery(e.target.value)
    }
    const inputPress = (e) => {
        if(e.key === "Enter") {
            searchHandler(e.target.value)
        }
    }
    const searchHandler = () => {
        axios.post('/admin/join_code/query', {
            query: query
        }).then((res) => {
            if(res.data.joinCodeList !== null) {
                setCodeList(res.data.joinCodeList)
                //console.log(res.data.ipBlockList)
                setCount(res.data.count)
                setCodeNos([])
            }
        })
    }

    const handleClickOpen = () => {
        setIsOpen(true);
    }
    const handleClose = () => {
        setIsOpen(false)
        searchHandler();
        onRefresh();
    }
    const allCheckHandler = (e) => {
        if(codeList.length > 0 && e.currentTarget.value) {
            const checks = [];
            let total = 0;
            for(let i = 0; i < codeList.length; i++) {
                checks.push(codeList[i].no)
            }
            setCodeNos(e.target.checked? checks : [])
        }
    }


    const checkHandler = (checked, no) => {
        let codeNos2 = []
        if(checked) {
            codeNos2 = [...codeNos, no]
            setCodeNos([...codeNos, no])
        }
        else {
            codeNos2 = codeNos.filter((id) => id !== no)
            setCodeNos(codeNos.filter((id) => id !== no))
        }
        let total = 0;
        for(let i = 0; i < codeNos2.length; i++) {
            total += codeList.filter((id) => id.no === codeNos2[i])[0]
        }
        //console.log(total)
    }

    const joinCodeDelete = (e) => {
        if(codeNos.length > 0) {
            axios.post('/admin/join_code/join_code_delete', {
                codeNos: codeNos
            }).then((res) => {

                if (window.confirm("삭제 하시겠습니까?")) {
                    alert(res.data.isDeleted ? "삭제완료" : "삭제실패")
                    //getJoinCodeList()
                    searchHandler();
                    onRefresh();
                }
            })
        }
        else {
            alert('선택해주세요')
        }
    }


    return (
        <div className="m-2">
            <div className="card-body">

                    <div className="container-fluid">

                        <div className="d-flex">
                            <div className="my-2 d-flex">
                                <span>(가입코드 : {count} 개)</span>

                            </div>
                            {userInfo.level === 2?
                            <div className="col align-items-center justify-content-end d-flex">
                                <button type="button" className="btn btn-primary" onClick={handleClickOpen}>추가하기</button>
                                <JoinCodeAddComponent open={isOpen} onClose={handleClose} />

                                <button type="button" className="btn btn-secondary ms-2" onClick={joinCodeDelete} >삭제하기</button>
                            </div>
                                :<></>
                            }
                        </div>
                        <div>
                            <table className="table table-bordered table-sm text-center align-middle mt-2">
                                <thead className="table-dark">
                                <tr>
                                    <th style={{width: "3%"}}>
                                        <input className="form-check-input" type="checkbox" checked={codeList.length === codeNos.length} onChange={allCheckHandler}
                                               id="flexCheckDefault"/>
                                    </th>
                                    <th style={{width: "10%"}}>매장명</th>
                                    <th style={{width: "7%"}}>매장코드</th>
                                    <th style={{width: "*%"}}>내용</th>
                                    <th style={{width: "8%"}}>시작시간</th>
                                    <th style={{width: "8%"}}>종료시간</th>
                                    <th style={{width: "5%"}}>사용횟수</th>
                                    <th style={{width: "6%"}}>관리자</th>
                                </tr>
                                </thead>

                                {!joinCode.length && (
                                    <tbody >
                                    <tr>
                                        <td colSpan="8">목록이 비어있습니다.</td>
                                    </tr>
                                    </tbody>
                                )}

                                <tbody>
                                {joinCode.map((join_code, index) => (
                                    <>
                                    <tr>
                                        <td>
                                            <input className="form-check-input" type="checkbox" checked={codeNos.filter((no) => no === join_code.no).length > 0}
                                                   onChange={(e) => {checkHandler(e.currentTarget.checked, join_code.no)
                                            }}/>
                                        </td>
                                        <td>{join_code.store_name}</td>
                                        <td>{join_code.store_code}</td>
                                        <td>{join_code.cont}</td>
                                        <td>{moment(join_code.start_date).format("YYYY-MM-DD HH:mm")}</td>
                                        <td>{moment(join_code.end_date).format("YYYY-MM-DD HH:mm")}</td>
                                        <td>{join_code.use_number}</td>
                                        <td>{join_code.admin? join_code.admin.id : "-"}</td>

                                    </tr>
                                    </>
                                    ))}
                                </tbody>
                            </table>
                        </div>


                    </div>



            </div>
        </div>
    )
}

export default JoinCode;