import React, {useState} from "react";
import moment from "moment/moment";
import {useDispatch} from "react-redux";
import {
    setBetInputs,
    setCheckedInputs,
    setUpdateData,
    setCorrectScores,
    getWaitFixtureDetail
} from "../../redux/reducers/match/parsingWait";
import axios from "axios";
import common from "../../util/common";
import {NumericFormat} from "react-number-format";

const ParsingWaitList = ({parsingWaits}) => {
    const dispatch = useDispatch()

    const changeHandler = (checked, id) => {
        const fixtures = parsingWaits.fixtures.filter((el) => el.id === id);
        if(moment(fixtures[0].start_date).isAfter(moment().format("YYYY-MM-DD HH:mm"))) {
            if(checked) {
                dispatch(setCheckedInputs([...parsingWaits.checkedInputs, id]));
            }
            else {
                dispatch(setCheckedInputs(parsingWaits.checkedInputs.filter((el) => el !== id)));
            }
        }
        else {
            alert("이미시작된 경기 입니다.");
        }
    };

    const allChangeHandler = (e) => {
        const check_ids = []
        let hasStartMatch = false;
        for(let index = 0; index < parsingWaits.fixtures.length; index++) {
            if(moment(parsingWaits.fixtures[index].start_date).isAfter(moment().format("YYYY-MM-DD HH:mm"))) {
                check_ids.push(parsingWaits.fixtures[index].id)
            }
            else {
                hasStartMatch = true;
            }
        }
        if(hasStartMatch) {
            alert("이미시작된 경기를 제외하고 체크합니다.");
        }
        dispatch(setCheckedInputs(e.target.checked? check_ids : []));
    }
    const showCorrectScore = (e, fixtureId) => {

        if(parsingWaits.correctScores.includes(fixtureId)) {
            const arr = parsingWaits.correctScores.filter((el) => el !== fixtureId)
            dispatch(setCorrectScores(arr))
            e.target.innerText = "정확한 스코어 펼치기"
        }
        else {
            const arr = [fixtureId, ...parsingWaits.correctScores]
            dispatch(setCorrectScores(arr))
            e.target.innerText = "정확한 스코어 접기"
        }

    }

    const changeBet = (e, id, type) => {
        if(e.target.value !== e.target.defaultValue) {
            e.target.style.color = "green"
        }
        else {
            e.target.style.color = "#555"
        }
        const value = parseFloat(e.target.value)
        const fixtures = parsingWaits.fixtures.filter((el) => el.id === id);
        if(moment(fixtures[0].start_date).isAfter(moment().format("YYYY-MM-DD HH:mm"))) {
            const data = {
                bet1: fixtures[0].bet1,
                bet2: fixtures[0].bet2,
                bet3: fixtures[0].bet3,
            }

            const betInputs2 = parsingWaits.betInputs.filter((el) => el.id !== id);

            const betInputs3 = parsingWaits.betInputs.filter((el) => el.id === id && el.is_edited === 1);
            let inputs
            if(betInputs3.length > 0) {
                if(type === 1) {
                    inputs = {id: id, bet1: value, bet2: betInputs3[0].bet2, bet3: betInputs3[0].bet3}
                }
                else if(type === 2) {
                    inputs = {id: id, bet2: value, bet1: betInputs3[0].bet1, bet3: betInputs3[0].bet3}
                }
                else if(type === 3) {
                    inputs = {id: id, bet3: value, bet2: betInputs3[0].bet2, bet1: betInputs3[0].bet1}
                }
            }
            else {
                if(type === 1) {
                    inputs = {id: id, bet1: value, bet2: data.bet2, bet3: data.bet3}
                }
                else if(type === 2) {
                    inputs = {id: id, bet2: value, bet1: data.bet1, bet3: data.bet3}
                }
                else if(type === 3) {
                    inputs = {id: id, bet3: value, bet2: data.bet2, bet1: data.bet1}
                }
            }
            inputs.is_edited = 1;
            dispatch(setBetInputs([...betInputs2, inputs]))

            //수정된 데이터 처리. 보류 지금필요없음.
            /*const updateData2 = parsingWaits.updateData.filter((el) => el.id !== id);
            dispatch(setUpdateData([...updateData2, inputs]))*/

            //체크처리
            const checkedInput2 = parsingWaits.checkedInputs.filter((el) => el !== id)
            dispatch(setCheckedInputs([...checkedInput2, id]));
        }
        else {
            alert("이미시작된 경기 입니다.");
        }

    }

    const activeSelectedMatch = async () => {
        const updateData = []
        for(let i = 0; i < parsingWaits.checkedInputs.length; i++) {
            const betInputs2 = parsingWaits.betInputs.filter((el) => el.id === parsingWaits.checkedInputs[i] && el.is_edited === 1)
            const data = {
                id: parsingWaits.checkedInputs[i]
            }
            if(betInputs2.length > 0) {
                data.is_edited = 1
                const editedData = betInputs2[0];
                data.bet1 = editedData.bet1
                data.bet2 = editedData.bet2
                data.bet3 = editedData.bet3
            }
            else {
                data.is_edited = 0
            }
            updateData.push(data)
        }
        await activeMatch(updateData)
    }
    const activeAllMatch = async () => {
        const updateData = []
        for(let i = 0; i < parsingWaits.fixtures.length; i++) {
            const fixture = parsingWaits.fixtures[i];
            if(moment(fixture.start_date).isAfter(moment().format("YYYY-MM-DD HH:mm"))) {
                const betInputs2 = parsingWaits.betInputs.filter((el) => el.id === parsingWaits.fixtures[i])
                const data = {
                    id: parsingWaits.fixtures[i].id
                }
                if(betInputs2.length > 0) {
                    data.is_edited = 1
                    const editedData = betInputs2[0];
                    data.bet1 = editedData.bet1
                    data.bet2 = editedData.bet2
                    data.bet3 = editedData.bet3
                }
                else {
                    data.is_edited = 0
                }
                updateData.push(data)
            }

        }
        await activeMatch(updateData)
    }

    const activeMatch = async (updateData) => {
        axios.post('/api/activeMatch', updateData).then((res) => {
            if(res.status === 200) {
                alert(res.data.result + "개 활성완료");
                window.location.reload();
            }
        })
    }

    function groupBy(array, property) {
        const result = array.reduce((grouped, element) => ({
            ...grouped,
            [element[property]]: [...(grouped[element[property]] || []), element]
        }), {})
        return result
    }

    const getBody = () => {
        if(!parsingWaits.fixtures.length) {
            return (<tr>
                <td colSpan="3">목록이 비어있습니다.</td>
            </tr>)
        }
        else {
            const groupByFixtureId = groupBy(parsingWaits.fixtures, "fixture_id");
            const keys = Object.keys(groupByFixtureId)
            let trs = []
            console.log(keys.length)
            //키값 순서를 날짜별로 정리행됨.
            //날짜로 순서정렬
            keys.sort((a, b) => {
                return moment(groupByFixtureId[a][0].start_date).unix() - moment(groupByFixtureId[b][0].start_date).unix();
            })
            for(let i = 0; i < keys.length; i++) {
                const fixturesByFixtureId = groupByFixtureId[keys[i]];
                const groupByMarketId = groupBy(fixturesByFixtureId, "market_name");
                const marketNames = Object.keys(groupByMarketId)
                for(const market_name of marketNames) {
                    const fixturesByMarketIds = groupByMarketId[market_name]
                    if(market_name.includes("Correct Score")) {
                        const rows = []
                        for(let k = 0; k < fixturesByMarketIds.length; k++) {
                            const fixture = fixturesByMarketIds[k]
                            const result =
                                <tr style={{
                                    display: parsingWaits.correctScores.includes(keys[i])? "table-row": "none"
                                }}>
                                    <td>
                                        <input type="checkbox" name={fixture.id} id={fixture.id} value="true"
                                               checked={parsingWaits.checkedInputs.includes(fixture.id)}
                                               onChange={(e) => {changeHandler(e.currentTarget.checked, fixture.id)}}
                                        />
                                    </td>
                                    <td>{fixture.provider}</td>
                                    <td>{fixture.sport}</td>
                                    <td>{fixture.market_detail}</td>
                                    <td>{fixture.market}</td>
                                    <td>{moment(fixture.start_date).format("YYYY-MM-DD HH:mm")}</td>

                                    <td>{fixture.league}</td>
                                    <td>
                                        {fixture.home}
                                    </td>
                                    <td>
                                        <p>{parsingWaits.originFixtures.filter(item => item.id === fixture.id).length > 0?
                                            (<span style={{
                                                color: parsingWaits.originFixtures.filter(item => item.id === fixture.id)[0].bet1 < fixture.bet1?
                                                    "blue"
                                                    : "red",
                                                textDecoration: fixture.bet1_status !== 1?
                                                    "line-through"
                                                    : ""
                                            }}>{fixture.bet1}</span>)
                                            : (<span style={{
                                                textDecoration: fixture.bet1_status !== 1?
                                                    "line-through"
                                                    : ""
                                            }}>{fixture.bet1}
                                    </span>)}
                                        </p>
                                        <input type="number" className="form-control"
                                               defaultValue={fixture.bet1}
                                               value={parsingWaits.betInputs.filter((e) => e.id === fixture.id).length > 0? parsingWaits.betInputs.filter((e) => e.id === fixture.id)[0].bet1 : ""}
                                               onChange={(e) => {changeBet(e, fixture.id, 1) }} />
                                    </td>
                                    <td>{fixture.bet2}</td>
                                    <td></td>
                                    <td>{fixture.away}</td>
                                    <td>{fixture.fixture_id}</td>
                                    <td>{fixture.market_id}</td>
                                </tr>

                            rows.push(result);
                        }
                        if(rows.length > 0) {
                            const summary = [<tr>
                                <td colSpan="16"><button onClick={(e) => {showCorrectScore(e, keys[i])}}>정확한 스코어 펼치기</button></td>
                            </tr>, ...rows];
                            trs.push(summary)
                        }
                    }
                    else {
                        for(let k = 0; k < fixturesByMarketIds.length; k++) {
                            const fixture = fixturesByMarketIds[k]
                            const result = <tr className={parsingWaits.checkedInputs.includes(fixture.id)? "info" : ""}>
                                <td>
                                    <input type="checkbox" name={fixture.id} id={fixture.id} value="true"
                                           checked={parsingWaits.checkedInputs.includes(fixture.id)}
                                           onChange={(e) => {changeHandler(e.currentTarget.checked, fixture.id)}}
                                    />
                                </td>
                                <td>{fixture.provider}</td>
                                <td>{fixture.sport}</td>
                                <td>{fixture.market_detail}</td>
                                <td>{fixture.market}</td>
                                <td>{moment(fixture.start_date).format("YYYY-MM-DD HH:mm")}</td>

                                <td>{fixture.league}</td>
                                <td>
                                    {fixture.home}
                                </td>
                                <td>
                                    <p>{parsingWaits.originFixtures.filter(item => item.id === fixture.id).length > 0?
                                        (<span style={{
                                            color: parsingWaits.originFixtures.filter(item => item.id === fixture.id)[0].bet1 < fixture.bet1?
                                                "blue"
                                                : "red",
                                            textDecoration: fixture.bet1_status !== 1?
                                                "line-through"
                                                : ""
                                        }}>{fixture.bet1}</span>)
                                        : (<span style={{
                                            textDecoration: fixture.bet1_status !== 1?
                                                "line-through"
                                                : ""
                                        }}>{fixture.bet1}
                                    </span>)}
                                    </p>
                                    <input type="number" className="form-control"
                                           defaultValue={fixture.bet1}
                                           value={parsingWaits.betInputs.filter((e) => e.id === fixture.id).length > 0? parsingWaits.betInputs.filter((e) => e.id === fixture.id)[0].bet1 : ""}
                                           onChange={(e) => {changeBet(e, fixture.id, 1) }} />
                                </td>
                                {fixture.market_baseline === null && !fixture.market_name.includes("Correct Score")?
                                    fixture.bet2?
                                        (
                                            <td>
                                                <p>{parsingWaits.originFixtures.filter(item => item.id === fixture.id).length > 0?
                                                    (<span style={{
                                                        color: parsingWaits.originFixtures.filter(item => item.id === fixture.id)[0].bet2 < fixture.bet2?
                                                            "blue"
                                                            : "red",
                                                        textDecoration: fixture.bet2_status !== 1?
                                                            "line-through"
                                                            : "",
                                                    }}>
                                                    {fixture.bet2}
                                                </span>)
                                                    : (<span>
                                                    {fixture.bet2}
                                                </span>)}
                                                </p>
                                                <input type="number" className="form-control"
                                                       defaultValue={fixture.bet2}
                                                       value={parsingWaits.betInputs.filter((e) => e.id === fixture.id).length > 0? parsingWaits.betInputs.filter((e) => e.id === fixture.id)[0].bet2 : ""}
                                                       onChange={(e) => {changeBet(e, fixture.id, 2) }} />
                                            </td>
                                        )
                                        :(<td>VS</td>)
                                    : (<td>{fixture.bet2? fixture.bet2.replace(" (0-0)", ""): fixture.bet2}</td>)
                                }
                                <td>
                                    <p>{parsingWaits.originFixtures.filter(item => item.id === fixture.id).length > 0?
                                        (<span style={{
                                            color: parsingWaits.originFixtures.filter(item => item.id === fixture.id)[0].bet3 < fixture.bet3?
                                                "blue"
                                                : "red",
                                            textDecoration: fixture.bet3_status !== 1?
                                                "line-through"
                                                : "",
                                        }}>
                                        {fixture.bet3}
                                    </span>)
                                        : (<span style={{
                                            textDecoration: fixture.bet3_status !== 1?
                                                "line-through"
                                                : "",
                                        }}>
                                        {fixture.bet3}
                                    </span>)}
                                    </p>
                                    {fixture.market_name.includes("Correct Score")? (<></>): (<input type="number" className="form-control"
                                                                                                     defaultValue={fixture.bet3}
                                                                                                     value={parsingWaits.betInputs.filter((e) => e.id === fixture.id).length > 0? parsingWaits.betInputs.filter((e) => e.id === fixture.id)[0].bet3 : ""}
                                                                                                     onChange={(e) => {changeBet(e, fixture.id, 3) }} />)}

                                </td>
                                <td>{fixture.away}</td>
                                <td>{fixture.fixture_id}</td>
                                <td>{fixture.market_id}</td>


                                {/*<td>
                                <input
                                    name={index}
                                    type="text"
                                    defaultValue={league.name_kor}
                                    onBlur={(e) => {
                                        changeName(e.currentTarget.value, league.id, index)
                                    }}
                                />
                            </td>*/}

                            </tr>
                            trs.push(result);
                        }
                    }
                }

            }

            return trs

        }

    }

    function getDetail() {
        const ratios = parsingWaits.currentFixture.ratios
        const ratiosByMarket = common.groupBy(ratios, "market_id")
        const keys = Object.keys(ratiosByMarket)
        const result = []
        for(let i = 0; i < keys.length; i++) {

            //마켓아이디 처리
            const marketId = keys[i]

            const arr = ratiosByMarket[marketId]
            // 마켓아이디별 픽스쳐처리
            const trs = []
            for(const fixture of arr) {
                trs.push(<tr>
                    <td>
                        <input
                            type="checkbox"
                        />
                    </td>
                    <td className={fixture.bet1_settlement === 2 && "table-success"}>{fixture.bet1}</td>
                    <td className={fixture.market_id === 1 && (fixture.bet2_settlement === 2 && "table-success")}>{fixture.bet2}</td>
                    <td className={fixture.bet3_settlement === 2 && "table-success"}>{fixture.bet3}</td>
                    <td>{""}</td>
                </tr>)
            }

            const tableTemp = <div>
                <p className="h4 text-bg-dark mb-0 p-2">{arr[0].market}</p>
                <table className="table table-bordered table-hover text-center">
                    <thead className="table-secondary">
                    <tr>
                        <th style={{
                            width: "auto"
                        }}>
                            <input
                                type="checkbox"
                            />
                        </th>
                        <th style={{
                            width: "35%"
                        }}>{arr[0].home}</th>
                        <th style={{
                            width: "13%"
                        }}>{arr[0].market_id === 1? "무" : "기준점"}</th>
                        <th style={{
                            width: "35%"
                        }}>{arr[0].away}</th>
                        <th style={{
                            width: "auto"
                        }}>상태</th>
                    </tr>
                    </thead>
                    <tbody>
                    {trs}
                    </tbody>
                </table>
            </div>

            result.push(tableTemp)
        }

        return result
    }

    return (
        <div className="container-fluid p-1 m-0">

            {/*<div className="mb-1">
                <button className="btn btn-success me-1" onClick={activeAllMatch} >전체활성</button>
                <button className="btn btn-success me-1" onClick={activeSelectedMatch} >선택활성</button>
            </div>*/}

            <div className="d-flex">
                <div className="" style={{
                    width: "50%",
                    height: "1200px",
                    overflowY: "scroll",
                }}>
                    {parsingWaits.low_ratios && (
                        <table className="table table-bordered table-hover text-center">
                            <thead className="table-dark">
                            <tr>
                                <th className="text-center">
                                    <input
                                        type="checkbox"
                                        checked={
                                            parsingWaits.checkedInputs.length === parsingWaits.low_ratios.length
                                        }
                                        onChange={(e) => {allChangeHandler(e)}}
                                    />
                                </th>
                                <th>종목</th>
                                <th>일정</th>
                                <th>리그</th>
                                <th>홈팀</th>
                                <th>원정팀</th>
                                <th>진행배팅금</th>
                                <th>FixID</th>
                            </tr>
                            </thead>
                            <tbody>
                            {parsingWaits.isLoading && "로딩중..."}
                            {!parsingWaits.isLoading && !parsingWaits.low_ratios.length > 0 && (
                                <tr>
                                    <td colSpan="3">목록이 비어있습니다.</td>
                                </tr>
                            )}
                            {!parsingWaits.isLoading && !!parsingWaits.low_ratios.length && parsingWaits.low_ratios.map((item, index) => (
                                <tr className={parsingWaits.checkedInputs.includes(item.id) && "info"} onClick={() => {
                                    dispatch(getWaitFixtureDetail(item.fixture_id))
                                }}>
                                    <td>
                                        <input type="checkbox" name={item.fixture_id} id={item.fixture_id} value="true"
                                               checked={parsingWaits.checkedInputs.includes(item.fixture_id)}
                                               onChange={(e) => {changeHandler(e.currentTarget.checked, item.fixture_id, index)}}
                                        />
                                    </td>
                                    <td>{`${item.sport}\n(${item.mCount})`}</td>
                                    <td>{moment(item.start_date).format("YYYY-MM-DD HH:mm")}</td>
                                    <td>{item.league}</td>
                                    <td>
                                        {item.home}
                                    </td>
                                    {/*<td>
                                <p>{matches.originFixtures.filter(item => item.id === item.arr[0].id).length > 0?
                                    (<span style={{
                                        color: matches.originFixtures.filter(item => item.id === item.arr[0].id)[0].bet1 < item.arr[0].bet1?
                                            "blue"
                                            : "red",
                                        textDecoration: item.arr[0].bet1_status !== 1 && "line-through"
                                    }}>{item.arr[0].bet1}</span>)
                                    : (<span style={{
                                        textDecoration: item.arr[0].bet1_status !== 1 && "line-through"
                                    }}>{item.arr[0].bet1}
                                </span>)}
                                </p>

                            </td>
                            {item.arr[0].market_id === 1 && [6046, 35232, 530129].includes(item.arr[0].sport_id)?
                                (<td>
                                    <p>{matches.originFixtures.filter(item => item.id === item.arr[0].id).length > 0?
                                        (<span style={{
                                            color: matches.originFixtures.filter(item => item.id === item.arr[0].id)[0].bet2 < item.arr[0].bet2?
                                                "blue"
                                                : "red",
                                            textDecoration: item.arr[0].bet2_status !== 1 && "line-through",
                                        }}>
                                        {item.arr[0].bet2}
                                    </span>)
                                        : (<span style={{
                                            textDecoration: item.arr[0].bet2_status !== 1 && "line-through",
                                        }}>
                                    {item.arr[0].bet2}
                                    </span>)}
                                    </p>
                                </td>)
                                : ![1,5,52].includes(item.arr[0].market_id)?
                                    (<td>{item.arr[0].bet2}</td>)
                                    : (<td>VS</td>)}
                            <td>
                                <p>{matches.originFixtures.filter(item => item.id === item.arr[0].id).length > 0?
                                    (<span style={{
                                        color: matches.originFixtures.filter(item => item.id === item.arr[0].id)[0].bet3 < item.arr[0].bet3?
                                            "blue"
                                            : "red",
                                        textDecoration: item.arr[0].bet3_status !== 1 && "line-through",
                                    }}>
                                    {item.arr[0].bet3}
                                </span>)
                                    : (<span style={{
                                        textDecoration: item.arr[0].bet3_status !== 1 && "line-through"
                                    }}>
                                    {item.arr[0].bet3}
                                </span>)}
                                </p>

                            </td>*/}
                                    <td>{item.away}</td>
                                    <td>
                                        {item.total && (
                                            <div style={{
                                                width: "100px",
                                                justifyContent: "center"
                                            }}>
                                                <p><NumericFormat value={item.total.sumExtraMoney} displayType={'text'} thousandSeparator={true}/><span>{` (${item.total.betCount})`}</span></p>
                                            </div>
                                        )}

                                    </td>
                                    <td>{item.fixture_id}</td>
                                    {/*<td>
                            <input
                                name={index}
                                type="text"
                                defaultValue={league.name_kor}
                                onBlur={(e) => {
                                    changeName(e.currentTarget.value, league.id, index)
                                }}
                            />
                        </td>*/}

                                </tr>
                            ))}
                            </tbody>
                        </table>
                    )}

                </div>
                <div className="" style={{
                    width: "50%",
                    height: "1200px",
                    overflowY: "scroll",
                }}>
                    {parsingWaits.isLoadingDetail && "로딩중..."}
                    {!parsingWaits.isLoadingDetail && parsingWaits.currentFixture === null && "선택된 경기가 없습니다."}
                    {!parsingWaits.isLoadingDetail && parsingWaits.currentFixture !== null && (
                        <div>
                            <div className="text-bg-dark p-2 mb-5">
                                <p>{`${parsingWaits.currentFixture.ratios[0].sport} - ${parsingWaits.currentFixture.ratios[0].league}`}</p>
                                <div className="text-center align-middle" style={{
                                    height: "100px",
                                    display: "flex",
                                    justifyContent: "center"
                                }}>
                                    <div style={{
                                        width: "33.3%",
                                        alignSelf: "center"
                                    }}><p>{`${parsingWaits.currentFixture.ratios[0].home}`}</p></div>
                                    <div style={{
                                        width: "33.3%",
                                        alignSelf: "center",
                                    }}>
                                        <p className="m-0">{`${moment(parsingWaits.currentFixture.ratios[0].start_date).format("YYYY-MM-DD HH:mm")}`}</p>
                                    </div>
                                    <div style={{
                                        width: "33.3%",
                                        alignSelf: "center"
                                    }}>{`${parsingWaits.currentFixture.ratios[0].away}`}</div>

                                </div>

                            </div>
                            {getDetail()}

                        </div>
                    )}

                </div>
            </div>



            {parsingWaits.isLoading && "로딩중..."}
            {!parsingWaits.isLoading && parsingWaits.fixtures && (
                <table className="table table-bordered table-hover text-center">
                    <thead>
                    <tr>
                        <th className="text-center">
                            <input
                                type="checkbox"
                                checked={
                                    parsingWaits.checkedInputs.length === parsingWaits.fixtures.length
                                }
                                onChange={(e) => {allChangeHandler(e)}}
                            />
                        </th>
                        <th>업체</th>
                        <th>종목</th>
                        <th>구분</th>
                        <th>종류</th>
                        <th>경기일정</th>
                        <th>리그</th>
                        <th>홈팀명</th>
                        <th>A</th>
                        <th>무/핸/합</th>
                        <th>B</th>
                        <th>원정팀명</th>
                        <th>FixID</th>
                        <th>마켓ID</th>
                    </tr>
                    </thead>
                    <tbody>
                    {getBody()}
                    </tbody>
                </table>
            )}
        </div>
    );

}

export default ParsingWaitList;