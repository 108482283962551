import React, {useEffect} from 'react';
import moment from "moment";
import { NumericFormat } from 'react-number-format';
import {useDispatch, useSelector} from "react-redux";
import HomeHoPartnerTotalContainer from "../../containers/home/HomeHoPartnerTotalContainer";
import HomeEtcListComponent from "../../components/home/HomeEtcListComponent";
import {getHomeStats, getHomeTableStats} from "../../redux/reducers/home/HomeReducer";

const HomeMainComponent = () => {
    const dispatch = useDispatch()
    const initialState = useSelector((state) => state.home)

    useEffect(() => {
        dispatch(getHomeStats())
        dispatch(getHomeTableStats({
            startDate: moment().format('YYYY-MM-DD'),
            endDate: moment().add(1, "day").format('YYYY-MM-DD')
        }))
    },[dispatch])

    return <>
            <div style={{
                paddingLeft: "30px"
            }}>
                <div style={{
                    paddingLeft: "10px",
                    paddingTop: "10px",
                }}>
                    <p>
                        관리자홈
                    </p>
                </div>


                <div className="card" style={{
                    margin: "10px"
                }}>
                    {!initialState.isLoading && initialState.home !== null && (
                        <div className="card-body">
                            <div>

                                <div>
                                    <p>- 통계</p>
                                    <table className="table table-bordered table-sm text-center align-middle">
                                        <thead className="table-dark">

                                        <tr>
                                            <td>보유머니</td>
                                            <td>보유포인트</td>
                                            <td>배팅참여자수</td>
                                            <td>진행중인 배팅건수</td>
                                            <td>진행중인 배팅머니</td>
                                            <td>당첨예상머니</td>
                                        </tr>

                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td><NumericFormat value={initialState.home.stayMoney? initialState.home.stayMoney : "-"} displayType={'text'} thousandSeparator={true}/></td>
                                            <td><NumericFormat value={initialState.home.stayPoint? initialState.home.stayPoint : "-"} displayType={'text'} thousandSeparator={true}/></td>
                                            <td><NumericFormat value={initialState.home.betPeople? initialState.home.betPeople : "-"} displayType={'text'} thousandSeparator={true}/></td>
                                            <td><NumericFormat value={initialState.home.betCount? initialState.home.betCount : "-"} displayType={'text'} thousandSeparator={true}/></td>
                                            <td><NumericFormat value={initialState.home.betMoney? initialState.home.betMoney : "-"} displayType={'text'} thousandSeparator={true}/></td>
                                            <td><NumericFormat value={initialState.home.betWinMoney? initialState.home.betWinMoney : "-"} displayType={'text'} thousandSeparator={true}/></td>
                                        </tr>
                                        </tbody>

                                    </table>
                                </div>
                                <div>
                                    <p>- 통계</p>
                                    <table className="table table-bordered table-sm text-center align-middle">
                                        <thead className="table-dark">
                                        <tr>
                                            <td>구분</td>
                                            <td>충전</td>
                                            <td>환전</td>
                                            <td>충전건수</td>
                                            <td>충환차액</td>
                                            <td>카지노충전</td>
                                            <td>카지노환전</td>
                                            <td>카지노충환차액</td>
                                            <td>회원가입</td>
                                            <td>방문자</td>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>오늘</td>
                                            <td><NumericFormat value={initialState.home.todayDeposit? initialState.home.todayDeposit : "-"} displayType={'text'} thousandSeparator={true}/></td>
                                            <td><NumericFormat value={initialState.home.todayWithdraw? initialState.home.todayWithdraw : "-"} displayType={'text'} thousandSeparator={true}/></td>
                                            <td><NumericFormat value={initialState.home.todayDepositCount? initialState.home.todayDepositCount : "-"} displayType={'text'} thousandSeparator={true}/></td>
                                            <td><NumericFormat value={initialState.home.todayDeposit - initialState.home.todayWithdraw} displayType={'text'} thousandSeparator={true}/></td>
                                            <td><NumericFormat value={initialState.home.todayCasinoDeposit? initialState.home.todayCasinoDeposit : "-"} displayType={'text'} thousandSeparator={true}/></td>
                                            <td><NumericFormat value={initialState.home.todayCasinoWithdraw? initialState.home.todayCasinoWithdraw : "-"} displayType={'text'} thousandSeparator={true}/></td>
                                            <td><NumericFormat value={initialState.home.todayCasinoDeposit - initialState.home.todayCasinoWithdraw} displayType={'text'} thousandSeparator={true}/></td>
                                            <td><NumericFormat value={initialState.home.todayJoin? initialState.home.todayJoin : "-"} displayType={'text'} thousandSeparator={true}/></td>
                                            <td><NumericFormat value={initialState.home.todayLogin? initialState.home.todayLogin : "-"} displayType={'text'} thousandSeparator={true}/></td>
                                        </tr>
                                        <tr>
                                            <td>어제</td>
                                            <td><NumericFormat value={initialState.home.yesterdayDeposit? initialState.home.yesterdayDeposit : "-"} displayType={'text'} thousandSeparator={true}/></td>
                                            <td><NumericFormat value={initialState.home.yesterdayWithdraw? initialState.home.yesterdayWithdraw : "-"} displayType={'text'} thousandSeparator={true}/></td>
                                            <td><NumericFormat value={initialState.home.yesterdayDepositCount? initialState.home.yesterdayDepositCount : "-"} displayType={'text'} thousandSeparator={true}/></td>
                                            <td><NumericFormat value={initialState.home.yesterdayDeposit - initialState.home.yesterdayWithdraw} displayType={'text'} thousandSeparator={true}/></td>
                                            <td><NumericFormat value={initialState.home.yesterdayCasinoDeposit? initialState.home.yesterdayCasinoDeposit : "-"} displayType={'text'} thousandSeparator={true}/></td>
                                            <td><NumericFormat value={initialState.home.yesterdayCasinoWithdraw? initialState.home.yesterdayCasinoWithdraw : "-"} displayType={'text'} thousandSeparator={true}/></td>
                                            <td><NumericFormat value={initialState.home.yesterdayCasinoDeposit - initialState.home.yesterdayCasinoWithdraw} displayType={'text'} thousandSeparator={true}/></td>
                                            <td><NumericFormat value={initialState.home.yesterdayJoin? initialState.home.yesterdayJoin : "-"} displayType={'text'} thousandSeparator={true}/></td>
                                            <td><NumericFormat value={initialState.home.yesterdayLogin? initialState.home.yesterdayLogin : "-"} displayType={'text'} thousandSeparator={true}/></td>
                                        </tr>
                                        <tr>
                                            <td>이달</td>
                                            <td><NumericFormat value={initialState.home.monthDeposit? initialState.home.monthDeposit : "-"} displayType={'text'} thousandSeparator={true}/></td>
                                            <td><NumericFormat value={initialState.home.monthWithdraw? initialState.home.monthWithdraw : "-"} displayType={'text'} thousandSeparator={true}/></td>
                                            <td><NumericFormat value={initialState.home.monthDepositCount? initialState.home.monthDepositCount : "-"} displayType={'text'} thousandSeparator={true}/></td>
                                            <td><NumericFormat value={initialState.home.monthDeposit - initialState.home.monthWithdraw} displayType={'text'} thousandSeparator={true}/></td>
                                            <td><NumericFormat value={initialState.home.monthCasinoDeposit? initialState.home.monthCasinoDeposit : "-"} displayType={'text'} thousandSeparator={true}/></td>
                                            <td><NumericFormat value={initialState.home.monthCasinoWithdraw? initialState.home.monthCasinoWithdraw : "-"} displayType={'text'} thousandSeparator={true}/></td>
                                            <td><NumericFormat value={initialState.home.monthCasinoDeposit - initialState.home.monthCasinoWithdraw} displayType={'text'} thousandSeparator={true}/></td>
                                            <td><NumericFormat value={initialState.home.monthJoin? initialState.home.monthJoin : "-"} displayType={'text'} thousandSeparator={true}/></td>
                                            <td><NumericFormat value={initialState.home.monthLogin? initialState.home.monthLogin : "-"} displayType={'text'} thousandSeparator={true}/></td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <HomeHoPartnerTotalContainer initialState={initialState} />
                                <HomeEtcListComponent initialState={initialState} />
                            </div>
                        </div>
                    )}
                </div>
            </div>
    </>
}

export default HomeMainComponent;