import {call, put, take,} from "redux-saga/effects";
import {failedGetTotalBetting, setTotalBetting} from "../../reducers/betting/totalBetting";
import {fetchTotalBetting} from "../../../lib/api";

export function* getTotalBettingSaga(action) {
    try{
        const res = yield call(fetchTotalBetting,
            action.payload.page, action.payload.pageSize, action.payload.query,
            action.payload.order, action.payload.startDate, action.payload.endDate
        );
        yield put(setTotalBetting(res.data))
    }
    catch (error) {
        console.log(error)
        yield put(failedGetTotalBetting(error))
    }
}