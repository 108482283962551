import React from 'react';
import AttendContainer from "../../containers/user/AttendListContainer";


const Attend = () => {
    return (
        <div>
            <h1>출석부</h1>
            <AttendContainer />
        </div>
    );
};

export default Attend;