import {call, put, take,} from "redux-saga/effects";
import {fetchCancelMatch, fetchCancelMatchBy} from "../../../lib/api";
import {closeChannel, createSocketChannel} from "../../channel/createSocketChannel";
import socket from "../../../config/socket";
import {
    changeCancelMatchBy,
    failedGetCancelMatchBy,
    pushCancelMatchByTask,
    setCancelMatchBy
} from "../../reducers/match/cancelMatchBy";

export function* getCancelMatchBySaga(action) {
    try {
        const res = yield call(fetchCancelMatchBy, action.payload.page, action.payload.pageSize, action.payload.sport, action.payload.market, action.payload.provider, action.payload.league, action.payload.query);
        yield put(setCancelMatchBy(res.data));
    } catch(error) {
        console.log(error)
        yield put(failedGetCancelMatchBy(error));
    }
}

export function* waitCancelMatchByTaskSaga() {
    let channel;
    try {
        channel = yield call(createSocketChannel, "cancelMatchBy");
        while(true) {
            const task = yield take(channel);
            yield onTaskSaga(task)
        }
    } catch (e) {
        console.log(e, "error");
    } finally {
        socket.close();
        closeChannel(channel)
    }
}

function* onTaskSaga(task) {
    yield put(pushCancelMatchByTask(task));
    yield put(changeCancelMatchBy(task));
}

export function* changeCancelMatchBySaga() {
    //yield put(changeSport(changedItem))
}

