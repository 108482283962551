import React, {useEffect, useState} from 'react';
import axios from "axios";
import AdminAddComponent from "../../components/AdminAddComponent";
import {useDispatch, useSelector} from "react-redux";
import {getAdminReducer,setAdminOpen,setAdminNos} from "../../redux/reducers/user/AdminReducer";
import AdminComponent from "../../components/user/AdminComponent";
import Pagination from "../../components/common/Pagination";
const AdminContainer = () => {
    const dispatch = useDispatch()
    const initialState = useSelector((state) => state.AdminReducer)
    const userInfo = useSelector((state) => state.userInfo);

    const getAdminList = () => {
        if(initialState.adminList !== null) {
            dispatch(getAdminReducer({
                page: initialState.page,
                pageSize: initialState.pageSize,
            }))
        }
    }
    useEffect(getAdminList, [dispatch])
    const adminOpenHandler = () => {
        dispatch(setAdminOpen(true));
    }
    const adminCloseHandler = () => {
        dispatch(setAdminOpen(false));
        getAdminList()
    }



    const adminDelete = (e) => {
        if(initialState.adminNos.length > 0) {
            axios.post('/admin/admin/admin_delete', {
                adminNos: initialState.adminNos
            }).then((res) => {
                alert(res.data.isDeleted? "삭제완료" : "삭제실패")
                getAdminList()
            })
        }
        else {
            alert('선택해주세요')
        }
    }
    const adminLevelUpdate = (level) => {
        if(initialState.adminNos.length > 0) {
            axios.post('/admin/admin/change_level', {
                level: level,
                adminNos: initialState.adminNos,
            }).then((res) => {
                alert(res.data.isUpdated? "변경완료" : "변경실패")
                getAdminList()
            })
        }
    }
    const adminStateUpdate = (user_state) => {
        if(initialState.adminNos.length > 0) {
            axios.post('/admin/admin/change_use', {
                user_state: user_state,
                adminNos: initialState.adminNos,
            }).then((res) => {
                alert(res.data.isUpdated? "변경완료" : "변경실패")
                getAdminList()
            })
        }
    }

    const handlePageChange = (page) => {
        dispatch(getAdminReducer({
            page: page,
            pageSize: initialState.pageSize,
        }))
    }
    const allList = () => {
        getAdminList()
    }


    return (
        <div className="m-2">
            <div className="card-body">
                <div>
                    <div className="container-fluid">
                        <div className="row bg-light align-items-center" style={{
                            height: "50px"
                        }}>
                            <div className="col align-items-center justify-content-end d-flex" style={{
                                height: "50px"
                            }}>
                                <div className="input-group align-items-end" style={{
                                    width: "95px",
                                    height: "40px",
                                }}>
                                    <button type="button" className="btn btn-outline-secondary mx-1" >엑셀출력</button>
                                </div>
                                <select className="form-select" style={{
                                    width: "120px",
                                    height: "40px"
                                }}
                                        value={initialState.pageSize === "999" ? "모두" : initialState.pageSize}
                                        onChange={(e) => {
                                            dispatch(getAdminReducer({
                                                page:1,
                                                pageSize: parseInt(e.target.value),
                                            }))
                                        }}
                                >
                                    <option value={20}>20개씩</option>
                                    <option value={50}>50개씩</option>
                                    <option value={100}>100개씩</option>
                                    <option value={200}>200개씩</option>
                                    <option value={999}>모두</option>
                                </select>
                            </div>
                        </div>
                        <div>
                            <div className="my-3 d-flex">
                                <span className="my-auto" onClick={allList} style={{cursor: "pointer"}}>관리자 : {initialState.adminCount}명</span>

                                <div className="col align-items-center d-flex justify-content-end">
                                    {userInfo.level === 2 ?
                                        <>
                                            <button className="btn btn-secondary me-2" type="button" onClick={() => adminLevelUpdate(0)}>
                                                일반관리자
                                            </button>
                                            <button className="btn btn-success me-2" type="button" onClick={() => adminLevelUpdate(1)}>
                                                서브관리자
                                            </button>
                                            <button className="btn btn-dark me-2" type="button" onClick={() => adminLevelUpdate(2)}>
                                                마스터관리자
                                            </button>
                                            <button className="btn btn-outline-primary me-2" type="button" onClick={() => adminStateUpdate(0)}>
                                                사용
                                            </button>
                                            <button className="btn btn-outline-danger me-2" type="button" onClick={() => adminStateUpdate(1)}>
                                                미사용
                                            </button>
                                        </>
                                        : null}

                                    <button type="button" className="btn btn-outline-secondary" data-bs-toggle="modal" style={{
                                        width: "150px"}} data-bs-target="#adminAdd">관리자추가
                                    </button>
                                    <AdminAddComponent/>

                                    {userInfo.level === 2 ?
                                        <button className="btn btn-outline-secondary ms-2" type="button"
                                                id="button-addon2" onClick={adminDelete}>선택 삭제
                                        </button>
                                        : null}
                                </div>
                            </div>
                        </div>
                        <AdminComponent initialState={initialState}/>
                        <Pagination totalCount={initialState.adminCount} pageSize={initialState.pageSize} currentPage={initialState.page} onPageChange={handlePageChange}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminContainer;