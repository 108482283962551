import {call, put, take,} from "redux-saga/effects";
import {fetchMember} from "../../../lib/api";
import {failedGetMember, setMember} from "../../reducers/user/member";

export function* getMemberSaga(action) {
    try{
        const res = yield call(
            fetchMember,
            action.payload.page, action.payload.pageSize, action.payload.query,
            action.payload.order, action.payload.startDate, action.payload.endDate
        )
        yield put(setMember(res.data))
    }
    catch (error) {
        console.log(error)
        yield put(failedGetMember(error))
    }
}