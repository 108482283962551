import {createSlice} from "@reduxjs/toolkit"
import moment from "moment/moment";

export const basicSettingPageSlice = createSlice({
    name: 'basicSettingPage',
    initialState: {
        isLoading: false,
        error: null,
        basicSettingPage: [],
        page: 1,
        pageSize: 100,
        totalCount: 0,
        order: 0,
        startDate: moment().format('YYYY-MM-DD'),
        endDate: moment().add(1, "day").format('YYYY-MM-DD'),
        query: "",
        basicSettingPage2: [],
        basicSettingPage3: [],
        nationSwitchStates : {},
    },
    reducers: {
        getBasicSettingPage(state, action) {
            state.isLoading = true;
            state.page = action.payload.pageSize === 999? 1: action.payload.page;
            state.pageSize = action.payload.pageSize;
            state.order = action.payload.order
            state.query = action.payload.query
            state.startDate = action.payload.startDate;
            state.endDate = action.payload.endDate;
        },
        setBasicSettingPage(state, action) {
            if((state.page -1) * state.pageSize > action.payload.totalCount) {
                state.page = 1
            }

            state.isLoading = false;
            state.totalCount = action.payload.totalCount;
            state.basicSettingPage = action.payload.result;
            state.basicSettingPage2 = action.payload.result2;
            state.basicSettingPage3 = action.payload.result3;

            let updatedSwitchStates = { ...state.nationSwitchStates };
            for(const r of action.payload.result) {
                if( r.use_yn === 'Y' )
                {
                    updatedSwitchStates["a"+r.sports_cd] = true;
                    state.nationSwitchStates = updatedSwitchStates;
                }

                if( r.check_yn === 'Y' )
                {
                    updatedSwitchStates["b"+r.sports_cd] = true;
                    state.nationSwitchStates = updatedSwitchStates;
                }
            }

            for(const r of action.payload.result2) {
                if( r.use_yn === 'Y' )
                {
                    updatedSwitchStates["c"+r.mark_cd] = true;
                    state.nationSwitchStates = updatedSwitchStates;
                }

                if( r.check_yn === 'Y' )
                {
                    updatedSwitchStates["d"+r.mark_cd] = true;
                    state.nationSwitchStates = updatedSwitchStates;
                }
                if( r.game_expose === 'Y' )
                {
                    updatedSwitchStates["e"+r.mark_cd] = true;
                    state.nationSwitchStates = updatedSwitchStates;
                }
                if( r.dividend_yn === 'Y' )
                {
                    updatedSwitchStates["f"+r.mark_cd] = true;
                    state.nationSwitchStates = updatedSwitchStates;
                }
            }

        },
        failedGetBasicSettingPage(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        setNationSwitchStatesReducer(state, action){
            console.log("##setNationSwitchStatesReducer##", action.payload);
            const code = action.payload;
            let updatedSwitchStates = { ...state.nationSwitchStates };
            // 스위치 상태를 업데이트
            if (state.nationSwitchStates[code]) {
                updatedSwitchStates[code] = false;
            } else {
                updatedSwitchStates[code] = true;
            }
            state.nationSwitchStates = updatedSwitchStates;
        },
    }
});

export const { getBasicSettingPage, setBasicSettingPage, failedGetBasicSettingPage, setNationSwitchStatesReducer } = basicSettingPageSlice.actions;

export default basicSettingPageSlice.reducer;