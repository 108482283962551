import React, {useEffect, useState} from "react";
import moment from "moment";
import typeCase from "../../util/typeCase";
import {NumericFormat} from "react-number-format";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import axios from "axios";
import UserInformation from "../../components/UserInformation";
import NoticeListContainer from "../../containers/board/NoticeListContainer";
import imageCompression from "browser-image-compression";
import commonInfo from "../../config/commonInfo";

const NoticeList = ({notice, onRefresh}) => {

    const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().add(1, "day").format('YYYY-MM-DD'));
    const [noticeList, setNoticeList] = useState([]);
    const [noticeNos, setNoticeNos] = useState([]);
    const [title, setTitle] = useState([]);
    const [cont, setCont] = useState([]);
    const [noticeCount, setNoticeCount] =useState(0);
    const [imageFile, setImageFile] = useState();
    const [imageUrl, setImageUrl] = useState("");
    const [query, setQuery] = useState("");
    const [updates, setUpdates] = useState([]);
    const [updateImageFile, setUpdateImageFile] = useState();
    const [updateImageUrl, setUpdateImageUrl] = useState("0");

    const initialState = useSelector((state) => state.notice)

    const imageOptions = {
        maxSizeMB: 10,
        maxWidth: 1920,
        useWebWorker: true,
    }

    const userInfo = useSelector((state) => state.userInfo);

    const getNoticeList = () => {
        axios.post('/admin/notice/get_notice_list', {
            adminNo: userInfo.userNo
        }).then((res) => {
            //console.log(res.data)
            if(res.data.noticeList !== null) {
                const updates2 = []
                for(let i = 0; i < res.data.noticeList.length; i++) {
                    const item = {
                        no: res.data.noticeList[i].no,
                        title: res.data.noticeList[i].title,
                        cont: res.data.noticeList[i].cont,
                    }
                    updates2.push(item)
                }
                setUpdates(updates2)
                setNoticeList(res.data.noticeList)
                setNoticeCount(res.data.noticeCount)
                setNoticeNos([])
            }
        })
    }
    useEffect(getNoticeList, [userInfo.userNo])



    const startDateHandler = (e) => {
        setStartDate(e.currentTarget.value)
    }
    const endDateHandler = (e) => {
        setEndDate(e.currentTarget.value)
    }
    const onTitleHandler = (e) =>{
        setTitle(e.currentTarget.value);
    }
    const onContHandler = (e) =>{
        setCont(e.currentTarget.value);
    }

    const allCheckHandler = (e) => {
        if(noticeList.length > 0 && e.currentTarget.value) {
            const checks = [];
            for(let i = 0; i < noticeList.length; i++) {
                checks.push(noticeList[i].no)
            }
            setNoticeNos(e.target.checked? checks : [])
        }
    }
    const checkHandler = (checked, no) => {


        let noticeNos2 = []
        if(checked) {
            noticeNos2 = [...noticeNos, no]
            setNoticeNos([...noticeNos, no])
        }
        else {
            noticeNos2 = noticeNos.filter((id) => id !== no)
            setNoticeNos(noticeNos.filter((id) => id !== no))
        }
        let totalCheck = 0;
        for(let i = 0; i < noticeNos2.length; i++) {
            totalCheck += noticeList.filter((id) => id.no === noticeNos2[i])[0].no
        }
    }
    const updatesTitleHandler = (value, no) => {
        const updates2 = updates.filter((item) => item.no !== no)
        const beforeItem = updates.filter((item) => item.no === no)[0]
        beforeItem.title = value
        updates2.push(beforeItem)
        setUpdates(updates2)
    }
    const updatesContHandler = (value, no) => {
        const updates2 = updates.filter((item) => item.no !== no)
        const beforeItem = updates.filter((item) => item.no === no)[0]
        beforeItem.cont = value
        updates2.push(beforeItem)
        setUpdates(updates2)
    }

    const noticeUpdate = (no, controllerId) => {

        const updatedItem = updates.filter((item) => item.no === no)[0]
        const noticeUpdate = []
        noticeUpdate.push(updatedItem)

        const formData = new FormData()
        formData.append("image", updateImageFile);
        formData.append('data', JSON.stringify(noticeUpdate));
        const headers = {
            "Content-Type": "multipart/form-data"
        };


        if (window.confirm("수정 하시겠습니까?"))
        {
            axios.post('/admin/notice/update', formData, {headers}
            ).then((res) => {
                alert(res.data.success? "수정완료" : "수정실패")
                //getNoticeList()
                onRefresh();
                // setTitle([]);
                // setCont([]);


                //alert('controllerId >>> '+controllerId);
                //document.getElementById(controllerId).click();

            })
        }
        
        
    }

    const previewImage = async (e, change) => {
        e.preventDefault();
        const file = e.target.files[0];
        try {
            const compressedFile = await imageCompression(file, imageOptions);
            const reader = new FileReader();

            reader.readAsDataURL(compressedFile);
            reader.onloadend = () => {
                // 변환 완료!
                const base64data = reader.result;
                handlingDataForm(base64data, change);
            };
        } catch (error) {
            alert("이미지파일 등록실패")
        }
    }

    const handlingDataForm = async (base64data, change) => {
        // dataURL 값이 data:image/jpeg:base64,~~~~~~~ 이므로 ','를 기점으로 잘라서 ~~~~~인 부분만 다시 인코딩
        const byteString = atob(base64data.split(",")[1]);

        // Blob를 구성하기 위한 준비, 이 내용은 저도 잘 이해가 안가서 기술하지 않았습니다.
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([ia], {
            type: "image/jpg"
        });
        const file = new File([blob], ".jpg");
        // 위 과정을 통해 만든 image폼을 FormData에 넣어줍니다.
        // 서버에서는 이미지를 받을 때, FormData가 아니면 받지 않도록 세팅해야합니다.

        if (change === 0) {
            const fileUrl = URL.createObjectURL(file); // (*)
            setImageFile(file)
            setImageUrl(fileUrl)
        } else {
            const fileUrl = URL.createObjectURL(file); // (*)
            setUpdateImageFile(file)
            setUpdateImageUrl(fileUrl)
        }
    }

    const noticeInsert = () => {
        if(imageFile === undefined) {

            if (window.confirm("등록 하시겠습니까?"))
            {
                axios.post('/admin/notice/insert_text', {
                    title: title,
                    cont: cont,
                    adminNo: userInfo.userNo
                }).then((res) => {
                    alert(res.data.isInserted? "등록완료" : "등록실패")
                    getNoticeList()
                    onRefresh();
                    setTitle([]);
                    setCont([]);

                    document.getElementById("button-addon3").click();
                })
            }


        } else {
            const formData = new FormData()
            formData.append("image", imageFile);
            formData.append('data', JSON.stringify({
                    title: title,
                    cont: cont,
                    adminNo: userInfo.userNo
                }),
            );
            const headers = {
                "Content-Type": "multipart/form-data"
            };

            if (window.confirm("등록 하시겠습니까?")) {
                axios.post('/admin/notice/insert', formData, {headers}
                ).then((res) => {
                    alert(res.data.isInserted ? "등록완료" : "등록실패")
                    //getNoticeList()
                    onRefresh();
                    setTitle([]);
                    setCont([]);
                    document.getElementById("button-addon3").click();

                })
            }
        }
    }

    const noticeDelete = (e) => {
        if(noticeNos.length > 0) {

            if (window.confirm("삭제 하시겠습니까?")) {
                axios.post('/admin/notice/delete', {
                    noticeNos: noticeNos
                }).then((res) => {
                    alert(res.data.isDeleted? "삭제완료" : "삭제실패")
                    //getNoticeList()
                    onRefresh();
                })
            }


        }
        else {
            alert('선택해주세요')
        }
    }
    const queryHandler = (e) => {
        setQuery(e.target.value)
    }
    // const inputPress = (e) => {
    //     if(e.key === "Enter") {
    //         searchHandler(query, startDate, endDate)
    //     }
    // }

    const searchHandler = (query, startDate, endDate) => {
        axios.post('/admin/notice/query', {
            start_date: startDate,
            end_date: endDate,
            query: query,
        }).then((res) => {
            if (res.data.noticeList !== null) {
                setNoticeList(res.data.noticeList)
                setNoticeCount(res.data.noticeCount)
            }
        })
    }

    return (
        <div className="m-2">
            <div className="card-body">
                <div className="container-fluid">
                    <div className="my-2 d-flex">
                        <span>(공지수 : {noticeCount && noticeCount[0].noticeCount} 개)</span>
                    </div>
                </div>


                <div>
                    <table className="table table-bordered table-sm text-center align-middle">
                        <thead className="table-dark">
                        <tr>
                            <th style={{width: "2%"}}>
                                <input className="form-check-input" type="checkbox" checked={noticeList.length === noticeNos.length}
                                       onChange={allCheckHandler} id="flexCheckDefault"/>
                            </th>
                            <th>제목</th>
                            <th style={{width: "10%"}}>등록일시</th>
                            <th style={{width: "10%"}}>수정일시</th>
                            <th style={{width: "5%"}}>수정</th>
                            <th style={{width: "5%"}}>관리자</th>
                        </tr>
                        </thead>

                        {!notice.length && (
                            <tbody key={notice.no}>
                            <tr>
                                <td colSpan="6">목록이 비어있습니다.</td>
                            </tr>
                            </tbody>
                        )}


                        {notice.map((notice, index) => (
                            <tbody key={notice.no}>
                            <tr>
                                <td>
                                    <input className="form-check-input" type="checkbox"
                                           checked={noticeNos.filter((no) => no === notice.no).length > 0}
                                           onChange={(e) => {checkHandler(e.currentTarget.checked, notice.no)}}
                                    />
                                </td>
                                <td>
                                    <Link className="nav-link" to={"#collapse" + index} data-bs-toggle="collapse"
                                          aria-expanded="true" aria-controls={"#collapse" + index}>
                                        {notice.title}
                                    </Link>
                                </td>
                                <td>{moment(notice.createdAt).format("YYYY-MM-DD HH:mm")}</td>
                                <td>{moment(notice.updatedAt).format("YYYY-MM-DD HH:mm")}</td>
                                <td>
                                    <button className="btn btn-outline-secondary ms-2" type="button"
                                            data-bs-toggle="collapse" data-bs-target={"#collapse2" + index}
                                            aria-expanded="false" aria-controls={"collapse2" + index}  style={{
                                        width: "60px"
                                    }}>수정
                                    </button>
                                </td>
                                <td>{notice.admin.id}</td>
                            </tr>
                            <tr className="collapse" id={"collapse" + index}>
                                <td></td>
                                <td>
                                    {notice.cont}
                                    <br></br>
                                    {notice.image !== "0"?
                                        <img src={commonInfo.imgUrl + notice.image} alt="" />
                                        :""}
                                </td>
                                <td colSpan="3"></td>
                            </tr>
                            <tr className="collapse" id={"collapse2" + index}>
                                <td colSpan="4">
                                    <div className="update">
                                        <div className="my-3 d-flex">
                                            <label htmlFor="exampleFormControlInput1" className="form-label" style={{
                                                width: "50px",
                                                marginTop: "5px"
                                            }}>제목</label>
                                            <input type="text" className="form-control" id="exampleFormControlInput1" maxLength="200"
                                                   value={updates.filter((item) => item.no === notice.no).length > 0? updates.filter((item) => item.no === notice.no)[0].title : ""}
                                                   onChange={(e) => {updatesTitleHandler(e.currentTarget.value, notice.no)}}
                                            />
                                        </div>
                                        <div className="mb-3 d-flex">
                                            <label htmlFor="exampleFormControlTextarea1" className="form-label" style={{
                                                width: "50px"
                                            }}>내용</label>
                                            <textarea className="form-control"  id="exampleFormControlTextarea1" maxLength="2000"
                                                      value={updates.filter((item) => item.no === notice.no).length > 0?
                                                          updates.filter((item) => item.no === notice.no)[0].cont : ""}
                                                      onChange={(e) => {updatesContHandler(e.currentTarget.value, notice.no)}}
                                            >
                                                </textarea>
                                        </div>
                                        <div className="text-start">
                                            <div>
                                                <img src={updates.filter((file) => file.no === notice.no).length > 0 ?
                                                    updateImageUrl === "0"? commonInfo.imgUrl + notice.image : updateImageUrl
                                                    :""} alt="" />
                                            </div>
                                            <input type="file" accept=".jpeg, .jpg, .png"  onChange={(e) => previewImage(e, 1)} />
                                        </div>
                                        <div className="text-center">
                                            <button className="btn btn-outline-secondary" type="button"
                                                    id="button-addon2"
                                                    onClick={()=> {
                                                        noticeUpdate(notice.no, "collapse2" + index)
                                                    }}
                                            >내용수정
                                            </button>
                                        </div>

                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        ))}
                    </table>
                </div>

                <div className="d-flex">
                    <button className="btn btn-outline-secondary me-2" type="button"
                            data-bs-toggle="collapse" data-bs-target="#addCollapse"
                            aria-expanded="false" aria-controls="addCollapse"  id="button-addon3" >추가
                    </button>
                    <button className="btn btn-outline-secondary me-4" type="button"
                            id="button-addon2" onClick={noticeDelete}>선택 삭제
                    </button>
                </div>

                <div id="addCollapse" className="add collapse">
                    <div className="my-3 d-flex">
                        <label htmlFor="exampleFormControlInput1" className="form-label" style={{
                            width: "50px",
                            marginTop: "5px"
                        }}>제목</label>
                        <input type="text" className="form-control" maxLength="200" name="title" value={title} id="exampleFormControlInput1" onChange={onTitleHandler}/>
                    </div>
                    <div className="my-3 d-flex">
                        <label htmlFor="exampleFormControlTextarea1" className="form-label" style={{
                            width: "50px"
                        }}>내용</label>
                        <textarea className="form-control" name="cont" maxLength="2000" value={cont} id="exampleFormControlTextarea1" rows="3" onChange={onContHandler}></textarea>
                    </div>
                    <div>
                        <div>
                            <img  src={imageUrl} style={{maxWidth: "100%"}} alt=""/>
                        </div>

                        <input type="file" accept=".jpeg, .jpg, .png"  onChange={(e) => previewImage(e, 0)} />
                    </div>
                    <div className="text-center">
                        <button className="btn btn-outline-secondary" type="button"
                                id="button-addon2"
                                onClick={() => noticeInsert()}
                        >내용등록
                        </button>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default NoticeList;