import React from 'react';
import ResultMatchListContainer from "../../containers/ResultMatchListContainer";

/**
 * 함수형 컴포넌트 스니펫입니다.
 */
const ResultMatch = () => {
    return (
        <div>
            <h1>경기결과</h1>
            <ResultMatchListContainer />
        </div>
    );
};

export default ResultMatch;