import {createSlice} from "@reduxjs/toolkit"

export const PointLogMngReducerSlice = createSlice({
    name: 'PointLogMngReducer',
    initialState: {
        isLoading: false,
        error: null,
        pointLogMngList: [],
        totalList: [],
        page: 1,
        pageSize: 20,
        totalCount: 0,
        pointLogListCount: 0,
        order: 0,
        selectedNos: [],
        totalSelectedPoint : 0,
        IsOpen : false
    },
    reducers: {
        getPointLogMngReducer(state, action) {
            console.log("#reducers getPointLogMngReducer start#",action.payload.pageSize,action.payload.page);
            state.isLoading = true;
            state.page = action.payload.pageSize === 999? 1: action.payload.page;
            state.pageSize = action.payload.pageSize;
            state.order = action.payload.order;
            state.query = action.payload.query
            state.startDate = action.payload.startDate;
            state.endDate = action.payload.endDate;
        },
        setPointLogMngReducer(state, action) {
            console.log("#reducers setPointLogMngReducer start#",action.payload.pointLogList);
            if((state.page -1) * state.pageSize > action.payload.totalCount) {
                state.page = 1
            }
            state.isLoading = false;
            state.totalList = action.payload.totalList;
            state.totalCount = action.payload.totalCount;
            state.pointLogMngList = action.payload.pointLogList;
            state.pointLogListCount = action.payload.pointLogListCount;
            //초기화
            state.selectedNos = [];
            state.totalSelectedPoint = 0;
        },
        failedGetPointLogMngReducer(state, action) {
            console.log("#reducers failedGetPointLogMngReducer start#");
            state.isLoading = false;
            state.error = action.payload;
        },
        setSelectedNos(state, action) {
            state.selectedNos = action.payload;
        },
        setTotalSelectedPoint(state, action) {
            state.totalSelectedPoint = action.payload;
        },
    }
});

export const { getPointLogMngReducer, setPointLogMngReducer, failedGetPointLogMngReducer,setSelectedNos,setTotalSelectedPoint } = PointLogMngReducerSlice.actions;

export default PointLogMngReducerSlice.reducer;