import {createSlice} from "@reduxjs/toolkit"
import moment from "moment/moment";


export const statsSlice = createSlice({
    name: 'ConnectListReducer',
    initialState: {
        isLoading: false,
        error: null,
        totalCount: 0,
        joinListCount: 0,
        userList: [],
        storeList: [],
        year: moment().format('YYYY'),
        month: moment().format('MM'),
    },
    reducers: {
        getConnectListReducer(state, action) {
            console.log("##getConnectListReducer##", action.payload.page,action.payload.pageSize,action.payload.year,action.payload.month,action.payload.date);
            state.isLoading = true;
            state.year = action.payload.year;
            state.month = action.payload.month;
            state.date = action.payload.date;

        },
        setConnectListReducer(state, action) {
            console.log("#setConnectListReducer#", action.payload);
            state.isLoading = false;
            state.totalCount = action.payload.totalCount;
            state.joinListCount = action.payload.joinListCount;
            state.userList = action.payload.userList;
            state.storeList = action.payload.storeList;
        },
        failedConnectListReducer(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        setYear(state, action) {
            state.year = action.payload;
        },
        setMonth(state, action) {
            state.month = action.payload;
        },
    }
});

export const {
    getConnectListReducer,
    setConnectListReducer,
    failedConnectListReducer,
    setYear,
    setMonth,
} = statsSlice.actions;

export default statsSlice.reducer;