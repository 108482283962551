import React from 'react';
import DailyFeeContainer from "../../containers/agent/DailyFeeContainer";


const daily_fee = () => {
    return (
        <div>
            <h6>가맹점배당금관리</h6>
            <DailyFeeContainer />
        </div>
    );
};

export default daily_fee;