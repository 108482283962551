import React, {useEffect, useState} from "react";
import moment from "moment";
import typeCase from "../../util/typeCase";
import {NumericFormat} from "react-number-format";
import {Link} from "react-router-dom";
import axios from "axios";
import {useSelector} from "react-redux";
import UserInformation from "../../components/UserInformation";
const DepositCompleteList = ({deposit}) => {
    const userInfo = useSelector((state) => state.userInfo)
    const [depositTotal, setDepositTotal] = useState(0);
    const [depositTypeSum, setDepositTypeSum] = useState(0);
    const [totalSelectedMoney, setTotalSelectedMoney] = useState(0);
    const [depositStateSum, setDepositStateSum] = useState(0);
    const [userDeposit, setUserDeposit] = useState([]);
    const [userWithdraw, setUserWithdraw] = useState([]);
    const [monthDeposit, setMonthDeposit] = useState([]);
    const [monthWithdraw, setMonthWithdraw] = useState([]);

    const [userInformation, setUserInformation] = useState(false);
    const initialState = useSelector((state) => state.depositComplete)


    const getAllList = () => {
        axios.post('/admin/deposit/get_all_list', {
            adminNo: userInfo.userNo
        }).then((res) => {
            setDepositTotal(res.data.depositTotal)
            setDepositTypeSum(res.data.depositTypeSum)
            setUserDeposit(res.data.userDeposit)
            setUserWithdraw(res.data.userWithdraw)
            setMonthDeposit(res.data.monthDeposit)
            setMonthWithdraw(res.data.monthWithdraw)
            setDepositStateSum(res.data.depositStateSum)
            setTotalSelectedMoney(0)
        })
    }
    useEffect(getAllList, [])

    const informationOpenHandler = () => {
        setUserInformation(true);
    }


    function onCommentColor (color) {
        switch (color) {
            case 0 : return "red"
            case 1 : return "orange"
            case 2 : return "yellow"
            case 3 : return "green"
            case 4 : return "blue"
            case 5 : return "navy"
            case 6 : return "purple"
            case 10 : return "gray"
            default : return color
        }
    }

    return (
        <div className="m-2">

            <div className="row my-2">
                <div className="col-5">
                                <span>전체충전신청 :
                                    <NumericFormat className="ms-1" value={depositTotal && depositTotal[0].depositTotal} displayType={'text'} thousandSeparator={true}/>
                                </span>
                </div>

            </div>
            <div className="my-2" style={{
                display: "flex"
            }}>
                <div className="col-5">
                    <h4 className="text-danger">선택금액 :
                        <NumericFormat className="ms-1" value={totalSelectedMoney} displayType={'text'} thousandSeparator={true}/>
                    </h4>
                </div>
                <div className="col-7" style={{
                    textAlign: "right"
                }}>
                                <span className="text-success">충전완료 금액 :
                                    {depositStateSum.length > 0?
                                        <NumericFormat className="ms-1" value={depositStateSum[2].deposit_state === 2?
                                            depositStateSum[2].depositStateSum : 0 } displayType={'text'} thousandSeparator={true}/>
                                        : <>0</>}
                                </span>
                </div>
            </div>

            <div className="container-fluid">
                    <table className="table table-bordered table-sm text-center align-middle">
                        <thead className="table-dark">
                        <tr>
                            <td style={{width: "3%"}}>파트너</td>
                            <td style={{width: "3%"}}>상태</td>
                            <td style={{width: "3%"}}>등급</td>
                            <td style={{width: "6%"}}>닉네임</td>
                            <td style={{width: "5%"}}>충전</td>
                            <td style={{width: "5%"}}>환전</td>
                            <td style={{width: "5%"}}>충환차액</td>
                            <td style={{width: "5%"}}>이달수익</td>
                            <td style={{width: "5%"}}>보유머니</td>
                            <td style={{width: "3%"}}>예금주</td>
                            <td style={{width: "6%"}}>충전금액</td>
                            <td style={{width: "6%"}}>추가포인트</td>
                            <td style={{width: "8%"}}>요청시간</td>
                            <td style={{width: "8%"}}>처리시간</td>
                            <td style={{width: "4%"}}>처리상태</td>
                            <td style={{width: "4%"}}>충전구분</td>
                            <td style={{width: "3%"}}>관리자</td>
                        </tr>
                        </thead>
                        {!deposit.length && (
                            <tbody key={deposit.no}>
                            <tr>
                                <td colSpan="17">목록이 비어있습니다.</td>
                            </tr>
                            </tbody>
                        )}
                        {deposit.map((bet, index) => (
                            <tbody key={deposit.no}>
                            <tr>
                                <td>본사</td>
                                <td>{typeCase.userState(bet.user.user_state)}</td>
                                <td>{typeCase.levelState(bet.user.level)}</td>
                                <td>
                                    <Link className="nav-link" to={"#userInformation" + index} data-bs-toggle="collapse"
                                          aria-expanded="false" aria-controls={"#userInformation" + index}
                                          onClick={informationOpenHandler}>
                                        {bet.user.comment ?
                                            <>
                                                <span>{bet.user.nickname}</span>
                                                <br></br>
                                                <span style={{fontSize: "12px"}}>
                                                    <span className="me-1" style={{backgroundColor: onCommentColor(bet.user.comment_color)}}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                    {bet.user.comment}
                                                </span>
                                            </>
                                            :  (bet.user.comment_color !== null?
                                                <>
                                                    <span>{bet.user.nickname}</span>
                                                    <br></br>
                                                    <span className="me-1" style={{backgroundColor: onCommentColor(bet.user.comment_color)}}>
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                        </span>
                                                </>
                                                :  <span>{bet.user.nickname}</span> )}
                                    </Link>
                                </td>
                                <td>
                                    <NumericFormat value={userDeposit.filter((el) => el.user_no === bet.user_no).length > 0? userDeposit.filter((el) => el.user_no === bet.user_no)[0].user_total : 0} displayType={'text'} thousandSeparator={true}/>
                                </td>
                                <td>
                                    <NumericFormat value={userWithdraw.filter((el) => el.user_no === bet.user_no).length > 0? userWithdraw.filter((el) => el.user_no === bet.user_no)[0].user_total : 0} displayType={'text'} thousandSeparator={true}/>
                                </td>
                                <td>
                                    <NumericFormat value={
                                        (userDeposit.filter((el) => el.user_no === bet.user_no).length > 0? userDeposit.filter((el) => el.user_no === bet.user_no)[0].user_total : 0)
                                        -
                                        (userWithdraw.filter((el) => el.user_no === bet.user_no).length > 0? userWithdraw.filter((el) => el.user_no === bet.user_no)[0].user_total : 0)
                                    } displayType={'text'} thousandSeparator={true}/>
                                </td>
                                {/*이달수익*/}
                                <td>
                                        <NumericFormat value={
                                        (monthDeposit && monthDeposit.filter((el) => el.user_no === bet.user_no).length > 0?
                                        monthDeposit.filter((el) => el.user_no === bet.user_no)[0].user_total : 0)
                                        -
                                        (monthWithdraw && monthWithdraw.filter((el) => el.user_no === bet.user_no).length > 0?
                                        monthWithdraw.filter((el) => el.user_no === bet.user_no)[0].user_total : 0)
                                    } displayType={'text'} thousandSeparator={true}/>
                                </td>
                                <td><NumericFormat value={bet.user.money} displayType={'text'} thousandSeparator={true}/></td>
                                <td>{bet.user.name}</td>
                                <td>
                                    <NumericFormat value={bet.request_money} displayType={'text'} thousandSeparator={true}/>
                                </td>
                                <td>-</td>
                                <td>
                                    {moment(bet.createdAt).format("YYYY-MM-DD")}
                                    <br></br>
                                    {moment(bet.createdAt).format(" HH:mm:ss")}
                                </td>
                                <td>
                                    {moment(bet.updatedAt).format("YYYY-MM-DD")}
                                    <br></br>
                                    {moment(bet.updatedAt).format("HH:mm:ss")}

                                </td>
                                <td>{typeCase.depositState(bet.deposit_state)}</td>
                                <td>{typeCase.depositType(bet.deposit_type)}</td>
                                <td>{bet.admin? bet.admin.id : "-"}</td>
                            </tr>
                            <tr className="collapse" id={"userInformation" + index} key={bet.user.id}>
                                <td colSpan="18">
                                    {userInformation && <UserInformation no={bet.user.no} id={bet.user.id}/> }
                                </td>
                            </tr>
                            </tbody>
                        ))}
                    </table>

            </div>

        </div>
    );
}

export default DepositCompleteList;