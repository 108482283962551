import React, {useState} from "react";
import axios from "axios";
import {useDispatch} from "react-redux";
import {setCheckedInputs, setImageFiles, setNameInputs, setUpdateData} from "../../redux/reducers/match/sport";
import imageCompression from "browser-image-compression";

const SportList = ({sports}) => {
    const dispatch = useDispatch()

    const [image, setImage] = useState("")

    const imageOptions = {
        maxSizeMB: 0.2,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
    }

    const handleChange = async (e, id, index) => {
        e.preventDefault();
        const file = e.target.files[0];
        console.log(file)

        try {
            const compressedFile = await imageCompression(file, imageOptions);

            // FileReader 는 File 혹은 Blob 객체를 이용하여, 파일의 내용을 읽을 수 있게 해주는 Web API
            const reader = new FileReader();

            reader.readAsDataURL(compressedFile);
            reader.onloadend = () => {
                // 변환 완료!
                const base64data = reader.result;

                handlingDataForm(id, base64data, index);

            };
        } catch (error) {
            console.log(error);
            alert("이미지파일이 아니거나, 업로드 할 수 없는 파일입니다.")

        }

    };

    const handlingDataForm = async (id, base64data, index) => {
        // dataURL 값이 data:image/jpeg:base64,~~~~~~~ 이므로 ','를 기점으로 잘라서 ~~~~~인 부분만 다시 인코딩
        const byteString = atob(base64data.split(",")[1]);

        // Blob를 구성하기 위한 준비, 이 내용은 저도 잘 이해가 안가서 기술하지 않았습니다.
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([ia], {
            type: "image/jpeg"
        });
        const file = new File([blob], id + ".jpg");

        // 위 과정을 통해 만든 image폼을 FormData에 넣어줍니다.
        // 서버에서는 이미지를 받을 때, FormData가 아니면 받지 않도록 세팅해야합니다.


        //TODO post 보내기

        const data = {
            no: sports.sports[index].no,
            id: sports.sports[index].id,
            name: sports.sports[index].name,
            name_kor: sports.sports[index].name_kor,
            is_active: sports.sports[index].is_active,
            image: sports.sports[index].image,
            createdAt: sports.sports[index].createdAt,
            updatedAt: sports.sports[index].updatedAt,
        }

        const updateData2 = sports.updateData.filter((el) => el.id !== id)
        const updateData3 = sports.updateData.filter((el) => el.id === id)
        if(updateData3.length > 0) {
            data.is_active = updateData3[0].is_active
            data.name_kor = updateData3[0].name_kor
        }
        data.image = {id: id, image: file, isChange: 1};
        dispatch(setUpdateData([...updateData2, data]))
        const imageFiles2 = sports.imageFiles.filter((el) => el.id !== id)
        const fileUrl = URL.createObjectURL(file); // (*)
        dispatch(setImageFiles([...imageFiles2, {id: id, image: file, isChange: 1,}]))
        setImage(fileUrl)

    }


    const changeHandler = (checked, id, index) => {
        if(checked) {
            dispatch(setCheckedInputs([...sports.checkedInputs, id]));
        } else {
            // 체크 해제
            dispatch(setCheckedInputs(sports.checkedInputs.filter((el) => el !== id)));
        }
        const data = {
            no: sports.sports[index].no,
            id: sports.sports[index].id,
            name: sports.sports[index].name,
            name_kor: sports.sports[index].name_kor,
            is_active: sports.sports[index].is_active,
            image: sports.sports[index].image,
            createdAt: sports.sports[index].createdAt,
            updatedAt: sports.sports[index].updatedAt,
        }
        const updateData2 = sports.updateData.filter((el) => el.id !== id)
        const updateData3 = sports.updateData.filter((el) => el.id === id)
        if(updateData3.length > 0) {
            data.name_kor = updateData3[0].name_kor
        }
        data.is_active = checked;
        dispatch(setUpdateData([...updateData2, data]))

    };

    const changeName = (value, id, index) => {
        const data = {
            no: sports.sports[index].no,
            id: sports.sports[index].id,
            name: sports.sports[index].name,
            name_kor: sports.sports[index].name_kor,
            is_active: sports.sports[index].is_active,
            image: sports.sports[index].image,
            createdAt: sports.sports[index].createdAt,
            updatedAt: sports.sports[index].updatedAt,
        }

        const updateData2 = sports.updateData.filter((el) => el.id !== id)
        const updateData3 = sports.updateData.filter((el) => el.id === id)
        if(updateData3.length > 0) {
            data.is_active = updateData3[0].is_active
            data.image = updateData3[0].image
        }
        data.name_kor = value;
        dispatch(setUpdateData([...updateData2, data]))
        const nameInputs2 = sports.nameInputs.filter((el) => el.id !== id)
        dispatch(setNameInputs([...nameInputs2, {id: id, name_kor: value}]))
    }

    const saveSport = async () => {
        axios.post('/api/updateSports', sports.updateData).then( (res) => {
                if(res.status === 200) {
                    dispatch(setUpdateData([]));
                    alert("저장완료");
                }
            }
        )
    }
    const saveSportTest = async () => {
        const formData = new FormData();

        const hasImages = sports.updateData.filter((el) => el.image.isChange === 1)

        for(const sport of hasImages) {
            formData.append("img", sport.image.image)
        }

        formData.append("data", JSON.stringify(sports.updateData) )

        const headers = {
            "Content-Type": "multipart/form-data"
        };

        axios.post('/api/updateSportsTest', formData, {headers }).then( (res) => {
                if(res.status === 200) {
                    dispatch(setUpdateData([]));
                    alert("저장완료");
                }
            }
        )
    }

    const getSportsByServer = async () => {
        const response =  await axios.get('/limit/getSports',{

        })
        console.log(response.data)
    }



    return (
        <div>
            <div className="mb-1">
                <button className="btn btn-primary me-1" onClick={getSportsByServer} >불러오기</button>
                <button className="btn btn-secondary" onClick={saveSportTest} >저장</button>
            </div>

            {sports._isLoading && "로딩중..."}
            {!sports._isLoading && sports.sports && (
                <table className="table table-bordered table-hover text-center">
                    <thead>
                    <tr>
                        <th>종목</th>
                        <th>종목한글</th>
                        <th>ID</th>
                        <th>종목 이미지</th>
                        <th>관리</th>
                    </tr>
                    </thead>
                    <tbody>
                    {!sports.sports.length && (
                        <tr>
                            <td colSpan="3">목록이 비어있습니다.</td>
                        </tr>
                    )}
                    {!!sports.sports.length && sports.sports.map((sport, index) => (
                        <tr>
                            <td>{sport.name}</td>
                            <td>
                                <input
                                    name={sport.no}
                                    type="text"
                                    value={sports.nameInputs.filter((e) => e.id === sport.id).length > 0 ? sports.nameInputs.filter((e) => e.id === sport.id)[0].name_kor: ""}
                                    onChange={(e) => {
                                        changeName(e.currentTarget.value, sport.id, index)
                                    }}
                                />
                            </td>
                            <td>{sport.id}</td>
                            <td>
                                <div  style={{
                                    display: "flex"
                                }}>
                                    <input type="file" accept="image/*" onChange={(e) => {handleChange(e, sport.id, index)}} />
                                    {/*<img src={sports.imageFiles.filter((e) => e.id === sport.id).length > 0 ?
                                        sports.imageFiles.filter((e) => e.id === sport.id)[0].isChange === 0 ?
                                            "/images/"+ sports.imageFiles.filter((e) => e.id === sport.id)[0].image
                                            : sports.imageFiles.filter((e) => e.id === sport.id)[0].isChange === 1 ?
                                                URL.createObjectURL(sports.imageFiles.filter((e) => e.id === sport.id)[0].image)
                                                : null
                                        : ""} width="25" height="20" alt={sport.id} />*/}
                                </div>

                            </td>
                            <td>
                                <label>
                                    사용여부
                                    <input type="checkbox" name={sport.id} id={sport.id} value="true"
                                           checked={sports.checkedInputs.includes(sport.id)}
                                           onChange={(e) => {changeHandler(e.currentTarget.checked, sport.id, index)}}
                                    />
                                </label>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            )}
        </div>
    )
}
export default SportList;