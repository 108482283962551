import React, {useState} from 'react';
import {Outlet, useNavigate} from "react-router-dom";

const User = () => {

    const navigate = useNavigate();
    const [activeNav, setActiveNav] = useState();
    const ontoggle = (index, address) => {
        setActiveNav(index)
        navigate(address)
    }
    const menuArr = [
        {name:"회원관리", address:"/user/member"},
        {name:"출석부", address:"/user/attend"},
        {name:"쪽지관리", address:"/user/memo_list"},
        {name:"로그인내역", address:"/user/login_log"},
        {name:"차단내역", address:"/user/block_list"},
        {name:"가입통계", address:"/user/join_list"},
        {name:"접속중", address:"/user/connect_list"},
        {name:"분실문의", address:"/user/lost_list"},
        {name:"접속오류", address:"/user/fail_list"},
        {name:"관리자관리", address:"/user/admin"},
        {name:"관리자로그인실패", address:"/user/admin_fail_log"},
    ]

    return (
        <div>
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
                <div className="collapse navbar-collapse" style={{
                    marginLeft: "290px",
                    height: "40px"
                }}>
                    <ul className="navbar-nav text-center">
                        {menuArr.map((menu,index) => (
                            <li key={index} className="mx-2" onClick={() => ontoggle(index, menu.address)}>
                                <div className={activeNav === index ? "nav-item active" : "nav-item"} style={{cursor: "pointer"}}>
                                    {menu.name}
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>

            </nav>
            <div style={{
                paddingLeft: "10px"
            }}>
                <Outlet />
            </div>

        </div>

    )
}

export default User;