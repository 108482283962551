import React, {useEffect} from 'react';
import MarketListApiSettingComponent from "../../components/match/MarketListApiSettingComponent";
import Pagination from "../../components/common/Pagination";
import {useDispatch, useSelector} from "react-redux";
import {
    getMarketApiSettingInfoListReducer,
    setMarketNm,
    setMarketUseYn
} from "../../redux/reducers/match/marketApiSettingReducer";
import axios from "axios";
const MarketListApiSettingContainer = () => {
    const dispatch = useDispatch()
    const initialState = useSelector((state) => state.marketApiSettingReducer)

    const getMarketList = () => {
        if(initialState.marketList !== null) {
            dispatch(getMarketApiSettingInfoListReducer({
                marketPage: initialState.marketPage,
                marketPageSize: initialState.marketPageSize,
                marketUseYn: initialState.marketUseYn,
                marketNm: initialState.marketNm,
            }))
        }
    }

    useEffect(getMarketList, [dispatch])

    const handlePageChange = (page) => {
        dispatch(getMarketApiSettingInfoListReducer({
            marketPage: page,
            marketPageSize: initialState.marketPageSize,
            marketUseYn: initialState.marketUseYn,
            marketNm: initialState.marketNm,
        }))
    }

    const useOption = [
        {value: "", name: "전체"},
        {value: "Y", name: "사용"},
        {value: "N", name: "미사용"},
    ]

    const query = (marketUseYn) => {
        dispatch(getMarketApiSettingInfoListReducer({
            marketPage: 1,
            marketPageSize: initialState.marketPageSize,
            marketUseYn: marketUseYn,
            marketNm: initialState.marketNm,
        }))
    }

    const inputPress = (e) => {
        if(e.key === "Enter") {
            query(initialState.marketUseYn)
        }
    }

    const marketNmHandler = (e) => {
        dispatch(setMarketNm(e.target.value))
    }

    const changeUseYn = (e) => {
        dispatch(setMarketUseYn(e.target.value))
        console.log(e.target.value)
        query(e.target.value)
    }

    const getCode = () => {
        axios.post('/admin/getCmmnCd', {
            tableId: "admins",
            columnNm: "user_state",
           // useYn: "Y",
            inCd: ["1","2","3"],
            notInCd: ["1"],
        }).then((res) => {
            console.log("##res.data.cmmnCd##",res.data.cmmnCd);
        })
    }
    return (
        <div>
            <div className="container-fluid me-3 p-1" style={{
                display: "flex"
                ,justifyContent: "flex-end"
            }}>
                <div className="input-group me-1" style={{
                    display: "flex",
                    width: "800px",
                    alignItems: "center",
                    justifyContent: "flex-end"
                }}>
                    <p className="me-2" style={{width: "60px", margin: 0}}>사용여부</p>
                    <select className="form-select me-4"
                            style={{width: "50px"}} onChange={changeUseYn}>
                        {useOption.map((item) => (
                            <option key={item.value} value={item.value}>{item.name}</option>
                        ))}
                    </select>
                    <p className="me-2" style={{width: "50px", margin: 0}}>마켓명</p>
                    <input style={{
                        width: "220px"
                    }} type="text" className="form-control" id="search_edit" placeholder="마켓명" onChange={marketNmHandler} onKeyDown={inputPress} />
                    <button style={{
                        width: "80px"
                        ,borderRadius: "5px"
                    }} type="submit" className="btn btn-primary" onClick={() => query(initialState.marketUseYn)}>검색</button>
                    <button style={{width: "100px",backgroundColor : "green" ,borderColor : "green",borderRadius: "5px"}} type="submit" className="btn btn-primary mx-1" onClick={() => getCode()} >가져오기</button>
                </div>
            </div>
            <MarketListApiSettingComponent initialState = {initialState} query={query}/>
            <div className="d-flex justify-content-center"> {/* text-center 클래스 추가 */}
                {initialState.totalMarketCount && initialState.totalMarketCount > 0 ?
                <Pagination totalCount={initialState.totalMarketCount} pageSize={initialState.marketPageSize} currentPage={initialState.marketPage} onPageChange={handlePageChange}/>
                    :""}
            </div>
        </div>
    );
};

export default MarketListApiSettingContainer;