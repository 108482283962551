import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import axios from "axios";
import imageCompression from "browser-image-compression";
import commonInfo from "../../config/commonInfo";
import moment from "moment/moment";
const Event = ({event, onRefresh}) => {

    const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().add(1, "day").format('YYYY-MM-DD'));
    const [eventList, setEventList] = useState([]);
    const [eventNos, setEventNos] = useState([]);
    const [title, setTitle] = useState([]);
    const [cont, setCont] = useState([]);
    const [count, setCount] =useState(0);
    const [updates, setUpdates] = useState([]);
    const [imageFile, setImageFile] = useState()
    const [imageUrl, setImageUrl] = useState("")
    const [query, setQuery] = useState("");
    const [updateImageFile, setUpdateImageFile] = useState();
    const [updateImageUrl, setUpdateImageUrl] = useState("");
    const [replyCont, setReplyCont] = useState([]);
    const [fakeId, setFakeId] = useState([]);

    const initialState = useSelector((state) => state.event)

    const imageOptions = {
        maxSizeMB: 10,
        maxWidth: 1920,
        useWebWorker: true,
    }

    const userInfo = useSelector((state) => state.userInfo);

    const getEventList = () => {
        axios.post('/admin/event/get_event_list', {
            adminNo: userInfo.userNo
        }).then((res) => {
            //console.log(res.data)
            if(res.data.eventList !== null) {
                const updates2 = []
                for(let i = 0; i < res.data.eventList.length; i++) {
                    const item = {
                        no: res.data.eventList[i].no,
                        title: res.data.eventList[i].title,
                        cont: res.data.eventList[i].cont,
                    }
                    updates2.push(item)
                }
                setUpdates(updates2)
                setEventList(res.data.eventList)
                //console.log(res.data.eventList)
                setCount(res.data.count)
                setEventNos([])
            }

        })
    }
    useEffect(getEventList, [userInfo.userNo])

    const startDateHandler = (e) => {
        setStartDate(e.currentTarget.value)
    }
    const endDateHandler = (e) => {
        setEndDate(e.currentTarget.value)
    }
    const onTitleHandler = (e) =>{
        setTitle(e.currentTarget.value);
    }
    const onContHandler = (e) =>{
        setCont(e.currentTarget.value);
    }

    const allCheckHandler = (e) => {
        if(eventList.length > 0 && e.currentTarget.value) {
            const checks = [];
            for(let i = 0; i < eventList.length; i++) {
                checks.push(eventList[i].no)
                //console.log(eventList[i].no)
            }
            setEventNos(e.target.checked? checks : [])
        }
    }
    const checkHandler = (checked, no) => {
        let eventNos2 = []
        if(checked) {
            eventNos2 = [...eventNos, no]
            setEventNos([...eventNos, no])
        }
        else {
            eventNos2 = eventNos.filter((id) => id !== no)
            setEventNos(eventNos.filter((id) => id !== no))
        }
        let totalCheck = 0;
        for(let i = 0; i < eventNos2.length; i++) {
            totalCheck += eventList.filter((id) => id.no === eventNos2[i])[0].no
        }
        //console.log(totalCheck)
    }
    const updatesEventTitleHandler = (value, no) =>{
        const updates2 = updates.filter((item) => item.no !== no)
        const beforeItem = updates.filter((item) => item.no === no)[0]
        beforeItem.title = value
        updates2.push(beforeItem)
        setUpdates(updates2)
        //console.log(updates2)
    }
    const updatesEventContHandler = (value, no) =>{
        const updates2 = updates.filter((item) => item.no !== no)
        const beforeItem = updates.filter((item) => item.no === no)[0]
        beforeItem.cont = value
        updates2.push(beforeItem)
        setUpdates(updates2)
        //console.log(updates2)
    }

    const previewImage = async (e, change) => {
        e.preventDefault();
        const file = e.target.files[0];
        try {
            const compressedFile = await imageCompression(file, imageOptions);
            const reader = new FileReader();

            reader.readAsDataURL(compressedFile);
            reader.onloadend = () => {
                // 변환 완료!
                const base64data = reader.result;
                handlingDataForm(base64data, change);
            };
        } catch (error) {
            alert("이미지파일 등록실패")
        }
    }

    const handlingDataForm = async (base64data, change) => {
        // dataURL 값이 data:image/jpeg:base64,~~~~~~~ 이므로 ','를 기점으로 잘라서 ~~~~~인 부분만 다시 인코딩
        const byteString = atob(base64data.split(",")[1]);

        // Blob를 구성하기 위한 준비, 이 내용은 저도 잘 이해가 안가서 기술하지 않았습니다.
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([ia], {
            type: "image/jpg"
        });
        const file = new File([blob], ".jpg");

        // 위 과정을 통해 만든 image폼을 FormData에 넣어줍니다.
        // 서버에서는 이미지를 받을 때, FormData가 아니면 받지 않도록 세팅해야합니다.
        if (change === 0) {
            const fileUrl = URL.createObjectURL(file); // (*)
            setImageFile(file)
            setImageUrl(fileUrl)
        } else {
            const fileUrl = URL.createObjectURL(file); // (*)
            setUpdateImageFile(file)
            setUpdateImageUrl(fileUrl)
        }
    }
    const eventInsert = () => {
        if(imageFile === undefined) {

            if (window.confirm("등록 하시겠습니까?")) {
                axios.post('/admin/event/insert_text', {
                    title: title,
                    cont: cont,
                    adminNo: userInfo.userNo
                }).then((res) => {
                    alert(res.data.isInserted? "등록완료" : "등록실패")
                    getEventList()
                    onRefresh()

                    setTitle([]);
                    setCont([]);
                    document.getElementById("button-addon3").click();
                })
            }


        } else {
            const formData = new FormData()
            formData.append("image", imageFile);
            formData.append('data', JSON.stringify({
                    title: title,
                    cont: cont,
                    adminNo: userInfo.userNo
                }),
            );
            const headers = {
                "Content-Type": "multipart/form-data"
            };

            if (window.confirm("등록 하시겠습니까?")) {
                axios.post('/admin/event/insert', formData, {headers}
                ).then((res) => {
                    alert(res.data.isInserted? "등록완료" : "등록실패")
                    //getEventList()
                    onRefresh()

                    setTitle([]);
                    setCont([]);
                    document.getElementById("button-addon3").click();
                })
            }

        }
    }
    const eventUpdate = (no) => {
        const updatedItem = updates.filter((item) => item.no === no)[0]
        const eventUpdate = []
        eventUpdate.push(updatedItem)

        const formData = new FormData()
        formData.append("image", updateImageFile);
        formData.append('data', JSON.stringify(eventUpdate));
        //console.log(eventUpdate)
        const headers = {
            "Content-Type": "multipart/form-data"
        };

        if (window.confirm("수정 하시겠습니까?")) {
            axios.post('/admin/event/update', formData, {headers}
            ).then((res) => {
                alert(res.data.success? "수정완료" : "수정실패")
                //getEventList()
                onRefresh()

            })
        }

    }
    const eventDelete = (e) => {
        if(eventNos.length > 0) {

            if (window.confirm("삭제 하시겠습니까?")) {
                axios.post('/admin/event/delete', {
                    eventNos: eventNos
                }).then((res) => {
                    alert(res.data.isDeleted? "삭제완료" : "삭제실패")
                    //getEventList()
                    onRefresh()
                })
            }


        }
        else {
            alert('선택해주세요')
        }
    }
    const onFakeIdHandler = (e) =>{
        setFakeId(e.currentTarget.value);
    }
    const onReplyContHandler = (e) =>{
        setReplyCont(e.currentTarget.value);
    }

    const eventInsertReply = (eventNo) => {
        if (window.confirm("등록 하시겠습니까?")) {
            axios.post('/admin/event/insert_reply', {
                eventNo: eventNo,
                fakeId: fakeId,
                cont: replyCont,
                adminNo: userInfo.userNo
            }).then((res) => {
                alert(res.data.isInserted? "등록완료" : "등록실패")
                getEventList()
            })
        }

    }
    const eventReplyDelete = (eventNo) => {

        if (window.confirm("삭제 하시겠습니까?")) {
            axios.post('/admin/event/delete_Reply', {
                eventNo: eventNo,
                adminNo: userInfo.userNo
            }).then((res) => {
                alert(res.data.isDeleted? "삭제완료" : "삭제실패")
                //getEventList()
                onRefresh()
            })
        }

    }

    const queryHandler = (e) => {
        setQuery(e.target.value)
    }
    const inputPress = (e) => {
        if(e.key === "Enter") {
            searchHandler(query, startDate, endDate)
        }
    }

    const searchHandler = (query, startDate, endDate) => {
        axios.post('/admin/event/query', {
            start_date: startDate,
            end_date: endDate,
            query: query,
        }).then((res) => {
            if (res.data.eventList !== null) {
                setEventList(res.data.eventList)
            }
        })
    }

    const replace = () => {
        window.location.replace('/board/event')
    }

    return (
        <div className="m-2">

            <div className="card-body">
                <div>
                    <div className="container-fluid">


                        <div className="my-2 d-flex">
                            <span>(이벤트수 : {count} 개)</span>
                        </div>

                    </div>
                    <div>

                        <table className="table table-bordered table-sm text-center align-middle">
                            <thead className="table-dark">
                            <tr>
                                <th style={{width: "30px"}}>
                                    <input className="form-check-input" type="checkbox" checked={eventList.length === eventNos.length}
                                           onChange={allCheckHandler} id="flexCheckDefault"/>
                                </th>
                                <td>제목</td>
                                <th style={{width: "10%"}}>등록일시</th>
                                <th style={{width: "10%"}}>수정일시</th>
                                <th style={{width: "6%"}}>수정</th>
                                <th style={{width: "9%"}}>관리자</th>
                            </tr>
                            </thead>

                            {!event.length && (
                                <tbody key={event.no}>
                                <tr>
                                    <td colSpan="6">목록이 비어있습니다.</td>
                                </tr>
                                </tbody>
                            )}

                            {event.map((event, index) => (
                                <tbody key={event.no}>
                                <tr>
                                    <td>
                                        <input className="form-check-input" type="checkbox"
                                               checked={eventNos.filter((no) => no === event.no).length > 0}
                                               onChange={(e) => {checkHandler(e.currentTarget.checked, event.no)}}
                                        />
                                    </td>
                                    <td>
                                        <Link className="nav-link" to={"#collapse" + index} data-bs-toggle="collapse"
                                              aria-expanded="true" aria-controls={"#collapse" + index}>
                                            {event.title}
                                        </Link>
                                    </td>
                                    <td>{moment(event.createdAt).format("YYYY-MM-DD HH:mm:ss")}</td>
                                    <td>{moment(event.updatedAt).format("YYYY-MM-DD HH:mm:ss")}</td>
                                    <td>
                                        <button className="btn btn-outline-secondary ms-2" type="button"
                                                data-bs-toggle="collapse" data-bs-target={"#collapse2" + index}
                                                aria-expanded="false" aria-controls={"collapse2" + index} style={{
                                            width: "60px"
                                        }}>수정
                                        </button>
                                    </td>
                                    <td>{event.admin.id}</td>
                                </tr>
                                <tr className="collapse" id={"collapse" + index}>
                                    <td></td>
                                    <td>
                                        {event.cont}
                                        <br></br>
                                        <img src={commonInfo.imgUrl + event.image} alt="" />
                                        <br></br>
                                        <br></br>
                                        {event.event_replies.map((event_reply, index) => (

                                            <div className="list-group list-group-flush text-start border border-dark">
                                                <div className="d-flex my-auto justify-content-between ">
                                                    <div className="d-flex m-2">
                                                        <span>아이디 : {event_reply.fakeId}</span>
                                                        <span className="ms-3">댓글 내용 : {event_reply.cont}</span>
                                                    </div>
                                                    <div>
                                                        <button className="btn btn-outline-secondary me-1"
                                                                type="button" id="button-addon2" onClick={() => eventReplyDelete(event_reply.no)}>
                                                            삭제
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}

                                        <div className="d-flex mt-4">
                                            <label className="my-auto" style={{width: "10%"}}>아이디</label>
                                            <input type="text" maxLength="12" value={fakeId} onChange={onFakeIdHandler}/>

                                            <label className="my-auto" style={{width: "10%"}}>댓글내용</label>
                                            <textarea className="form-control" maxLength="100" value={replyCont} rows="1"
                                                      onChange={onReplyContHandler}>
                                                </textarea>
                                        </div>



                                        <div className="text-center justify-content-center d-flex my-2" style={{
                                            width: "100%"
                                        }}>

                                            <button className="btn btn-primary me-1" type="button" id="button-addon2"
                                                    onClick={() => eventInsertReply(event.no)}>저장
                                            </button>
                                            <button className="btn btn-outline-secondary" type="button"
                                                    id="button-addon2" onClick={replace}>목록
                                            </button>
                                        </div>

                                    </td>
                                    <td colSpan="2"></td>

                                </tr>
                                <tr className="collapse" id={"collapse2" + index}>
                                    <td colSpan="4">
                                        <div className="update">
                                            <div className="my-3 d-flex">
                                                <label htmlFor="exampleFormControlInput1" className="form-label" style={{
                                                    width: "50px",
                                                    marginTop: "5px"
                                                }}>제목</label>
                                                <input type="text" maxLength="200" className="form-control" id="exampleFormControlInput1"
                                                       value={updates.filter((item) => item.no === event.no).length > 0? updates.filter((item) => item.no === event.no)[0].title : ""}
                                                       onChange={(e) => {updatesEventTitleHandler(e.currentTarget.value, event.no)}}
                                                />
                                            </div>
                                            <div className="mb-3" style={{
                                                display: "flex"
                                            }}>
                                                <label htmlFor="exampleFormControlTextarea1" className="form-label" style={{
                                                    width: "50px"
                                                }}>내용</label>
                                                <textarea className="form-control" maxLength="1000" id="exampleFormControlTextarea1"
                                                          value={updates.filter((item) => item.no === event.no).length > 0? updates.filter((item) => item.no === event.no)[0].cont : ""}
                                                          onChange={(e) => {updatesEventContHandler(e.currentTarget.value, event.no)}}
                                                >
                                                    </textarea>
                                            </div>
                                            <div className="text-start">
                                                <div>
                                                    <img src={updates.filter((file) => file.no === event.no).length > 0 ?
                                                        updateImageUrl === ""? commonInfo.imgUrl + event.image : updateImageUrl
                                                        :""} alt="" />
                                                </div>
                                                <input type="file" accept=".jpeg, .jpg, .png"  onChange={(e) => previewImage(e, 1)} />
                                            </div>
                                            <div className="text-center">
                                                <button className="btn btn-outline-secondary" type="button"
                                                        id="button-addon2"
                                                        onClick={()=> {
                                                            eventUpdate(event.no)
                                                        }}
                                                >내용수정
                                                </button>
                                            </div>

                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            ))}
                        </table>
                    </div>

                    <div className="d-flex">
                        <button className="btn btn-outline-secondary me-2" type="button"
                                data-bs-toggle="collapse" data-bs-target="#addCollapse"
                                aria-expanded="false" aria-controls="addCollapse" id="button-addon3"  >추가
                        </button>
                        <button className="btn btn-outline-secondary me-4" type="button"
                                id="button-addon2" onClick={eventDelete}>선택 삭제
                        </button>
                    </div>

                    <div id="addCollapse" className="add collapse">
                        <div className="my-3 d-flex">
                            <label htmlFor="exampleFormControlInput1" className="form-label" style={{
                                width: "50px",
                                marginTop: "5px"
                            }}>제목</label>
                            <input type="text" maxLength="200" className="form-control" value={title} id="exampleFormControlInput1" onChange={onTitleHandler}/>
                        </div>
                        <div className="my-3 d-flex">
                            <label htmlFor="exampleFormControlTextarea1" className="form-label" style={{
                                width: "50px"
                            }}>내용</label>
                            <textarea className="form-control" maxLength="1000" value={cont} id="exampleFormControlTextarea1" rows="3" onChange={onContHandler}></textarea>
                        </div>
                        <div>
                            <div>
                                <img src={imageUrl} style={{maxWidth: "100%"}} alt=""/>
                            </div>

                            <input type="file" accept=".jpeg, .jpg, .png"  onChange={(e) => previewImage(e, 0)} />
                        </div>
                        <div className="text-center">
                            <button className="btn btn-outline-secondary" type="button"
                                    id="button-addon2"
                                    onClick={() => eventInsert()}
                            >내용등록
                            </button>
                        </div>
                    </div>

                </div>
            </div>

        </div>

    )
}

export default Event;