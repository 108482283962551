import {createSlice} from "@reduxjs/toolkit"
export const MenuListReducerSlice = createSlice({
    name: 'MenuListReducer',
    initialState: {
        isLoading: false,
        error: null,

        //output
        menuList: [],
        totalCount: 0,
    },
    reducers: {
        getMenuListReducer(state, action) {
            state.isLoading = true;
        },
        setMenuListReducer(state, action) {
            console.log("##setMenuListReducer.menuList##",action.payload.menuList);
            console.log("##setMenuListReducer.totalCount##",action.payload.totalCount);
            state.isLoading = false;
            state.totalCount = action.payload.totalCount;
            state.menuList = action.payload.menuList;
        },
        failedGetMenuListReducer(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
    }
});

export const {
    getMenuListReducer,
    setMenuListReducer,
    failedGetMenuListReducer,
} = MenuListReducerSlice.actions;

export default MenuListReducerSlice.reducer;