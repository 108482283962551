import React from 'react';
import ParsingWaitListContainer from "../../containers/ParsingWaitListContainer";

/**
 * 함수형 컴포넌트 스니펫입니다.
 */
const ParsingWait = () => {
    return (
        <div>
            <h1>파싱대기</h1>
            <ParsingWaitListContainer />
        </div>
    );
};

export default ParsingWait;