import React from 'react';
import FailListContainer from "../../containers/user/FailListContainer";

const FailListPage = () => {

    return (
        <div>
            <h1>접속오류</h1>
        <FailListContainer/>
        </div>
    )
}

export default FailListPage;