import {createSlice} from "@reduxjs/toolkit"
import moment from "moment";

export const DailyFeeReducerSlice = createSlice({
    name: 'DailyFeeReducer',
    initialState: {
        isLoading: false,
        error: null,
        //input
        page: 1,
        pageSize: 20,
        order: 0,
        storeName : "",
        storeCode : "",
        startDt : moment().format('YYYY-MM-DD'),
        endDt : moment().format('YYYY-MM-DD'),
        comboYn : "Y", // Y 콤보박스 사용O ,N 콤보박스 사용 X

        //output
        partnerWithdrawList: [],
        totalCount: 0,
        comboPartnerList: [],

        //etc
        storeNameList : [],
        storeCodeList : [],
    },
    reducers: {
        getDailyFeeReducer(state, action) {
            console.log("#reducers getDailyFeeReducer start#",action.payload.page,action.payload.pageSize,action.payload.order,action.payload.storeName,action.payload.storeCode,action.payload.startDt,action.payload.endDt,action.payload.comboYn);
            state.isLoading = true;
            state.page = action.payload.pageSize === 999? 1: action.payload.page;
            state.pageSize = action.payload.pageSize;
            state.order = action.payload.order;
            state.storeName = action.payload.storeName;
            state.storeCode = action.payload.storeCode;
            state.startDt = action.payload.startDt;
            state.endDt = action.payload.endDt;
            state.comboYn = action.payload.comboYn;
        },
        setDailyFeeReducer(state, action) {
            console.log("#reducers setDailyFeeReducer start#",action.payload.partnerWithdrawList,action.payload.totalCount,action.payload.comboPartnerList);
            if((state.page -1) * state.pageSize > action.payload.totalCount) {
                state.page = 1
            }
            state.isLoading = false;
            state.totalCount = action.payload.totalCount;
            state.partnerWithdrawList = action.payload.partnerWithdrawList;

            if(state.comboYn === 'Y'){
                state.storeNameList = action.payload.comboPartnerList;
                state.storeCodeList = action.payload.comboPartnerList;
            }

        },
        failedGetDailyFeeReducer(state, action) {
            console.log("#reducers failedGetDailyFeeReducer start#");
            state.isLoading = false;
            state.error = action.payload;
        },
        setStoreName(state, action){
            state.storeName = action.payload;
        },
        setStoreCode(state, action){
            state.storeCode = action.payload;
        },
        setStartDt(state, action){
            state.startDt = action.payload;
        },
        setEndDt(state, action){
            state.endDt = action.payload;
        },
    }
});

export const {
    getDailyFeeReducer,
    setDailyFeeReducer,
    failedGetDailyFeeReducer,
    setStoreName,
    setStoreCode,
    setStartDt,
    setEndDt,
} = DailyFeeReducerSlice.actions;

export default DailyFeeReducerSlice.reducer;