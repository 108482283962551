import React from 'react';
import moment from "moment";
import {Link, Outlet} from "react-router-dom";
import LeagueListContainer from "../../containers/LeagueListContainer";

const League = () => {
    return (
        <div>
            <div style={{
                paddingLeft: "10px",
                paddingTop: "10px",
            }}>
                <p>
                    리그등록
                </p>
            </div>
            <LeagueListContainer />


        </div>

    )
}

export default League;