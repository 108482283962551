import {createSlice} from "@reduxjs/toolkit"
export const statsSlice = createSlice({
    name: 'sportsApiSettingReducer',
    initialState: {
        //스포츠
        sportsPage: 1,
        sportsPageSize:20,
        sportsList:[],
        totalSportsCount:0,
        selectedRow: null,
        isLoading: false,
        error: null,

    },
    reducers: {
        getSportsApiSettingInfoListReducer(state, action) {
            console.log("##getSportsApiSettingInfoListReducer##", action.payload.sportsPage,action.payload.sportsPageSize);
            state.isLoading = true;
            state.sportsPage = action.payload.sportsPage;
            state.sportsPageSize = action.payload.sportsPageSize;
        },
        setSportsApiSettingInfoListReducer(state, action) {
            console.log("#setSportsApiSettingInfoListReducer#", action.payload);
            if((state.sportsPage -1) * state.sportsPageSize > action.payload.totalSportsCount) {
                state.sportsPage = 1
            }
            state.isLoading = false;
            state.selectedRow = null;
            state.sportsList = action.payload.sportsList;
            state.totalSportsCount = action.payload.totalSportsCount;
        },
        setSportsListChange(state, action) {
            state.isLoading = false;
            state.sportsList = action.payload;
        },
        failedGetSportsApiSettingInfoListReducer(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        setSelectedRow(state, action) {
            state.isLoading = false;
            state.selectedRow = action.payload;
        },

    }
});

export const {
    getSportsApiSettingInfoListReducer,
    setSportsApiSettingInfoListReducer,
    failedGetSportsApiSettingInfoListReducer,
    setSportsListChange,
    setSelectedRow,
} = statsSlice.actions;

export default statsSlice.reducer;