import {useDispatch, useSelector} from "react-redux"
import React, {useEffect, useState} from "react"

import Pagination from "../../components/common/Pagination";
import {getSportMarketListSettingPage} from "../../redux/reducers/match/sportMarketListSettingPage";
import SportsMarketListSettingPageList from "../../components/match/SportsMarketListSettingPage";
import {getEvent} from "../../redux/reducers/board/event";

const SportsMarketListSettingPageListContainer = () => {
    // useDispatch: 컴포넌트 내부에서 스토어의 내장 함수 dispatch를 사용할 수 있게 해주는 hook 이다.
    // useSelector: connect 함수를 대신하여 스토어 상태를 조회
    const dispatch = useDispatch()
    const initialState = useSelector((state) => state.sportMarketListSettingPage)
    const [searchQuery, setSearchQuery] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    // 브라우저 상에 컴포넌트가 나타날 때 상품 목록을 조회하는 함수를 실행
    useEffect(() => {
        dispatch(getSportMarketListSettingPage({
            page: initialState.pages? initialState.pages : 1,
            pageSize: initialState.pageSizes? initialState.pageSizes : 10,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
        }))
    }, [dispatch])

    const handlePageChange = (page) => {
        dispatch(getSportMarketListSettingPage({
            page: initialState.pages? initialState.pages : 1,
            pageSize: initialState.pageSizes? initialState.pageSizes : 10,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
        }))
    }

    const changeQuery = (value) => {
        setSearchQuery(value)
    }

    const inputPress = (e) => {
        if(e.key === "Enter") {
            onSearch(e.target.value)
        }
    }
    const onSearch = (query) => {
        setSearchQuery(query)
        dispatch(getSportMarketListSettingPage({
            page: initialState.pages? initialState.pages : 1,
            pageSize: initialState.pageSizes? initialState.pageSizes : 10,
            order: initialState.order,
            query: query,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
        }))
    }
    const onClickSearch = (e) => {

        dispatch(getSportMarketListSettingPage({
            page: initialState.pages? initialState.pages : 1,
            pageSize: initialState.pageSizes? initialState.pageSizes : 10,
            //page: 2,
            //pageSize: 3,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
        }))
    }

    const onRefresh = () => {
        dispatch(getSportMarketListSettingPage({
            page: initialState.pages? initialState.pages : 1,
            pageSize: initialState.pageSizes? initialState.pageSizes : 10,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
        }))
    }





    return <>

            <div className="container-fluid m-1 p-0" style={{
                display: "flex"
            }}>

                <select
                    className="form-select me-1"
                    defaultValue={0}
                    style={{
                        width: "100px"
                    }}
                    value={initialState.order === 0? 0 : initialState.order}
                    onChange={e =>
                        dispatch(getSportMarketListSettingPage({
                            page: initialState.page,
                            pageSize: initialState.pageSize,
                            order: parseInt(e.target.value),
                            query: searchQuery,
                            startDate: startDate? startDate : initialState.startDate,
                            endDate: endDate? endDate : initialState.endDate,
                        }))
                    }
                >
                    <option value={0}>전체</option>
                </select>
                <div className="input-group me-1" style={{
                    display: "flex",
                    width: "400px"
                }}>
                    <input style={{
                        width: "220px"
                    }} type="text" className="form-control" id="market_nm" placeholder="마켓명" onBlur={(e) => {
                        changeQuery(e.target.value)
                    }} onKeyDown={inputPress} />
                    <button style={{
                        width: "80px"
                    }} type="submit" className="btn btn-primary" onClick={onClickSearch}>검색</button>
                </div>
                <div className="input-group me-1" style={{
                    display: "flex",
                    width: "1200px"
                }}>
                </div>
                <div className="input-group me-1" style={{
                    display: "flex",
                    width: "200px"
                }}>
                    <select
                        className="form-select me-1"
                        defaultValue={0}
                        style={{
                            width: "100px"
                        }}
                        value={initialState.sports_cd === 0? 0 : initialState.sports_cd}
                        onChange={e =>
                            dispatch(getSportMarketListSettingPage({
                                page: initialState.page,
                                pageSize: initialState.pageSize,
                                sports_cd: e.target.value,
                                query: searchQuery,
                                startDate: startDate? startDate : initialState.startDate,
                                endDate: endDate? endDate : initialState.endDate,
                            }))
                        }
                    >
                        <option value={0}>전체</option>
                        <option value={1}>축구</option>
                        <option value={2}>농구</option>
                        <option value={3}>야구</option>
                        <option value={4}>배구</option>
                        <option value={5}>아이스 하키</option>
                        <option value={6}>미식축구</option>
                        <option value={7}>e스포츠</option>
                    </select>

                </div>
            </div>


            <SportsMarketListSettingPageList sportMarketListSettingPage={initialState.sportMarketListSettingPage} sportsMarketListSettingPage2={initialState.sportMarketListSettingPage2}  onRefresh={onRefresh} initialState={initialState}  />
            <Pagination totalCount={initialState.totalCount} pageSize={initialState.pageSize} currentPage={initialState.page} onPageChange={handlePageChange}/>


    </>





}
export default SportsMarketListSettingPageListContainer