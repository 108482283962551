import {createSlice} from "@reduxjs/toolkit"
import moment from "moment/moment";
export const joinCodeSlice = createSlice({
    name: 'joinCode',
    initialState: {
        isLoading: false,
        error: null,
        join_code: [],
        page: 1,
        pageSize: 100,
        totalCount: 0,
        order: 0,
        startDate: moment().format('YYYY-MM-DD'),
        endDate: moment().add(1, "day").format('YYYY-MM-DD'),
        query: "",
    },
    reducers: {
        getJoinCode(state, action) {
            state.isLoading = true;
            state.page = action.payload.pageSize === 999? 1: action.payload.page;
            state.pageSize = action.payload.pageSize;
            state.order = action.payload.order
            state.query = action.payload.query
            state.startDate = action.payload.startDate;
            state.endDate = action.payload.endDate;
        },
        setJoinCode(state, action) {
            if((state.page -1) * state.pageSize > action.payload.totalCount) {
                state.page = 1
            }
            state.isLoading = false;
            state.totalCount = action.payload.totalCount;
            state.join_code = action.payload.result;
        },
        failedGetJoinCode(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        }
    }
});

export const { getJoinCode, setJoinCode, failedGetJoinCode } = joinCodeSlice.actions;

export default joinCodeSlice.reducer;