import React from 'react';
import MarqueeListContainer from "../../containers/board/MarqueeListContainer";


const Marquee = () => {
    return (
        <div>
            <h1>흐르는공지</h1>
            <MarqueeListContainer />
        </div>
    );
};

export default Marquee;