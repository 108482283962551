import React from 'react';
import moment from "moment";
import {Link, Outlet} from "react-router-dom";

const PointSetting = () => {
    return (
        <div>
            <div style={{
                paddingLeft: "10px",
                paddingTop: "10px",
            }}>
                <p>
                    포인트설정
                </p>
            </div>
        </div>

    )
}

export default PointSetting;