import React, {useEffect, useState} from 'react';
import axios from "axios";
import {NumericFormat} from "react-number-format";
import {withWidth} from "@material-ui/core";
import {orange} from "@material-ui/core/colors";
import { ArrowRightCircleFill, ArrowLeftCircleFill, Controller } from "react-bootstrap-icons";
import Switch from 'react-switch';
import americanfootball from "../../images/sports/americanfootball.jpg";
import baseball from "../../images/sports/baseball.jpg";
import basketball from "../../images/sports/basketball.jpg";
import esports from "../../images/sports/esports.jpg";
import icehocky from "../../images/sports/icehocky.jpg";
import soccer from "../../images/sports/soccer.jpg";
import vollyball from "../../images/sports/vollyball.jpg";

import {useDispatch, useSelector} from "react-redux";
import {setNationSwitchStatesReducer} from "../../redux/reducers/match/sportMarketListSettingPage";

const SportsMarketListSettingPage = ({sportMarketListSettingPage, sportsMarketListSettingPage2, onRefresh, initialState}) => {

    const [market_combine_no, setMarketCombineNo] = useState("")
    const [market_combine_edit, setMarketCombineEdit] = useState("")
    const [combine_etc_edit, setCombineEtcEdit] = useState("")
    const [combine_type_select, setCombineTypeSelect] = useState("")
    const [sports_cd_select, setSportsCdSelect] = useState("")
    const [combine_option_select, setCombineOptionSelect] = useState("")

    const imageFile = [soccer,basketball,baseball,vollyball,icehocky,americanfootball,esports];


    const onMarketCombineNoHandler = (e) =>{
        setMarketCombineNo(e.currentTarget.value);
    }
    const onMarketCombineEditHandler = (e) =>{
        setMarketCombineEdit(e.currentTarget.value);
    }
    const onCombineEtcEditHandler = (e) =>{
        setCombineEtcEdit(e.currentTarget.value);
    }
    const onCombineTypeSelectHandler = (e) =>{
        setCombineTypeSelect(e.currentTarget.value);
    }
    const onSportsCdSelectHandler = (e) =>{
        setSportsCdSelect(e.currentTarget.value);
    }
    const onCombineOptionSelectHandler = (e) =>{
        setCombineOptionSelect(e.currentTarget.value);
    }



    const handleToggle = (code) => {
        dispatch(setNationSwitchStatesReducer(code));
    };

    const [isOpen, setIsOpen] = useState(false);
    const [checkMsg, setCheckMsg] = useState([]);
    const dispatch = useDispatch()

    const handleClickOpen = () => {
        setIsOpen(true);
    }
    const handleClose = () => {
        setIsOpen(false);
    }

    const onCheckMsgHandler = (e) =>{
        setCheckMsg(e.currentTarget.value);
    }

    const allUpdate = (toggleNo) => {
        let sportsNo = "";
        let allIncreaseDecrease = "";

        sportsNo = toggleNo;
        allIncreaseDecrease = document.getElementById("all_increase_decrease").value;

        //alert("allIncreaseDecrease"+allIncreaseDecrease);

        if (window.confirm("일괄 적용 하시겠습니까?"))
        {
            axios.post('/admin/sportsMarketListSettingPage/updateAll', {
                    all_increase_decrease: allIncreaseDecrease,
                }
            ).then((res) => {
                alert(res.data.success? "변경완료" : "변경실패")
                onRefresh();
            })
        }

    }


    const update = (toggleNo, no) => {

        let sportsNo = "";
        let mininumDividend = "";
        let retrieveRate = "";
        let dividend = "";
        let retrieveRevision = "";
        let increaseDecrease = "";
        let sort = "";
        let point = "";
        let useYn = "";
        let domesticYn = "";
        let liveYn = "";
        let impossibleYn = "";



        sportsNo = toggleNo;
        mininumDividend = document.getElementById("mininum_dividend"+no).value;
        retrieveRate = document.getElementById("retrieve_rate"+no).value;
        dividend = document.getElementById("dividend"+no).value;
        retrieveRevision = document.getElementById("retrieve_revision"+no).value;
        increaseDecrease = document.getElementById("increase_decrease"+no).value;
        sort = document.getElementById("sort"+no).value;
        point = document.getElementById("point"+no).value;

        useYn = (initialState.nationSwitchStates["a"+toggleNo] ? "Y":"N");
        domesticYn = (initialState.nationSwitchStates["b"+toggleNo] ? "Y":"N");
        liveYn = (initialState.nationSwitchStates["c"+toggleNo] ? "Y":"N");
        impossibleYn = (initialState.nationSwitchStates["d"+toggleNo] ? "Y":"N");



        if (window.confirm("변경 하시겠습니까?"))
        {
            axios.post('/admin/sportsMarketListSettingPage/update', {
                    no: sportsNo,
                    mininum_dividend: mininumDividend,
                    retrieve_rate: retrieveRate,
                    dividend: dividend,
                    retrieve_revision: retrieveRevision,
                    increase_decrease: increaseDecrease,
                    sort: sort,
                    point: point,
                    use_yn: useYn,
                    domestic_yn: domesticYn,
                    live_yn: liveYn,
                    impossible_yn: impossibleYn,
                }
            ).then((res) => {
                alert(res.data.success? "변경완료" : "변경실패")
                onRefresh();
            })
        }

    }


    const insertCombine = (updateFlag) => {

        let combineNoEdit = "";
        let marketCombineEdit = "";
        let combineEtcEdit = "";
        let combineTypeSelect = "";
        let sportsCdSelect = "";
        let combineOptionSelect = "";

        combineNoEdit = document.getElementById("market_combine_no").value;
        marketCombineEdit = document.getElementById("market_combine_edit").value;
        combineEtcEdit = document.getElementById("combine_etc_edit").value;
        combineTypeSelect = document.getElementById("combine_type_select").value;
        sportsCdSelect = document.getElementById("sports_cd_select").value;
        combineOptionSelect = document.getElementById("combine_option_select").value;
        
        if( combineNoEdit === "" )
        {
            if (window.confirm("조합배팅 추가 하시겠습니까?"))
            {
                axios.post('/admin/sportsMarketListSettingPage/insertCombine', {
                        market_combine_edit: marketCombineEdit,
                        combine_etc_edit: combineEtcEdit,
                        combine_type_select: combineTypeSelect,
                        sports_cd_select: sportsCdSelect,
                        combine_option_select: combineOptionSelect,
                    }
                ).then((res) => {
                    alert(res.data.success? "추가완료" : "추가실패")
                    setMarketCombineEdit("");
                    setCombineEtcEdit("");
                    setCombineTypeSelect("1");
                    setSportsCdSelect("1");
                    setCombineOptionSelect("1");

                    onRefresh();
                })
            }
        }
        else
        {
            if (window.confirm("조합배팅 변경 하시겠습니까?"))
            {
                axios.post('/admin/sportsMarketListSettingPage/updateCombine', {
                        combineNo: combineNoEdit,
                        market_combine_edit: marketCombineEdit,
                        combine_etc_edit: combineEtcEdit,
                        combine_type_select: combineTypeSelect,
                        sports_cd_select: sportsCdSelect,
                        combine_option_select: combineOptionSelect,
                    }
                ).then((res) => {
                    alert(res.data.success? "변경완료" : "변경실패")
                    setMarketCombineNo("");
                    setMarketCombineEdit("");
                    setCombineEtcEdit("");
                    setCombineTypeSelect("1");
                    setSportsCdSelect("1");
                    setCombineOptionSelect("1");

                    onRefresh();
                })
            }
        }
        
        

    }



    const deleteSportsMarketList = (toggleNo) => {

        let marketNo = toggleNo;


        if (window.confirm("조합배팅 삭제 하시겠습니까?"))
        {
            axios.post('/admin/sportsMarketListSettingPage/deleteSportsMarketList', {
                    marketNo: marketNo,
                }
            ).then((res) => {
                alert(res.data.success? "삭제완료" : "삭제실패")
                onRefresh();
            })
        }

    }




    const addSportsMarketList = (toggleNo, markeyNm) => {

        let sportsNo = "";
        let beforeSportsNo = "";
        sportsNo = toggleNo+"("+markeyNm+")";

        beforeSportsNo = document.getElementById("market_combine_edit").value;

        if( beforeSportsNo != "" )
        {
            //document.getElementById("market_combine_edit").value = beforeSportsNo+"+"+sportsNo;
            setMarketCombineEdit(beforeSportsNo+"+"+sportsNo);
        }
        else
        {
            //document.getElementById("market_combine_edit").value = sportsNo;
            setMarketCombineEdit(sportsNo);
        }

    }



    const updateSportsMarketList = (toggleNo, market_combine, combine_etc,combine_type,sports_cd,combine_option
    ) => {

        //alert("toggleNo >>>> "+toggleNo);
        //document.getElementById("market_combine_no").value = toggleNo;
        setMarketCombineNo("");
        setMarketCombineEdit("");
        setCombineEtcEdit("");
        setCombineTypeSelect("1");
        setSportsCdSelect("1");
        setCombineOptionSelect("1");

        setMarketCombineNo(toggleNo);
        setMarketCombineEdit(market_combine);
        setCombineEtcEdit(combine_etc);
        setCombineTypeSelect(combine_type);
        setSportsCdSelect(sports_cd);
        setCombineOptionSelect(combine_option);
        //alert("value >>>> "+document.getElementById("market_combine_no").value);

    }


    const resetSportsMarketList = () => {

        setMarketCombineNo("");
        setMarketCombineEdit("");
        setCombineEtcEdit("");
        setCombineTypeSelect("1");
        setSportsCdSelect("1");
        setCombineOptionSelect("1");
        // document.getElementById("market_combine_edit").value = "";
        // document.getElementById("combine_etc_edit").value = "";
        // document.getElementById("combine_type_select").value = "1";
        // document.getElementById("sports_cd_select").value = "1";
        // document.getElementById("combine_option_select").value = "1";

    }

    function combineType(type) {
        switch (type) {
            case "1" : return "국내형"
            case "2" : return "해외형"
            case "3" : return "라이브"
            default: break;
        }
    }

    function sportsType(type) {
        switch (type) {
            //case 0 : return "레이어"
            case "1" : return "축구"
            case "2" : return "농구"
            case "3" : return "야구"
            case "4" : return "배구"
            case "5" : return "아이스 하키"
            case "6" : return "미식축구"
            case "7" : return "e스포츠"
            default: break;
        }
    }

    function sportsTypeInt(type) {
        switch (type) {
            //case 0 : return "레이어"
            case 1 : return "축구"
            case 2 : return "농구"
            case 3 : return "야구"
            case 4 : return "배구"
            case 5 : return "아이스 하키"
            case 6 : return "미식축구"
            case 7 : return "e스포츠"
            default: break;
        }
    }




    return (
        <div>
            <div style={{
                paddingLeft: "1px"
            }}>


                <div className="card" style={{
                    margin: "1px"
                }}>
                    <div className="d-flex">
                        <div className="card-body">
                            <div className="" style={{
                                width: "100%",
                                height: "1000px",
                                overflowY: "scroll",
                            }}>

                                <div className="container-fluid m-1 p-0" style={{
                                    display: "flex"
                                }}>
                                    <table>
                                        <tr>
                                            <td>
                                                일괄 배당 증차감:
                                            </td>
                                            <td>
                                                <input style={{
                                                    width: "100px"
                                                }} type="text" className="form-control" id="all_increase_decrease" placeholder="0" onBlur={(e) => {

                                                }}  />
                                            </td>
                                            <td>
                                                <button className="btn btn-outline-secondary" type="button"
                                                        id="button-addon1"
                                                        onClick={()=> {
                                                            allUpdate(sportMarketListSettingPage.no)
                                                        }}
                                                >적용
                                                </button>
                                            </td>
                                        </tr>

                                    </table>

                                </div>

                                <div>
                                    <table className="table table-bordered table-sm text-center align-middle">
                                        <thead className="table-dark">
                                        <tr>
                                            <td style={{width: "3%"}}>번호</td>
                                            <td style={{width: "6%"}}>종목</td>
                                            <td style={{width: "6%"}}>마켓코드</td>
                                            <td style={{width: "6%"}}>마켓명</td>
                                            <td style={{width: "6%"}}>마켓명(영문)</td>
                                            <td style={{width: "6%"}}>북마커</td>
                                            <td style={{width: "6%"}}>노출최소배당</td>
                                            <td style={{width: "6%"}}>배당환수율</td>
                                            <td style={{width: "6%"}}>합배당</td>
                                            <td style={{width: "6%"}}>환수율조정배당</td>
                                            <td style={{width: "6%"}}>배당증차감</td>
                                            <td style={{width: "4%"}}>순서</td>
                                            <td style={{width: "6%"}}>기준점</td>
                                            <td style={{width: "6%"}}>사용여부</td>
                                            <td style={{width: "6%"}}>국내형현시여부</td>
                                            <td style={{width: "6%"}}>라이브현시여부</td>
                                            <td style={{width: "6%"}}>단폴배팅불가능</td>
                                            <td style={{width: "6%"}}></td>
                                        </tr>
                                        </thead>
                                        <tbody>

                                        {!sportMarketListSettingPage.length && (
                                            <tr>
                                                <td colSpan="18">목록이 비어있습니다.</td>
                                            </tr>
                                        )}

                                        {sportMarketListSettingPage.map((sportMarketListSettingPage, index) => (
                                            <tr id={"collapse2" + index}>
                                                <td>
                                                    {sportMarketListSettingPage.no}
                                                </td>
                                                <td>
                                                    <img src={process.env.PUBLIC_URL +imageFile[sportMarketListSettingPage.sports_cd-1]} /><br />
                                                    {sportsTypeInt(sportMarketListSettingPage.sports_cd)}
                                                </td>
                                                <td>
                                                    {sportMarketListSettingPage.market_cd}
                                                </td>
                                                <td>
                                                    {sportMarketListSettingPage.market_kor_nm}
                                                </td>
                                                <td>
                                                    {sportMarketListSettingPage.market_eng_nm}
                                                </td>
                                                <td>
                                                    {sportMarketListSettingPage.book_maker}
                                                </td>
                                                <td>
                                                    <input style={{
                                                        width: "100%"
                                                    }} type="text" className="form-control" id={"mininum_dividend" + index} placeholder="0" defaultValue={sportMarketListSettingPage.mininum_dividend}  onChange={onCheckMsgHandler} />

                                                </td>
                                                <td>
                                                    <input style={{
                                                        width: "100%"
                                                    }} type="text" className="form-control" id={"retrieve_rate" + index} placeholder="0" defaultValue={sportMarketListSettingPage.retrieve_rate}  onChange={onCheckMsgHandler} />
                                                </td>
                                                <td>
                                                    <input style={{
                                                        width: "100%"
                                                    }} type="text" className="form-control" id={"dividend" + index} placeholder="0" defaultValue={sportMarketListSettingPage.dividend}  onChange={onCheckMsgHandler} />
                                                </td>
                                                <td>
                                                    <input style={{
                                                        width: "100%"
                                                    }} type="text" className="form-control" id={"retrieve_revision" + index} placeholder="0" defaultValue={sportMarketListSettingPage.retrieve_revision}  onChange={onCheckMsgHandler} />
                                                </td>
                                                <td>
                                                    <input style={{
                                                        width: "100%"
                                                    }} type="text" className="form-control" id={"increase_decrease" + index} placeholder="0" defaultValue={sportMarketListSettingPage.increase_decrease}  onChange={onCheckMsgHandler} />
                                                </td>
                                                <td align={"center"}>
                                                    <select
                                                        className="form-select me-1"
                                                        defaultValue={sportMarketListSettingPage.sort}
                                                        id= {"sort" + index}
                                                        style={{
                                                            width: "100px"
                                                        }}
                                                    >
                                                        <option value={1}>1번째</option>
                                                        <option value={2}>2번째</option>
                                                        <option value={3}>3번째</option>
                                                        <option value={4}>4번째</option>
                                                        <option value={5}>5번째</option>
                                                        <option value={6}>6번째</option>
                                                        <option value={7}>7번째</option>
                                                        <option value={8}>8번째</option>
                                                        <option value={9}>9번째</option>
                                                    </select>
                                                </td>
                                                <td align={"center"}>
                                                    <select
                                                        className="form-select me-1"
                                                        defaultValue={sportMarketListSettingPage.point}
                                                        id= {"point" + index}
                                                        style={{
                                                            width: "150px"
                                                        }}
                                                    >
                                                        <option value={1}>종목별 설정 적용</option>
                                                        <option value={2}>전체 적용</option>
                                                    </select>
                                                </td>
                                                <td align={"center"}>
                                                    <Switch
                                                        id={"a"+sportMarketListSettingPage.no}
                                                        onChange={() => {
                                                            handleToggle("a"+sportMarketListSettingPage.no, sportMarketListSettingPage.no)
                                                        }
                                                        }
                                                        checked={initialState.nationSwitchStates["a"+sportMarketListSettingPage.no]}
                                                        onColor="#2196F3"
                                                    />
                                                </td>
                                                <td align={"center"}>
                                                    <Switch
                                                        id={"b"+sportMarketListSettingPage.no}
                                                        onChange={() => {
                                                            handleToggle("b"+sportMarketListSettingPage.no, sportMarketListSettingPage.no)
                                                        }
                                                        }
                                                        checked={initialState.nationSwitchStates["b"+sportMarketListSettingPage.no]}
                                                        onColor="#2196F3"
                                                    />
                                                </td>
                                                <td align={"center"}>
                                                    <Switch
                                                        id={"c"+sportMarketListSettingPage.no}
                                                        onChange={() => {
                                                            handleToggle("c"+sportMarketListSettingPage.no, sportMarketListSettingPage.no)
                                                        }
                                                        }
                                                        checked={initialState.nationSwitchStates["c"+sportMarketListSettingPage.no]}
                                                        onColor="#2196F3"
                                                    />
                                                </td>
                                                <td align={"center"}>
                                                    <Switch
                                                        id={"d"+sportMarketListSettingPage.no}
                                                        onChange={() => {
                                                            handleToggle("d"+sportMarketListSettingPage.no, sportMarketListSettingPage.no)
                                                        }
                                                        }
                                                        checked={initialState.nationSwitchStates["d"+sportMarketListSettingPage.no]}
                                                        onColor="#2196F3"
                                                    />
                                                </td>
                                                <td>
                                                    <button className="btn btn-outline-secondary" type="button"
                                                            id="button-addon2"
                                                            onClick={()=> {
                                                                update(sportMarketListSettingPage.no, sportMarketListSettingPage.no-1)
                                                            }}
                                                    >변경
                                                    </button>
                                                    <button className="btn btn-outline-secondary" type="button"
                                                            id="button-addon3"
                                                            onClick={()=> {
                                                                addSportsMarketList(sportMarketListSettingPage.no, sportMarketListSettingPage.market_kor_nm);
                                                                //basicSettingPageUpdate2(basicSettingPage2.mark_cd, basicSettingPage2.mark_cd-1)
                                                            }}
                                                    >조합배팅추가
                                                    </button>

                                                </td>

                                            </tr>
                                        ))}

                                        </tbody>

                                    </table>
                                </div>
                            </div>
                        </div>

                        <div className="" style={{
                            width: "50%",
                            height: "1000px",
                            overflowY: "scroll",
                        }}>
                            <input style={{
                                width: "100px"
                            }}
                                   type="hidden" className="form-control" id="market_combine_no"  onChange={onMarketCombineNoHandler} value={market_combine_no} onBlur={(e) => {
                            }}  />
                            <label className="col-form-label">마켓조합</label>
                            <input style={{
                                width: "500px"
                            }}
                                   onChange={onMarketCombineEditHandler} value={market_combine_edit}
                                   type="text" className="form-control" id="market_combine_edit" placeholder="e.g. 1+2" onBlur={(e) => {
                            }}  />
                            <label className="col-form-label">조합설명</label>
                            <input style={{
                                width: "500px"
                            }}
                                   onChange={onCombineEtcEditHandler} value={combine_etc_edit}
                                   type="text" className="form-control" id="combine_etc_edit" placeholder="" onBlur={(e) => {
                            }}  />
                            <label className="col-form-label"></label>
                            <table>
                                <tr>
                                    <td>
                                        <select
                                            id="combine_type_select"
                                            className="form-select me-1"
                                            defaultValue={1}
                                            onChange={onCombineTypeSelectHandler} value={combine_type_select}
                                            style={{
                                                width: "170px"
                                            }}
                                        >
                                            <option value={1}>국내형</option>
                                            <option value={2}>해외형</option>
                                        </select>
                                    </td>
                                    <td>
                                        <select
                                            id="sports_cd_select"
                                            className="form-select me-1"
                                            defaultValue={1}
                                            onChange={onSportsCdSelectHandler} value={sports_cd_select}
                                            style={{
                                                width: "170px"
                                            }}
                                        >
                                            <option value={1}>축구</option>
                                            <option value={2}>농구</option>
                                            <option value={3}>야구</option>
                                            <option value={4}>배구</option>
                                            <option value={5}>아이스 하키</option>
                                            <option value={6}>미식축구</option>
                                            <option value={7}>e스포츠</option>
                                        </select>

                                    </td>
                                    <td>
                                        <select
                                            id="combine_option_select"
                                            className="form-select me-1"
                                            defaultValue={1}
                                            onChange={onCombineOptionSelectHandler} value={combine_option_select}
                                            style={{
                                                width: "170px"
                                            }}
                                        >
                                            <option value={1}>동일경기 허용</option>
                                        </select>
                                    </td>

                                </tr>
                                <tr>
                                    <td align={"center"} colspan="3">
                                        <button className="btn btn-outline-secondary " type="button"
                                                id="button-addon3"
                                                style={{backgroundColor: '#0077ff'}}
                                                onClick={()=> {
                                                    resetSportsMarketList();
                                                }}
                                        ><font color="white" >RESET</font>
                                        </button>
                                        <button className="btn btn-outline-secondary" type="button"
                                                id="button-addon3"
                                                style={{backgroundColor: '#FF0000'}}
                                                onClick={()=> {
                                                    insertCombine("")
                                                    //addSportsMarketList(sportMarketListSettingPage.no);
                                                    //basicSettingPageUpdate2(basicSettingPage2.mark_cd, basicSettingPage2.mark_cd-1)
                                                }}
                                        ><font color="white" >추가</font>
                                        </button>
                                    </td>
                                </tr>
                            </table>

                            <table className="table table-bordered table-sm text-center align-middle">
                                <thead className="table-dark">
                                <tr>
                                    <th style={{width: "5%"}}>번호</th>
                                    <th style={{width: "10%"}}>타입</th>
                                    <th style={{width: "10%"}}>종목</th>
                                    <th style={{width: "*%"}}>베팅조합</th>
                                    <th style={{width: "15%"}}>-</th>
                                </tr>
                                </thead>
                            </table>

                                <table className="table table-bordered table-sm text-center align-middle">
                                    <tbody>

                                    {!sportsMarketListSettingPage2.length && (
                                        <tr>
                                            <td colSpan="5">목록이 비어있습니다.</td>
                                        </tr>
                                    )}

                                {sportsMarketListSettingPage2.map((sportsMarketListSettingPage2, index) => (
                                    <tr>
                                        <table>
                                            <tr>
                                                <td style={{width: "5%"}}>{sportsMarketListSettingPage2.no}</td>
                                                <td style={{width: "10%", backgroundColor: '#87ea80'}}>{combineType(sportsMarketListSettingPage2.combine_type)}</td>
                                                <td style={{width: "10%", textAlign: 'left',paddingLeft: '10px' }}>
                                                    <img src={process.env.PUBLIC_URL +imageFile[sportsMarketListSettingPage2.sports_cd-1]} /><br />
                                                    {sportsType(sportsMarketListSettingPage2.sports_cd)}
                                                </td>
                                                <td style={{width: "*%", backgroundColor: '#bde8e0'}}>{sportsMarketListSettingPage2.market_combine}</td>
                                                <td style={{width: "15%"}}>
                                                    <button className="btn btn-outline-secondary" type="button"
                                                            id="button-addon4"
                                                            onClick={()=> {
                                                                deleteSportsMarketList(sportsMarketListSettingPage2.no);
                                                            }}
                                                    >삭제
                                                    </button>
                                                    <button className="btn btn-outline-secondary" type="button"
                                                            id="button-addon4"
                                                            onClick={()=> {
                                                                 updateSportsMarketList(
                                                                     sportsMarketListSettingPage2.no,
                                                                     sportsMarketListSettingPage2.market_combine,
                                                                     sportsMarketListSettingPage2.combine_etc,
                                                                     sportsMarketListSettingPage2.combine_type,
                                                                     sportsMarketListSettingPage2.sports_cd,
                                                                     sportsMarketListSettingPage2.combine_option
                                                                 );

                                                            }}
                                                    >변경
                                                    </button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan="5">{sportsMarketListSettingPage2.combine_etc}</td>
                                            </tr>
                                        </table>


                                    </tr>
                                ))}


                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>



            </div>
        </div>

    )
}
export default SportsMarketListSettingPage;