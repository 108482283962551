import React, {useState} from 'react';
import soccer from "../../images/sports/soccer.jpg";
import basketball from "../../images/sports/basketball.jpg";
import baseball from "../../images/sports/baseball.jpg";
import vollyball from "../../images/sports/vollyball.jpg";
import icehocky from "../../images/sports/icehocky.jpg";
import americanfootball from "../../images/sports/americanfootball.jpg";
import esports from "../../images/sports/esports.jpg";
import Switch from "react-switch";
import {useDispatch} from "react-redux";
import {setMarketListChange, setMarketSwitchStatesReducer} from "../../redux/reducers/match/marketApiSettingReducer";
import axios from "axios";


const MarketListApiSettingComponent = ({initialState,query}) => {
    const dispatch = useDispatch()
    const handleToggle = (code) => {
        dispatch(setMarketSwitchStatesReducer(code))
    };

    const handleChangeNm = (e,index) => {
        const newValue = e.currentTarget.value;
        const updatedMarketList = [...initialState.marketList]; // Create a copy of the array
        updatedMarketList[index] = { ...updatedMarketList[index], market_kor_nm: newValue };
        dispatch(setMarketListChange(updatedMarketList)); // Update the state with the new array
    }

    const handleUpdate = (marketList) => {
        if (window.confirm("변경 하시겠습니까?")) {
            const no = marketList.no;
            const marketKorNm = marketList.market_kor_nm;
            const useYn = marketList.use_yn;

            axios.post("/admin/marketApiSettingInfoApi/update", {
                no : no,
                marketKorNm : marketKorNm,
                useYn : useYn,
            }).then((res) => {
                if(res.data.isMarketUpdated){
                    alert("변경되었습니다.");
                    query(initialState.marketUseYn);
                }else{
                    alert("변경실패.");
                }
            })
        }
    }
    return (
        <div className="m-2">
            <div className="card-body">
                <div>
                    <table className="table table-bordered table-sm text-center align-middle">
                        <thead className="table-dark">
                        <tr>
                            <th style={{width: "10px"}}>번호</th>
                            <th style={{width: "10px"}}>마켓코드</th>
                            <th style={{width: "200px"}}>마켓명(영문)</th>
                            <th style={{width: "200px"}}>마켓명</th>
                            <th style={{width: "60px"}}>사용여부</th>
                            <th style={{width: "130px"}}>-</th>
                        </tr>
                        </thead>
                        {initialState.marketList && initialState.marketList.length > 0 ?
                            initialState.marketList.map((List, index) => (
                                <tbody key={List.no}>
                        <tr>
                            <td>{List.no}</td>
                            <td>{List.market_cd}</td>
                            <td>{List.market_eng_nm}</td>
                            <td style={{ textAlign: 'center',paddingLeft: '10px' }}><input type="text" style={{ width: '100%' }} value={List.market_kor_nm === "" ? List.market_eng_nm : List.market_kor_nm} onChange={(e) => handleChangeNm(e, index)}/></td>
                            <td>
                                <Switch
                                    onChange={() => handleToggle(List.no)}
                                    checked={List.use_yn === 'Y'}
                                    onColor="#2196F3"
                                />
                            </td>
                            <td> <button className="my-auto btn btn-sm btn-primary w-50" onClick={() => handleUpdate(List)}>변경</button></td>
                        </tr>
                        </tbody>
                            ))
                            :
                            <tbody>
                            <tr className="table-secondary">
                                <td colSpan= "8"><font size= '3'> 조회된 데이터가 없습니다.</font></td>
                            </tr>
                            </tbody>
                        }
                    </table>
                </div>
            </div>
        </div>
    );
};

export default MarketListApiSettingComponent;