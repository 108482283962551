import axios from "axios";
import {Link, Outlet, Route, Routes, useNavigate} from "react-router-dom";
import Match from "./pages/match";
import Sport from "./pages/match/sport";
import League from "./pages/match/league";
import Team from "./pages/match/team";
import Market from "./pages/match/market";
import Bookmaker from "./pages/match/bookmaker";
import SettingMarket from "./pages/match/settingMarket";
import Fixture from "./pages/match/Fixture";
import ParsingWait from "./pages/match/ParsingWait";
import ActiveMatch from "./pages/match/ActiveMatch";
import CancelMatch from "./pages/match/CancelMatch";
import CancelMatchBy from "./pages/match/CancelMatchBy";
import CloseMatch from "./pages/match/CloseMatch";
import ResultMatch from "./pages/match/ResultMatch";
import StopMatch from "./pages/match/StopMatch";
import StopMatchBy from "./pages/match/StopMatchBy";
import BasicSettingPage from "./pages/match/BasicSettingPage";
import LeagueListApiSettingPage from "./pages/match/LeagueListApiSettingPage";
import MarketListApiSettingPage from "./pages/match/MarketListApiSettingPage";
import NationListApiSettingPage from "./pages/match/NationListApiSettingPage";
import SportsListApiSettingPage from "./pages/match/SportsListApiSettingPage";
import TeamListApiSettingPage from "./pages/match/TeamListApiSettingPage";
import SportsMarketListSettingPage from "./pages/match/SportsMarketListSettingPage";
import CombinationBettingListPage from "./pages/match/CombinationBettingListPage";
import TotalBetting from "./pages/betting/TotalBetting";
import ActiveBetting from "./pages/betting/ActiveBetting";
import CancelBetting from "./pages/betting/CancelBetting";
import CloseBetting from "./pages/betting/CloseBetting";
import CompleteBetting from "./pages/betting/CompleteBetting";
import moment from "moment";
import React, {useState} from "react";
import User from "./pages/user";
import Member from "./pages/user/member";
import Attend from "./pages/user/attend";
import LoginLog from "./pages/user/login_log";
import BlockList from "./pages/user/block_list";
import Hack_list from "./pages/user/hack_list";
import JoinList from "./pages/user/JoinListPage";
import ConnectList from "./pages/user/ConnectListPage";
import LostList from "./pages/user/LostListPage";
import FailList from "./pages/user/FailListPage";
import MemoList from "./pages/user/memo_list";
import Admin from "./pages/user/AdminPage";
import Money from "./pages/money";
import Deposit from "./pages/money/deposit";
import DepositComplete from "./pages/money/deposit_complete";
import DepositMSG from "./pages/money/deposit_msg";
import Casino from "./pages/casino";
import CasinoMember from "./pages/casino/member";
import Board from "./pages/board";
import Notice from "./pages/board/notice";
import Help from "./pages/board/help";
import Faq from "./pages/board/faq";
//import NoticeInsert from "./pages/board/notice_insert";
import Marquee from "./pages/board/mqrquee";
import Popup from "./pages/board/popup";
import Event from "./pages/board/event";
import Agent from "./pages/agent";
import AgentList from "./pages/agent/AgentList";
import CasinoStatistic from "./pages/casino/statistic";
import Statistic from "./pages/statistic";
import StatisticDetail from "./pages/statistic/detail";
import Total from "./pages/statistic/Total";
import DailyFee from "./pages/agent/daily_fee";
import Betting from "./pages/betting";
import Setting from "./pages/setting";
import AdminSetting from "./pages/setting/adminSetting";
import AdminPage from "./pages/setting/adminPage";
import ForeverDomain from "./pages/setting/foreverDomain";
import Grade from "./pages/setting/grade";
import PointSetting from "./pages/setting/pointSetting";
import Admin_fail_log from "./pages/user/AdminFailLogPage";
import JoinCode from "./pages/setting/JoinCode";
import LoginComponent from "./components/loginComponent";
import LoginModal from "./components/LoginModal";
import SideStatus from "./components/status/SideStatus";
import LiveStatus from "./components/status/LiveStatus";
import WithdrawCompletePage from "./pages/money/WithdrawCompletePage";
import WithdrawMngPage from "./pages/money/WithdrawMngPage";
import PointLogMngPage from "./pages/money/PointLogMngPage";
import MoneyLogMngPage from "./pages/money/MoneyLogMngPage";
import HomePage from "./pages/home/HomePage";


import {useSelector} from "react-redux";

function App() {

    const [ip, setIp] = useState("");

    const getIp = () => {
        axios.get('https://api.ipify.org?format=json').then((res) => {
            if(res.status === 200) {
                setIp(res.data.ip)
            }
        })
    }
    ///useEffect(getIp, [])


    return (
        <div>
            <Routes>
                <Route element={<Layout />}>
                    <Route path="/" element={<HomePage />}/>
                    <Route path="user" element={<User />}>
                        <Route path="member" element={<Member />}/>
                        <Route path="attend" element={<Attend />}/>
                        <Route path="memo_list" element={<MemoList />}/>
                        <Route path="login_log" element={<LoginLog />}/>
                        <Route path="block_list" element={<BlockList />}/>
                        <Route path="hack_list" element={<Hack_list />}/>
                        <Route path="join_list" element={<JoinList />}/>
                        <Route path="connect_list" element={<ConnectList />}/>
                        <Route path="lost_list" element={<LostList />}/>
                        <Route path="fail_list" element={<FailList />}/>
                        <Route path="admin_fail_log" element={<Admin_fail_log />}/>
                        <Route path="admin" element={<Admin />}/>
                    </Route>
                    <Route path="money" element={<Money />}>
                        <Route path="deposit" element={<Deposit />}/>
                        <Route path="deposit_complete" element={<DepositComplete />}/>
                        <Route path="deposit_msg" element={<DepositMSG />}/>
                        <Route path="WithdrawMngPage" element={<WithdrawMngPage />}/>
                        <Route path="WithdrawCompletePage" element={<WithdrawCompletePage />}/>
                        <Route path="MoneyLogMngPage" element={<MoneyLogMngPage />}/>
                        <Route path="PointLogMngPage" element={<PointLogMngPage />}/>
                    </Route>
                    <Route path="casino" element={<Casino />}>
                        <Route path="member" element={<CasinoMember />}/>
                        <Route path="statistic" element={<CasinoStatistic />}/>
                    </Route>
                    <Route path="board" element={<Board />}>
                        <Route path="notice" element={<Notice />}/>
                        <Route path="help" element={<Help />}/>
                        <Route path="faq" element={<Faq />}/>
                        <Route path="marquee" element={<Marquee />}/>
                        <Route path="popup" element={<Popup />}/>
                        <Route path="event" element={<Event />}/>
                    </Route>
                    <Route path="statistic" element={<Statistic />}>
                        <Route path="detail" element={<StatisticDetail />}/>
                        <Route path="total" element={<Total />}/>
                    </Route>
                    <Route path="agent" element={<Agent />}>
                        <Route path="AgentList" element={<AgentList />}/>
                        <Route path="daily_fee" element={<DailyFee />}/>
                    </Route>
                    <Route path="matchMng" element={<Match />}>
                        <Route path="BasicSettingPage" element={<BasicSettingPage />} />
                        <Route path="SportsListApiSettingPage" element={<SportsListApiSettingPage />} />
                        <Route path="NationListApiSettingPage" element={<NationListApiSettingPage />} />
                        <Route path="LeagueListApiSettingPage" element={<LeagueListApiSettingPage />} />
                        <Route path="TeamListApiSettingPage" element={<TeamListApiSettingPage />} />
                        <Route path="MarketListApiSettingPage" element={<MarketListApiSettingPage />} />
                        <Route path="SportsMarketListSettingPage" element={<SportsMarketListSettingPage />} />
                        <Route path="CombinationBettingListPage" element={<CombinationBettingListPage />} />
                        <Route path="sport" element={<Sport />}/>
                        <Route path="league" element={<League />}/>
                        <Route path="team" element={<Team />}/>
                        <Route path="market" element={<Market />}/>
                        <Route path="bookmaker" element={<Bookmaker />}/>
                        <Route path="setting_market" element={<SettingMarket />}/>
                        <Route path="fixture" element={<Fixture />} />
                        <Route path="passive_wait" element={<ParsingWait />} />
                        <Route path="wait" element={<ParsingWait />} />
                        <Route path="active" element={<ActiveMatch />} />
                        <Route path="close" element={<CloseMatch />} />
                        <Route path="stop" element={<StopMatch />} />
                        <Route path="passive_stop" element={<StopMatchBy />} />
                        <Route path="result" element={<ResultMatch />} />
                        <Route path="passive_cancel" element={<CancelMatchBy />} />
                        <Route path="cancel" element={<CancelMatch />} />
                        <Route path="passive_fix" element={<CancelMatch />} />
                        <Route path="fix" element={<CancelMatch />} />
                    </Route>
                    <Route path="betting" element={<Betting />}>
                        <Route path="TotalBetting" element={<TotalBetting />}/>
                        <Route path="ActiveBetting" element={<ActiveBetting />}/>
                        <Route path="CancelBetting" element={<CancelBetting />}/>
                        <Route path="CloseBetting" element={<CloseBetting />}/>
                        <Route path="CompleteBetting" element={<CompleteBetting />}/>
                    </Route>
                    <Route path="setting" element={<Setting />}>
                        <Route path="adminSetting" element={<AdminSetting />}/>
                        <Route path="adminPage" element={<AdminPage />}/>
                        <Route path="foreverDomain" element={<ForeverDomain />}/>
                        <Route path="grade" element={<Grade />}/>
                        <Route path="pointSetting" element={<PointSetting />}/>
                        <Route path="JoinCode" element={<JoinCode />}/>
                    </Route>
                </Route>
            </Routes>
        </div>
    );
}

function Layout() {
    const userInfo = useSelector((state) => state.userInfo);

    const [activeNav, setActiveNav] = useState();
    const navigate = useNavigate();

    const ontoggle = (index, address) => {
        setActiveNav(index)
        navigate(address)
    }

    let [openYn, setOpenYn] = useState(false);
    const sideStatusOpen = () => {
        if(openYn){
            openYn = false
            setOpenYn(false)
        }else{
            openYn = true
            setOpenYn(true)
        }
    }


    return (
        <div>
            <div className="container-fluid justify-content-between d-flex">

                <LiveStatus/>

                <div className="p-2 d-flex">
                    <LoginComponent />
                </div>
            </div>

            <div className="todayStatus mt-3">
                <Link className="nav-link" to="#collapse_todayStatus" data-bs-toggle="collapse"
                      aria-expanded="false" aria-controls="collapse_todayStatus" onClick={() => sideStatusOpen()}
                      style={{
                          color: "white", fontWeight: "bold"
                      }}>
                    금일 현황 {moment().format('YYYY-MM-DD')}
                </Link>

                <div className="collapse" id="collapse_todayStatus">
                    <SideStatus openYn={openYn} />
                </div>
            </div>

            <nav className="navbar navbar-expand-sm navbar-dark ">
                <div className="navbar-collapse collapse justify-content-center adminMenu">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <Link className="nav-link" to="/">관리자홈</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/user/member">회원관리</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/money/deposit">입출금관리</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/matchMng/active">게임관리</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/betting/TotalBetting">배팅관리</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/casino/member">카지노관리</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/board/notice">게시물관리</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/statistic/total">정산관리</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/agent/AgentList">총판관리</Link>
                        </li>

                        {userInfo.level === 2?
                        <li className="nav-item">
                            <Link className="nav-link" to="/setting/adminSetting">환경설정</Link>
                        </li>
                            :<></>
                        }
                    </ul>
                </div>
            </nav>

            <div className="d-flex">
                {userInfo.isLogin?
                <>
                    <div style={{
                        width: "100%",
                        position:"relative",
                        }}>
                            <Outlet/>
                    </div>
                </>
                    :
                    (
                        <div>
                            <LoginModal/>
                        </div>
                    )}
            </div>

        </div>
    );
}

export default App;

