import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getPageAphAthrReducer} from "../../redux/reducers/setting/PageAphAthrReducer";
import PageAphAthrComponent from "../../components/setting/PageAphAthrComponent";

const PageAphAthrContainer = () => {
    const dispatch = useDispatch()
    const initialState = useSelector((state) => state.PageAphAthrReducer)
    const getAdminList = () => {
        if(initialState.adminList !== null) {
            dispatch(getPageAphAthrReducer({
                page: 1,
                pageSize: initialState.pageSize,
            }))
        }
    }
    useEffect(getAdminList, [dispatch])

    return (
       <div>
           <PageAphAthrComponent initialState = {initialState}/>
       </div>

    )
}

export default PageAphAthrContainer;