import {call, put, take,} from "redux-saga/effects";
import {fetchBookmaker} from "../../../lib/api";
import {closeChannel, createSocketChannel} from "../../channel/createSocketChannel";
import socket from "../../../config/socket";
import {changeBookmaker, failedGetBookmaker, pushBookmakerTask, setBookmaker} from "../../reducers/match/bookmaker";

export function* getBookmakerSaga(action) {
    try {
        const res = yield call(fetchBookmaker, action.payload.is_active, action.payload.page, action.payload.pageSize, action.payload.query);
        yield put(setBookmaker(res.data));
    } catch(error) {
        console.log(error)
        yield put(failedGetBookmaker(error));
    }
}

export function* waitBookmakerTaskSaga() {
    let channel;
    try {
        channel = yield call(createSocketChannel, "bookmaker");
        while(true) {
            const task = yield take(channel);
            yield onTaskSaga(task)
        }
    } catch (e) {
        console.log(e, "error");
    } finally {
        socket.close();
        closeChannel(channel)
    }
}

function* onTaskSaga(task) {
    yield put(pushBookmakerTask(task));
    yield put(changeBookmaker(task))
}

export function* changeBookmakerSaga() {
    //yield put(changeSport(changedItem))
}
