import React from 'react';
import LostListContainer from "../../containers/user/LostListContainer";

const LostListPage = () => {

    return (
        <div>
            <h1>분실문의</h1>
        <LostListContainer/>
        </div>
    )
}

export default LostListPage;