import {createSlice} from "@reduxjs/toolkit"
export const statsSlice = createSlice({
    name: 'AdminReducer',
    initialState: {
        page: 1,
        pageSize:20,
        isLoading: false,
        error: null,
        adminCount: 0,
        adminList: [],
        adminNos:[],
        adminOpen: false
    },
    reducers: {
        getAdminReducer(state, action) {
            console.log("##getAdminReducer##", action.payload.page,action.payload.pageSize);
            state.isLoading = true;
            state.page = action.payload.page;
            state.pageSize = action.payload.pageSize;
        },
        setAdminReducer(state, action) {
            console.log("#setAdminReducer#", action.payload);
            if((state.page -1) * state.pageSize > action.payload.adminCount) {
                state.page = 1
            }
            state.isLoading = false;
            state.adminCount = action.payload.adminCount;
            state.adminList = action.payload.adminList;

            state.adminNos = [];
        },
        failedAdminReducer(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        setAdminNos(state, action) {
            state.adminNos = action.payload;
        },
        setAdminOpen(state, action) {
            state.adminOpen = action.payload;
        },
    }
});

export const {
    getAdminReducer,
    setAdminReducer,
    failedAdminReducer,
    setAdminNos,
    setAdminOpen,
} = statsSlice.actions;

export default statsSlice.reducer;