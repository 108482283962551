import React from 'react';
import ActiveBettingListContainer from "../../containers/betting/ActiveBettingListContainer";
const ActiveBetting = () => {
    return (
        <div>
            <h1>진행배팅</h1>
            <ActiveBettingListContainer />
        </div>

    )
}

export default ActiveBetting;