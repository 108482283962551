import React, {useState} from "react";
import moment from "moment";
import typeCase from "../../util/typeCase";
import {NumericFormat} from "react-number-format";
import CancelBetDialog from "../CancelBetDialog";
import RecoveryBetDialog from "../RecoveryBetDialog";
import RefundBetDialog from "../RefundBetDialog";
import {Link} from "react-router-dom";
import UserInformation from "../UserInformation";
import {useDispatch} from "react-redux";
import {
    setMemberDepositLog, setMemberMoneyLog, setMemberPointLog, setMemberWithdrawLog,
    setSelectedNos,
    setToastState,
    setTotalSelectedMoney,
    setUserInformation, setUserNo
} from "../../redux/reducers/money/WithdrawCompleteReducer";

const WithdrawCompleteListComponent = ({initialState, updateState}) => {
    const dispatch = useDispatch()
    const informationOpenHandler = () => {
        dispatch(setUserInformation(true))
    }
    function toastHandler() {
        dispatch(setToastState)(true)
    }
    const withdrawStateColor = (state) => {
        switch(state) {
            case 0 :
                return "bgRequest"
            case 1 :
                return "bgWait"
            case 2 :
                return "bgComplete"
            case 3 :
                return "bgCancel"
            case 10 :
                return "bg-secondary"
            default : return state
        }
    }
    function onCommentColor (color) {
        switch (color) {
            case 0 : return "red"
            case 1 : return "orange"
            case 2 : return "yellow"
            case 3 : return "green"
            case 4 : return "blue"
            case 5 : return "navy"
            case 6 : return "purple"
            case 10 : return "gray"
            default : return color
        }
    }
    const allCheckHandler = (e) => {
        if(initialState.withdrawList !== null && e.currentTarget.value) {
            const checks = [];
            let total = 0;
            for(let i = 0; i < initialState.withdrawList.length; i++) {
                checks.push(initialState.withdrawList[i].no)
                total += initialState.withdrawList[i].request_money
            }
            dispatch(setSelectedNos(e.target.checked? checks : []))
            dispatch(setTotalSelectedMoney(e.target.checked? total : 0))
        }
    }

    const checkHandler = (checked, no) => {
        let selectedNos2 = []
        if(checked) {
            selectedNos2 = [...initialState.selectedNos, no]
            dispatch(setSelectedNos([...initialState.selectedNos, no]))
        }
        else {
            selectedNos2 = initialState.selectedNos.filter((id) => id !== no)
            dispatch(setSelectedNos(initialState.selectedNos.filter((id) => id !== no)))
        }
        let total = 0;
        for(let i = 0; i < selectedNos2.length; i++) {
            total += initialState.withdrawList.filter((id) => id.no === selectedNos2[i])[0].request_money
        }
        dispatch(setTotalSelectedMoney(total))
    }

    return (
        <div className="withdrawt2">
            <table className="table table-bordered table-sm text-center align-middle">
                <thead className="table-dark">
                <tr>
                    <th>
                        <input className="form-check-input" type="checkbox" checked={initialState.withdrawListCount === initialState.selectedNos.length} onChange={allCheckHandler}
                               id="flexCheckDefault"/>
                    </th>
                    <th>파트너</th>
                    <th>상태</th>
                    <th>등급</th>
                    <th>닉네임</th>
                    <th>충전</th>
                    <th>환전</th>
                    <th>충환차액</th>
                    <th>이달수익</th>
                    <th>보유머니</th>
                    <th>예금주</th>
                    <th>계좌번호</th>
                    <th>환전금액</th>
                    <th>요청시간</th>
                    <th>처리시간</th>
                    <th>처리상태</th>
                    <th>충전구분</th>
                    <th>관리자</th>
                </tr>
                </thead>
                {initialState.totalCount > 0 ?
                    initialState.withdrawList && initialState.withdrawList.map((withdraw, index) => (
                    <tbody key={withdraw.no}>
                    <tr>
                        <td>
                            <input className="form-check-input" type="checkbox" checked={initialState.selectedNos.filter((no) => no === withdraw.no).length > 0} onChange={(e) => {
                                checkHandler(e.currentTarget.checked, withdraw.no)
                            }}/>
                        </td>
                        <td>본사</td>
                        <td>{typeCase.userState(withdraw.user.user_state)}</td>
                        <td>{typeCase.levelState(withdraw.user.level)}</td>
                        <td>
                            <Link className="nav-link" to={"#userInformation" + index} data-bs-toggle="collapse"
                                  aria-expanded="false" aria-controls={"#userInformation" + index} onClick={informationOpenHandler}>
                                {withdraw.user.comment ?
                                    <>
                                        <span>{withdraw.user.nickname}</span>
                                        <br></br>
                                        <span style={{fontSize: "12px"}}>
                                                        <span className="me-1" style={{backgroundColor: onCommentColor(withdraw.user.comment_color)}}>
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                        </span>
                                            {withdraw.user.comment}
                                                    </span>
                                    </>
                                    :  (withdraw.user.comment_color !== null?
                                        <>
                                            <span>{withdraw.user.nickname}</span>
                                            <br></br>
                                            <span className="me-1" style={{backgroundColor: onCommentColor(withdraw.user.comment_color)}}>
                                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                            </span>
                                        </>
                                        :  <span>{withdraw.user.nickname}</span> )}
                            </Link>
                        </td>
                        <td>
                            <NumericFormat value={initialState.userDeposit.filter((el) => el.user_no === withdraw.user_no).length > 0? initialState.userDeposit.filter((el) => el.user_no === withdraw.user_no)[0].user_total : 0} displayType={'text'} thousandSeparator={true}/>
                        </td>
                        <td>
                            <NumericFormat value={initialState.userWithdraw.filter((el) => el.user_no === withdraw.user_no).length > 0? initialState.userWithdraw.filter((el) => el.user_no === withdraw.user_no)[0].user_total : 0} displayType={'text'} thousandSeparator={true}/>
                        </td>
                        <td>
                            <NumericFormat value={
                                (initialState.userDeposit.filter((el) => el.user_no === withdraw.user_no).length > 0? initialState.userDeposit.filter((el) => el.user_no === withdraw.user_no)[0].user_total : 0)
                                -
                                (initialState.userWithdraw.filter((el) => el.user_no === withdraw.user_no).length > 0? initialState.userWithdraw.filter((el) => el.user_no === withdraw.user_no)[0].user_total : 0)
                            } displayType={'text'} thousandSeparator={true}/>
                        </td>
                        {/*이달수익*/}
                        <td>
                            <NumericFormat value={
                                (initialState.monthDeposit.filter((el) => el.user_no === withdraw.user_no).length > 0? initialState.monthDeposit.filter((el) => el.user_no === withdraw.user_no)[0].user_total : 0)
                                -
                                (initialState.monthWithdraw.filter((el) => el.user_no === withdraw.user_no).length > 0? initialState.monthWithdraw.filter((el) => el.user_no === withdraw.user_no)[0].user_total : 0)
                            } displayType={'text'} thousandSeparator={true}/>
                        </td>
                        <td>
                            <NumericFormat value={withdraw.user.money} displayType={'text'} thousandSeparator={true}/>
                        </td>
                        <td>{withdraw.user.name}</td>
                        <td>
                            {withdraw.user.bank}
                            <br></br>
                            {withdraw.user.withdraw_account}
                        </td>
                        <td>
                            <NumericFormat value={withdraw.request_money} displayType={'text'} thousandSeparator={true}/>
                        </td>
                        <td>
                            {moment(withdraw.createdAt).format("YYYY-MM-DD")}
                            <br></br>
                            {moment(withdraw.createdAt).format("HH:mm:ss")}
                        </td>
                        <td>
                            {moment(withdraw.updatedAt).format("YYYY-MM-DD")}
                            <br></br>
                            {moment(withdraw.updatedAt).format("HH:mm:ss")}
                        </td>
                        <td>{typeCase.depositState(withdraw.withdraw_state)}</td>
                        <td>{typeCase.depositType(withdraw.withdraw_type)}</td>
                        <td>{withdraw.admin? withdraw.admin.id : "-"}</td>
                    </tr>
                    <tr className="collapse" id={"userInformation" + index} >
                        {initialState.userInformation && <UserInformation no={withdraw.user.no} id={withdraw.user.id}/> }
                    </tr>
                    </tbody>
                    ))
                    :
                    <tbody>
                    <tr className="table-secondary">
                        <td colSpan= "20"><font size= '3'> 조회된 데이터가 없습니다.</font></td>
                    </tr>
                    </tbody>
                }
            </table>
        </div>
    );
}

export default WithdrawCompleteListComponent;