import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import axios from "axios";

const JoinComponent = (props) => {
    const { open, onClose } = props
    const [userInfo, setUserInfo] = useState(null)

    const idChangeHandler = (value) => {
        if(userInfo === null) {
            setUserInfo({id: value})
        }
        else {
            userInfo.id = value
            setUserInfo(userInfo)
        }
    }
    const pwChangeHandler = (value) => {
        if(userInfo === null) {
            setUserInfo({pw: value})
        }
        else {
            userInfo.pw = value
            setUserInfo(userInfo)
        }
    }
    const nicknameChangeHandler = (value) => {
        if(userInfo === null) {
            setUserInfo({nickname: value})
        }
        else {
            userInfo.nickname = value
            setUserInfo(userInfo)
        }
    }
    const nameChangeHandler = (value) => {
        if(userInfo === null) {
            setUserInfo({name: value})
        }
        else {
            userInfo.name = value
            setUserInfo(userInfo)
        }
    }

    const handleCancel = () => {
        onClose();
    }
    const handleRegister = () => {

        axios.post('/admin/member/duplicatedUserId', userInfo)
                .then((res) => {
                    if(res.data.isUpdated) {
                        alert('중복된 ID가 존재합니다.')
                        return;
                    }
                    else {
                        if (window.confirm("등록 하시겠습니까?")) {
                            axios.post('/admin/member/insert', userInfo)
                                .then((res) => {
                                    if(res.data.isInserted) {
                                        onClose();
                                        alert('가입완료')
                                    }
                                    else {
                                        alert('가입실패')
                                    }
                                })
                        }
                    }
                })

    }
    return (
        <Dialog open={open}>
            <DialogTitle>회원등록</DialogTitle>
            <DialogContent>
                <label className="col-form-label">ID</label>
                <input className="form-control" maxLength="30"  size="50" type="text" placeholder="아이디" id="id" onChange={(event) => {idChangeHandler(event.currentTarget.value)}} />
                <label className="col-form-label">비밀번호</label>
                <input className="form-control" maxLength="30" type="text" placeholder="비밀번호" id="pw" onChange={(event) => {pwChangeHandler(event.currentTarget.value)}} />
                <label className="col-form-label">닉네임</label>
                <input className="form-control" maxLength="30" type="text" placeholder="닉네임" id="nickname" onChange={(event) => {nicknameChangeHandler(event.currentTarget.value)}} />
                <label className="col-form-label">예금주</label>
                <input className="form-control" maxLength="30" type="text" placeholder="예금주" id="name" onChange={(event) => {nameChangeHandler(event.currentTarget.value)}} />
            </DialogContent>
            <DialogActions>
                <button type="button" className="btn btn-secondary" onClick={handleCancel}>취소</button>
                <button type="button" className="btn btn-primary" onClick={handleRegister}>등록</button>
            </DialogActions>
        </Dialog>
    )
}

export default JoinComponent;