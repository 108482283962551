import React from "react";
import moment from "moment/moment";
import {useDispatch} from "react-redux";
import {getStopFixtureDetail, setBetInputs, setCheckedInputs} from "../../redux/reducers/match/stopMatch";
import axios from "axios";
import common from "../../util/common";

const StopMatchList = ({matches}) => {
    const dispatch = useDispatch()

    const changeHandler = (checked, id, index) => {
        if(checked) {
            dispatch(setCheckedInputs([...matches.checkedInputs, id]));
        }
        else {
            dispatch(setCheckedInputs(matches.checkedInputs.filter((el) => el !== id)));
        }
    };

    const allChangeHandler = (e) => {
        const check_ids = []
        for(let index = 0; index < matches.fixtures.length; index++) {
            check_ids.push(matches.fixtures[index].id)
        }
        dispatch(setCheckedInputs(e.target.checked? check_ids : []));
    }

    const changeBet = (e, id, index, type) => {
        if(e.target.value !== e.target.defaultValue) {
            e.target.style.color = "green"
        }
        else {
            e.target.style.color = "#555"
        }
        const value = parseFloat(e.target.value)
        const data = {
            bet1: matches.fixtures[index].bet1,
            bet2: matches.fixtures[index].bet2,
            bet3: matches.fixtures[index].bet3,
        }

        const betInputs2 = matches.betInputs.filter((el) => el.id !== id);

        const betInputs3 = matches.betInputs.filter((el) => el.id === id && el.is_edited === 1);
        let inputs
        if(betInputs3.length > 0) {
            if(type === 1) {
                inputs = {id: id, bet1: value, bet2: betInputs3[0].bet2, bet3: betInputs3[0].bet3}
            }
            else if(type === 2) {
                inputs = {id: id, bet2: value, bet1: betInputs3[0].bet1, bet3: betInputs3[0].bet3}
            }
            else if(type === 3) {
                inputs = {id: id, bet3: value, bet2: betInputs3[0].bet2, bet1: betInputs3[0].bet1}
            }
        }
        else {
            if(type === 1) {
                inputs = {id: id, bet1: value, bet2: data.bet2, bet3: data.bet3}
            }
            else if(type === 2) {
                inputs = {id: id, bet2: value, bet1: data.bet1, bet3: data.bet3}
            }
            else if(type === 3) {
                inputs = {id: id, bet3: value, bet2: data.bet2, bet1: data.bet1}
            }
        }
        inputs.is_edited = 1;
        dispatch(setBetInputs([...betInputs2, inputs]))

        //체크처리
        const checkedInput2 = matches.checkedInputs.filter((el) => el !== id)
        dispatch(setCheckedInputs([...checkedInput2, id]));
    }

    const editSelectedMatch = () => {
        const updateData = []
        for(let i = 0; i < matches.checkedInputs.length; i++) {
            const betInputs2 = matches.betInputs.filter((el) => el.id === matches.checkedInputs[i] && el.is_edited === 1)
            const data = {
                id: matches.checkedInputs[i]
            }
            if(betInputs2.length > 0) {
                data.is_edited = 1
                const editedData = betInputs2[0];
                data.bet1 = editedData.bet1
                data.bet2 = editedData.bet2
                data.bet3 = editedData.bet3
            }
            else {
                data.is_edited = 0
            }
            updateData.push(data)
        }
        return updateData
    }
    const editAllMatch = () => {
        const updateData = []
        for(let i = 0; i < matches.fixtures.length; i++) {
            const betInputs2 = matches.betInputs.filter((el) => el.id === matches.fixtures[i])
            const data = {
                id: matches.fixtures[i].id
            }
            if(betInputs2.length > 0) {
                data.is_edited = 1
                const editedData = betInputs2[0];
                data.bet1 = editedData.bet1
                data.bet2 = editedData.bet2
                data.bet3 = editedData.bet3
            }
            else {
                data.is_edited = 0
            }
            updateData.push(data)
        }
        return updateData;
    }
    const getAllIds = () => {
        const updateData = []
        for(let i = 0; i < matches.fixtures.length; i++) {
            updateData.push(matches.fixtures[i].id)
        }
        return updateData
    }

    const editActiveMatch = (updateData) => {
        axios.post('/api/editActiveMatch', updateData).then((res) => {
            if(res.status === 200) {
                alert(res.data.result + "개 수정완료");
                window.location.reload();
            }
        })
    }
    const activeMatch = (updateData) => {
        axios.post('/api/activeMatch', updateData).then((res) => {
            if(res.status === 200) {
                alert(res.data.result + "개 활성완료");
                window.location.reload();
            }
        })
    }
    const closeMatch = (updateData) => {
        axios.post('/api/closeMatch', updateData).then((res) => {
            if(res.status === 200) {
                alert(res.data.result + "개 마감처리완료");
                window.location.reload();
            }
        })
    }
    const refundMatch = (updateData) => {
        axios.post('/api/refundMatch', updateData).then((res) => {
            if(res.status === 200) {
                alert(res.data.result + "개 적특처리완료");
                window.location.reload();
            }
        })
    }
    const changeIsEdited = (updateData) => {
        axios.post('/api/changeIsEdited', updateData).then((res) => {
            if(res.status === 200) {
                alert(res.data.result + "개 파싱상태변경완료");
                window.location.reload();
            }
        })
    }

    function getDetail() {
        const ratios = matches.currentFixture.ratios
        const ratiosByMarket = common.groupBy(ratios, "market_id")
        const keys = Object.keys(ratiosByMarket)
        const result = []
        for(let i = 0; i < keys.length; i++) {

            //마켓아이디 처리
            const marketId = keys[i]

            const arr = ratiosByMarket[marketId]
            // 마켓아이디별 픽스쳐처리
            const trs = []
            for(const fixture of arr) {
                trs.push(<tr>
                    <td className={fixture.bet1_settlement === 2 && "table-success"}>{fixture.bet1}</td>
                    <td className={fixture.market_id === 1 && (fixture.bet2_settlement === 2 && "table-success")}>{fixture.bet2}</td>
                    <td className={fixture.bet3_settlement === 2 && "table-success"}>{fixture.bet3}</td>
                    <td>{moment(fixture.updatedAt).format("YYYY-MM-DD HH:mm:ss")}</td>
                </tr>)
            }

            const tableTemp = <div>
                <p className="h4 text-bg-dark mb-0 p-2">{arr[0].market}</p>
                <table className="table table-bordered table-hover text-center">
                    <thead className="table-secondary">
                    <tr>
                        <th style={{
                            width: "35%"
                        }}>{arr[0].home}</th>
                        <th style={{
                            width: "13%"
                        }}>{arr[0].market_id === 1? "무" : "기준점"}</th>
                        <th style={{
                            width: "35%"
                        }}>{arr[0].away}</th>
                        <th style={{
                            width: "auto"
                        }}>최근수정</th>
                    </tr>
                    </thead>
                    <tbody>
                    {trs}
                    </tbody>
                </table>
            </div>

            result.push(tableTemp)
        }

        return result
    }

    return (
        <div className="container-fluid p-1 m-0">

            <div className="mb-1">
                <button className="btn btn-info me-1" onClick={() => {editActiveMatch(editAllMatch())}} >전체수정</button>
                <button className="btn btn-info me-1" onClick={() => {editActiveMatch(editSelectedMatch())}} >선택수정</button>
                <button className="btn btn-success me-1" onClick={() => {activeMatch(editAllMatch())}} >전체활성</button>
                <button className="btn btn-success me-1" onClick={() => {activeMatch(editSelectedMatch())}} >선택활성</button>
                <button className="btn btn-secondary me-1" onClick={() => {closeMatch(getAllIds())}} >전체마감</button>
                <button className="btn btn-secondary me-1" onClick={() => {closeMatch(matches.checkedInputs)}} >선택마감</button>
                <button className="btn btn-secondary me-1" onClick={() => {refundMatch(getAllIds())}} >전체적특</button>
                <button className="btn btn-secondary me-1" onClick={() => {refundMatch(matches.checkedInputs)}} >선택적특</button>
                <button className="btn btn-secondary me-1" onClick={() => {changeIsEdited(0)}} >파싱 ON</button>
                <button className="btn btn-secondary me-1" onClick={() => {changeIsEdited(1)}} >파싱 OFF</button>
            </div>


            <div className="d-flex">
                <div className="" style={{
                    width: "50%",
                    height: "1200px",
                    overflowY: "scroll",
                }}>
                    {matches.low_ratios && (
                        <table className="table table-bordered table-hover text-center">
                            <thead className="table-dark">
                            <tr>
                                <th className="text-center">
                                    <input
                                        type="checkbox"
                                        checked={
                                            matches.checkedInputs.length === matches.low_ratios.length
                                        }
                                        onChange={(e) => {allChangeHandler(e)}}
                                    />
                                </th>
                                <th>종목</th>
                                <th>일정</th>
                                <th>리그</th>
                                <th>홈팀</th>
                                <th>원정팀</th>
                                <th>취소사유</th>
                                <th>FixID</th>
                            </tr>
                            </thead>
                            <tbody>
                            {matches.isLoading && "로딩중..."}
                            {!matches.isLoading && !matches.low_ratios.length > 0 && (
                                <tr>
                                    <td colSpan="3">목록이 비어있습니다.</td>
                                </tr>
                            )}
                            {!matches.isLoading && !!matches.low_ratios.length && matches.low_ratios.map((item, index) => (
                                <tr className={matches.checkedInputs.includes(item.id) && "info"} onClick={() => {
                                    dispatch(getStopFixtureDetail(item.fixture_id))
                                }}>
                                    <td>
                                        <input type="checkbox" name={item.fixture_id} id={item.fixture_id} value="true"
                                               checked={matches.checkedInputs.includes(item.fixture_id)}
                                               onChange={(e) => {changeHandler(e.currentTarget.checked, item.fixture_id, index)}}
                                        />
                                    </td>
                                    <td>{`${item.sport}\n(${item.mCount})`}</td>
                                    <td>{moment(item.start_date).format("YYYY-MM-DD HH:mm")}</td>
                                    <td>{item.league}</td>
                                    <td>
                                        {item.home}
                                    </td>
                                    <td>{item.away}</td>
                                    <td>{item['match.fixture.status']}</td>
                                    <td>{item.fixture_id}</td>

                                </tr>
                            ))}
                            </tbody>
                        </table>
                    )}
                </div>
                <div className="" style={{
                    width: "50%",
                    height: "1200px",
                    overflowY: "scroll",
                }}>
                    {matches.isLoadingDetail && "로딩중..."}
                    {!matches.isLoadingDetail && matches.currentFixture === null && "선택된 경기가 없습니다."}
                    {!matches.isLoadingDetail && matches.currentFixture !== null && (
                        <div>
                            <div className="text-bg-dark p-2 mb-5">
                                <p>{`${matches.currentFixture.ratios[0].sport} - ${matches.currentFixture.ratios[0].league}`}</p>
                                <div className="text-center align-middle" style={{
                                    height: "100px",
                                    display: "flex",
                                    justifyContent: "center"
                                }}>
                                    <div style={{
                                        width: "33.3%",
                                        alignSelf: "center"
                                    }}><p>{`${matches.currentFixture.ratios[0].home}`}</p></div>
                                    <div style={{
                                        width: "33.3%",
                                        alignSelf: "center",
                                    }}>
                                        <p className="m-0">{`${moment(matches.currentFixture.ratios[0].start_date).format("YYYY-MM-DD HH:mm")}`}</p>
                                    </div>
                                    <div style={{
                                        width: "33.3%",
                                        alignSelf: "center"
                                    }}>{`${matches.currentFixture.ratios[0].away}`}</div>

                                </div>

                            </div>
                            {getDetail()}

                        </div>
                    )}

                </div>
            </div>



            {matches.isLoading && "로딩중..."}
            {!matches.isLoading && matches.fixtures && (
                <table className="table table-bordered table-hover text-center">
                    <thead>
                    <tr>
                        <th className="text-center">
                            <input
                                type="checkbox"
                                checked={
                                    matches.checkedInputs.length === matches.fixtures.length
                                }
                                onChange={(e) => {allChangeHandler(e)}}
                            />
                        </th>
                        <th>업체</th>
                        <th>종목</th>
                        <th>구분</th>
                        <th>종류</th>
                        <th>경기일정</th>
                        <th>리그</th>
                        <th>홈팀명</th>
                        <th>A</th>
                        <th>무/핸/합</th>
                        <th>B</th>
                        <th>원정팀명</th>
                        <th>환수율</th>
                        <th>수정</th>
                        <th>FixID</th>
                        <th>마켓ID</th>
                        <th>파싱상태</th>
                    </tr>
                    </thead>
                    <tbody>
                    {!matches.fixtures.length && (
                        <tr>
                            <td colSpan="3">목록이 비어있습니다.</td>
                        </tr>
                    )}
                    {!!matches.fixtures.length && matches.fixtures.map((fixture, index) => (
                        <tr className={matches.checkedInputs.includes(fixture.id)? "info" : ""}>
                            <td>
                                <input type="checkbox" name={fixture.id} id={fixture.id} value="true"
                                       checked={matches.checkedInputs.includes(fixture.id)}
                                       onChange={(e) => {changeHandler(e.currentTarget.checked, fixture.id, index)}}
                                />
                            </td>
                            <td>{fixture.provider}</td>
                            <td>{fixture.sport}</td>
                            <td>{fixture.market_detail}</td>
                            <td>{fixture.market}</td>
                            <td>{moment(fixture.start_date).format("YYYY-MM-DD HH:mm")}</td>

                            <td>{fixture.league}</td>
                            <td>
                                {fixture.home}
                            </td>
                            <td>
                                <p>{matches.originFixtures.filter(item => item.id === fixture.id).length > 0?
                                    (<span style={{
                                        color: matches.originFixtures.filter(item => item.id === fixture.id)[0].bet1 < fixture.bet1?
                                            "blue"
                                            : "red",
                                        textDecoration: fixture.bet1_status !== 1?
                                            "line-through"
                                            : ""
                                    }}>{fixture.bet1}</span>)
                                    : (<span style={{
                                        textDecoration: fixture.bet1_status !== 1?
                                            "line-through"
                                            : ""
                                    }}>{fixture.bet1}
                                        </span>)}
                                </p>
                                <input type="number" className="form-control"
                                       defaultValue={fixture.bet1}
                                       value={matches.betInputs.filter((e) => e.id === fixture.id).length > 0? matches.betInputs.filter((e) => e.id === fixture.id)[0].bet1 : ""}
                                       onChange={(e) => {changeBet(e, fixture.id, index, 1) }} />
                            </td>
                            {fixture.market_id === 1 && [6046, 35232, 530129].includes(fixture.sport_id)?
                                (<td>
                                    <p>{matches.originFixtures.filter(item => item.id === fixture.id).length > 0?
                                        (<span style={{
                                            color: matches.originFixtures.filter(item => item.id === fixture.id)[0].bet2 < fixture.bet2?
                                                "blue"
                                                : "red",
                                            textDecoration: fixture.bet2_status !== 1?
                                                "line-through"
                                                : "",
                                        }}>
                                                {fixture.bet2}
                                            </span>)
                                        : (<span>
                                                {fixture.bet2}
                                            </span>)}
                                    </p>
                                    <input type="number" className="form-control"
                                           defaultValue={fixture.bet2}
                                           value={matches.betInputs.filter((e) => e.id === fixture.id).length > 0? matches.betInputs.filter((e) => e.id === fixture.id)[0].bet2 : ""}
                                           onChange={(e) => {changeBet(e, fixture.id, index, 2) }} />
                                </td>)
                                : ![1,5,52].includes(fixture.market_id)?
                                    (<td>{fixture.bet2? fixture.bet2.replace(" (0-0)", ""): fixture.bet2}</td>)
                                    : (<td>VS</td>)}
                            <td>
                                <p>{matches.originFixtures.filter(item => item.id === fixture.id).length > 0?
                                    (<span style={{
                                        color: matches.originFixtures.filter(item => item.id === fixture.id)[0].bet3 < fixture.bet3?
                                            "blue"
                                            : "red",
                                        textDecoration: fixture.bet3_status !== 1?
                                            "line-through"
                                            : "",
                                    }}>
                                            {fixture.bet3}
                                        </span>)
                                    : (<span style={{
                                        textDecoration: fixture.bet3_status !== 1?
                                            "line-through"
                                            : "",
                                    }}>
                                            {fixture.bet3}
                                        </span>)}
                                </p>
                                {fixture.market_id === 6? (<></>): (<input type="number" className="form-control"
                                                                           defaultValue={fixture.bet3}
                                                                           value={matches.betInputs.filter((e) => e.id === fixture.id).length > 0? matches.betInputs.filter((e) => e.id === fixture.id)[0].bet3 : ""}
                                                                           onChange={(e) => {changeBet(e, fixture.id, index, 3) }} />)}

                            </td>
                            <td>{fixture.away}</td>
                            <td></td>
                            <td></td>
                            <td>{fixture.fixture_id}</td>
                            <td>{fixture.market_id}</td>
                            <td></td>


                            {/*<td>
                                    <input
                                        name={index}
                                        type="text"
                                        defaultValue={league.name_kor}
                                        onBlur={(e) => {
                                            changeName(e.currentTarget.value, league.id, index)
                                        }}
                                    />
                                </td>*/}

                        </tr>
                    ))}
                    </tbody>
                </table>
            )}
        </div>
    );

}

export default StopMatchList;