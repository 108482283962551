import React from 'react';
import PopupContainer from "../../containers/board/PopupListContainer";


const Popup = () => {
    return (
        <div>
            <h1>팝업관리</h1>
            <PopupContainer />
        </div>
    );
};

export default Popup;