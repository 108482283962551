import React, {useEffect, useState} from "react";
import moment from "moment";
import typeCase from "../../util/typeCase";
import {NumericFormat} from "react-number-format";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import axios from "axios";
import UserInformation from "../../components/UserInformation";
import DepositListContainer from "../../containers/money/DepositListContainer";
//import {getDeposit} from "../../redux/reducers/money/deposit";

const DepositList = ({deposit, onRefresh}) => {
    const userInfo = useSelector((state) => state.userInfo)
    const [depositTotal, setDepositTotal] = useState(0);
    const [depositTypeSum, setDepositTypeSum] = useState(0);
    const [userDeposit, setUserDeposit] = useState([]);
    const [userWithdraw, setUserWithdraw] = useState([]);
    const [monthDeposit, setMonthDeposit] = useState([]);
    const [monthWithdraw, setMonthWithdraw] = useState([]);
    const [depositStateSum, setDepositStateSum] = useState(0);

    const [depositList, setDepositList] = useState([]);
    const [selectedNos, setSelectedNos] = useState([]);
    const [totalSelectedMoney, setTotalSelectedMoney] = useState(0);

    const [userInformation, setUserInformation] = useState(false);
    const initialState = useSelector((state) => state.deposit)
    const informationOpenHandler = () => {
        setUserInformation(true);
    }


    const getAllList = () => {
        axios.post('/admin/deposit/get_all_list', {
            adminNo: userInfo.userNo
        }).then((res) => {
            setDepositTotal(res.data.depositTotal)
            setDepositTypeSum(res.data.depositTypeSum)
            setUserDeposit(res.data.userDeposit)
            setUserWithdraw(res.data.userWithdraw)
            setMonthDeposit(res.data.monthDeposit)
            setMonthWithdraw(res.data.monthWithdraw)
            setDepositStateSum(res.data.depositStateSum)

            setDepositList(res.data.depositList)
            setSelectedNos([])
            setTotalSelectedMoney(0)
        })
    }
    useEffect(getAllList, [])

    const updateState = (state, depositNos) => {
        if (window.confirm("변경 하시겠습니까?"))
        {
            if(depositNos.length > 0) {
                axios.post('/admin/deposit/change_state', {
                    adminNo: userInfo.userNo,
                    depositNos: depositNos,
                    state: state
                }).then((res) => {
                    alert(res.data.isUpdated? "변경완료" : "변경실패")
                    //getAllList()
                    //window.location.reload();
                    onRefresh();
                })
            }
        }


    }

    const depositState = (state, no) => {
        switch(state) {
            case 0 :
                return (
                    <div className="d-flex justify-content-center">
                        <button type="button" className="btn btn-warning btn xSmallButton mx-1" onClick={() => {updateState(1, [no])}}>대기</button>
                        <button type="button" className="btn btn-primary btn xSmallButton mx-1" onClick={() => {updateState(2, [no])}}>완료</button>
                        <button type="button" className="btn btn-danger btn xSmallButton mx-1" onClick={() => {updateState(3, [no])}}>취소</button>
                    </div>
                )
            case 1 :
                return (
                    <div className="d-flex justify-content-center">
                        <button type="button" className="btn btn-primary btn xSmallButton mx-1" onClick={() => {updateState(2, [no])}}>완료</button>
                        <button type="button" className="btn btn-danger btn xSmallButton mx-1" onClick={() => {updateState(3, [no])}}>취소</button>
                    </div>
                )
            case 2 :
                return (
                    <div className="d-flex justify-content-center">완료</div>
                )
            case 3 :
                return "-"
            default : return state
        }
    }


    function onCommentColor (color) {
        switch (color) {
            case 0 : return "red"
            case 1 : return "orange"
            case 2 : return "yellow"
            case 3 : return "green"
            case 4 : return "blue"
            case 5 : return "navy"
            case 6 : return "purple"
            case 10 : return "gray"
            default : return color
        }
    }


    const allCheckHandler = (e) => {
        if(depositList.length > 0 && e.currentTarget.value) {
            const checks = [];
            let total = 0;
            for(let i = 0; i < depositList.length; i++) {
                checks.push(depositList[i].no)
                total += depositList[i].request_money
            }
            setSelectedNos(e.target.checked? checks : [])
            setTotalSelectedMoney(e.target.checked? total : 0)
        }
    }


    const checkHandler = (checked, no) => {
        let selectedNos2 = []
        if(checked) {
            selectedNos2 = [...selectedNos, no]
            setSelectedNos([...selectedNos, no])
        }
        else {
            selectedNos2 = selectedNos.filter((id) => id !== no)
            setSelectedNos(selectedNos.filter((id) => id !== no))
        }
        let total = 0;
        for(let i = 0; i < selectedNos2.length; i++) {
            total += depositList.filter((id) => id.no === selectedNos2[i])[0].request_money
        }
        setTotalSelectedMoney(total)
    }


    const allList = () => {
        getAllList()
    }


    const depositStateColor = (state) => {
        switch(state) {
            case 0 :
                return "bgRequest"
            case 1 :
                return "bgWait"
            case 2 :
                return "bgComplete"
            case 3 :
                return "bgCancel"
            default : return state
        }
    }

    return (
        <div className="m-2">

            <div className="row my-2">
                <div className="col-5">
                    <span>전체충전신청 :
                        <NumericFormat className="ms-1" value={depositTotal && depositTotal[0].depositTotal} displayType={'text'} thousandSeparator={true}/>
                    </span>
                </div>
            </div>
            <div className="my-2 d-flex">
                <div className="col-5">
                    <h4 className="text-danger">선택금액 :
                        <NumericFormat className="ms-1" value={totalSelectedMoney} displayType={'text'} thousandSeparator={true}/>
                    </h4>
                </div>
                <div className="col-7" style={{
                    textAlign: "right"
                }}>
                    <span>스포츠 :
                        {depositTypeSum.length > 0?
                            <NumericFormat className="ms-1" value={depositTypeSum[0].deposit_type === 0?
                                depositTypeSum[0].depositTypeSum : 0 } displayType={'text'} thousandSeparator={true}/>
                            : <>0</>}
                    </span>
                    <span className="mx-2">|</span>
                        <span>미니게임 :
                            {depositTypeSum.length > 0?
                                <NumericFormat className="ms-1" value={depositTypeSum[1].deposit_type === 1?
                                    depositTypeSum[1].depositTypeSum : 0 } displayType={'text'} thousandSeparator={true}/>
                                : <>0</>}
                        </span>
                    <span className="mx-2">|</span>
                        <span>카지노 :
                            {depositTypeSum.length > 0?
                                <NumericFormat className="ms-1" value={depositTypeSum[0].deposit_type === 2?
                                    depositTypeSum[2].depositTypeSum : 0 } displayType={'text'} thousandSeparator={true}/>
                                : <>0</>}
                        </span>
                </div>

            </div>
            <div className="row my-2 text-center">
                <div className="col-4 depositd1 d-flex">

                    {/*<button type="button" className="btn btn-warning btn-sm me-1" style={{*/}
                    {/*    width: "100px",*/}
                    {/*    height: "30px"*/}
                    {/*}} onClick={() => {*/}
                    {/*    updateState(1, selectedNos)*/}
                    {/*}}>선택대기</button>*/}
                    <button type="button" className="btn btn-primary btn-sm me-1" style={{
                        width: "100px",
                        height: "30px"
                    }} onClick={() => {
                        updateState(2, selectedNos)
                    }}>선택완료</button>
                    <button type="button" className="btn btn-outline-secondary btn-sm text-danger" style={{
                        width: "100px",
                        height: "30px"
                    }}  onClick={() => {
                        updateState(3, selectedNos)
                    }}>선택취소</button>

                </div>

                <div className="col-8" style={{
                    textAlign: "right"
                }}>
                            <span>충전신청 금액 :
                                {depositStateSum.length > 0?
                                    <NumericFormat className="ms-1" value={depositStateSum[0].deposit_state === 0?
                                        depositStateSum[0].depositStateSum : 0 } displayType={'text'} thousandSeparator={true}/>
                                    : <>0</>}
                            </span>
                    <span className="mx-2">|</span>
                    <span className="text-secondary">충전대기 금액 :
                        {depositStateSum.length > 0?
                            <NumericFormat className="ms-1" value={depositStateSum[1].deposit_state === 1?
                                depositStateSum[1].depositStateSum : 0 } displayType={'text'} thousandSeparator={true}/>
                            : <>0</>}
                            </span>
                    <span className="mx-2">|</span>
                    <span className="text-success">충전완료 금액 :
                        {depositStateSum.length > 0?
                            <NumericFormat className="ms-1" value={depositStateSum[2].deposit_state === 2?
                                depositStateSum[2].depositStateSum : 0 } displayType={'text'} thousandSeparator={true}/>
                            : <>0</>}
                            </span>
                    <span className="mx-2">|</span>
                    <span className="text-danger">충전취소 금액 :
                        {depositStateSum.length > 0?
                            <NumericFormat className="ms-1" value={depositStateSum[3].deposit_state === 3?
                                depositStateSum[3].depositStateSum : 0 } displayType={'text'} thousandSeparator={true}/>
                            : <>0</>}
                            </span>
                </div>
            </div>

            <div className="container-fluid">
                <table className="table table-bordered table-sm text-center align-middle">
                    <thead className="table-dark">

                    <tr >
                        <td style={{width: "2%"}}>
                            <input className="form-check-input" type="checkbox" checked={depositList.length === selectedNos.length} onChange={allCheckHandler}
                                   id="flexCheckDefault"/>
                        </td>
                        <td style={{width: "3%"}}>파트너</td>
                        <td style={{width: "3%"}}>상태</td>
                        <td style={{width: "3%"}}>등급</td>
                        <td style={{width: "6%"}}>닉네임</td>
                        <td style={{width: "5%"}}>충전</td>
                        <td style={{width: "5%"}}>환전</td>
                        <td style={{width: "5%"}}>충환차액</td>
                        <td style={{width: "5%"}}>이달수익</td>
                        <td style={{width: "5%"}}>보유머니</td>
                        <td style={{width: "3%"}}>예금주</td>
                        <td style={{width: "6%"}}>충전금액</td>
                        <td style={{width: "6%"}}>추가포인트</td>
                        <td style={{width: "8%"}}>요청시간</td>
                        <td style={{width: "8%"}}>처리시간</td>
                        <td style={{width: "10%"}}>처리상태</td>
                        <td style={{width: "4%"}}>충전구분</td>
                        <td style={{width: "3%"}}>관리자</td>
                    </tr>
                    </thead>

                    {!deposit.length && (
                        <tbody key={deposit.no}>
                        <tr>
                            <td colSpan="18">목록이 비어있습니다.</td>
                        </tr>
                        </tbody>
                    )}
                    {deposit.map((bet, index) => (
                        <tbody key={deposit.no}>
                            <tr className={depositStateColor(bet.deposit_state)}>
                                <td>
                                    <input className="form-check-input" type="checkbox" checked={selectedNos.filter((no) => no === bet.no).length > 0} onChange={(e) => {
                                        checkHandler(e.currentTarget.checked, bet.no)
                                    }}/>
                                </td>
                                <td>본사</td>
                                <td>{typeCase.userState(bet.user.user_state)}</td>
                                <td>{typeCase.levelState(bet.user.level)}</td>
                                <td>
                                    <Link className="nav-link" to={"#userInformation" + index} data-bs-toggle="collapse"
                                          aria-expanded="false" aria-controls={"#userInformation" + index}
                                          onClick={informationOpenHandler}>
                                        {bet.user.comment ?
                                            <>
                                                <span>{bet.user.nickname}</span>
                                                <br></br>
                                                <span style={{fontSize: "12px"}}>
                                                        <span className="me-1" style={{backgroundColor: onCommentColor(bet.user.comment_color)}}>
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                        </span>
                                                    {bet.user.comment}
                                                    </span>
                                            </>
                                            :  (bet.user.comment_color !== null?
                                                <>
                                                    <span>{bet.user.nickname}</span>
                                                    <br></br>
                                                    <span className="me-1" style={{backgroundColor: onCommentColor(bet.user.comment_color)}}>
                                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                            </span>
                                                </>
                                                :  <span>{bet.user.nickname}</span> )}
                                    </Link>
                                </td>
                                <td>
                                    <NumericFormat value={userDeposit.filter((el) => el.user_no === bet.user_no).length > 0? userDeposit.filter((el) => el.user_no === bet.user_no)[0].user_total : 0} displayType={'text'} thousandSeparator={true}/>
                                </td>
                                <td>
                                    <NumericFormat value={userWithdraw.filter((el) => el.user_no === bet.user_no).length > 0? userWithdraw.filter((el) => el.user_no === bet.user_no)[0].user_total : 0} displayType={'text'} thousandSeparator={true}/>
                                </td>
                                <td>
                                    <NumericFormat value={
                                        (userDeposit.filter((el) => el.user_no === bet.user_no).length > 0? userDeposit.filter((el) => el.user_no === bet.user_no)[0].user_total : 0)
                                        -
                                        (userWithdraw.filter((el) => el.user_no === bet.user_no).length > 0? userWithdraw.filter((el) => el.user_no === bet.user_no)[0].user_total : 0)
                                    } displayType={'text'} thousandSeparator={true}/>
                                </td>
                                {/*이달수익*/}
                                <td>
                                    <NumericFormat value={
                                        (monthDeposit && monthDeposit.filter((el) => el.user_no === bet.user_no).length > 0?
                                            monthDeposit.filter((el) => el.user_no === bet.user_no)[0].user_total : 0)
                                        -
                                        (monthWithdraw && monthWithdraw.filter((el) => el.user_no === bet.user_no).length > 0?
                                            monthWithdraw.filter((el) => el.user_no === bet.user_no)[0].user_total : 0)
                                    } displayType={'text'} thousandSeparator={true}/>
                                </td>
                                <td><NumericFormat value={bet.user.money} displayType={'text'} thousandSeparator={true}/></td>
                                <td>{bet.user.name}</td>
                                <td><NumericFormat value={bet.request_money} displayType={'text'} thousandSeparator={true}/></td>
                                <td>-</td>
                                <td>
                                    {moment(bet.createdAt).format("YYYY-MM-DD")}
                                    <br></br>
                                    {moment(bet.createdAt).format(" HH:mm:ss")}
                                </td>
                                <td>
                                    {moment(bet.updatedAt).format("YYYY-MM-DD")}
                                    <br></br>
                                    {moment(bet.updatedAt).format("HH:mm:ss")}

                                </td>
                                <td>{depositState(bet.deposit_state, bet.no)}</td>
                                <td>{typeCase.depositType(bet.deposit_type)}</td>
                                <td>{bet.admin? bet.admin.id : "-"}</td>
                            </tr>
                            <tr className="collapse" id={"userInformation" + index} key={bet.user.id}>
                                <td colSpan="18">
                                    {userInformation && <UserInformation no={bet.user.no} id={bet.user.id}/> }
                                </td>
                            </tr>
                        </tbody>
                    ))}

                </table>

            </div>

        </div>
    );
}

export default DepositList;