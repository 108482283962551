import React from 'react';
import TeamListApiSettingContainer from "../../containers/match/TeamListApiSettingContainer";

const TeamListApiSettingPage = () => {
    return (
        <div>
            <h3>팀목록</h3>
            <TeamListApiSettingContainer/>
        </div>
    );
};

export default TeamListApiSettingPage;