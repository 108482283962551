import {call, put} from "redux-saga/effects";
import {failedSideStatusReducer, setSideStatusReducer} from "../../reducers/status/SideStatusReducer";
import {fetchSideStatus} from "../../../lib/api";

export function* getSideStatusSaga() {
    try{
        console.log("##getSideStatusSaga#")
        const res = yield call(fetchSideStatus)
        yield put(setSideStatusReducer(res.data))
    }
    catch (error) {
        console.log(error)
        yield put(failedSideStatusReducer(error))
    }
}