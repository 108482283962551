import React from 'react';
import CancelBettingListContainer from "../../containers/betting/CancelBettingListContainer";

const CancelBetting = () => {
    return (
        <div>
            <h1>완료배팅</h1>
            <CancelBettingListContainer />
        </div>
    )
}

export default CancelBetting;