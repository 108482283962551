import {all, fork, takeEvery, takeLatest} from 'redux-saga/effects';
import {changeSport, getSport, waitSportTask} from "../reducers/match/sport";
import {changeLeague, getLeague, waitLeagueTask} from "../reducers/match/league";
import {changeTeam, getTeam, waitTeamTask} from "../reducers/match/team";
import {changeMarket, getMarket, waitMarketTask} from "../reducers/match/market";
import {changeBookmaker, getBookmaker, waitBookmakerTask} from "../reducers/match/bookmaker";
import {changeSettingMarket, getSettingMarket, waitSettingMarketTask} from "../reducers/match/settingMarket";
import {changeFixture, getFixture, waitFixtureTask} from "../reducers/match/fixture";
import {
    changeParsingWait,
    getWaitFixture,
    getWaitFixtureDetail,
    waitParsingWaitTask
} from "../reducers/match/parsingWait";
import {
    changeActiveMatch,
    getActiveFixture,
    getActiveFixtureDetail,
    waitActiveMatchTask
} from "../reducers/match/activeMatch";
import {
    changeCloseMatch,
    getCloseFixture,
    getCloseFixtureDetail,
    waitCloseMatchTask
} from "../reducers/match/closeMatch";
import {changeStopMatch, getStopFixture, getStopFixtureDetail, waitStopMatchTask} from "../reducers/match/stopMatch";
import {changeStopMatchBy, getStopMatchBy, waitStopMatchByTask} from "../reducers/match/stopMatchBy";
import {
    changeResultMatch,
    getResultFixture,
    getResultFixtureDetail,
    waitResultMatchTask
} from "../reducers/match/resultMatch";

import {
    changeCancelMatch,
    getCancelFixture,
    getCancelFixtureDetail,
    waitCancelMatchTask
} from "../reducers/match/cancelMatch";
import {changeCancelMatchBy, getCancelMatchBy, waitCancelMatchByTask} from "../reducers/match/cancelMatchBy";



import {changeSportSaga, getSportSaga, waitSportTaskSaga} from "./match/sport";
import {changeLeagueSaga, getLeagueSaga, waitLeagueTaskSaga} from "./match/league";
import {changeTeamSaga, getTeamSaga, waitTeamTaskSaga} from "./match/team";
import {changeMarketSaga, getMarketSaga, waitMarketTaskSaga} from "./match/market";
import {changeBookmakerSaga, getBookmakerSaga, waitBookmakerTaskSaga} from "./match/bookmaker";
import {changeSettingMarketSaga, getSettingMarketSaga, waitSettingMarketTaskSaga} from "./match/settingMarket";
import {changeFixtureSaga, getFixtureSaga, waitFixtureTaskSaga} from "./match/fixture";
import {
    changeParsingWaitSaga,
    getWaitFixtureDetailSaga,
    getWaitFixtureSaga,
    waitParsingWaitTaskSaga
} from "./match/parsingWait";
import {
    changeActiveMatchSaga,
    getActiveFixtureDetailSaga,
    getActiveFixtureSaga,
    waitActiveMatchTaskSaga
} from "./match/activeMatch";
import {
    changeCloseMatchSaga,
    getCloseFixtureDetailSaga,
    getCloseFixtureSaga,
    waitCloseMatchTaskSaga
} from "./match/closeMatch";
import {
    changeStopMatchSaga,
    getStopFixtureDetailSaga,
    getStopFixtureSaga,
    waitStopMatchTaskSaga
} from "./match/stopMatch";
import {changeStopMatchBySaga, getStopMatchBySaga, waitStopMatchByTaskSaga} from "./match/stopMatchBy";
import {
    changeResultMatchSaga,
    getResultFixtureDetailSaga,
    getResultFixtureSaga,
    waitResultMatchTaskSaga
} from "./match/resultMatch";

import {
    changeCancelMatchSaga,
    getCancelFixtureDetailSaga,
    getCancelFixtureSaga,
    waitCancelMatchTaskSaga
} from "./match/cancelMatch";
import {changeCancelMatchBySaga, getCancelMatchBySaga, waitCancelMatchByTaskSaga} from "./match/cancelMatchBy";
import {setUserInfoSaga} from "./userInfo";
import {getTotalBetting} from "../reducers/betting/totalBetting";
import {getTotalBettingSaga} from "./betting/totalBetting";
import {getActiveBetting} from "../reducers/betting/activeBetting";
import {getActiveBettingSaga} from "./betting/activeBetting";
import {getCancelBetting} from "../reducers/betting/cancelBetting";
import {getCancelBettingSaga} from "./betting/cancelBetting";
import {getCloseBetting} from "../reducers/betting/closeBetting";
import {getCloseBettingSaga} from "./betting/closeBetting";
import {getCompleteBetting} from "../reducers/betting/completeBetting";
import {getCompleteBettingSaga} from "./betting/completeBetting";
import {getDeposit} from "../reducers/money/deposit";
import {getDepositComplete} from "../reducers/money/depositComplete";
import {getDepositSaga} from "./money/deposit";
import {getDepositCompleteSaga} from "./money/depositComplete";
import {getStats} from "../reducers/stat";
import {getStatsSaga} from "./stat";
import {getHomeStats, getHomeTableStats} from "../reducers/home/HomeReducer";
import {getHomeStatsSaga, getHomeTableStatsSaga} from "./home/HomeSaga";
import {getNowStatusSaga, getTodayStatusSaga} from "./status";
import {getNowStatus, getTodayStatus} from "../reducers/status";

import {getNotice} from "../reducers/board/notice";
import {getNoticeSaga} from "./board/notice";
import {getHelp} from "../reducers/board/help";
import {getHelpSaga} from "./board/help";
import {getFaq} from "../reducers/board/faq";
import {getFaqSaga} from "./board/faq";
import {getMarquee} from "../reducers/board/marquee";
import {getMarqueeSaga} from "./board/marquee";
import {getPopup} from "../reducers/board/popup";
import {getPopupSaga} from "./board/popup";
import {getEvent} from "../reducers/board/event";
import {getEventSaga} from "./board/event";

import {getMemberSaga} from "./user/member";
import {getMemberUser} from "../reducers/user/member";
import {getAttendSaga} from "./user/attend";
import {getAttend} from "../reducers/user/attend";
import {getMemoSaga} from "./user/memo";
import {getMemo} from "../reducers/user/memo";
import {getLoginLogSaga} from "./user/loginLog";
import {getLoginLog} from "../reducers/user/loginLog";
import {getBlockSaga} from "./user/block";
import {getBlock} from "../reducers/user/block";

import {getMoneyLogMngReducer} from "../reducers/money/MoneyLogMngReducer";
import {getMoneyLogMngSaga} from "./money/MoneyLogMngSaga";
import {getPointLogMngReducer} from "../reducers/money/PointLogMngReducer";
import {getPointLogMngSaga} from "./money/PointLogMngSaga";
import {getWithdrawMngReducer} from "../reducers/money/WithdrawMngReducer";
import {getWithdrawMngSaga} from "./money/WithdrawMngSaga";
import {getWithdrawCompleteReducer} from "../reducers/money/WithdrawCompleteReducer";
import {getWithdrawCompleteSaga} from "./money/WithdrawCompleteSaga";
import {getUserDetailReducer} from "../reducers/user/UserDetailReducer";
import {getUserDetailSaga} from "./user/UserDetailSaga";
import {getJoinListReducer} from "../reducers/user/JoinListReducer";
import {getJoinListSaga} from "./user/JoinListSaga";
import {getConnectListReducer} from "../reducers/user/ConnectListReducer";
import {getConnectListSaga} from "./user/ConnectListSaga";
import {getLostListReducer} from "../reducers/user/LostListReducer";
import {getLostListSaga} from "./user/LostListSaga";
import {getFailListReducer} from "../reducers/user/FailListReducer";
import {getFailListSaga} from "./user/FailListSaga";
import {getAdminReducer} from "../reducers/user/AdminReducer";
import {getAdminSaga} from "./user/AdminSaga";
import {getAdminFailLogReducer} from "../reducers/user/AdminFailLogReducer";
import {getAdminFailLogSaga} from "./user/AdminFailLogSaga";
import {getSideStatusReducer} from "../reducers/status/SideStatusReducer";
import {getSideStatusSaga} from "./status/SideStatusSaga";
import {getSportsApiSettingInfoListReducer} from "../reducers/match/sportsApiSettingReducer";
import {getSportsApiSettingInfoListSaga} from "./match/sportsApiSettingSaga";
import {getNationApiSettingInfoListReducer} from "../reducers/match/nationApiSettingReducer";
import {getNationApiSettingInfoListSaga} from "./match/nationApiSettingSaga";
import {getLeagueApiSettingInfoListReducer} from "../reducers/match/leagueApiSettingReducer";
import {getLeagueApiSettingInfoListSaga} from "./match/leagueApiSettingSaga";
import {getTeamApiSettingInfoListReducer} from "../reducers/match/teamApiSettingReducer";
import {getTeamApiSettingInfoListSaga} from "./match/teamApiSettingSaga";
import {getMarketApiSettingInfoListReducer} from "../reducers/match/marketApiSettingReducer";
import {getMarketApiSettingInfoListSaga} from "./match/marketApiSettingSaga";
import {getBasicSettingPage} from "../reducers/match/basicSettingPage";
import {getBasicSettingPageSaga} from "./match/basicSettingPage";
import {getSportMarketListSettingPage} from "../reducers/match/sportMarketListSettingPage";
import {getSportsMarketListSettingPageSaga} from "./match/sportsMarketListSettingPage"
import {getAgentListReducer} from "../reducers/agent/AgentListReducer";
import {getAgentListSaga} from "./agent/AgentListSaga";
import {getDailyFeeReducer} from "../reducers/agent/DailyFeeReducer";
import {getDailyFeeSaga} from "./agent/DailyFeeSaga";
import {getJoinCode} from "../reducers/setting/joinCode";
import {getJoinCodeSaga} from "./setting/joinCode";
import {getPageAphAthrReducer} from "../reducers/setting/PageAphAthrReducer";
import {getPageAphAthrSaga} from "./setting/PageAphAthrSaga";
import {getPageAphAthrMenuListSettingReducer} from "../reducers/setting/PageAphAthrReducer";
import {getPageAphAthrMenuListSettingSaga} from "./setting/PageAphAthrSaga";

export function* watcherSaga() {
    yield all([
        fork(setUserInfoSaga)
    ])

    //Sport
    yield takeLatest(getSport.type, getSportSaga)
    yield takeLatest(changeSport.type, changeSportSaga)
    yield takeEvery(waitSportTask.type, waitSportTaskSaga)

    //League
    yield takeLatest(getLeague.type, getLeagueSaga)
    yield takeLatest(changeLeague.type, changeLeagueSaga)
    yield takeEvery(waitLeagueTask.type, waitLeagueTaskSaga)

    //Team
    yield takeLatest(getTeam.type, getTeamSaga)
    yield takeLatest(changeTeam.type, changeTeamSaga)
    yield takeEvery(waitTeamTask.type, waitTeamTaskSaga)

    //Market
    yield takeLatest(getMarket.type, getMarketSaga)
    yield takeLatest(changeMarket.type, changeMarketSaga)
    yield takeEvery(waitMarketTask.type, waitMarketTaskSaga)

    //Bookmaker
    yield takeLatest(getBookmaker.type, getBookmakerSaga)
    yield takeLatest(changeBookmaker.type, changeSettingMarketSaga)
    yield takeEvery(waitBookmakerTask.type, waitBookmakerTaskSaga)

    //SettingMarket
    yield takeLatest(getSettingMarket.type, getSettingMarketSaga)
    yield takeLatest(changeSettingMarket.type, changeBookmakerSaga)
    yield takeEvery(waitSettingMarketTask.type, waitSettingMarketTaskSaga)


    //Fixture
    yield takeLatest(getFixture.type, getFixtureSaga)
    yield takeLatest(changeFixture.type, changeFixtureSaga)
    yield takeEvery(waitFixtureTask.type, waitFixtureTaskSaga)

    //CancelMatchBy
    yield takeLatest(getCancelMatchBy.type, getCancelMatchBySaga)
    yield takeLatest(changeCancelMatchBy.type, changeCancelMatchBySaga)
    yield takeEvery(waitCancelMatchByTask.type, waitCancelMatchByTaskSaga)

    //CancelMatch
    yield takeLatest(getCancelFixtureDetail.type, getCancelFixtureDetailSaga)
    yield takeLatest(getCancelFixture.type, getCancelFixtureSaga)
    yield takeLatest(changeCancelMatch.type, changeCancelMatchSaga)
    yield takeEvery(waitCancelMatchTask.type, waitCancelMatchTaskSaga)

    //ResultMatch
    yield takeLatest(getResultFixtureDetail.type, getResultFixtureDetailSaga)
    yield takeLatest(getResultFixture.type, getResultFixtureSaga)
    yield takeLatest(changeResultMatch.type, changeResultMatchSaga)
    yield takeEvery(waitResultMatchTask.type, waitResultMatchTaskSaga)

    //StopMatchBy
    yield takeLatest(getStopMatchBy.type, getStopMatchBySaga)
    yield takeLatest(changeStopMatchBy.type, changeStopMatchBySaga)
    yield takeEvery(waitStopMatchByTask.type, waitStopMatchByTaskSaga)

    //StopMatch
    yield takeLatest(getStopFixtureDetail.type, getStopFixtureDetailSaga)
    yield takeLatest(getStopFixture.type, getStopFixtureSaga)
    yield takeLatest(changeStopMatch.type, changeStopMatchSaga)
    yield takeEvery(waitStopMatchTask.type, waitStopMatchTaskSaga)

    //CloseMatch
    yield takeLatest(getCloseFixture.type, getCloseFixtureSaga)
    yield takeLatest(getCloseFixtureDetail.type, getCloseFixtureDetailSaga)
    yield takeLatest(changeCloseMatch.type, changeCloseMatchSaga)
    yield takeEvery(waitCloseMatchTask.type, waitCloseMatchTaskSaga)

    //ActiveMatch
    yield takeLatest(getActiveFixture.type, getActiveFixtureSaga)
    yield takeLatest(getActiveFixtureDetail.type, getActiveFixtureDetailSaga)
    yield takeLatest(changeActiveMatch.type, changeActiveMatchSaga)
    yield takeEvery(waitActiveMatchTask.type, waitActiveMatchTaskSaga)

    //ParsingWait
    yield takeLatest(getWaitFixtureDetail.type, getWaitFixtureDetailSaga)
    yield takeLatest(getWaitFixture.type, getWaitFixtureSaga)
    yield takeLatest(changeParsingWait.type, changeParsingWaitSaga)
    yield takeEvery(waitParsingWaitTask.type, waitParsingWaitTaskSaga)


    //Betting
    yield takeLatest(getTotalBetting.type, getTotalBettingSaga)
    yield takeLatest(getActiveBetting.type, getActiveBettingSaga)
    yield takeLatest(getCancelBetting.type, getCancelBettingSaga)
    yield takeLatest(getCloseBetting.type, getCloseBettingSaga)
    yield takeLatest(getCompleteBetting.type, getCompleteBettingSaga)

    yield takeLatest(getDeposit.type, getDepositSaga)
    yield takeLatest(getDepositComplete.type, getDepositCompleteSaga)

    //Stat
    yield takeLatest(getStats.type, getStatsSaga)

    //Home
    yield takeLatest(getHomeStats.type, getHomeStatsSaga)
    yield takeLatest(getHomeTableStats.type, getHomeTableStatsSaga)

    //Status
    yield takeLatest(getNowStatus.type, getNowStatusSaga)
    yield takeLatest(getTodayStatus.type, getTodayStatusSaga)



    //board-notice
    yield takeLatest(getNotice.type, getNoticeSaga)
    //board-help
    yield takeLatest(getHelp.type, getHelpSaga)
    //board-faq
    yield takeLatest(getFaq.type, getFaqSaga)
    //board-getMarquee
    yield takeLatest(getMarquee.type, getMarqueeSaga)
    //board-popup
    yield takeLatest(getPopup.type, getPopupSaga)
    //board-event
    yield takeLatest(getEvent.type, getEventSaga)


    //user-member
    yield takeLatest(getMemberUser.type, getMemberSaga)
    //user-attend
    yield takeLatest(getAttend.type, getAttendSaga)
    //user-memo
    yield takeLatest(getMemo.type, getMemoSaga)
    //user-loginLog
    yield takeLatest(getLoginLog.type, getLoginLogSaga)
    //user-block
    yield takeLatest(getBlock.type, getBlockSaga)

    //withdraw 환전
    yield takeLatest(getWithdrawMngReducer.type, getWithdrawMngSaga)
    yield takeLatest(getWithdrawCompleteReducer.type, getWithdrawCompleteSaga)

    //money 머니
    yield takeLatest(getMoneyLogMngReducer.type, getMoneyLogMngSaga)
    //Point 포인트
    yield takeLatest(getPointLogMngReducer.type, getPointLogMngSaga)

    //user 유저
    yield takeLatest(getUserDetailReducer.type, getUserDetailSaga)

    //member 멤버
    yield takeLatest(getJoinListReducer.type, getJoinListSaga)
    yield takeLatest(getConnectListReducer.type, getConnectListSaga)
    yield takeLatest(getLostListReducer.type, getLostListSaga)
    yield takeLatest(getFailListReducer.type, getFailListSaga)
    yield takeLatest(getAdminReducer.type, getAdminSaga)
    yield takeLatest(getAdminFailLogReducer.type, getAdminFailLogSaga)

    //status
    yield takeLatest(getSideStatusReducer.type, getSideStatusSaga)

    //스포츠 API 설정
    yield takeLatest(getSportsApiSettingInfoListReducer.type,getSportsApiSettingInfoListSaga)
    yield takeLatest(getNationApiSettingInfoListReducer.type,getNationApiSettingInfoListSaga)
    yield takeLatest(getLeagueApiSettingInfoListReducer.type,getLeagueApiSettingInfoListSaga)
    yield takeLatest(getTeamApiSettingInfoListReducer.type,getTeamApiSettingInfoListSaga)

    //마켓 API 설정
    yield takeLatest(getMarketApiSettingInfoListReducer.type,getMarketApiSettingInfoListSaga)
    //match.
    yield takeLatest(getBasicSettingPage.type, getBasicSettingPageSaga)
    yield takeLatest(getSportMarketListSettingPage.type, getSportsMarketListSettingPageSaga)

    //agent
    yield takeLatest(getAgentListReducer.type, getAgentListSaga)
    yield takeLatest(getDailyFeeReducer.type, getDailyFeeSaga)

    //setting
    yield takeLatest(getJoinCode.type, getJoinCodeSaga)
    yield takeLatest(getPageAphAthrReducer.type, getPageAphAthrSaga)

    //common
    yield takeLatest(getPageAphAthrMenuListSettingReducer.type, getPageAphAthrMenuListSettingSaga)
}
