import {call, put, take,} from "redux-saga/effects";
import {closeChannel, createSocketChannel} from "../../channel/createSocketChannel";
import socket from "../../../config/socket";
import {
    changeCancelMatch, failedGetCancelFixture, failedGetCancelFixtureDetail,
    getCancelFixtureDetail,
    pushCancelMatchTask,
    setCancelFixture, setCurrentFixture
} from "../../reducers/match/cancelMatch";
import {fetchCancelFixture, fetchFixtureDetail} from "../../../lib/api";

export function* getCancelFixtureSaga(action) {
    try {
        const res = yield call(fetchCancelFixture,
            action.payload.page, action.payload.pageSize, action.payload.sport,
            action.payload.provider, action.payload.league, action.payload.query,
            action.payload.startDate, action.payload.endDate);
        yield put(setCancelFixture(res.data));
        if(res.data.result?.length > 0) {
            yield put(getCancelFixtureDetail(res.data.result[0].fixture_id))
        }
        else {
            yield put(setCurrentFixture(null))
        }
    } catch(error) {
        console.log(error)
        yield put(failedGetCancelFixture(error));
    }
}
export function* getCancelFixtureDetailSaga(action) {
    try {
        const res = yield call(fetchFixtureDetail, action.payload, 8)
        yield put(setCurrentFixture(res.data))
    }
    catch (error) {
        console.log(error)
        yield put(failedGetCancelFixtureDetail(error))
    }
}

export function* waitCancelMatchTaskSaga() {
    let channel;
    try {
        channel = yield call(createSocketChannel, "cancelMatch");
        while(true) {
            const task = yield take(channel);
            yield onTaskSaga(task)
        }
    } catch (e) {
        console.log(e, "error");
    } finally {
        socket.close();
        closeChannel(channel)
    }
}

function* onTaskSaga(task) {
    yield put(pushCancelMatchTask(task));
    yield put(changeCancelMatch(task));
}

export function* changeCancelMatchSaga() {
    //yield put(changeSport(changedItem))
}

