import React from 'react';
import LoginLogContainer from "../../containers/user/LoginLogListContainer";


const LoginLog = () => {
    return (
        <div>
            <h1>로그인내역</h1>
            <LoginLogContainer />
        </div>
    );
};

export default LoginLog;