import React from 'react';
import PointLogMngListContainer from "../../containers/money/PointLogMngListContainer";
const PointLogMngPage = () => {
    return (
        <div>
            <h1>포인트관리</h1>
            <PointLogMngListContainer />
        </div>

    )
}

export default PointLogMngPage;