import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@material-ui/core";
import React, {useState} from "react";
import axios from "axios";
import moment from "moment/moment";
import {useSelector} from "react-redux";

const JoinComponent = (props) => {


    const { open, onClose } = props

    const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().add(1, "day").format('YYYY-MM-DD'));
    const [storeName, setStoreName] = useState("");
    const [storeCode, setStoreCode] = useState("");
    const [cont, setCont] = useState("");
    const [useNumber, setUseNumber] = useState(0);

    const userInfo = useSelector((state) => state.userInfo);


    const storeNameChangeHandler = (e) => {
        setStoreName(e.currentTarget.value);
        console.log(e.currentTarget.value)
    }
    const storeCodeChangeHandler = (e) => {
        setStoreCode(e.currentTarget.value);
    }
    const contChangeHandler = (e) => {
        setCont(e.currentTarget.value);
    }
    const startDateChangeHandler = (e) => {
        setStartDate(e.currentTarget.value);
    }
    const endDateChangeHandler = (e) => {
        setEndDate(e.currentTarget.value);
    }
    const use_NumberChangeHandler = (e) => {
        setUseNumber(e.currentTarget.value);
    }

    const handleCancel = () => {
        setStoreName("");
        setStoreCode("");
        setCont("");
        setUseNumber(0);
        onClose();
    }
    const handleRegister = () => {
        if(storeName ==="") {
            alert("매장이름을 입력해주세요")
        } else if (storeCode === "") {
            alert("매장코드를 입력해주세요")
        } else if (cont === "") {
            alert("내용을 입력해주세요")
        } else if (startDate === "") {
            alert("시작시간을 입력해주세요")
        } else if (endDate === "") {
            alert("종료시간을 입력해주세요")
        } else if (storeCode === "") {
            alert("사용횟수를 입력해주세요")
        } else {
            if (window.confirm("등록 하시겠습니까?")) {
                axios.post('/admin/join_code/insert', {
                    store_name: storeName,
                    store_code: storeCode,
                    cont: cont,
                    start_date: startDate,
                    end_date: endDate,
                    use_number: useNumber,
                    admin_no: userInfo.userNo
                }).then((res) => {
                    if(res.data.isInserted) {
                        setStoreName("");
                        setStoreCode("");
                        setCont("");
                        setUseNumber(0);
                        onClose();
                        alert('가입완료')
                    }
                    else {
                        alert('가입실패')
                    }
                })
            }

        }
    }
    return (
        <Dialog open={open}>
            <DialogTitle>가입코드등록</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="store_name"
                    label="매장명"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={storeName}
                    onChange={storeNameChangeHandler}
                />
                <TextField
                    margin="dense"
                    id="store_code"
                    label="매장코드"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={storeCode}
                    onChange={storeCodeChangeHandler}
                />
                <TextField
                    margin="dense"
                    id="cont"
                    label="내용"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={cont}
                    onChange={contChangeHandler}
                />
                <TextField
                    className="mt-4"
                    margin="dense"
                    id="start_date"
                    label="시작시간"
                    type="date"
                    fullWidth
                    variant="standard"
                    value={startDate}
                    onChange={startDateChangeHandler}
                />
                <TextField
                    className="mt-4"
                    margin="dense"
                    id="end_date"
                    type="date"
                    fullWidth
                    variant="standard"
                    value={endDate}
                    onChange={endDateChangeHandler}
                />
                <TextField
                    margin="dense"
                    id="use_number"
                    label="사용횟수"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={useNumber}
                    onChange={use_NumberChangeHandler}
                />

            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel}>취소</Button>
                <Button onClick={handleRegister}>등록</Button>
            </DialogActions>
        </Dialog>
    )
}

export default JoinComponent;