import {createSlice} from "@reduxjs/toolkit"
import moment from "moment/moment";

export const popupSlice = createSlice({
    name: 'popup',
    initialState: {
        isLoading: false,
        error: null,
        popup: [],
        page: 1,
        pageSize: 100,
        totalCount: 0,
        order: 0,
        startDate: moment().format('YYYY-MM-DD'),
        endDate: moment().add(1, "day").format('YYYY-MM-DD'),
        query: "",
    },
    reducers: {
        getPopup(state, action) {
            state.isLoading = true;
            state.page = action.payload.pageSize === 999? 1: action.payload.page;
            state.pageSize = action.payload.pageSize;
            state.order = action.payload.order
            state.query = action.payload.query
            state.startDate = action.payload.startDate;
            state.endDate = action.payload.endDate;
        },
        setPopup(state, action) {
            if((state.page -1) * state.pageSize > action.payload.totalCount) {
                state.page = 1
            }
            state.isLoading = false;
            state.totalCount = action.payload.totalCount;
            state.popup = action.payload.result;
        },
        failedGetPopup(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        }
    }
});

export const { getPopup, setPopup, failedGetPopup } = popupSlice.actions;

export default popupSlice.reducer;