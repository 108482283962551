import { createSlice } from "@reduxjs/toolkit";

export const marketSlice = createSlice({
    name: 'market',
    initialState: {
        isLoading: false,
        markets: [],
        error: null,
        isConnect: false,
        tasks: [],
        is_active: 0,
        page: 1,
        pageSize: 100,
        totalCount: 0,
        query: "",
        checkedInputs: [],
        updateData: [],
    },
    reducers: {
        getMarket(state, action) {
            state.isLoading = true;
            state.is_active = action.payload.is_active;
            state.page = action.payload.pageSize === 999? 1: action.payload.page;
            state.pageSize = action.payload.pageSize;
            state.query = action.payload.query
        },
        setMarket(state, action) {
            if((state.page -1) * state.pageSize > action.payload.totalCount) {
                state.page = 1
            }

            state.isLoading = false;
            state.markets = action.payload.result
            state.totalCount = action.payload.totalCount;
            const checks = [];
            for(const r of action.payload.result) {
                if(r.is_active) {
                    checks.push(r.id);
                }
            }
            state.checkedInputs = checks;
        },
        setCheckedInputs(state, action) {
            state.checkedInputs = action.payload;
        },
        setUpdateData(state, action) {
            state.updateData = action.payload;
        },
        failedGetMarket(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        waitMarketTask(state) {
            state.isConnect = true
        },
        pushMarketTask(state, action) {
            state.tasks.push(action.payload)
        },
        changeMarket(state, action) {
            //변경된 인자값의 인덱스넘버를 찾아야된다.
            const changedItem = action.payload
            const beforeArray = state.markets.filter((item) => item.id === changedItem.id)
            //찾은후 변경사항업데이트
            const index = state.markets.indexOf(beforeArray[0])
            state.markets[index] = changedItem
        }
    },
});

export const { getMarket, setMarket, setCheckedInputs, setUpdateData, failedGetMarket, waitMarketTask, pushMarketTask, changeMarket } = marketSlice.actions;

export default marketSlice.reducer;