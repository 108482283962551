import {createSlice} from "@reduxjs/toolkit"


export const statsSlice = createSlice({
    name: 'SideStatusReducer',
    initialState: {
        isLoading: false,
        error: null,
        todayDeposit: 0,
        todayWithdraw: 0,
        stayMoney: 0,
        totalBetUserCount: 0,
        sportsMoney: 0,
        sportsProcMoney: 0,
        sportsWinMoney: 0,
        sportsBetUserCount: 0,
        sportsProcUserCount: 0,
        casinoMoney: 0,
        casinoProcMoney: 0,
        casinoWinMoney: 0,
        casinoBetUserCount: 0,
        casinoProcUserCount: 0,
        minigameMoney: 0,
        minigameProcMoney: 0,
        minigameWinMoney: 0,
        minigameBetUserCount: 0,
        minigameProcUserCount: 0,
    },
    reducers: {
        getSideStatusReducer(state) {
            state.isLoading = true;
        },
        setSideStatusReducer(state, action) {
            state.isLoading = false;
            state.todayDeposit = action.payload.todayDeposit; //충전
            state.todayWithdraw = action.payload.todayWithdraw; //환전
            state.stayMoney = action.payload.stayMoney; //보유금액
            state.stayPoint = action.payload.stayPoint; //보유포인트
            state.totalBetUserCount = action.payload.totalBetUserCount;//총베팅인원
            state.sportsMoney = action.payload.sportsMoney;//스포츠베팅금액
            state.sportsProcMoney = action.payload.sportsProcMoney;//스포츠진행베팅금
            state.sportsWinMoney = action.payload.sportsWinMoney;//스포츠예상적중금
            state.sportsBetUserCount = action.payload.sportsBetUserCount; //스포츠베팅인원
            state.sportsProcUserCount = action.payload.sportsProcUserCount;//스포츠진행인원
            state.casinoMoney = action.payload.casinoMoney;//카지노베팅금액
            state.casinoProcMoney = action.payload.casinoProcMoney;//카지노진행베팅금
            state.casinoWinMoney = action.payload.casinoWinMoney;//카지노예상적중금
            state.casinoBetUserCount = action.payload.casinoBetUserCount;//카지노베팅인원
            state.casinoProcUserCount = action.payload.casinoProcUserCount;//카지노진행인원
            state.minigameMoney = action.payload.minigameMoney;//미니게임베팅금액
            state.minigameProcMoney = action.payload.minigameProcMoney; //미니게임진행베팅금
            state.minigameWinMoney = action.payload.minigameWinMoney; //미니게임예상적중금
            state.minigameBetUserCount = action.payload.minigameBetUserCount; //미니게임베팅인원
            state.minigameProcUserCount = action.payload.minigameProcUserCount;//미니게임진행인원
        },
        failedSideStatusReducer(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
    }
});

export const {
    getSideStatusReducer,
    setSideStatusReducer,
    failedSideStatusReducer,
} = statsSlice.actions;

export default statsSlice.reducer;