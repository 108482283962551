import {useDispatch, useSelector} from "react-redux"
import React, {useEffect, useState} from "react"

import Pagination from "../components/common/Pagination";
import StopMatchByList from "../components/match/StopMatchByList";
import {getStopMatchBy, waitStopMatchByTask} from "../redux/reducers/match/stopMatchBy";

const StopMatchByListContainer = () => {
    // useDispatch: 컴포넌트 내부에서 스토어의 내장 함수 dispatch를 사용할 수 있게 해주는 hook 이다.
    // useSelector: connect 함수를 대신하여 스토어 상태를 조회
    const dispatch = useDispatch()
    const matches = useSelector((state) => state.stopMatchesBy)
    const [searchQuery, setSearchQuery] = useState("");

    // 브라우저 상에 컴포넌트가 나타날 때 상품 목록을 조회하는 함수를 실행
    useEffect(() => {
        dispatch(getStopMatchBy({
            page: matches.page,
            pageSize: matches.pageSize,
            sport: matches.sport,
            market: matches.market,
            provider: matches.provider,
            league: matches.league,
            query: searchQuery,
        }))
        dispatch(waitStopMatchByTask())
    }, [dispatch])

    const handlePageChange = (page) => {
        dispatch(getStopMatchBy({
            page: page,
            pageSize: matches.pageSize,
            sport: matches.sport,
            market: matches.market,
            provider: matches.provider,
            league: matches.league,
            query: searchQuery,
        }))
    }

    const changeQuery = (value) => {
        setSearchQuery(value)
    }

    const inputPress = (e) => {
        if(e.key === "Enter") {
            onSearch(e.target.value)
        }
    }
    const onSearch = (query) => {
        setSearchQuery(query)
        dispatch(getStopMatchBy({
            page: matches.page,
            pageSize: matches.pageSize,
            sport: matches.sport,
            market: matches.market,
            provider: matches.provider,
            league: matches.league,
            query: query,
        }))
    }
    const onClickSearch = (e) => {

        dispatch(getStopMatchBy({
            page: matches.page,
            pageSize: matches.pageSize,
            sport: matches.sport,
            market: matches.market,
            provider: matches.provider,
            league: matches.league,
            query: searchQuery,
        }))
    }

    const startIndex = ((matches.page - 1) * matches.pageSize) + 1;
    return <>
        <p>{ startIndex + "~" + (startIndex + matches.pageSize - 1) + " / " + matches.totalCount}</p>
        <div style={{
            display: "flex"
        }}>
            <select
                className="form-control"
                defaultValue={100}
                value={matches.pageSize === "999"? "모두" : matches.pageSize}
                onChange={e =>
                    dispatch(getStopMatchBy({
                        page: matches.page,
                        pageSize: parseInt(e.target.value),
                        sport: matches.sport,
                        market: matches.market,
                        provider: matches.provider,
                        league: matches.league,
                        query: searchQuery,
                    }))
                }
            >
                <option value={50}>50</option>
                <option value={100}>100</option>
                <option value={200}>200</option>
                <option value={999}>모두</option>
            </select>
            <select
                className="form-control"
                defaultValue={0}
                value={matches.provider === "0"? "업체" : matches.provider}
                onChange={e =>
                    dispatch(getStopMatchBy({
                        page: matches.page,
                        pageSize: matches.pageSize,
                        sport: matches.sport,
                        market: matches.market,
                        provider: parseInt(e.target.value),
                        league: matches.league,
                        query: searchQuery,
                    }))
                }
            >
                <option value={0}>업체</option>
                {matches.providers.map(m => (
                    <option value={m.id}>{m.name}</option>
                ))}
            </select>
            <select
                className="form-control"
                defaultValue={0}
                value={matches.sport === "0"? "종목" : matches.sport}
                onChange={e =>
                    dispatch(getStopMatchBy({
                        page: matches.page,
                        pageSize: matches.pageSize,
                        sport: parseInt(e.target.value),
                        market: matches.market,
                        provider: matches.provider,
                        league: matches.league,
                        query: searchQuery,
                    }))
                }
            >
                <option value={0}>종목</option>
                {matches.sports.map(m => (
                    <option value={m.id}>{m.name}</option>
                ))}
            </select>
            <select
                className="form-control"
                defaultValue={0}
                value={matches.market === "0"? "종류" : matches.market}
                onChange={e =>
                    dispatch(getStopMatchBy({
                        page: matches.page,
                        pageSize: matches.pageSize,
                        sport: matches.sport,
                        market: parseInt(e.target.value),
                        provider: matches.provider,
                        league: matches.league,
                        query: searchQuery,
                    }))
                }
            >
                <option value={0}>종류</option>
                {matches.markets.map(m => (
                    <option value={m.id}>{m.name}</option>
                ))}
            </select>
            <select
                className="form-control"
                defaultValue={0}
                value={matches.league === "0"? "리그" : matches.league}
                onChange={e =>
                    dispatch(getStopMatchBy({
                        page: matches.page,
                        pageSize: matches.pageSize,
                        sport: matches.sport,
                        market: matches.market,
                        provider: matches.provider,
                        league: parseInt(e.target.value),
                        query: searchQuery,
                    }))
                }
            >
                <option value={0}>리그</option>
                {matches.leagues.map(m => (
                    <option value={m.id}>{m.name}</option>
                ))}
            </select>

            <div style={{
                display: "flex",
                width: "300px"
            }}>
                <input style={{
                    width: "220px"
                }} type="text" className="form-control" id="search_edit" placeholder="리그,팀명,ID를 입력하세요." onBlur={(e) => {
                    changeQuery(e.target.value)
                }} onKeyDown={inputPress} />
                <button style={{
                    width: "80px"
                }} type="submit" className="btn btn-primary" onClick={onClickSearch}>검색</button>
            </div>
        </div>
        <div>
            {matches.leagues.map(m => (
                <button type="button" className="btn btn-default" onClick={ e =>
                    dispatch(getStopMatchBy({
                        page: matches.page,
                        pageSize: matches.pageSize,
                        sport: matches.sport,
                        market: matches.market,
                        provider: matches.provider,
                        league: parseInt(m.id),
                        query: searchQuery,
                    }))
                }>
                    {m.name}
                </button>
            ))}
        </div>
        <StopMatchByList matches={matches} />
        <Pagination totalCount={matches.totalCount} pageSize={matches.pageSize} currentPage={matches.page} onPageChange={handlePageChange}/>
    </>





}
export default StopMatchByListContainer