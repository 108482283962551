import {call, put, take,} from "redux-saga/effects";
import {fetchSettingMarket} from "../../../lib/api";
import {closeChannel, createSocketChannel} from "../../channel/createSocketChannel";
import socket from "../../../config/socket";
import {
    changeSettingMarket,
    failedGetSettingMarket,
    pushSettingMarketTask,
    setSettingMarket
} from "../../reducers/match/settingMarket";

export function* getSettingMarketSaga(action) {
    try {
        const res = yield call(fetchSettingMarket, action.payload.is_active, action.payload.page, action.payload.pageSize, action.payload.sport, action.payload.query);
        yield put(setSettingMarket(res.data));
    } catch(error) {
        console.log(error)
        yield put(failedGetSettingMarket(error));
    }
}

export function* waitSettingMarketTaskSaga() {
    let channel;
    try {
        channel = yield call(createSocketChannel, "settingMarket");
        while(true) {
            const task = yield take(channel);
            yield onTaskSaga(task)
        }
    } catch (e) {
        console.log(e, "error");
    } finally {
        socket.close();
        closeChannel(channel)
    }
}

function* onTaskSaga(task) {
    yield put(pushSettingMarketTask(task));
    yield put(changeSettingMarket(task))
}

export function* changeSettingMarketSaga() {
    //yield put(changeSport(changedItem))
}
