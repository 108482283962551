import {call, put} from "redux-saga/effects";
import {setSportsApiSettingInfoListReducer, failedGetSportsApiSettingInfoListReducer}
        from "../../reducers/match/sportsApiSettingReducer";
import {fetchSportsApiSettingInfoList, fetchNationApiSettingInfoList,fetchLeagueApiSettingInfoList,fetchTeamApiSettingInfoList} from "../../../lib/api";

//스포츠 API설정리스트
export function* getSportsApiSettingInfoListSaga(action) {
    try{
        console.log("##getSportsApiSettingInfoListSaga#",action.payload.sportsPage,action.payload.sportsPageSize)
        const res = yield call(fetchSportsApiSettingInfoList,action.payload.sportsPage, action.payload.sportsPageSize);
        yield put(setSportsApiSettingInfoListReducer(res.data))
    }
    catch (error) {
        console.log(error)
        yield put(failedGetSportsApiSettingInfoListReducer(error))
    }
}
