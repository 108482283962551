import React from 'react';

const HackList = () => {
    return (
        <div>
            <div style={{
                paddingLeft: "10px",
                paddingTop: "10px",
            }}>
                <p>
                    회원관리 > 해킹내역
                </p>
            </div>
            <div className="card" style={{
                margin: "10px"
            }}>
                <div className="card-body">
                    <div>
                        <div className="container-fluid">
                            <div className="row bg-light align-items-center" >
                                <div className="col-6">
                                    배팅취소해킹내역
                                </div>

                                <div className="col align-items-center justify-content-end" style={{
                                    display:"flex",
                                    height: "50px"
                                }}>

                                    <select className="form-select"  style={{
                                        width: "100px",
                                        height: "40px",
                                    }}>
                                        <option selected>회원ID</option>
                                        <option value="1">회원ID</option>
                                        <option value="2">IP</option>
                                    </select>
                                    <div className="input-group align-items-end" style={{
                                        width: "250px",
                                        height: "40px",
                                    }}>
                                        <input type="text" className="form-control" placeholder=""
                                                aria-describedby="button-addon2"/>
                                        <button className="btn btn-outline-secondary" type="button"
                                                id="button-addon2">검색
                                        </button>
                                    </div>
                                </div>

                            </div>
                            <div className="my-2" style={{
                                display: "flex"
                            }}>
                                <span>처음 ( 46 )</span>
                            </div>

                        </div>
                        <div>
                            <table className="table table-bordered table-sm text-center align-middle hacklistt1">
                                <thead className="table-dark">
                                <tr>
                                    <td>아이디</td>
                                    <td>IP</td>
                                    <td>내용</td>
                                    <td>금액</td>
                                    <td>시간</td>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>test</td>
                                    <td>127.0.0.1</td>
                                    <td>글자수 제한이 있는지 확인용</td>
                                    <td>100,000</td>
                                    <td>2022-07-17 05:51:55</td>
                                </tr>
                                <tr>
                                    <td>test</td>
                                    <td>127.0.0.1</td>
                                    <td></td>
                                    <td>100,000</td>
                                    <td>2022-07-17 05:51:55</td>
                                </tr>
                                </tbody>

                            </table>

                        </div>

                    </div>
                </div>
            </div>


        </div>

    )
}

export default HackList;