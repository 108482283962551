import {useDispatch, useSelector} from "react-redux"
import React, {useEffect, useState} from "react"
import {getStats} from "../redux/reducers/stat";
import Stat from "../components/Stat";
import moment from "moment/moment";

const StatContainer = () => {
    // useDispatch: 컴포넌트 내부에서 스토어의 내장 함수 dispatch를 사용할 수 있게 해주는 hook 이다.
    // useSelector: connect 함수를 대신하여 스토어 상태를 조회
    const dispatch = useDispatch()
    const initialState = useSelector((state) => state.stat)
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    // 브라우저 상에 컴포넌트가 나타날 때 상품 목록을 조회하는 함수를 실행
    useEffect(() => {
        dispatch(getStats({
            userState: initialState.userState,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
        }))
    }, [dispatch])


    const onClickSearch = (e) => {
        dispatch(getStats({
            userState: initialState.userState,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
        }))
    }

    const onRefresh = () => {
        dispatch(getStats({
            userState: initialState.userState,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
        }))
    }

    return <>
        <div className="container-fluid p-0 mx-1" style={{
            display: "flex"
        }}>
            <input style={{
                width: "220px"
            }} className="form-control" type="date" id="start_date" value={startDate? startDate : initialState.startDate} onChange={ event => {
                setStartDate(event.target.value)
            }}/>
            <big className="mx-2">~</big>
            <input style={{
                width: "220px"
            }} className="form-control" type="date" id="end_date" value={endDate? endDate : initialState.endDate} onChange={ event => {
                setEndDate(event.target.value)
            }}/>

            <button style={{
                width: "80px"
            }} className="btn btn-dark me-1" onClick={() => {
                const sDate = moment().format('YYYY-MM-DD')
                const eDate = moment().add(1, "day").format('YYYY-MM-DD')
                setStartDate(sDate)
                setEndDate(eDate)
                dispatch(getStats({
                    userState: initialState.userState,
                    startDate: sDate,
                    endDate: eDate,
                }))
            }} >오늘</button>
            <button style={{
                width: "80px"
            }} className="btn btn-dark me-1" onClick={() => {
                const sDate = moment().add(-1, "day").format('YYYY-MM-DD')
                const eDate = moment().format('YYYY-MM-DD')
                setStartDate(sDate)
                setEndDate(eDate)
                dispatch(getStats({
                    userState: initialState.userState,
                    startDate: sDate,
                    endDate: eDate,
                }))
            }} >어제</button>
            <button style={{
                width: "80px"
            }} className="btn btn-dark me-1" onClick={() => {
                const sDate = moment().add(-6, "day").format('YYYY-MM-DD')
                const eDate = moment().add(1, "day").format('YYYY-MM-DD')
                setStartDate(sDate)
                setEndDate(eDate)
                dispatch(getStats({
                    userState: initialState.userState,
                    startDate: sDate,
                    endDate: eDate,
                }))
            }} >1주일</button>
            <button style={{
                width: "80px"
            }} className="btn btn-dark me-1" onClick={() => {
                const sDate = moment().startOf("month").format('YYYY-MM-DD')
                const eDate = moment().add(1, "day").format('YYYY-MM-DD')
                setStartDate(sDate)
                setEndDate(eDate)
                dispatch(getStats({
                    userState: initialState.userState,
                    startDate: sDate,
                    endDate: eDate,
                }))
            }} >이번달</button>

            <select
                className="form-select me-1"
                defaultValue={0}
                style={{
                    width: "180px"
                }}

                onChange={(e) => {
                    dispatch(getStats({
                        userState: parseInt(e.target.value),
                        startDate: startDate? startDate : initialState.startDate,
                        endDate: endDate? endDate : initialState.endDate,
                    }))
                }}
            >
                <option value={10}>정상</option>
                <option value={1}>보류</option>
                <option value={9}>요주의</option>
                <option value={2}>신규</option>
                <option value={3}>악성</option>
                <option value={4}>미이용</option>
                <option value={5}>휴면</option>
                <option value={6}>탈퇴</option>
                <option value={7}>졸업</option>
                <option value={8}>블랙</option>
                <option value={0}>모든회원</option>
            </select>
            <button style={{
                width: "80px"
            }} className="btn btn-primary me-1" onClick={onClickSearch}>검색</button>
            <button style={{
                width: "80px"
            }} className="btn btn-primary me-1" onClick={onClickSearch}>엑셀</button>
        </div>
        <Stat stat={initialState.stat} onRefresh={onRefresh} />
    </>





}
export default StatContainer