import React from 'react';
import TotalBettingListContainer from "../../containers/betting/TotalBettingListContainer";

const TotalBetting = () => {

    return (
        <div>
            <h1>전체배팅</h1>
            <TotalBettingListContainer />
        </div>
    )
}

export default TotalBetting;