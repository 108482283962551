import {call, put, take,} from "redux-saga/effects";
import {fetchActiveBetting} from "../../../lib/api";
import {failedGetActiveBetting, setActiveBetting} from "../../reducers/betting/activeBetting";

export function* getActiveBettingSaga(action) {
    try{
        const res = yield call(fetchActiveBetting,
            action.payload.page, action.payload.pageSize, action.payload.query,
            action.payload.order,
        )
        yield put(setActiveBetting(res.data))
    }
    catch (error) {
        console.log(error)
        yield put(failedGetActiveBetting(error))
    }
}