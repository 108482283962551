import {call, put, take,} from "redux-saga/effects";
import {closeChannel, createSocketChannel} from "../../channel/createSocketChannel";
import socket from "../../../config/socket";
import {fetchStopMatch, fetchStopMatchBy} from "../../../lib/api";
import {changeStopMatchBy, failedGetStopMatchBy, pushStopMatchByTask, setStopMatchBy} from "../../reducers/match/stopMatchBy";

export function* getStopMatchBySaga(action) {
    try {
        const res = yield call(fetchStopMatchBy, action.payload.page, action.payload.pageSize, action.payload.sport, action.payload.market, action.payload.provider, action.payload.league, action.payload.query);
        yield put(setStopMatchBy(res.data));
    } catch(error) {
        console.log(error)
        yield put(failedGetStopMatchBy(error));
    }
}

export function* waitStopMatchByTaskSaga() {
    let channel;
    try {
        channel = yield call(createSocketChannel, "stopMatchBy");
        while(true) {
            const task = yield take(channel);
            yield onTaskSaga(task)
        }
    } catch (e) {
        console.log(e, "error");
    } finally {
        socket.close();
        closeChannel(channel)
    }
}

function* onTaskSaga(task) {
    yield put(pushStopMatchByTask(task));
    yield put(changeStopMatchBy(task));
}

export function* changeStopMatchBySaga() {
    //yield put(changeSport(changedItem))
}

