import React from "react";
import moment from "moment";
import {setTotalSelectedPoint, setSelectedNos} from "../../redux/reducers/money/PointLogMngReducer";
import {useDispatch} from "react-redux";
import {NumericFormat} from "react-number-format";

const PointLogMngListComponent = ({initialState}) => {

    const dispatch = useDispatch()
    const allCheckHandler = (e) => {
        if(initialState.pointLogMngList.length > 0 && e.currentTarget.value) {
            const checks = [];
            let total = 0;
            for(let i = 0; i < initialState.pointLogMngList.length; i++) {
                checks.push(initialState.pointLogMngList[i].no)
                total += initialState.pointLogMngList[i].Point
            }
            dispatch(setSelectedNos(e.target.checked? checks : []));
            dispatch(setTotalSelectedPoint(e.target.checked? total : 0));
        }
    }
    const checkHandler = (checked, no) => {
        let selectedNos2 = []
        if(checked) {
            selectedNos2 = [...initialState.selectedNos, no]
            dispatch(setSelectedNos([...initialState.selectedNos, no]))
        }
        else {
            selectedNos2 = initialState.selectedNos.filter((id) => id !== no)
            dispatch(setSelectedNos(initialState.selectedNos.filter((id) => id !== no)))
        }
        let total = 0;
        for(let i = 0; i < selectedNos2.length; i++) {
            total += initialState.pointLogMngList.filter((id) => id.no === selectedNos2[i])[0].Point
        }
        dispatch(setTotalSelectedPoint(total));
    }
    return (
        <div>
            <table className="table table-bordered table-sm text-center align-middle">
                <thead className="table-dark">
                <tr>
                    <th>
                        <input className="form-check-input" type="checkbox" checked={initialState.pointLogListCount === initialState.selectedNos.length} onChange={allCheckHandler}
                               id="flexCheckDefault"/>
                    </th>
                    <th>회원ID(닉네임)</th>
                    <th>증가</th>
                    <th>감소</th>
                    <th style={{width: "50%"}}>설명</th>
                    <th>잔여포인트</th>
                    <th style={{width: "7%"}}>시간</th>
                    <th>관리자</th>
                </tr>
                </thead>
                {initialState.totalCount > 0 ?
                    initialState.pointLogMngList && initialState.pointLogMngList.map((point, index) => (
                        <tbody key={point.no}>
                    <tr>
                        <td>
                            <input className="form-check-input" type="checkbox" checked={initialState.selectedNos.filter((no) => no === point.no).length > 0} onChange={(e) => {
                                checkHandler(e.currentTarget.checked, point.no)
                            }}/>
                        </td>
                        <td>{point.user.id}({point.user.nickname})</td>
                        <td><NumericFormat value={point.increase_point} displayType={'text'} thousandSeparator={true} /></td>
                        <td><NumericFormat value={point.decrease_point} displayType={'text'} thousandSeparator={true} /></td>
                        <td>{point.cont}</td>
                        <td><NumericFormat value={point.point} displayType={'text'} thousandSeparator={true} /></td>
                        <td>{moment(point.createdAt).format("YYYY-MM-DD HH:mm:ss")}</td>
                        <td>
                            {point.admin ?
                                point.admin.id : "환전신청"}
                        </td>
                    </tr>
                    </tbody>
                    ))
                    :
                    <tbody>
                    <tr className="table-secondary">
                        <td colSpan= "8"><font size= '3'> 조회된 데이터가 없습니다.</font></td>
                    </tr>
                    </tbody>
                }
            </table>
        </div>
    );
}

export default PointLogMngListComponent;