import {call, put, take,} from "redux-saga/effects";
import {fetchDeposit} from "../../../lib/api";
import {failedGetDeposit, setDeposit} from "../../reducers/money/deposit";

export function* getDepositSaga(action) {
    try{
        const res = yield call(
            fetchDeposit,
            action.payload.page, action.payload.pageSize, action.payload.query,
            action.payload.order, action.payload.startDate, action.payload.endDate
        )
        yield put(setDeposit(res.data))
    }
    catch (error) {
        console.log(error)
        yield put(failedGetDeposit(error))
    }
}