import React, {useState, useEffect} from "react";
import axios from "axios";
import {useSelector} from "react-redux";
import {getDailyFeeReducer} from "../redux/reducers/agent/DailyFeeReducer";

const PartnerAddComponent = (props) => {

    const {open, onClose} = props
    const [partnerList, setPartnerList] = useState([]);
    const userInfo = useSelector((state) => state.userInfo);

    const [id, setId] = useState("");
    const [pw, setPw] = useState("");
    const [name, setName] = useState("");
    const [telecom, setTelecom] = useState("");
    const [phone, setPhone] = useState("");
    const [bank, setBank] = useState("");
    const [bankPw, setBankPw] = useState("");
    const [account, setAccount] = useState("");
    const [storeName, setStoreName] = useState("");
    const [storeCode, setStoreCode] = useState("");
    const [partnerType, setPartnerType] = useState("");
    const [structure, setStructure] = useState(0);

    const [DWRate, setDWRate] = useState(0);
    const [rollingRate, setRollingRate] = useState(0);
    const [sportRate, setSportRate] = useState(0);
    const [liveRate, setLiveRate] = useState(0);
    const [miniGameRate, setMiniGameRate] = useState(0);
    const [casinoRate, setCasinoRate] = useState(0);
    const [sportRollingRate, setSportRollingRate] = useState(0);
    const [liveRollingRate, setLiveRollingRate] = useState(0);
    const [miniGameRollingRate, setMiniGameRollingRate] = useState(0);
    const [casinoRollingRate, setCasinoRollingRate] = useState(0);
    const [crossRate, setCrossRate] = useState(0);
    const [crossRollingRate, setCrossRollingRate] = useState(0);
    const [specialRate, setSpecialRate] = useState(0);
    const [specialRollingRate, setSpecialRollingRate] = useState(0);
    const [twoFolderRate, setTwoFolderRate] = useState(0);
    const [twoFolderRollingRate, setTwoFolderRollingRate] = useState(0);
    const [threeFolderRate, setThreeFolderRate] = useState(0);
    const [threeFolderRollingRate, setThreeFolderRollingRate] = useState(0);

    const [idCheck, setIdCheck] = useState(false);
    const [accountCheck, setAccountCheck] = useState(false);
    const [bankList, setBankList] = useState([]);
    const [telecomList, setTelecomList] = useState([]);


    const getCmmnCd = () => {
        if(bankList.length === 0){
            axios.post('/admin/getCmmnCd', {
                tableId: 'partners',
                columnNm: 'bank',
                useYn: 'Y',
                //inCd: ['국민','기업','우리'],
                //notInCd: ['국민','기업']
            }).then((res) => {
                setBankList(res.data.cmmnCd);
            })
        }
        if(telecomList.length === 0){
            axios.post('/admin/getCmmnCd', {
                tableId: 'partners',
                columnNm: 'telecom',
                useYn: 'Y',
                //inCd: ['SKT','KT','LG'],
                //notInCd: ['KT','LG']
            }).then((res) => {
                setTelecomList(res.data.cmmnCd);
            })
        }
    }
    useEffect(getCmmnCd, [])

    const handleCancel = () => {
        onClose()
    }
    const idHandler = (e) => {
        const currentId = e.currentTarget.value
        setId(currentId);
        const notKorean = /[ㄱ-ㅎ가-힣]|[ \[\]{}()<>?|`~!@#$%^&*_+=,.;:\"'\\]/g;
        if (notKorean.test(currentId)) {
            alert("영어,숫자조합만 가능합니다")
            setId("")
        }
        setIdCheck(false);
    }
    const pwHandler = (e) => {
        const currentPw = e.currentTarget.value
        setPw(currentPw);
        const notKorean = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]|[ \[\]{}()<>?|`~!@#$%^&*_+=,.;:\"'/\\]/g;
        if (notKorean.test(currentPw)) {
            alert("영어,숫자조합만 가능합니다")
            setPw("")
        }
    }
    const nameHandler = (e) => {
        const currenName = e.currentTarget.value
        setName(currenName);
        const onlyKorean = /[a-z0-9]|[ \[\]{}()<>?|`~!@#$%^&*-_+=,.;:\"'/\\]/g;
        if (onlyKorean.test(currenName)) {
            alert("한글만 입력바랍니다")
            setName("")
        }
    }
    const telecomHandler = (e) => {
        setTelecom(e.currentTarget.value);
    }

    const phoneHandler = (e) => {
        setPhone(e.currentTarget.value);
    }
    const bankHandler = (e) => {
        setBank(e.currentTarget.value);
    }

    const bankPwHandler = (e) => {
        setBankPw(e.currentTarget.value);
    }
    const accountHandler = (e) => {
        setAccount(e.currentTarget.value);
    }
    const storeNameHandler = (e) => {
        setStoreName(e.currentTarget.value);
    }
    const storeCodeHandler = (e) => {
        setStoreCode(e.currentTarget.value);
    }
    const partnerTypeHandler = (e) => {
        setPartnerType(e.currentTarget.value);
        //console.log(e.currentTarget.value)
    }
    const partnerTypeSelectList = [
        {value : 0, name : "본사"},
        {value : 1, name : "부본사"},
        {value : 2, name : "총판"},
    ]
    const structureHandler = (e) => {
        setStructure(e.currentTarget.value)
        //console.log(e.currentTarget.value)
    }

    const DWRateHandler = (e) => {
        setDWRate(e.currentTarget.value);
    }
    const rollingRateHandler = (e) => {
        setRollingRate(e.currentTarget.value);
    }
    const sportRateHandler = (e) => {
        setSportRate(e.currentTarget.value);
    }
    const liveRateHandler = (e) => {
        setLiveRate(e.currentTarget.value);
    }
    const miniGameRateHandler = (e) => {
        setMiniGameRate(e.currentTarget.value);
    }
    const casinoRateHandler = (e) => {
        setCasinoRate(e.currentTarget.value);
    }
    const sportRollingRateHandler = (e) => {
        setSportRollingRate(e.currentTarget.value);
    }
    const liveRollingRateHandler = (e) => {
        setLiveRollingRate(e.currentTarget.value);
    }
    const miniGameRollingRateHandler = (e) => {
        setMiniGameRollingRate(e.currentTarget.value);
    }
    const casinoRollingRateHandler = (e) => {
        setCasinoRollingRate(e.currentTarget.value);
    }
    const crossRateHandler = (e) => {
        setCrossRate(e.currentTarget.value);
    }
    const crossRollingRateHandler = (e) => {
        setCrossRollingRate(e.currentTarget.value);
    }
    const specialRateHandler = (e) => {
        setSpecialRate(e.currentTarget.value);
    }
    const specialRollingRateHandler = (e) => {
        setSpecialRollingRate(e.currentTarget.value);
    }
    const twoFolderRateHandler = (e) => {
        setTwoFolderRate(e.currentTarget.value);
    }
    const twoFolderRollingRateHandler = (e) => {
        setTwoFolderRollingRate(e.currentTarget.value);
    }
    const threeFolderRateHandler = (e) => {
        setThreeFolderRate(e.currentTarget.value);
    }
    const threeFolderRollingRateHandler = (e) => {
        setThreeFolderRollingRate(e.currentTarget.value);
    }



    const onIdCheckHandler = (e) => {
        axios.post('/admin/partner/check_id', {
            id: id
        }).then((res) => {
            if(res.data.isPossible) {
                alert("중복되는 아이디입니다.")
                setIdCheck(false);
            } else {
                alert("이용가능한 아이디입니다.")
                setIdCheck(true);
            }
        })
    }
    const onAccountCheckHandler = (e) => {
        axios.post('/admin/partner/check_account', {
            account: account
        }).then((res) => {
            if(res.data.isPossible) {
                alert("중복되는 계좌입니다.")
                setAccountCheck(false);
            } else {
                alert("이용가능한 계좌입니다.")
                setAccountCheck(true);
            }
        })
    }


    const handleRegister = () => {
        if(id === "") {
            alert("아이디를 입력해주세요")
        } else if (pw ==="") {
            alert("비밀번호를 입력해주세요")
        } else if (name ==="") {
            alert("예금주를 입력해주세요")
        } else if (telecom === "") {
            alert("통신사를 선택해주세요")
        } else if (phone === "") {
            alert("전화번호를 입력해주세요")
        } else if (bank === "") {
            alert("은행을 선택해주세요")
        } else if (bankPw ==="") {
            alert("환전비번을 입력해주세요")
        } else if (account ==="") {
            alert("환전계좌를 입력해주세요")
        } else if (DWRate ==="") {
            alert("입출금요율을 입력해주세요")
        } else if (rollingRate ==="") {
            alert("롤링요율을 입력해주세요")
        } else if (storeName ==="") {
            alert("매장명을 입력해주세요")
        } else if (storeCode ==="") {
            alert("매장코드를 입력해주세요")
        } else {
            axios.post('/admin/partner/insert', {
                id: id,
                pw: pw,
                name: name,
                telecom: telecom,
                phone: phone,
                bank: bank,
                bank_pw: bankPw,
                withdraw_account: account,
                store_name: storeName,
                store_code: storeCode,
                user_type: 1,// 0본사 1파트너
                substructure: structure,

                DW_rate: DWRate,
                rolling_rate: rollingRate,
                sport_rate: sportRate,
                live_rate: liveRate,
                miniGame_rate: miniGameRate,
                casino_rate: casinoRate,
                sport_rolling_rate: sportRollingRate,
                live_rolling_rate: liveRollingRate,
                miniGame_rolling_rate: miniGameRollingRate,
                casino_rolling_rate: casinoRollingRate,
                cross_rate: crossRate,
                special_rate: specialRate,
                twoFolder_rate: twoFolderRate,
                threeFolder_rate: threeFolderRate,
                cross_rolling_rate: crossRollingRate,
                special_rolling_rate: specialRollingRate,
                twoFolder_rolling_rate: twoFolderRollingRate,
                threeFolder_rolling_rate: threeFolderRollingRate,

            }).then((res) => {
                if (res.data.isInserted) {
                    alert('가입완료')
                    handleCancel()
                } else {
                    alert('가입실패')
                }
            })
        }

    }
    return (
        <div className="partner_modal_background">
            <div className="partner_modal_box" >
                <div className="partner_modal_body">

                    <div className="m-2 title" >
                        총판등록
                    </div>
                    <div className="d-flex content">
                        <div className="w-50">
                            <div className="d-flex m-3 items">
                                <label className="my-1 my-auto" style={{width: "30%"}}>아이디</label>
                                <div className="d-flex w-100">
                                    <input type="text" className="form-control" maxLength="12" placeholder="아이디" value={id} onChange={idHandler} style={{width: "70%"}}/>
                                    <button type="button" className="btn btn-secondary ms-1" onClick={onIdCheckHandler} style={{width: "30%"}}>중복체크</button>
                                </div>
                            </div>
                            <div className="d-flex m-3 items">
                                <label className="my-1 my-auto" style={{width: "30%"}}>비밀번호</label>
                                <input type="text" className="form-control" maxLength="12" placeholder="비밀번호" value={pw} onChange={pwHandler}/>
                            </div>
                            <div className="d-flex m-3 items">
                                <label className="my-1 my-auto" style={{width: "30%"}}>예금주</label>
                                <input type="text" maxLength="12" placeholder="예금주" className="form-control" value={name} onChange={nameHandler}/>
                            </div>
                            <div className="d-flex m-3 items">
                                <label className="my-1 my-auto" style={{width: "28%"}}>전화번호</label>
                                <div className="w-100 d-flex">
                                    <select className="form-select ms-1" onChange={telecomHandler} value={telecom} style={{width: "40%"}}>
                                        <option value = "">통신사선택</option>
                                        {telecomList.map((item) => (
                                            <option key={item.cd} value={item.cd}>{item.cd_nm}</option>
                                        ))}
                                    </select>
                                    <input type="text" className="form-control" maxLength="13" placeholder="전화번호" value={phone} onChange={phoneHandler} style={{width: "60%"}}/>
                                </div>
                            </div>
                            <div className="d-flex m-3 items">
                                <label className="my-1 my-auto" style={{width: "30%"}}>은행</label>
                                <select className="form-select ms-1" onChange={bankHandler} value={bank}>
                                    <option value = "">은행선택</option>
                                    {bankList.map((item, index) => (
                                        <option key={item.cd} value={item.cd}>{item.cd_nm}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="d-flex m-3 items">
                                <label className="my-1 my-auto" style={{width: "30%"}}>환전계좌</label>
                                <div className="d-flex w-100">
                                    <input type="text" className="form-control" maxLength="20" placeholder="환전계좌" value={account} onChange={accountHandler} style={{width: "70%"}} />
                                    <button type="button" className="btn btn-secondary ms-2" onClick={onAccountCheckHandler} style={{width: "30%"}}>중복체크</button>
                                </div>
                            </div>
                            <div className="d-flex m-3 items">
                                <label className="my-1 my-auto"  style={{width: "30%"}}>환전비번</label>
                                <input type="text" className="form-control" maxLength="12" placeholder="환전비번" value={bankPw} onChange={bankPwHandler} />
                            </div>
                            <div className="d-flex m-3 items">
                                <label className="my-1 my-auto" style={{width: "30%"}}>매장명</label>
                                <input type="text" className="form-control" maxLength="12" placeholder="매장명" value={storeName} onChange={storeNameHandler} />
                            </div>
                            <div className="d-flex m-3 items">
                                <label className="my-1 my-auto" style={{width: "30%"}}>매장코드</label>
                                <input type="text" className="form-control"  maxLength="8" placeholder="매장코드" value={storeCode} onChange={storeCodeHandler} />
                            </div>


                        </div>
                        <div className="w-50">
                            <div className="d-flex m-3 items">
                                <label className="my-1 my-auto" style={{width: "30%"}}>입출금요율</label>
                                <input type="text" maxLength="4" className="form-control" value={DWRate} onChange={DWRateHandler}/>
                            </div>
                            <div className="d-flex m-3 items">
                                <label className="my-1 my-auto" style={{width: "30%"}}>롤링요율</label>
                                <input type="text" maxLength="4" className="form-control" value={rollingRate} onChange={rollingRateHandler} />
                            </div>
                            <div className="d-flex m-3 items">
                                <div className="d-flex w-50">
                                    <label className="my-1 my-auto" style={{width: "46%"}}>스포츠요율</label>
                                    <input type="text" className="form-control" maxLength="4" value={sportRate} onChange={sportRateHandler} style={{width:"54%"}}/>
                                </div>
                                <div className="d-flex w-50">
                                    <label className="my-1 ms-1 my-auto" style={{width: "46%"}}>스포츠롤링</label>
                                    <input type="text" className="form-control" maxLength="4" value={sportRollingRate} onChange={sportRollingRateHandler} style={{width:"54%"}}/>
                                </div>
                            </div>
                            <div className="d-flex m-3 items">
                                <div className="d-flex w-50">
                                    <label className="my-1 my-auto" style={{width: "46%"}}>라이브요율</label>
                                    <input type="text" className="form-control" maxLength="4" value={liveRate} onChange={liveRateHandler} style={{width:"54%"}}/>
                                </div>
                                <div className="d-flex w-50">
                                    <label className="my-1 ms-1 my-auto" style={{width: "46%"}}>라이브롤링</label>
                                    <input type="text" className="form-control" maxLength="4" value={liveRollingRate} onChange={liveRollingRateHandler} style={{width:"54%"}}/>
                                </div>
                            </div>
                            <div className="d-flex m-3 items">
                                <div className="d-flex w-50">
                                    <label className="my-1 my-auto" style={{width: "46%"}}>미니게임요율</label>
                                    <input type="text" className="form-control" maxLength="4" value={miniGameRate} onChange={miniGameRateHandler} style={{width:"54%"}}/>
                                </div>
                                <div className="d-flex w-50">
                                    <label className="my-1 ms-1 my-auto" style={{width: "46%"}}>미니게임롤링</label>
                                    <input type="text" className="form-control" maxLength="4" value={miniGameRollingRate} onChange={miniGameRollingRateHandler} style={{width:"54%"}}/>
                                </div>
                            </div>
                            <div className="d-flex m-3 items">
                                <div className="d-flex w-50">
                                    <label className="my-1 my-auto" style={{width: "46%"}}>카지노요율</label>
                                    <input type="text" className="form-control" maxLength="4" value={casinoRate} onChange={casinoRateHandler} style={{width:"54%"}}/>
                                </div>
                                <div className="d-flex w-50">
                                    <label className="my-1 ms-1 my-auto" style={{width: "46%"}}>카지노롤링</label>
                                    <input type="text" className="form-control" maxLength="4" value={casinoRollingRate} onChange={casinoRollingRateHandler} style={{width:"54%"}}/>
                                </div>
                            </div>
                            <div className="d-flex m-3 items">
                                <div className="d-flex w-50">
                                    <label className="my-1 my-auto" style={{width: "46%"}}>크로스요율</label>
                                    <input type="text" className="form-control" maxLength="4" value={crossRate} onChange={crossRateHandler} style={{width:"54%"}}/>
                                </div>
                                <div className="d-flex w-50">
                                    <label className="my-1 ms-1 my-auto" style={{width: "46%"}}>크로스롤링</label>
                                    <input type="text" className="form-control" maxLength="4" value={crossRollingRate} onChange={crossRollingRateHandler} style={{width:"54%"}}/>
                                </div>
                            </div>
                            <div className="d-flex m-3 items">
                                <div className="d-flex w-50">
                                    <label className="my-1 my-auto" style={{width: "46%"}}>스페셜요율</label>
                                    <input type="text" className="form-control" maxLength="4" value={specialRate} onChange={specialRateHandler} style={{width:"54%"}}/>
                                </div>
                                <div className="d-flex w-50">
                                    <label className="my-1 ms-1 my-auto" style={{width: "46%"}}>스페셜롤링</label>
                                    <input type="text" className="form-control" maxLength="4" value={specialRollingRate} onChange={specialRollingRateHandler} style={{width:"54%"}}/>
                                </div>
                            </div>
                            <div className="d-flex m-3 items">
                                <div className="d-flex w-50">
                                    <label className="my-1 my-auto" style={{width: "46%"}}>두폴더요율</label>
                                    <input type="text" className="form-control" maxLength="4" value={twoFolderRate} onChange={twoFolderRateHandler} style={{width:"54%"}}/>
                                </div>
                                <div className="d-flex w-50">
                                    <label className="my-1 ms-1 my-auto" style={{width: "46%"}}>두폴더롤링</label>
                                    <input type="text" className="form-control" maxLength="4" value={twoFolderRollingRate} onChange={twoFolderRollingRateHandler} style={{width:"54%"}}/>
                                </div>
                            </div>
                            <div className="d-flex m-3 items">
                                <div className="d-flex w-50">
                                    <label className="my-1 my-auto" style={{width: "46%"}}>세폴더요율</label>
                                    <input type="text" className="form-control" maxLength="4" value={threeFolderRate} onChange={threeFolderRateHandler} style={{width:"54%"}}/>
                                </div>
                                <div className="d-flex w-50">
                                    <label className="my-1 ms-1 my-auto" style={{width: "46%"}}>세폴더롤링</label>
                                    <input type="text" className="form-control" maxLength="4" value={threeFolderRollingRate} onChange={threeFolderRollingRateHandler} style={{width:"54%"}}/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="register d-flex justify-content-end me-3">
                        <button className="btn btn-secondary mx-1" onClick={handleCancel}>
                            취소
                        </button>
                        <button className="btn btn-primary" onClick={handleRegister}>
                            등록
                        </button>
                    </div>
                </div>
            </div>
        </div>




    )
}
export default PartnerAddComponent;