import {createSlice} from "@reduxjs/toolkit"
import moment from "moment/moment";


export const statsSlice = createSlice({
    name: 'FailListReducer',
    initialState: {
        page: 1,
        pageSize:20,
        order:0,
        isLoading: false,
        error: null,
        loginFailCount: 0,
        loginFailList: [],
        startDate: moment().format('YYYY-MM-DD'),
        endDate: moment().add(1, "day").format('YYYY-MM-DD'),
        query: "",
        device: 10,
    },
    reducers: {
        getFailListReducer(state, action) {
            console.log("##getFailListReducer##", action.payload.page,action.payload.pageSize,action.payload.startDate,action.payload.endDate,action.payload.query,action.payload.device);
            state.isLoading = true;
            state.page = action.payload.page;
            state.pageSize = action.payload.pageSize;
            state.order = action.payload.order;
            state.startDate = action.payload.startDate;
            state.endDate = action.payload.endDate;
            state.query = action.payload.query;
            state.device = action.payload.device;

        },
        setFailListReducer(state, action) {
            console.log("#setFailListReducer#", action.payload);
            if((state.page -1) * state.pageSize > action.payload.loginFailCount) {
                state.page = 1
            }
            state.isLoading = false;
            state.loginFailCount = action.payload.loginFailCount;
            state.loginFailList = action.payload.loginFailList;
        },
        failedFailListReducer(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        setStartDate(state, action) {
            state.startDate = action.payload;
        },
        setEndDate(state, action) {
            state.endDate = action.payload;
        },
        setQuery(state, action) {
            state.query = action.payload;
        },
        setDevice(state, action) {
            state.device = action.payload;
        },
    }
});

export const {
    getFailListReducer,
    setFailListReducer,
    failedFailListReducer,
    setStartDate,
    setEndDate,
    setQuery,
    setDevice,
} = statsSlice.actions;

export default statsSlice.reducer;