import moment from "moment";
import {createSlice} from "@reduxjs/toolkit"
import {useState} from "react";


export const statsSlice = createSlice({
    name: 'UserDetailReducer',
    initialState: {
        isLoading: false,
        error: null,
        user: [],
        recentlyLoginLog: [],
        recently5: [],
        memoOpen : false,
        memberLoginLog : false,
        memberDepositLog : false,
        memberWithdrawLog : false,
        memberMoneyLog : false,
        memberPointLog : false,
        bank : "",
        level : "",
        status : "",
        telecom : "",
        comment : "",
        commentColor : 10,

    },
    reducers: {
        getUserDetailReducer(state, action) {
            console.log("##getUserDetailReducer##", action.payload.no);
            state.isLoading = true;
            state.no = action.payload.no
        },
        setUserDetailReducer(state, action) {
            console.log("#action.payload.result#", action.payload);
            state.isLoading = false;
            state.user = action.payload.findUser;
            state.recentlyLoginLog = action.payload.recentlyLoginLog;
            state.recently5 = action.payload.recently5;
        },
        failedGetUserDetailReducer(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        setMemoOpen(state, action) {
            state.memoOpen = action.payload;
        },
        setMemberLoginLog(state, action) {
            state.memberLoginLog = action.payload;
        },
        setMemberDepositLog(state, action) {
            state.memberDepositLog = action.payload;
        },
        setMemberWithdrawLog(state, action) {
            state.memberWithdrawLog = action.payload;
        },
        setMemberMoneyLog(state, action) {
            state.memberMoneyLog = action.payload;
        },
        setMemberPointLog(state, action) {
            state.memberPointLog = action.payload;
        },
        setBank(state, action) {
            state.bank = action.payload;
        },
        setLevel(state, action) {
            state.level = action.payload;
        },
        setStatus(state, action) {
            state.status = action.payload;
        },
        setTelecom(state, action) {
            state.telecom = action.payload;
        },
        setComment(state, action) {
            state.comment = action.payload;
        },
        setCommentColor(state, action) {
            state.commentColor = action.payload;
        },
    }
});

export const {
    getUserDetailReducer,
    setUserDetailReducer,
    failedGetUserDetailReducer,
    setMemoOpen,
    setMemberLoginLog,
    setMemberDepositLog,
    setMemberWithdrawLog,
    setMemberMoneyLog,
    setMemberPointLog,
    setBank,
    setLevel,
    setStatus,
    setTelecom,
    setComment,
    setCommentColor,
} = statsSlice.actions;

export default statsSlice.reducer;