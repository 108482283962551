import React from 'react';
import CancelMatchListContainer from "../../containers/CancelMatchListContainer";

/**
 * 함수형 컴포넌트 스니펫입니다.
 */
const CancelMatch = () => {
    return (
        <div>
            <h1>파싱취소</h1>
            <CancelMatchListContainer />
        </div>
    );
};

export default CancelMatch;