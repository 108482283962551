import {call, put, take,} from "redux-saga/effects";
import {fetchFixtureDetail, fetchWaitFixture} from "../../../lib/api";
import {closeChannel, createSocketChannel} from "../../channel/createSocketChannel";
import socket from "../../../config/socket";
import {
    changeParsingWait,
    failedGetWaitFixture,
    failedGetWaitFixtureDetail,
    getWaitFixtureDetail,
    pushParsingWaitTask,
    setCurrentFixture,
    setWaitFixture
} from "../../reducers/match/parsingWait";

export function* getWaitFixtureSaga(action) {
    try {
        const res = yield call(fetchWaitFixture,
            action.payload.page, action.payload.pageSize, action.payload.sport,
            action.payload.provider, action.payload.league, action.payload.query);
        yield put(setWaitFixture(res.data));
        if(res.data.result?.length > 0) {
            yield put(getWaitFixtureDetail(res.data.result[0].fixture_id))
        }
        else {
            yield put(setCurrentFixture(null))
        }
    } catch(error) {
        console.log(error)
        yield put(failedGetWaitFixture(error));
    }
}

export function* getWaitFixtureDetailSaga(action) {
    try{
        const res = yield call(fetchFixtureDetail, action.payload, 0)
        yield put(setCurrentFixture(res.data))
    } catch(error) {
        console.log(error)
        yield put(failedGetWaitFixtureDetail(error))
    }
}

export function* waitParsingWaitTaskSaga() {
    let channel;
    try {
        channel = yield call(createSocketChannel, "parsingWait");
        while(true) {
            const task = yield take(channel);
            yield onTaskSaga(task)
        }
    } catch (e) {
        console.log(e, "error");
    } finally {
        socket.close();
        closeChannel(channel)
    }
}

function* onTaskSaga(task) {
    yield put(pushParsingWaitTask(task));
    yield put(changeParsingWait(task));
}

export function* changeParsingWaitSaga() {
    //yield put(changeSport(changedItem))
}

