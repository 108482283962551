import React, {useEffect, useState} from 'react';
import moment from "moment";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import axios from "axios";
import imageCompression from "browser-image-compression";
import commonInfo from "../../config/commonInfo";

const Popup = ({popup, onRefresh}) => {

    const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().add(1, "day").format('YYYY-MM-DD'));
    const [popupList, setPopupList] = useState([]);
    const [popupNos, setPopupNos] = useState([]);
    const [title, setTitle] = useState([]);
    const [cont, setCont] = useState([]);
    const [count, setCount] =useState(0);
    const [updates, setUpdates] = useState([]);
    const [type, setType] = useState("0");
    const [width, setWidth] = useState("0");
    const [height, setHeight] = useState("0");
    const [popupStartDate, setPopupStartDate] = useState([]);
    const [popupEndDate, setPopupEndDate] = useState([]);
    const [level, setLevel] = useState("10");
    const [status, setStatus] = useState("0");
    const [imageFile, setImageFile] = useState()
    const [imageUrl, setImageUrl] = useState("")
    const [updateImageFile, setUpdateImageFile] = useState();
    const [updateImageUrl, setUpdateImageUrl] = useState("");
    const [query, setQuery] = useState("");

    const initialState = useSelector((state) => state.popup)

    const imageOptions = {
        maxSizeMB: 10,
        maxWidth: width,
        maxheight: height,
        useWebWorker: true,
    }


    const userInfo = useSelector((state) => state.userInfo);

    const getPopupList = () => {
        axios.post('/admin/popup/get_popup_list', {
            adminNo: userInfo.userNo
        }).then((res) => {
            //console.log(res.data)
            if(res.data.popupList !== null) {
                const updates2 = []
                for(let i = 0; i < res.data.popupList.length; i++) {
                    const item = {
                        no: res.data.popupList[i].no,
                        type: res.data.popupList[i].type,
                        status: res.data.popupList[i].status,
                        width: res.data.popupList[i].width,
                        height: res.data.popupList[i].height,
                        level: res.data.popupList[i].level,
                        start_date: res.data.popupList[i].start_date.substring(0,23),
                        end_date: res.data.popupList[i].end_date.substring(0,23),
                        title: res.data.popupList[i].title,
                        cont: res.data.popupList[i].cont,
                        image: res.data.popupList[i].image,
                    }

                    //alert('1  :  '+item.start_date);
                    //alert('2  :  '+res.data.popupList[i].start_date);

                    updates2.push(item)
                }
                setUpdates(updates2)
                setPopupList(res.data.popupList)
                //console.log(res.data.popupList)
                setCount(res.data.count)
                setPopupNos([])
            }

        })
    }
    useEffect(getPopupList, [userInfo.userNo])

    const startDateHandler = (e) => {
        setStartDate(e.currentTarget.value)
    }
    const endDateHandler = (e) => {
        setEndDate(e.currentTarget.value)
    }
    const onTitleHandler = (e) =>{
        setTitle(e.currentTarget.value);
    }
    const onContHandler = (e) =>{
        setCont(e.currentTarget.value);
    }
    const onTypeHandler = (e) =>{
        setType(e.currentTarget.value);
    }
    const onWidthHandler = (e) =>{
        setWidth(e.currentTarget.value);
    }
    const onHeightHandler = (e) =>{
        setHeight(e.currentTarget.value);
    }
    const onStartDateHandler = (e) =>{
        setPopupStartDate(e.currentTarget.value);
    }
    const onEndDateHandler = (e) =>{
        setPopupEndDate(e.currentTarget.value);
    }
    const onLevelHandler = (e) =>{
        setLevel(e.currentTarget.value);
    }
    const onStatusHandler = (e) =>{
        setStatus(e.currentTarget.value);
    }

    const allCheckHandler = (e) => {
        if(popupList.length > 0 && e.currentTarget.value) {
            const checks = [];
            for(let i = 0; i < popupList.length; i++) {
                checks.push(popupList[i].no)
                //console.log(popupList[i].no)
            }
            setPopupNos(e.target.checked? checks : [])
        }
    }
    const checkHandler = (checked, no) => {
        let popupNos2 = []
        if(checked) {
            popupNos2 = [...popupNos, no]
            setPopupNos([...popupNos, no])
        }
        else {
            popupNos2 = popupNos.filter((id) => id !== no)
            setPopupNos(popupNos.filter((id) => id !== no))
        }
        let totalCheck = 0;
        for(let i = 0; i < popupNos2.length; i++) {
            totalCheck += popupList.filter((id) => id.no === popupNos2[i])[0].no
        }
        //console.log(totalCheck)
    }
    const updatesPopupTitleHandler = (value, no) =>{
        const updates2 = updates.filter((item) => item.no !== no)
        const beforeItem = updates.filter((item) => item.no === no)[0]
        beforeItem.title = value
        updates2.push(beforeItem)
        setUpdates(updates2)
        //console.log(updates2)
    }
    const updatesPopupContHandler = (value, no) =>{
        const updates2 = updates.filter((item) => item.no !== no)
        const beforeItem = updates.filter((item) => item.no === no)[0]
        beforeItem.cont = value
        updates2.push(beforeItem)
        setUpdates(updates2)
        //console.log(updates2)
    }
    const updatesPopupTypeHandler = (value, no) =>{
        const updates2 = updates.filter((item) => item.no !== no)
        const beforeItem = updates.filter((item) => item.no === no)[0]
        beforeItem.type = value
        updates2.push(beforeItem)
        setUpdates(updates2)
        //console.log(updates2)
    }
    const updatesPopupWidthHandler = (value, no) =>{
        const updates2 = updates.filter((item) => item.no !== no)
        const beforeItem = updates.filter((item) => item.no === no)[0]
        beforeItem.width = value
        updates2.push(beforeItem)
        setUpdates(updates2)
        //console.log(updates2)
    }
    const updatesPopupHeightHandler = (value, no) =>{
        const updates2 = updates.filter((item) => item.no !== no)
        const beforeItem = updates.filter((item) => item.no === no)[0]
        beforeItem.height = value
        updates2.push(beforeItem)
        setUpdates(updates2)
        //console.log(updates2)
    }
    const updatesPopupStartHandler = (value, no) =>{
        const updates2 = updates.filter((item) => item.no !== no)
        const beforeItem = updates.filter((item) => item.no === no)[0]
        beforeItem.startDate = value
        updates2.push(beforeItem)
        setUpdates(updates2)
        //console.log(updates2)
    }
    const updatesPopupEndHandler = (value, no) =>{
        const updates2 = updates.filter((item) => item.no !== no)
        const beforeItem = updates.filter((item) => item.no === no)[0]
        beforeItem.endDate = value
        updates2.push(beforeItem)
        setUpdates(updates2)
        //console.log(updates2)
    }
    const updatesPopupLevelHandler = (value, no) =>{
        const updates2 = updates.filter((item) => item.no !== no)
        const beforeItem = updates.filter((item) => item.no === no)[0]
        beforeItem.level = value
        updates2.push(beforeItem)
        setUpdates(updates2)
        //console.log(updates2)
    }

    const updatesPopupStatusHandler = (value, no) =>{
        const updates2 = updates.filter((item) => item.no !== no)
        const beforeItem = updates.filter((item) => item.no === no)[0]
        beforeItem.status = value
        updates2.push(beforeItem)
        setUpdates(updates2)
        //console.log(updates2)
    }
    const popupInsert = (e) => {
        if(imageFile === undefined) {

            if (window.confirm("등록 하시겠습니까?")) {

                if( popupStartDate.length < 1 )
                {
                    alert('시작일시 필수값');
                    return;
                }
                if( popupEndDate.length < 1 )
                {
                    alert('종료일시 필수값');
                    return;
                }

                axios.post('/admin/popup/insert_text', {
                    title: title,
                    cont: cont,
                    adminNo: userInfo.userNo,
                    type: type,
                    width: width,
                    height: height,
                    startDate: popupStartDate,
                    endDate: popupEndDate,
                    level: level,
                    status: status,
                }).then((res) => {
                    alert(res.data.isInserted? "등록완료" : "등록실패")
                    getPopupList()
                    onRefresh()

                    setTitle([]);
                    setCont([]);
                    setType("0");
                    setWidth("0");
                    setHeight("0");
                    setLevel("10");
                    // setStatus("0")


                    document.getElementById("button-addon3").click();


                })
            }


        } else {
            //console.log(adminNo)
            const formData = new FormData()
            formData.append("image", imageFile);
            formData.append('data', JSON.stringify({
                    title: title,
                    cont: cont,
                    adminNo: userInfo.userNo,
                    type: type,
                    width: width,
                    height: height,
                    startDate: popupStartDate,
                    endDate: popupEndDate,
                    level: level,
                    status: status,
                }),
            );
            const headers = {
                "Content-Type": "multipart/form-data"
            };

            if (window.confirm("등록 하시겠습니까?")) {
                axios.post('/admin/popup/insert', formData, {headers}
                ).then((res) => {
                    alert(res.data.isInserted? "등록완료" : "등록실패")
                    //getPopupList()
                    onRefresh()
                })
            }

        }
    }
    const popupUpdate = (no) => {
        const updatedItem = updates.filter((item) => item.no === no)[0]
        const popupUpdate = []
        popupUpdate.push(updatedItem)

        const formData = new FormData()
        formData.append("image", updateImageFile);
        formData.append('data', JSON.stringify(popupUpdate));
        const headers = {
            "Content-Type": "multipart/form-data"
        };

        if (window.confirm("수정 하시겠습니까?")) {
            axios.post('/admin/popup/update', formData, {headers}
            ).then((res) => {
                alert(res.data.success? "수정완료" : "수정실패")
                //getPopupList()
                onRefresh()
            })
        }

    }
    const popupDelete = (e) => {
        if(popupNos.length > 0) {

            if (window.confirm("삭제 하시겠습니까?")) {
                axios.post('/admin/popup/delete', {
                    popupNos: popupNos
                }).then((res) => {
                    alert(res.data.isDeleted? "삭제완료" : "삭제실패")
                    //getPopupList()
                    onRefresh()
                })
            }

        }
        else {
            alert('선택해주세요')
        }
    }

    const previewImage = async (e, change) => {
        e.preventDefault();
        const file = e.target.files[0];
        try {
            const compressedFile = await imageCompression(file, imageOptions);
            const reader = new FileReader();

            reader.readAsDataURL(compressedFile);
            reader.onloadend = () => {
                // 변환 완료!
                const base64data = reader.result;
                handlingDataForm(base64data, change);
            };
        } catch (error) {
            alert("이미지파일 등록실패")
        }
    }

    const handlingDataForm = async (base64data, change) => {
        // dataURL 값이 data:image/jpeg:base64,~~~~~~~ 이므로 ','를 기점으로 잘라서 ~~~~~인 부분만 다시 인코딩
        const byteString = atob(base64data.split(",")[1]);

        // Blob를 구성하기 위한 준비, 이 내용은 저도 잘 이해가 안가서 기술하지 않았습니다.
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([ia], {
            type: "image/jpg"
        });
        const file = new File([blob], ".jpg");

        // 위 과정을 통해 만든 image폼을 FormData에 넣어줍니다.
        // 서버에서는 이미지를 받을 때, FormData가 아니면 받지 않도록 세팅해야합니다.
        if (change === 0) {
            const fileUrl = URL.createObjectURL(file); // (*)
            setImageFile(file)
            setImageUrl(fileUrl)
        } else {
            const fileUrl = URL.createObjectURL(file); // (*)
            setUpdateImageFile(file)
            setUpdateImageUrl(fileUrl)
        }
    }

    const queryHandler = (e) => {
        setQuery(e.target.value)
    }
    const inputPress = (e) => {
        if(e.key === "Enter") {
            searchHandler(query, startDate, endDate)
        }
    }

    const searchHandler = (query, startDate, endDate) => {
        axios.post('/admin/popup/query', {
            start_date: startDate,
            end_date: endDate,
            query: query,
        }).then((res) => {
            if (res.data.popupList !== null) {
                setPopupList(res.data.popupList)
            }
        })
    }

    const typeOptions = [
        {value : "0", name : "레이어"},
        {value : "1", name : "텍스트"},
        {value : "2", name : "이미지"},
    ]
    const widthOptions = [
        {value : "0", name : "320"},
        {value : "1", name : "480"},
        {value : "2", name : "720"},
        {value : "3", name : "1080"},
        {value : "4", name : "1280"},
    ]
    const heightOptions = [
        {value : "0", name : "320"},
        {value : "1", name : "480"},
        {value : "2", name : "720"},
        {value : "3", name : "1080"},
        {value : "4", name : "1280"},
    ]
    const levelOptions = [
        {value : "10", name : "전체"},
        {value : "0", name : "1레벨"},
        {value : "1", name : "2레벨"},
        {value : "2", name : "3레벨"},
        {value : "3", name : "4레벨"},
        {value : "4", name : "5레벨"},
        {value : "5", name : "6레벨"},
        {value : "6", name : "VIP"},
    ]
    const statusOptions = [
        {value : "0", name : "사용"},
        {value : "1", name : "미사용"},
    ]

    function imgType(type) {
        switch (type) {
            case 0 : return "레이어"
            case 1 : return "텍스트"
            case 2 : return "이미지"
            default: break;
        }
    }

    function size(state) {
        switch (state) {
            case 0 : return "320"
            case 1 : return "480"
            case 2 : return "720"
            case 3 : return "1080"
            case 4 : return "1280"
            default: break;
        }
    }

    function levelType(state) {
        switch (state) {
            case 10 : return "전체"
            case 0 : return "1레벨"
            case 1 : return "2레벨"
            case 2 : return "3레벨"
            case 3 : return "4레벨"
            case 4 : return "5레벨"
            case 5 : return "6레벨"
            case 6 : return "VIP"
            default: break;
        }
    }

    function exposure(state) {
        switch (state) {
            case 0 : return "사용"
            case 1 : return "미사용"
            default: break;
        }
    }

    return (
        <div className="m-2">

            <div className="card-body">
                <div>
                    <div className="container-fluid">
                        <div className="my-2 d-flex">
                            <span>(팝업수 : {count} 개)</span>
                        </div>
                    </div>
                    <div>
                        <table className="table table-bordered table-sm text-center align-middle">
                            <thead className="table-dark">
                            <tr>
                                <th style={{width: "30px"}}>
                                    <input className="form-check-input" type="checkbox" checked={popupList.length === popupNos.length}
                                           onChange={allCheckHandler} id="flexCheckDefault"/>
                                </th>
                                <th style={{width: "450px"}}>제목</th>
                                <th style={{width: "5%"}}>타입</th>
                                <th style={{width: "5%"}}>사이즈</th>
                                <th style={{width: "5%"}}>레벨적용</th>
                                <th style={{width: "10%"}}>시작시간</th>
                                <th style={{width: "10%"}}>종료시간</th>
                                <th style={{width: "5%"}}>사용</th>
                                <th style={{width: "6%"}}>수정</th>
                                <th style={{width: "6%"}}>관리자</th>
                            </tr>
                            </thead>

                            {!popup.length && (
                                <tbody key={popup.no}>
                                <tr>
                                    <td colSpan="10">목록이 비어있습니다.</td>
                                </tr>
                                </tbody>
                            )}

                            {popup.map((popup, index) => (
                                <tbody key={popup.no}>
                                <tr>
                                    <td>
                                        <input className="form-check-input" type="checkbox"
                                               checked={popupNos.filter((no) => no === popup.no).length > 0}
                                               onChange={(e) => {checkHandler(e.currentTarget.checked, popup.no)}}
                                        />
                                    </td>
                                    <td>
                                        <Link className="nav-link" to={"#collapse" + index} data-bs-toggle="collapse"
                                              aria-expanded="true" aria-controls={"#collapse" + index}>
                                            {popup.title}
                                        </Link>
                                    </td>
                                    <td>{imgType(popup.type)}</td>
                                    <td>{size(popup.width)}X{size(popup.height)}</td>
                                    <td>{levelType(popup.level)}</td>
                                    <td>{moment(popup.start_date).format("YYYY-MM-DD HH:mm:ss")}</td>
                                    <td>{moment(popup.end_date).format("YYYY-MM-DD HH:mm:Ss")}</td>
                                    <td>{exposure(popup.status)}</td>
                                    <td>
                                        <button className="btn btn-outline-secondary ms-2" type="button"
                                                data-bs-toggle="collapse" data-bs-target={"#collapse2" + index}
                                                aria-expanded="false" aria-controls={"collapse2" + index} style={{
                                            width: "60px"
                                        }}>수정
                                        </button>
                                    </td>
                                    <td>{popup.admin.id}</td>
                                </tr>
                                <tr className="collapse" id={"collapse" + index}>
                                    <td></td>
                                    <td>
                                        {popup.cont}
                                        <br></br>
                                        <img src={commonInfo.imgUrl + popup.image} alt="" />
                                    </td>
                                    <td colSpan="8"></td>
                                </tr>
                                <tr className="collapse" id={"collapse2" + index}>
                                    <td colSpan="10">
                                        <div className="update d-flex" >
                                            <div style={{
                                                width: "60%"
                                            }}>
                                                <div className="my-3 d-flex">
                                                    <label htmlFor="exampleFormControlInput1" className="form-label" style={{
                                                        width: "50px",
                                                        marginTop: "5px"
                                                    }}>제목</label>
                                                    <input type="text" maxLength="200" className="form-control" id="exampleFormControlInput1"
                                                           value={updates.filter((item) => item.no === popup.no).length > 0? updates.filter((item) => item.no === popup.no)[0].title : ""}
                                                           onChange={(e) => {updatesPopupTitleHandler(e.currentTarget.value, popup.no)}}
                                                    />
                                                </div>
                                                <div className="mb-3 d-flex">
                                                    <label htmlFor="exampleFormControlTextarea1" className="form-label" style={{
                                                        width: "50px"
                                                    }}>내용</label>
                                                    <textarea className="form-control" maxLength="1000"  id="exampleFormControlTextarea1" rows="12"
                                                              value={updates.filter((item) => item.no === popup.no).length > 0? updates.filter((item) => item.no === popup.no)[0].cont : ""}
                                                              onChange={(e) => {updatesPopupContHandler(e.currentTarget.value, popup.no)}}
                                                    >
                                                        </textarea>
                                                </div>
                                                <div className="text-start">
                                                    <div>
                                                        <img src={updates.filter((file) => file.no === popup.no).length > 0 ?
                                                            updateImageUrl === ""? commonInfo.imgUrl + popup.image : updateImageUrl
                                                            :""} alt="" />
                                                    </div>
                                                    <input type="file" accept=".jpeg, .jpg, .png"  onChange={(e) => previewImage(e, 1)} />
                                                </div>
                                            </div>
                                            <div style={{width: "2%"}}>
                                            </div>
                                            <div style={{
                                                width: "38%"
                                            }}>
                                                <div className="my-3 d-flex">
                                                    <label className="form-label" style={{
                                                        width: "80px",
                                                        marginTop: "5px"
                                                    }}>타입</label>
                                                    <select className="form-select" id="type"
                                                            value={updates.filter((item) => item.no === popup.no).length > 0? updates.filter((item) => item.no === popup.no)[0].type : ""}
                                                            onChange={(e) => {updatesPopupTypeHandler(e.currentTarget.value, popup.no)}}>
                                                        {typeOptions.map((item, index) => (
                                                            <option key={item.value} value={item.value}>{item.name}</option>
                                                        ))}
                                                    </select>
                                                </div>

                                                <div className="my-3 d-flex">
                                                    <label className="form-label" style={{
                                                        width: "80px",
                                                        marginTop: "5px"
                                                    }}>가로</label>
                                                    <select className="form-select" id="width"
                                                            value={updates.filter((item) => item.no === popup.no).length > 0? updates.filter((item) => item.no === popup.no)[0].width : ""}
                                                            onChange={(e) => {updatesPopupWidthHandler(e.currentTarget.value, popup.no)}}>
                                                        {widthOptions.map((item, index) => (
                                                            <option key={item.value} value={item.value}>{item.name}</option>
                                                        ))}
                                                    </select>
                                                </div>

                                                <div className="my-3 d-flex">
                                                    <label className="form-label" style={{
                                                        width: "80px",
                                                        marginTop: "5px"
                                                    }}>세로</label>
                                                    <select className="form-select" id="height"
                                                            value={updates.filter((item) => item.no === popup.no).length > 0? updates.filter((item) => item.no === popup.no)[0].height : ""}
                                                            onChange={(e) => {updatesPopupHeightHandler(e.currentTarget.value, popup.no)}}>
                                                        {heightOptions.map((item, index) => (
                                                            <option key={item.value} value={item.value}>{item.name}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="my-3 d-flex">
                                                    <label className="form-label" style={{
                                                        width: "80px",
                                                        marginTop: "5px"
                                                    }}>시작</label>
                                                    <input type="datetime-local" className="form-control" id="startDate"
                                                           value={updates.filter((item) => item.no === popup.no).length > 0? updates.filter((item) => item.no === popup.no)[0].startDate : ""}
                                                           onChange={(e) => {updatesPopupStartHandler(e.currentTarget.value, popup.no)}}
                                                           min="2022-01-01" max="2025-12-31"/>
                                                </div>
                                                <div className="my-3 d-flex">
                                                    <label className="form-label" style={{
                                                        width: "80px",
                                                        marginTop: "5px"
                                                    }}>종료</label>
                                                    <input type="datetime-local" className="form-control" id="endDate"
                                                           value={updates.filter((item) => item.no === popup.no).length > 0? updates.filter((item) => item.no === popup.no)[0].endDate : ""}
                                                           onChange={(e) => {updatesPopupEndHandler(e.currentTarget.value, popup.no)}}
                                                           min="2022-01-01" max="2025-12-31"/>
                                                </div>
                                                <div className="my-3 d-flex">
                                                    <label className="form-label" style={{
                                                        width: "80px",
                                                        marginTop: "5px"
                                                    }}>레벨</label>
                                                    <select className="form-select" id="level"
                                                            value={updates.filter((item) => item.no === popup.no).length > 0? updates.filter((item) => item.no === popup.no)[0].level : ""}
                                                            onChange={(e) => {updatesPopupLevelHandler(e.currentTarget.value, popup.no)}}>
                                                        {levelOptions.map((item, index) => (
                                                            <option key={item.value} value={item.value}>{item.name}</option>
                                                        ))}
                                                    </select>
                                                </div>

                                                <div className="my-3 d-flex">
                                                    <label className="form-label" style={{
                                                        width: "80px",
                                                        marginTop: "5px"
                                                    }}>상태</label>
                                                    <select className="form-select" id="status"
                                                            value={updates.filter((item) => item.no === popup.no).length > 0? updates.filter((item) => item.no === popup.no)[0].status : ""}
                                                            onChange={(e) => {updatesPopupStatusHandler(e.currentTarget.value, popup.no)}}>
                                                        {statusOptions.map((item, index) => (
                                                            <option key={item.value} value={item.value}>{item.name}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="text-center">
                                            <button className="btn btn-outline-secondary" type="button"
                                                    onClick={()=> {
                                                        popupUpdate(popup.no)
                                                    }}>
                                                내용수정
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            ))}
                        </table>
                    </div>

                    <div className="d-flex">
                        <button className="btn btn-outline-secondary me-2" type="button"
                                data-bs-toggle="collapse" data-bs-target="#addCollapse"
                                aria-expanded="false" aria-controls="addCollapse" id="button-addon3">추가
                        </button>
                        <button className="btn btn-outline-secondary me-4" type="button"
                                id="button-addon2" onClick={popupDelete}>선택 삭제
                        </button>
                    </div>
                    <div id="addCollapse" className="add collapse">
                        <div className="my-3 d-flex">
                            <div style={{
                                width: "60%"
                            }}>
                                <div className="my-3 d-flex">
                                    <label htmlFor="exampleFormControlInput1" className="form-label" style={{
                                        width: "50px",
                                        marginTop: "5px"
                                    }}>제목</label>
                                    <input type="text" maxLength="200" className="form-control" value={title} id="exampleFormControlInput1" onChange={onTitleHandler}/>
                                </div>
                                <div className="mb-3 d-flex">
                                    <label htmlFor="exampleFormControlTextarea1" className="form-label" style={{
                                        width: "50px"
                                    }}>내용</label>
                                    <textarea className="form-control" maxLength="1000" value={cont} id="exampleFormControlTextarea1" rows="3" onChange={onContHandler}></textarea>
                                </div>
                                <div>
                                    <div>
                                        <img src={imageUrl} style={{maxWidth: "100%"}} alt=""/>
                                    </div>

                                    <input type="file" accept=".jpeg, .jpg, .png"  onChange={(e) => previewImage(e, 0)} />
                                </div>

                            </div>

                            <div className="ms-3" style={{
                                width: "38%"
                            }}>
                                <div className="my-3 d-flex">
                                    <label className="form-label" style={{
                                        width: "80px",
                                        marginTop: "5px"
                                    }}>타입</label>
                                    <select className="form-select" id="type" onChange={onTypeHandler} value={type}>
                                        {typeOptions.map((item, index) => (
                                            <option key={item.value} value={item.value}>{item.name}</option>
                                        ))}
                                    </select>
                                </div>

                                <div className="my-3 d-flex">
                                    <label className="form-label" style={{
                                        width: "80px",
                                        marginTop: "5px"
                                    }}>가로</label>
                                    <select className="form-select" id="width" onChange={onWidthHandler} value={width}>
                                        {widthOptions.map((item, index) => (
                                            <option key={item.value} value={item.value}>{item.name}</option>
                                        ))}
                                    </select>
                                </div>

                                <div className="my-3 d-flex">
                                    <label className="form-label" style={{
                                        width: "80px",
                                        marginTop: "5px"
                                    }}>세로</label>
                                    <select className="form-select" id="height" onChange={onHeightHandler} value={height}>
                                        {heightOptions.map((item, index) => (
                                            <option key={item.value} value={item.value}>{item.name}</option>
                                        ))}
                                    </select>
                                </div>

                                <div className="my-3 d-flex">
                                    <label className="form-label" style={{
                                        width: "80px",
                                        marginTop: "5px"
                                    }}>시작</label>
                                    <input type="datetime-local" className="form-control" id="start" onChange={onStartDateHandler}
                                           value={popupStartDate} min="2022-01-01" max="2025-12-31"/>
                                </div>

                                <div className="my-3 d-flex">
                                    <label className="form-label" style={{
                                        width: "80px",
                                        marginTop: "5px"
                                    }}>종료</label>
                                    <input type="datetime-local" className="form-control" id="end" onChange={onEndDateHandler}
                                           value={popupEndDate} min="2022-01-01" max="2025-12-31"/>
                                </div>

                                <div className="my-3 d-flex">
                                    <label className="form-label" style={{
                                        width: "80px",
                                        marginTop: "5px"
                                    }}>레벨</label>
                                    <select className="form-select" id="level" onChange={onLevelHandler} value={level}>
                                        {levelOptions.map((item, index) => (
                                            <option key={item.value} value={item.value}>{item.name}</option>
                                        ))}
                                    </select>
                                </div>

                                <div className="my-3 d-flex">
                                    <label className="form-label" style={{
                                        width: "80px",
                                        marginTop: "5px"
                                    }}>상태</label>
                                    <select className="form-select" id="status" onChange={onStatusHandler} value={status}>
                                        {statusOptions.map((item, index) => (
                                            <option key={item.value} value={item.key}>{item.name}</option>
                                        ))}
                                    </select>
                                </div>

                            </div>
                        </div>
                        <div className="text-center">
                            <button className="btn btn-outline-secondary" type="button"
                                    id="button-addon2"
                                    onClick={() => popupInsert()}>
                                내용등록
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    )
}

export default Popup;