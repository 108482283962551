import React from 'react';
import StopMatchByListContainer from "../../containers/StopMatchByListContainer";

/**
 * 함수형 컴포넌트 스니펫입니다.
 */
const StopMatchBy = () => {
    return (
        <div>
            <h1>수동중지</h1>
            <StopMatchByListContainer />
        </div>
    );
};

export default StopMatchBy;