import React, {useEffect, useState} from 'react';
import moment from "moment";
import {Link} from "react-router-dom";
import axios from "axios";
import {useSelector} from "react-redux";
import {NumericFormat} from 'react-number-format';
import JoinComponent from "../../components/JoinComponent";
import AdminAddComponent from "../../components/AdminAddComponent";
import UserInformation from "../../components/UserInformation";
import typeCase from "../../util/typeCase"

const Member = ({member, onRefresh}) => {
    const [userList, setUserList] = useState([]);
    const [selectedNos, setSelectedNos] = useState([]);
    const [query, setQuery] = useState("");
    const [total, setTotal] = useState(0);
    const [levelCount, setLevelCount] = useState([]);
    const [stateCount, setStateCount] = useState([]);

    const [isOpen, setIsOpen] = useState(false);
    const [userInformation, setUserInformation] = useState(false);
    const [dormancyDate, setDormancyDate] = useState("");

    const [userTypeSort, setUserTypeSort] = useState(0);
    const [codeSort, setCodeSort] = useState(0);
    const [userStateSort, setUserStateSort] = useState(0);
    const [levelSort, setLevelSort] = useState(0);
    const [moneySort, setMoneySort] = useState(0);
    const [pointSort, setPointSort] = useState(0);
    const [depositSort, setDepositSort] = useState(0);
    const [withdrawSort, setWithdrawSort] = useState(0);
    const [totalAmountSort, setTotalAmountSort] = useState(0);
    const [monthAmountSort, setMonthAmountSort] = useState(0);
    const [loginCountSort, setLoginCountSort] = useState(0);
    const [recentlyDepositSort, setRecentlyDepositSort] = useState(0);
    const [recentlyLoginSort, setRecentlyLoginSort] = useState(0);
    const [recentlyDomainSort, setRecentlyDomainSort] = useState(0);
    const [createdAtSort, setCreatedAtSort] = useState(0);

    const userInfo = useSelector((state) => state.userInfo);

    const getAllList = () => {
        axios.post('/admin/member/get_member_list', {
            adminNo: userInfo.userNo
        }).then((res) => {
            setSelectedNos([])
            setUserList(res.data.userList)
            setTotal(res.data.userTotal)
            //console.log(res.data.userTotal)
            setLevelCount(res.data.userLevelCount)
            setStateCount(res.data.userStateCount)
            setUserTypeSort(0)
            setCodeSort(0)
            setUserStateSort(0)
            setLevelSort(0)
            setMoneySort(0)
            setPointSort(0)
            setDepositSort(0)
            setWithdrawSort(0)
            setTotalAmountSort(0)
            setMonthAmountSort(0)
            setLoginCountSort(0)
            setRecentlyDepositSort(0)
            setRecentlyLoginSort(0)
            setRecentlyDomainSort(0)
            setCreatedAtSort(0)
        })
    }
    useEffect(getAllList, [])

    const sortByColNameUser = (colName, orderOption) => {
        if (userList.length > 0) {
            axios.post('/admin/member/sort_by_colName_user', {
                adminNo: userInfo.userNo,
                colName: colName,
                orderOption: orderOption,
            }).then((res) => {
                setUserList(res.data.userOrder)
            })
        }

        if(colName === "user_type") {
            setUserTypeSort(orderOption)
        } else if(colName === "recommend_code") {
            setCodeSort(orderOption)
        } else if(colName === "user_state") {
            setUserStateSort(orderOption)
        } else if(colName === "level") {
            setLevelSort(orderOption)
        } else if(colName === "money") {
            setMoneySort(orderOption)
        } else if(colName === "point") {
            setPointSort(orderOption)
        } else if(colName === "deposit_total") {
            setDepositSort(orderOption)
        } else if(colName === "withdraw_total") {
            setWithdrawSort(orderOption)
        } else if(colName === "total_amount") {
            setTotalAmountSort(orderOption)
        } else if(colName === "month_amount") {
            setMonthAmountSort(orderOption)
        } else if(colName === "login_count") {
            setLoginCountSort(orderOption)
        } else if(colName === "recently_deposit") {
            setRecentlyDepositSort(orderOption)
        } else if(colName === "login_createdAt") {
            setRecentlyLoginSort(orderOption)
        } else if(colName === "login_domain") {
            setRecentlyDomainSort(orderOption)
        } else if(colName === "createdAt") {
            setCreatedAtSort(orderOption)
        }
    }

    function sortUserType(id, userTypeSort) {
        switch (userTypeSort) {
            case 0 : return <div onClick={(e) => sortByColNameUser(id, 1)}>파트너</div>
            case 1 : return <div onClick={(e) => sortByColNameUser(id, 2)}>파트너▲</div>
            case 2 : return <div onClick={(e) => sortByColNameUser(id, 1)}>파트너▼</div>
            default : return userTypeSort
        }
    }
    function sortCodeType(id, setCodeSort) {
        switch (setCodeSort) {
            case 0 : return <div onClick={(e) => sortByColNameUser(id, 1)}>추천</div>
            case 1 : return <div onClick={(e) => sortByColNameUser(id, 2)}>추천▲</div>
            case 2 : return <div onClick={(e) => sortByColNameUser(id, 1)}>추천▼</div>
            default : return setCodeSort
        }
    }
    function sortUserStateType(id, setUserStateSort) {
        switch (setUserStateSort) {
            case 0 : return <div onClick={(e) => sortByColNameUser(id, 1)}>상태</div>
            case 1 : return <div onClick={(e) => sortByColNameUser(id, 2)}>상태▲</div>
            case 2 : return <div onClick={(e) => sortByColNameUser(id, 1)}>상태▼</div>
            default : return setUserStateSort
        }
    }
    function sortLevelType(id, setLevelSort) {
        switch (setLevelSort) {
            case 0 : return <div onClick={(e) => sortByColNameUser(id, 1)}>등급</div>
            case 1 : return <div onClick={(e) => sortByColNameUser(id, 2)}>등급▲</div>
            case 2 : return <div onClick={(e) => sortByColNameUser(id, 1)}>등급▼</div>
            default : return setLevelSort
        }
    }
    function sortMoneyType(id, moneySort) {
        switch (moneySort) {
            case 0 : return <div onClick={(e) => sortByColNameUser(id, 1)}>보유머니</div>
            case 1 : return <div onClick={(e) => sortByColNameUser(id, 2)}>보유머니▲</div>
            case 2 : return <div onClick={(e) => sortByColNameUser(id, 1)}>보유머니▼</div>
            default : return moneySort
        }
    }
    function sortPointType(id, pointSort) {
        switch (pointSort) {
            case 0 : return <div onClick={(e) => sortByColNameUser(id, 1)}>포인트</div>
            case 1 : return <div onClick={(e) => sortByColNameUser(id, 2)}>포인트▲</div>
            case 2 : return <div onClick={(e) => sortByColNameUser(id, 1)}>포인트▼</div>
            default : return pointSort
        }
    }
    function sortDepositType(id, depositSort) {
        switch (depositSort) {
            case 0 : return <div onClick={(e) => sortByColNameUser(id, 1)}>충전</div>
            case 1 : return <div onClick={(e) => sortByColNameUser(id, 2)}>충전▲</div>
            case 2 : return <div onClick={(e) => sortByColNameUser(id, 1)}>충전▼</div>
            default : return depositSort
        }
    }
    function sortWithdrawType(id, withdrawSort) {
        switch (withdrawSort) {
            case 0 : return <div onClick={(e) => sortByColNameUser(id, 1)}>환전</div>
            case 1 : return <div onClick={(e) => sortByColNameUser(id, 2)}>환전▲</div>
            case 2 : return <div onClick={(e) => sortByColNameUser(id, 1)}>환전▼</div>
            default : return withdrawSort
        }
    }
    function sortTotalAmount(id, totalAmountSort) {
        switch (totalAmountSort) {
            case 0 : return <div onClick={(e) => sortByColNameUser(id, 1)}>충환차액</div>
            case 1 : return <div onClick={(e) => sortByColNameUser(id, 2)}>충환차액▲</div>
            case 2 : return <div onClick={(e) => sortByColNameUser(id, 1)}>충환차액▼</div>
            default : return totalAmountSort
        }
    }
    function sortMonthAmount(id, monthAmountSort) {
        switch (monthAmountSort) {
            case 0 : return <div onClick={(e) => sortByColNameUser(id, 1)}>이달수익</div>
            case 1 : return <div onClick={(e) => sortByColNameUser(id, 2)}>이달수익▲</div>
            case 2 : return <div onClick={(e) => sortByColNameUser(id, 1)}>이달수익▼</div>
            default : return monthAmountSort
        }
    }
    function sortLoginCount(id, loginCountSort) {
        switch (loginCountSort) {
            case 0 : return <div onClick={(e) => sortByColNameUser(id, 1)}>접속수</div>
            case 1 : return <div onClick={(e) => sortByColNameUser(id, 2)}>접속수▲</div>
            case 2 : return <div onClick={(e) => sortByColNameUser(id, 1)}>접속수▼</div>
            default : return loginCountSort
        }
    }
    function sortRecentlyDeposit(id, recentlyDepositSort) {
        switch (recentlyDepositSort) {
            case 0 : return <div onClick={(e) => sortByColNameUser(id, 1)}>최근충전</div>
            case 1 : return <div onClick={(e) => sortByColNameUser(id, 2)}>최근충전▲</div>
            case 2 : return <div onClick={(e) => sortByColNameUser(id, 1)}>최근충전▼</div>
            default : return recentlyDepositSort
        }
    }
    function sortRecentlyLogin(id, recentlyLoginSort) {
        switch (recentlyLoginSort) {
            case 0 : return <div onClick={(e) => sortByColNameUser(id, 1)}>최근접속일</div>
            case 1 : return <div onClick={(e) => sortByColNameUser(id, 2)}>최근접속일▲</div>
            case 2 : return <div onClick={(e) => sortByColNameUser(id, 1)}>최근접속일▼</div>
            default : return recentlyLoginSort
        }
    }
    function sortRecentlyDomain(id, recentlyDomainSort) {
        switch (recentlyDomainSort) {
            case 0 : return <div onClick={(e) => sortByColNameUser(id, 1)}>최근도메인</div>
            case 1 : return <div onClick={(e) => sortByColNameUser(id, 2)}>최근도메인▲</div>
            case 2 : return <div onClick={(e) => sortByColNameUser(id, 1)}>최근도메인▼</div>
            default : return recentlyDomainSort
        }
    }
    function sortCreatedAtType(id, setCreatedAtSort) {
        switch (setCreatedAtSort) {
            case 0 : return <div onClick={(e) => sortByColNameUser(id, 1)}>가입일</div>
            case 1 : return <div onClick={(e) => sortByColNameUser(id, 2)}>가입일▲</div>
            case 2 : return <div onClick={(e) => sortByColNameUser(id, 1)}>가입일▼</div>
            default : return setCreatedAtSort
        }
    }

    const handleClickOpen = () => {
        setIsOpen(true);
    }
    const handleClose = () => {
        setIsOpen(false);
        getAllList();
        onRefresh();
    }

    const informationOpenHandler = () => {
        setUserInformation(true);
    }
    const dormancyDateHandler = (e) => {
        setDormancyDate(e.target.value)
        //console.log(e.target.value)
    }
    const dormancyState = (change_state) => {
        if ( dormancyDate !== "" ) {
            axios.post('/admin/member/dormancy_state', {
                dormancyDate: dormancyDate,
                adminNo: userInfo.userNo,
                user_state: change_state
            }).then((res) => {
                if(res.data.isUpdated) {
                    alert("휴면변경완료")
                    setDormancyDate("")
                    getAllList()
                } else {
                    alert("실패")
                }
            })
        } else {
            alert("날짜를 선택해주세요")
        }
    }
    const allCheckHandler = (e) => {
        if(userList.length > 0 && e.currentTarget.value) {
            const checks = [];
            let total = 0;
            for(let i = 0; i < userList.length; i++) {
                checks.push(userList[i].no)
                total += userList[i].request_money
            }
            setSelectedNos(e.target.checked? checks : [])
        }
    }
    const checkHandler = (checked, no) => {
        let selectedNos2 = []
        if(checked) {
            selectedNos2 = [...selectedNos, no]
            setSelectedNos([...selectedNos, no])
        }
        else {
            selectedNos2 = selectedNos.filter((id) => id !== no)
            setSelectedNos(selectedNos.filter((id) => id !== no))
        }
        let total = 0;
        for(let i = 0; i < selectedNos2.length; i++) {
            total += userList.filter((id) => id.no === selectedNos2[i])[0]
        }
    }
    const queryHandler = (e) => {
        setQuery(e.target.value)
    }
    const inputPress = (e) => {
        if(e.key === "Enter") {
            searchHandler(query, 10, 10)
        }
    }
    const partnerSelectList =[
        {value : "1", name: "총판1"},
        {value : "2", name: "총판2"},
        {value : "3", name: "총판3"},
        {value : "4", name: "총판4"},
    ]

    const searchHandler = (query, user_state, level) => {
        axios.post('/admin/member/query', {
            query: query,
            user_state: user_state,
            level: level
        }).then((res) => {
            if (res.data.userList !== null) {
                setUserList(res.data.userList)
                //console.log(res.data.userList)
            }
        })
    }
    const allList = () => {
        getAllList()
    }

    const updateState = (user_state, selectedNos) => {
        //console.log(selectedNos)
        if(selectedNos.length > 0) {

            if (window.confirm("변경 하시겠습니까?")) {

                axios.post('/admin/member/change_state', {
                    adminNo: userInfo.userNo,
                    selectedNos: selectedNos,
                    user_state: user_state
                }).then((res) => {
                    if (res.data.isUpdated) {
                        alert("변경완료")
                        setSelectedNos([])

                        getAllList()
                        onRefresh()
                    } else {
                        alert("실패")
                    }
                })
            }
        }
        else {
            alert('선택해주세요')
        }
    }

    function onCommentColor (color) {
        switch (color) {
            case 0 : return "red"
            case 1 : return "orange"
            case 2 : return "yellow"
            case 3 : return "green"
            case 4 : return "blue"
            case 5 : return "navy"
            case 6 : return "purple"
            case 10 : return ""
            default : return color
        }
    }

    function userLevel (level) {
        switch (level) {
            case 0 : return "레벨 1 "
            case 1 : return "레벨 2 "
            case 2 : return "레벨 3 "
            case 3 : return "레벨 4 "
            case 4 : return "레벨 5 "
            case 5 : return "VIP "
            default : return level
        }
    }
    const newUserColor = (state) => {
        switch(state) {
            case 0 :
                return "bgRequest"
            default : return state
        }
    }

    return (
        <div className="m-2">
            <div className="card-body">
                <div>
                    <div className="container-fluid">
                        <div className="row bg-light align-items-center" >
                        </div>
                        <div className="row mt-2" style={{
                            fontSize: "13px"
                        }}>
                            <div className="col-8">
                                <div className="d-flex" style={{fontWeight: "bold", fontSize: "14px"}}>
                                    <span className="me-2" onClick={allList} style={{cursor: "pointer"}}>총 회원수 : {total && total[0].count}</span>
                                    <span>[</span>
                                    <span className="text-success mx-2" onClick={() => searchHandler(query, 0, 10)} style={{cursor: "pointer"}}>
                                        대기 : <span className="text-dark">{stateCount[0] && stateCount[0].count}</span>
                                    </span>
                                    <span className="text-warning mx-2"onClick={() => searchHandler(query, 1, 10)} style={{cursor: "pointer"}}>
                                        요주의 : <span className="text-dark">{stateCount[1] && stateCount[1].count}</span>
                                    </span>
                                    <span className="mx-2" onClick={() => searchHandler(query, 2, 10)} style={{cursor: "pointer"}}>
                                        신규 : <span className="text-dark">{stateCount[2] && stateCount[2].count}</span>
                                    </span>
                                    <span className="mx-2" onClick={() => searchHandler(query, 3, 10)} style={{cursor: "pointer"}}>
                                        정상 : <span className="text-dark">{stateCount[3] && stateCount[3].count}</span>
                                    </span>
                                    <span className="text-info mx-2"onClick={() => searchHandler(query, 4, 10)} style={{cursor: "pointer"}}>
                                        성향 : <span className="text-dark">{stateCount[4] && stateCount[4].count}</span>
                                    </span>
                                    <span className="text-danger mx-2" onClick={() => searchHandler(query, 5, 10)} style={{cursor: "pointer"}}>
                                        악성 : <span className="text-dark">{stateCount[5] && stateCount[5].count}</span>
                                    </span>
                                    <span className="mx-2" onClick={() => searchHandler(query, 6, 10)} style={{cursor: "pointer"}}>
                                        미이용 : <span className="text-dark">{stateCount[6] && stateCount[6].count}</span>
                                    </span>
                                    <span className="mx-2" onClick={() => searchHandler(query, 7, 10)} style={{cursor: "pointer"}}>
                                        휴면 : <span className="text-dark">{stateCount[7] && stateCount[7].count}</span>
                                    </span>
                                    <span className="mx-2" onClick={() => searchHandler(query, 8, 10)} style={{cursor: "pointer"}}>
                                        탈퇴 : <span className="text-dark">{stateCount[8] && stateCount[8].count}</span>
                                    </span>
                                    <span className="mx-2" onClick={() => searchHandler(query, 9, 10)} style={{cursor: "pointer"}}>
                                        졸업 : <span className="text-dark">{stateCount[9] && stateCount[9].count}</span>
                                    </span>
                                    <span className="mx-2 text-danger" onClick={() => searchHandler(query, 10, 10)} style={{cursor: "pointer"}}>
                                        블랙 : <span className="text-dark">{stateCount[10] && stateCount[10].count}</span>
                                    </span>
                                    <span>]</span>
                                </div>
                                <div className="d-flex my-1" style={{fontWeight: "bold", fontSize: "14px"}}>
                                    <span className="me-3" onClick={() => searchHandler(query, 10, 0)} style={{cursor: "pointer"}}>
                                        {userLevel(0)} : {levelCount[0] && levelCount[0].levelCount}
                                    </span>
                                    <span className="me-3" onClick={() => searchHandler(query, 10, 1)} style={{cursor: "pointer"}}>
                                        {userLevel(1)} : {levelCount[1] && levelCount[1].levelCount}
                                    </span>
                                    <span className="me-3" onClick={() => searchHandler(query, 10, 2)} style={{cursor: "pointer"}}>
                                        {userLevel(2)} : {levelCount[2] && levelCount[2].levelCount}
                                    </span>
                                    <span className="me-3" onClick={() => searchHandler(query, 10, 3)} style={{cursor: "pointer"}}>
                                        {userLevel(3)} : {levelCount[3] && levelCount[3].levelCount}
                                    </span>
                                    <span className="me-3" onClick={() => searchHandler(query, 10, 4)} style={{cursor: "pointer"}}>
                                        {userLevel(4)} : {levelCount[4] && levelCount[4].levelCount}
                                    </span>
                                    <span className="me-3" onClick={() => searchHandler(query, 10, 5)} style={{cursor: "pointer"}}>
                                        {userLevel(5)} : {levelCount[5] && levelCount[5].levelCount}
                                    </span>
                                </div>

                            </div>

                            <div className="col justify-content-end d-flex" style={{fontSize: "15px"}}>
                                <span className="me-3">전체회원보유머니 : <NumericFormat value={total && total[0].money_total} displayType={'text'} thousandSeparator={true} /></span>
                                <span>전체회원보유포인트 : <NumericFormat value={total && total[0].point_total} displayType={'text'} thousandSeparator={true} /></span>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between">
                            <div className="userStatus">
                                <button type="button" className="btn btn-success btn-sm me-1" onClick={() => {updateState(0, selectedNos)}}>대기</button>
                                <button type="button" className="btn btn-warning btn-sm me-1" onClick={() => {updateState(1, selectedNos)}}>요주의</button>
                                <button type="button" className="btn btn-outline-secondary btn-sm me-1" onClick={() => {updateState(2, selectedNos)}}>신규</button>
                                <button type="button" className="btn btn-outline-secondary btn-sm me-1" onClick={() => {updateState(3, selectedNos)}}>정상</button>
                                <button type="button" className="btn btn-info btn-sm me-1" onClick={() => {updateState(4, selectedNos)}}>성향</button>
                                <button type="button" className="btn btn-outline-danger btn-sm me-1" onClick={() => {updateState(5, selectedNos)}}>악성</button>
                                <button type="button" className="btn btn-primary btn-sm me-1" onClick={() => {updateState(6, selectedNos)}}>미이용</button>
                                <button type="button" className="btn btn-outline-secondary btn-sm me-1" onClick={() => {updateState(7, selectedNos)}}>휴면</button>
                                <button type="button" className="btn btn-secondary btn-sm me-1" onClick={() => {updateState(8, selectedNos)}}>탈퇴</button>
                                <button type="button" className="btn btn-dark btn-sm me-1" onClick={() => {updateState(9, selectedNos)}}>졸업</button>
                                <button type="button" className="btn btn-danger btn-sm me-1" onClick={() => {updateState(10, selectedNos)}}>블랙</button>
                            </div>

                        <div className="input-group align-items-end ms-4" style={{
                            width: "100px",
                            height: "40px",
                        }}>
                            <button className="btn btn-outline-secondary" type="button" style={{
                                width: "100px"}} onClick={handleClickOpen}>회원등록
                            </button>
                            <JoinComponent open={isOpen} onClose={handleClose} />


                        </div>

                        </div>

                    </div>
                    <div className="usert1" >
                        <table className="table table-bordered table-sm text-center align-middle" >
                            <thead className="table-dark">
                            <tr>
                                <th>
                                    <input className="form-check-input" type="checkbox" id="flexCheckDefault"
                                           checked={userList.length === selectedNos.length} onChange={allCheckHandler}
                                    />
                                </th>
                                <th>{sortUserType("user_type", userTypeSort)}</th>
                                <th>{sortCodeType("recommend_code", codeSort)}</th>
                                <th>{sortUserStateType("user_state", userStateSort)}</th>
                                <th>{sortLevelType("level", levelSort)}</th>
                                <th>회원ID</th>
                                <th>닉네임</th>
                                <th>예금주</th>
                                <th>{sortMoneyType("money", moneySort)}</th>
                                <th>{sortPointType("point", pointSort)}</th>
                                <th>{sortDepositType("deposit_total",depositSort)}</th>
                                <th>{sortWithdrawType("withdraw_total",withdrawSort)}</th>
                                <th>{sortTotalAmount("total_amount", totalAmountSort)}</th>
                                <th>{sortMonthAmount("month_amount", monthAmountSort)}</th>
                                <th>{sortLoginCount("login_count", loginCountSort)}</th>
                                <th style={{width: "6%"}}>{sortRecentlyDeposit("recently_deposit", recentlyDepositSort)}</th>
                                <th style={{width: "6%"}}>{sortRecentlyLogin("login_createdAt", recentlyLoginSort)}</th>
                                <th>{sortRecentlyDomain("login_domain", recentlyDomainSort)}</th>
                                <th>{sortCreatedAtType("createdAt", createdAtSort)}</th>
                                <th>관리자</th>
                            </tr>
                            </thead>

                            {!member.length && (
                                <tbody key={member.no}>
                                <tr>
                                    <td colSpan="20">목록이 비어있습니다.</td>
                                </tr>
                                </tbody>
                            )}

                            {member.map((member, index) => (
                                <tbody key={member.no}>
                                <tr className={newUserColor(member.user_state)} style={{height: "40px"}}>
                                    <td>
                                        <input className="form-check-input" type="checkbox"
                                               checked={selectedNos.filter((no) => no === member.no).length > 0}
                                               onChange={(e) => {checkHandler(e.currentTarget.checked, member.no)
                                               }}/>
                                    </td>
                                    <td>{typeCase.userType(member.user_type)}</td>
                                    <td>-</td>
                                    <td>{typeCase.userState(member.user_state)}</td>
                                    <td>{typeCase.levelState(member.level)}</td>
                                    <td>{member.id}</td>
                                    <td>
                                        <Link className="nav-link" to={"#userInformation" + index} data-bs-toggle="collapse"
                                              aria-expanded="false" aria-controls={"#userInformation" + index}
                                              onClick={informationOpenHandler}>

                                            {member.comment ?
                                                <>
                                                    <span>{member.nickname}</span>
                                                    <br></br>
                                                    <span style={{fontSize: "12px"}}>
                                                        <span className="me-1" style={{backgroundColor: onCommentColor(member.comment_color)}}>
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                        </span>
                                                        {member.comment}
                                                    </span>
                                                </>
                                                :  (member.comment_color !== null?
                                                    <>
                                                        <span>{member.nickname}</span>
                                                        <br></br>
                                                        <span className="me-1" style={{backgroundColor: onCommentColor(member.comment_color)}}>
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                        </span>
                                                    </>
                                                    :  <span>{member.nickname}</span> )}
                                        </Link>
                                    </td>
                                    <td>{member.name}</td>
                                    <td><NumericFormat value={member.money} displayType={'text'} thousandSeparator={true} /></td>
                                    <td><NumericFormat value={member.point} displayType={'text'} thousandSeparator={true} /></td>

                                    <td>
                                        <NumericFormat value={member.deposit_total? member.deposit_total : 0} displayType={'text'} thousandSeparator={true} />
                                    </td>
                                    <td>
                                        <NumericFormat value={member.withdraw_total? member.withdraw_total : 0} displayType={'text'} thousandSeparator={true} />
                                    </td>
                                    <td>{/*충환차액*/}
                                        <NumericFormat value={member.total_amount? member.total_amount : 0} displayType={'text'} thousandSeparator={true} />
                                    </td>
                                    <td>{/*이달수익*/}
                                        <NumericFormat value={member.month_amount? member.month_amount : 0} displayType={'text'} thousandSeparator={true} />
                                    </td>
                                    <td>{/*접속수*/}
                                        {member.login_count? member.login_count : 0}
                                    </td>
                                    <td>{/*최근충전*/}
                                        {member.recently_deposit?
                                            <>
                                                {moment(member.recently_deposit).format("YYYY-MM-DD HH:mm:ss")}
                                            </>: "-"}
                                    </td>
                                    <td>{/*최근접속일*/}
                                        {member.login_createdAt?
                                            <>
                                                {moment(member.login_createdAt).format("YYYY-MM-DD HH:mm:ss")}
                                            </>: "-"}
                                    </td>
                                    <td>{/*최근도메인*/}
                                        {member.login_domain? member.login_domain: "-"}
                                    </td>
                                    <td>{/*가입일*/}
                                        {moment(member.createdAt).format("YYYY-MM-DD")}
                                    </td>
                                    <td>관리자</td>

                                </tr>
                                <tr className="collapse" id={"userInformation" + index} key={member.id}>
                                    {userInformation && <UserInformation  no={member.no} id={member.id} /> }
                                </tr>
                                </tbody>
                            ))}


                        </table>

                    </div>

                </div>
            </div>
        </div>

    )

}


export default Member;