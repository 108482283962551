import {call, put} from "redux-saga/effects";
import {setMarketApiSettingInfoListReducer, failedGetMarketApiSettingInfoListReducer} from "../../reducers/match/marketApiSettingReducer";
import {fetchMarketApiSettingInfoList} from "../../../lib/api";

//마켓 API설정리스트
export function* getMarketApiSettingInfoListSaga(action) {
    try{
        console.log("##getMarketApiSettingInfoListSaga#")
        const res = yield call(fetchMarketApiSettingInfoList, action.payload.marketPage, action.payload.marketPageSize, action.payload.marketUseYn, action.payload.marketNm);
        yield put(setMarketApiSettingInfoListReducer(res.data))
    }
    catch (error) {
        console.log(error)
        yield put(failedGetMarketApiSettingInfoListReducer(error))
    }
}

