import React, {useEffect, useState} from 'react';
import moment from "moment";
import {NumericFormat} from 'react-number-format';
import axios from "axios";
import MemberLoginLog from "./../components/modal/MemberLoginLog";
import MemberDepositLog from "./../components/modal/MemberDepositLog";
import MemberWithdrawLog from "./../components/modal/MemberWithdrawLog";
import MemberMoneyLog from "./../components/modal/MemberMoneyLog";
import MemberPointLog from "./../components/modal/MemberPointLog";
import MemberMemo from "./../components/modal/MemberMemo";
import typeCase from "../util/typeCase"
const UserInformation = ({no, id}) => {

    const [user, setUser] = useState([]);
    const [recentlyLoginLog, setRecentlyLoginLog] = useState([])
    const [recently5, setRecently5] = useState([]);
    const [memoOpen, setMemoOpen] = useState(false);
    const [memberLoginLog, setMemberLoginLog] = useState(false);
    const [memberDepositLog, setMemberDepositLog] = useState(false);
    const [memberWithdrawLog, setMemberWithdrawLog] = useState(false);
    const [memberMoneyLog, setMemberMoneyLog] = useState(false);
    const [memberPointLog, setMemberPointLog] = useState(false);
    const [userNo, setUserNo] = useState(false)

    const [bank, setBank] = useState("");
    const [level, setLevel] = useState("");
    const [status, setStatus] = useState("");
    const [telecom, setTelecom] = useState("");
    const [comment, setComment] = useState("");
    const [commentColor, setCommentColor] = useState(10);

    const getFindUserList = () => {

        axios.post('/admin/member/get_member', {
            no: no,
            id: id
        }).then((res) => {
            setUser(res.data.findUser)
            setRecentlyLoginLog(res.data.recentlyLoginLog)
            //console.log(res.data.recentlyLoginLog)
            setRecently5(res.data.recently5)
        })
    }
    useEffect(getFindUserList, [])
    const memoOpenHandler = (open, userNo) => {
        setMemoOpen(open);
        setUserNo(userNo)
    }
    const memberLoginLogOpen = (open, userNo) => {
        setMemberLoginLog(open);
        setUserNo(userNo)
    }
    const memberDepositLogOpen = (open, userNo) => {
        setMemberDepositLog(open);
        setUserNo(userNo)
    }
    const memberWithdrawLogOpen = (open, userNo) => {
        setMemberWithdrawLog(open);
        setUserNo(userNo)
    }
    const memberMoneyLogOpen = (open, userNo) => {
        setMemberMoneyLog(open);
        setUserNo(userNo)
    }
    const memberPointLogOpen = (open, userNo) => {
        setMemberPointLog(open);
        setUserNo(userNo)
    }


    const bankList = [
        {value : "국민", name : "국민"},
        {value : "기업", name : "기업"},
        {value : "농협", name : "농협"},
        {value : "신한", name : "신한"},
        {value : "우리", name : "우리"},
        {value : "우체국", name : "우체국"},
        {value : "새마을금고", name : "새마을금고"},
        {value : "수협", name : "수협"},
        {value : "카카오뱅크", name : "카카오뱅크"},
        {value : "케이뱅크", name : "케이뱅크"},
        {value : "KEB하나", name : "KEB하나"},
        {value : "SC제일", name : "SC제일"},
        {value : "한국시티", name : "한국시티"},
        {value : "경남", name : "경남"},
        {value : "광주", name : "광주"},
        {value : "대구", name : "대구"},
        {value : "부산", name : "부산"},
        {value : "전북", name : "전북"},
        {value : "제주", name : "제주"},
    ]
    const onBankHandler = (e) => {
        setBank(e.currentTarget.value);
    }
    const levelList = [
        {value : "0", name : "1레벨"},
        {value : "1", name : "2레벨"},
        {value : "2", name : "3레벨"},
        {value : "3", name : "4레벨"},
        {value : "4", name : "5레벨"},
        {value : "5", name : "VIP"},
    ]
    const onLevelHandler = (e) => {
        setLevel(e.currentTarget.value);
    }
    const statusList = [
        {value : "0", name : "대기"},
        {value : "1", name : "신규"},
        {value : "2", name : "정상"},
        {value : "3", name : "요주의"},
        {value : "4", name : "악성"},
        {value : "5", name : "미이용"},
        {value : "6", name : "휴면"},
        {value : "7", name : "탈퇴"},
        {value : "8", name : "졸업"},
        {value : "9", name : "블랙"},
    ]
    const onStatusHandler = (e) => {
        setStatus(e.currentTarget.value);
    }
    const telecomList = [
        {value : "0", name : "SKT"},
        {value : "1", name : "KT"},
        {value : "2", name : "LG"},
        {value : "3", name : "알뜰SKT"},
        {value : "4", name : "알뜰KT"},
        {value : "5", name : "알뜰LG"},
    ]
    const onTelecomHandler = (e) => {
        setTelecom(e.currentTarget.value);
    }
    const onCommentHandler = (e) => {
        setComment(e.currentTarget.value)
    }

    const commentColorList = [
        {value : "10", name : "---color---"},
        {value : "0", name : "빨강", color: "red"},
        {value : "1", name : "주황", color: "orange"},
        {value : "2", name : "노랑", color: "yellow"},
        {value : "3", name : "초록", color: "green"},
        {value : "4", name : "파랑", color: "blue"},
        {value : "5", name : "남색", color: "navy"},
        {value : "6", name : "보라", color: "purple"},
    ]
    const onCommentColorHandler = (e) => {
        setCommentColor(e.currentTarget.value)
    }

    const updateCommentColor = (user_no, comment, commentColor) => {
        axios.post('/admin/member/comment_color', {
            user_no: user_no,
            comment: comment,
            comment_color: commentColor
        }).then((res) => {
            if(res.data.isUpdated) {
                alert("변경완료")
                window.location.reload();
            } else {
                alert("실패")
            }
        })
    }

    return (

        <td colSpan="30">
            <div className="container-fluid member_collapse">
                {/*특이사항*/}
                <div className="row border ">
                    <div className="col-1 my-auto ">
                        특이사항
                    </div>
                    <div className="col text-center d-flex border-start border-dark">
                        <div className="row gx-0" style={{
                            width: "100%"
                        }}>
                            <div className="col-10">
                                <input type="text" className="form-control border border-dark" maxLength="12" defaultValue={comment}
                                       placeholder="특이사항 메모(12글자)" style={{height: "100%"}} onChange={onCommentHandler}/>

                            </div>
                            <div className="col-1">
                                <select className="form-select border border-dark" onChange={onCommentColorHandler} defaultValue={commentColor}>
                                    {commentColorList.map((item, index) => (
                                        <option key={item.value} value={item.value} style={{backgroundColor: item.color}}>{item.name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-1">
                                <button className="btn btn-sm btn-info" onClick={() => updateCommentColor(user.no, comment, commentColor)}>특이사항 저장</button>
                            </div>
                        </div>
                    </div>
                </div>
                {/*가입정보*/}
                <div className="row border ">
                    <div className="col-1 my-auto ">
                        가입정보
                    </div>
                    <div className="col text-center d-flex border-start border-dark">
                        <div className="row gx-0" style={{
                            width: "17%"
                        }}>
                            <label className="col-4 my-auto">매장명</label>
                            <div className="col-8">
                                <input readOnly type="text" className="form-control" defaultValue="본사"
                                       style={{height: "100%", textAlign: "center"}}/>
                            </div>
                        </div>
                        <div className="row gx-0" style={{
                            width: "17%"
                        }}>
                            <label className="col-4 my-auto">매장코드</label>
                            <div className="col-8">
                                <input readOnly type="text" className="form-control" defaultValue="AA01"
                                       style={{height: "100%", textAlign: "center"}}/>
                            </div>
                        </div>
                        <div className="row gx-0" style={{
                            width: "17%"
                        }}>
                            <label className="col-4 my-auto">파트너ID</label>
                            <div className="col-8">
                                <input readOnly type="text" className="form-control" defaultValue="bonsa"
                                       style={{height: "100%", textAlign: "center"}}/>
                            </div>
                        </div>
                        <div className="row gx-0" style={{
                            width: "17%"
                        }}>
                            <label className="col-4 my-auto">가입코드</label>
                            <div className="col-8">
                                <input readOnly type="text" className="form-control" defaultValue="bonsa"
                                       style={{height: "100%", textAlign: "center"}}/>
                            </div>
                        </div>
                        <div className="row gx-0" style={{
                            width: "17%"
                        }}>
                            <label className="col-4 my-auto">가입일</label>
                            <div className="col-8">
                                <input readOnly type="text" className="form-control"
                                       value={user.createdAt}
                                       style={{height: "100%", textAlign: "center"}}/>
                            </div>
                        </div>
                        <div className="row gx-0" style={{
                            width: "17%"
                        }}>
                            <label className="col-4 my-auto">가입도메인</label>
                            <div className="col-8">
                                <input readOnly type="text" className="form-control" defaultValue="bonsa.com"
                                       style={{height: "100%", textAlign: "center"}}/>
                            </div>
                        </div>

                    </div>
                </div>
                {/*회원정보-아이디*/}
                <div className="row border ">
                    <div className="col-1 my-auto">
                        <button type="button" className="btn btn-primary btn-sm" onClick={() => memoOpenHandler("true", user.no)}>쪽지</button>
                        {memoOpen && <MemberMemo userNo={userNo} setMemoOpen={setMemoOpen} />}
                        <br></br>
                        <span style={{fontSize: "15px"}}>회원정보</span>
                        <br></br>
                        <button type="button" className="btn btn-danger btn-sm">로그아웃</button>
                    </div>
                    <div className="col border-start border-dark">
                        <div className="d-flex">
                            <div className="row gx-0" style={{
                                width: "17%"
                            }}>
                                <label className="col-4 my-auto">아이디</label>
                                <div className="col-8">
                                    <input readOnly type="text" className="form-control" defaultValue={user.id}
                                           style={{height: "100%", textAlign: "center"}}/>
                                </div>
                            </div>
                            <div className="row gx-0" style={{
                                width: "17%"
                            }}>
                                <label className="col-4 my-auto">닉네임</label>
                                <div className="col-8">
                                    <input readOnly type="text" className="form-control" defaultValue={user.nickname}
                                           style={{height: "100%", textAlign: "center"}}/>
                                </div>
                            </div>
                            <div className="row gx-0" style={{
                                width: "17%"
                            }}>
                                <label className="col-4 my-auto">예금주</label>
                                <div className="col-8">
                                    <input readOnly type="text" className="form-control" defaultValue={user.name}
                                           style={{height: "100%", textAlign: "center"}}/>
                                </div>
                            </div>
                            <div className="row gx-0" style={{
                                width: "17%"
                            }}>
                                <label className="col-4 my-auto">은행명</label>
                                <div className="col-8">
                                    <select className="form-select" onChange={onBankHandler} defaultValue={user.bank}>
                                        {bankList.map((item, index) => (
                                            <option key={item.value} defaultValue={item.value}>{item.name}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="row gx-0" style={{
                                width: "17%"
                            }}>
                                <label className="col-4 my-auto">계좌번호</label>
                                <div className="col-8">
                                    <input type="text" maxLength="20" className="form-control" defaultValue={user.withdraw_account}
                                           style={{height: "100%", textAlign: "center"}}/>
                                </div>
                            </div>
                            <div className="row gx-0" style={{
                                width: "17%"
                            }}>
                                <label className="col-4 my-auto">계좌변경</label>
                                <div className="col-8">
                                    <input type="text" maxLength="20" className="form-control" defaultValue={user.withdraw_account}
                                           style={{height: "100%", textAlign: "center"}}/>
                                </div>
                            </div>
                        </div>
                        {/*회원정보-비밀번호*/}
                        <div className="d-flex">
                            <div className="row gx-0" style={{
                                width: "17%"
                            }}>
                                <label className="col-4 my-auto">비밀번호</label>
                                <div className="col-8">
                                    <input type="text" className="form-control" defaultValue=""
                                           style={{height: "100%", textAlign: "center"}}/>
                                </div>
                            </div>
                            <div className="row gx-0" style={{
                                width: "17%"
                            }}>
                                <label className="col-4 my-auto">환전비밀번호</label>
                                <div className="col-8">
                                    <input type="text" className="form-control" defaultValue={user.bank_pw}
                                           style={{height: "100%", textAlign: "center"}}/>
                                </div>
                            </div>
                            <div className="row gx-0" style={{
                                width: "17%"
                            }}>
                                <label className="col-4 my-auto">등급</label>
                                <div className="col-8">
                                    <select className="form-select" onChange={onLevelHandler} defaultValue={user.level}>
                                        {levelList.map((item, index) => (
                                            <option key={item.value} defaultValue={item.value}>{item.name}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="row gx-0" style={{
                                width: "17%"
                            }}>
                                <label className="col-4 my-auto">상태</label>
                                <div className="col">
                                    <select className="form-select" onChange={onStatusHandler} defaultValue={user.status} >
                                        {statusList.map((item, index) => (
                                            <option key={item.value} defaultValue={item.value}>{item.name}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="row gx-0" style={{
                                width: "22.5%"
                            }}>
                                <label className="col-3 my-auto">전화번호</label>
                                <div className="col-3">
                                    <select className="form-select" onChange={onTelecomHandler} defaultValue={user.telecom}>
                                        {telecomList.map((item, index) => (
                                            <option key={item.value} defaultValue={item.value}>{item.name}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="col">
                                    <input type="text" className="form-control" defaultValue={user.phone}/>
                                </div>
                            </div>
                            <div className="row gx-0" style={{
                                width: "11.5%"
                            }}>
                                <label className="col-4 my-auto">생년월일</label>
                                <div className="col-8">
                                    <input type="text" className="form-control" defaultValue={user.birth} />
                                </div>
                            </div>
                        </div>
                        {/*회원정보-추천인코드*/}
                        <div className="d-flex">
                            <div className="row gx-0" style={{
                                width: "17%"
                            }}>
                                <label className="col-4 my-auto">추천인코드</label>
                                <div className="col-8">
                                    <input type="text" className="form-control" defaultValue={user.recommend_code}
                                           style={{height: "100%", textAlign: "center"}}/>
                                </div>
                            </div>
                            <div className="row gx-0" style={{
                                width: "17%"
                            }}>
                                <label className="col-4 my-auto">접속수</label>
                                <div className="col-8">
                                    <input readOnly type="text" className="form-control"
                                           value={user.login_count? user.login_count : 0}
                                           style={{height: "100%", textAlign: "center"}}/>
                                </div>
                            </div>
                            <div className="row gx-0" style={{
                                width: "17%"
                            }}>
                                <label className="col-4 my-auto">최근접속일</label>
                                <div className="col-8">
                                    {recentlyLoginLog && recentlyLoginLog.length > 0 ?
                                    <input readOnly type="text" className="form-control"
                                           value={moment(recentlyLoginLog[0].createdAt).format("YYYY-MM-DD HH:mm:ss")}
                                           style={{fontSize:"12px", height: "100%", textAlign: "center"}}/>
                                        : "-"}
                                </div>
                            </div>
                            <div className="row gx-0" style={{
                                width: "17%"
                            }}>
                                <label className="col-4 my-auto">최근도메인</label>
                                <div className="col-8">
                                    {recentlyLoginLog && recentlyLoginLog.length > 0 ?
                                        <input readOnly type="text" className="form-control"
                                               value={recentlyLoginLog[0].domain}
                                               style={{fontSize:"12px", height: "100%", textAlign: "center"}}/>
                                        : "-"}
                                </div>
                            </div>
                            <div className="row gx-0" style={{
                                width: "17%"
                            }}>
                                <label className="col-4 my-auto">최근 아이피</label>
                                <div className="col-8">
                                    {recentlyLoginLog && recentlyLoginLog.length > 0 ?
                                        <input readOnly type="text" className="form-control"
                                               value={recentlyLoginLog[0].ip}
                                               style={{fontSize:"12px", height: "100%", textAlign: "center"}}/>
                                        : "-"}
                                </div>
                            </div>

                            <div className="row gx-0" style={{
                                width: "17%",
                            }}>
                                <button className="my-auto btn btn-success btn-sm" onClick={()=> memberLoginLogOpen("true", user.no)} >접속기록</button>
                                {memberLoginLog && <MemberLoginLog userNo={userNo} setMemberLoginLog={setMemberLoginLog}/>}
                            </div>
                        </div>
                    </div>
                </div>
                {/*가입승인메모*/}
                <div className="row border ">
                    <div className="col-1 my-auto " style={{fontSize: "11px"}}>
                        가입 승인 메모
                    </div>
                    <div className="col text-center border-start border-dark">
                        <div className="d-flex">
                            <div className="row gx-0 my-auto" style={{
                                width: "40%"
                            }}>
                                <div className="col my-auto">가입경로</div>
                                <div className="col my-auto">
                                    <input className="form-check-input border border-dark me-1" type="radio"
                                           name="flexRadioDefault" defaultChecked=""/>
                                    <label className="form-check-label">
                                        <span style={{fontSize: "13px"}}>파트너</span>
                                    </label>
                                </div>
                                <div className="col my-auto">
                                    <input className="form-check-input border border-dark me-1" type="radio"
                                           name="flexRadioDefault" defaultChecked="" />
                                    <label className="form-check-label ">
                                        <span style={{fontSize: "13px"}}>회원</span>
                                    </label>
                                </div>
                                <input type="text" className="form-control" defaultValue="" style={{
                                    width: "200px",
                                    height: "30px"
                                }}/>
                            </div>
                            <div className="row gx-0 my-auto" style={{
                                width: "25%"
                            }}>
                                <label className="col-4 my-auto">본인인증</label>
                                <div className="col-8">
                                    <input type="text" className="form-control" defaultValue="" />
                                </div>
                            </div>
                            <div className="row gx-0 my-auto" style={{
                                width: "25%"
                            }}>
                                <label className="col my-auto">이용종목</label>
                                <div className="col">
                                    <input type="text" className="form-control" defaultValue="" />
                                </div>
                            </div>
                            <div className="row gx-0" style={{
                                width: "10%"
                            }}>
                                <button className="my-auto btn btn-primary btn-sm">메모</button>
                            </div>
                        </div>

                        <div className="d-flex">
                            <div className="row gx-0 my-auto" style={{
                                width: "40%"
                            }}>
                                <label className="col-2 my-auto">정보조회</label>
                                <div className="col-10">
                                    <textarea type="text" className="form-control" defaultValue="" rows="4" />
                                </div>
                            </div>
                            <div className="row gx-0 my-auto" style={{
                                width: "60%"
                            }}>
                                <label className="col-1 my-auto">메모</label>
                                <div className="col-11">
                                    <textarea type="text" className="form-control" defaultValue="" rows="4" />
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
                {/*배팅현황*/}
                <div className="row border">
                    <div className="col-1 my-auto">
                        배팅현황
                    </div>
                    <div className="col-11 justify-content-between d-flex border-start border-dark">
                        <div className="row gx-0 my-auto">
                            <div className="d-flex ">
                                <button className="btn btn-outline-success btn-sm mx-1">배팅전체</button>
                                <button className="btn btn-outline-primary btn-sm mx-1">진행배팅</button>
                                <button className="btn btn-outline-primary btn-sm mx-1">배팅완료</button>
                                <button className="btn btn-outline-primary btn-sm mx-1">당첨</button>
                                <button className="btn btn-outline-primary btn-sm mx-1">미당첨</button>
                                <button className="btn btn-outline-secondary btn-sm mx-1">배팅취소</button>
                                <button className="btn btn-outline-secondary btn-sm mx-1">세부내역</button>
                            </div>
                        </div>
                        <div className="row gx-0">
                            <div className="d-flex my-auto">
                                <span className="text-primary my-auto">[단폴더 : 1회]</span>
                                <span className="mx-1 my-auto">|</span>
                                <span className="text-primary my-auto">[2폴더 : 0회]</span>
                                <span className="mx-1 my-auto">|</span>
                                <span className="text-primary my-auto">[3폴더 : 0회]</span>
                                <span className="mx-1 my-auto">|</span>
                                <span className="text-primary my-auto">[4폴더이상 : 0회]</span>
                                <span className="mx-1 my-auto">|</span>
                                <span className="text-primary my-auto">[<span className="text-success">미니게임</span> : 1회]</span>
                                <span className="mx-1 my-auto">|</span>
                                <span className="text-primary my-auto">[<span className="text-success">로투스</span> : 1회]</span>
                                <span className="mx-1 my-auto">|</span>
                                <span className="text-primary my-auto">[<span className="text-success">가상게임</span> : 1회]</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row border">
                    <div className="col-1 my-auto">
                        보유현황
                    </div>
                    <div className="col text-center d-flex border-start border-dark">
                        <div className="row gx-0" style={{
                            width: "17%"
                        }}>
                            <label className="col-4 my-auto">보유머니</label>
                            <div className="col-8 my-auto">
                                <NumericFormat value={user.money} displayType={'text'} thousandSeparator={true} />
                            </div>
                        </div>
                        <div className="row gx-0" style={{
                            width: "17%"
                        }}>
                            <label className="col-4 my-auto">포인트</label>
                            <div className="col-8 my-auto">
                                <NumericFormat value={user.point} displayType={'text'} thousandSeparator={true}/>

                            </div>
                        </div>

                        <div className="row gx-0" style={{
                            width: "17%"
                        }}>
                            <label className="col-4 my-auto">충전</label>
                            <div className="col-8 my-auto">
                                <NumericFormat value={user && user.deposit_total} displayType={'text'} thousandSeparator={true}/>
                            </div>
                        </div>

                        <div className="row gx-0" style={{
                            width: "17%"
                        }}>
                            <label className="col-4 my-auto">환전</label>
                            <div className="col-8 my-auto">
                                <NumericFormat value={user && user.withdraw_total} displayType={'text'} thousandSeparator={true}/>
                            </div>
                        </div>

                        <div className="row gx-0" style={{
                            width: "17%"
                        }}>
                            <label className="col-4 my-auto">정산</label>
                            <div className="col-8 my-auto">
                                <NumericFormat value={user && user.total_amount} displayType={'text'} thousandSeparator={true}/>
                            </div>
                        </div>
                        <div className="row gx-0 " style={{
                            width: "17%",
                        }}>
                            <div className="d-flex">
                                <button className="my-auto btn btn-sm btn-primary w-50" onClick={() => memberDepositLogOpen("true", user.no)} >입금내역</button>
                                {memberDepositLog && <MemberDepositLog userNo={userNo} setMemberDepositLog={setMemberDepositLog}/>}

                                <button className="btn btn-sm btn-danger w-50" onClick={() => memberWithdrawLogOpen("true", user.no)}>출금내역</button>
                                {memberWithdrawLog && <MemberWithdrawLog userNo={userNo} setMemberWithdrawLog={setMemberWithdrawLog}/>}
                            </div>
                            <div className="d-flex">
                                <button className="btn btn-sm btn-warning w-50" onClick={() => memberMoneyLogOpen("true", user.no)}>머니로그</button>
                                {memberMoneyLog && <MemberMoneyLog userNo={userNo} setMemberMoneyLog={setMemberMoneyLog}/>}

                                <button className="btn btn-sm btn-success w-50" onClick={() => memberPointLogOpen("true", user.no)}>포인트로그</button>
                                {memberPointLog && <MemberPointLog userNo={userNo} setMemberPointLog={setMemberPointLog}/>}
                            </div>
                        </div>
                    </div>
                </div>
                {/*종목별추천*/}
                <div className="row border">
                    <div className="col-1 my-auto">
                        종목별 충전
                    </div>
                    <div className="col text-center d-flex border-start border-dark">
                        <div className="row gx-0" style={{
                            width: "20%"
                        }}>
                            <label className="col-4 my-auto">스포츠충전</label>
                            <div className="col-8">
                                <input readOnly type="text" className="form-control" defaultValue="120,000"
                                       style={{height: "100%", textAlign: "center"}}/>
                            </div>
                        </div>
                        <div className="row gx-0" style={{
                            width: "20%"
                        }}>
                            <label className="col-4 my-auto">미니게임충전</label>
                            <div className="col-8">
                                <input readOnly type="text" className="form-control" defaultValue="120,000"
                                       style={{height: "100%", textAlign: "center"}}/>
                            </div>
                        </div>
                        <div className="row gx-0" style={{
                            width: "20%"
                        }}>
                            <label className="col-4 my-auto">카지노충전</label>
                            <div className="col-8">
                                <input readOnly type="text" className="form-control" defaultValue="120,000"
                                       style={{height: "100%", textAlign: "center"}}/>
                            </div>
                        </div>
                        <div className="row gx-0" style={{
                            width: "20%"
                        }}>
                            <label className="col-4 my-auto">최근충전</label>
                            <div className="col-8">
                                <input readOnly type="text" className="form-control" defaultValue="120,000"
                                       style={{height: "100%", textAlign: "center"}}/>
                            </div>
                        </div>
                        <div className="row gx-0" style={{
                            width: "20%"
                        }}>
                            <label className="col-4 my-auto">마지막이용</label>
                            <div className="col-8">
                                <p className="my-auto text-success mx-1">미니게임 <br></br>2021-10-11 15:00</p>
                            </div>
                        </div>

                    </div>
                </div>
                {/*스포츠배팅*/}
                <div className="row border">
                    <div className="col-1 my-auto">
                        스포츠배팅
                    </div>
                    <div className="col text-center d-flex border-start border-dark">
                        <div className="row gx-0" style={{
                            width: "20%"
                        }}>
                            <label className="col-4 my-auto">스포츠충전</label>
                            <div className="col-8">
                                <input readOnly type="text" className="form-control" defaultValue="120,000"
                                       style={{height: "100%", textAlign: "center"}}/>
                            </div>
                        </div>
                        <div className="row gx-0" style={{
                            width: "20%"
                        }}>
                            <label className="col-4 my-auto">스포츠당첨</label>
                            <div className="col-8">
                                <input readOnly type="text" className="form-control" defaultValue="120,000"
                                       style={{height: "100%", textAlign: "center"}}/>
                            </div>
                        </div>
                        <div className="row gx-0" style={{
                            width: "20%"
                        }}>
                            <label className="col-4 my-auto">스포츠낙첨</label>
                            <div className="col-8">
                                <input readOnly type="text" className="form-control" defaultValue="120,000"
                                       style={{height: "100%", textAlign: "center"}}/>
                            </div>
                        </div>
                        <div className="row gx-0" style={{
                            width: "20%"
                        }}>
                            <label className="col-4 my-auto">스포츠정산</label>
                            <div className="col-8">
                                <input readOnly type="text" className="form-control" defaultValue="120,000"
                                       style={{height: "100%", textAlign: "center"}}/>
                            </div>
                        </div>
                        <div className="row gx-0" style={{
                            width: "20%"
                        }}>
                            <label className="col-4 my-auto">스포츠승률</label>
                            <div className="col-8">
                                <input readOnly type="text" className="form-control" defaultValue="120,000"
                                       style={{height: "100%", textAlign: "center"}}/>
                            </div>
                        </div>
                    </div>
                </div>
                {/*미니게임배팅*/}
                <div className="row border">
                    <div className="col-1 my-auto">
                        미니게임배팅
                    </div>
                    <div className="col text-center d-flex border-start border-dark">
                        <div className="row gx-0" style={{
                            width: "20%"
                        }}>
                            <label className="col-4 my-auto">미니게임충전</label>
                            <div className="col-8">
                                <input readOnly type="text" className="form-control" defaultValue="120,000"
                                       style={{height: "100%", textAlign: "center"}}/>
                            </div>
                        </div>
                        <div className="row gx-0" style={{
                            width: "20%"
                        }}>
                            <label className="col-4 my-auto">미니게임당첨</label>
                            <div className="col-8">
                                <input readOnly type="text" className="form-control" defaultValue="120,000"
                                       style={{height: "100%", textAlign: "center"}}/>
                            </div>
                        </div>
                        <div className="row gx-0" style={{
                            width: "20%"
                        }}>
                            <label className="col-4 my-auto">미니게임낙첨</label>
                            <div className="col-8">
                                <input readOnly type="text" className="form-control" defaultValue="120,000"
                                       style={{height: "100%", textAlign: "center"}}/>
                            </div>
                        </div>
                        <div className="row gx-0" style={{
                            width: "20%"
                        }}>
                            <label className="col-4 my-auto">미니게임정산</label>
                            <div className="col-8">
                                <input readOnly type="text" className="form-control" defaultValue="120,000"
                                       style={{height: "100%", textAlign: "center"}}/>
                            </div>
                        </div>
                        <div className="row gx-0" style={{
                            width: "20%"
                        }}>
                            <label className="col-4 my-auto">미니게임승률</label>
                            <div className="col-8">
                                <input readOnly type="text" className="form-control" defaultValue="120,000"
                                       style={{height: "100%", textAlign: "center"}}/>
                            </div>
                        </div>
                    </div>
                </div>
                {/*카지노*/}
                <div className="row border">
                    <div className="col-1 my-auto">
                        카지노
                    </div>
                    <div className="col text-center d-flex border-start border-dark">
                        <div className="row gx-0" style={{
                            width: "20%"
                        }}>
                            <label className="col-4 my-auto">카지노충전</label>
                            <div className="col-8">
                                <input readOnly type="text" className="form-control" defaultValue="120,000"
                                       style={{height: "100%", textAlign: "center"}}/>
                            </div>
                        </div>
                        <div className="row gx-0" style={{
                            width: "20%"
                        }}>
                            <label className="col-4 my-auto">카지노당첨</label>
                            <div className="col-8">
                                <input readOnly type="text" className="form-control" defaultValue="120,000"
                                       style={{height: "100%", textAlign: "center"}}/>
                            </div>
                        </div>
                        <div className="row gx-0" style={{
                            width: "20%"
                        }}>
                            <label className="col-4 my-auto">카지노낙첨</label>
                            <div className="col-8">
                                <input readOnly type="text" className="form-control" defaultValue="120,000"
                                       style={{height: "100%", textAlign: "center"}}/>
                            </div>
                        </div>
                        <div className="row gx-0" style={{
                            width: "20%"
                        }}>
                            <label className="col-4 my-auto">카지노정산</label>
                            <div className="col-8">
                                <input readOnly type="text" className="form-control" defaultValue="120,000"
                                       style={{height: "100%", textAlign: "center"}}/>
                            </div>
                        </div>
                        <div className="row gx-0" style={{
                            width: "20%"
                        }}>
                            <label className="col-4 my-auto">카지노승률</label>
                            <div className="col-8">
                                <input readOnly type="text" className="form-control" defaultValue="120,000"
                                       style={{height: "100%", textAlign: "center"}}/>
                            </div>
                        </div>
                    </div>
                </div>
                {/*이용차단*/}
                <div className="row border">
                    <div className="col-1 my-auto">
                        이용차단
                    </div>
                    <div className="col text-center d-flex border-start border-dark">
                        <div className="row gx-0" style={{
                            width: "20%"
                        }}>
                            <div className="d-flex justify-content-between ">

                                <input className="my-auto form-check-input" type="checkbox" defaultChecked=""/>
                                <label className="my-auto form-label mx-1">전체</label>

                                <input className="my-auto form-check-input ms-1" type="checkbox" defaultChecked=""/>
                                <label className="my-auto form-label text-secondary mx-1">고객센터</label>

                                <input className="my-auto form-check-input ms-1" type="checkbox" defaultChecked=""/>
                                <label className="my-auto form-label text-secondary mx-1">스포츠</label>

                                <input className="my-auto form-check-input ms-1" type="checkbox" defaultChecked=""/>
                                <label className="my-auto form-label text-secondary mx-1">카지노</label>

                            </div>
                        </div>
                        <div className="row gx-0 border-start border-dark" style={{
                            width: "20%"
                        }}>
                            <div className="d-flex justify-content-between ">
                                <label className="my-auto form-label mx-2">크로스</label>

                                <input className="my-auto form-check-input" type="checkbox" defaultChecked=""/>
                                <label className="my-auto form-label mx-1">단폴더</label>

                                <input className="my-auto form-check-input" type="checkbox" defaultChecked=""/>
                                <label className="my-auto form-label mx-1">두폴더</label>

                                <input className="my-auto form-check-input" type="checkbox" defaultChecked=""/>
                                <label className="my-auto form-label mx-1">세폴더</label>
                            </div>
                        </div>
                        <div className="row gx-0 border-start border-dark" style={{
                            width: "20%"
                        }}>
                            <div className="d-flex justify-content-between ">
                                <label className="my-auto form-label mx-2">스페셜</label>

                                <input className="my-auto form-check-input" type="checkbox" defaultChecked=""/>
                                <label className="my-auto form-label mx-1">단폴더</label>

                                <input className="my-auto form-check-input" type="checkbox" defaultChecked=""/>
                                <label className="my-auto form-label mx-1">두폴더</label>

                                <input className="my-auto form-check-input" type="checkbox" defaultChecked=""/>
                                <label className="my-auto form-label mx-1">세폴더</label>
                            </div>
                        </div>
                        <div className="row gx-0 border-start border-dark" style={{
                            width: "20%"
                        }}>
                            <div className="d-flex justify-content-between ">
                                <label className="my-auto form-label mx-2">라이브</label>

                                <input className="my-auto form-check-input" type="checkbox" defaultChecked=""/>
                                <label className="my-auto form-label mx-1">단폴더</label>

                                <input className="my-auto form-check-input" type="checkbox" defaultChecked=""/>
                                <label className="my-auto form-label mx-1">두폴더</label>

                                <input className="my-auto form-check-input" type="checkbox" defaultChecked=""/>
                                <label className="my-auto form-label mx-1">세폴더</label>
                            </div>
                        </div>
                        <div className="row gx-0 border-start border-dark" style={{
                            width: "20%"
                        }}>
                            <label className="col-4 my-auto form-label ">스포츠배당</label>
                            <div className="col-8">
                                <input type="text" className="form-control"
                                       style={{height: "100%", textAlign: "center"}}/>
                            </div>
                        </div>
                    </div>
                </div>
                {/*미니게임차단*/}
                <div className="row border">
                    <div className="col-1 my-auto">
                        미니게임차단
                    </div>
                    <div className="col text-center d-flex border-start border-dark">
                        <div className="row gx-0" style={{
                            width: "100%"
                        }}>
                            <div className="d-flex my-auto">
                                <input className="form-check-input" type="checkbox" defaultChecked=""/>
                                <label className="form-label text-secondary mx-1">파워볼</label>
                                <input className="form-check-input ms-4" type="checkbox" defaultChecked=""/>
                                <label className="form-label text-secondary mx-1">BET365가상축구</label>
                                <input className="form-check-input ms-4" type="checkbox" defaultChecked=""/>
                                <label className="form-label text-secondary mx-1">파워사다리</label>
                                <input className="form-check-input ms-4" type="checkbox" defaultChecked=""/>
                                <label className="form-label text-secondary mx-1">로투스홀짝</label>
                                <input className="form-check-input ms-4" type="checkbox" defaultChecked=""/>
                                <label className="form-label text-secondary mx-1">로투스바카라</label>
                                <input className="form-check-input ms-4" type="checkbox" defaultChecked=""/>
                                <label className="form-label text-secondary mx-1">로투스바카라II</label>
                                <input className="form-check-input ms-4" type="checkbox" defaultChecked=""/>
                                <label className="form-label text-secondary mx-1">드래곤타이거</label>
                                <input className="form-check-input ms-4" type="checkbox" defaultChecked=""/>
                                <label className="form-label text-secondary mx-1">오리지널용호</label>
                                <input className="form-check-input ms-4" type="checkbox" defaultChecked=""/>
                                <label className="form-label text-secondary mx-1">룰렛</label>
                                <input className="form-check-input ms-4" type="checkbox" defaultChecked=""/>
                                <label className="form-label text-secondary mx-1">홀덤</label>
                            </div>
                            <div className="d-flex my-auto">

                                <input className="form-check-input" type="checkbox" defaultChecked=""/>
                                <label className="form-label text-secondary mx-1">로투스사다리</label>
                                <input className="form-check-input ms-4" type="checkbox" defaultChecked=""/>
                                <label className="form-label text-secondary mx-1">BET365개경주</label>
                                <input className="form-check-input ms-4" type="checkbox" defaultChecked=""/>
                                <label className="form-label text-secondary mx-1">로투스파워볼</label>
                                <input className="form-check-input ms-4" type="checkbox" defaultChecked=""/>
                                <label className="form-label text-secondary mx-1">뱃페어가상축구</label>
                                <input className="form-check-input ms-4" type="checkbox" defaultChecked=""/>
                                <label className="form-label text-secondary mx-1">BET365경마</label>
                            </div>
                        </div>
                    </div>
                </div>
                {/*개별배팅상한*/}
                <div className="row border">
                    <div className="col-1 my-auto">
                        개별배팅상한
                    </div>
                    <div className="col text-center d-flex border-start border-dark">
                        <div className="row gx-0" style={{
                            width: "16%"
                        }}>
                            <label className="col-4 my-auto">단폴더</label>
                            <div className="col-8">
                                <input type="text" className="form-control" defaultValue=""
                                       style={{height: "100%", textAlign: "center"}}/>
                            </div>
                        </div>
                        <div className="row gx-0" style={{
                            width: "16%"
                        }}>
                            <label className="col-4 my-auto">두폴더</label>
                            <div className="col-8">
                                <input type="text" className="form-control" defaultValue=""
                                       style={{height: "100%", textAlign: "center"}}/>
                            </div>
                        </div>
                        <div className="row gx-0" style={{
                            width: "16%"
                        }}>
                            <label className="col-4 my-auto">세폴더</label>
                            <div className="col-8">
                                <input type="text" className="form-control" defaultValue=""
                                       style={{height: "100%", textAlign: "center"}}/>
                            </div>
                        </div>
                        <div className="row gx-0" style={{
                            width: "16%"
                        }}>
                            <label className="col-4 my-auto">스포츠</label>
                            <div className="col-8">
                                <input type="text" className="form-control" defaultValue=""
                                       style={{height: "100%", textAlign: "center"}}/>
                            </div>
                        </div>
                        <div className="row gx-0" style={{
                            width: "16%"
                        }}>
                            <label className="col-4 my-auto">스페셜</label>
                            <div className="col-8">
                                <input type="text" className="form-control" defaultValue=""
                                       style={{height: "100%", textAlign: "center"}}/>
                            </div>
                        </div>
                        <div className="row gx-0" style={{
                            width: "16%"
                        }}>
                            <label className="col-4 my-auto">라이브</label>
                            <div className="col-8">
                                <input type="text" className="form-control" defaultValue=""
                                       style={{height: "100%", textAlign: "center"}}/>
                            </div>
                        </div>
                        <div className="row gx-0" style={{
                            width: "16%"
                        }}>
                            <label className="col-4 my-auto">미니게임</label>
                            <div className="col-8">
                                <input type="text" className="form-control" defaultValue=""
                                       style={{height: "100%", textAlign: "center"}}/>
                            </div>
                        </div>
                    </div>
                </div>
                {/*개별당청상한*/}
                <div className="row border">
                    <div className="col-1 my-auto">
                        개별당첨상한
                    </div>
                    <div className="col text-center d-flex border-start border-dark">
                        <div className="row gx-0" style={{
                            width: "16%"
                        }}>
                            <label className="col-4 my-auto">단폴더</label>
                            <div className="col-8">
                                <input type="text" className="form-control" defaultValue=""
                                       style={{height: "100%", textAlign: "center"}}/>
                            </div>
                        </div>
                        <div className="row gx-0" style={{
                            width: "16%"
                        }}>
                            <label className="col-4 my-auto">두폴더</label>
                            <div className="col-8">
                                <input type="text" className="form-control" defaultValue=""
                                       style={{height: "100%", textAlign: "center"}}/>
                            </div>
                        </div>
                        <div className="row gx-0" style={{
                            width: "16%"
                        }}>
                            <label className="col-4 my-auto">세폴더</label>
                            <div className="col-8">
                                <input type="text" className="form-control" defaultValue=""
                                       style={{height: "100%", textAlign: "center"}}/>
                            </div>
                        </div>
                        <div className="row gx-0" style={{
                            width: "16%"
                        }}>
                            <label className="col-4 my-auto">스포츠</label>
                            <div className="col-8">
                                <input type="text" className="form-control" defaultValue=""
                                       style={{height: "100%", textAlign: "center"}}/>
                            </div>
                        </div>
                        <div className="row gx-0" style={{
                            width: "16%"
                        }}>
                            <label className="col-4 my-auto">스페셜</label>
                            <div className="col-8">
                                <input type="text" className="form-control" defaultValue=""
                                       style={{height: "100%", textAlign: "center"}}/>
                            </div>
                        </div>
                        <div className="row gx-0" style={{
                            width: "16%"
                        }}>
                            <label className="col-4 my-auto">라이브</label>
                            <div className="col-8">
                                <input type="text" className="form-control" defaultValue=""
                                       style={{height: "100%", textAlign: "center"}}/>
                            </div>
                        </div>
                        <div className="row gx-0" style={{
                            width: "16%"
                        }}>
                            <label className="col-4 my-auto">미니게임</label>
                            <div className="col-8">
                                <input type="text" className="form-control" defaultValue=""
                                       style={{height: "100%", textAlign: "center"}}/>
                            </div>
                        </div>
                    </div>
                </div>
                {/*개별충전*/}
                <div className="row border">
                    <div className="col-1 my-auto">
                        개별 충전
                    </div>
                    <div className="col text-center d-flex border-start border-dark">
                        <div className="row gx-0" style={{
                            width: "25%"
                        }}>
                            <div className="d-flex justify-content-center ">
                                <input className="my-auto form-check-input" type="checkbox" defaultChecked=""/>
                                <label className="my-auto form-label mx-1">지급</label>
                                <input className="my-auto form-check-input" type="checkbox" defaultChecked=""/>
                                <label className="my-auto form-label text-secondary mx-1">미지급</label>

                                <input className="my-auto form-check-input" type="checkbox" defaultChecked=""/>
                                <label className="my-auto form-label mx-1">회차사용</label>
                                <input type="text" className="form-control " defaultValue=""
                                       style={{width: "50px", height: "100%", textAlign: "center"}}/>
                                <label className="my-auto form-label mx-1">회</label>
                            </div>
                        </div>
                        <div className="row gx-0" style={{
                            width: "60%"
                        }}>
                            <div className="d-flex justify-content-center ">
                                <input className="my-auto form-check-input" type="checkbox" defaultChecked=""/>
                                <label className="my-auto form-label me-2">기간사용</label>
                                <input type="date" className="form-control" defaultValue=""
                                       style={{width: "20%", height: "100%", textAlign: "center"}}/>
                                <input type="time" className="form-control" defaultValue=""
                                       style={{width: "20%", height: "100%", textAlign: "center"}}/>
                                <span className="mx-1 my-auto">~</span>
                                <input type="date" className="form-control" defaultValue=""
                                       style={{width: "20%", height: "100%", textAlign: "center"}}/>
                                <input type="time" className="form-control" defaultValue=""
                                       style={{width: "20%", height: "100%", textAlign: "center"}}/>
                            </div>
                        </div>
                        <div className="row gx-0" style={{
                            width: "15%"
                        }}>
                            <div className="d-flex justify-content-center ">
                                <label className="my-auto form-label ms-2">충전</label>
                                <input type="text" className="form-control ms-1" defaultValue="0"
                                       style={{width: "60px", height: "100%", textAlign: "center"}}/>
                                <span className="mx-1 my-auto">%</span>
                            </div>
                        </div>
                    </div>
                </div>
                {/*개별전용계좌*/}
                <div className="row border">
                    <div className="col-1 my-auto">
                        개별전용계좌
                    </div>
                    <div className="col text-center d-flex border-start border-dark">
                        <div className="row gx-0" style={{
                            width: "15%"
                        }}>
                            <div className="d-flex justify-content-center">
                                <input className="my-auto form-check-input" type="checkbox" defaultChecked=""/>
                                <label className="my-auto form-label mx-1">사용</label>
                                <input className="my-auto form-check-input ms-1" type="checkbox" defaultChecked=""/>
                                <label className="my-auto form-label text-secondary mx-1">미사용</label>
                            </div>
                        </div>

                        <div className="row gx-0" style={{
                            width: "25%"
                        }}>
                            <div className="d-flex justify-content-center">
                                <label className="my-auto form-label ms-5">예금주</label>
                                <input type="text" className="form-control ms-1" defaultValue="홍길동" style={{
                                    width: "150px",
                                    height: "30px"
                                }} />
                            </div>
                        </div>
                        <div className="row gx-0" style={{
                            width: "30%"
                        }}>
                            <div className="d-flex justify-content-center">
                                <label className="my-auto form-label ms-5">은행명</label>
                                <input type="text" className="form-control ms-1" defaultValue="하나은행" style={{
                                    width: "150px",
                                    height: "30px"
                                }} />
                            </div>
                        </div>
                        <div className="row gx-0" style={{
                            width: "30%"
                        }}>
                            <div className="d-flex justify-content-center">
                                <label className="my-auto form-label ms-5">계좌번호</label>
                                <input type="text" className="form-control ms-1" defaultValue="123451235123213" style={{
                                    width: "300px",
                                    height: "30px"
                                }} />
                            </div>
                        </div>
                    </div>
                </div>
                {/*추천인*/}
                <div className="row border">
                    <div className="col-1 my-auto">
                        추천인
                    </div>
                    <div className="col text-center d-flex border-start border-dark">
                        <div className="row gx-0" style={{
                            width: "25%"
                        }}>
                            <div className="col my-auto">
                                <input className="form-check-input" type="radio" defaultChecked=""
                                       name="flexRadioDefault" id="flexRadioDefault1" />
                                <label className="form-check-label"
                                       htmlFor="flexRadioDefault21">
                                    가능
                                </label>
                            </div>
                            <div className="col my-auto">
                                <input className="form-check-input me-1" type="radio"
                                       name="flexRadio44" id="flexRad22" defaultChecked="" />
                                <label className="form-check-label"
                                       htmlFor="flexRad22">
                                    불가능
                                </label>
                            </div>
                            <label className="col my-auto">추천인아이디</label>
                            <div className="col">
                                <input readOnly type="text" className="form-control" defaultValue="118"
                                       style={{height: "100%", textAlign: "center"}}/>
                            </div>

                        </div>

                        <div className="row gx-0" style={{
                            width: "15%"
                        }}>
                            <div className="col my-auto">
                                <input className="form-check-input" type="radio"
                                       name="flexRadioDefault" id="flexRadioDefault21" defaultChecked="" />
                                <label className="form-check-label"
                                       htmlFor="flexRadioDefault21">
                                    낙첨수수료
                                </label>
                            </div>
                            <div className="col my-auto">
                                <input className="form-check-input me-1" type="radio"
                                       name="flexRadio44" id="flexRad22" defaultChecked=""/>
                                <label className="form-check-label"
                                       htmlFor="flexRad22">
                                    배팅수수료
                                </label>
                            </div>
                        </div>

                        <div className="row gx-0" style={{
                            width: "30%"
                        }}>
                            <label className="col my-auto">스포츠</label>
                            <div className="col">
                                <input type="text" className="form-control ms-1" defaultValue="0"
                                       style={{height: "100%", textAlign: "center"}}/>
                            </div>

                            <label className="col my-auto">미니게임</label>
                            <div className="col">
                                <input type="text" className="form-control ms-1" defaultValue="0"
                                       style={{height: "100%", textAlign: "center"}}/>
                            </div>

                            <label className="col my-auto">충/환 죽장</label>
                            <div className="col">
                                <input type="text" className="form-control ms-1" defaultValue="0"
                                       style={{height: "100%", textAlign: "center"}}/>
                            </div>
                        </div>
                        <div className="row gx-0" style={{
                            width: "30%"
                        }}>
                            <div className="col-3 my-auto">
                                <input className="form-check-input" type="radio"
                                       name="flexRadioDefault" id="flexRadioDefault31" defaultChecked=""/>
                                <label className="form-check-label"
                                       htmlFor="flexRadioDefault31">
                                    보유+P
                                </label>
                            </div>
                            <div className="col-4 my-auto">
                                <input className="form-check-input me-1" type="radio"
                                       name="flexRadioDefault" id="flexRadioDefault32" defaultChecked=""/>
                                <label className="form-check-label"
                                       htmlFor="flexRadioDefault32">
                                    보유+P+충/환
                                </label>
                            </div>
                            <div className="col-5 my-auto">
                                <input className="form-check-input me-1" type="radio"
                                       name="flexRadioDefault" id="flexRadioDefault33" defaultChecked=""/>
                                <label className="form-check-label"
                                       htmlFor="flexRadioDefault33">
                                    보유+P+충/환+배팅
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                {/*관리자*/}
                <div className="row border">
                    <div className="col-1 my-auto">
                        관리자
                    </div>
                    <div className="col-11 text-center d-flex border-start border-dark">

                        <div className="row gx-0" style={{
                            width: "30%"
                        }}>
                            <label className="col-4 my-auto">마지막저장</label>
                            <div className="col">
                                <input readOnly type="text" className="form-control ms-1" defaultValue="2021-10-11 15:38:00"
                                       style={{height: "100%", textAlign: "center"}}/>
                            </div>
                        </div>

                        <div className="row gx-0" style={{
                            width: "15%"
                        }}>
                            <label className="col-4 my-auto">관리자</label>
                            <div className="col">
                                <input readOnly type="text" className="form-control ms-1" defaultValue="admin"
                                       style={{height: "100%", textAlign: "center"}}/>
                            </div>
                        </div>
                        <div className="row gx-0" style={{
                            width: "15%"
                        }}>
                            <p className="my-auto text-danger mx-1">[수정로그]</p>
                        </div>

                        <div className="row gx-0" style={{
                            width: "40%"
                        }}>
                            <label className="col-3 my-auto">관리자 비밀번호</label>
                            <div className="col-3">
                                <input type="text" className="form-control ms-1"
                                       style={{border: "1px solid black", height: "100%", textAlign: "center"}}/>
                            </div>
                            <div className="col-6">
                                <button type="button" className="btn btn-primary ms-1" style={{width: "100%"}}>저장</button>
                            </div>
                        </div>


                    </div>
                </div>
                <div className="mt-3 row previewMenu">
                    <div className="col">
                        <table className="table table-bordered table-sm text-center align-middle">
                            <thead className="table-secondary">
                            <tr>
                                <th colSpan="4">최근충전</th>
                            </tr>
                            <tr>
                                <th>요청시간</th>
                                <th>처리시간</th>
                                <th>금액</th>
                                <th>상태</th>
                            </tr>
                            </thead>
                            <tbody>
                            {recently5 && recently5.filter((el) => el.id === user.id).length > 0?
                                recently5.filter((el) => el.id === user.id)[0].deposits.map((recently5Deposit, index) => (
                                <tr key={recently5Deposit.no}>
                                    <td>{moment(recently5Deposit.createdAt).format("YYYY-MM-DD HH:mm")}</td>
                                    <td>{moment(recently5Deposit.updatedAt).format("YYYY-MM-DD HH:mm")}</td>
                                    <td><NumericFormat value={recently5Deposit.request_money} displayType={'text'} thousandSeparator={true}/></td>
                                    <td>{typeCase.depositState(recently5Deposit.deposit_state)}
                                    </td>
                                </tr>
                                )) : <></>
                            }
                            </tbody>
                        </table>
                    </div>
                    <div className="col">
                        <table className="table table-bordered table-sm text-center align-middle">
                            <thead className="table-secondary">
                            <tr>
                                <th colSpan="4">최근환전</th>
                            </tr>
                            <tr>
                                <th>요청시간</th>
                                <th>처리시간</th>
                                <th>금액</th>
                                <th>상태</th>
                            </tr>
                            </thead>
                            <tbody>
                            {recently5 && recently5.filter((el) => el.id === user.id).length > 0?
                                recently5.filter((el) => el.id === user.id)[0].withdraws.map((recently5Withdraw, index) => (
                                    <tr key={recently5Withdraw.no}>
                                        <td>{moment(recently5Withdraw.createdAt).format("YYYY-MM-DD HH:mm")}</td>
                                        <td>{moment(recently5Withdraw.updatedAt).format("YYYY-MM-DD HH:mm")}</td>
                                        <td><NumericFormat value={recently5Withdraw.request_money} displayType={'text'} thousandSeparator={true}/></td>
                                        <td>{typeCase.withdrawState(recently5Withdraw.withdraw_state)}
                                        </td>
                                    </tr>
                                )) : <></>
                            }
                            </tbody>
                        </table>
                    </div>
                    <div className="col">
                        <table className="table table-bordered table-sm text-center align-middle">
                            <thead className="table-secondary">
                            <tr>
                                <th colSpan="4">최근 로그인</th>
                            </tr>
                            <tr>
                                <th>로그인시간</th>
                                <th>도메인</th>
                                <th>IP</th>
                                <th>접속장비</th>
                            </tr>
                            </thead>
                            <tbody>
                            {recently5 && recently5.filter((el) => el.id === user.id).length > 0?
                                recently5.filter((el) => el.id === user.id)[0].login_logs.map((recently5LoginLog, index) => (
                                    <tr key={recently5LoginLog.no}>
                                        <td>{moment(recently5LoginLog.createdAt).format("YYYY-MM-DD HH:mm")}</td>
                                        <td>{recently5LoginLog.domain}</td>
                                        <td>{recently5LoginLog.ip}</td>
                                        <td>{typeCase.deviceType(recently5LoginLog.device)}
                                        </td>
                                    </tr>
                                )) : <></>
                            }
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
        </td>

    )
}

export default UserInformation;