import React, {useEffect, useState} from 'react';
import moment from "moment";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import axios from "axios";
import imageCompression from "browser-image-compression";
import commonInfo from "../../config/commonInfo";

const Faq = ({faq, onRefresh}) => {

    const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().add(1, "day").format('YYYY-MM-DD'));
    const [faqList, setFaqList] = useState([]);
    const [faqNos, setFaqNos] = useState([]);
    const [title, setTitle] = useState([]);
    const [cont, setCont] = useState([]);
    const [faqCount, setFaqCount] = useState(0);
    const [query, setQuery] = useState("");
    const [updates, setUpdates] = useState([]);
    const [imageFile, setImageFile] = useState()
    const [imageUrl, setImageUrl] = useState("")
    const [updateImageFile, setUpdateImageFile] = useState();
    const [updateImageUrl, setUpdateImageUrl] = useState("");

    const initialState = useSelector((state) => state.faq)

    const imageOptions = {
        maxSizeMB: 10,
        maxWidth: 1920,
        useWebWorker: true,
    }
    const userInfo = useSelector((state) => state.userInfo);

    const getFaqList = () => {
        axios.post('/admin/faq/get_faq_list', {
            adminNo: userInfo.userNo
        }).then((res) => {
            if(res.data.faqList !== null) {
                const updates2 = []
                for(let i = 0; i < res.data.faqList.length; i++) {
                    const item = {
                        faq_no: res.data.faqList[i].faq_no,
                        no: res.data.faqList[i].no,
                        title: res.data.faqList[i].title,
                        cont: res.data.faqList[i].cont,
                    }
                    updates2.push(item)
                }
                setUpdates(updates2)
                setFaqList(res.data.faqList)
                setFaqCount(res.data.faqCount)
                setFaqNos([])
            }
        })
    }
    useEffect(getFaqList, [userInfo.userNo])

    const startDateHandler = (e) => {
        setStartDate(e.currentTarget.value)
    }
    const endDateHandler = (e) => {
        setEndDate(e.currentTarget.value)
    }
    const onTitleHandler = (e) =>{
        setTitle(e.currentTarget.value);
    }
    const onContHandler = (e) =>{
        setCont(e.currentTarget.value);
    }

    const allCheckHandler = (e) => {
        if(faqList.length > 0 && e.currentTarget.value) {
            const checks = [];
            for(let i = 0; i < faqList.length; i++) {
                checks.push(faqList[i].no)
            }
            setFaqNos(e.target.checked? checks : [])
        }
    }
    const checkHandler = (checked, no) => {
        let faqNos2 = []
        if(checked) {
            faqNos2 = [...faqNos, no]
            setFaqNos([...faqNos, no])
        }
        else {
            faqNos2 = faqNos.filter((id) => id !== no)
            setFaqNos(faqNos.filter((id) => id !== no))
        }
        let totalCheck = 0;
        for(let i = 0; i < faqNos2.length; i++) {
            totalCheck += faqList.filter((id) => id.no === faqNos2[i])[0].no
        }
    }
    const updatesFaqTitleHandler = (value, no) =>{
        const updates2 = updates.filter((item) => item.no !== no)
        const beforeItem = updates.filter((item) => item.no === no)[0]
        beforeItem.title = value
        updates2.push(beforeItem)
        setUpdates(updates2)
    }
    const updatesFaqContHandler = (value, no) =>{
        const updates2 = updates.filter((item) => item.no !== no)
        const beforeItem = updates.filter((item) => item.no === no)[0]
        beforeItem.cont = value
        updates2.push(beforeItem)
        setUpdates(updates2)
    }
    const faqInsert = () => {
        if(imageFile === undefined) {
            const i = 1
            const lastNo = faqList.length
            const number = i + lastNo

            if (window.confirm("등록 하시겠습니까?")) {
                axios.post('/admin/faq/insert_text',{
                    faq_no: number,
                    title: title,
                    cont: cont,
                    adminNo: userInfo.userNo
                }).then((res) => {
                    alert(res.data.isInserted? "등록완료" : "등록실패")
                    getFaqList()
                    onRefresh()
                    setTitle([]);
                    setCont([]);

                    document.getElementById("button-addon3").click();
                })
            }

        } else {
            const i = 1
            const lastNo = faqList.length
            const number = i + lastNo
            const formData = new FormData()
            formData.append("image", imageFile);
            formData.append('data', JSON.stringify({
                    faq_no: number,
                    title: title,
                    cont: cont,
                    adminNo: userInfo.userNo
                }),
            );
            const headers = {
                "Content-Type": "multipart/form-data"
            };

            if (window.confirm("등록 하시겠습니까?")) {
                axios.post('/admin/faq/insert', formData, {headers}
                ).then((res) => {
                    alert(res.data.isInserted? "등록완료" : "등록실패")
                    //getFaqList()
                    onRefresh()
                })
            }

        }
    }

    const faqUpdate = (no) => {
        const updatedItem = updates.filter((item) => item.no === no)[0]
        const faqUpdate = []
        faqUpdate.push(updatedItem)

        const formData = new FormData()
        formData.append("image", updateImageFile);
        formData.append('data', JSON.stringify(faqUpdate));
        const headers = {
            "Content-Type": "multipart/form-data"
        };

        if (window.confirm("수정 하시겠습니까?")) {
            axios.post('/admin/faq/update', formData, {headers}
            ).then((res) => {
                alert(res.data.success? "수정완료" : "수정실패")
                //getFaqList()
                onRefresh()
            })
        }

    }
    const faqDelete = (e) => {
        if(faqNos.length > 0) {
            if (window.confirm("삭제 하시겠습니까?")) {
                axios.post('/admin/faq/delete', {
                    faqNos: faqNos
                }).then((res) => {
                    alert(res.data.isDeleted? "삭제완료" : "삭제실패")
                    //getFaqList()
                    onRefresh()
                })
            }

        }
        else {
            alert('선택해주세요')
        }
    }

    const previewImage = async (e, change) => {
        e.preventDefault();
        const file = e.target.files[0];
        try {
            const compressedFile = await imageCompression(file, imageOptions);
            const reader = new FileReader();

            reader.readAsDataURL(compressedFile);
            reader.onloadend = () => {
                // 변환 완료!
                const base64data = reader.result;
                handlingDataForm(base64data, change);
            };
        } catch (error) {
            alert("이미지파일 등록실패")
        }
    }

    const handlingDataForm = async (base64data, change) => {
        // dataURL 값이 data:image/jpeg:base64,~~~~~~~ 이므로 ','를 기점으로 잘라서 ~~~~~인 부분만 다시 인코딩
        const byteString = atob(base64data.split(",")[1]);

        // Blob를 구성하기 위한 준비, 이 내용은 저도 잘 이해가 안가서 기술하지 않았습니다.
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([ia], {
            type: "image/jpg"
        });
        const file = new File([blob], ".jpg");

        // 위 과정을 통해 만든 image폼을 FormData에 넣어줍니다.
        // 서버에서는 이미지를 받을 때, FormData가 아니면 받지 않도록 세팅해야합니다.

        if (change === 0) {
            const fileUrl = URL.createObjectURL(file); // (*)
            setImageFile(file)
            setImageUrl(fileUrl)
        } else {
            const fileUrl = URL.createObjectURL(file); // (*)
            setUpdateImageFile(file)
            setUpdateImageUrl(fileUrl)
        }
    }

    const queryHandler = (e) => {
        setQuery(e.target.value)
    }
    const inputPress = (e) => {
        if(e.key === "Enter") {
            searchHandler(query, startDate, endDate)
        }
    }


    const searchHandler = (query, startDate, endDate) => {
        axios.post('/admin/faq/query', {
            start_date: startDate,
            end_date: endDate,
            query: query,
        }).then((res) => {
            if (res.data.faqList !== null) {
                setFaqList(res.data.faqList)
            }
        })
    }

    return (
        <div className="m-2">

            <div className="card-body">
                <div>
                    <div className="container-fluid">
                        <div className="my-2 d-flex">
                            <span>(공지수 : {faqCount && faqCount[0].faqCount} 개)</span>
                        </div>

                    </div>
                    <div>

                        <table className="table table-bordered table-sm text-center align-middle">
                            <thead className="table-dark">
                            <tr>
                                <th style={{width: "30px"}}>
                                    <input className="form-check-input" type="checkbox" checked={faqList.length === faqNos.length}
                                           onChange={allCheckHandler} id="flexCheckDefault"/>
                                </th>
                                <th style={{width: "4%"}}>번호</th>
                                <th>제목</th>
                                {/*<th>내용</th>*/}
                                <th style={{width: "10%"}}>등록일시</th>
                                <th style={{width: "10%"}}>수정일시</th>
                                <th style={{width: "6%"}}>수정</th>
                                <th style={{width: "9%"}}>관리자</th>
                            </tr>
                            </thead>

                            {!faq.length && (
                                <tbody key={faq.no}>
                                <tr>
                                    <td colSpan="7">목록이 비어있습니다.</td>
                                </tr>
                                </tbody>
                            )}

                            {faq.map((faq, index) => (
                                <tbody key={faq.no}>
                                <tr>
                                    <td>
                                        <input className="form-check-input" type="checkbox"
                                               checked={faqNos.filter((no) => no === faq.no).length > 0}
                                               onChange={(e) => {checkHandler(e.currentTarget.checked, faq.no)}}
                                        />
                                    </td>
                                    <td>{faq.faq_no}</td>
                                    <td>
                                        <Link className="nav-link" to={"#collapse_faq_cont" + index} data-bs-toggle="collapse"
                                              aria-expanded="true" aria-controls={"#collapse_faq_cont" + index}>
                                            {faq.title}
                                        </Link>
                                    </td>
                                    <td>{moment(faq.createdAt).format("YYYY-MM-DD HH:mm:ss")}</td>
                                    <td>{moment(faq.updatedAt).format("YYYY-MM-DD HH:mm:ss")}</td>
                                    <td>
                                        <button className="btn btn-outline-secondary ms-2" type="button"
                                                data-bs-toggle="collapse" data-bs-target={"#collapse_update" + index}
                                                aria-expanded="false" aria-controls={"collapse_update" + index} style={{
                                            width: "60px"
                                        }}>수정
                                        </button>
                                    </td>
                                    <td>{faq.admin.id}</td>
                                </tr>
                                <tr className="collapse" id={"collapse_faq_cont" + index}>
                                    <td colSpan="2"></td>
                                    <td>
                                        {faq.cont}
                                        <br></br>
                                        <img src={commonInfo.imgUrl + faq.image} alt="" />

                                    </td>
                                    <td colSpan="3"></td>
                                </tr>
                                <tr className="collapse" id={"collapse_update" + index}>
                                    <td colSpan="4">
                                        <div className="update">
                                            <div className="my-3 d-flex">
                                                <label htmlFor="exampleFormControlInput1" className="form-label" style={{
                                                    width: "50px",
                                                    marginTop: "5px"
                                                }}>제목</label>
                                                <input type="text" maxLength="200" className="form-control"
                                                       value={updates.filter((item) => item.no === faq.no).length > 0?
                                                           updates.filter((item) => item.no === faq.no)[0].title : ""}
                                                       onChange={(e) => {updatesFaqTitleHandler(e.currentTarget.value, faq.no)}}/>
                                            </div>
                                            <div className="mb-3 d-flex">
                                                <label htmlFor="exampleFormControlTextarea1" className="form-label" style={{
                                                    width: "50px"
                                                }}>내용</label>
                                                <textarea className="form-control" maxLength="1000"  id="exampleFormControlTextarea1"
                                                          value={updates.filter((item) => item.no === faq.no).length > 0? updates.filter((item) => item.no === faq.no)[0].cont : ""}
                                                          onChange={(e) => {updatesFaqContHandler(e.currentTarget.value, faq.no)}}
                                                >
                                                    </textarea>
                                            </div>
                                            <div className="text-start">
                                                <div>
                                                    <img src={updates.filter((file) => file.no === faq.no).length > 0 ?
                                                        updateImageUrl === ""? commonInfo.imgUrl + faq.image : updateImageUrl
                                                        :""} alt="" />
                                                </div>
                                                <input type="file" accept=".jpeg, .jpg, .png"  onChange={(e) => previewImage(e, 1)} />
                                            </div>
                                            <div className="text-center">
                                                <button className="btn btn-outline-secondary" type="button"
                                                        id="button-addon2"
                                                        onClick={()=> {
                                                            faqUpdate(faq.no)
                                                        }}
                                                >내용수정
                                                </button>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            ))}
                        </table>
                    </div>

                    <div className="d-flex">
                        <button className="btn btn-outline-secondary me-2" type="button"
                                data-bs-toggle="collapse" data-bs-target="#addCollapse"
                                aria-expanded="false" aria-controls="addCollapse" id="button-addon3">추가
                        </button>
                        <button className="btn btn-outline-secondary me-4" type="button"
                                id="button-addon2" onClick={faqDelete}>선택 삭제
                        </button>
                    </div>

                    <div id="addCollapse" className="add collapse">
                        <div className="my-3 d-flex">
                            <label htmlFor="exampleFormControlInput1" className="form-label" style={{
                                width: "50px",
                                marginTop: "5px"
                            }}>제목</label>
                            <input type="text" maxLength="200" className="form-control" value={title} id="exampleFormControlInput1" onChange={onTitleHandler}/>
                        </div>
                        <div className="mb-3 d-flex">
                            <label htmlFor="exampleFormControlTextarea1" className="form-label" style={{
                                width: "50px"
                            }}>내용</label>
                            <textarea className="form-control" maxLength="1000" value={cont} id="exampleFormControlTextarea1" rows="3" onChange={onContHandler}></textarea>
                        </div>
                        <div>
                            <div>
                                <img src={imageUrl} style={{maxWidth: "100%"}} alt=""/>
                            </div>

                            <input type="file" accept=".jpeg, .jpg, .png"  onChange={(e) => previewImage(e, 0)} />
                        </div>
                        <div className="text-center">
                            <button className="btn btn-outline-secondary" type="button"
                                    id="button-addon2"
                                    onClick={() => faqInsert()}
                            >내용등록
                            </button>
                        </div>
                    </div>

                </div>
            </div>

        </div>

    )
}

export default Faq;