import React from 'react';
import ActiveMatchListContainer from "../../containers/ActiveMatchListContainer";

/**
 * 함수형 컴포넌트 스니펫입니다.
 */
const ActiveMatch = () => {
    return (
        <div>
            <h1>경기진행중</h1>
            <ActiveMatchListContainer />
        </div>
    );
};

export default ActiveMatch;