import {useDispatch, useSelector} from "react-redux"
import React, {useEffect, useState} from "react"
import {getSettingMarket, waitSettingMarketTask} from "../redux/reducers/match/settingMarket";
import SettingMarketList from "../components/match/SettingMarketList";
import Pagination from "../components/common/Pagination";

const SettingMarketListContainer = () => {
    // useDispatch: 컴포넌트 내부에서 스토어의 내장 함수 dispatch를 사용할 수 있게 해주는 hook 이다.
    // useSelector: connect 함수를 대신하여 스토어 상태를 조회
    const dispatch = useDispatch()
    const settingMarkets = useSelector((state) => state.settingMarkets)
    const [searchQuery, setSearchQuery] = useState("");

    // 브라우저 상에 컴포넌트가 나타날 때 상품 목록을 조회하는 함수를 실행
    useEffect(() => {
        dispatch(getSettingMarket({
            page: settingMarkets.page,
            pageSize: settingMarkets.pageSize,
            is_active: 0,
            //sport: settingMarkets.sport,
            //sport_name: settingMarkets.sport_name,
            sport: 6046,
            sport_name: "축구",
            query: searchQuery,
        }))
        dispatch(waitSettingMarketTask())
    }, [dispatch])

    const handlePageChange = (page) => {
        dispatch(getSettingMarket({
            page: page,
            pageSize: settingMarkets.pageSize,
            is_active: settingMarkets.is_active,
            sport: settingMarkets.sport,
            sport_name: settingMarkets.sport_name,
            query: searchQuery,
        }))
    }

    const changeQuery = (value) => {
        setSearchQuery(value)
    }

    const inputPress = (e) => {
        if(e.key === "Enter") {
            onSearch(e.target.value)
        }
    }
    const onSearch = (query) => {
        setSearchQuery(query)
        dispatch(getSettingMarket({
            page: settingMarkets.page,
            pageSize: settingMarkets.pageSize,
            is_active: settingMarkets.is_active,
            sport: settingMarkets.sport,
            sport_name: settingMarkets.sport_name,
            query: query,
        }))
    }
    const onClickSearch = (e) => {

        dispatch(getSettingMarket({
            page: settingMarkets.page,
            pageSize: settingMarkets.pageSize,
            is_active: settingMarkets.is_active,
            sport: settingMarkets.sport,
            sport_name: settingMarkets.sport_name,
            query: searchQuery,
        }))
    }

    const clickSport = async (e) => {
        dispatch(getSettingMarket({
            is_active: settingMarkets.is_active,
            page: settingMarkets.page,
            pageSize: settingMarkets.pageSize,
            sport: parseInt(e.target.id),
            sport_name: e.target.alt,
            query: searchQuery,
        }))
    }

    return <>
        <div style={{
            display: "flex"
        }}>
            <select
                style={{
                    width: "100px"
                }}
                className="form-control"
                defaultValue={0}
                value={settingMarkets.is_active === "0"? "전체" : settingMarkets.is_active}
                onChange={e =>
                    dispatch(getSettingMarket({
                        is_active: e.target.value,
                        page: settingMarkets.page,
                        pageSize: settingMarkets.pageSize,
                        sport: settingMarkets.sport,
                        sport_name: settingMarkets.sport_name,
                        query: searchQuery,
                    }))
                }
            >
                <option value={0}>전체</option>
                <option value={1}>사용</option>
                <option value={2}>미사용</option>

            </select>
            <select
                style={{
                    width: "100px"
                }}
                className="form-control"
                defaultValue={100}
                value={settingMarkets.pageSize === "999"? "모두" : settingMarkets.pageSize}
                onChange={e =>
                    dispatch(getSettingMarket({
                        page: settingMarkets.page,
                        pageSize: parseInt(e.target.value),
                        is_active: settingMarkets.is_active,
                        sport: settingMarkets.sport,
                        sport_name: settingMarkets.sport_name,
                        query: searchQuery,
                    }))
                }
            >
                <option value={50}>50</option>
                <option value={100}>100</option>
                <option value={200}>200</option>
                <option value={999}>모두</option>
            </select>
            <div style={{
                display: "flex",
                width: "300px"
            }}>
                <input style={{
                    width: "220px"
                }} type="text" className="form-control" id="search_edit" placeholder="마켓명, ID를 입력하세요" onBlur={(e) => {
                    changeQuery(e.target.value)
                }} onKeyDown={inputPress} />
                <button style={{
                    width: "80px"
                }} type="submit" className="btn btn-primary" onClick={onClickSearch}>검색</button>
            </div>
        </div>
        {!!settingMarkets.sports.length && settingMarkets.sports.map((_sport, index) => (
            <input type="button" class="btn btn-default" id={_sport.id} alt={_sport.name_kor?_sport.name_kor: _sport.name} value={_sport.name_kor?_sport.name_kor: _sport.name} onClick={(e) => {clickSport(e)}} />
        ))}
        <SettingMarketList settingMarkets={settingMarkets} />
        <Pagination totalCount={settingMarkets.totalCount} pageSize={settingMarkets.pageSize} currentPage={settingMarkets.page} onPageChange={handlePageChange}/>
    </>


}
export default SettingMarketListContainer