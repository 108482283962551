import {call, put, take,} from "redux-saga/effects";
import {fetchLoginLog} from "../../../lib/api";
import {failedGetLoginLog, setLoginLog} from "../../reducers/user/loginLog";

export function* getLoginLogSaga(action) {
    try{
        const res = yield call(
            fetchLoginLog,
            action.payload.page, action.payload.pageSize, action.payload.query,
            action.payload.order, action.payload.startDate, action.payload.endDate
        )
        yield put(setLoginLog(res.data))
    }
    catch (error) {
        console.log(error)
        yield put(failedGetLoginLog(error))
    }
}