import React, {useEffect, useState, useRef} from "react";
import axios from "axios";
import {useSelector} from "react-redux";
import { NumericFormat } from 'react-number-format';
import typeCase from "../../util/typeCase"
import moment from "moment";
const MemberBettingLog = ({setMemberBettingLog, userNo, route}) => {

    const [depositLog, setDepositLog] = useState([])
    const [withdrawLog, setWithdrawLog] = useState([])

    // const helpNo = () => {
    //     //console.log(userNo)
    //     if(userNo > 0) {
    //         axios.post('/admin/help/help_log', {
    //             user_no: userNo,
    //         }).then((res) => {
    //             setDepositLog(res.data.depositLog)
    //             setWithdrawLog(res.data.withdrawLog)
    //         })
    //     }
    // }
    // useEffect(helpNo, [])

    const handleCancel = () => {
        setMemberBettingLog(false);
    }

    return (
        <div className="memberLog_background" onClick={handleCancel}>
            <div className="log_modal_box" onClick={(event => event.stopPropagation())}>
                <div className="member_Log">
                    <div className="modalOverflow">
                        <div className="title p-2" style={{
                            textAlign: "left",
                            fontSize: "25px"
                        }}>
                            배팅내역
                        </div>
                        <div className="content p-2">
                            <div style={{
                                border: "1px solid black"
                            }}>
                                <table style={{width: "100%"}}>
                                    <thead style={{
                                        color:"white",
                                        background: "black"
                                    }}>
                                    <th>시작시간</th>
                                    <th>게임분류</th>
                                    <th>게임종류</th>
                                    <th>리그명</th>
                                    <th>홈팀</th>
                                    <th>무</th>
                                    <th>원정팀</th>
                                    <th>배팅시간</th>
                                    <th>배팅금액</th>
                                    <th>배당</th>
                                    <th>당첨금액</th>
                                    <th>결과</th>
                                    </thead>
                                    <tbody>

                                    </tbody>
                                </table>

                            </div>

                        </div>


                    </div>
                    <div className="member_Log_close" onClick={handleCancel}>
                        <button className="btn btn-primary">확인</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MemberBettingLog;