import React, {useEffect, useState} from 'react';
import moment from "moment";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {NumericFormat} from 'react-number-format';
import axios from "axios";
import typeCase from "../../util/typeCase"

const Attend = ({attend, onRefresh}) => {

    const today = moment()
    const startWeek = today.clone().startOf('week').subtract(1, 'week').add(1, 'days').format("YYYY-MM-DD")
    const endWeek = today.clone().endOf('week').subtract(1, 'week').add(1, 'days').format("YYYY-MM-DD")

    const [attendList, setAttendList] = useState([]);
    const [daySetting, setDaySetting] = useState([]);
    const [attendDayOver, setAttendDayOver] = useState("");
    const [day5, setDay5] = useState("");
    const [day6, setDay6] = useState("");
    const [day7, setDay7] = useState("");
    const [day25, setDay25] = useState("");
    const [dayAll, setDayAll] = useState("");

    const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().add(1, "day").format('YYYY-MM-DD'));
    const [query, setQuery] = useState("");

    const userInfo = useSelector((state) => state.userInfo);

    const getAttendList = () => {
        axios.post('/admin/attend/get_attend_list', {
            adminNo: userInfo.userNo
        }).then((res) => {
            setAttendList(res.data.attendList)
            setDaySetting(res.data.DaySetting)
        })
    }
    useEffect(getAttendList, [userInfo.userNo])
    const startDateHandler = (e) => {
        setStartDate(e.currentTarget.value)
    }
    const endDateHandler = (e) => {
        setEndDate(e.currentTarget.value)
    }
    const queryHandler = (e) => {
        setQuery(e.currentTarget.value)
    }
    const inputPress = (e) => {
        if(e.key === "Enter") {
            searchHandler(startDate, endDate, query)
        }
    }
    const searchHandler = (startDate, endDate, query) => {
        // console.log(startDate)
        // console.log(endDate)
        axios.post('/admin/attend/query', {
            start_date: startDate,
            end_date: endDate,
            query: query,
        }).then((res) => {
            if (res.data.attendList !== null) {
                setAttendList(res.data.attendList)
            }
            else {
                alert("실패")
            }
        })
    }

    const searchDate = (startWeek, endWeek, date) => {
        axios.post('/admin/attend/search_date', {
            start_week: startWeek,
            end_week: endWeek,
            date: date,
        }).then((res) => {
            setAttendList(res.data.attendList)
        })
    }

    const attendDayOverHandler = (e) => {
        setAttendDayOver(e.currentTarget.value)
        searchDate(startWeek, endWeek, e.target.value)
    }

    const attendDay5Handler = (e) => {
        setDay5(e.currentTarget.value)
    }
    const attendDay6Handler = (e) => {
        setDay6(e.currentTarget.value)
    }
    const attendDay7Handler = (e) => {
        setDay7(e.currentTarget.value)
    }
    const attendDay25Handler = (e) => {
        setDay25(e.currentTarget.value)
    }
    const attendDayAllHandler = (e) => {
        setDayAll(e.currentTarget.value)
    }

    const updateAttendItem = (e) => {
        if (window.confirm("저장 하시겠습니까?")) {
            axios.post('/admin/attend/update_day_setting', {
                attend_5day: day5,
                attend_6day: day6,
                attend_7day: day7,
                attend_25day: day25,
                attend_allday: dayAll,
            }).then((res) => {
                alert(res.data.isUpdated? "변경완료" : "변경실패")
                getAttendList()
                onRefresh()

                setDaySetting([])
                setAttendDayOver("")
                setDay5("")
                setDay6("")
                setDay7("")
                setDay25("")
                setDayAll("")

                //document.getElementById("button-action1").click();
            })
        }

    }



    function getPartnerType (recommend_code) {
        switch (recommend_code) {
            case "0" : return "본사"
            case "1" : return "파트너"
            default : return recommend_code
        }
    }

    function getStatus(user_state) {
        switch (user_state) {
            case 0 : return "대기"
            case 1 : return "신규"
            case 2 : return "정상"
            case 3 : return "요주의"
            case 4 : return "악성"
            case 5 : return "미이용"
            case 6 : return "휴면"
            case 7 : return "탈퇴"
            case 8 : return "졸업"
            case 9 : return "블랙"
            default : return user_state
        }
    }
    function getLevel(level) {
        switch (level) {
            case 0 : return "1"
            case 1 : return "2"
            case 2 : return "3"
            case 3 : return "4"
            case 4 : return "5"
            case 5 : return "VIP"
            default : return level
        }
    }

    return (
        <div className="m-2">

            <div className="card-body">
                <div>
                    <div className="container-fluid">
                        <div className="row bg-light align-items-center" >

                        </div>
                        <div className="my-2">
                            <div className="d-flex justify-content-between">
                                <div className="d-flex">
                                    <input type="text" className="form-control border border-dark" value={attendDayOver} onChange={attendDayOverHandler} style={{
                                        width: "30%"
                                    }}/>
                                    <span className="my-auto ms-1">이상 출석체크</span>
                                </div>
                                {daySetting.map((daySetting, index) => (
                                    <div key={index} className="d-flex justify-content-between">
                                        <div className="d-flex " >

                                            <span className="text-danger ms-5 my-auto">월요일 ~ 일요일 7일 기준</span>
                                            <span className="ms-2 my-auto">5일 </span>
                                            <span className="ms-2 my-auto">
                                                <NumericFormat value={daySetting.attend_5day} displayType={'text'} thousandSeparator={true} />
                                            </span>


                                            <span className="ms-4 my-auto">6일 </span>
                                            <span className="ms-2 my-auto">
                                                <NumericFormat value={daySetting.attend_6day} displayType={'text'} thousandSeparator={true} />
                                            </span>

                                            <span className="ms-4 my-auto">7일 </span>
                                            <span className="ms-2 my-auto">
                                                <NumericFormat value={daySetting.attend_7day} displayType={'text'} thousandSeparator={true} />
                                            </span>
                                        </div>

                                        <div className="d-flex ">
                                            <span className="text-danger ms-5 my-auto">월 기준</span>

                                            <span className="mx-1 my-auto">25일 </span>
                                            <span className="ms-2 my-auto">
                                                <NumericFormat value={daySetting.attend_25day} displayType={'text'} thousandSeparator={true} />
                                            </span>

                                            <span className="ms-4 my-auto">개근 </span>
                                            <span className="mx-2 my-auto">
                                                <NumericFormat value={daySetting.attend_allday} displayType={'text'} thousandSeparator={true} />
                                            </span>

                                            {userInfo.level === 2?
                                                <button className="btn btn-outline-secondary me-2" type="button"
                                                        data-bs-toggle="collapse" data-bs-target="#collapseDaySetting"
                                                        aria-expanded="false" aria-controls="collapseDaySetting" id="button-action1">수정
                                                </button>
                                                :<></>
                                            }

                                        </div>
                                    </div>
                                ))}

                            </div>
                        </div>
                        <div className="collapse my-2" id="collapseDaySetting">
                            <div className="d-flex justify-content-between">
                                <div className="d-flex">
                                    <input type="text" className="form-control border border-dark" value={attendDayOver} onChange={attendDayOverHandler}  style={{
                                        width: "30%"
                                    }}/>
                                    <span className="my-auto ms-1">이상 출석체크</span>
                                </div>
                                <div className="d-flex justify-content-center">
                                    <span className="text-danger ms-5 my-auto">월요일 ~ 일요일 7일 기준</span>
                                    <span className="ms-2 my-auto">5일 </span>
                                    <input type="text" className="form-control ms-1 border border-dark" value={day5} onChange={attendDay5Handler} style={{
                                        width: "13%",
                                    }} />

                                    <span className="ms-4 my-auto">6일 </span>
                                    <input type="text" className="form-control ms-1 border border-dark" value={day6} onChange={attendDay6Handler} style={{
                                        width: "13%",
                                    }} />

                                    <span className="ms-4 my-auto">7일 </span>
                                    <input type="text" className="form-control ms-1 border border-dark" value={day7} onChange={attendDay7Handler} style={{
                                        width: "13%",
                                    }} />
                                </div>

                                <div className="d-flex justify-content-end">
                                    <span className="text-danger ms-5 my-auto">월 기준</span>

                                    <span className="mx-1 my-auto">25일 </span>
                                    <input type="text" className="form-control ms-1 border border-dark" value={day25} onChange={attendDay25Handler} style={{
                                        width: "20%",
                                    }} />
                                    <span className="ms-4 my-auto">개근 </span>
                                    <input type="text" className="form-control ms-1 border border-dark" value={dayAll} onChange={attendDayAllHandler} style={{
                                        width: "20%",
                                    }} />

                                    <button className="btn btn-primary ms-2" onClick={updateAttendItem}>저장</button>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div>
                        <table className="table table-bordered table-sm text-center align-middle markt1">
                            <thead className="table-dark">
                            <tr>
                                <th style={{width: "5%"}}>상태</th>
                                <th style={{width: "5%"}}>등급</th>
                                <th style={{width: "10%"}}>회원ID</th>
                                <th style={{width: "20%"}}>닉네임</th>
                                <th style={{width: "5%"}}>출석체크횟수</th>
                                <th style={{width: "10%"}}>최초접속일</th>
                                <th style={{width: "10%"}}>최종접속일</th>
                            </tr>
                            </thead>

                            {!attend.length && (
                                <tbody key={attend.no}>
                                <tr>
                                    <td colSpan="7">목록이 비어있습니다.</td>
                                </tr>
                                </tbody>
                            )}

                            {attend.map((attend, index) => (
                                <tbody key={attend.no}>
                                <tr>
                                    <td>{getStatus(attend.user.user_state)}</td>
                                    <td>{getLevel(attend.user.level)}</td>
                                    <td>{attend.id}</td>
                                    <td>{attend.nickname}</td>
                                    <td>{attend.attendance_check}</td>
                                    <td>
                                        {moment(attend.createdAt).format("YYYY-MM-DD HH:mm:ss")}
                                    </td>
                                    <td>
                                        {moment(attend.updatedAt).format("YYYY-MM-DD HH:mm:ss")}
                                    </td>
                                </tr>
                                </tbody>
                            ))
                            }

                        </table>

                    </div>

                </div>
            </div>

        </div>

    )
}

export default Attend;