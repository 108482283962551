import {call, put, take,} from "redux-saga/effects";
import {fetchCompleteBetting} from "../../../lib/api";
import {failedGetCompleteBetting, setCompleteBetting} from "../../reducers/betting/completeBetting";

export function* getCompleteBettingSaga(action) {
    try{
        const res = yield call(
            fetchCompleteBetting,
            action.payload.page, action.payload.pageSize, action.payload.query,
            action.payload.order, action.payload.startDate, action.payload.endDate
        )
        yield put(setCompleteBetting(res.data))
    }
    catch (error) {
        console.log(error)
        yield put(failedGetCompleteBetting(error))
    }
}