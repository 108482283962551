import {createSlice} from "@reduxjs/toolkit"
import moment from "moment/moment";


export const statsSlice = createSlice({
    name: 'LostListReducer',
    initialState: {
        isLoading: false,
        error: null,
        lostCount: 0,
        lostList: [],
        page: 1,
        pageSize:20,
        order:0,
        status: "",
        query: "",
        lostNos:[],
    },
    reducers: {
        getLostListReducer(state, action) {
            console.log("##getLostListReducer##", action.payload.page,action.payload.pageSize,action.payload.status,action.payload.query);
            state.isLoading = true;
            state.page = action.payload.page;
            state.pageSize = action.payload.pageSize;
            state.order = action.payload.order;
            state.status = action.payload.status;
            state.query = action.payload.query;
        },
        setLostListReducer(state, action) {
            console.log("#setLostListReducer#", action.payload);
            if((state.page -1) * state.pageSize > action.payload.lostCount) {
                state.page = 1
            }
            state.isLoading = false;
            state.lostCount = action.payload.lostCount;
            state.lostList = action.payload.lostList;

            state.lostNos = [];
        },
        failedLostListReducer(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        setStatus(state, action) {
            state.status = action.payload;
        },
        setQuery(state, action) {
            state.query = action.payload;
        },
        setLostNos(state, action){
            state.lostNos = action.payload;
        }
    }
});

export const {
    getLostListReducer,
    setLostListReducer,
    failedLostListReducer,
    setStatus,
    setQuery,
    setLostNos
} = statsSlice.actions;

export default statsSlice.reducer;