import {call, put} from "redux-saga/effects";
import {fetchLostList} from "../../../lib/api";
import {failedLostListReducer, setLostListReducer} from "../../reducers/user/LostListReducer";

export function* getLostListSaga(action) {
    console.log("##getLostListSaga##");
    try{
        const res = yield call(fetchLostList,action.payload.page,action.payload.pageSize,action.payload.order,action.payload.status,action.payload.query);
        yield put(setLostListReducer(res.data))
    }
    catch (error) {
        console.log(error)
        yield put(failedLostListReducer(error))
    }
}
