import React, {useState} from "react";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import {setUserInfo} from "../redux/reducers/userInfo"
import {persistor} from "../index";
import AdminChangePw from "./modal/AdminChangePw";

const LoginComponent = () => {

    const dispatch = useDispatch();
    const userInfo = useSelector((state) => state.userInfo)

    const [userNo, setUserNo] = useState(0);
    const [id, setId] = useState("");
    const [pw, setPw] = useState("");

    const onIdHandler = (e) => {
        setId(e.currentTarget.value);
    }

    const onPwHandler = (e) => {
        setPw(e.currentTarget.value);
    }

    const onSubmitHandler = (e) => {
        const loginData = {
            id: id,
            pw: pw,
        }
        //Send Login Data
        axios.post('/admin/admin/login', loginData).then((res) => {
            if(res.status === 200) {
                dispatch(setUserInfo(res.data))
            }
        })
    }
    const onCheckEnter = (e) => {
        if(e.key === 'Enter') {
            onSubmitHandler()
        }
    }
    const changePwOpen = (userNo) => {
        setUserNo(userNo)
    }
    const purge = async () => {
        await persistor.purge();
    }

    const onLogoutHandler = (e) => {
        purge()
    }

    return (
        <div>
            {userInfo.isLogin?
                <div>
                    <div className="adminId">
                        {userInfo.id}님
                    </div>
                    <div className="adminButton">
                        <button type="button" className="btn btn-info btn-sm" data-bs-toggle="modal" data-bs-target="#changePw"
                        onClick={() => changePwOpen(userInfo.userNo)}>비번변경</button>
                        <AdminChangePw userNo={userNo}/>
                        <button type="button" className="btn btn-warning btn-sm ms-1" onClick={onLogoutHandler}>로그아웃</button>
                    </div>
                </div>
                :  (
                    <div className="d-flex">
                        <input type="text" value={id} onChange={onIdHandler}
                               placeholder="ID"/>
                        <input type="password" value={pw} onChange={onPwHandler}
                               placeholder="PW" onKeyDown={onCheckEnter}/>
                        <button onClick={onSubmitHandler} style={{
                            width: "100%"
                        }}>
                            로그인
                        </button>
                    </div>
                )
            }

        </div>
    )
}

export default LoginComponent;