import React from 'react';
import NationListApiSettingContainer from "../../containers/match/NationListApiSettingContainer";


const NationListApiSettingPage = () => {
    return (
        <div>
            <h3>국가목록</h3>
            <NationListApiSettingContainer/>
        </div>
    );
};

export default NationListApiSettingPage;