import React ,{useRef}from 'react';
import SportsListApiSettingContainer from "../../containers/match/SportsListApiSettingContainer";
import NationListApiSettingContainer from "../../containers/match/NationListApiSettingContainer";
import LeagueListApiSettingContainer from "../../containers/match/LeagueListApiSettingContainer";
import TeamListApiSettingContainer from "../../containers/match/TeamListApiSettingContainer";
import {setSportsListChange} from "../../redux/reducers/match/sportsApiSettingReducer";


const SportsListApiSettingPage = () => {
    const nationChildRef = useRef(null);
    const leagueChildRef = useRef(null);
    const teamChildRef = useRef(null);
    const getNationInfo = (sportsNo) => {
        console.log("getNationInfo ",sportsNo);
        nationChildRef.current.getChildNationInfo(sportsNo);
    }

    const getLeagueInfo = (nationNo) => {
        console.log("getLeagueInfo ",nationNo);
        leagueChildRef.current.getChildLeagueInfo(nationNo);
        leagueChildRef.current.focusLeague();
    }

    const getTeamInfo = (leagueNo) => {
        console.log("getTeamInfo ",leagueNo);
        teamChildRef.current.getChildTeamInfo(leagueNo);
    }


    return (
        <div>
            <h3 style={{ margin: '0' }}>스포츠 API 마켓 설정</h3>
        <div className="container">
            <div className="item">
                <h5>스포츠목록</h5>
                <SportsListApiSettingContainer getNationInfo = {getNationInfo}/>
            </div>
            <div className="item">
                <h5>국가목록</h5>
                <NationListApiSettingContainer getLeagueInfo = {getLeagueInfo} ref={nationChildRef}/>
            </div>
            <div className="item">
                <h5>리그목록</h5>
                <LeagueListApiSettingContainer getTeamInfo = {getTeamInfo} ref={leagueChildRef}/>
            </div>
            <div className="item">
                <h5>팀목록</h5>
                <TeamListApiSettingContainer ref={teamChildRef}/>
            </div>
        </div>
        </div>
    );
};

export default SportsListApiSettingPage;