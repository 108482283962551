import React, {useState} from "react";
import moment from "moment";
import typeCase from "../../util/typeCase";
import {NumericFormat} from "react-number-format";
import CancelBetDialog from "../CancelBetDialog";
import RecoveryBetDialog from "../RecoveryBetDialog";
import RefundBetDialog from "../RefundBetDialog";

const CancelBettingList = ({betting, onRefresh}) => {
    const [isRecoveryOpen, setIsRecoveryOpen] = useState(false);
    const [recoveryBet, setRecoveryBet] = useState(null);


    const handleClickRecoveryOpen = (bet) => {
        setRecoveryBet(bet)
        setIsRecoveryOpen(true);
    }
    const handleClickRecoveryClose = () => {
        setIsRecoveryOpen(false)
        onRefresh()
    }


    return (
        <div className="m-2">

            <div className="container-fluid">
                {isRecoveryOpen && <RecoveryBetDialog open={isRecoveryOpen} onClose={handleClickRecoveryClose} bet={recoveryBet} />}
                {betting.map((bet, index) => (
                    <table className="table table-bordered table-sm text-center align-middle">
                        <thead className="table-dark">
                        <tr>
                            <td colSpan="10">
                                <div className="px-3" style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}>
                                    <p>매장명: {bet.user.store_name}({bet.user.store_code})</p>
                                    <p className="text-primary">{bet.user.nickname}({bet.user.id})</p>
                                    <p>{bet.bet_ip}</p>


                                    {bet.status === 3 && (<button style={{
                                        width: "120px"
                                    }} type="submit" className="btn btn-warning" onClick={() => {
                                        handleClickRecoveryOpen(bet)
                                    }}>복구</button>)}


                                </div>
                            </td>
                        </tr>
                        <tr className="table-secondary">
                            <td style={{width: "13%"}}>경기시간</td>
                            <td style={{width: "13%"}}>리그</td>
                            <td style={{width: "13%"}}>홈</td>
                            <td style={{width: "13%"}}>원정</td>
                            <td style={{width: "7%"}}>타입</td>
                            <td style={{width: "4%"}}>승</td>
                            <td style={{width: "10%"}}>무</td>
                            <td style={{width: "4%"}}>패</td>
                            <td style={{width: "7%"}}>결과</td>
                            <td style={{width: "7%"}}>적중</td>
                        </tr>
                        </thead>
                        <tbody>
                        {bet.user_bet_details.map((detail, i) => (
                            <tr>
                                <td>{moment(detail.low_ratio.start_date).format("YYYY-MM-DD HH:mm")}</td>
                                <td>{detail.low_ratio.league}</td>
                                <td>{detail.low_ratio.home}</td>
                                <td>{detail.low_ratio.away}</td>
                                <td>{typeCase.marketType(detail.low_ratio.market_id)}</td>
                                <td className={detail.bet_no === 1? "bg-warning" : ""}>{detail.bet1}</td>
                                <td className={detail.bet_no === 2? "bg-warning" : ""}>{[2,3].includes(detail.market_id)? detail.low_ratio.bet2 : detail.bet2}</td>
                                <td className={detail.bet_no === 3? "bg-warning" : ""}>{detail.bet3}</td>
                                <td>{detail.score_home !== null? detail.score_home : "- "}
                                     : {detail.score_away !== null? detail.score_away : "-"}</td>
                                <td><b className={typeCase.betDetailResultClassName(detail.result)}>{typeCase.betDetailResult(detail.result)}</b></td>
                            </tr>
                        ))}
                        <tr>
                            <td colSpan="10">
                                <div className="px-3" style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}>
                                    <p>배팅번호: {bet.no}</p>
                                    <p>배팅시간: {moment(bet.createdAt).format("YYYY-MM-DD HH:mm:ss")}</p>
                                    <div>
                                        {"배팅수량: "}
                                        <NumericFormat value={bet.folder_count} displayType={'text'} thousandSeparator={true} />
                                    </div>
                                    <div>
                                        {"배팅금액: "}
                                        <NumericFormat value={bet.money} displayType={'text'} thousandSeparator={true} />
                                    </div>
                                    <div>
                                        {"총배당: "}
                                        <NumericFormat value={bet.total_ratio} displayType={'text'} thousandSeparator={true} />
                                    </div>
                                    <div>
                                        {"예상당첨금: "}
                                        <NumericFormat value={bet.win_money} displayType={'text'} thousandSeparator={true} />
                                    </div>
                                    <div>
                                        {"당첨금: "}
                                        <NumericFormat value={bet.result_money} displayType={'text'} thousandSeparator={true} />
                                    </div>
                                    <div>결과: <b className={typeCase.betResultClassName(bet)}>{typeCase.betResult(bet)}</b></div>
                                </div>
                            </td>
                        </tr>

                        </tbody>

                    </table>
                ))}

            </div>

        </div>
    );
}

export default CancelBettingList;