import React from "react";
import {NumericFormat} from "react-number-format";
import {Link} from "react-router-dom";
const JoinListComponent = ({initialState}) => {
    console.log("##JoinListComponent.initialState##",initialState);
    return (
        <div>
            <table className="table table-bordered table-sm text-center align-middle">
                <thead className="table-dark">
                <tr>
                    <th style={{width: "50%"}}>날짜</th>
                    <th>가입자수</th>
                </tr>
                </thead>
                {initialState.totalCount > 0 ?
                 initialState.userList && initialState.userList.map((user, index) => (
                    <tbody key={user.date}>
                    <tr className="bg-secondary">
                        <td>
                            <Link className="nav-link" to={"#collapse" + index} data-bs-toggle="collapse"
                                  aria-expanded="false" aria-controls={"collapse" + index} style={{color: "white"}}>
                                {user.date}
                            </Link>
                        </td>
                        <td style={{color: "white"}}>{user.count}</td>
                    </tr>

                    <tr className="collapse" id={"collapse" + index} >
                        <td>{user.date}</td>
                         <td>
                                {initialState.storeList && initialState.storeList.map((store, index) => (
                                <tr key={store.store}>
                                    <td>
                                {user.date === store.date ? store.store === "0"? "본사" : store.store +" "+
                                        store.count+"명"

                                    : "" }
                                    </td>
                                </tr>
                                ))}
                          </td>
                    </tr>

                    </tbody>
                ))
                    :
                    <tbody>
                    <tr className="table-secondary">
                        <td colSpan= "2"><font size= '3'> 조회된 데이터가 없습니다.</font></td>
                    </tr>
                    </tbody>
                }
            </table>
        </div>

    )
}

export default JoinListComponent;