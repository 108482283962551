import React from 'react';
import NoticeContainer from "../../containers/board/NoticeListContainer";


const Notice = () => {
    return (
        <div>
            <h1>공지게시판</h1>
            <NoticeContainer />
        </div>
    );
};

export default Notice;