import React, {useEffect, useState,} from "react";
import axios from "axios";
import {useSelector} from "react-redux";

const MemberMemo = ({setMemoOpen, userNo}) => {

    const [memo, setMemo] = useState([]);
    const [title, setTitle] = useState("");
    const [cont, setCont] = useState("");

    const userInfo = useSelector((state) => state.userInfo);

    const getNo = () => {
        axios.post('/admin/memo/get_no', {
            no: userNo,
        }).then((res) => {
            setMemo(res.data.memoList)
        })
    }
    useEffect(getNo, [])

    const onTitleHandler = (e) => {
        setTitle(e.currentTarget.value);
    }

    const onContHandler = (e) => {
        setCont(e.currentTarget.value);
    }

    const handleCancel = () => {
        setMemoOpen(false);
    }

    const memoInsert = (e) => {
        axios.post('/admin/memo/insert_individual', {
            id: memo.id,
            title: title,
            cont: cont,
            user_no: userNo,
            admin_id: userInfo.id,
            admin_no: userInfo.userNo
        }).then((res) => {
            if(res.data.isInserted) {
                alert("등록완료")
            } else {
                alert("등록실패")
            }
        })
    }

    return (
        <div className="memberMemo_background" >
            <div className="Memo_box" onClick={(event => event.stopPropagation())}>
                <div className="member_memo">
                    <div className="modalOverflow">
                        <div className="title p-2" style={{
                            textAlign: "left",
                            fontSize: "25px"
                        }}>
                            쪽지발송
                        </div>
                        <div className="content p-1">
                            <div className="d-flex m-2">
                                <label  className="col-form-label" style={{width: "10%"}}>받는사람</label>
                                <div className="my-auto">{memo.id} ({memo.nickname}) </div>
                            </div>
                            <div className="d-flex m-2">
                                <label className="my-auto col-form-label" style={{width: "10%"}}>제목</label>
                                    <input type="text" className="form-control"  maxLength="50" value={title} onChange={onTitleHandler} style={{width: "100%"}}/>

                            </div>
                            <div className="d-flex m-2">
                                <label className="my-auto col-form-label" style={{width: "10%"}}>내용</label>
                                <textarea className="form-control" maxLength="2000" value={cont} rows="6" onChange={onContHandler}></textarea>
                            </div>

                        </div>

                    </div>
                    <div className="member_memo_close">
                        <button className="btn btn-secondary" onClick={handleCancel}>취소</button>
                        <button className="btn btn-primary ms-2" onClick={memoInsert}>확인</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MemberMemo;