import React from 'react';
import FaqContainer from "../../containers/board/FaqListContainer";


const Faq = () => {
    return (
        <div>
            <h1>고객센터-FAQ</h1>
            <FaqContainer />
        </div>
    );
};

export default Faq;