import {createSlice} from "@reduxjs/toolkit"

export const WithdrawMngReducerSlice = createSlice({
    name: 'WithdrawMngReducer',
    initialState: {
        isLoading: false,
        error: null,
        withdrawSelect: 10,
        page: 1,
        pageSize: 20,
        order : 0,
        comboYn : 'Y',
        withdrawTotal: 0,
        withdrawStateSum: 0,
        withdrawTypeSum: 0,
        withdrawListCount: 0,
        totalCount: 0,
        UserDeposit: [],
        UserWithdraw: [],
        withdrawList: [], //페이징처리된리스트
        totalList: [],//모든리스트 엑셀다운로드위함
        monthDeposit: [],
        monthWithdraw: [],
        selectedNos: [],
        totalSelectedMoney: 0,
        userInformation: false,
        memberDepositLog: false,
        memberWithdrawLog: false,
        memberMoneyLog: false,
        memberPointLog: false,
        userNo: false,
        toastState: false,
        withdrawSelectList:[],
    },
    reducers: {
        getWithdrawMngReducer(state, action) {
            console.log("#reducers getWithdrawMngReducer start#");
            state.isLoading = true;
            state.page = action.payload.pageSize === 999? 1: action.payload.page;
            state.pageSize = action.payload.pageSize;
            state.order = action.payload.order;
            state.withdrawSelect = action.payload.withdrawSelect;
            state.query = action.payload.query
            state.startDate = action.payload.startDate;
            state.endDate = action.payload.endDate;
            state.comboYn = action.payload.comboYn;
        },
        setWithdrawMngReducer(state, action) {
            state.isLoading = false;
            console.log("#reducers setWithdrawMngReducer start#",action.payload.withdrawTotal,action.payload.cmmnCdWithdrawStateList);
            if((state.page -1) * state.pageSize > action.payload.totalCount) {
                state.page = 1
            }
            state.totalCount = action.payload.totalCount;
            state.withdrawListCount = action.payload.withdrawListCount;
            state.withdrawTotal = action.payload.withdrawTotal;
            state.withdrawList = action.payload.withdrawList;
            state.userDeposit = action.payload.userDeposit;
            state.userWithdraw = action.payload.userWithdraw;
            state.withdrawStateSum = action.payload.withdrawStateSum;
            state.withdrawTypeSum = action.payload.withdrawTypeSum;
            state.monthDeposit = action.payload.monthDeposit;
            state.monthWithdraw = action.payload.monthWithdraw;
            state.totalList = action.payload.totalList;

            if(state.comboYn === 'Y'){
                state.withdrawSelectList = action.payload.cmmnCdWithdrawStateList;
            }

            //초기화
            state.totalSelectedMoney = 0;//선택금액
            state.selectedNos = [];
            state.totalSelectedPoint = 0;
        },
        failedGetWithdrawMngReducer(state, action) {
            console.log("#reducers failedGetWithdrawMngReducer start#");
            state.isLoading = false;
            state.error = action.payload;
        },
        setSelectedNos(state, action) {
            state.selectedNos = action.payload;
        },
        setTotalSelectedMoney(state, action) {
            state.totalSelectedMoney = action.payload;
        },
        setWithdrawSelect(state, action) {
            state.withdrawSelect = action.payload;
        },
        setUserInformation(state, action) {
            state.userInformation = action.payload;
        },
        setMemberDepositLog(state, action) {
            state.memberDepositLog = action.payload;
        },
        setMemberWithdrawLog(state, action) {
            state.memberWithdrawLog = action.payload;
        },
        setMemberMoneyLog(state, action) {
            state.memberMoneyLog = action.payload;
        },
        setMemberPointLog(state, action) {
            state.memberPointLog = action.payload;
        },
        setUserNo(state, action) {
            state.userNo = action.payload;
        },
        setToastState(state, action) {
            state.toastState = action.payload;
        },
        setQuery(state, action) {
            state.query = action.payload;
        },

    }
});

export const {
    getWithdrawMngReducer,
    setWithdrawMngReducer,
    failedGetWithdrawMngReducer,
    setSelectedNos,
    setTotalSelectedMoney,
    setWithdrawSelect,
    setUserInformation,
    setMemberDepositLog,
    setMemberWithdrawLog,
    setMemberMoneyLog,
    setMemberPointLog,
    setUserNo,
    setToastState,
    setQuery
} = WithdrawMngReducerSlice.actions;

export default WithdrawMngReducerSlice.reducer;