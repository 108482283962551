import {call, put, take,} from "redux-saga/effects";
import {closeChannel, createSocketChannel} from "../../channel/createSocketChannel";
import socket from "../../../config/socket";
import {
    changeStopMatch, failedGetStopFixture, failedGetStopFixtureDetail,
    getStopFixtureDetail,
    pushStopMatchTask,
    setCurrentFixture,
    setStopFixture
} from "../../reducers/match/stopMatch";
import {fetchFixtureDetail, fetchStopFixture} from "../../../lib/api";

export function* getStopFixtureSaga(action) {
    try {
        const res = yield call(fetchStopFixture,
            action.payload.page, action.payload.pageSize, action.payload.sport,
            action.payload.provider, action.payload.league, action.payload.query,
            action.payload.startDate, action.payload.endDate
        );
        yield put(setStopFixture(res.data));
        if(res.data.result?.length > 0) {
            yield put(getStopFixtureDetail(res.data.result[0].fixture_id))
        }
        else {
            yield put(setCurrentFixture(null))
        }
    } catch(error) {
        console.log(error)
        yield put(failedGetStopFixture(error));
    }
}

export function* getStopFixtureDetailSaga(action) {
    try {
        const res = yield call(fetchFixtureDetail, action.payload, 6)
        yield put(setCurrentFixture(res.data))
    } catch (error) {
        console.log(error)
        yield put(failedGetStopFixtureDetail(error))
    }
}

export function* waitStopMatchTaskSaga() {
    let channel;
    try {
        channel = yield call(createSocketChannel, "stopMatch");
        while(true) {
            const task = yield take(channel);
            yield onTaskSaga(task)
        }
    } catch (e) {
        console.log(e, "error");
    } finally {
        socket.close();
        closeChannel(channel)
    }
}

function* onTaskSaga(task) {
    yield put(pushStopMatchTask(task));
    yield put(changeStopMatch(task));
}

export function* changeStopMatchSaga() {
    //yield put(changeSport(changedItem))
}

