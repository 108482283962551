import {createSlice} from "@reduxjs/toolkit";
import moment from "moment";

export const resultMatchSlice = createSlice({
    name: 'resultMatch',
    initialState: {
        isLoading: false,

        isLoadingDetail: false,
        fixtures: [],
        originFixtures: [],
        error: null,
        isConnect: false,
        tasks: [],
        page: 1,
        pageSize: 100,
        totalCount: 0,
        sport: 0,
        market: 0,
        provider: 0,
        league: 0,
        startDate: moment().format('YYYY-MM-DD'),
        endDate: moment().add(1, "day").format('YYYY-MM-DD'),
        sports: [],
        markets: [],
        providers: [],
        leagues: [],
        query: "",
        checkedInputs: [],
        betInputs: [],
        updateData: [],
        low_ratios: [],
        currentFixture: null,
        currentFixtureId: 0,
    },
    reducers: {
        getResultFixture(state, action) {
            state.isLoading = true;
            state.page = action.payload.pageSize === 999? 1: action.payload.page;
            state.pageSize = action.payload.pageSize;
            state.sport = action.payload.sport;
            state.provider = action.payload.provider;
            state.league = action.payload.league;
            state.query = action.payload.query
            state.startDate = action.payload.startDate;
            state.endDate = action.payload.endDate;
        },
        setResultFixture(state, action) {
            if((state.page -1) * state.pageSize > action.payload.totalCount) {
                state.page = 1
            }
            state.isLoading = false;
            state.low_ratios = action.payload.result;
            state.totalCount = action.payload.totalCount;
            state.sports = action.payload.sports;
            state.markets = action.payload.markets;
            state.providers = action.payload.providers;
            state.leagues = action.payload.leagues;
            /*const inputs = [];
            for(const r of action.payload.result) {
                if(r.is_edited) {
                    inputs.push({
                        id: r.id,
                        bet1: r.edited_bet? r.edited_bet.bet1: r.bet1,
                        bet2: r.edited_bet? r.edited_bet.bet2: r.bet2,
                        bet3: r.edited_bet? r.edited_bet.bet3: r.bet3,
                        is_edited: 0,
                        home_score: r.edited_bet? r.edited_bet.home_score : r.home_score,
                        away_score: r.edited_bet? r.edited_bet.away_score : r.away_score,
                    })
                }
                else {
                    inputs.push({id: r.id, bet1: r.bet1, bet2: r.bet2, bet3: r.bet3, is_edited: 0, home_score: r.home_score, away_score: r.away_score})
                }
            }
            state.betInputs = inputs;*/
            state.checkedInputs = [];

        },
        getResultFixtureDetail(state, action) {
            state.isLoadingDetail = true;
            state.currentFixtureId = action.payload;
        },
        setCurrentFixture(state, action) {
            state.isLoadingDetail = false;
            state.currentFixture = action.payload;
        },
        setCheckedInputs(state, action) {
            state.checkedInputs = action.payload;
        },
        setUpdateData(state, action) {
            state.updateData = action.payload;
        },
        setBetInputs(state, action) {
            state.betInputs = action.payload;
        },
        failedGetResultFixture(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        failedGetResultFixtureDetail(state, action) {
            state.isLoadingDetail = false;
            state.error = action.payload;
        },
        waitResultMatchTask(state) {
            state.isConnect = true
        },
        pushResultMatchTask(state, action) {
            state.tasks.push(action.payload)
        },
        changeResultMatch(state, action) {
            //변경된 인자값의 인덱스넘버를 찾아야된다.
            //const changedItems = action.payload
            const changedItem = action.payload
            const beforeArray = state.fixtures.filter((item) => item.id === changedItem.id)
            //배당 변경됬는지 비교

            //찾은후 변경사항업데이트
            const index = state.fixtures.indexOf(beforeArray[0])
            if(index >= 0) {
                if(beforeArray[0].bet1 !== changedItem.bet1 || beforeArray[0].bet3 !== changedItem.bet3) {
                    if(state.originFixtures.filter((item) => item.id === state.fixtures[index]).length === 0) {
                        state.originFixtures.push(state.fixtures[index])
                    }
                    state.fixtures[index] = changedItem
                    console.log(changedItem)
                }
            }
            else {
                //state.fixtures.push(changedItem)
            }
            /*for(const changedItem of changedItems) {
                const beforeArray = state.fixtures.filter((item) => item.id === changedItem.id)
                //배당 변경됬는지 비교

                //찾은후 변경사항업데이트
                const index = state.fixtures.indexOf(beforeArray[0])
                if(index >= 0) {
                    if(beforeArray[0].bet1 !== changedItem.bet1 || beforeArray[0].bet3 !== changedItem.bet3) {
                        if(state.originFixtures.filter((item) => item.id === state.fixtures[index]).length === 0) {
                            state.originFixtures.push(state.fixtures[index])
                        }
                        state.fixtures[index] = changedItem
                        console.log(changedItem)
                    }
                }
                else {
                    //state.fixtures.push(changedItem)
                }
            }*/
            state.tasks = []

        }
    },
});

export const { getResultFixture, getResultFixtureDetail, setResultFixture, setCheckedInputs, setUpdateData, setBetInputs, setCurrentFixture, failedGetResultFixture, failedGetResultFixtureDetail, waitResultMatchTask, pushResultMatchTask, changeResultMatch, changePageSize } = resultMatchSlice.actions;

export default resultMatchSlice.reducer;