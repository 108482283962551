import React from 'react';
import FixtureListContainer from "../../containers/FixtureListContainer";

/**
 * 함수형 컴포넌트 스니펫입니다.
 */
const Fixture = () => {
    return (
        <div>
            <h1>경기일정</h1>
            <FixtureListContainer />
        </div>
    );
};

export default Fixture;