import React, {useState} from 'react';
import ConnectListComponent from "../../components/user/ConnectListComponent";

const ConnectListContainer = () => {

    const [searchUser, setSearchUser] = useState("1");

    const searchSelectList =[
        {key : 1, value: "닉네임"},
        {key : 2, value: "회원ID"},
        {key : 3, value: "IP"},
        {key : 4, value: "국가"}
    ]
    const searchSelectHandler = (e) => {
        setSearchUser(e.target.value)
    }

    return (
        <div className="m-2">
            <div className="card-body">
                <div>
                    <div className="container-fluid">
                        <div className="row bg-light align-items-center"  style={{
                            height: "50px"
                        }}>
                            <div className="col align-items-center" style={{
                                display:"flex",
                                height: "50px"
                            }}>접속중
                            </div>

                            <div className="col align-items-center justify-content-end" style={{
                                display:"flex",
                                height: "50px"
                            }}>

                                <select className="form-select"  onChange={searchSelectHandler} value={searchUser} style={{
                                    width: "120px",
                                    height: "40px"
                                }}>
                                    {searchSelectList.map((item, index) => (
                                        <option key={item.key} value={item.key}>{item.value}</option>
                                    ))}
                                </select>
                                <div className="input-group align-items-end" style={{
                                    width: "250px",
                                    height: "40px",
                                }}>
                                    <input type="text" className="form-control" placeholder=""
                                           aria-describedby="button-addon2"/>
                                    <button className="btn btn-outline-secondary" type="button"
                                            id="button-addon2">검색
                                    </button>
                                </div>
                            </div>

                        </div>
                        <div className="my-2" style={{
                            display: "flex"
                        }}>
                            <span>전체인원 ( 46 )</span>

                        </div>

                    </div>
                <ConnectListComponent/>
                </div>
            </div>
        </div>
    )
}

export default ConnectListContainer;