import React from 'react';
import MemoListContainer from "../../containers/user/MemoListContainer";


const Memo = () => {
    return (
        <div>
            <h1>쪽지관리</h1>
            <MemoListContainer />
        </div>
    );
};

export default Memo;