import {useDispatch, useSelector} from "react-redux";
import {getSideStatusReducer} from "../../redux/reducers/status/SideStatusReducer";
import { NumericFormat } from 'react-number-format';
import {useEffect} from "react";

const SideStatus = ({openYn}) => {
    const dispatch = useDispatch()
    const initialState = useSelector((state) => state.SideStatusReducer)

    const todayStatus = () => {
        dispatch(getSideStatusReducer())
    }

    useEffect(() => {
        if (openYn) {
            todayStatus();
        }
    }, [openYn]);

    return (
        <div style={{background: "black",  padding: "5px"}}>
            <div className="statusBoard" style={{ background: "white"}}>
                <div className="card-body">
                    <table className="table table-bordered table-sm ">

                        <tbody>
                        <tr>
                            <td>충전</td>
                            <td>
                                <NumericFormat value={initialState.todayDeposit} displayType={'text'} thousandSeparator={true} />
                            </td>
                        </tr>
                        <tr>
                            <td>환전</td>
                            <td>
                                <NumericFormat value={initialState.todayWithdraw} displayType={'text'} thousandSeparator={true} />
                            </td>
                        </tr>
                        <tr>
                            <td>충환차액</td>
                            {initialState.todayDeposit-initialState.todayWithdraw > 0?
                                <td style={{color: "blue"}}>
                                    <NumericFormat value={initialState.todayDeposit-initialState.todayWithdraw} displayType={'text'} thousandSeparator={true} />
                                </td>
                                :
                                (
                                    <td style={{color: "red"}}>
                                        <NumericFormat value={initialState.todayDeposit-initialState.todayWithdraw} displayType={'text'} thousandSeparator={true} />
                                    </td>
                                )
                            }

                        </tr>
                        <tr>
                            <td>보유금액</td>
                            <td>
                                <NumericFormat value={initialState.stayMoney} displayType={'text'} thousandSeparator={true} />
                            </td>
                        </tr>
                        <tr>
                            <td>보유포인트</td>
                            <td>
                                <NumericFormat value={initialState.stayPoint} displayType={'text'} thousandSeparator={true} />
                            </td>
                        </tr>
                        <tr>
                            <td>총배팅인원</td>
                            <td>
                                <NumericFormat value={initialState.totalBetUserCount} displayType={'text'} thousandSeparator={true} />
                            </td>
                        </tr>


                        <tr className="table-secondary">
                            <td colSpan="2">스포츠</td>
                        </tr>
                        <tr>
                            <td>배팅금액</td>
                            <td>
                                <NumericFormat value={initialState.sportsMoney} displayType={'text'} thousandSeparator={true} />
                            </td>
                        </tr>
                        <tr>
                            <td>배팅인원</td>
                            <td>
                                <NumericFormat value={initialState.sportsBetUserCount} displayType={'text'} thousandSeparator={true} />
                            </td>
                        </tr>
                        <tr>
                            <td>진행배팅금</td>
                            <td>
                                <NumericFormat value={initialState.sportsProcMoney} displayType={'text'} thousandSeparator={true} />
                            </td>
                        </tr>
                        <tr>
                            <td>예상적중금</td>
                            <td>
                                <NumericFormat value={initialState.sportsWinMoney} displayType={'text'} thousandSeparator={true} />
                            </td>
                        </tr>
                        <tr>
                            <td>진행인원</td>
                            <td>
                                <NumericFormat value={initialState.sportsProcUserCount} displayType={'text'} thousandSeparator={true} />
                            </td>
                        </tr>


                        <tr className="table-secondary">
                            <td colSpan="2">미니게임</td>
                        </tr>
                        <tr>
                            <td>배팅금액</td>
                            <td>
                                <NumericFormat value={initialState.minigameMoney} displayType={'text'} thousandSeparator={true} />
                            </td>
                        </tr>
                        <tr>
                            <td>배팅인원</td>
                            <td>
                                <NumericFormat value={initialState.minigameBetUserCount} displayType={'text'} thousandSeparator={true} />
                            </td>
                        </tr>
                        <tr>
                            <td>진행배팅금</td>
                            <td>
                                <NumericFormat value={initialState.minigameProcMoney} displayType={'text'} thousandSeparator={true} />
                            </td>
                        </tr>
                        <tr>
                            <td>예상적중금</td>
                            <td>
                                <NumericFormat value={initialState.minigameWinMoney} displayType={'text'} thousandSeparator={true} />
                            </td>
                        </tr>
                        <tr>
                            <td>진행인원</td>
                            <td>
                                <NumericFormat value={initialState.minigameProcUserCount} displayType={'text'} thousandSeparator={true} />
                            </td>
                        </tr>


                        <tr className="table-secondary">
                            <td colSpan="2">카지노</td>
                        </tr>
                        <tr>
                            <td>배팅금액</td>
                            <td>
                                <NumericFormat value={initialState.casinoMoney} displayType={'text'} thousandSeparator={true} />
                            </td>
                        </tr>
                        <tr>
                            <td>배팅인원</td>
                            <td>
                                <NumericFormat value={initialState.casinoBetUserCount} displayType={'text'} thousandSeparator={true} />
                            </td>
                        </tr>
                        <tr>
                            <td>진행배팅금</td>
                            <td>
                                <NumericFormat value={initialState.casinoProcMoney} displayType={'text'} thousandSeparator={true} />
                            </td>
                        </tr>
                        <tr>
                            <td>예상적중금</td>
                            <td>
                                <NumericFormat value={initialState.casinoWinMoney} displayType={'text'} thousandSeparator={true} />
                            </td>
                        </tr>
                        <tr>
                            <td>진행인원</td>
                            <td>
                                <NumericFormat value={initialState.casinoProcUserCount} displayType={'text'} thousandSeparator={true} />
                            </td>
                        </tr>

                        </tbody>

                    </table>
                </div>

            </div>
        </div>

    )
}
export default SideStatus;