import React, { useEffect, useState} from 'react';
import moment from "moment";
import {useSelector} from "react-redux";
import axios from "axios";

const LoginLog = ({loginLog, onRefresh}) => {

    const [loginLogList, setLoginLogList] = useState([]);
    const [loginCount, setLoginCount] = useState(0);
    const [equalIp, setEqualIp] = useState([]);

    const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().add(1, "day").format('YYYY-MM-DD'));
    const [query, setQuery] = useState("");
    const [device, setDevice] = useState(10)

    const [isShow, setIsShow] = useState(false)
    const userInfo = useSelector((state) => state.userInfo);

    const getLoginLogList = () => {
        axios.post('/admin/login_log/get_login_log_list', {
            adminNo: userInfo.userNo
        }).then((res) => {
            if(res.data.loginLogList !== null) {
                //setLoginLogList(res.data.loginLogList)
                //console.log(res.data.loginLogList)
                setLoginCount(res.data.loginLogCount)
            }
        })
    }
    useEffect(getLoginLogList, [userInfo.userNo])

    const checkEqualIp = (ip) => {
        axios.post('/admin/login_log/equal_ip', {
            ip: ip,
        }).then((res) => {
            if (res.data.equalIp !== null) {
                setEqualIp(res.data.equalIp)
                //console.log(res.data.equalIp)
                setIsShow(true)
            }
        })
    }

    const startDateHandler = (e) => {
        setStartDate(e.currentTarget.value)
    }
    const endDateHandler = (e) => {
        setEndDate(e.currentTarget.value)
    }
    const queryHandler = (e) => {
        setQuery(e.currentTarget.value)
    }
    // const inputPress = (e) => {
    //     if(e.key === "Enter") {
    //         searchHandler(query, startDate, endDate, device)
    //     }
    // }
    const searchHandler = (query, startDate, endDate, device) => {
        axios.post('/admin/login_log/query', {
            start_date: startDate,
            end_date: endDate,
            query: query,
            device: device
        }).then((res) => {
            if (res.data.loginLogList !== null) {
                setLoginLogList(res.data.loginLogList)
            } else {
                alert("실패")
            }
        })
    }

    function getSucces(succes) {
        switch (succes) {
            case 0 : return "실패"
            case 1 : return "성공"
            default : return succes
        }
    }
    function getCountryKoreanName(englishName) {
        switch (englishName) {
            case "South Korea" : return "대한민국"
            case "China" : return "중국"
            case "Japan" : return "일본"
            case "Thailand" : return "태국"
            case "Singapore" : return "싱가포르"
            case "Laos" : return "라오스"
            case "North Korea" : return "북한"
            case "Vietnam" : return "베트남"
            case "Cambodia" : return "캄보디아"
            case "Taiwan" : return "대만"
            case "Philippines" : return "필리핀"
            case "Malaysia" : return "말레이시아"
            case "Burma Myanmar" : return "미얀마"
            case "Indonesia" : return "인도네시아"
            case "India" : return "인도네시아"
            default : return englishName
        }
    }
    function getDevice(device) {
        switch (device) {
            case 0 : return "PC"
            case 1 : return "안드로이드"
            case 2 : return "아이폰"
            default : return device
        }
    }
    const allList = () => {
        //getLoginLogList()
    }
    return (
        <div className="m-2">
            <div className="card-body">
                <div>
                    <div className="container-fluid">
                        <div className="my-2 d-flex">
                            <span>(총접속내역 : {loginCount && loginCount[0].loginCount} 개)</span>
                        </div>

                    </div>
                    <div>
                        <table className="table table-bordered table-sm text-center align-middle">
                            <thead className="table-dark">
                            <tr>
                                <th style={{width: "10%"}}>아이디</th>
                                <th style={{width: "5%"}}>성공여부</th>
                                <th style={{width: "*%"}}>도메인</th>
                                <th style={{width: "9%"}}>ip</th>
                                <th style={{width: "10%"}}>국가</th>
                                <th style={{width: "7%"}}>접속장비</th>
                                <th style={{width: "10%"}}>접속시간</th>
                            </tr>
                            </thead>

                            {!loginLog.length && (
                                <tbody key={loginLog.no}>
                                <tr>
                                    <td colSpan="7">목록이 비어있습니다.</td>
                                </tr>
                                </tbody>
                            )}

                            {loginLog.map((login_log, index) => (
                                <tbody key={login_log.no}>
                                <tr>
                                    <td key={login_log.id}>{login_log.id}</td>
                                    <td>{getSucces(login_log.success)}</td>
                                    <td>{login_log.domain}</td>
                                    <td>
                                        <div className="tooltipContent" onMouseEnter={() => checkEqualIp(login_log.ip)} onMouseLeave={() => setIsShow(false)}>
                                            {login_log.ip}
                                            {isShow &&
                                                <div className="tooltipText">
                                                    {equalIp.map((equalIp, index) => (
                                                        <>
                                                            {equalIp.id + " "}
                                                        </>
                                                    ))}
                                                </div>}
                                        </div>
                                    </td>
                                    <td>{getCountryKoreanName(login_log.country)}</td>
                                    <td>{getDevice(login_log.device)}</td>
                                    <td>{moment(login_log.createdAt).format("YYYY-MM-DD HH:mm")}</td>
                                </tr>
                                </tbody>
                            ))}
                        </table>

                    </div>

                </div>
            </div>
        </div>

    )
}

export default LoginLog;