import axios from "axios";

export const requestLogin = async (id, pw) => {
    return axios.post("/api/sign/login", {id: id, pw: pw})
}

export const fetchHome = async () => {
    return axios.get('/admin/home/total')
}
export const fetchHomeTable = async ( startDate, endDate) => {
    return axios.get('/admin/home/table', {
        params: {
            startDate: startDate,
            endDate: endDate,
        }
    })
}
export const fetchNowStatus = async (adminNo) => {
    return axios.get('/admin/status/now', {
        params: {
            adminNo: adminNo,
        }
    })
}
export const fetchTodayStatus = async (adminNo, startDate, endDate) => {
    return axios.get('/admin/status/today', {
        params: {
            adminNo: adminNo,
        }
    })
}


export const fetchSport = async (is_active, page, pageSize, query) => {
    return axios.get("/api/getSports", {
        params: {
            is_active: is_active,
            page: page,
            pageSize: pageSize,
            query: query,
        }
    })
};

export const fetchLeague = async (is_active, page, pageSize, sport, nation, query) => {
    return axios.get("/api/getLeagues",{
        params: {
            is_active: is_active,
            page: page,
            pageSize: pageSize,
            sport: sport,
            nation: nation,
            query: query,
        }
    })
};
export const fetchTeam = async (page, pageSize, sport, nation, query) => {
    return axios.get("/api/getTeams", {
        params: {
            page: page,
            pageSize: pageSize,
            sport: sport,
            nation: nation,
            query: query,
        }
    })
};

export const fetchMarket = async (is_active, page, pageSize, query) => {
    return axios.get("/api/getMarkets", {
        params: {
            is_active: is_active,
            page: page,
            pageSize: pageSize,
            query: query,
        }
    })
};

export const fetchBookmaker = async (is_active, page, pageSize, query) => {
    return axios.get("/api/getProviders",{
        params: {
            is_active: is_active,
            page: page,
            pageSize: pageSize,
            query: query,
        }
    })
};

export const fetchSettingMarket = async (is_active, page, pageSize, sport, query) => {
    return axios.get("/api/getSetMarketData", {
        params: {
            is_active: is_active,
            page: page,
            pageSize: pageSize,
            sport: sport,
            query: query,
        }
    })
};

export function fetchFixture(page, pageSize, status, sport, league, query, startDate, endDate) {
    return axios.get("/api/getFixture",{
        params: {
            page: page,
            pageSize: pageSize,
            status: status,
            sport: sport,
            league: league,
            query: query,
            startDate: startDate,
            endDate: endDate,
        }
    })
};
export function fetchWaitFixture(page, pageSize, sport, provider, league, query) {
    return axios.get("/admin/getWaitFixture",{
        params: {
            page: page,
            pageSize: pageSize,
            sport: sport,
            provider: provider,
            league: league,
            query: query,
        }
    })
};
export function fetchActiveFixture(page, pageSize, sport, provider, league, query) {
    return axios.get("/admin/getActiveFixture",{
        params: {
            page: page,
            pageSize: pageSize,
            sport: sport,
            provider: provider,
            league: league,
            query: query,
        }
    })
};
export function fetchCloseFixture(page, pageSize, sport, provider, league, query) {
    return axios.get("/admin/getCloseFixture",{
        params: {
            page: page,
            pageSize: pageSize,
            sport: sport,
            provider: provider,
            league: league,
            query: query,
        }
    })
};
export function fetchResultFixture(page, pageSize, sport, provider, league, query, startDate, endDate) {
    return axios.get("/admin/getResultFixture",{
        params: {
            page: page,
            pageSize: pageSize,
            sport: sport,
            provider: provider,
            league: league,
            query: query,
            startDate: startDate,
            endDate: endDate,
        }
    })
};
export function fetchStopFixture(page, pageSize, sport, provider, league, query, startDate, endDate) {
    return axios.get("/admin/getStopFixture",{
        params: {
            page: page,
            pageSize: pageSize,
            sport: sport,
            provider: provider,
            league: league,
            query: query,
            startDate: startDate,
            endDate: endDate,
        }
    })
};
export function fetchCancelFixture(page, pageSize, sport, provider, league, query, startDate, endDate) {
    return axios.get("/admin/getCancelFixture",{
        params: {
            page: page,
            pageSize: pageSize,
            sport: sport,
            provider: provider,
            league: league,
            query: query,
            startDate: startDate,
            endDate: endDate,
        }
    })
};

export function fetchFixtureDetail(fixtureId, status) {
    return axios.get("/admin/getFixtureDetail",{
        params: {
            fixtureId: fixtureId,
            status: status,
        }
    })
};

export function fetchActiveMatch(page, pageSize, sport, market, provider, league, query) {
    return axios.get("/admin/getActiveMatch",{
        params: {
            page: page,
            pageSize: pageSize,
            sport: sport,
            market: market,
            provider: provider,
            league: league,
            query: query,
        }
    })
};
export function fetchCloseMatch(page, pageSize, sport, market, provider, league, query) {
    return axios.get("/api/getCloseMatch",{
        params: {
            page: page,
            pageSize: pageSize,
            sport: sport,
            market: market,
            provider: provider,
            league: league,
            query: query,
        }
    })
};
export function fetchStopMatch(page, pageSize, sport, market, provider, league, query) {
    return axios.get("/api/getStopMatch",{
        params: {
            page: page,
            pageSize: pageSize,
            sport: sport,
            market: market,
            provider: provider,
            league: league,
            query: query,
        }
    })
};
export function fetchStopMatchBy(page, pageSize, sport, market, provider, league, query) {
    return axios.get("/api/getStopMatchBy",{
        params: {
            page: page,
            pageSize: pageSize,
            sport: sport,
            market: market,
            provider: provider,
            league: league,
            query: query,
        }
    })
};
export function fetchResultMatch(page, pageSize, sport, market, provider, league, query, startDate, endDate) {
    return axios.get("/api/getResultMatch",{
        params: {
            page: page,
            pageSize: pageSize,
            sport: sport,
            market: market,
            provider: provider,
            league: league,
            query: query,
            startDate: startDate,
            endDate: endDate,
        }
    })
};
export function fetchCancelMatch(page, pageSize, sport, market, provider, league, query) {
    return axios.get("/api/getCancelMatch",{
        params: {
            page: page,
            pageSize: pageSize,
            sport: sport,
            market: market,
            provider: provider,
            league: league,
            query: query,
        }
    })
};
export function fetchCancelMatchBy(page, pageSize, sport, market, provider, league, query) {
    return axios.get("/api/getCancelMatchBy",{
        params: {
            page: page,
            pageSize: pageSize,
            sport: sport,
            market: market,
            provider: provider,
            league: league,
            query: query,
        }
    })
};


export function fetchTotalBetting(page, pageSize, query, order, startDate, endDate) {
    return axios.get("/admin/betting/getTotalBetting", {
        params: {
            page: page,
            pageSize: pageSize,
            query: query,
            order: order,
            startDate: startDate,
            endDate: endDate,
        }
    })
}

export function fetchActiveBetting(page, pageSize, query, order) {
    return axios.get("/admin/betting/getActiveBetting", {
        params: {
            page: page,
            pageSize: pageSize,
            query: query,
            order: order,
        }
    })
}
export function fetchCancelBetting(page, pageSize, query, order, startDate, endDate) {
    return axios.get("/admin/betting/getCancelBetting", {
        params: {
            page: page,
            pageSize: pageSize,
            query: query,
            order: order,
            startDate: startDate,
            endDate: endDate,
        }
    })
}
export function fetchCloseBetting(page, pageSize, query, order) {
    return axios.get("/admin/betting/getCloseBetting", {
        params: {
            page: page,
            pageSize: pageSize,
            query: query,
            order: order,
        }
    })
}
export function fetchCompleteBetting(page, pageSize, query, order, startDate, endDate) {
    return axios.get("/admin/betting/getCompleteBetting", {
        params: {
            page: page,
            pageSize: pageSize,
            query: query,
            order: order,
            startDate: startDate,
            endDate: endDate,
        }
    })
}
export function fetchStats(userState, startDate, endDate) {
    return axios.get("/admin/stat/getStats", {
        params: {
            userState: userState,
            startDate: startDate,
            endDate: endDate,
        }
    })
}




export function fetchDeposit(page, pageSize, query, order, startDate, endDate) {
    return axios.get("/admin/deposit/getDeposit", {
        params: {
            page: page,
            pageSize: pageSize,
            query: query,
            order: order,
            startDate: startDate,
            endDate: endDate,
        }
    })
}


export function fetchDepositComplete(page, pageSize, query, order, startDate, endDate) {
    return axios.get("/admin/deposit/getDepositComplete", {
        params: {
            page: page,
            pageSize: pageSize,
            query: query,
            order: order,
            startDate: startDate,
            endDate: endDate,
        }
    })
}



export function fetchNotice(page, pageSize, query, order, startDate, endDate) {
    return axios.get("/admin/notice/getNotice", {
        params: {
            page: page,
            pageSize: pageSize,
            query: query,
            order: order,
            startDate: startDate,
            endDate: endDate,
        }
    })
}



export function fetchHelp(page, pageSize, query, order, startDate, endDate) {
    return axios.get("/admin/help/getHelp", {
        params: {
            page: page,
            pageSize: pageSize,
            query: query,
            order: order,
            startDate: startDate,
            endDate: endDate,
        }
    })
}


export function fetchFaq(page, pageSize, query, order, startDate, endDate) {
    return axios.get("/admin/faq/getFaq", {
        params: {
            page: page,
            pageSize: pageSize,
            query: query,
            order: order,
            startDate: startDate,
            endDate: endDate,
        }
    })
}

export function fetchMarquee(page, pageSize, query, order, startDate, endDate) {
    return axios.get("/admin/marquee/getMarquee", {
        params: {
            page: page,
            pageSize: pageSize,
            query: query,
            order: order,
            startDate: startDate,
            endDate: endDate,
        }
    })
}

export function fetchPopup(page, pageSize, query, order, startDate, endDate) {
    return axios.get("/admin/popup/getPopup", {
        params: {
            page: page,
            pageSize: pageSize,
            query: query,
            order: order,
            startDate: startDate,
            endDate: endDate,
        }
    })
}


export function fetchEvent(page, pageSize, query, order, startDate, endDate) {
    return axios.get("/admin/event/getEvent", {
        params: {
            page: page,
            pageSize: pageSize,
            query: query,
            order: order,
            startDate: startDate,
            endDate: endDate,
        }
    })
}


export function fetchMember(page, pageSize, query, order, startDate, endDate) {
    return axios.get("/admin/member/getMemberUser", {
        params: {
            page: page,
            pageSize: pageSize,
            query: query,
            order: order,
            startDate: startDate,
            endDate: endDate,
        }
    })
}

export function fetchAttend(page, pageSize, query, order, startDate, endDate) {
    return axios.get("/admin/attend/getAttend", {
        params: {
            page: page,
            pageSize: pageSize,
            query: query,
            order: order,
            startDate: startDate,
            endDate: endDate,
        }
    })
}

export function fetchMemo(page, pageSize, query, order, startDate, endDate) {
    return axios.get("/admin/memo/getMemo", {
        params: {
            page: page,
            pageSize: pageSize,
            query: query,
            order: order,
            startDate: startDate,
            endDate: endDate,
        }
    })
}


export function fetchLoginLog(page, pageSize, query, order, startDate, endDate) {

    return axios.get("/admin/login_log/getLoginLog", {
        params: {
            page: page,
            pageSize: pageSize,
            query: query,
            order: order,
            startDate: startDate,
            endDate: endDate,
        }
    })
}


export function fetchBlock(page, pageSize, query, order, startDate, endDate) {
    return axios.get("/admin/ip_block/getBlock", {
        params: {
            page: page,
            pageSize: pageSize,
            query: query,
            order: order,
            startDate: startDate,
            endDate: endDate,
        }
    })
}

export function fetchWithdrawMng(page, pageSize,order,withdrawSelect, query, startDate, endDate,comboYn) {
    console.log("##fetchWithdrawMng##",page,pageSize,order,withdrawSelect,query,startDate,endDate,comboYn);
    return axios.get("/admin/withdrawApi/get_all_list", {
        params: {
            page: page,
            pageSize: pageSize,
            order: order,
            withdrawSelect: withdrawSelect,
            query: query,
            startDate: startDate,
            endDate: endDate,
            comboYn: comboYn,
        }
    })
}

export function fetchWithdrawComplete(page, pageSize,order,withdrawSelect, query, startDate, endDate) {
    console.log("##fetchWithdrawComplete##",page,pageSize,order,withdrawSelect,query,startDate,endDate);
    return axios.get("/admin/withdrawApi/get_complete_list", {
        params: {
            page: page,
            pageSize: pageSize,
            order : order,
            withdrawSelect: withdrawSelect,
            query: query,
            startDate: startDate,
            endDate: endDate,
        }
    })
}

//money
export function fetchMoneyLogMng(page, pageSize,order, query, startDate, endDate) {
    console.log("##fetchMoneyLogMng##",page,pageSize,order,query,startDate,endDate);
    return axios.get("/admin/moneyLogApi/get_money_log", {
        params: {
            page: page,
            pageSize: pageSize,
            order : order,
            query: query,
            startDate: startDate,
            endDate: endDate,
        }
    })
}

//Point
export function fetchPointLogMng(page, pageSize,order,query, startDate, endDate) {
    console.log("##fetchPointLogMng##",page,pageSize,order,query,startDate,endDate);
    return axios.get("/admin/pointLogApi/get_point_log_list", {
        params: {
            page: page,
            pageSize: pageSize,
            order: order,
            query: query,
            startDate: startDate,
            endDate: endDate,
        }
    })
}

export function fetchUserDetail(no) {
    console.log("##fetchUserDetail##",no);
    return axios.post("/admin/userApi/getUserDetail", {no: no})
}

export function fetchJoinList(year,month,date) {
    console.log("##fetchJoinList##",year,month,date);
    return axios.post("/admin/member/get_join_count_list", {year:year,month:month,date: date})
}

export function fetchConnectList(year,month,date) {
    console.log("##fetchConnectList##",year,month,date);
    return axios.post("/admin/member/get_join_count_list", {year:year,month:month,date: date})
}

export function fetchLostList(page,pageSize,order,status,query) {
    console.log("##fetchLostList##",page,pageSize,order,status,query);
    return axios.post("/admin/lost/get_lost_list", {page:page,pageSize:pageSize,order:order,status:status,query:query})
}

export function fetchFailList(page,pageSize,order,startDate,endDate,query,device) {
    console.log("##fetchFailList##",page,pageSize,order,startDate,endDate,query,device);
    return axios.post("/admin/login_fail_log/get_login_fail_list", {page:page,pageSize:pageSize,order:order,startDate:startDate,endDate:endDate,query:query,device:device})
}

export function fetchAdmin(page,pageSize) {
    console.log("##fetchAdmin##",page,pageSize);
    return axios.post("/admin/admin/get_admin_list", {page:page,pageSize:pageSize})
}

export function fetchAdminFailLog(page,pageSize,order,startDate,endDate,query,device) {
    console.log("##fetchAdminFailLog##",page,pageSize,order,startDate,endDate,query,device);
    return axios.post("/admin/admin_login_fail/get_admin_fail_list", {page:page,pageSize:pageSize,order:order,startDate:startDate,endDate:endDate,query:query,device:device})
}

export function fetchSideStatus() {
    console.log("##fetchSideStatus##");
    return axios.post("/admin/boardStatus/today_status")
}

//스포츠 API 설정 리스트
export function fetchSportsApiSettingInfoList(page,pageSize) {
    console.log("##fetchSportsApiSettingInfoList##");
    return axios.post("/admin/sportsApiSettingInfoApi/getSportsList",{page:page,pageSize:pageSize})
}
//국가 API 설정 리스트
export function fetchNationApiSettingInfoList(page,pageSize,sportsNo,nationUseYn,nationNm) {
    console.log("##fetchNationApiSettingInfoList##");
    return axios.post("/admin/nationApiSettingInfoApi/getNationList",{page:page,pageSize:pageSize,sportsNo:sportsNo,nationUseYn:nationUseYn,nationNm:nationNm})
}
//리그 API 설정 리스트
export function fetchLeagueApiSettingInfoList(page,pageSize,nationNo,leagueUseYn,leagueNm) {
    console.log("##fetchLeagueApiSettingInfoList##");
    return axios.post("/admin/leagueApiSettingInfoApi/getLeagueList",{page:page,pageSize:pageSize,nationNo:nationNo,leagueUseYn:leagueUseYn,leagueNm:leagueNm})
}
//팀 API 설정 리스트
export function fetchTeamApiSettingInfoList(page,pageSize,leagueNo,teamUseYn,teamNm) {
    console.log("##fetchTeamApiSettingInfoList##",page,pageSize,leagueNo,teamUseYn,teamNm);
    return axios.post("/admin/teamApiSettingInfoApi/getTeamList",{page:page,pageSize:pageSize,leagueNo:leagueNo,teamUseYn:teamUseYn,teamNm:teamNm})
}

//마켓 API 설정 리스트
export function fetchMarketApiSettingInfoList(page,pageSize,marketUseYn,marketNm) {
    console.log("##fetchMarketApiSettingInfoList##");
    return axios.post("/admin/marketApiSettingInfoApi/getMarketList",{page:page,pageSize:pageSize,marketUseYn:marketUseYn,marketNm:marketNm})
}

export function fetchBasicSettingPage(page, pageSize, query, order, startDate, endDate) {

    return axios.get("/admin/basicSettingPage/getBasicSettingPage", {
        params: {
            page: page,
            pageSize: pageSize,
            query: query,
            order: order,
            startDate: startDate,
            endDate: endDate,
        }
    })
}


export function fetchSportsMarketListSettingPage(page, pageSize, query, order, startDate, endDate, sports_cd) {

    return axios.get("/admin/sportsMarketListSettingPage/getSportsMarketListSettingPage", {
        params: {
            page: page,
            pageSize: pageSize,
            query: query,
            order: order,
            startDate: startDate,
            endDate: endDate,
            sports_cd: sports_cd,
        }
    })
}

//공통코드 가져오기
//input tableId(테이블id-필수),columnNm(컬럼명-필수),useYn(사용여부),inCd(조회할코드),notInCd(조회하지을코드)
export function fetchCmmnCd(tableId, columnNm, useYn, inCd, notInCd) {
    return axios.get("/admin/getCmmnCd", {
        params: {
            tableId: tableId,
            columnNm: columnNm,
            useYn: useYn,
            inCd: inCd,
            notInCd: notInCd,
        }
    })
}

export function fetchAgentList(page, pageSize, order, query) {
    return axios.post("/admin/partner/get_partner_list", {
        page: page,
        pageSize: pageSize,
        order: order,
        query: query,
        })
}

export function fetchDailyFee(page, pageSize, order, storeName,storeCode,startDt,endDt,comboYn) {
    return axios.post("/admin/withdrawApi/getDailyFeeList", {
        page: page,
        pageSize: pageSize,
        order: order,
        storeName: storeName,
        storeCode: storeCode,
        startDt: startDt,
        endDt: endDt,
        comboYn: comboYn,
    })
}


export function fetchJoinCode(page, pageSize, query, order, startDate, endDate) {
    return axios.get("/admin/join_code/getJoinCode", {
        params: {
            page: page,
            pageSize: pageSize,
            query: query,
            order: order,
            startDate: startDate,
            endDate: endDate,
        }
    })
}

export function fetchMenuAthrSetting(selectedAdminNo) {
    return axios.post("/admin/getMenuAthrSetting",{
        adminNo : selectedAdminNo
    })
}