import React, {useState} from "react";
import axios from "axios";
import {setCheckedInputs, setUpdateData} from "../../redux/reducers/match/market";
import {useDispatch} from "react-redux";

const MarketList = ({markets}) => {
    const dispatch = useDispatch()

    const allChangeHandler = (e) => {
        const data_arr = []
        const check_ids = []
        for(let i = 0; i < markets.markets.length; i++) {
            const data = {
                no: markets.markets[i].no,
                id: markets.markets[i].id,
                name: markets.markets[i].name,
                name_kor: markets.markets[i].name_kor,
                is_active: markets.markets[i].is_active,
                createdAt: markets.markets[i].createdAt,
                updatedAt: markets.markets[i].updatedAt,
            }
            data.is_active = e.target.checked
            data_arr.push(data)
            check_ids.push(data.id)
        }
        dispatch(setCheckedInputs(e.target.checked? check_ids : []))
        dispatch(setUpdateData(data_arr))
    }

    const changeHandler = (checked, id, index) => {
        if(checked) {
            dispatch(setCheckedInputs([...markets.checkedInputs, id]));
        } else {
            // 체크 해제
            dispatch(setCheckedInputs(markets.checkedInputs.filter((el) => el !== id)));
        }

        const data = {
            no: markets.markets[index].no,
            id: markets.markets[index].id,
            name: markets.markets[index].name,
            name_kor: markets.markets[index].name_kor,
            is_active: markets.markets[index].is_active,
            createdAt: markets.markets[index].createdAt,
            updatedAt: markets.markets[index].updatedAt,
        }
        const updateData2 = markets.updateData.filter((el) => el.id !== id)
        data.is_active = checked;
        dispatch(setUpdateData([...updateData2, data]));
    };

    const saveMarket = async () => {
        axios.post('/api/updateMarkets', markets.updateData).then( (res) => {
                if(res.status === 200) {
                    dispatch(setUpdateData([]));
                    alert("저장완료");
                }
            }
        )
    }

    return (
        <div>
            <input type="button" value="저장" onClick={saveMarket} />
            {markets._isLoading && "로딩중..."}
            {!markets._isLoading && markets.markets && (
                <table className="table table-bordered table-hover text-center">
                        <thead>
                        <tr>
                            <th>마켓 ID</th>
                            <th>마켓 파싱명</th>
                            <th>
                                사용여부
                                <input
                                    type="checkbox"
                                    checked={
                                        markets.checkedInputs.length === markets.markets.length
                                    }
                                    onChange={(e) => {allChangeHandler(e)}}
                                />
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {!markets.markets.length && (
                            <tr>
                                <td colSpan="3">목록이 비어있습니다.</td>
                            </tr>
                        )}
                        {!!markets.markets.length && markets.markets.map((m, index) => (
                            <tr>
                                <td>{m.id}</td>
                                <td>{m.name}</td>
                                <td>
                                    <label>
                                        사용여부
                                        <input type="checkbox" name={m.id} id={m.id} value="true"
                                               checked={markets.checkedInputs.includes(m.id)}
                                               onChange={(e) => {changeHandler(e.currentTarget.checked, m.id, index)}}
                                        />
                                    </label>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
            )}
        </div>
    )
}
export default MarketList;