import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getAgentListReducer} from "../../redux/reducers/agent/AgentListReducer";
import {getDailyFeeReducer,setStartDt,setEndDt,setStoreName,setStoreCode} from "../../redux/reducers/agent/DailyFeeReducer";
import Pagination from "../../components/common/Pagination";
import DailyFeeComponent from "../../components/agent/DailyFeeComponent";

const DailyFeeContainer = () => {

    const dispatch = useDispatch()
    const initialState = useSelector((state) => state.DailyFeeReducer)
    const userInfo = useSelector((state) => state.userInfo);

    const getAllList = () => {
        if(initialState.totalCount === 0) {
            dispatch(getDailyFeeReducer({
                page: initialState.page,
                pageSize: initialState.pageSize,
                order: initialState.order,
                storeName: initialState.storeName,
                storeCode: initialState.storeCode,
                startDt: initialState.startDt,
                endDt: initialState.endDt,
                comboYn: 'Y',
            }))
        }
    }
    useEffect(getAllList, [dispatch])

    const searchHandler = () => {
        dispatch(getDailyFeeReducer({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            storeName: initialState.storeName,
            storeCode: initialState.storeCode,
            startDt: initialState.startDt,
            endDt: initialState.endDt,
            comboYn : 'N',
        }))
    }

    const storeNameSelectHandler = (e) => {
        dispatch(setStoreName(e.target.value))
    }


    const storeCodeSelectHandler = (e) => {
        dispatch(setStoreCode(e.target.value))
    }


    const startDateHandler = (e) => {
        dispatch(setStartDt(e.target.value))
    }
    const endDateHandler = (e) => {
        dispatch(setEndDt(e.target.value))
    }
    const handlePageChange = (page) => {
        dispatch(getDailyFeeReducer({
            page: page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            storeName: initialState.storeName,
            storeCode: initialState.storeCode,
            startDt: initialState.startDt,
            endDt: initialState.endDt,
            comboYn: 'N',
        }))
    }
    return (
        <div className="m-2">

            <div className="card-body">
                <div>
                    <div className="container-fluid">
                        <div className="row bg-light align-items-center" >
                            <div className="col-2">
                                일별수수료
                            </div>

                            <div className="col-10 align-items-center justify-content-end d-flex" style={{
                                height: "50px"
                            }}>
                                <select className="form-select" onChange={storeNameSelectHandler} value={initialState.storeName} style={{
                                    width: "150px",
                                    height: "40px"
                                }}>
                                    <option value="">전체</option>
                                    {initialState.storeNameList.map((item, index) => (
                                        <option key={item.no} value={item.store_name}>{item.store_name}</option>
                                    ))}
                                </select>

                                <select className="form-select" onChange={storeCodeSelectHandler} value={initialState.storeCode} style={{
                                    width: "150px",
                                    height: "40px"
                                }}>
                                    <option value="">전체</option>
                                    {initialState.storeCodeList.map((item, index) => (
                                        <option key={item.no} value={item.store_code}>{item.store_code}</option>
                                    ))}
                                </select>

                                <input type="date" className="form-control mx-1" value={initialState.startDt} onChange={startDateHandler} style={{
                                    width: "150px",
                                    height: "40px"
                                }}/>
                                <span className="mx-1">~</span>
                                <input type="date" className="form-control mx-1" value={initialState.endDt} onChange={endDateHandler} style={{
                                    width: "150px",
                                    height: "40px"
                                }}/>


                                <button className="btn btn-outline-secondary" type="button"
                                        id="button-addon2" onClick={() => searchHandler()}>검색
                                </button>

                                <button className="btn btn-outline-secondary" type="button"
                                        id="button-addon2">엑셀출력
                                </button>
                            </div>

                        </div>
                    </div>
                        <DailyFeeComponent initialState = {initialState}/>
                    <div className="d-flex justify-content-center"> {/* text-center 클래스 추가 */}
                        <Pagination totalCount={initialState.totalCount} pageSize={initialState.pageSize} currentPage={initialState.page} onPageChange={handlePageChange} />
                    </div>
                </div>
            </div>

        </div>

    )
}

export default DailyFeeContainer;