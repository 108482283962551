import React from 'react';
import DepositCompleteContainer from "../../containers/money/DepositCompleteListContainer";


const DepositComplete = () => {
    return (
        <div>
            <h1>충전완료</h1>
            <DepositCompleteContainer />
        </div>
    );
};

export default DepositComplete;