import {call, put, take,} from "redux-saga/effects";
import {fetchBasicSettingPage} from "../../../lib/api";
import {failedGetBasicSettingPage, setBasicSettingPage} from "../../reducers/match/basicSettingPage";

export function* getBasicSettingPageSaga(action) {

    //alert("11111111111");

    try{
        const res = yield call(
            fetchBasicSettingPage,
            action.payload.page, action.payload.pageSize, action.payload.query,
            action.payload.order, action.payload.startDate, action.payload.endDate
        )

        //alert("res.data ::: "+res.data);

        yield put(setBasicSettingPage(res.data))
    }
    catch (error) {
        console.log("ERROR >>>>>>>>>>>>>>>>>>>>>>>>> "+error)
        yield put(failedGetBasicSettingPage(error))
    }
}