import React from 'react';
import AgentListContainer from "../../containers/agent/AgentListContainer";


const AgentList = () => {
    return (
        <div>
            <h6>영업점관리</h6>
            <AgentListContainer />
        </div>
    );
};

export default AgentList;