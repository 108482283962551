import SportList from "../components/match/SportList"
import {useDispatch, useSelector} from "react-redux"
import React, {useEffect, useState} from "react"
import {getSport, waitSportTask} from "../redux/reducers/match/sport";
import Pagination from "../components/common/Pagination";

const SportListContainer = () => {
    // useDispatch: 컴포넌트 내부에서 스토어의 내장 함수 dispatch를 사용할 수 있게 해주는 hook 이다.
    // useSelector: connect 함수를 대신하여 스토어 상태를 조회
    const dispatch = useDispatch()
    const sports = useSelector((state) => state.sports)
    const [searchQuery, setSearchQuery] = useState("");

    // 브라우저 상에 컴포넌트가 나타날 때 상품 목록을 조회하는 함수를 실행
    useEffect(() => {
        /*dispatch(getSport({
            is_active: 0,
            page: sports.page,
            pageSize: sports.pageSize,
            query: searchQuery,
        }))
        */
        //dispatch(waitSportTask())
    }, [dispatch])

    const handlePageChange = (page) => {
        dispatch(getSport({
            page: page,
            pageSize: sports.pageSize,
            is_active: sports.is_active,
            query: searchQuery,
        }))
    }

    const changeQuery = (value) => {
        setSearchQuery(value)
    }

    const inputPress = (e) => {
        if(e.key === "Enter") {
            onSearch(e.target.value)
        }
    }
    const onSearch = (query) => {
        setSearchQuery(query)
        dispatch(getSport({
            page: sports.page,
            pageSize: sports.pageSize,
            is_active: sports.is_active,
            query: query,
        }))
    }
    const onClickSearch = (e) => {

        dispatch(getSport({
            page: sports.page,
            pageSize: sports.pageSize,
            is_active: sports.is_active,
            query: searchQuery,
        }))
    }

    return <>
        <div style={{
            display: "flex"
        }}>
            <select
                style={{
                    width: "100px"
                }}
                className="form-control"
                defaultValue={0}
                value={sports.isActive === "0"? "전체" : sports.isActive}
                onChange={e =>
                    dispatch(getSport({
                        is_active: e.target.value,
                        page: sports.page,
                        pageSize: sports.pageSize,
                        query: searchQuery,
                    }))
                }
            >
                <option value={0}>전체</option>
                <option value={1}>사용</option>
                <option value={2}>미사용</option>

            </select>
            <select
                style={{
                    width: "100px"
                }}
                className="form-control"
                defaultValue={50}
                value={sports.pageSize === "999"? "모두" : sports.pageSize}
                onChange={e =>
                    dispatch(getSport({
                        page: sports.page,
                        pageSize: parseInt(e.target.value),
                        is_active: sports.is_active,
                        query: searchQuery,
                    }))
                }
            >
                <option value={30}>30</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
                <option value={999}>모두</option>
            </select>
            <div style={{
                display: "flex",
                width: "300px"
            }}>
                <input style={{
                    width: "220px"
                }} type="text" className="form-control" id="search_edit" placeholder="종목, 종목ID 를 입력하세요." onBlur={(e) => {
                    changeQuery(e.target.value)
                }} onKeyDown={inputPress} />
                <button style={{
                    width: "80px"
                }} type="submit" className="btn btn-primary" onClick={onClickSearch}>검색</button>
            </div>
        </div>
        <SportList sports={sports} />
        <Pagination totalCount={sports.totalCount} pageSize={sports.pageSize} currentPage={sports.page} onPageChange={handlePageChange}/>
    </>


}
export default SportListContainer