import React, {useEffect,forwardRef } from 'react';
import NationListApiSettingComponent from "../../components/match/NationListApiSettingComponent";
import Pagination from "../../components/common/Pagination";
import {
    getNationApiSettingInfoListReducer,
    setNationNm,
    setNationUseYn
} from "../../redux/reducers/match/nationApiSettingReducer";
import {useDispatch, useSelector} from "react-redux";
import {resetLeagueApiSettingInfoListReducer} from "../../redux/reducers/match/leagueApiSettingReducer";
import {resetTeamApiSettingInfoListReducer} from "../../redux/reducers/match/teamApiSettingReducer";

const NationListApiSettingContainer =  forwardRef(({getLeagueInfo},ref) => {
    const dispatch = useDispatch()
    const initialState = useSelector((state) => state.nationApiSettingReducer)

    const getNationList = () => {
        if(initialState.nationList !== null) {
            dispatch(getNationApiSettingInfoListReducer({
                nationPage: initialState.nationPage,
                nationPageSize: initialState.nationPageSize,
                sportsNo: initialState.sportsNo,
                nationUseYn: initialState.nationUseYn,
                nationNm: initialState.nationNm,
            }))
        }
    }
    useEffect(() => {
        if (initialState.nationList === null) {
            getNationList();
        }
    }, [dispatch, initialState.nationList]);


    // 부모 컴포넌트에서 호출할 함수를 공개
    React.useImperativeHandle(ref, () => ({
        getChildNationInfo: (sportsNo) => {
            console.log('자식 컴포넌트 함수 호출');
            dispatch(getNationApiSettingInfoListReducer({
                nationPage: 1,
                nationPageSize: initialState.nationPageSize,
                sportsNo: sportsNo,
                nationUseYn: initialState.nationUseYn,
                nationNm: initialState.nationNm,
            }))
        },
    }), []); //


    const handlePageChange = (page) => {
        dispatch(getNationApiSettingInfoListReducer({
            nationPage: page,
            nationPageSize: initialState.nationPageSize,
            sportsNo: initialState.sportsNo,
            nationUseYn: initialState.nationUseYn,
            nationNm: initialState.nationNm,
        }))
    }

    const useOption = [
        {value: "", name: "전체"},
        {value: "Y", name: "사용"},
        {value: "N", name: "미사용"},
    ]

    const query = (nationUseYn) => {
        dispatch(getNationApiSettingInfoListReducer({
            nationPage: 1,
            nationPageSize: initialState.nationPageSize,
            sportsNo: initialState.sportsNo,
            nationUseYn: nationUseYn,
            nationNm: initialState.nationNm,
        }))
        dispatch(resetLeagueApiSettingInfoListReducer())//리그목록초기화
        dispatch(resetTeamApiSettingInfoListReducer())//팀목록초기화
    }

    const inputPress = (e) => {
        if(e.key === "Enter") {
            query(initialState.nationUseYn)
        }
    }

    const nationNmHandler = (e) => {
        dispatch(setNationNm(e.target.value))
    }

    const changeUseYn = (e) => {
        dispatch(setNationUseYn(e.target.value))
        console.log(e.target.value)
        query(e.target.value)
    }

    return (
        <div>
            <div className="container-fluid m-1 p-1" style={{
                display: "flex"
            }}>
                <div className="input-group me-1" style={{
                    display: "flex",
                    width: "600px",
                    alignItems: "center"
                }}>
                    <p className="me-2" style={{width: "60px", margin: 0}}>사용여부</p>
                    <select className="form-select me-4"
                             style={{width: "50px"}} onChange={changeUseYn}>
                        {useOption.map((item) => (
                            <option key={item.value} value={item.value}>{item.name}</option>
                        ))}
                    </select>
                    <input style={{
                        width: "100px"
                    }} type="text" className="form-control" id="search_edit" placeholder="국가명" onChange={nationNmHandler} onKeyDown={inputPress} />
                    <button style={{
                        width: "80px", borderTopRightRadius: "5px" ,borderBottomRightRadius: "5px"
                    }} type="submit" className="btn btn-primary" onClick={() => query(initialState.nationUseYn)}>검색</button>
                    <button style={{width: "100px",backgroundColor : "green" ,borderColor : "green",borderRadius: "5px"}} type="submit" className="btn btn-primary mx-1" >가져오기</button>
                </div>
            </div>
            <NationListApiSettingComponent initialState = {initialState} getLeagueInfo={getLeagueInfo} query = {query}/>
            <div className="d-flex justify-content-center"> {/* text-center 클래스 추가 */}
                {initialState.totalNationCount && initialState.totalNationCount > 0 ?
                 <Pagination totalCount={initialState.totalNationCount} pageSize={initialState.nationPageSize} currentPage={initialState.nationPage} onPageChange={handlePageChange} />
                :""}
            </div>
        </div>
    );
});

export default NationListApiSettingContainer;