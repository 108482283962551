import {call, put} from "redux-saga/effects";
import {failedGetPointLogMngReducer, setPointLogMngReducer} from "../../reducers/money/PointLogMngReducer";
import {fetchPointLogMng} from "../../../lib/api";

export function* getPointLogMngSaga(action) {
    try{
        console.log("##getPointLogMngSaga#",action.payload.page,action.payload.pageSize, action.payload.query, action.payload.startDate, action.payload.endDate)
        const res = yield call(fetchPointLogMng,
            action.payload.page, action.payload.pageSize,action.payload.order, action.payload.query,
            action.payload.startDate, action.payload.endDate
        );
        console.log("##setPointLogMngSaga#",res.data)
        yield put(setPointLogMngReducer(res.data))
    }
    catch (error) {
        console.log(error)
        yield put(failedGetPointLogMngReducer(error))
    }
}