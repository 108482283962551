import {useDispatch, useSelector} from "react-redux"
import React, {useEffect, useState} from "react"
import {NumericFormat} from "react-number-format";
import moment from "moment/moment";
import {getWithdrawMngReducer,setWithdrawSelect} from "../../redux/reducers/money/WithdrawMngReducer";
import axios from "axios";
import * as XLSX from 'xlsx';
import WithdrawMngListComponent from "../../components/money/WithdrawMngListComponent";
import Pagination from "../../components/common/Pagination";
import typeCase from "../../util/typeCase";

const WithdrawMngListContainer = () => {
    const dispatch = useDispatch()
    const initialState = useSelector((state) => state.WithdrawMngReducer)
    const userInfo = useSelector((state) => state.userInfo);
    const [query, setQuery] = useState("");
    const [startDate, setStartDate] = useState(moment().startOf("month").format("yyyy-MM-DD"));
    const [endDate, setEndDate] = useState(moment().endOf("month").format("yyyy-MM-DD"));

    useEffect(() => {
        dispatch(getWithdrawMngReducer({
            page:initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            withdrawSelect: initialState.withdrawSelect,
            query: query,
            startDate: startDate,
            endDate: endDate,
            comboYn: 'Y',
        }))
    },[dispatch])

    const getAllList = () => {
        dispatch(getWithdrawMngReducer({
            page:initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            withdrawSelect: initialState.withdrawSelect,
            query: query,
            startDate: startDate,
            endDate: endDate,
            comboYn: 'N',
        }))
    }

    const startDateHandler = (e) => {
        setStartDate(e.currentTarget.value)
    }
    const endDateHandler = (e) => {
        setEndDate(e.currentTarget.value)
    }
    const withdrawStateSelectHandler = (e) => {
        dispatch(setWithdrawSelect(e.target.value))
        //console.log(e.target.value)
        searchHandler(query, startDate, endDate, e.target.value)
    }
    const queryHandler = (e) => {
        setQuery(e.target.value)
    }
    const inputPress = (e) => {
        if(e.key === "Enter") {
            searchHandler(query, startDate, endDate, initialState.withdrawSelect)
        }
    }
    const searchHandler = (query, startDate, endDate, withdrawSelect) => {
        dispatch(getWithdrawMngReducer({
            page:1,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: query,
            startDate: startDate,
            endDate: endDate,
            withdrawSelect: withdrawSelect,
            comboYn: 'N',
        }))
    }
    const handlePageChange = (page) => {
        dispatch(getWithdrawMngReducer({
            page: page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            startDate:startDate,
            endDate:endDate,
            withdrawSelect:initialState.withdrawSelect,
            query: query,
            comboYn: 'N',
        }))
    }
    const exportExcel = ()=>{
        let excelList = [];
        // JSON 형태의 값들을 for 문을 통해 배열에 추가합니다.
        for (let i = 0; i < initialState.totalList.length; i++) {
                let jsonObject = { // JSON 객체를 생성합니다.
                    파트너:'본사',
                    상태:typeCase.userState(initialState.totalList[i].withdraw_state),
                    등급:initialState.totalList[i].admin?typeCase.userState(initialState.totalList[i].admin.level):'',
                    닉네임:initialState.totalList[i].user?initialState.totalList[i].user.nickname:'',
                    충전:initialState.userDeposit.filter((el) => el.user_no === initialState.totalList[i].user_no).length > 0? initialState.userDeposit.filter((el) => el.user_no === initialState.totalList[i].user_no)[0].user_total : 0,
                    환전:initialState.userWithdraw.filter((el) => el.user_no === initialState.totalList[i].user_no).length > 0? initialState.userWithdraw.filter((el) => el.user_no === initialState.totalList[i].user_no)[0].user_total : 0,
                    충환차액:(initialState.userDeposit.filter((el) => el.user_no === initialState.totalList[i].user_no).length > 0? initialState.userDeposit.filter((el) => el.user_no === initialState.totalList[i].user_no)[0].user_total : 0)
                                 -
                            (initialState.userWithdraw.filter((el) => el.user_no === initialState.totalList[i].user_no).length > 0? initialState.userWithdraw.filter((el) => el.user_no === initialState.totalList[i].user_no)[0].user_total : 0),
                    이달수익:
                        (initialState.monthDeposit.filter((el) => el.user_no === initialState.totalList[i].user_no).length > 0? initialState.monthDeposit.filter((el) => el.user_no === initialState.totalList[i].user_no)[0].user_total : 0)
                        -
                        (initialState.monthWithdraw.filter((el) => el.user_no === initialState.totalList[i].user_no).length > 0? initialState.monthWithdraw.filter((el) => el.user_no === initialState.totalList[i].user_no)[0].user_total : 0),
                    보유머니: initialState.totalList[i].user?initialState.totalList[i].user.money:0,
                    예금주: initialState.totalList[i].user?initialState.totalList[i].user.name:'',
                    은행: initialState.totalList[i].user?initialState.totalList[i].user.bank:'',
                    계좌번호: initialState.totalList[i].user?initialState.totalList[i].user.withdraw_account:'',
                    환전금액: initialState.totalList[i].request_money,
                    요청시간: initialState.totalList[i].createdAt,
                    처리시간: initialState.totalList[i].updatedAt,
                    처리상태: typeCase.withdrawState(initialState.totalList[i].withdraw_state),
                    충전구분: typeCase.depositType(initialState.totalList[i].withdraw_type),
                    관리자: initialState.totalList[i].admin?initialState.totalList[i].admin.id:'',
                }
                excelList.push(jsonObject); // 배열에 JSON 객체를 추가합니다.
        }
        console.log(excelList);
        const workSheet = XLSX.utils.json_to_sheet(excelList);
        const workBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, workSheet, '충전관리 리스트');
        XLSX.writeFile(workBook, '충전관리 리스트.xlsx');
    }

    const updateState = (state, withdrawNos) => {
        if (state === 3) {
            if (window.confirm("취소하겠습니까?")) {
                if (withdrawNos.length > 0) {
                    axios.post('/admin/withdrawApi/change_state', {
                        adminNo: userInfo.userNo,
                        withdrawNos: withdrawNos,
                        state: state
                    }).then((res) => {
                        //console.log(res.data.result)
                        alert(res.data.isUpdated ? "완료" : "실패")
                        getAllList()
                    })
                } else {
                    alert('선택해주세요')
                }
            }
        } else {
            if (withdrawNos.length > 0) {
                axios.post('/admin/withdrawApi/change_state', {
                    adminNo: userInfo.userNo,
                    withdrawNos: withdrawNos,
                    state: state
                }).then((res) => {
                    //console.log(res.data.result)
                    alert(res.data.isUpdated ? "완료" : "실패")
                    getAllList()
                })
            }
        }
    }

    const allList = () => {
        getAllList()
    }
    return <>
        <div className="m-2">
            <div className="card-body">
                <div>
                    <div className="container-fluid">
                        <div className="row bg-light align-items-center" >
                            <div className="col align-items-center justify-content-end d-flex" style={{height: "50px"}}>
                                <input type="date" className="form-control mx-1" value={startDate} onChange={startDateHandler} style={{
                                    width: "150px",
                                    height: "40px"
                                }}/>
                                <span className="mx-1">~</span>
                                <input type="date" className="form-control mx-1" value={endDate} onChange={endDateHandler} style={{
                                    width: "150px",
                                    height: "40px"
                                }}/>

                                <select className="form-select me-1" onChange={withdrawStateSelectHandler} value={initialState.withdrawSelect} style={{
                                    width: "120px",
                                    height: "40px"
                                }}
                                >
                                    <option value="">전체</option>
                                    {initialState.withdrawSelectList.map((item, index) => (
                                        <option key={item.cd} value={item.cd}>{item.cd_nm}</option>
                                    ))}
                                </select>
                                <div className="input-group align-items-end" style={{
                                    width: "20%",
                                    height: "40px",
                                }}>
                                    <input type="text" className="form-control" placeholder="검색"
                                           aria-describedby="button-addon" value={query} onChange={queryHandler} onKeyDown={inputPress}/>
                                    <button className="btn btn-outline-secondary" type="button" id="button-addon2"
                                            onClick={() => searchHandler(query, startDate, endDate, initialState.withdrawSelect)}>검색
                                    </button>
                                    <button className="btn btn-outline-secondary mx-1" type="button" onClick={exportExcel}>엑셀출력</button>
                                </div>
                                <select className="form-select mx-1" style={{
                                    width: "120px",
                                    height: "40px"
                                }}
                                value={initialState.pageSize === "999" ? "모두" : initialState.pageSize}
                                onChange={(e) => {
                                    dispatch(getWithdrawMngReducer({
                                        page:1,
                                        pageSize: parseInt(e.target.value),
                                        order: initialState.order,
                                        withdrawSelect: initialState.withdrawSelect,
                                        query: query,
                                        startDate: startDate,
                                        endDate: endDate,
                                        comboYn: 'N',
                                    }))
                                }}
                                >
                                    <option value={20}>20개씩</option>
                                    <option value={50}>50개씩</option>
                                    <option value={100}>100개씩</option>
                                    <option value={200}>200개씩</option>
                                    <option value={999}>모두</option>
                                </select>
                                <select className="form-select"  style={{
                                    width: "120px",
                                    height: "40px"
                                }}
                                        value={initialState.order === 0? 0 : initialState.order}
                                        onChange={(e) => {
                                            dispatch(getWithdrawMngReducer({
                                                page:1,
                                                pageSize: initialState.pageSize,
                                                order: parseInt(e.target.value),
                                                withdrawSelect: initialState.withdrawSelect,
                                                query: query,
                                                startDate: startDate,
                                                endDate: endDate,
                                                comboYn: 'N',
                                            }))
                                        }}
                                >
                                    <option value={0}>요청시간순</option>
                                    <option value={1}>처리시간순</option>
                                </select>
                            </div>

                        </div>
                        <div className="row my-2">
                            <div className="col-5">
                                    <span>전체환전신청 :
                                        <NumericFormat className="ms-1" value={initialState.withdrawTotal && initialState.withdrawTotal[0].withdrawTotal} displayType={'text'} thousandSeparator={true}/>
                                    </span>
                            </div>

                        </div>
                        <div className="my-2" style={{
                            display: "flex"
                        }}>
                            <div className="col-5">
                                <h4 className="text-danger">선택금액 :
                                    <NumericFormat className="ms-1" value={initialState.totalSelectedMoney} displayType={'text'} thousandSeparator={true}/>
                                </h4>
                            </div>
                            <div className="col-7" style={{
                                textAlign: "right"
                            }}>
                                    <span>스포츠 :
                                        {initialState.withdrawTypeSum.length > 0?
                                            <NumericFormat className="ms-1" value={initialState.withdrawTypeSum[0].withdraw_type === 0?
                                                initialState.withdrawTypeSum[0].withdrawTypeSum : 0 } displayType={'text'} thousandSeparator={true}/>
                                            : <>0</>}
                                    </span>
                                <span className="mx-2">|</span>
                                <span>미니게임 :
                                    {initialState.withdrawTypeSum.length > 0?
                                        <NumericFormat className="ms-1" value={initialState.withdrawTypeSum[1].withdraw_type === 1?
                                            initialState.withdrawTypeSum[1].withdrawTypeSum : 0 } displayType={'text'} thousandSeparator={true}/>
                                        : <>0</>}
                                    </span>
                                <span className="mx-2">|</span>
                                <span>카지노 :
                                    {initialState.withdrawTypeSum.length > 0?
                                        <NumericFormat className="ms-1" value={initialState.withdrawTypeSum[2].withdraw_type === 2?
                                            initialState.withdrawTypeSum[2].withdrawTypeSum : 0 } displayType={'text'} thousandSeparator={true}/>
                                        : <>0</>}
                                    </span>
                            </div>
                        </div>
                        <div className="row my-2 text-center">
                            <div className="col-4 depositd1 d-flex">
                                <button type="button" className="btn btn-outline-secondary btn-sm me-1" style={{
                                    width: "100px",
                                    height: "30px"
                                }} onClick={allList}>모두보기</button>
                                {/*<button type="button" className="btn btn-warning btn-sm me-1" style={{*/}
                                {/*    width: "100px",*/}
                                {/*    height: "30px"*/}
                                {/*}} onClick={() => {*/}
                                {/*    updateState(1, selectedNos)*/}
                                {/*}}>선택대기</button>*/}
                                <button type="button" className="btn btn-primary btn-sm me-1" style={{
                                    width: "100px",
                                    height: "30px"
                                }} onClick={() => {
                                    updateState(2, initialState.selectedNos)
                                }}>선택완료</button>
                                <button type="button" className="btn btn-outline-secondary btn-sm text-danger" style={{
                                    width: "100px",
                                    height: "30px"
                                }}  onClick={() => {
                                    updateState(3, initialState.selectedNos)
                                }}>선택취소</button>

                            </div>

                            <div className="col-8" style={{
                                textAlign: "right"
                            }}>
                                    <span>환전신청 금액 :
                                        {initialState.withdrawStateSum.length > 0 ?
                                            <NumericFormat className="ms-1" value={initialState.withdrawStateSum[0].withdraw_state === 0?
                                                initialState.withdrawStateSum[0].withdrawStateSum : 0 } displayType={'text'} thousandSeparator={true}/>
                                            : <>0</>}
                                    </span>
                                <span className="mx-2">|</span>
                                <span className="text-secondary">환전대기 금액 :
                                    {initialState.withdrawStateSum.length > 0?
                                        <NumericFormat className="ms-1" value={initialState.withdrawStateSum[1].withdraw_state === 1?
                                            initialState.withdrawStateSum[1].withdrawStateSum : 0 } displayType={'text'} thousandSeparator={true}/>
                                        : <>0</>}
                                    </span>
                                <span className="mx-2">|</span>
                                <span className="text-success">환전완료 금액 :
                                    {initialState.withdrawStateSum.length > 0?
                                        <NumericFormat className="ms-1" value={initialState.withdrawStateSum[2].withdraw_state === 2?
                                            initialState.withdrawStateSum[2].withdrawStateSum : 0 } displayType={'text'} thousandSeparator={true}/>
                                        : <>0</>}
                                    </span>
                                <span className="mx-2">|</span>
                                <span className="text-danger">환전취소 금액 :
                                    {initialState.withdrawStateSum.length > 0?
                                        <NumericFormat className="ms-1" value={initialState.withdrawStateSum[3].withdraw_state === 3?
                                            initialState.withdrawStateSum[3].withdrawStateSum : 0 } displayType={'text'} thousandSeparator={true}/>
                                        : <>0</>}
                                    </span>
                            </div>
                        </div>
                    </div>
                    <WithdrawMngListComponent initialState={initialState}  updateState ={updateState}/>
                    <Pagination totalCount={initialState.totalCount} pageSize={initialState.pageSize} currentPage={initialState.page} onPageChange={handlePageChange}/>
                </div>
            </div>
        </div>
    </>
}
export default WithdrawMngListContainer