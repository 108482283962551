import {call, put} from "redux-saga/effects";
import {fetchConnectList} from "../../../lib/api";
import {failedConnectListReducer, setConnectListReducer} from "../../reducers/user/ConnectListReducer";

export function* getConnectListSaga(action) {
    console.log("##getConnectListSaga##");
    try{
        const res = yield call(fetchConnectList,action.payload.year,action.payload.month,action.payload.date);
        yield put(setConnectListReducer(res.data))
    }
    catch (error) {
        console.log(error)
        yield put(failedConnectListReducer(error))
    }
}
