import React from "react";
import moment from "moment/moment";
import {useDispatch} from "react-redux";
import {setBetInputs, setCheckedInputs} from "../../redux/reducers/match/cancelMatchBy";
import axios from "axios";

const CancelMatchByList = ({matches}) => {
    const dispatch = useDispatch()

    const changeHandler = (checked, id, index) => {
        if(checked) {
            dispatch(setCheckedInputs([...matches.checkedInputs, id]));
        }
        else {
            dispatch(setCheckedInputs(matches.checkedInputs.filter((el) => el !== id)));
        }
    };

    const allChangeHandler = (e) => {
        const check_ids = []
        for(let index = 0; index < matches.fixtures.length; index++) {
            check_ids.push(matches.fixtures[index].id)
        }
        dispatch(setCheckedInputs(e.target.checked? check_ids : []));
    }

    const changeBet = (e, id, index, type) => {
        if(e.target.value !== e.target.defaultValue) {
            e.target.style.color = "green"
        }
        else {
            e.target.style.color = "#555"
        }
        const value = parseFloat(e.target.value)
        const data = {
            bet1: matches.fixtures[index].bet1,
            bet2: matches.fixtures[index].bet2,
            bet3: matches.fixtures[index].bet3,
        }

        const betInputs2 = matches.betInputs.filter((el) => el.id !== id);

        const betInputs3 = matches.betInputs.filter((el) => el.id === id && el.is_edited === 1);
        let inputs
        if(betInputs3.length > 0) {
            if(type === 1) {
                inputs = {id: id, bet1: value, bet2: betInputs3[0].bet2, bet3: betInputs3[0].bet3}
            }
            else if(type === 2) {
                inputs = {id: id, bet2: value, bet1: betInputs3[0].bet1, bet3: betInputs3[0].bet3}
            }
            else if(type === 3) {
                inputs = {id: id, bet3: value, bet2: betInputs3[0].bet2, bet1: betInputs3[0].bet1}
            }
        }
        else {
            if(type === 1) {
                inputs = {id: id, bet1: value, bet2: data.bet2, bet3: data.bet3}
            }
            else if(type === 2) {
                inputs = {id: id, bet2: value, bet1: data.bet1, bet3: data.bet3}
            }
            else if(type === 3) {
                inputs = {id: id, bet3: value, bet2: data.bet2, bet1: data.bet1}
            }
        }
        inputs.is_edited = 1;
        dispatch(setBetInputs([...betInputs2, inputs]))

        //체크처리
        const checkedInput2 = matches.checkedInputs.filter((el) => el !== id)
        dispatch(setCheckedInputs([...checkedInput2, id]));
    }

    const getAllIds = () => {
        const updateData = []
        for(let i = 0; i < matches.fixtures.length; i++) {
            updateData.push(matches.fixtures[i].id)
        }
        return updateData
    }

    const closeMatch = (updateData) => {
        axios.post('/api/closeMatch', updateData).then((res) => {
            if(res.status === 200) {
                alert(res.data.result + "개 활성완료");
                window.location.reload();
            }
        })
    }

    const changeIsEdited = (updateData) => {
        axios.post('/api/changeIsEdited', updateData).then((res) => {
            if(res.status === 200) {
                alert(res.data.result + "개 파싱상태변경완료");
                window.location.reload();
            }
        })
    }

    return (
        <div className="container-fluid">
            <button className="btn btn-default" onClick={() => {closeMatch(getAllIds())}} >전체마감</button>
            <button className="btn btn-default" onClick={() => {closeMatch(matches.checkedInputs)}} >선택마감</button>
            <button className="btn btn-default" onClick={() => {changeIsEdited(0)}} >파싱 ON</button>
            <button className="btn btn-default" onClick={() => {changeIsEdited(1)}} >파싱 OFF</button>
            {matches.isLoading && "로딩중..."}
            {!matches.isLoading && matches.fixtures && (
                <table className="table table-bordered table-hover text-center">
                    <thead>
                        <tr>
                            <th className="text-center">
                                <input
                                    type="checkbox"
                                    checked={
                                        matches.checkedInputs.length === matches.fixtures.length
                                    }
                                    onChange={(e) => {allChangeHandler(e)}}
                                />
                            </th>
                            <th>업체</th>
                            <th>종목</th>
                            <th>구분</th>
                            <th>종류</th>
                            <th>경기일정</th>
                            <th>리그</th>
                            <th>홈팀명</th>
                            <th>A</th>
                            <th>무/핸/합</th>
                            <th>B</th>
                            <th>원정팀명</th>
                            <th>환수율</th>
                            <th>수정</th>
                            <th>FixID</th>
                            <th>마켓ID</th>
                            <th>파싱상태</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!matches.fixtures.length && (
                            <tr>
                                <td colSpan="3">목록이 비어있습니다.</td>
                            </tr>
                        )}
                        {!!matches.fixtures.length && matches.fixtures.map((fixture, index) => (
                            <tr className={matches.checkedInputs.includes(fixture.id)? "info" : ""}>
                                <td>
                                    <input type="checkbox" name={fixture.id} id={fixture.id} value="true"
                                           checked={matches.checkedInputs.includes(fixture.id)}
                                           onChange={(e) => {changeHandler(e.currentTarget.checked, fixture.id, index)}}
                                    />
                                </td>
                                <td>{fixture.provider}</td>
                                <td>{fixture.sport}</td>
                                <td>{fixture.market_detail}</td>
                                <td>{fixture.market}</td>
                                <td>{moment(fixture.start_date).format("YYYY-MM-DD HH:mm")}</td>

                                <td>{fixture.league}</td>
                                <td>
                                    {fixture.home}
                                </td>
                                <td>
                                    <p>{matches.originFixtures.filter(item => item.id === fixture.id).length > 0?
                                        (<span style={{
                                            color: matches.originFixtures.filter(item => item.id === fixture.id)[0].bet1 < fixture.bet1?
                                                "blue"
                                                : "red",
                                            textDecoration: fixture.bet1_status !== 1?
                                                "line-through"
                                                : ""
                                        }}>{fixture.bet1}</span>)
                                        : (<span style={{
                                                textDecoration: fixture.bet1_status !== 1?
                                                    "line-through"
                                                    : ""
                                                }}>{fixture.bet1}
                                        </span>)}
                                    </p>
                                    <input type="number" className="form-control"
                                           defaultValue={fixture.bet1}
                                           value={matches.betInputs.filter((e) => e.id === fixture.id).length > 0? matches.betInputs.filter((e) => e.id === fixture.id)[0].bet1 : ""}
                                           onChange={(e) => {changeBet(e, fixture.id, index, 1) }} />
                                </td>
                                {fixture.market_id === 1 && [6046, 35232, 530129].includes(fixture.sport_id)?
                                    (<td>
                                        <p>{matches.originFixtures.filter(item => item.id === fixture.id).length > 0?
                                            (<span style={{
                                                color: matches.originFixtures.filter(item => item.id === fixture.id)[0].bet2 < fixture.bet2?
                                                    "blue"
                                                    : "red",
                                                textDecoration: fixture.bet2_status !== 1?
                                                    "line-through"
                                                    : "",
                                            }}>
                                                {fixture.bet2}
                                            </span>)
                                            : (<span>
                                                {fixture.bet2}
                                            </span>)}
                                        </p>
                                        <input type="number" className="form-control"
                                               defaultValue={fixture.bet2}
                                               value={matches.betInputs.filter((e) => e.id === fixture.id).length > 0? matches.betInputs.filter((e) => e.id === fixture.id)[0].bet2 : ""}
                                               onChange={(e) => {changeBet(e, fixture.id, index, 2) }} />
                                    </td>)
                                    : ![1,5,52].includes(fixture.market_id)?
                                        (<td>{fixture.bet2? fixture.bet2.replace(" (0-0)", ""): fixture.bet2}</td>)
                                        : (<td>VS</td>)}
                                <td>
                                    <p>{matches.originFixtures.filter(item => item.id === fixture.id).length > 0?
                                        (<span style={{
                                            color: matches.originFixtures.filter(item => item.id === fixture.id)[0].bet3 < fixture.bet3?
                                            "blue"
                                            : "red",
                                            textDecoration: fixture.bet3_status !== 1?
                                            "line-through"
                                            : "",
                                        }}>
                                            {fixture.bet3}
                                        </span>)
                                        : (<span style={{
                                            textDecoration: fixture.bet3_status !== 1?
                                            "line-through"
                                            : "",
                                            }}>
                                            {fixture.bet3}
                                        </span>)}
                                    </p>
                                    {fixture.market_id === 6? (<></>): (<input type="number" className="form-control"
                                                                               defaultValue={fixture.bet3}
                                                                               value={matches.betInputs.filter((e) => e.id === fixture.id).length > 0? matches.betInputs.filter((e) => e.id === fixture.id)[0].bet3 : ""}
                                                                               onChange={(e) => {changeBet(e, fixture.id, index, 3) }} />)}

                                </td>
                                <td>{fixture.away}</td>
                                <td></td>
                                <td></td>
                                <td>{fixture.fixture_id}</td>
                                <td>{fixture.market_id}</td>
                                <td></td>


                                {/*<td>
                                    <input
                                        name={index}
                                        type="text"
                                        defaultValue={league.name_kor}
                                        onBlur={(e) => {
                                            changeName(e.currentTarget.value, league.id, index)
                                        }}
                                    />
                                </td>*/}

                            </tr>
                        ))}
                    </tbody>
            </table>
            )}
        </div>
    );

}

export default CancelMatchByList;