

const typeCase = {
    depositType: (type) => {
        switch(type) {
            case 1 : return "스포츠"
            case 2 : return "미니게임"
            case 3 : return "카지노"
            default : return type
        }
    },
    depositState: (state) => {
        switch(state) {
            case 0 : return "신청"
            case 1 : return "대기"
            case 2 : return "완료"
            case 3 : return "취소"
            default : return state
        }
    },
    withdrawType: (type) => {
        switch(type) {
            case 1 : return "스포츠"
            case 2 : return "미니게임"
            case 3 : return "카지노"
            default : return type
        }
    },
    withdrawState: (state) => {
        switch(state) {
            case 0 : return "신청"
            case 1 : return "대기"
            case 2 : return "완료"
            case 3 : return "취소"
            default : return state
        }
    },

    userState: (state) => {
        switch(state) {
            case 0 : return "대기"
            case 1 : return "요주의"
            case 2 : return "신규"
            case 3 : return "정상"
            case 4 : return "성향"
            case 5 : return "악성"
            case 6 : return "미이용"
            case 7 : return "휴면"
            case 8 : return "탈퇴"
            case 9 : return "졸업"
            case 10 : return "블랙"
            default : return state
        }
    },

    userType: (type) => {
        switch (type) {
            case 0 : return "본사"
            case 1 : return "파트너"
            default : return type
        }
    },

    levelState: (state) => {
        switch (state) {
            case 0 : return "1레벨"
            case 1 : return "2레벨"
            case 2 : return "3레벨"
            case 3 : return "4레벨"
            case 4 : return "5레벨"
            case 5 : return "VIP"
            default : return state
        }
    },

    adminState: (type) => {
        switch(type) {
            case 0 : return "일반관리자"
            case 1 : return "서브관리자"
            case 2 : return "마스터관리자"
            default : return type
        }
    },

    deviceType: (type) => {
        switch (type) {
            case 0 : return "pc"
            case 1 : return "안드로이드"
            case 2 : return "아이폰"
            default : return type
        }
    },

    adminUse: (type) => {
        switch (type) {
            case 0 : return "사용"
            case 1 : return "미사용"
            default : return type
        }
    },

    bank: (type) => {
        switch (type) {
            case "국민" : return "국민"
            case "기업" : return "기업"
            case "농협" : return "농협"
            case "신한" : return "신한"
            case "우리" : return "우리"
            case "우체국" : return "우체국"
            case "새마을금고" : return "새마을금고"
            case "수협" : return "수협"
            case "카카오뱅크" : return "카카오뱅크"
            case "케이뱅크" : return "케이뱅크"
            case "KEB하나" : return "KEB하나"
            case "SC제일" : return "SC제일"
            case "한국시티" : return "한국시티"
            case "경남" : return "경남"
            case "광주" : return "광주"
            case "대구" : return "대구"
            case "부산" : return "부산"
            case "전북" : return "전북"
            case "제주" : return "제주"
            default : return type
        }
    },

    telecom: (type) => {
        switch (type) {
            case 0 : return "SKT"
            case 1 : return "KT"
            case 2 : return "LG"
            case 3 : return "알뜰SKT"
            case 4 : return "알뜰KT"
            case 5 : return "알뜰LG"
            default : return type

        }
    },

    betDetailRatio: (detail) => {
        switch (detail.bet_no) {
            case 1 : return detail.bet1
            case 2 : return detail.bet2
            case 3 : return detail.bet3
        }
    },
    betDetailSelect: (detail) => {
        if(detail.market_id === 1) {
            switch (detail.bet_no) {
                case 1 : return detail.low_ratio.home
                case 2 : return "무승부"
                case 3 : return detail.low_ratio.away
            }
        }
        else if(detail.market_id === 2) {
            const line = detail.low_ratio.market_baseline
            switch (detail.bet_no) {
                case 1 : return `${detail.low_ratio.home} (${line})`
                case 3 : return `${detail.low_ratio.away} (${line})`
            }
        }
        else if(detail.market_id === 3) {
            const line = parseFloat(detail.low_ratio.market_baseline.replace(" (0-0)", ""))
            switch (detail.bet_no) {
                case 1 : return `${detail.low_ratio.home} (${line > 0? "+" : ""}${line})`
                case 3 : return `${detail.low_ratio.away} (${line * -1.0 > 0? "+" : ""}${line * -1.0})`
            }
        }

    },
    betDetailResult: (result) => {
        switch (result) {
            case 0 : return "진행중"
            case 1 : return "미적중"
            case 2 : return "적중"
            case 3 : return "적특"
        }
    },
    betResult: (bet) => {
        if(bet.status === 3) {
            return "배팅취소"
        }
        else {
            switch (bet.result) {
                case 0 : return "진행중"
                case 1 : return "낙첨"
                case 2 : return "당첨"
                case 3 : return "적특"
            }
        }

    },
    betDetailResultClassName: (result) => {
        switch (result) {
            case 0 : return ""
            case 1 : return "text-secondary"
            case 2 : return "text-primary"
            case 3 : return "text-success"
        }
    },
    betResultClassName: (bet) => {
        if(bet.status === 3) {
            return "text-danger"
        }
        else {
            switch (bet.result) {
                case 0 : return ""
                case 1 : return "text-secondary"
                case 2 : return "text-primary"
                case 3 : return "text-success"
            }
        }

    },
    marketType: (marketId) => {
        switch (marketId) {
            case 1: return "승무패"
            case 3: return "핸디캡"
            case 2: return "오버언더"
        }
    },
    betStatus: (status) => {
        switch (status) {
            case 0: return "진행"
            case 1: return "마감"
            case 2: return "완료"
            case 3: return "취소"
        }
    },
    checkedCancelBet: (bet) => {
        if(bet.status === 0) {
            for(const detail of bet.user_bet_details) {
                if(detail.status !== 1) {
                    return false
                }
            }
            return true
        }
        else {
            return false
        }
    }

}
export default typeCase;
