import React from "react";

const ConnectListComponent = ({}) => {
    return(
        <div>
            <table className="table table-bordered table-sm text-center align-middle">
                <thead className="table-dark">
                <tr>
                    <td>번호</td>
                    <td>이름</td>
                    <td>IP</td>
                    <td>국가</td>
                    <td>시간</td>
                    <td>링크</td>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>001</td>
                    <td>테스트유저(testuser)</td>
                    <td>127.0.0.1</td>
                    <td>대한민국</td>
                    <td>2022-08-28 20:14:24</td>
                    <td>크로스</td>
                </tr>
                <tr>
                    <td>002</td>
                    <td>테스트유저1(testuser1)</td>
                    <td>127.0.0.1</td>
                    <td>대한민국</td>
                    <td>2022-08-28 20:14:24</td>
                    <td>크로스</td>
                </tr>
                <tr>
                    <td>003</td>
                    <td>테스트유저2(testuser2)</td>
                    <td>127.0.0.1</td>
                    <td>캄보디아</td>
                    <td>2022-08-28 20:14:24</td>
                    <td>크로스</td>
                </tr>
                </tbody>

            </table>

        </div>
    )
}

export default ConnectListComponent;