import React, {useEffect, useState} from 'react';
import moment from "moment";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import axios from "axios";
import MemberDepositLog from "../../components/modal/MemberDepositLog";
import MemberWithdrawLog from "../../components/modal/MemberWithdrawLog";
import UserInformation from "../../components/UserInformation";
import MemberBettingLog from "../../components/modal/MemberBettingLog";
import typeCase from "../../util/typeCase"
const HelpList = ({help, onRefresh}) => {

    const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().add(1, "day").format('YYYY-MM-DD'));
    const [helpList, setHelpList] = useState([]);
    const [autoReply, setAutoReply] = useState([]);
    const [autoReplyItem, setAutoReplyItem] = useState([])
    const [helpNos, setHelpNos] = useState([]);
    const [replyNos, setReplyNos] = useState([]);
    const [recentlyDeposit, setRecentlyDeposit] = useState([]);
    const [cont, setCont] = useState([]);
    const [replyCont, setReplyCont] = useState([]);
    const [autoReplyTitle, setAutoReplyTitle] = useState([]);
    const [autoReplyCont, setAutoReplyCont] = useState([]);
    const [autoItemTitle, setAutoItemTitle] = useState([]);
    const [type, setType] = useState(0);
    const [query, setQuery] = useState("");

    const initialState = useSelector((state) => state.help)

    const [userInformation, setUserInformation] = useState(false);
    const [memberBettingLog, setMemberBettingLog] = useState(false);
    const [memberDepositLog, setMemberDepositLog] = useState(false);
    const [memberWithdrawLog, setMemberWithdrawLog] = useState(false);
    const [userNo, setUserNo] = useState(false)


    const userInfo = useSelector((state) => state.userInfo);
    const getHelpList = () => {
        if (helpList !== null) {
            axios.post('/admin/help/get_help_list', {
                adminNo: userInfo.userNo
            }).then((res) => {
                //console.log(res.data)
                if (res.data.helpList !== null) {
                    setHelpList(res.data.helpList)
                    setAutoReply(res.data.autoReply)
                    setAutoReplyItem(res.data.autoReplyItem)
                    setRecentlyDeposit(res.data.recentlyDeposit)
                    setHelpNos([])
                    setReplyNos([])
                }
            })
        }
    }

    useEffect(getHelpList, [])


    const onContHandler = (e) =>{
        setCont(e.currentTarget.value);
    }
    const onReplyContHandler = (e) =>{
        setReplyCont(e.currentTarget.value);

    }
    const onAutoReplyTitleHandler = (e) =>{
        setAutoReplyTitle(e.currentTarget.value);
    }
    const onAutoReplyContHandler = (e) =>{
        setAutoReplyCont(e.currentTarget.value);
    }
    const onTypeHandler = (e) =>{
        setType(e.currentTarget.value);
        //console.log(e.currentTarget.value)
    }
    const startDateHandler = (e) => {
        setStartDate(e.currentTarget.value)
    }
    const endDateHandler = (e) => {
        setEndDate(e.currentTarget.value)
    }

    const clickHandler = (e) => {
        setReplyCont(e);
    }
    const allCheckHandler = (e) => {
        if(helpList.length > 0 && e.currentTarget.value) {
            const checks = [];
            for(let i = 0; i < helpList.length; i++) {
                checks.push(helpList[i].no)
                //console.log(helpList[i].no)
            }
            setHelpNos(e.target.checked? checks : [])
        }
    }
    const checkHandler = (checked, no) => {
        let helpNo2 = []
        if(checked) {
            helpNo2 = [...helpNos, no]
            setHelpNos([...helpNos, no])
        }
        else {
            helpNo2 = helpNos.filter((id) => id !== no)
            setHelpNos(helpNos.filter((id) => id !== no))
        }
        let totalCheck = 0;
        for(let i = 0; i < helpNo2.length; i++) {
            totalCheck += helpList.filter((id) => id.no === helpNo2[i])[0].no
        }
    }
    const replyCheckHandler = (checked, no) => {
        let replyNos2 = []
        if(checked) {
            replyNos2 = [...replyNos, no]
            setReplyNos([...replyNos, no])
        }
        else {
            replyNos2 = replyNos.filter((id) => id !== no)
            setReplyNos(replyNos.filter((id) => id !== no))
        }
        let totalCheck = 0;
        for(let i = 0; i < replyNos2.length; i++) {
            totalCheck += autoReply.filter((id) => id.no === replyNos2[i])[0].no
        }
    }
    const helpInsertReply = (helpNo) => {
        if (window.confirm("등록 하시겠습니까?")) {
            axios.post('/admin/help/insert_reply', {
                helpNo: helpNo,
                cont: replyCont,
                adminNo: userInfo.userNo
            }).then((res) => {
                alert(res.data.isInserted? "등록완료" : "등록실패")
                //getHelpList()
                onRefresh();
            })
        }
        
        
    }

    const helpInsertAutoReply = (e) => {
        if(type === 0) {
            alert("항목 선택 바랍니다")
        } else {
            if (window.confirm("등록 하시겠습니까?")) {
                axios.post('/admin/help/insert_auto_reply', {
                    type: type,
                    title: autoReplyTitle,
                    cont: autoReplyCont,
                    adminNo: userInfo.userNo
                }).then((res) => {
                    alert(res.data.isInserted ? "등록완료" : "등록실패")
                    //getHelpList()
                    onRefresh();
                })
            }
        }
    }
    const helpDelete = (e) => {

        if (window.confirm("삭제 하시겠습니까?")) {
            axios.post('/admin/help/delete', {
                nos: helpNos,
                adminNo: userInfo.userNo
            }).then((res) => {
                alert(res.data.isDeleted? "삭제완료" : "삭제실패")
                //getHelpList()
                onRefresh();
            })
        }
    }
    const helpReplyDelete = (helpNo) => {
        if (window.confirm("삭제 하시겠습니까?")) {
            axios.post('/admin/help/delete_Reply', {
                nos: helpNo,
                adminNo: userInfo.userNo
            }).then((res) => {
                alert(res.data.isDeleted? "삭제완료" : "삭제실패")
                //getHelpList()
                onRefresh();
            })
        }

    }
    const helpAutoDelete = (no) => {

        if (window.confirm("삭제 하시겠습니까?")) {
            axios.post('/admin/help/delete_auto_reply', {
                nos: replyNos,
                no: no,
                adminNo: userInfo.userNo
            }).then((res) => {
                alert(res.data.isDeleted? "삭제완료" : "삭제실패")
                //getHelpList()
                onRefresh();
            })
        }

    }

    const onAutoItemTitleHandler = (e) =>{
        setAutoItemTitle(e.currentTarget.value);
    }
    const helpInsertAutoReplyItem = (e) => {

        if (window.confirm("등록 하시겠습니까?")) {
            axios.post('/admin/help/insert_auto_reply_item', {
                title: autoItemTitle,
                adminNo: userInfo.userNo
            }).then((res) => {
                alert(res.data.isInserted? "등록완료" : "등록실패")
                //getHelpList()
                onRefresh();
            })
        }


    }
    const helpDeleteAutoReplyItem = (no) => {

        if (window.confirm("삭제 하시겠습니까?")) {
            axios.post('/admin/help/delete_auto_reply_item', {
                no: no,
                adminNo: userInfo.userNo
            }).then((res) => {
                alert(res.data.isDeleted? "삭제완료" : "삭제실패")
                //getHelpList()
                onRefresh();
            })
        }


    }

    const queryHandler = (e) => {
        setQuery(e.target.value)
    }
    const inputPress = (e) => {
        if(e.key === "Enter") {
            searchHandler(query, startDate, endDate)
        }
    }

    const searchHandler = (query, startDate, endDate) => {
        axios.post('/admin/help/query', {
            start_date: startDate,
            end_date: endDate,
            query: query,
        }).then((res) => {
            if (res.data.helpList !== null) {
                setHelpList(res.data.helpList)
            }
        })
    }

    const informationOpenHandler = () => {
        setUserInformation(true);
    }
    const memberBettingLogOpen = (open, userNo) => {
        setMemberBettingLog(open);
        setUserNo(userNo)
    }
    const memberDepositLogOpen = (open, userNo) => {
        setMemberDepositLog(open);
        setUserNo(userNo)
    }
    const memberWithdrawLogOpen = (open, userNo) => {
        setMemberWithdrawLog(open);
        setUserNo(userNo)
    }

    const replace = () => {
        window.location.replace('/board/help')
    }

    function onCommentColor (color) {
        switch (color) {
            case 0 : return "red"
            case 1 : return "orange"
            case 2 : return "yellow"
            case 3 : return "green"
            case 4 : return "blue"
            case 5 : return "navy"
            case 6 : return "purple"
            case 10 : return "gray"
            default : return color
        }
    }

    return (
       <div className="m-2">
            <div className="card-body">
                <div className="container-fluid">
                    <div className="row bg-light align-items-center" >
                        <div className="col-6">
                        </div>

                        <div className="col align-items-center justify-content-end d-flex" style={{
                            height: "50px"
                            }}>


                            <div className="input-group align-items-end" style={{
                                width: "40%",
                                height: "40px",
                            }}>

                            </div>
                            <button className="btn btn-outline-secondary ms-1" type="button"
                                    id="button-addon2" onClick={helpDelete}>삭제
                            </button>
                        </div>
                    </div>

                </div>
                <div>
                    <table className="table table-bordered table-sm text-center align-middle helpTitle">
                        <thead className="table-dark">
                        <tr>
                            <th style={{width: "30px"}}>
                                <input className="form-check-input" type="checkbox" checked={helpList.length === helpNos.length}
                                       onChange={allCheckHandler} id="flexCheckDefault"/>
                            </th>
                            <th style={{width: "5%"}}>파트너</th>
                            <th style={{width: "4%"}}>상태</th>
                            <th style={{width: "4%"}}>등급</th>
                            <th style={{width: "8%"}}>작성자</th>
                            <th>제목</th>
                            <th style={{width: "10%"}}>문의시간</th>
                            <th style={{width: "10%"}}>답변시간</th>
                            <th style={{width: "10%"}}>최근충전일</th>
                            <th style={{width: "17%"}}>작성자정보</th>
                            <th style={{width: "5%"}}>관리자</th>
                        </tr>
                        </thead>

                        {!help.length && (
                            <tbody key={help.no}>
                            <tr>
                                <td colSpan="11">목록이 비어있습니다.</td>
                            </tr>
                            </tbody>
                        )}

                        {help.map((help, index) => (
                            <tbody key={help.no}>
                            <tr>
                                <td>
                                    <input className="form-check-input" type="checkbox"
                                           checked={helpNos.filter((no) => no === help.no).length > 0}
                                           onChange={(e) => {checkHandler(e.currentTarget.checked, help.no)}}
                                    />
                                </td>
                                <td>{typeCase.userType(help.user.user_type)}</td>
                                <td>{typeCase.userState(help.user.user_state)}</td>
                                <td>{typeCase.levelState(help.user.level)}</td>
                                <td>
                                    {help.user.comment ?
                                        <>
                                            <span>{help.user.nickname}</span>
                                            <br></br>
                                            <span style={{fontSize: "12px"}}>
                                                    <span className="me-1" style={{backgroundColor: onCommentColor(help.user.comment_color)}}>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </span>
                                                {help.user.comment}
                                                </span>
                                        </>
                                        :  (help.user.comment_color !== null?
                                            <>
                                                <span>{help.user.nickname}</span>
                                                <br></br>
                                                <span className="me-1" style={{fontSize: "12px", backgroundColor: onCommentColor(help.user.comment_color)}}>
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                </span>
                                            </>
                                            :  <span>{help.user.nickname}</span> )
                                    }
                                </td>
                                <td>
                                    <Link className="nav-link" to={"#collapse" + index} data-bs-toggle="collapse"
                                          aria-expanded="true" aria-controls={"#collapse" + index}>
                                        {help.title}
                                    </Link>
                                </td>
                                <td>{moment(help.createdAt).format("YYYY-MM-DD HH:mm")}</td>
                                <td>
                                    {help.createdAt !== help.updatedAt?
                                        moment(help.updatedAt).format("YYYY-MM-DD HH:mm") :
                                        "-"
                                    }
                                </td>
                                <td>
                                    {recentlyDeposit.filter((el) => el.user_no === help.user.no).length > 0?
                                        moment(recentlyDeposit.filter((el) => el.user_no === help.user.no)[0].updatedAt).format("YYYY-MM-DD HH:mm:ss")
                                        : "-"}
                                </td>
                                <td className="d-flex justify-content-between ">
                                    <button className="btn btn-primary m-1" onClick={() => memberDepositLogOpen("true", help.user.no)}>
                                        충전내역</button>
                                    {memberDepositLog && <MemberDepositLog userNo={userNo} setMemberDepositLog={setMemberDepositLog}/>}

                                    <button className="btn btn-danger m-1" onClick={() => memberWithdrawLogOpen("true", help.user.no)}>
                                        환전내역</button>
                                    {memberWithdrawLog && <MemberWithdrawLog userNo={userNo} setMemberWithdrawLog={setMemberWithdrawLog}/>}

                                    <button className="btn btn-secondary m-1" onClick={() => memberBettingLogOpen("true", help.user.no)}>
                                        배팅내역</button>
                                    {memberBettingLog && <MemberBettingLog userNo={userNo} setMemberBettingLog={setMemberBettingLog}/>}
                                </td>
                                <td>
                                    {help.admin?
                                        help.admin.id :
                                        "-"}
                                </td>
                            </tr>
                            <tr className="collapse " id={"collapse" + index}>
                                <td className="border border-dark" colSpan="11">
                                    <div className="d-flex">
                                        <div style={{width: "75%"}}>
                                            <div className="bg-light p-2 d-flex justify-content-between">
                                                <p className="my-auto">예금주: {help.user.name}</p>
                                                <h4 className="text-danger ms-2">최근충전일:
                                                    {recentlyDeposit.filter((el) => el.user_no === help.user.no).length > 0?
                                                        moment(recentlyDeposit.filter((el) => el.user_no === help.user.no)[0].updatedAt).format("YYYY-MM-DD")
                                                    : "-"}
                                                </h4>
                                                <Link className="nav-link" to={"#userInformation" + index} data-bs-toggle="collapse"
                                                      aria-expanded="false" aria-controls={"#userInformation" + index} >
                                                    <button className="btn btn-outline-secondary " type="button" onClick={informationOpenHandler}>
                                                        회원정보
                                                    </button>
                                                </Link>
                                            </div>
                                            <tr className="collapse" id={"userInformation" + index} >
                                                {userInformation && <UserInformation no={help.user.no} id={help.user.id}/> }
                                            </tr>
                                            <p className="text-start p-2">
                                                {help.cont}
                                            </p>
                                            {help.help_replies.map((help_reply, index) => (

                                            <ul className="list-group list-group-flush text-start border-top border-dark" key={help_reply.no}>
                                                <li className="list-group-item"></li>
                                                <li className="list-group-item">
                                                    <div>
                                                        <p>답변 시각 : {moment(help_reply.createdAt).format("YYYY-MM-DD HH:mm")}</p>
                                                        <p>답변 내용 : {help_reply.cont}</p>
                                                        <div className="justify-content-end d-flex">
                                                            <button className="btn btn-outline-secondary me-1"
                                                                    type="button" id="button-addon2" onClick={() => helpReplyDelete(help_reply.no)}>
                                                                삭제
                                                            </button>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                                ))}

                                            <div className="bg-light d-flex">
                                                <h4 className="text-danger ms-2">답변내용</h4>
                                            </div>

                                            <textarea className="form-control" maxLength="3000" value={replyCont} id="exampleFormControlTextarea1" rows="4"
                                                  onChange={onReplyContHandler}></textarea>

                                            <div className="text-center justify-content-center d-flex my-2" style={{
                                                width: "100%"
                                            }}>

                                                <button className="btn btn-primary me-1" type="button"
                                                        id="button-addon2"
                                                        onClick={() => helpInsertReply(help.no)}>저장
                                                </button>
                                                <button className="btn btn-outline-secondary" type="button"
                                                        id="button-addon2" onClick={replace} >목록
                                                </button>
                                            </div>
                                        </div>

                                        <div className="accordion" id="accordionExample" style={{
                                            width: "25%"
                                        }}>
                                            <div className="accordion-item">
                                                {autoReplyItem.map((item, index) => (
                                                    <div key={item.no}>
                                                        <div className="accordion-header d-flex" id={"heading" + (item.no)}>
                                                            <button className="accordion-button" type="button"
                                                                    data-bs-toggle="collapse" data-bs-target={"#collapseReply" + (item.no)}
                                                                    aria-expanded="false" aria-controls="collapseOne">
                                                                {item.title}
                                                            </button>
                                                            { userInfo.level === 2?
                                                            <button className="justify-content-end" onClick={() => helpDeleteAutoReplyItem(item.no)}>삭제</button>
                                                                : <></>
                                                            }
                                                        </div>

                                                        <div id={"collapseReply" + (item.no)} className="accordion-collapse collapse show"
                                                             aria-labelledby={"heading" + (item.no)}
                                                             data-bs-parent="#accordionExample">
                                                            {autoReply.map((auto_reply, index) =>(
                                                                <div key={auto_reply.no}>
                                                                {
                                                                    auto_reply.type === item.no?
                                                                    <div className="accordion-body text-start mt-1 p-1 d-flex">
                                                                        <div className="my-auto" style={{
                                                                            width: "10%"
                                                                        }}>
                                                                            <input className="form-check-input" type="checkbox"
                                                                                   checked={replyNos.filter((no) => no === auto_reply.no).length > 0}
                                                                                   onChange={(e) => {replyCheckHandler(e.currentTarget.checked, auto_reply.no)}}
                                                                            />
                                                                        </div>
                                                                        <div className="mx-auto my-auto">
                                                                            <span onClick={() => clickHandler(auto_reply.cont)}>
                                                                                {auto_reply.title}
                                                                            </span>
                                                                        </div>
                                                                        <div className="justify-content-end d-flex">
                                                                            <button className="btn btn-outline-secondary me-1"
                                                                                   type="button" id="button-addon2" onClick={() => helpAutoDelete(auto_reply.no)}>
                                                                                삭제
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                        : <></>
                                                                }
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>

                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="headingFour">
                                                    <button className="accordion-button collapsed" type="button"
                                                            data-bs-toggle="collapse" data-bs-target="#collapseFour"
                                                            aria-expanded="false" aria-controls="collapseFour">
                                                        신규추가
                                                    </button>
                                                </h2>
                                                <div id="collapseFour" className="accordion-collapse collapse"
                                                     aria-labelledby="headingFour"
                                                     data-bs-parent="#accordionExample">
                                                    <div className="accordion-body">
                                                        <input className="form-control" maxLength="100" type="text" value={autoReplyTitle} placeholder="제목"
                                                               onChange={onAutoReplyTitleHandler}/>
                                                        <textarea className="form-control" maxLength="3000" value={autoReplyCont} id="exampleFormControlTextarea1" rows="3"
                                                                  onChange={onAutoReplyContHandler}></textarea>

                                                        <div className="d-flex">
                                                            <select className="form-select" onChange={onTypeHandler} value={type}>
                                                                <option key={0} value={0}>---항목선택---</option>
                                                                {autoReplyItem.map((item, index) => (
                                                                    <option key={item.no} value={item.no}>{item.title}</option>
                                                                ))}
                                                            </select>

                                                            <button className="btn btn-primary" type="button" id="button-addon2"
                                                                    onClick={helpInsertAutoReply}
                                                                    style={{
                                                                        width: "100px"
                                                                    }}>추가
                                                            </button>
                                                        </div>
                                                    </div>
                                                    {userInfo.level === 2?
                                                    <div className="ms-4 d-flex">
                                                        <label className="my-auto">항목</label>
                                                        <div className="d-flex">
                                                            <input className=" ms-1" type="text" maxLength="100" value={autoItemTitle} onChange={onAutoItemTitleHandler} style={{width: "210px"}}/>
                                                            <button className="btn btn-primary" onClick={helpInsertAutoReplyItem} style={{width: "80px"}}>추가</button>
                                                        </div>
                                                    </div>
                                                        : <></>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        ))}
                    </table>
                </div>
            </div>
       </div>
    )
}

export default HelpList;