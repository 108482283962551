import React from 'react';
import LeagueListApiSettingContainer from "../../containers/match/LeagueListApiSettingContainer";


const LeagueListApiSettingPage = () => {
    return (
        <div>
            <h3>리그목록</h3>
            <LeagueListApiSettingContainer/>
        </div>
    );
};

export default LeagueListApiSettingPage;