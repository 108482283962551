import React, {useState} from "react";
import axios from "axios";
import {useDispatch} from "react-redux";
import {setCheckedInputs, setNameInputs, setUpdateData} from "../../redux/reducers/match/settingMarket";

const SettingMarketList = ({settingMarkets}) => {
    const dispatch = useDispatch()

    const changeHandler = (checked, id, index) => {

        if (checked) {
            dispatch(setCheckedInputs([...settingMarkets.checkedInputs, id]));
        } else {
            // 체크 해제
            dispatch(setCheckedInputs(settingMarkets.checkedInputs.filter((el) => el !== id)));
        }

        const data = {
            id: settingMarkets.settingMarkets[index].id,
            name_kor: settingMarkets.settingMarkets[index].name_kor,
            is_active: settingMarkets.settingMarkets[index].is_active,
            createdAt: settingMarkets.settingMarkets[index].createdAt,
            updatedAt: settingMarkets.settingMarkets[index].updatedAt,
            market_id: settingMarkets.settingMarkets[index].market_id,
            sport_id: settingMarkets.settingMarkets[index].sport_id,
        }

        const updateData2 = settingMarkets.updateData.filter((el) => el.id !== id);
        const updateData3 = settingMarkets.updateData.filter((el) => el.id === id);
        if(updateData3.length > 0) {
            data.name_kor = updateData3[0].name_kor
        }
        data.is_active = checked;
        dispatch(setUpdateData([...updateData2, data]))
    };

    const allChangeHandler = (e) => {

        const data_arr = []
        const check_ids = []
        for(let i = 0; i < settingMarkets.settingMarkets.length; i++) {
            const data = {
                id: settingMarkets.settingMarkets[i].id,
                name_kor: settingMarkets.settingMarkets[i].name_kor,
                is_active: settingMarkets.settingMarkets[i].is_active,
                createdAt: settingMarkets.settingMarkets[i].createdAt,
                updatedAt: settingMarkets.settingMarkets[i].updatedAt,
                market_id: settingMarkets.settingMarkets[i].market_id,
                sport_id: settingMarkets.settingMarkets[i].sport_id,
            }
            data.is_active = e.target.checked
            const updateData3 = settingMarkets.updateData.filter((el) => el.id === data.id);
            if(updateData3.length > 0) {
                data.name_kor = updateData3[0].name_kor
            }
            data_arr.push(data)
            check_ids.push(data.id)
        }

        dispatch(setCheckedInputs(e.target.checked? check_ids : []))
        dispatch(setUpdateData(data_arr))

    }

    const changeName = (value, id, index) => {
        const data = {
            id: settingMarkets.settingMarkets[index].id,
            name_kor: settingMarkets.settingMarkets[index].name_kor,
            is_active: settingMarkets.settingMarkets[index].is_active,
            createdAt: settingMarkets.settingMarkets[index].createdAt,
            updatedAt: settingMarkets.settingMarkets[index].updatedAt,
            market_id: settingMarkets.settingMarkets[index].market_id,
            sport_id: settingMarkets.settingMarkets[index].sport_id,
        }

        const updateData2 = settingMarkets.updateData.filter((el) => el.id !== id);
        const updateData3 = settingMarkets.updateData.filter((el) => el.id === id);
        if(updateData3.length > 0) {
            data.is_active = updateData3[0].is_active
        }
        data.name_kor = value;
        dispatch(setUpdateData([...updateData2, data]))
        const nameInputs2 = settingMarkets.nameInputs.filter((el) => el.id !== id);
        dispatch(setNameInputs([...nameInputs2, {id: id, name_kor: value}]))
    }

    const saveSetMarketData = async () => {
        axios.post('/api/updateSetMarket', settingMarkets.updateData).then( (res) => {
            if(res.status === 200) {
                dispatch(setUpdateData([]));
                alert("저장완료");
            }
        })
    }

    return (
        <div>
            <input type="button" value="저장" onClick={saveSetMarketData} />


            {settingMarkets._isLoading && "로딩중..."}
            {!settingMarkets._isLoading && settingMarkets.settingMarkets && (
                <div>
                    <p>{settingMarkets.sport_name}</p>
                <table className="table table-bordered table-hover text-center">
                        <thead>
                            <tr>
                                <th>마켓 ID</th>
                                <th>마켓명</th>
                                <th>종목별 마켓명</th>
                                <th>
                                    사용여부
                                    <input
                                        type="checkbox"
                                        checked={
                                            settingMarkets.checkedInputs.length === settingMarkets.settingMarkets.length
                                        }
                                        onChange={(e) => {allChangeHandler(e)}}
                                    />
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                        {!settingMarkets.settingMarkets.length && (
                            <tr>
                                <td colSpan="3">목록이 비어있습니다.</td>
                            </tr>
                        )}
                        {!!settingMarkets.settingMarkets.length && settingMarkets.settingMarkets.map((m, index) => (
                            <tr>
                                <td>{m.market.id}</td>
                                <td>{m.market.name_kor? m.market.name_kor:m.market.name}</td>
                                <td>
                                    <input
                                        name={index}
                                        className="form-control"
                                        type="text"
                                        value={settingMarkets.nameInputs.filter((e) => e.id === m.id).length > 0 ? settingMarkets.nameInputs.filter((e) => e.id === m.id)[0].name_kor: ""}
                                        onChange={(e) => {
                                            changeName(e.currentTarget.value, m.id, index)
                                        }}
                                    />
                                </td>
                                <td>
                                    <label>
                                        사용여부
                                        <input type="checkbox" name={m.id} id={m.id} value="true"
                                               checked={settingMarkets.checkedInputs.includes(m.id)}
                                               onChange={(e) => {changeHandler(e.currentTarget.checked, m.id, index)}}
                                        />
                                    </label>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    )
}
export default SettingMarketList;