import { createSlice } from "@reduxjs/toolkit";

export const leagueSlice = createSlice({
    name: 'league',
    initialState: {
        isLoading: false,
        leagues: [],
        error: null,
        isConnect: false,
        tasks: [],
        is_active: 0,
        page: 1,
        pageSize: 100,
        totalCount: 0,
        sport: 0,
        sports: [],
        nation: 0,
        nations: [],
        query: "",
        checkedInputs: [],
        nameInputs: [],
        updateData: [],
    },
    reducers: {
        getLeague(state, action) {
            state.isLoading = true;
            state.is_active = action.payload.is_active;
            state.page = action.payload.pageSize === 999? 1: action.payload.page;
            state.pageSize = action.payload.pageSize;
            state.sport = action.payload.sport;
            state.nation = action.payload.nation;
            state.query = action.payload.query
        },
        setLeague(state, action) {
            if((state.page -1) * state.pageSize > action.payload.totalCount) {
                state.page = 1
            }

            state.isLoading = false;
            state.leagues = action.payload.result
            state.totalCount = action.payload.totalCount;
            state.sports = action.payload.sports;
            state.nations = action.payload.nations;
            const checks = [];
            const inputs = [];
            for(const r of action.payload.result) {
                if(r.is_active) {
                    checks.push(r.id);
                }
                if(r.name_kor) {
                    inputs.push({id: r.id, name_kor: r.name_kor})
                }
            }
            state.checkedInputs = checks;
            state.nameInputs = inputs;
        },
        setCheckedInputs(state, action) {
            state.checkedInputs = action.payload;
        },
        setUpdateData(state, action) {
            state.updateData = action.payload;
        },
        setNameInputs(state, action) {
            state.nameInputs = action.payload;
        },
        failedGetLeague(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        waitLeagueTask(state) {
            state.isConnect = true
        },
        pushLeagueTask(state, action) {
            state.tasks.push(action.payload)
        },
        changeLeague(state, action) {
            //변경된 인자값의 인덱스넘버를 찾아야된다.
            const changedItem = action.payload
            const beforeArray = state.leagues.filter((item) => item.id === changedItem.id)
            //찾은후 변경사항업데이트
            const index = state.leagues.indexOf(beforeArray[0])
            state.leagues[index] = changedItem
            console.log(index)
        }
    },
});

export const { getLeague, setLeague, setCheckedInputs, setUpdateData, setNameInputs, failedGetLeague, waitLeagueTask, pushLeagueTask, changeLeague } = leagueSlice.actions;

export default leagueSlice.reducer;