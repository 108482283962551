import React from 'react';
import moment from "moment";
import {Link, Outlet} from "react-router-dom";

const StatisticDetail = () => {
    return (
        <div>
            <div style={{
                paddingLeft: "10px",
                paddingTop: "10px",
            }}>
                <p>
                    정산관리 > 통합세부내역
                </p>
            </div>
            <div className="card" style={{
                margin: "10px"
            }}>
                <div className="card-body">
                    <div>
                        <div className="container-fluid">
                            <div className="row bg-light align-items-center" >
                                <div className="col-2">
                                    통합세부내역
                                </div>

                                <div className="col-10 align-items-center justify-content-end" style={{
                                    display:"flex",
                                    height: "50px"
                                }}>
                                    <select className="form-select me-1"  style={{
                                        width: "150px",
                                        height: "40px",
                                    }}>
                                        <option selected>부본사코드</option>
                                        <option value="1">a11</option>
                                        <option value="2">v22</option>
                                        <option value="3">c33</option>
                                        <option value="4">d444</option>
                                    </select>
                                    <select className="form-select me-1"  style={{
                                        width: "150px",
                                        height: "40px",
                                    }}>
                                        <option selected>총판코드</option>
                                        <option value="1">총판11</option>
                                        <option value="2">총판22</option>
                                        <option value="3">총판33</option>
                                        <option value="4">총판44</option>
                                    </select>

                                    <select className="form-select me-1"  style={{
                                        width: "150px",
                                        height: "40px",
                                    }}>
                                        <option selected>매장코드</option>
                                        <option value="1">매장11</option>
                                        <option value="2">매장22</option>
                                        <option value="3">매장33</option>
                                        <option value="4">매장44</option>
                                    </select>

                                    <select className="form-select me-1"  style={{
                                        width: "150px",
                                        height: "40px",
                                    }}>
                                        <option selected>회원구분</option>
                                        <option value="10">정상</option>
                                        <option value="1">보류</option>
                                        <option value="9">요주의</option>
                                        <option value="2">신규</option>
                                        <option value="3">악성</option>
                                        <option value="4">미이용</option>
                                        <option value="5">휴면</option>
                                        <option value="6">탈퇴</option>
                                        <option value="7">졸업</option>
                                        <option value="8">블랙</option>
                                    </select>

                                    <input type="date" className="form-control mx-1" value="" style={{
                                        width: "150px",
                                        height: "40px"
                                    }}/>
                                    <span className="mx-1">~</span>
                                    <input type="date" className="form-control mx-1" value="" style={{
                                        width: "150px",
                                        height: "40px"
                                    }}/>


                                    <button className="btn btn-outline-secondary me-1" type="button"
                                            id="button-addon2" style={{
                                        width: "80px",
                                        height: "40px"
                                    }}>검색
                                    </button>

                                    <button className="btn btn-outline-secondary" type="button"
                                            id="button-addon2" style={{
                                        width: "120px",
                                        height: "40px"
                                    }}>엑셀출력
                                    </button>
                                </div>

                            </div>
                        </div>
                        <div>
                            <span>- 통합</span>
                            <table className="table table-bordered table-sm text-center align-middle my-2">
                                <thead className="table-dark">
                                <tr>
                                   <td>보유머니</td>
                                   <td>진행중</td>
                                   <td>충전건수</td>
                                   <td>충전금액</td>
                                   <td>환전건수</td>
                                   <td>환전금액</td>
                                   <td className="text-danger">충환차액</td>
                                   <td>총배팅금액</td>
                                   <td>총당첨금액</td>
                                   <td className="text-danger">총배팅차액</td>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>45,722,519원</td>
                                    <td>13,857,171원</td>
                                    <td>416건</td>
                                    <td>75,796,000원</td>
                                    <td>54건</td>
                                    <td>64,220,000원</td>
                                    <td className="text-primary">11,576,000원</td>
                                    <td>103,615,285원</td>
                                    <td>51,698,928원</td>
                                    <td className="text-primary">51,916,357원</td>
                                </tr>
                                </tbody>

                            </table>

                        </div>
                        <div className="my-3">
                            <span className="text-success">- 스포츠</span>
                            <table className="table table-bordered table-sm text-center align-middle my-2">
                                <thead className="table-success">
                                <tr>
                                    <td>스포츠총배팅</td>
                                    <td>스포츠총당첨</td>
                                    <td className="text-danger">스포츠총차액</td>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>103,615,285원</td>
                                    <td>51,698,928원</td>
                                    <td className="text-primary">51,916,357원</td>
                                </tr>
                                </tbody>

                            </table>
                            <table className="table table-bordered table-sm text-center align-middle my-2">
                                <thead className="table-success">
                                <tr>
                                   <td>국내형스포츠배팅</td>
                                   <td>국내형스포츠당첨</td>
                                   <td className="text-danger">국내형스포츠차액</td>
                                   <td>유럽형스포츠배팅</td>
                                   <td>유럽형스포츠당첨</td>
                                   <td className="text-danger">유럽형스포츠차액</td>
                                   <td>인플레이배팅</td>
                                   <td>인플레이당첨</td>
                                   <td className="text-danger">인플레이차액</td>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>76,976,011원</td>
                                    <td>25,486,345원</td>
                                    <td className="text-primary">51,489,666원</td>
                                    <td>76,976,011원</td>
                                    <td>25,486,345원</td>
                                    <td className="text-primary">51,489,666원</td>
                                    <td>76,976,011원</td>
                                    <td>25,486,345원</td>
                                    <td className="text-primary">51,489,666원</td>
                                </tr>
                                </tbody>

                            </table>

                        </div>
                        <div className="my-3">
                            <span className="text-warning">- 미니게임</span>
                            <table className="table table-bordered table-sm text-center align-middle mt-2">
                                <thead className="table-warning">
                                <tr>
                                    <td>미니게임총배팅</td>
                                    <td>미니게임총당첨</td>
                                    <td className="text-danger">미니게임총차액</td>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>103,615,285원</td>
                                    <td>51,698,928원</td>
                                    <td className="text-primary">51,916,357원</td>
                                </tr>
                                </tbody>

                            </table>
                            <table className="table table-bordered table-sm text-center align-middle">
                                <thead className="table-warning">
                                <tr>
                                    <td>넥스트바카라배팅</td>
                                    <td>넥스트바카라당첨</td>
                                    <td className="text-danger">넥스트바카라차액</td>
                                    <td>넥스트사다리배팅</td>
                                    <td>넥스트사다리당첨</td>
                                    <td className="text-danger">넥스트사다리차액</td>
                                    <td>넥스트파워볼배팅</td>
                                    <td>넥스트파워볼당첨</td>
                                    <td className="text-danger">넥스트파워볼차액</td>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>14,188,079원</td>
                                    <td>14,986,735원</td>
                                    <td className="text-danger">-798,656원</td>
                                    <td>162,436원</td>
                                    <td>68,250원</td>
                                    <td className="text-primary">94,186원</td>
                                    <td>6,413,728원</td>
                                    <td>7,017,479원</td>
                                    <td className="text-danger">-603,751원</td>
                                </tr>
                                </tbody>

                            </table>
                            <table className="table table-bordered table-sm text-center align-middle">
                                <thead className="table-warning">
                                <tr>
                                    <td>EOS 5분파워볼배팅</td>
                                    <td>EOS 5분파워볼당첨</td>
                                    <td className="text-danger">EOS 5분파워볼차액</td>
                                    <td>엔트리파워사다리배팅</td>
                                    <td>엔트리파워사다리당첨</td>
                                    <td className="text-danger">엔트리파워사다리차액</td>
                                    <td></td>
                                    <td></td>
                                    <td className="text-danger"></td>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>14,188,079원</td>
                                    <td>14,986,735원</td>
                                    <td className="text-danger">-798,656원</td>
                                    <td>162,436원</td>
                                    <td>68,250원</td>
                                    <td className="text-primary">94,186원</td>
                                    <td></td>
                                    <td></td>
                                    <td className="text-danger"></td>
                                </tr>
                                </tbody>

                            </table>

                        </div>
                        <div>
                            <span className="text-secondary">- 가상게임</span>

                            <table className="table table-bordered table-sm text-center align-middle mt-2">
                                <thead className="table-secondary">
                                <tr>
                                    <td>가상게임총배팅</td>
                                    <td>가상게임총당첨</td>
                                    <td className="text-danger">가상게임총배팅</td>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>103,615,285원</td>
                                    <td>51,698,928원</td>
                                    <td className="text-primary">51,916,357원</td>
                                </tr>
                                </tbody>

                            </table>

                            <table className="table table-bordered table-sm text-center align-middle">
                                <thead className="table-secondary">
                                <tr>
                                    <td>bet365가상축구배팅</td>
                                    <td>bet365가상축구당첨</td>
                                    <td className="text-danger">bet365가상축구차액</td>
                                    <td>bet365개경주배팅</td>
                                    <td>bet365개경주당첨</td>
                                    <td className="text-danger">bet365개경주차액</td>
                                    <td>bet365경마배팅</td>
                                    <td>bet365경마배팅</td>
                                    <td className="text-danger">bet365경마차액</td>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>14,188,079원</td>
                                    <td>14,986,735원</td>
                                    <td className="text-danger">-798,656원</td>
                                    <td>162,436원</td>
                                    <td>68,250원</td>
                                    <td className="text-primary">94,186원</td>
                                    <td>6,413,728원</td>
                                    <td>7,017,479원</td>
                                    <td className="text-danger">-603,751원</td>
                                </tr>
                                </tbody>

                            </table>

                        </div>
                        <div>
                            <table className="table table-bordered table-sm text-center align-middle">
                                <thead className="table-secondary">
                                <tr>
                                    <td>벳페어가상축구배팅</td>
                                    <td>벳페어가상축구당첨</td>
                                    <td className="text-danger">벳페어가상축구차액</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>

                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>14,188,079원</td>
                                    <td>14,986,735원</td>
                                    <td className="text-danger">-798,656원</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                </tbody>

                            </table>

                        </div>
                        <div>
                            <span className="text-primary">- 본사카지노</span>
                            <table className="table table-bordered table-sm text-center align-middle my-2">
                                <thead className="table-primary">
                                <tr>
                                    <td>본사카지노총출금</td>
                                    <td>본사카지노총입금</td>
                                    <td className="text-danger">본사카지노총차액</td>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>103,615,285원</td>
                                    <td>51,698,928원</td>
                                    <td className="text-primary">51,916,357원</td>
                                </tr>
                                </tbody>

                            </table>
                            <table className="table table-bordered table-sm text-center align-middle">
                                <thead className="table-primary">
                                <tr>
                                    <td>로투스홀짝배팅</td>
                                    <td>로투스홀짝당첨</td>
                                    <td className="text-danger">로투스홀짝차액</td>
                                    <td>로투스룰렛배팅</td>
                                    <td>로투스룰렛당첨</td>
                                    <td className="text-danger">로투스룰렛차액</td>
                                    <td></td>
                                    <td></td>
                                    <td className="text-danger"></td>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>6,413,728원</td>
                                    <td>7,017,479원</td>
                                    <td className="text-danger">-603,751원</td>
                                    <td>6,413,728원</td>
                                    <td>7,017,479원</td>
                                    <td className="text-danger">-603,751원</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                </tbody>

                            </table>

                        </div><div>
                            <span className="text-primary">- 라이브카지노</span>
                            <table className="table table-bordered table-sm text-center align-middle my-2">
                                <thead className="table-primary">
                                <tr>
                                    <td>라이브카지노총출금</td>
                                    <td>라이브카지노총입금</td>
                                    <td className="text-danger">라이브카지노총차액</td>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>103,615,285원</td>
                                    <td>51,698,928원</td>
                                    <td className="text-primary">51,916,357원</td>
                                </tr>
                                </tbody>

                            </table>
                            <table className="table table-bordered table-sm text-center align-middle">
                                <thead className="table-primary">
                                <tr>
                                    <td>에볼루션으로출금</td>
                                    <td>에볼루션에서입금</td>
                                    <td className="text-danger">에볼루션출입차액</td>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>6,413,728원</td>
                                    <td>7,017,479원</td>
                                    <td className="text-danger">-603,751원</td>
                                </tr>
                                </tbody>

                            </table>

                        </div>
                    </div>
                </div>
            </div>


        </div>

    )
}

export default StatisticDetail;