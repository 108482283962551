import React from 'react';
import BlockContainer from "../../containers/user/BlockListContainer";


const Block = () => {
    return (
        <div>
            <h1>차단내역</h1>
            <BlockContainer />
        </div>
    );
};

export default Block;