import React from 'react';
import CompleteBettingListContainer from "../../containers/betting/CompleteBettingListContainer";

const CompleteBetting = () => {
    return (
        <div>
            <h1>완료배팅</h1>
            <CompleteBettingListContainer />
        </div>
    )
}

export default CompleteBetting;