import React from 'react';
import {setNationSwitchStatesReducer, setNationListChange,setSelectedRow} from "../../redux/reducers/match/nationApiSettingReducer";
import {useDispatch} from "react-redux";
import Switch from 'react-switch';
import axios from "axios";

const NationListApiSettingComponent = ({initialState,getLeagueInfo,query}) => {
    const dispatch = useDispatch()
    const handleToggle = (code) => {
        dispatch(setNationSwitchStatesReducer(code))
    };

    const handleChangeNm = (e,index) => {
        const newValue = e.currentTarget.value;
        const updatedNationList = [...initialState.nationList]; // Create a copy of the array
        updatedNationList[index] = { ...updatedNationList[index], nation_kor_nm: newValue };
        dispatch(setNationListChange(updatedNationList)); // Update the state with the new array
    }

    const handleUpdate = (nationList) => {
        if (window.confirm("변경 하시겠습니까?")) {
            const no = nationList.no;
            const nationKorNm = nationList.nation_kor_nm;
            const useYn = nationList.use_yn;

            axios.post("/admin/nationApiSettingInfoApi/update", {
                no : no,
                nationKorNm : nationKorNm,
                useYn : useYn,
            }).then((res) => {
                if(res.data.isNationUpdated){
                    alert("변경되었습니다.");
                    query(initialState.nationUseYn);
                }else{
                    alert("변경실패.");
                }
            })
        }
    }

    const changeRowColor = (index) => {
        dispatch(setSelectedRow(index));
    }

    return (
        <div className="m-2">
            <div className="card-body">
                <div>
                    <table className="table table-bordered table-sm text-center align-middle">
                        <thead className="table-dark">
                        <tr>
                            <th style={{width: "10%"}}>코드</th>
                            <th style={{width: "35%"}}>국가명</th>
                            <th style={{width: "5%"}}>영문</th>
                            <th style={{width: "25%"}}>사용여부</th>
                            <th style={{width: "20%"}}>-</th>
                        </tr>
                        </thead>
                        {initialState.nationList && initialState.nationList.length > 0 ?
                            initialState.nationList.map((List, index) => (
                        <tbody key={List.no} style={{ backgroundColor: index === initialState.selectedRow ? '#ead980' : 'transparent' }}>
                        <tr>
                            <td>{List.nation_cd}</td>
                            <td style={{ textAlign: 'center',paddingLeft: '10px' }}>
                                <img src={process.env.PUBLIC_URL +List.nation_img_file_path} /><br />
                                <input type="text" style={{ width: '100%' }} value={List.nation_kor_nm} onChange={(e) => handleChangeNm(e, index)}/></td>
                            <td>{List.nation_eng_nm}</td>
                            <td>
                                <Switch
                                    onChange={() => handleToggle(List.no)}
                                    checked={List.use_yn === 'Y'}
                                onColor="#2196F3"
                                />
                            </td>
                            <td> <button className="my-auto btn btn-sm btn-primary w-50" onClick={() => handleUpdate(List)}>변경</button><br/>
                                <button className="btn btn-sm btn-danger w-50" onClick={() => {getLeagueInfo(List.no); changeRowColor(index);}}>리그정보</button>
                            </td>
                        </tr>
                        </tbody>
                            ))
                            :
                            <tbody>
                            <tr className="table-secondary">
                                <td colSpan= "8"><font size= '3'> 조회된 데이터가 없습니다.</font></td>
                            </tr>
                            </tbody>
                        }
                    </table>
                </div>
            </div>
        </div>
    );
};

export default NationListApiSettingComponent;