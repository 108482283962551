import React, {useState} from "react";
import axios from "axios";
import {useDispatch} from "react-redux";
import {setCheckedInputs, setNameInputs, setUpdateData} from "../../redux/reducers/match/bookmaker";

const BookmakerList = ({bookmakers}) => {
    const dispatch = useDispatch()

    const allChangeHandler = (e) => {
        const data_arr = []
        const check_ids = []
        for(let i = 0; i < bookmakers.bookmakers.length; i++) {
            const data = {
                no: bookmakers.bookmakers[i].no,
                id: bookmakers.bookmakers[i].id,
                name: bookmakers.bookmakers[i].name,
                name_kor: bookmakers.bookmakers[i].name_kor,
                is_active: bookmakers.bookmakers[i].is_active,
                createdAt: bookmakers.bookmakers[i].createdAt,
                updatedAt: bookmakers.bookmakers[i].updatedAt,
            };
            data.is_active = e.target.checked;
            const updateData3 = bookmakers.updateData.filter((el) => el.id === data.id);
            if(updateData3.length > 0) {
                data.name_kor = updateData3[0].name_kor
            }
            data_arr.push(data);
            check_ids.push(data.id);
        }
        dispatch(setCheckedInputs(e.target.checked? check_ids : []))
        dispatch(setUpdateData(data_arr));
    }

    const changeHandler = (checked, id, index) => {
        if(checked) {
            dispatch(setCheckedInputs([...bookmakers.checkedInputs, id]));
        } else {
            // 체크 해제
            dispatch(setCheckedInputs(bookmakers.checkedInputs.filter((el) => el !== id)));
        }
        const data = {
            no: bookmakers.bookmakers[index].no,
            id: bookmakers.bookmakers[index].id,
            name: bookmakers.bookmakers[index].name,
            name_kor: bookmakers.bookmakers[index].name_kor,
            is_active: bookmakers.bookmakers[index].is_active,
            createdAt: bookmakers.bookmakers[index].createdAt,
            updatedAt: bookmakers.bookmakers[index].updatedAt,
        }

        const updateData2 = bookmakers.updateData.filter((el) => el.id !== id);
        const updateData3 = bookmakers.updateData.filter((el) => el.id === id);
        if(updateData3.length > 0) {
            data.name_kor = updateData3[0].name_kor
        }
        data.is_active = checked;
        dispatch(setUpdateData([...updateData2, data]));

    };

    const changeName = (value, id, index) => {
        const data = {
            no: bookmakers.bookmakers[index].no,
            id: bookmakers.bookmakers[index].id,
            name: bookmakers.bookmakers[index].name,
            name_kor: bookmakers.bookmakers[index].name_kor,
            is_active: bookmakers.bookmakers[index].is_active,
            createdAt: bookmakers.bookmakers[index].createdAt,
            updatedAt: bookmakers.bookmakers[index].updatedAt,
        }
        const updateData2 = bookmakers.updateData.filter((el) => el.id !== id);
        const updateData3 = bookmakers.updateData.filter((el) => el.id === id);
        if(updateData3.length > 0) {
            data.is_active = updateData3[0].is_active
        }
        data.name_kor = value;
        dispatch(setUpdateData([...updateData2, data]));
        const nameInputs2 = bookmakers.nameInputs.filter((el) => el.id !== id);
        dispatch(setNameInputs([...nameInputs2, {id: id, name_kor: value}]))
    }

    const saveBookmaker = async () => {
        axios.post('/api/updateProviders', bookmakers.updateData).then( (res) => {
                if(res.status === 200) {
                    dispatch(setUpdateData([]));
                    alert("저장완료");
                }
            }
        )
    }

    return (
        <div>
            <input type="button" value="저장" onClick={saveBookmaker} />
            {bookmakers._isLoading && "로딩중..."}
            {!bookmakers._isLoading && bookmakers.bookmakers && (
                <table className="table table-bordered table-hover text-center">
                        <thead>
                        <tr>
                            <th>북마커 ID</th>
                            <th>북마커 파싱명</th>
                            <th>북마커 한글명</th>
                            <th>
                                사용여부
                                <input
                                    type="checkbox"
                                    checked={
                                        bookmakers.checkedInputs.length === bookmakers.bookmakers.length
                                    }
                                    onChange={(e) => {allChangeHandler(e)}}
                                />
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {!bookmakers.bookmakers.length && (
                            <tr>
                                <td colSpan="3">목록이 비어있습니다.</td>
                            </tr>
                        )}
                        {!!bookmakers.bookmakers.length && bookmakers.bookmakers.map((m, index) => (
                            <tr>
                                <td>{m.id}</td>
                                <td>{m.name}</td>
                                <td>
                                    <input
                                        name={index}
                                        type="text"
                                        value={bookmakers.nameInputs.filter((e) => e.id === m.id).length > 0 ? bookmakers.nameInputs.filter((e) => e.id === m.id)[0].name_kor: ""}
                                        onChange={(e) => {
                                            changeName(e.currentTarget.value, m.id, index)
                                        }}
                                    />
                                </td>
                                <td>
                                    <label>
                                        사용여부
                                        <input type="checkbox" name={m.id} id={m.id} value="true"
                                               checked={bookmakers.checkedInputs.includes(m.id)}
                                               onChange={(e) => {changeHandler(e.currentTarget.checked, m.id, index)}}
                                        />
                                    </label>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
            )}
        </div>
    )
}
export default BookmakerList;