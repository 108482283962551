import {call, put} from "redux-saga/effects";
import {failedGetWithdrawCompleteReducer, setWithdrawCompleteReducer} from "../../reducers/money/WithdrawCompleteReducer";
import {fetchWithdrawComplete} from "../../../lib/api";

export function* getWithdrawCompleteSaga(action) {
    try{
        console.log("##getWithdrawCompleteSaga#",action.payload.page,action.payload.pageSize, action.payload.withdrawSelect , action.payload.query, action.payload.startDate, action.payload.endDate)
        const res = yield call(fetchWithdrawComplete,
            action.payload.page, action.payload.pageSize,action.payload.order, action.payload.withdrawSelect ,action.payload.query,
            action.payload.startDate, action.payload.endDate
        );
        console.log("##setWithdrawCompleteSaga#",res.data)
        yield put(setWithdrawCompleteReducer(res.data))
    }
    catch (error) {
        console.log(error)
        yield put(failedGetWithdrawCompleteReducer(error))
    }
}