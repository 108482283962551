import {call, put, take,} from "redux-saga/effects";
import {closeChannel, createSocketChannel} from "../../channel/createSocketChannel";
import socket from "../../../config/socket";
import {
    changeCloseMatch, failedGetCloseFixture, failedGetCloseFixtureDetail,
    getCloseFixtureDetail,
    pushCloseMatchTask,
    setCloseFixture, setCurrentFixture
} from "../../reducers/match/closeMatch";
import {fetchCloseFixture, fetchFixtureDetail} from "../../../lib/api";

export function* getCloseFixtureSaga(action) {
    try {
        const res = yield call(fetchCloseFixture,
            action.payload.page, action.payload.pageSize, action.payload.sport,
            action.payload.provider, action.payload.league, action.payload.query);
        yield put(setCloseFixture(res.data));
        if(res.data.result?.length > 0) {
            yield put(getCloseFixtureDetail(res.data.result[0].fixture_id))
        }
        else {
            yield put(setCurrentFixture(null))
        }
    } catch(error) {
        console.log(error)
        yield put(failedGetCloseFixture(error));
    }
}

export function* getCloseFixtureDetailSaga(action) {
    try {
        const res = yield call(fetchFixtureDetail, action.payload, 3)
        yield put(setCurrentFixture(res.data))
    } catch (error) {
        console.log(error)
        yield put(failedGetCloseFixtureDetail(error))
    }
}

export function* waitCloseMatchTaskSaga() {
    let channel;
    try {
        channel = yield call(createSocketChannel, "closeMatch");
        while(true) {
            const task = yield take(channel);
            yield onTaskSaga(task)
        }
    } catch (e) {
        console.log(e, "error");
    } finally {
        socket.close();
        closeChannel(channel)
    }
}

function* onTaskSaga(task) {
    yield put(pushCloseMatchTask(task));
    yield put(changeCloseMatch(task));
}

export function* changeCloseMatchSaga() {
    //yield put(changeSport(changedItem))
}

