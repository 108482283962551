import {call, put, take,} from "redux-saga/effects";
import {fetchFaq} from "../../../lib/api";
import {failedGetFaq, setFaq} from "../../reducers/board/faq";

export function* getFaqSaga(action) {
    try{
        const res = yield call(
            fetchFaq,
            action.payload.page, action.payload.pageSize, action.payload.query,
            action.payload.order, action.payload.startDate, action.payload.endDate
        )
        yield put(setFaq(res.data))
    }
    catch (error) {
        console.log(error)
        yield put(failedGetFaq(error))
    }
}