import {call, put} from "redux-saga/effects";
import {failedGetAgentListReducer, setAgentListReducer} from "../../reducers/agent/AgentListReducer";
import {fetchAgentList} from "../../../lib/api";

export function* getAgentListSaga(action) {
    try{
        console.log("##getAgentListSaga#",action.payload.page,action.payload.pageSize, action.payload.order, action.payload.query)
        const res = yield call(fetchAgentList,
            action.payload.page, action.payload.pageSize,action.payload.order, action.payload.query,
            action.payload.startDate, action.payload.endDate
        );
        console.log("##setAgentListSaga#",res.data)
        yield put(setAgentListReducer(res.data))
    }
    catch (error) {
        console.log(error)
        yield put(failedGetAgentListReducer(error))
    }
}