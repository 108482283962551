import {createSlice} from "@reduxjs/toolkit"
export const statsSlice = createSlice({
    name: 'nationApiSettingReducer',
    initialState: {
        //국가
        nationSwitchStates : {},
        nationPage: 1,
        nationPageSize:20,
        sportsNo: 0,
        nationUseYn: "",
        nationNm: "",
        nationList:[],
        totalNationCount:0,
        selectedRow: null,
        isLoading: false,
        error: null,

    },
    reducers: {
        getNationApiSettingInfoListReducer(state, action) {
            console.log("##getNationApiSettingInfoListReducer##", action.payload.nationPage,action.payload.nationPageSize,action.payload.sportsNo,action.payload.nationUseYn,action.payload.nationNm);
            state.isLoading = true;
            state.nationPage = action.payload.nationPage;
            state.nationPageSize = action.payload.nationPageSize;
            state.sportsNo = action.payload.sportsNo;
            state.nationUseYn = action.payload.nationUseYn;
            state.nationNm = action.payload.nationNm;
        },
        setNationApiSettingInfoListReducer(state, action) {
            console.log("#setNationApiSettingInfoListReducer#", action.payload);
            if((state.nationPage -1) * state.nationPageSize > action.payload.totalNationCount) {
                state.nationPage = 1
            }
            state.isLoading = false;
            state.selectedRow = null;
            state.nationList = action.payload.nationList;
            state.totalNationCount = action.payload.totalNationCount;
        },
        failedGetNationApiSettingInfoListReducer(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        setNationSwitchStatesReducer(state, action){
            console.log("##setNationSwitchStatesReducer##", action.payload);
            const code = action.payload;
            //  let updatedNationSwitchStates = { ...state.nationSwitchStates };
            // 스위치 상태를 업데이트
            //  if (state.nationSwitchStates[code]  ) {
            //      updatedNationSwitchStates[code] = false;

            // } else {
            //    updatedNationSwitchStates[code] = true;
            // }
            // state.nationSwitchStates = updatedNationSwitchStates;
            const updatedNationList = state.nationList.map((item) => {
                if (item.no === code) {
                    // 클릭된 국가의 use_yn 값을 변경
                    return {
                        ...item,
                        use_yn: item.use_yn === 'Y' ? 'N' : 'Y', // 'Y'와 'N'을 토글
                    };
                }
                return item;
            });
            state.nationList = updatedNationList;
        },
        setNationListChange(state, action) {
            state.isLoading = false;
            state.nationList = action.payload;
        },
        setSportsNo(state, action){
            state.sportsNo = action.payload;
        },

        //조회조건 사용여부
        setNationUseYn(state,action){
            state.nationUseYn = action.payload;
        },

        //조회조건 국가명
        setNationNm(state,action){
            state.nationNm = action.payload;
        },
        setSelectedRow(state, action) {
            state.isLoading = false;
            state.selectedRow = action.payload;
        },


        resetNationApiSettingInfoListReducer(state,action){
            state.nationSwitchStates = {};
            state.nationPage = 1;
            state.nationPageSize =20;
            state.sportsNo = 0;
            state.nationUseYn = "";
            state.nationNm = "";
            state.nationList = [];
            state.totalNationCount = 0;
            state.selectedRow = null;
            state.isLoading = false;
            state.error = null;
        },
    }
});

export const {
    getNationApiSettingInfoListReducer,
    setNationApiSettingInfoListReducer,
    failedGetNationApiSettingInfoListReducer,
    setNationSwitchStatesReducer,
    setNationListChange,
    setSportsNo,
    setNationUseYn,
    setNationNm,
    resetNationApiSettingInfoListReducer,
    setSelectedRow,
} = statsSlice.actions;

export default statsSlice.reducer;