import {createSlice} from "@reduxjs/toolkit"

export const WithdrawCompleteReducerSlice = createSlice({
    name: 'WithdrawCompleteReducer',
    initialState: {
        isLoading: false,
        error: null,
        withdrawSelect: 10,
        page: 1,
        pageSize: 20,
        order: 0,
        withdrawTotal: 0,
        withdrawStateSum: 0,
        withdrawTypeSum: 0,
        withdrawListCount: 0,
        totalCount: 0,
        UserDeposit: [],
        UserWithdraw: [],
        withdrawList: [], //페이징처리된리스트
        totalList: [],//모든리스트 엑셀다운로드위함
        monthDeposit: [],
        monthWithdraw: [],
        selectedNos: [],
        totalSelectedMoney: 0,
        userInformation: false,
        memberDepositLog: false,
        memberWithdrawLog: false,
        memberMoneyLog: false,
        memberPointLog: false,
        userNo: false,
        toastState: false,
        withdrawSelectList:[
            {value : 10, name : "전체"},
            {value : 1, name : "환전대기"},
            {value : 2, name : "환전완료"},
            {value : 3, name : "환전취소"},
        ],
    },
    reducers: {
        getWithdrawCompleteReducer(state, action) {
            console.log("#reducers getWithdrawCompleteReducer start#");
            state.isLoading = true;
            state.page = action.payload.pageSize === 999? 1: action.payload.page;
            state.pageSize = action.payload.pageSize;
            state.order = action.payload.order;
            state.withdrawSelect = action.payload.withdrawSelect;
            state.query = action.payload.query
            state.startDate = action.payload.startDate;
            state.endDate = action.payload.endDate;
        },
        setWithdrawCompleteReducer(state, action) {
            state.isLoading = false;
            console.log("#reducers setWithdrawCompleteReducer start#",action.payload.withdrawTotal);
            if((state.page -1) * state.pageSize > action.payload.totalCount) {
                state.page = 1
            }
            state.totalCount = action.payload.totalCount;
            state.withdrawListCount = action.payload.withdrawListCount;
            state.withdrawTotal = action.payload.withdrawTotal;
            state.withdrawList = action.payload.withdrawList;
            state.userDeposit = action.payload.userDeposit;
            state.userWithdraw = action.payload.userWithdraw;
            state.withdrawStateSum = action.payload.withdrawStateSum;
            state.withdrawTypeSum = action.payload.withdrawTypeSum;
            state.monthDeposit = action.payload.monthDeposit;
            state.monthWithdraw = action.payload.monthWithdraw;
            state.totalList = action.payload.totalList;

            //초기화
            state.totalSelectedMoney = 0;//선택금액
            state.selectedNos = [];
            state.totalSelectedPoint = 0;
        },
        failedGetWithdrawCompleteReducer(state, action) {
            console.log("#reducers failedGetWithdrawCompleteReducer start#");
            state.isLoading = false;
            state.error = action.payload;
        },
        setSelectedNos(state, action) {
            state.selectedNos = action.payload;
        },
        setTotalSelectedMoney(state, action) {
            state.totalSelectedMoney = action.payload;
        },
        setWithdrawSelect(state, action) {
            state.withdrawSelect = action.payload;
        },
        setUserInformation(state, action) {
            state.userInformation = action.payload;
        },
        setMemberDepositLog(state, action) {
            state.memberDepositLog = action.payload;
        },
        setMemberWithdrawLog(state, action) {
            state.memberWithdrawLog = action.payload;
        },
        setMemberMoneyLog(state, action) {
            state.memberMoneyLog = action.payload;
        },
        setMemberPointLog(state, action) {
            state.memberPointLog = action.payload;
        },
        setUserNo(state, action) {
            state.userNo = action.payload;
        },
        setToastState(state, action) {
            state.toastState = action.payload;
        },
        setQuery(state, action) {
            state.query = action.payload;
        },

    }
});

export const {
    getWithdrawCompleteReducer,
    setWithdrawCompleteReducer,
    failedGetWithdrawCompleteReducer,
    setSelectedNos,
    setTotalSelectedMoney,
    setWithdrawSelect,
    setUserInformation,
    setMemberDepositLog,
    setMemberWithdrawLog,
    setMemberMoneyLog,
    setMemberPointLog,
    setUserNo,
    setToastState,
    setQuery
} = WithdrawCompleteReducerSlice.actions;

export default WithdrawCompleteReducerSlice.reducer;