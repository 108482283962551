import moment from "moment";
import {setLostNos} from "../../redux/reducers/user/LostListReducer";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";

const LostListComponent = ({initialState},{getLostList}) => {
    const dispatch = useDispatch()
    const userInfo = useSelector((state) => state.userInfo);
    const allCheckHandler = (e) => {
        if(initialState.lostList && initialState.lostList.length > 0 && e.currentTarget.value) {
            const checks = [];
            for(let i = 0; i < initialState.lostList.length; i++) {
                checks.push(initialState.lostList[i].no)
                console.log(initialState.lostList[i].no)
            }
            dispatch(setLostNos(e.target.checked? checks : []));

        }
    }
    const lostStateColor = (state) => {
        switch(state) {
            case 0 :
                return "bgRequest"
            case 1 :
                return "bgWait"
            case 2 :
                return "bgComplete"
            case 3 :
                return "bgCancel"
            default : return state
        }
    }

    function getCountryKoreanName(englishName) {
        switch (englishName) {
            case "South Korea" : return "대한민국"
            case "China" : return "중국"
            case "Japan" : return "일본"
            case "Thailand" : return "태국"
            case "Singapore" : return "싱가포르"
            case "Laos" : return "라오스"
            case "North Korea" : return "북한"
            case "Vietnam" : return "베트남"
            case "Cambodia" : return "캄보디아"
            case "Taiwan" : return "대만"
            case "Philippines" : return "필리핀"
            case "Malaysia" : return "말레이시아"
            case "Burma Myanmar" : return "미얀마"
            case "Indonesia" : return "인도네시아"
            case "India" : return "인도네시아"
            default : return englishName
        }
    }

    const checkHandler = (checked, no) => {
        let lostNos2 = []
        if(checked) {
            lostNos2 = [...initialState.lostNos, no]
            dispatch(setLostNos([...initialState.lostNos, no]));
        }
        else {
            lostNos2 = initialState.lostNos.filter((id) => id !== no)
            dispatch(setLostNos(initialState.lostNos.filter((id) => id !== no)));
        }
        let totalCheck = 0;

        for(let i = 0; i < lostNos2.length; i++) {
            totalCheck += initialState.lostList.filter((id) => id.no === lostNos2[i])[0].no
        }
        console.log(totalCheck)
    }

    const updateStatus = (state, lostNos) => {
        //console.log(state)
        if (state === 3) {
            if(window.confirm("취소하겠습니까?")) {
                if(lostNos.length > 0) {
                    axios.post('/admin/lost/update', {
                        adminNo: userInfo.userNo,
                        lostNos: lostNos,
                        status: state
                    }).then((res) => {
                        alert(res.data.isUpdated? "완료" : "실패")
                        getLostList()
                    })
                } else {
                    alert("취소했습니다")
                }
            }
        } else {
            if(lostNos.length > 0) {
                axios.post('/admin/lost/update', {
                    adminNo: userInfo.userNo,
                    lostNos: lostNos,
                    status: state
                }).then((res) => {
                    alert(res.data.isUpdated? "완료" : "실패")
                    getLostList()
                })
            }
        }
    }

    const lostStatus = (state, no) => {
        switch (state) {
            case 0 :
                return (
                    <div className="d-flex justify-content-center">
                        <button type="button" className="btn btn-warning btn-sm xSmallButton mx-1" onClick={() => {updateStatus(1, [no])}}>대기</button>
                        <button type="button" className="btn btn-primary btn-sm xSmallButton mx-1" onClick={() => {updateStatus(2, [no])}}>완료</button>
                        <button type="button" className="btn btn-danger btn-sm xSmallButton mx-1" onClick={() => {updateStatus(3, [no])}}>취소</button>
                    </div>
                )
            case 1 :
                return (
                    <div className="d-flex justify-content-center">
                        <button type="button" className="btn btn-primary btn-sm xSmallButton mx-1" onClick={() => {updateStatus(2, [no])}}>완료</button>
                        <button type="button" className="btn btn-danger btn-sm xSmallButton mx-1" onClick={() => {updateStatus(3, [no])}}>취소</button>
                    </div>
                )
            case 2 :
                return (
                    "완료"
                )
            case 3 :
                return "취소"
            default : return state
        }
    }
    return (
        <div>
            <table className="table table-bordered table-sm text-center align-middle mt-2">
                <thead className="table-dark">
                <tr>
                    <th style={{width: "30px"}}>
                        <input className="form-check-input" type="checkbox" checked={initialState.lostList&&initialState.lostList.length === initialState.lostNos.length}
                               onChange={allCheckHandler} id="flexCheckDefault"/>
                    </th>
                    <th>아이디/닉네임</th>
                    <th>휴대폰번호</th>
                    <th>예금주</th>
                    <th>도메인</th>
                    <th>IP</th>
                    <th>국가</th>
                    <th>요청시간</th>
                    <th>처리</th>
                </tr>
                </thead>
                {initialState.lostList && initialState.lostList.length > 0 ?
                 initialState.lostList.map((lost, index) => (
                    <tbody key={lost.no}>
                    <tr className={lostStateColor(lost.status)}>
                        <td>
                            <input className="form-check-input" type="checkbox"
                                   checked={Array.isArray(initialState.lostNos) && initialState.lostNos.filter((no) => no === lost.no).length > 0}
                                   onChange={(e) => {checkHandler(e.currentTarget.checked, lost.no)}}
                            />
                        </td>
                        <td>{lost.id}/{lost.nickname}</td>
                        <td>{lost.phone}</td>
                        <td>{lost.name}</td>
                        <td>{lost.domain}</td>
                        <td>{lost.ip}</td>
                        <td>{getCountryKoreanName(lost.country)}</td>
                        <td>{moment(lost.createdAt).format("YYYY-MM-DD HH:mm:ss")}</td>
                        <td>
                            {lostStatus(lost.status, lost.no)}
                        </td>
                    </tr>
                    </tbody>
                ))
                  :
                    <tbody>
                    <tr className="table-secondary">
                        <td colSpan= "9"><font size= '3'> 조회된 데이터가 없습니다.</font></td>
                    </tr>
                    </tbody>

                }
            </table>

        </div>
    )
}

export default LostListComponent;