import {call, put} from "redux-saga/effects";
import {fetchFailList} from "../../../lib/api";
import {failedFailListReducer, setFailListReducer} from "../../reducers/user/FailListReducer";

export function* getFailListSaga(action) {
    console.log("##getFailListSaga##");
    try{
        const res = yield call(fetchFailList,action.payload.page,action.payload.pageSize,action.payload.order,action.payload.startDate,action.payload.endDate,action.payload.query,action.payload.device);
        yield put(setFailListReducer(res.data))
    }
    catch (error) {
        console.log(error)
        yield put(failedFailListReducer(error))
    }
}
