import {createSlice} from "@reduxjs/toolkit";

export const stopMatchBySlice = createSlice({
    name: 'stopMatchBy',
    initialState: {
        isLoading: false,
        fixtures: [],
        originFixtures: [],
        error: null,
        isConnect: false,
        tasks: [],
        page: 1,
        pageSize: 100,
        totalCount: 0,
        sport: 0,
        market: 0,
        provider: 0,
        league: 0,
        sports: [],
        markets: [],
        providers: [],
        leagues: [],
        query: "",
        checkedInputs: [],
        betInputs: [],
        updateData: [],
    },
    reducers: {
        getStopMatchBy(state, action) {
            state.isLoading = true;
            state.page = action.payload.pageSize === 999? 1: action.payload.page;
            state.pageSize = action.payload.pageSize;
            state.sport = action.payload.sport;
            state.market = action.payload.market;
            state.provider = action.payload.provider;
            state.league = action.payload.league;
            state.query = action.payload.query
        },
        setStopMatchBy(state, action) {
            if((state.page -1) * state.pageSize > action.payload.totalCount) {
                state.page = 1
            }
            state.isLoading = false;
            state.fixtures = action.payload.result;
            state.totalCount = action.payload.totalCount;
            state.sports = action.payload.sports;
            state.markets = action.payload.markets;
            state.providers = action.payload.providers;
            state.leagues = action.payload.leagues;
            const inputs = [];
            for(const r of action.payload.result) {
                inputs.push({id: r.id, bet1: r.bet1, bet2: r.bet2, bet3: r.bet3, is_edited: 0})
            }
            state.betInputs = inputs;
            state.checkedInputs = [];

        },
        setCheckedInputs(state, action) {
            state.checkedInputs = action.payload;
        },
        setUpdateData(state, action) {
            state.updateData = action.payload;
        },
        setBetInputs(state, action) {
            state.betInputs = action.payload;
        },
        failedGetStopMatchBy(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        waitStopMatchByTask(state) {
            state.isConnect = true
        },
        pushStopMatchByTask(state, action) {
            state.tasks.push(action.payload)
        },
        changeStopMatchBy(state, action) {
            //변경된 인자값의 인덱스넘버를 찾아야된다.
            //const changedItems = action.payload
            const changedItem = action.payload
            const beforeArray = state.fixtures.filter((item) => item.id === changedItem.id)
            //배당 변경됬는지 비교

            //찾은후 변경사항업데이트
            const index = state.fixtures.indexOf(beforeArray[0])
            if(index >= 0) {
                if(beforeArray[0].bet1 !== changedItem.bet1 || beforeArray[0].bet3 !== changedItem.bet3) {
                    if(state.originFixtures.filter((item) => item.id === state.fixtures[index]).length === 0) {
                        state.originFixtures.push(state.fixtures[index])
                    }
                    state.fixtures[index] = changedItem
                    console.log(changedItem)
                }
            }
            else {
                //state.fixtures.push(changedItem)
            }
            /*for(const changedItem of changedItems) {
                const beforeArray = state.fixtures.filter((item) => item.id === changedItem.id)
                //배당 변경됬는지 비교

                //찾은후 변경사항업데이트
                const index = state.fixtures.indexOf(beforeArray[0])
                if(index >= 0) {
                    if(beforeArray[0].bet1 !== changedItem.bet1 || beforeArray[0].bet3 !== changedItem.bet3) {
                        if(state.originFixtures.filter((item) => item.id === state.fixtures[index]).length === 0) {
                            state.originFixtures.push(state.fixtures[index])
                        }
                        state.fixtures[index] = changedItem
                        console.log(changedItem)
                    }
                }
                else {
                    //state.fixtures.push(changedItem)
                }
            }*/
            state.tasks = []

        }
    },
});

export const { getStopMatchBy, setStopMatchBy, setCheckedInputs, setUpdateData, setBetInputs, failedGetStopMatchBy, waitStopMatchByTask, pushStopMatchByTask, changeStopMatchBy, changePageSize } = stopMatchBySlice.actions;

export default stopMatchBySlice.reducer;