import React, {useState} from "react";
import axios from "axios";

const AdminAddComponent = () => {

    const [id, setId] = useState('')
    const [pw, setPw] = useState('')
    const [level, setLevel] = useState('')

    const idChangeHandler = (e) => {
        setId(e.currentTarget.value);
    }
    const pwChangeHandler = (e) => {
        setPw(e.currentTarget.value);
    }

    const handleRegister = () => {
        axios.post('/admin/admin/insert', {
            id: id,
            pw: pw,
            level: level
        }).then((res) => {
            if(res.data.isInserted) {
                alert('가입완료')
            }
            else {
                alert('가입실패')
            }
        })
    }



    const levelHandler = (e) =>{
        setLevel(e.currentTarget.value);
    }
    const levelSelectList = [
        {value : 0, name : "일반관리자"},
        {value : 1, name : "서브관리자"},
        {value : 2, name : "마스터관리자"},
    ]
    return (

        <div className="modal fade" id="adminAdd" tabIndex="-1" aria-hidden="true" >
            <div className="modal-dialog" >
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" >관리자추가</h1>
                    </div>
                    <div className="modal-body">
                        <div className="mb-2">
                            <label className="col-form-label">ID</label>
                            <input className="form-control" maxLength="12" type="text" placeholder="아이디" value={id} onChange={idChangeHandler}  />
                        </div>
                        <div className="mb-2">
                            <label className="col-form-label">PW</label>
                            <input className="form-control" maxLength="12" type="text" placeholder="비밀번호" value={pw} onChange={pwChangeHandler}  />
                        </div>
                        <div className="mb-1">
                            <label htmlFor="message-text" className="col-form-label">Level</label>
                            <select className="form-select" onChange={levelHandler} value={level}>
                                 {levelSelectList.map((item, index) => (
                                    <option key={item.value} value={item.value}>{item.name}</option>
                                 ))}
                             </select>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">취소</button>
                        <button type="button" className="btn btn-primary" onClick={handleRegister}>등록</button>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default AdminAddComponent;