import HomeMainComponent from "../../components/home/HomeMainComponent";
import React from "react";

const HomePage = () => {
    return (
        <div>
            <HomeMainComponent />
        </div>

    )
}
export default HomePage;