import HomeHoPartnerTotalListComponent from "../../components/home/HomeHoPartnerTotalListComponent";
import {NumericFormat} from "react-number-format";
import {getHomeTableStats} from "../../redux/reducers/home/HomeReducer";
import moment from "moment/moment";
import {useDispatch} from "react-redux";
import {useState} from "react";


const HomeHoPartnerTotalContainer = ({initialState}) => {
    const dispatch = useDispatch()
    const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().add(1, "day").format('YYYY-MM-DD'));
    console.log("##initialState##",initialState);
    console.log("##initialState.home##",initialState.recently10Deposit);

    return <>
        <div>
            <div>
                <p>- 통계</p>
            </div>
            {!initialState.isLoading && initialState.home !== null && (
            <div className="row">
                <div className="col">
                    <p>최근 10일 충전 현황
                        [본사누적 : <NumericFormat value={initialState.home.recently10Deposit} displayType={'text'}
                                               thousandSeparator={true}/>원,
                        파트너 누적 : <NumericFormat value={initialState.home.recently10DepositPartner} displayType={'text'}
                                                thousandSeparator={true}/>원,
                        전체 : <NumericFormat
                            value={initialState.home.recently10Deposit + initialState.home.recently10DepositPartner}
                            displayType={'text'} thousandSeparator={true}/>원]
                    </p>
                    <p>최근 10일 환전 현황
                        [본사누적 : <NumericFormat value={initialState.home.recently10Withdraw} displayType={'text'}
                                               thousandSeparator={true}/>원,
                        파트너 누적 : <NumericFormat value={initialState.home.recently10WithdrawPartner} displayType={'text'}
                                                thousandSeparator={true}/>원,
                        전체 : <NumericFormat
                            value={initialState.home.recently10Withdraw + initialState.home.recently10WithdrawPartner}
                            displayType={'text'} thousandSeparator={true}/>원]
                    </p>
                    <p>최근 10일 수익 현황
                        [본사누적 : <NumericFormat
                            value={initialState.home.recently10Deposit - initialState.home.recently10Withdraw}
                            displayType={'text'} thousandSeparator={true}/>원,
                        파트너 누적 : <NumericFormat
                            value={initialState.home.recently10DepositPartner - initialState.home.recently10WithdrawPartner}
                            displayType={'text'} thousandSeparator={true}/>원,
                        전체 : <NumericFormat
                            value={(initialState.home.recently10Deposit + initialState.home.recently10DepositPartner) - (initialState.home.recently10Withdraw + initialState.home.recently10WithdrawPartner)}
                            displayType={'text'} thousandSeparator={true}/>원]
                    </p>
                </div>
            </div>
            )}
            <div className="row">
                <div className="col-12 justify-content-end" style={{
                    display: "flex"
                }}>
                    <input type="date" className="form-control mx-1"
                           value={startDate} style={{
                        width: "150px", height: "30px"
                    }} onChange={event => {
                        setStartDate(event.target.value)
                    }}/>
                    <span className="mx-1">~</span>
                    <input type="date" className="form-control mx-1" value={endDate}
                           style={{
                               width: "150px", height: "30px"
                           }} onChange={event => {
                        setEndDate(event.target.value)
                    }}/>
                    <button type="button" className="btn btn-secondary btn-sm" onClick={() => {
                        dispatch(getHomeTableStats({
                            startDate: startDate,
                            endDate: endDate,
                        }))
                    }}>기간검색
                    </button>
                </div>
            </div>
            {/*HomeHoPartnerTotalContainer 끝*/}
            <div className="row">
                <div className="col">
                    <button type="button" className="btn btn-secondary btn-sm m-1" onClick={() => {
                        const sDate = moment().startOf("month").format('YYYY-MM-DD')
                        const eDate = moment().add(1, "day").format('YYYY-MM-DD')
                        setStartDate(sDate)
                        setEndDate(eDate)
                        dispatch(getHomeTableStats({
                            startDate: sDate,
                            endDate: eDate,
                        }))
                    }}>이번 달
                    </button>
                    <button type="button" className="btn btn-secondary btn-sm m-1" onClick={() => {
                        const sDate = moment().add(-1, "months").startOf("month").format('YYYY-MM-DD')
                        const eDate = moment().add(-1, "months").endOf("month").format('YYYY-MM-DD')
                        setStartDate(sDate)
                        setEndDate(eDate)
                        dispatch(getHomeTableStats({
                            startDate: sDate,
                            endDate: eDate,
                        }))
                    }}>1달전
                    </button>
                    <button type="button" className="btn btn-secondary btn-sm m-1" onClick={() => {
                        const sDate = moment().add(-2, "months").startOf("month").format('YYYY-MM-DD')
                        const eDate = moment().add(-2, "months").endOf("month").format('YYYY-MM-DD')
                        setStartDate(sDate)
                        setEndDate(eDate)
                        dispatch(getHomeTableStats({
                            startDate: sDate,
                            endDate: eDate,
                        }))
                    }}>2달전
                    </button>
                    <button type="button" className="btn btn-secondary btn-sm m-1" onClick={() => {
                        const sDate = moment().add(-3, "months").startOf("month").format('YYYY-MM-DD')
                        const eDate = moment().add(-3, "months").endOf("month").format('YYYY-MM-DD')
                        setStartDate(sDate)
                        setEndDate(eDate)
                        dispatch(getHomeTableStats({
                            startDate: sDate,
                            endDate: eDate,
                        }))
                    }}>3달전
                    </button>
                </div>
                <div className="col justify-content-end" style={{
                    display: "flex", alignSelf: "auto"
                }}>
                    <button type="button" className="btn btn-secondary btn-sm m-1" onClick={() => {
                        const sDate = moment().startOf("month").format('YYYY-MM-DD')
                        const eDate = moment().startOf("month").add(9, "day").format('YYYY-MM-DD')
                        setStartDate(sDate)
                        setEndDate(eDate)
                        dispatch(getHomeTableStats({
                            startDate: sDate,
                            endDate: eDate,
                        }))
                    }}>1~10일
                    </button>
                    <button type="button" className="btn btn-secondary btn-sm m-1" onClick={() => {
                        const sDate = moment().startOf("month").add(10, "day").format('YYYY-MM-DD')
                        const eDate = moment().startOf("month").add(19, "day").format('YYYY-MM-DD')
                        setStartDate(sDate)
                        setEndDate(eDate)
                        dispatch(getHomeTableStats({
                            startDate: sDate,
                            endDate: eDate,
                        }))
                    }}>11~20일
                    </button>
                    <button type="button" className="btn btn-secondary btn-sm m-1" onClick={() => {
                        const sDate = moment().startOf("month").add(20, "day").format('YYYY-MM-DD')
                        const eDate = moment().endOf("month").format('YYYY-MM-DD')
                        setStartDate(sDate)
                        setEndDate(eDate)
                        dispatch(getHomeTableStats({
                            startDate: sDate,
                            endDate: eDate,
                        }))
                    }}>21~말일
                    </button>

                    <button type="button" className="btn btn-secondary btn-sm m-1" onClick={() => {
                        const sDate = moment().startOf("month").format('YYYY-MM-DD')
                        const eDate = moment().startOf("month").add(14, "day").format('YYYY-MM-DD')
                        setStartDate(sDate)
                        setEndDate(eDate)
                        dispatch(getHomeTableStats({
                            startDate: sDate,
                            endDate: eDate,
                        }))
                    }}>1~15일
                    </button>
                    <button type="button" className="btn btn-secondary btn-sm m-1" onClick={() => {
                        const sDate = moment().startOf("month").add(15, "day").format('YYYY-MM-DD')
                        const eDate = moment().endOf("month").format('YYYY-MM-DD')
                        setStartDate(sDate)
                        setEndDate(eDate)
                        dispatch(getHomeTableStats({
                            startDate: sDate,
                            endDate: eDate,
                        }))
                    }}>16일~말일
                    </button>
                </div>
            </div>
            <HomeHoPartnerTotalListComponent initialState = {initialState}/>
        </div>
    </>
}

export default HomeHoPartnerTotalContainer;