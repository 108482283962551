import React from 'react';
import MemberContainer from "../../containers/user/MemberListContainer";


const Member = () => {
    return (
        <div>
            <h1>회원관리</h1>
            <MemberContainer />
        </div>
    );
};

export default Member;