import React from 'react';
import EventContainer from "../../containers/board/EventListContainer";


const Notice = () => {
    return (
        <div>
            <h1>이벤트게시판</h1>
            <EventContainer />
        </div>
    );
};

export default Notice;