import React from 'react';
import CombinationBettingListContainer from "../../containers/match/CombinationBettingListContainer";

const CombinationBettingListPage = () => {
    return (
        <div>
            <h3>조합베팅목록</h3>
            <CombinationBettingListContainer/>
        </div>
    );
};

export default CombinationBettingListPage;