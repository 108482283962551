import React from 'react';
import AdminContainer from "../../containers/user/AdminContainer";
const AdminPage = () => {

    return (
        <div>
            <h1>관리자관리</h1>
        <AdminContainer/>
        </div>
    )
}

export default AdminPage;