import React from 'react';
import BasicSettingPageContainer from "../../containers/match/BasicSettingPageContainer";


const BasicSettingPage = () => {
    return (
        <div>
            <h1>스포츠기본설정</h1>
            <BasicSettingPageContainer />
        </div>
    );
};

export default BasicSettingPage;