import {call, put} from "redux-saga/effects";
import {failedGetWithdrawMngReducer, setWithdrawMngReducer} from "../../reducers/money/WithdrawMngReducer";
import {fetchWithdrawMng} from "../../../lib/api";

export function* getWithdrawMngSaga(action) {
    try{
        console.log("##getWithdrawMngSaga#",action.payload.page,action.payload.pageSize, action.payload.withdrawSelect , action.payload.query, action.payload.startDate, action.payload.endDate, action.payload.comboYn)
        const res = yield call(fetchWithdrawMng,
            action.payload.page, action.payload.pageSize,action.payload.order, action.payload.withdrawSelect ,action.payload.query,
            action.payload.startDate, action.payload.endDate, action.payload.comboYn
        );
        console.log("##setWithdrawMngSaga#",res.data)
        yield put(setWithdrawMngReducer(res.data))
    }
    catch (error) {
        console.log(error)
        yield put(failedGetWithdrawMngReducer(error))
    }
}