import {call, put} from "redux-saga/effects";
import {fetchUserDetail} from "../../../lib/api";
import {failedGetUserDetailReducer, setUserDetailReducer} from "../../reducers/user/UserDetailReducer";

export function* getUserDetailSaga(action) {
    console.log("##getUserDetailSaga##");
    try{
        const res = yield call(fetchUserDetail,action.payload.no);
        yield put(setUserDetailReducer(res.data))
    }
    catch (error) {
        console.log(error)
        yield put(failedGetUserDetailReducer(error))
    }
}