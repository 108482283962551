import {call, put, take,} from "redux-saga/effects";
import {fetchNotice} from "../../../lib/api";
import {failedGetNotice, setNotice} from "../../reducers/board/notice";

export function* getNoticeSaga(action) {
    try{
        const res = yield call(
            fetchNotice,
            action.payload.page, action.payload.pageSize, action.payload.query,
            action.payload.order, action.payload.startDate, action.payload.endDate
        )
        yield put(setNotice(res.data))
    }
    catch (error) {
        console.log(error)
        yield put(failedGetNotice(error))
    }
}