import React from 'react';
import AdminFailLogContainer from "../../containers/user/AdminFailLogContainer";

const AdminFailLogPage = () => {

    return (
        <div>
        <h1>관리자로그인실패</h1>
        <AdminFailLogContainer/>
        </div>
    )
}

export default AdminFailLogPage;