import React, {useEffect} from 'react';
import LostListComponent from "../../components/user/LostListComponent";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import Pagination from "../../components/common/Pagination";
import {getLostListReducer, setQuery, setStatus} from "../../redux/reducers/user/LostListReducer";

const LostListContainer = () => {
    const dispatch = useDispatch()
    const initialState = useSelector((state) => state.LostListReducer)

    const getLostList = () => {
        if(initialState.lostList !== null) {
            dispatch(getLostListReducer({
                page: initialState.page,
                pageSize: initialState.pageSize,
                order: initialState.order,
                status: initialState.status,
                query: initialState.query,
            }))
        }
    }
    useEffect(getLostList, [dispatch])

    const lostDelete = (e) => {
        axios.post('/admin/lost/delete', {
            lostNos: initialState.lostNos,
        }).then((res) => {
            alert(res.data.isDeleted? "삭제완료" : "삭제실패")
            getLostList()
        })
    }

    const statusHandler = (e) => {
        dispatch(setStatus(e.target.value))
        //console.log(e.target.value)
        searchHandler(e.target.value,initialState.query)
    }
    const statusOption = [
        {value: "", name: "전체"},
        {value: 0, name: "신청"},
        {value: 1, name: "대기"},
        {value: 2, name: "완료"},
        {value: 3, name: "취소"},
    ]
    const queryHandler = (e) => {
        dispatch(setQuery(e.target.value));
    }
    const inputPress = (e) => {
        if(e.key === "Enter") {
            searchHandler(initialState.status, initialState.query)
        }
    }

    const searchHandler = (status, query) => {
        dispatch(getLostListReducer({
            page: 1,
            pageSize: 5,
            order:initialState.order,
            status: status,
            query: query,
        }))
    }

    const handlePageChange = (page) => {
        dispatch(getLostListReducer({
            page: page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            status: initialState.status,
            query: initialState.query,
        }))
    }


    const allList = () => {
        getLostList()
    }
    return (
        <div className="m-2">
            <div className="card-body">
                <div>
                    <div className="container-fluid">
                        <div className="row bg-light align-items-center"  style={{
                            height: "50px"
                        }}>
                            <div className="col align-items-center justify-content-end d-flex" style={{
                                height: "50px"
                            }}>
                                <div className="input-group align-items-end" style={{
                                    width: "40%",
                                    height: "40px",
                                }}>
                                    <select className="form-select me-1" value={initialState.status === "" ? "전체" : initialState.status}
                                            onChange={statusHandler}  style={{width: "5px"}}>
                                        {statusOption.map((item) => (
                                            <option key={item.value} value={item.value}>{item.name}</option>
                                        ))}
                                    </select>
                                    <input type="text" className="form-control" placeholder="검색"
                                           aria-describedby="button-addon2"
                                           value={initialState.query} onChange={queryHandler} onKeyDown={inputPress}/>
                                    <button className="btn btn-outline-secondary" type="button"
                                            id="button-addon2" onClick={() => searchHandler(initialState.status, initialState.query)}>검색
                                    </button>

                                    <button type="button" className="btn btn-outline-secondary mx-1" >엑셀출력</button>
                                </div>
                                <select className="form-select mx-1" style={{
                                    width: "120px",
                                    height: "40px"
                                }}
                                        value={initialState.pageSize === "999" ? "모두" : initialState.pageSize}
                                        onChange={(e) => {
                                            dispatch(getLostListReducer({
                                                page:1,
                                                pageSize: parseInt(e.target.value),
                                                order: initialState.order,
                                                query: initialState.query,
                                                status: initialState.status,
                                            }))
                                        }}
                                >
                                    <option value={20}>20개씩</option>
                                    <option value={50}>50개씩</option>
                                    <option value={100}>100개씩</option>
                                    <option value={200}>200개씩</option>
                                    <option value={999}>모두</option>
                                </select>
                                <select className="form-select"  style={{
                                    width: "120px",
                                    height: "40px"
                                }}
                                        value={initialState.order === 0? 0 : initialState.order}
                                        onChange={(e) => {
                                            dispatch(getLostListReducer({
                                                page:1,
                                                pageSize: initialState.pageSize,
                                                order: parseInt(e.target.value),
                                                query: initialState.query,
                                                status: initialState.status,
                                            }))
                                        }}
                                >
                                    <option value={0}>요청시간순</option>
                                </select>
                            </div>

                        </div>
                        <div className="my-2 d-flex">
                            <span onClick={allList} style={{cursor: "pointer"}}>모두 보기</span>
                        </div>
                        <div className="d-flex">
                            <div className="my-2 d-flex">
                                <span>(총접속오류 : {initialState.lostCount} 개)</span>

                            </div>
                            <div className="col align-items-center justify-content-end d-flex">
                                <button className="btn btn-outline-secondary me-2" type="button"
                                        id="button-addon2"
                                        onClick={lostDelete}
                                >삭제
                                </button>
                            </div>
                        </div>
                    </div>
                     <LostListComponent initialState={initialState} getLostList={getLostList}/>
                    <Pagination totalCount={initialState.lostCount} pageSize={initialState.pageSize} currentPage={initialState.page} onPageChange={handlePageChange}/>
                </div>
            </div>
        </div>

    )
}

export default LostListContainer;