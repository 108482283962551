import React from "react";
import typeCase from "../../util/typeCase";
import {setAdminNos} from "../../redux/reducers/user/AdminReducer";
import {useDispatch} from "react-redux";

const AdminComponent = ({initialState}) => {
    const dispatch = useDispatch()
    const allCheckHandler = (e) => {
        if(initialState.adminList.length > 0 && e.currentTarget.value) {
            const checks = [];
            for(let i = 0; i < initialState.adminList.length; i++) {
                checks.push(initialState.adminList[i].no)
                //console.log(adminList[i].no)
            }
            dispatch(setAdminNos(e.target.checked? checks : []))
        }
    }

    const checkHandler = (checked, no) => {
        //console.log(no)
        let adminNos2 = []
        if (checked) {
            adminNos2 = [...initialState.adminNos, no]
            dispatch(setAdminNos([...initialState.adminNos, no]))
        } else {
            adminNos2 = initialState.adminNos.filter((id) => id !== no)
            dispatch(setAdminNos(initialState.adminNos.filter((id) => id !== no)))
        }
        let totalCheck = 0;
        for (let i = 0; i < adminNos2.length; i++) {
            totalCheck += initialState.adminList.filter((id) => id.no === adminNos2[i])[0].no
        }
        //console.log(totalCheck)
    }
    return (
        <div>
            <table className="table table-bordered table-sm text-center align-middle">
                <thead className="table-dark">
                <tr>
                    <th style={{width: "30px"}}>
                        <input className="form-check-input" type="checkbox" checked={initialState.adminList && initialState.adminList.length === initialState.adminNos.length}
                               onChange={allCheckHandler} id="flexCheckDefault"/>
                    </th>
                    <th>아이디</th>
                    {/*<th>로그인아이피</th>*/}
                    <th style={{width: "15%"}}>사용권한</th>
                    <th style={{width: "10%"}}>상태</th>
                </tr>
                </thead>
                {initialState.adminList && initialState.adminList.length > 0 ?
                    initialState.adminList.map((admin, index) => (
                        <tbody key={admin.no}>
                        <tr>
                            <td>
                                <input className="form-check-input" type="checkbox"
                                       checked={initialState.adminNos.filter((no) => no === admin.no).length > 0}
                                       onChange={(e) => {checkHandler(e.currentTarget.checked, admin.no)}}
                                />
                            </td>
                            <td>{admin.id}</td>

                            <td><div>{typeCase.adminState(admin.level)}</div></td>
                            <td><div>{typeCase.adminUse(admin.user_state)}</div></td>
                        </tr>
                        </tbody>
                    ))
                    :
                    <tbody>
                    <tr className="table-secondary">
                        <td colSpan= "4"><font size= '3'> 조회된 데이터가 없습니다.</font></td>
                    </tr>
                    </tbody>
                }
            </table>
        </div>
    )
}

export default AdminComponent;