import {createSlice} from "@reduxjs/toolkit"

export const AgentListReducerSlice = createSlice({
    name: 'AgentListReducer',
    initialState: {
        isLoading: false,
        error: null,
        partnerList: [],
        partnerCount: 0,
        totalCount: 0,
        page: 1,
        pageSize: 20,
        order: 0,
        partnerNos : [],
        query : "",
        isOpen : false,

    },
    reducers: {
        getAgentListReducer(state, action) {
            console.log("#reducers getAgentListReducer start#",action.payload.page,action.payload.pageSize,action.payload.order,action.payload.query);
            state.isLoading = true;
            state.page = action.payload.pageSize === 999? 1: action.payload.page;
            state.pageSize = action.payload.pageSize;
            state.order = action.payload.order;
            state.query = action.payload.query;
        },
        setAgentListReducer(state, action) {
            console.log("#reducers setAgentListReducer start#",action.payload.partnerList);
            if((state.page -1) * state.pageSize > action.payload.totalCount) {
                state.page = 1
            }
            state.isLoading = false;
            state.totalCount = action.payload.totalCount;
            state.partnerList = action.payload.partnerList;
            state.partnerCount = action.payload.partnerCount;
            //state.userType = action.payload.cmmnCdUserTypeList
            //초기화
            state.partnerNos = [];

        },
        failedGetAgentListReducer(state, action) {
            console.log("#reducers failedGetAgentListReducer start#");
            state.isLoading = false;
            state.error = action.payload;
        },
        setQuery(state,action){
            state.query = action.payload;
        },
        setIsOpen(state,action){
            state.isOpen = action.payload;
        },
        setPartnerNos(state,action){
            state.partnerNos = action.payload;
        },
    }
});

export const {
    getAgentListReducer,
    setAgentListReducer,
    failedGetAgentListReducer,
    setQuery,
    setIsOpen,
    setPartnerNos,
} = AgentListReducerSlice.actions;

export default AgentListReducerSlice.reducer;