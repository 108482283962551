import React, {useEffect, useState,} from "react";
import axios from "axios";
import {NumericFormat} from 'react-number-format';
import moment from "moment";

const MemberPointLog = ({setMemberPointLog, userNo}) => {

    const [pointLog, setPointLog] = useState([]);
    const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().add(1, "day").format('YYYY-MM-DD'));
    const [query, setQuery] = useState("");
    const [totalDeposit, setTotalDeposit] = useState(0);
    const [totalWithdraw, setTotalWithdraw] = useState(0);

    const getNo = () => {
        axios.post('/admin/point_log/get_no', {
            user_no: userNo,
        }).then((res) => {
            setPointLog(res.data.pointLog)
            setTotalDeposit(res.data.totalDeposit)
            setTotalWithdraw(res.data.totalWithdraw)
        })

    }
    useEffect(getNo, [])

    const startDateHandler = (e) => {
        setStartDate(e.currentTarget.value)
    }
    const endDateHandler = (e) => {
        setEndDate(e.currentTarget.value)
    }

    const searchHandler = (query, startDate, endDate) => {
        axios.post('/admin/point_log/query', {
            start_date: startDate,
            end_date: endDate,
            query: query,
            manual: 10,
        }).then((res) => {
            if (res.data.pointLogList !== null) {
                setPointLog(res.data.pointLogList)
            } else {
                alert("실패")
            }
        })
    }

    const handleCancel = () => {
        setMemberPointLog(false);
    }

    return (
        <div className="memberLog_background" onClick={handleCancel}>
            <div className="log_modal_box" onClick={(event => event.stopPropagation())}>
                <div className="member_Log">
                    <div className="pointLog">
                        <div className="title p-2" style={{
                            textAlign: "left",
                            fontSize: "25px"
                        }}>
                            포인트로그
                        </div>
                        <div className="d-flex justify-content-end">
                            <div className="col-5 my-auto d-flex ps-3 justify-content-start">
                                <span className="me-2">총 충전금액 :
                                 <NumericFormat value={totalDeposit && totalDeposit[0].userDeposit} displayType={'text'} thousandSeparator={true}/></span>
                                <span className="me-2">총 환전금액 :
                                <NumericFormat value={totalWithdraw && totalWithdraw[0].userWithdraw} displayType={'text'} thousandSeparator={true}/></span>
                                <span style={{fontWeight: "bold"}}>차액 :
                                <NumericFormat value={(totalDeposit && totalDeposit[0].userDeposit) - (totalWithdraw && totalWithdraw[0].userWithdraw)} displayType={'text'} thousandSeparator={true}/></span>
                            </div>
                            <div className="col-7 d-flex justify-content-end">
                                <input type="date" className="form-control mx-1" value={startDate} onChange={startDateHandler} style={{
                                    width: "150px",
                                    height: "40px"
                                }}/>
                                <span className="mx-1 my-auto">~</span>
                                <input type="date" className="form-control mx-1" value={endDate} onChange={endDateHandler} style={{
                                    width: "150px",
                                    height: "40px"
                                }}/>

                                <div className=" justify-content-end me-2" style={{
                                    height: "40px",
                                }}>
                                    <button className="btn btn-secondary" type="button" id="button-addon2"
                                            onClick={() => searchHandler(query, startDate, endDate)}>검색
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className="modalOverflow content p-2">
                            <div className=" ">
                                <table className="table table-bordered table-sm text-center align-middle">
                                    <thead className="table-dark">
                                    <tr>
                                    <th>아이디 | 닉네임</th>
                                    <th>증가포인트</th>
                                    <th>감소포인트</th>
                                    <th>포인트합</th>
                                    <th>유형</th>
                                    <th>사유</th>
                                    <th>시간</th>
                                    <th>관리자</th>
                                    </tr>
                                    </thead>
                                    {pointLog.map((pointLog,index) => (
                                        <tbody key={pointLog.no}>
                                            <tr>
                                                <td>{pointLog.id} | {pointLog.user? pointLog.user.nickname : "-"}</td>
                                                <td>
                                                    <NumericFormat value={pointLog.increase_point} displayType={'text'} thousandSeparator={true}/>
                                                </td>
                                                <td>
                                                    <NumericFormat value={pointLog.decrease_point} displayType={'text'} thousandSeparator={true}/>
                                                </td>
                                                <td>
                                                    <NumericFormat value={pointLog.point} displayType={'text'} thousandSeparator={true}/>
                                                </td>
                                                <td>{pointLog.money_type}</td>
                                                <td>{pointLog.cont}</td>
                                                <td>{moment(pointLog.createdAt).format("YYYY-MM-DD HH:mm")}</td>
                                                <td>{pointLog.admin? pointLog.admin.id : "-"}</td>
                                            </tr>
                                        </tbody>
                                    ))
                                    }
                                </table>

                            </div>

                        </div>


                    </div>
                    <div className="member_Log_close" onClick={handleCancel}>
                        <button className="btn btn-primary">확인</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MemberPointLog;