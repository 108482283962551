import React from 'react';
import WithdrawMngListContainer from "../../containers/money/WithdrawMngListContainer";
const WithdrawMngPage = () => {
    return (
        <div>
            <h1>환전관리</h1>
            <WithdrawMngListContainer />
        </div>

    )
}

export default WithdrawMngPage;