import {createSlice} from "@reduxjs/toolkit"
export const statsSlice = createSlice({
    name: 'leagueApiSettingReducer',
    initialState: {
        //리그
        leagueSwitchStates : {},
        leaguePage: 1,
        leaguePageSize:20,
        nationNo: 0,
        leagueUseYn: "",
        leagueNm: "",
        leagueList : [],
        totalLeagueCount : 0,
        selectedRow: null,

    },
    reducers: {
        getLeagueApiSettingInfoListReducer(state, action) {
            console.log("##getLeagueApiSettingInfoListReducer##", action.payload.leaguePage,action.payload.leaguePageSize,action.payload.nationNo,action.payload.leagueUseYn,action.payload.leagueNm);
            state.isLoading = true;
            state.leaguePage = action.payload.leaguePage;
            state.leaguePageSize = action.payload.leaguePageSize;
            state.nationNo = action.payload.nationNo;
            state.leagueUseYn = action.payload.leagueUseYn;
            state.leagueNm = action.payload.leagueNm;
        },
        setLeagueApiSettingInfoListReducer(state, action) {
            console.log("#setLeagueApiSettingInfoListReducer#", action.payload);
            if((state.leaguePage -1) * state.leaguePageSize > action.payload.totalLeagueCount) {
                state.leaguePage = 1
            }
            state.isLoading = false;
            state.selectedRow = null;
            state.leagueList = action.payload.leagueList;
            state.totalLeagueCount = action.payload.totalLeagueCount;
        },
        failedGetLeagueApiSettingInfoListReducer(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        setLeagueSwitchStatesReducer(state, action){
            console.log("##setLeagueSwitchStatesReducer##", action.payload);
            const code = action.payload;
            const updatedLeagueList = state.leagueList.map((item) => {
                if (item.no === code) {
                    // 클릭된 국가의 use_yn 값을 변경
                    return {
                        ...item,
                        use_yn: item.use_yn === 'Y' ? 'N' : 'Y', // 'Y'와 'N'을 토글
                    };
                }
                return item;
            });
            state.leagueList = updatedLeagueList;
        },
        setLeagueListChange(state, action) {
            state.isLoading = false;
            state.leagueList = action.payload;
        },
        //조회조건 사용여부
        setLeagueUseYn(state,action){
            state.leagueUseYn = action.payload;
        },

        //조회조건 리그명
        setLeagueNm(state,action){
            state.leagueNm = action.payload;
        },
        resetLeagueApiSettingInfoListReducer(state, action) {
            state.leagueSwitchStates = {};
            state.leaguePage= 1;
            state.leaguePageSize=20;
            state.nationNo= 0;
            state.leagueUseYn= "";
            state.leagueNm= "";
            state.leagueList = [];
            state.totalLeagueCount = 0;
            state.selectedRow = null;
        },
        setSelectedRow(state, action) {
            state.isLoading = false;
            state.selectedRow = action.payload;
        },
    }
});

export const {
    getLeagueApiSettingInfoListReducer,
    setLeagueApiSettingInfoListReducer,
    failedGetLeagueApiSettingInfoListReducer,
    setLeagueSwitchStatesReducer,
    setLeagueListChange,
    setLeagueUseYn,
    setLeagueNm,
    resetLeagueApiSettingInfoListReducer,
    setSelectedRow,
} = statsSlice.actions;

export default statsSlice.reducer;