import React,{useState,useEffect} from 'react';
import axios from "axios";
import {setSportsListChange,setSelectedRow} from "../../redux/reducers/match/sportsApiSettingReducer";
import {useDispatch} from "react-redux";

const SportsListApiSettingComponent = ({initialState,getNationInfo,resetSportsList}) => {
    const dispatch = useDispatch()

    const [aa, setaa] = useState('../../images/sports/basketball.jpg');
    const handleChangeNm = (e,index) => {
        const newValue = e.currentTarget.value;
        console.log("index",index);
        const updatedSportsList = [...initialState.sportsList]; // Create a copy of the array
        updatedSportsList[index] = { ...updatedSportsList[index], sports_kor_nm: newValue };
        dispatch(setSportsListChange(updatedSportsList)); // Update the state with the new array
    }

    const handleChangeBookMaker = (e,index) => {
        const newValue = e.currentTarget.value;
        const updatedSportsList = [...initialState.sportsList]; // Create a copy of the array
        updatedSportsList[index] = { ...updatedSportsList[index], book_maker: newValue };
        dispatch(setSportsListChange(updatedSportsList)); // Update the state with the new array
    }

    const handleUpdate = (sportsList) => {
        if (window.confirm("변경 하시겠습니까?")) {
            const no = sportsList.no;
            const sportsKorNm = sportsList.sports_kor_nm;
            const bookMaker = sportsList.book_maker;

            axios.post("/admin/sportsApiSettingInfoApi/update", {
                no : no,
                sportsKorNm : sportsKorNm,
                bookMaker : bookMaker,
            }).then((res) => {
                if(res.data.isSportsUpdated){
                    alert("변경되었습니다.");
                    resetSportsList();
                }else{
                    alert("변경실패.");
                }
            })
        }

    }

    const changeRowColor = (index) => {
        dispatch(setSelectedRow(index));
    }

    return (
        <div className="m-2">
            <div className="card-body">
                <div>
                    <table className="table table-bordered table-sm text-center align-middle">
                         <thead className="table-dark">
                        <tr>
                            <th style={{width: "10%"}}>코드</th>
                            <th style={{width: "30%"}}>종목</th>
                            <th style={{width: "5%"}}>영문</th>
                            <th style={{width: "30%"}}>북메이커</th>
                            <th style={{width: "25%"}}>-</th>
                        </tr>
                        </thead>
                        {initialState.sportsList && initialState.sportsList.length > 0 ?
                            initialState.sportsList.map((sportsList, index) => (
                            <tbody key={sportsList.no} style={{ backgroundColor: index === initialState.selectedRow ? '#ead980' : 'transparent' }}>
                            <tr>
                                <td>{sportsList.sports_cd}</td>
                                <td style={{ textAlign: 'center',paddingLeft: '10px' }}>
                                    <img src={process.env.PUBLIC_URL +sportsList.sports_img_file_path} /><br />
                                    <input type="text" style={{ width: '100%' }} value={sportsList.sports_kor_nm} onChange={(e) => handleChangeNm(e, index)} />
                                </td>
                                <td>{sportsList.sports_eng_nm}</td>
                                <td><input type="text" style={{ width: '100%' }} value={sportsList.book_maker} onChange={(e) => handleChangeBookMaker(e, index)}/></td>
                                <td> <button className="btn btn-sm btn-primary w-50" onClick={() => handleUpdate(sportsList)}>변경</button><br />
                                     <button className="btn btn-sm btn-danger w-50"
                                             onClick={() => {
                                                 getNationInfo(sportsList.no);
                                                 changeRowColor(index);
                                             }}>국가정보
                                     </button>
                                </td>
                            </tr>
                        </tbody>
                            ))
                            :
                            <tbody>
                            <tr className="table-secondary">
                                <td colSpan= "8"><font size= '3'> 조회된 데이터가 없습니다.</font></td>
                            </tr>
                            </tbody>
                        }
                    </table>
                </div>
            </div>
        </div>
    );
};

export default SportsListApiSettingComponent;