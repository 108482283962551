import React from "react";
import {NumericFormat} from "react-number-format";
const HomeHoPartnerTotalListComponent = ({initialState}) => {
    console.log("##HomeHoPartnerTotalListComponent.initialState##",initialState);
    return (
        <div className="homet1">
            <table className="table table-bordered table-sm text-center align-middle calculate">
                <thead className="table-primary ">
                <tr>
                    <td></td>
                    <td colSpan="6">본사</td>
                    <td className="partner" colSpan="6">파트너</td>
                    <td className="total" colSpan="6">토탈</td>
                </tr>
                <tr>
                    <td>날짜</td>
                    <td>충전</td>
                    <td>환전</td>
                    <td>수익</td>
                    <td>가입/가입뱃</td>
                    <td>실뱃</td>
                    <td>입id/출id</td>

                    <td className="partner">충전</td>
                    <td className="partner">환전</td>
                    <td className="partner">수익</td>
                    <td className="partner">가입/가입뱃</td>
                    <td className="partner">실뱃</td>
                    <td className="partner">입id/출id</td>

                    <td className="total">충전</td>
                    <td className="total">환전</td>
                    <td className="total">수익</td>
                    <td className="total">가입/가입뱃</td>
                    <td className="total">실뱃</td>
                    <td className="total">입id/출id</td>
                </tr>
                </thead>
                {initialState.homeTable&&(
                <tbody>
                <tr>
                    {/*본사 시작*/}
                    <td>
                        <div>
                            <p>{initialState.homeTable.startDateInTable}</p>
                            <p>{" ~ "}</p>
                            <p>{initialState.homeTable.endDateInTable}</p>
                        </div>
                    </td>
                    <td>
                        <div>
                            <NumericFormat value={initialState.homeTable.bonsaDepositMoney} displayType={'text'}
                                           thousandSeparator={true}/>
                            <p>{initialState.homeTable.bonsaDepositCount}건</p>
                        </div>
                    </td>
                    <td>
                        <div>
                            <NumericFormat value={initialState.homeTable.bonsaWithdrawMoney} displayType={'text'}
                                           thousandSeparator={true}/>
                            <p>{initialState.homeTable.bonsaWithdrawCount}건</p>
                        </div>
                    </td>
                    <td>
                        <NumericFormat
                            value={initialState.homeTable.bonsaDepositMoney - initialState.homeTable.bonsaWithdrawMoney}
                            displayType={'text'} thousandSeparator={true}/>
                    </td>
                    <td>
                        <span>{initialState.homeTable.bonsaJoinUsers}</span> / <span>{initialState.homeTable.bonsaJoinBet}</span>
                    </td>
                    <td>{initialState.homeTable.bonsaUserBet}</td>
                    <td>
                        <span>{initialState.homeTable.depositId}</span> / <span>{initialState.homeTable.withdrawId}</span>
                    </td>
                    {/*본사 끝*/}
                    {/*파트너 시작*/}
                    <td className="borderLeft">
                        <div>
                            <NumericFormat value={initialState.homeTable.partnerDepositMoney} displayType={'text'}
                                           thousandSeparator={true}/>
                            <p>{initialState.homeTable.partnerDepositCount}건</p>
                        </div>
                    </td>
                    <td>
                        <div>
                            <NumericFormat value={initialState.homeTable.partnerWithdrawMoney} displayType={'text'}
                                           thousandSeparator={true}/>
                            <p>{initialState.homeTable.partnerWithdrawCount}건</p>
                        </div>
                    </td>
                    <td>
                        <NumericFormat
                            value={initialState.homeTable.partnerDepositMoney - initialState.homeTable.partnerWithdrawMoney}
                            displayType={'text'} thousandSeparator={true}/>
                    </td>
                    <td>
                        <span>{initialState.homeTable.partnerJoinUsers}</span> / <span>{initialState.homeTable.partnerJoinBet}</span>
                    </td>
                    <td>{initialState.homeTable.partnerUserBet}</td>
                    <td>
                        <span>{initialState.homeTable.partnerDepositId}</span> / <span>{initialState.homeTable.partnerWithdrawId}</span>
                    </td>
                    {/*파트너 끝*/}
                    {/*토탈 시작*/}
                    <td className="borderLeft">
                        <div>
                            <NumericFormat value={initialState.homeTable.depositMoney} displayType={'text'}
                                           thousandSeparator={true}/>
                            <p>{initialState.homeTable.depositCount}건</p>
                        </div>
                    </td>
                    <td>
                        <div>
                            <NumericFormat value={initialState.homeTable.withdrawMoney} displayType={'text'}
                                           thousandSeparator={true}/>
                            <p>{initialState.homeTable.withdrawCount}건</p>
                        </div>
                    </td>
                    <td>
                        <NumericFormat
                            value={initialState.homeTable.depositMoney - initialState.homeTable.withdrawMoney}
                            displayType={'text'} thousandSeparator={true}/>
                    </td>
                    <td>
                        <span>{initialState.homeTable.totalJoinUsers}</span> / <span>{initialState.homeTable.totalJoinBet}</span>
                    </td>
                    <td>{initialState.homeTable.totalUserBet}</td>
                    <td>
                        <span>{initialState.homeTable.depositId}</span> / <span>{initialState.homeTable.withdrawId}</span>
                    </td>
                    {/*토탈 끝*/}
                </tr>
                </tbody>
                )}
            </table>
        </div>

    )
}

export default HomeHoPartnerTotalListComponent;