import {combineReducers} from "redux";
import storage from "redux-persist/lib/storage"
import userInfoReducer  from "./userInfo"
import sportReducer from "./match/sport"
import leagueReducer from "./match/league"
import teamReducer from "./match/team"
import marketReducer from "./match/market"
import bookmakerReducer from "./match/bookmaker"
import settingMarketReducer from "./match/settingMarket"
import fixtureReducer from "./match/fixture"
import parsingWaitReducer from "./match/parsingWait"
import activeMatchReducer from "./match/activeMatch"
import closeMatchReducer from "./match/closeMatch"
import stopMatchReducer from "./match/stopMatch"
import stopMatchByReducer from "./match/stopMatchBy"
import resultMatchReducer from "./match/resultMatch"
import cancelMatchByReducer from "./match/cancelMatchBy"
import cancelMatchReducer from "./match/cancelMatch"
import totalBettingReducer from "./betting/totalBetting"
import activeBettingReducer from "./betting/activeBetting"
import cancelBettingReducer from "./betting/cancelBetting"
import closeBettingReducer from "./betting/closeBetting"
import completeBettingReducer from "./betting/completeBetting"
import statReducer from "./stat"
import homeReducer from "./home/HomeReducer"
import MoneyLogMngReducer from "./money/MoneyLogMngReducer"
import PointLogMngReducer from "./money/PointLogMngReducer"
import WithdrawMngReducer from "./money/WithdrawMngReducer"
import WithdrawCompleteReducer from "./money/WithdrawCompleteReducer"
import statusReducer from "./status"
import depositReducer from "./money/deposit"
import depositCompleteReducer from "./money/depositComplete"
import noticeReducer from "./board/notice"
import helpReducer from "./board/help"
import faqReducer from "./board/faq"
import marqueeReducer from "./board/marquee"
import popupReducer from "./board/popup"
import eventReducer from "./board/event"

import memberReducer from "./user/member"
import attendReducer from "./user/attend"
import memoReducer from "./user/memo"
import loginLogReducer from "./user/loginLog"
import blockReducer from "./user/block"

import UserDetailReducer from "./user/UserDetailReducer"
import JoinListReducer from "./user/JoinListReducer"
import ConnectListReducer from "./user/ConnectListReducer"
import LostListReducer from "./user/LostListReducer"
import FailListReducer from "./user/FailListReducer"
import AdminReducer from "./user/AdminReducer"
import AdminFailLogReducer from "./user/AdminFailLogReducer"

import {persistReducer} from "redux-persist";
import SideStatusReducer from "./status/SideStatusReducer";
import sportsApiSettingReducer from "./match/sportsApiSettingReducer";
import nationApiSettingReducer from "./match/nationApiSettingReducer";
import leagueApiSettingReducer from "./match/leagueApiSettingReducer";
import teamApiSettingReducer from "./match/teamApiSettingReducer";
import marketApiSettingReducer from "./match/marketApiSettingReducer";
import basicSettingPageReducer from "./match/basicSettingPage"
import sportMarketListSettingPageReducer from "./match/sportMarketListSettingPage"
import AgentListReducer from "./agent/AgentListReducer"
import DailyFeeReducer from "./agent/DailyFeeReducer"
import joinCodeReducer from "./setting/joinCode"
import PageAphAthrReducer from "./setting/PageAphAthrReducer"
import MenuListReducer from "./common/MenuListReducer";



const persistConfig = {
    key: "root",
    storage,
    whitelist: ["userInfo"]
}

const rootReducer = combineReducers({
    userInfo: userInfoReducer,
    sports: sportReducer,
    leagues: leagueReducer,
    teams: teamReducer,
    markets: marketReducer,
    bookmakers: bookmakerReducer,
    settingMarkets: settingMarketReducer,
    fixtures: fixtureReducer,
    parsingWaits: parsingWaitReducer,
    activeMatches: activeMatchReducer,
    closeMatches: closeMatchReducer,
    stopMatches: stopMatchReducer,
    stopMatchesBy: stopMatchByReducer,
    resultMatches: resultMatchReducer,
    cancelMatchesBy: cancelMatchByReducer,
    cancelMatches: cancelMatchReducer,
    totalBetting: totalBettingReducer,
    activeBetting: activeBettingReducer,
    cancelBetting: cancelBettingReducer,
    closeBetting: closeBettingReducer,
    completeBetting: completeBettingReducer,
    stat: statReducer,
    home: homeReducer,
    status: statusReducer,
    deposit: depositReducer,
    depositComplete: depositCompleteReducer,
    notice: noticeReducer,
    help: helpReducer,
    faq: faqReducer,
    marquee: marqueeReducer,
    popup: popupReducer,
    event: eventReducer,
    member: memberReducer,
    attend: attendReducer,
    memo: memoReducer,
    loginLog: loginLogReducer,
    block: blockReducer,
    MoneyLogMngReducer : MoneyLogMngReducer,
    PointLogMngReducer : PointLogMngReducer,
    WithdrawMngReducer : WithdrawMngReducer,
    WithdrawCompleteReducer : WithdrawCompleteReducer,
    UserDetailReducer : UserDetailReducer,
    JoinListReducer : JoinListReducer,
    ConnectListReducer : ConnectListReducer,
    LostListReducer : LostListReducer,
    FailListReducer : FailListReducer,
    AdminReducer : AdminReducer,
    AdminFailLogReducer : AdminFailLogReducer,
    SideStatusReducer : SideStatusReducer,
    sportsApiSettingReducer : sportsApiSettingReducer,
    nationApiSettingReducer : nationApiSettingReducer,
    leagueApiSettingReducer : leagueApiSettingReducer,
    teamApiSettingReducer : teamApiSettingReducer,
    marketApiSettingReducer : marketApiSettingReducer,
    basicSettingPage : basicSettingPageReducer,
    sportMarketListSettingPage : sportMarketListSettingPageReducer,
    AgentListReducer : AgentListReducer,
    DailyFeeReducer : DailyFeeReducer,
    joinCodeReducer: joinCodeReducer,
    PageAphAthrReducer: PageAphAthrReducer,
    MenuListReducer: MenuListReducer,
});

export default persistReducer(persistConfig, rootReducer);