import {createSlice} from "@reduxjs/toolkit"
export const PageAphAthrReducerSlice = createSlice({
    name: 'PageAphAthrReducer',
    initialState: {
        isLoading: false,
        error: null,

        //input
        page: 1,
        pageSize: 20,

        //output1
        adminList: [],
        totalCount: 0,

        //output2
        menuList : [],
        lclmenuList : [],

        //etc
        selectedRow: null,
        selectedAdminNo: null,
        inputMenuList: [],
    },
    reducers: {
        getPageAphAthrReducer(state, action) {
            state.isLoading = true;
            state.page = action.payload.pageSize === 999? 1: action.payload.page;
            state.pageSize = action.payload.pageSize;
        },
        setPageAphAthrReducer(state, action) {
            if((state.page -1) * state.pageSize > action.payload.adminCount) {
                state.page = 1
            }
            console.log("##setPageAphAthrReducer.adminList##",action.payload.adminList);
            console.log("##setPageAphAthrReducer.adminCount##",action.payload.adminCount);
            state.isLoading = false;
            state.totalCount = action.payload.adminCount;
            state.adminList = action.payload.adminList;
            state.menuList = [];
            state.lclmenuList = [];
            state.selectedRow = null;
            state.selectedAdminNo = null;
        },
        failedGetPageAphAthrReducer(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        setMenuList(state,action){
            state.menuList = action.payload;
        },
        setInputMenuList(state,action){
            state.inputMenuList = action.payload;
        },
        setSelectedRow(state,action){
            state.selectedRow = action.payload;
        },

        getPageAphAthrMenuListSettingReducer(state, action) {
            console.log("##getPageAphAthrMenuListSettingReducer.adminNo##",action.payload.selectedAdminNo);
            state.isLoading = true;
            state.selectedAdminNo = action.payload.selectedAdminNo
        },
        setPageAphAthrMenuListSettingReducer(state, action) {
            console.log("##setPageAphAthrMenuListSettingReducer.menuList##",action.payload.menuList);
            console.log("##setPageAphAthrMenuListSettingReducer.lclmenuList##",action.payload.lclmenuList);
            state.isLoading = false;
            state.menuList = action.payload.menuList;
            state.lclmenuList = action.payload.lclmenuList;
        },
        failedGetPageAphAthrMenuListSettingReducer(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
    }
});

export const {
    getPageAphAthrReducer,
    setPageAphAthrReducer,
    failedGetPageAphAthrReducer,
    setMenuList,
    setInputMenuList,
    setSelectedRow,
    getPageAphAthrMenuListSettingReducer,
    setPageAphAthrMenuListSettingReducer,
    failedGetPageAphAthrMenuListSettingReducer,
} = PageAphAthrReducerSlice.actions;

export default PageAphAthrReducerSlice.reducer;