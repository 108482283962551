import React from "react";

const FailListComponent = ({initialState}) => {
    function getCountryKoreanName(englishName) {
        switch (englishName) {
            case "South Korea" : return "대한민국"
            case "China" : return "중국"
            case "Japan" : return "일본"
            case "Thailand" : return "태국"
            case "Singapore" : return "싱가포르"
            case "Laos" : return "라오스"
            case "North Korea" : return "북한"
            case "Vietnam" : return "베트남"
            case "Cambodia" : return "캄보디아"
            case "Taiwan" : return "대만"
            case "Philippines" : return "필리핀"
            case "Malaysia" : return "말레이시아"
            case "Burma Myanmar" : return "미얀마"
            case "Indonesia" : return "인도네시아"
            case "India" : return "인도네시아"
            default : return englishName
        }
    }

    function getDevice(device) {
        switch (device) {
            case 0 : return "PC"
            case 1 : return "안드로이드"
            case 2 : return "아이폰"
            default : return device
        }
    }
    return (
        <div>
            <table className="table table-bordered table-sm text-center align-middle">
                <thead className="table-dark">
                <tr>
                    <th>아이디</th>
                    <th>비밀번호</th>
                    <th>도메인</th>
                    <th>IP</th>
                    <th>국가</th>
                    <th>접속장비</th>
                    <th>틀린시간</th>
                    <th>틀린사유</th>
                </tr>
                </thead>
                {initialState.loginFailList && initialState.loginFailList.length > 0 ?
                    initialState.loginFailList.map((login_fail_log, index) => (
                        <tbody key={login_fail_log.no}>
                        <tr>
                            <td>{login_fail_log.id}</td>
                            <td>{login_fail_log.pw}</td>
                            <td>{login_fail_log.domain}</td>
                            <td>{login_fail_log.ip}</td>
                            <td>{getCountryKoreanName(login_fail_log.country)}</td>
                            <td>{getDevice(login_fail_log.device)}</td>
                            <td>{login_fail_log.createdAt}</td>
                            <td>{login_fail_log.reason}</td>
                        </tr>
                        </tbody>
                    ))
                    :
                    <tbody>
                    <tr className="table-secondary">
                        <td colSpan= "8"><font size= '3'> 조회된 데이터가 없습니다.</font></td>
                    </tr>
                    </tbody>
                }
            </table>
        </div>
    )
}

export default FailListComponent;