import React, {useState} from 'react';
import { useLocation } from "react-router-dom";
import {Link, Outlet, useNavigate} from "react-router-dom";

const Match = () => {

    const navigate = useNavigate();
    const [activeNav, setActiveNav] = useState();
    const [menuName, setMenuName] = useState("경기현황");

    const location = useLocation();
    const currentPath = location.pathname;
    //const currentPath = "/match/BasicSettingPage";


    const ontoggle = (index, address,name) => {
        setActiveNav(index)
        navigate(address)
        setMenuName(name)
    }
    const menuArr = [
        {name:"경기현황", address:"/matchMng/active"},
        {name:"경기설정", address:"/matchMng/BasicSettingPage"}
    ]

    return (
        <div>
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
                <div className="collapse navbar-collapse" style={{
                    marginLeft: "290px",
                    height: "40px"
                }}>
                    <ul className="navbar-nav text-center">
                        {menuArr.map((menu,index) => (
                            <li key={index} className="mx-2" onClick={() => ontoggle(index, menu.address,menu.name)}>
                                <div className={activeNav === index ? "nav-item active" : "nav-item"} style={{cursor: "pointer"}}>
                                    {menu.name}
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>

            </nav>
            <div style={{
                paddingLeft: "10px",
                display: 'flex'
            }}>
                <div style={{
                    width: "200px",
                    textAlign: "center"
                }}>

                    {currentPath === "/matchMng/wait" ||
                    currentPath === "/matchMng/active" ||
                    currentPath === "/matchMng/close" ||
                    currentPath === "/matchMng/result" ||
                    currentPath === "/matchMng/stop" ||
                    currentPath === "/matchMng/cancel"
                        ?
                        <nav>
                            <p>스포츠 경기 현황 </p>
                            <p><Link to="/matchMng/wait">파싱대기</Link></p>
                            <p><Link to="/matchMng/active">경기진행중</Link></p>
                            <p><Link to="/matchMng/close">경기마감</Link></p>
                            <p><Link to="/matchMng/result">경기결과</Link></p>
                            <p><Link to="/matchMng/stop">파싱중지</Link></p>
                            <p><Link to="/matchMng/cancel">파싱취소</Link></p>
                        </nav>
                        :
                        <nav>
                            <p style={{ textAlign: 'left' }}>
                                <Link to="/matchMng/BasicSettingPage" className={currentPath === "/matchMng/BasicSettingPage" ? "selected-leftmenu" : ""}>
                                    1.스포츠 기본 설정
                                </Link>
                            </p>
                            <p style={{ textAlign: 'left' }}>
                                <Link to="/matchMng/SportsListApiSettingPage" className={currentPath === "/matchMng/SportsListApiSettingPage" ? "selected-leftmenu" : ""}>
                                    2.스포츠 API 설정
                                </Link>
                            </p>
                            <p style={{ textAlign: 'left' }}>
                                <Link to="/matchMng/MarketListApiSettingPage" className={currentPath === "/matchMng/MarketListApiSettingPage" ? "selected-leftmenu" : ""}>
                                    3.스포츠 API 마켓설정
                                </Link>
                            </p>
                            <p style={{ textAlign: 'left' }}>
                                <Link to="/matchMng/SportsMarketListSettingPage" className={currentPath === "/matchMng/SportsMarketListSettingPage" ? "selected-leftmenu" : ""}>
                                    4.스포츠 종목별 마켓설정
                                </Link>
                            </p>
                        </nav>
                    }
                </div>
                <div style={{
                    width: "100%"
                }}>
                    <Outlet />
                </div>
            </div>
        </div>

    )
}

export default Match;