import React from 'react';
import MarketListApiSettingContainer from "../../containers/match/MarketListApiSettingContainer";

const MarketListApiSettingPage = () => {
    return (
        <div>
            <h3>스포츠 API 마켓 설정</h3>
            <MarketListApiSettingContainer/>
        </div>
    );
};

export default MarketListApiSettingPage;