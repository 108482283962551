import {call, put, take,} from "redux-saga/effects";
import {fetchFixture} from "../../../lib/api";
import {closeChannel, createSocketChannel} from "../../channel/createSocketChannel";
import socket from "../../../config/socket";
import {changeFixture, failedGetFixture, pushFixtureTask, setFixture} from "../../reducers/match/fixture";

export function* getFixtureSaga(action) {
    try {
        const res = yield call(fetchFixture, action.payload.page, action.payload.pageSize, action.payload.status, action.payload.sport, action.payload.league, action.payload.query, action.payload.startDate, action.payload.endDate);
        yield put(setFixture(res.data));
    } catch(error) {
        console.log(error)
        yield put(failedGetFixture(error));
    }
}

export function* waitFixtureTaskSaga() {
    let channel;
    try {
        channel = yield call(createSocketChannel, "fixture");
        while(true) {
            const task = yield take(channel);
            yield onTaskSaga(task)
        }
    } catch (e) {
        console.log(e, "error");
    } finally {
        socket.close();
        closeChannel(channel)
    }
}

function* onTaskSaga(task) {
    yield put(pushFixtureTask(task));
    yield put(changeFixture(task));
}

export function* changeFixtureSaga() {
    //yield put(changeSport(changedItem))
}

