import React from 'react';
import CancelMatchByListContainer from "../../containers/CancelMatchByListContainer";

/**
 * 함수형 컴포넌트 스니펫입니다.
 */
const CancelMatchBy = () => {
    return (
        <div>
            <h1>수동취소</h1>
            <CancelMatchByListContainer />
        </div>
    );
};

export default CancelMatchBy;