import React from "react";
import moment from "moment";
import {setUserInformation, setSelectedNos, setToastState, setTotalSelectedMoney, setMemberDepositLog, setUserNo, setMemberWithdrawLog, setMemberMoneyLog, setMemberPointLog} from "../../redux/reducers/money/WithdrawMngReducer";
import {useDispatch} from "react-redux";
import {NumericFormat} from "react-number-format";
import Toast from "../../components/Toast";
import typeCase from "../../util/typeCase";
import {Link} from "react-router-dom";
import MemberDepositLog from "../../components/modal/MemberDepositLog";
import MemberWithdrawLog from "../../components/modal/MemberWithdrawLog";
import MemberMoneyLog from "../../components/modal/MemberMoneyLog";
import MemberPointLog from "../../components/modal/MemberPointLog";
import UserInformation from "../UserInformation";

const WithdrawMngListComponets = ({initialState, updateState}) => {
    const dispatch = useDispatch()
    const informationOpenHandler = () => {
        dispatch(setUserInformation(true))
    }
    function toastHandler() {
        dispatch(setToastState)(true)
    }
    const withdrawStateColor = (state) => {
        switch(state) {
            case 0 :
                return "bgRequest"
            case 1 :
                return "bgWait"
            case 2 :
                return "bgComplete"
            case 3 :
                return "bgCancel"
            case 10 :
                return "bg-secondary"
            default : return state
        }
    }
    function onCommentColor (color) {
        switch (color) {
            case 0 : return "red"
            case 1 : return "orange"
            case 2 : return "yellow"
            case 3 : return "green"
            case 4 : return "blue"
            case 5 : return "navy"
            case 6 : return "purple"
            case 10 : return "gray"
            default : return color
        }
    }
    const allCheckHandler = (e) => {
        if(initialState.withdrawList !== null && e.currentTarget.value) {
            const checks = [];
            let total = 0;
            for(let i = 0; i < initialState.withdrawList.length; i++) {
                checks.push(initialState.withdrawList[i].no)
                total += initialState.withdrawList[i].request_money
            }
            dispatch(setSelectedNos(e.target.checked? checks : []))
            dispatch(setTotalSelectedMoney(e.target.checked? total : 0))
        }
    }

    const checkHandler = (checked, no) => {
        let selectedNos2 = []
        if(checked) {
            selectedNos2 = [...initialState.selectedNos, no]
            dispatch(setSelectedNos([...initialState.selectedNos, no]))
        }
        else {
            selectedNos2 = initialState.selectedNos.filter((id) => id !== no)
            dispatch(setSelectedNos(initialState.selectedNos.filter((id) => id !== no)))
        }
        let total = 0;
        for(let i = 0; i < selectedNos2.length; i++) {
            total += initialState.withdrawList.filter((id) => id.no === selectedNos2[i])[0].request_money
        }
        dispatch(setTotalSelectedMoney(total))
    }

    const memberDepositLogOpen = (open, userNo) => {
        dispatch(setMemberDepositLog(open))
        dispatch(setUserNo(userNo))
    }
    const memberWithdrawLogOpen = (open, userNo) => {
        dispatch(setMemberWithdrawLog(open))
        dispatch(setUserNo(userNo))
    }
    const memberMoneyLogOpen = (open, userNo) => {
        dispatch(setMemberMoneyLog(open));
        dispatch(setUserNo(userNo))
    }
    const memberPointLogOpen = (open, userNo) => {
        dispatch(setMemberPointLog(open))
        dispatch(setUserNo(userNo))
    }

    const withdrawState = (state, no) => {
        switch(state) {
            case 0 :
                return (
                    <div className="d-flex justify-content-center" >
                        <button type="button" className="btn btn-warning btn-sm xSmallButton" onClick={() => {updateState(1, [no])}}>대기</button>
                        <button type="button" className="btn btn-primary btn-sm xSmallButton" onClick={() => {updateState(2, [no])}}>완료</button>
                        <button type="button" className="btn btn-danger btn-sm xSmallButton" onClick={() => {updateState(3, [no])}}>취소</button>
                    </div>
                )
            case 1 :
                return (
                    <div className="d-flex justify-content-center">
                        <button type="button" className="btn btn-primary btn-sm xSmallButton" onClick={() => {updateState(2, [no])}}>완료</button>
                        <button type="button" className="btn btn-danger btn-sm xSmallButton" onClick={() => {updateState(3, [no])}}>취소</button>
                    </div>
                )
            case 2 :
                return "완료"
            case 3 :
                return "-"
            default : return state
        }
    }

    return (
        <div className="withdrawt1">
            <table className="table table-hover table-bordered table-sm text-center align-middle">
                <thead className="table-dark">
                <tr>
                    <th>
                        <input className="form-check-input" type="checkbox" checked={initialState.withdrawListCount === initialState.selectedNos.length} onChange={allCheckHandler}
                               id="flexCheckDefault"/>
                    </th>
                    <th>파트너</th>
                    <th>상태</th>
                    <th>등급</th>
                    <th style={{width: "8%"}}>닉네임</th>
                    <th>충전</th>
                    <th>환전</th>
                    <th>충환차액</th>
                    <th>이달수익</th>
                    <th>보유머니</th>
                    <th>예금주</th>
                    <th>계좌번호</th>
                    <th>환전금액</th>
                    <th>롤링%</th>
                    <th style={{width: "15%"}}>회원정보</th>
                    <th style={{width: "6%"}}>요청시간</th>
                    <th style={{width: "6%"}}>처리시간</th>
                    <th>처리상태</th>
                    <th>환전구분</th>
                    <th>관리자</th>
                </tr>
                </thead>
                {initialState.totalCount > 0 ?
                    initialState.withdrawList && initialState.withdrawList.map((withdraw, index) => (
                    <tbody key={withdraw.no}>
                    <tr className={withdrawStateColor(withdraw.withdraw_state)}>
                        <td>
                            <input className="form-check-input" type="checkbox"
                                   checked={initialState.selectedNos.filter((no) => no === withdraw.no).length > 0}
                                   onChange={(e) => {
                                       checkHandler(e.currentTarget.checked, withdraw.no)
                                   }}/>
                        </td>
                        <td>본사</td>
                        <td>{typeCase.userState(withdraw.user.user_state)}</td>
                        <td>{typeCase.levelState(withdraw.user.level)}</td>
                        <td>
                            <Link className="nav-link" to={"#userInformation" + index} data-bs-toggle="collapse"
                                  aria-expanded="false" aria-controls={"#userInformation" + index} onClick={informationOpenHandler}>
                                {withdraw.user.comment ?
                                    <>
                                        <span>{withdraw.user.nickname}</span>
                                        <br></br>
                                        <span style={{fontSize: "12px"}}>
                                                            <span className="me-1" style={{backgroundColor: onCommentColor(withdraw.user.comment_color)}}>
                                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                            </span>
                                            {withdraw.user.comment}
                                                    </span>
                                    </>
                                    :  (withdraw.user.comment_color !== null?
                                        <>
                                            <span>{withdraw.user.nickname}</span>
                                            <br></br>
                                            <span className="me-1" style={{backgroundColor: onCommentColor(withdraw.user.comment_color)}}>
                                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                </span>
                                        </>
                                        :  <span>{withdraw.user.nickname}</span> )}
                            </Link>
                        </td>
                        <td>
                            <NumericFormat value={initialState.userDeposit.filter((el) => el.user_no === withdraw.user_no).length > 0? initialState.userDeposit.filter((el) => el.user_no === withdraw.user_no)[0].user_total : 0} displayType={'text'} thousandSeparator={true}/>
                        </td>
                        <td>
                            <NumericFormat value={initialState.userWithdraw.filter((el) => el.user_no === withdraw.user_no).length > 0? initialState.userWithdraw.filter((el) => el.user_no === withdraw.user_no)[0].user_total : 0} displayType={'text'} thousandSeparator={true}/>
                        </td>
                        <td>
                            <NumericFormat value={
                                (initialState.userDeposit.filter((el) => el.user_no === withdraw.user_no).length > 0? initialState.userDeposit.filter((el) => el.user_no === withdraw.user_no)[0].user_total : 0)
                                -
                                (initialState.userWithdraw.filter((el) => el.user_no === withdraw.user_no).length > 0? initialState.userWithdraw.filter((el) => el.user_no === withdraw.user_no)[0].user_total : 0)
                            } displayType={'text'} thousandSeparator={true}/>
                        </td>
                        {/*이달수익*/}
                        <td>
                            <NumericFormat value={
                                (initialState.monthDeposit.filter((el) => el.user_no === withdraw.user_no).length > 0? initialState.monthDeposit.filter((el) => el.user_no === withdraw.user_no)[0].user_total : 0)
                                -
                                (initialState.monthWithdraw.filter((el) => el.user_no === withdraw.user_no).length > 0? initialState.monthWithdraw.filter((el) => el.user_no === withdraw.user_no)[0].user_total : 0)
                            } displayType={'text'} thousandSeparator={true}/>
                        </td>
                        <td>
                            <NumericFormat value={withdraw.user.money} displayType={'text'} thousandSeparator={true}/>
                        </td>
                        <td onClick={() => toastHandler(navigator.clipboard.writeText(withdraw.user.name))}
                            style={{
                                fontSize: "14px",
                                fontWeight: "bold"
                            }}>
                            {
                                initialState.toastState === true ? (
                                    <Toast setToastState={dispatch(setToastState(false))} />
                                ) : null
                            }
                            {withdraw.user.name}
                        </td>

                        <td onClick={() => toastHandler(navigator.clipboard.writeText(withdraw.user.withdraw_account))}
                            style={{
                                fontSize: "14px",
                                fontWeight: "bold"
                            }}>
                            {
                                initialState.toastState === true ? (
                                    <Toast setToastState={dispatch(setToastState(false))} />
                                ) : null
                            }
                            {withdraw.user.bank}
                            <br></br>
                            {withdraw.user.withdraw_account}
                        </td>
                        <td onClick={() => toastHandler(navigator.clipboard.writeText(withdraw.request_money))}
                            style={{
                                fontSize: "14px",
                                fontWeight: "bold",
                                color: "#bf0010"
                            }}>
                            {
                                initialState.toastState === true ? (
                                    <Toast setToastState={dispatch(setToastState(false))} />
                                ) : null
                            }
                            <NumericFormat value={withdraw.request_money} displayType={'text'} thousandSeparator={true}/>
                        </td>
                        {/*롤링퍼센티지*/}
                        <td>
                            {/*스포츠(100%)*/}
                            {/*<br></br>*/}
                            {/*미니게임(400%)*/}
                            {/*<button onClick={() => {alert("ddd")}}>배팅내역</button>*/}
                        </td>
                        <td>
                            <button className="btn btn-sm btn-primary m-1" onClick={() => memberDepositLogOpen("true", withdraw.user.no, 1)}>
                                충</button>
                            {initialState.memberDepositLog && <MemberDepositLog userNo={initialState.userNo} setMemberDepositLog={dispatch(setMemberDepositLog(false))}/>}

                            <button className="btn btn-sm btn-danger m-1" onClick={() => memberWithdrawLogOpen("true", withdraw.user.no, 1)}>
                                환</button>
                            {initialState.memberWithdrawLog && <MemberWithdrawLog userNo={initialState.userNo} setMemberWithdrawLog={dispatch(setMemberWithdrawLog(false))}/>}

                            <Link to={"#collapseBettingList" + index} data-bs-toggle="collapse"
                                  aria-expanded="false" aria-controls={"#collapseBettingList" + index}>
                                <button className="btn btn-sm btn-secondary m-1" >
                                    뱃</button>
                            </Link>

                            <button className="btn btn-sm btn-warning m-1" onClick={() => memberMoneyLogOpen("true", withdraw.user.no, 1)}>
                                M</button>
                            {initialState.memberMoneyLog && <MemberMoneyLog userNo={initialState.userNo} setMemberMoneyLog={dispatch(setMemberMoneyLog(false))}/>}

                            <button className="btn btn-sm btn-success m-1" onClick={() => memberPointLogOpen("true", withdraw.user.no, 1)}>
                                P</button>
                            {initialState.memberPointLog && <MemberPointLog userNo={initialState.userNo} setMemberPointLog={dispatch(setMemberPointLog(false))}/>}

                        </td>
                        <td>
                            {moment(withdraw.createdAt).format("YYYY-MM-DD HH:mm:ss")}
                        </td>
                        <td>
                            {moment(withdraw.updatedAt).format("YYYY-MM-DD HH:mm:ss")}
                        </td>
                        <td>{withdrawState(withdraw.withdraw_state, withdraw.no)}</td>
                        <td>{typeCase.depositType(withdraw.withdraw_type)}</td>
                        <td>{withdraw.admin? withdraw.admin.id : "-"}</td>
                    </tr>
                    <tr className="collapse" id={"userInformation" + index} >
                        {initialState.userInformation && <UserInformation no={withdraw.user.no} id={withdraw.user.id}/> }
                    </tr>
                    <tr className="collapse" id={"collapseBettingList" + index}>
                        <td colSpan="20">
                            <div className="container-fluid">
                                <div className="text-start fs-3">
                                    배팅내역
                                </div>
                                <div style={{height: "300px"}}></div>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                    ))
                    :
                    <tbody>
                    <tr className="table-secondary">
                        <td colSpan= "20"><font size= '3'> 조회된 데이터가 없습니다.</font></td>
                    </tr>
                    </tbody>
                }
            </table>
        </div>
    );
}

export default WithdrawMngListComponets;