import {createSlice} from "@reduxjs/toolkit"
import {PURGE} from "redux-persist";

const initialState = {
    isLoading: false,
    userNo: -1,
    id: null,
    isLogin: false,
    level: null,
    error: null
}

export const userInfoSlice = createSlice({
    name: 'userInfo',
    initialState,
    reducers: {
        login(state, action) {
            state.userNo = action.payload.userNo;
            state.id = action.payload.id;
        },
        setUserInfo(state, action) {
            if(action.payload.isLogin) {
                state.isLogin = action.payload.isLogin;
                state.userNo = action.payload.userNo;
                state.id = action.payload.id;
                state.level = action.payload.level;
            }
            else {
                state.isLogin = action.payload.isLogin;
            }

        },
        failedLogin(state, action) {
            state.isLoading = false;
            state.error = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(PURGE, () => initialState);
    }
})

export const { login, setUserInfo, failedLogin } = userInfoSlice.actions
export default userInfoSlice.reducer