import React, {useState, useEffect} from 'react';
import {Link, useNavigate} from "react-router-dom";
import axios from "axios";
const LiveStatus = () => {

    const [helpSignUp, setHelpSignUp] = useState(0);
    const [helpReady, setHelpReady] = useState(0);
    const [todayJoin, setTodayJoin] = useState(0);
    const [depositSignUp, setDepositSignUp] = useState(0);
    const [depositReady, setDepositReady] = useState(0);
    const [depositComplete, setDepositComplete] = useState(0);
    const [withdrawSignUp, setWithdrawSignUp] = useState(0);
    const [withdrawReady, setWithdrawReady] = useState(0);
    const [withdrawComplete, setWithdrawComplete] = useState(0);
    const [lostSignUp, setLostSignUp] = useState(0);
    const [lostComplete, setLostComplete] = useState(0);
    const [failLogCount, setFailLogCount] = useState(0);


    const liveStatus = () => {
        axios.post('/admin/boardStatus/live_status')
            .then((res) => {
                setHelpSignUp(res.data.helpSignUp)
                setHelpReady(res.data.helpReady)
                setDepositSignUp(res.data.depositSignUp)
                setDepositReady(res.data.depositReady)
                setDepositComplete(res.data.depositComplete)
                setWithdrawSignUp(res.data.withdrawSignUp)
                setWithdrawReady(res.data.withdrawReady)
                setWithdrawComplete(res.data.withdrawComplete)
                setTodayJoin(res.data.todayJoin)
                setLostSignUp(res.data.lostSignUp)
                setLostComplete(res.data.lostComplete)
                setFailLogCount(res.data.failLogCount)

                // console.log(res.data.todayJoin)
            })
    }
    //TODO 간헐적오류
    //useEffect(liveStatus)

    const [activeNav, setActiveNav] = useState();
    const navigate = useNavigate();

    const ontoggle = (index, address) => {
        setActiveNav(index)
        navigate(address)
        //console.log(address)
    }
    return (

        <div className="align-items-center d-flex ah">
            <div className="d-flex">
                <div className="d-flex" onClick={() => ontoggle(2, "/board/help")} style={{cursor: "pointer"}}>
                    <span className="border bg-dark text-white p-2">고객센터</span>
                    <span className="border p-2">{helpSignUp}│{helpReady}</span>
                </div>
                <Link to="/user/member" className="d-flex">
                    <span className="border bg-dark text-white p-2">회원가입</span>
                    <span className="border p-2">0│{todayJoin}</span>
                </Link>
                <Link to="/money/deposit" className="d-flex">
                    <span className="border bg-dark text-white p-2">충전</span>
                    <span className="border p-2">{depositSignUp}│{depositReady}│{depositComplete}</span>
                </Link>
                <Link to="/money/deposit_msg" className="d-flex">
                    <span className="border bg-dark text-white p-2">충전문자</span>
                    <span className="border p-2">1│1</span>
                </Link>
                <Link to="/money/WithdrawMngPage" className="d-flex">
                    <span className="border bg-dark text-white p-2">환전</span>
                    <span className="border p-2">{withdrawSignUp}│{withdrawReady}│{withdrawComplete}</span>
                </Link>
            </div>

            <div className="d-flex">
                <Link to="/agent/Agentlist" className="d-flex">
                    <span className="border bg-dark text-white p-2">파트너환전</span>
                    <span className="border p-2">1│1</span>
                </Link>
                <Link to="/user/member" className="d-flex">
                    <span className="border bg-dark text-white p-2">회원수정</span>
                    <span className="border p-2">1</span>
                </Link>
                <div className="d-flex" onClick={() => ontoggle(7, "/user/connect_list")} style={{cursor: "pointer"}}>
                    <span className="border bg-dark text-white p-2">접속중</span>
                    <span className="border p-2">1</span>
                </div>
                <Link to="/user/lost_list" className="d-flex">
                    <span className="border bg-dark text-white p-2">분실문의</span>
                    <span className="border p-2">{lostSignUp}│{lostComplete}</span>
                </Link>
                <Link to="/user/fail_list" className="d-flex">
                    <span className="border bg-dark text-white p-2">접속오류</span>
                    <span className="border p-2">{failLogCount}</span>
                </Link>
            </div>

            <div className="d-flex">
                <button style={{
                    width: "80px"
                }} type="submit" className="btn btn-primary" onClick={liveStatus}>갱신</button>
            </div>
        </div>
    )
}
export default LiveStatus;