import React from 'react';
import canada from "../../images/nation/canada.jpg";
import baseball from "../../images/sports/baseball.jpg";
import Switch from "react-switch";
import usa from "../../images/nation/usa.jpg";
import france from "../../images/nation/france.jpg";
import soccer from "../../images/sports/soccer.jpg";
import korea from "../../images/nation/korea.jpg";
import esports from "../../images/sports/esports.jpg";


const CombinationBettingListComponent = () => {
    return (

        <div className="m-2">
            <div className="card-body">
                <div>
                    <table className="table table-bordered table-sm text-center align-middle">
                        <thead className="table-dark">
                        <tr>
                            <th style={{width: "2%"}}>번호</th>
                            <th style={{width: "3%"}}>타입</th>
                            <th style={{width: "10%"}}>종목</th>
                            <th style={{width: "10%"}}>베팅조합</th>
                            <th style={{width: "5%"}}>-</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>1</td>
                            <td style={{ backgroundColor: '#87ea80'}}>국내형</td>
                            <td style={{ textAlign: 'left',paddingLeft: '10px' }}><img src={baseball} alt="야구"/> 야구</td>
                            <td style={{ backgroundColor: '#bde8e0'}}>202+989</td>
                            <td> <button className="my-auto btn btn-sm btn-primary w-50">변경</button>
                                <button className="btn btn-sm btn-danger w-50">삭제</button>
                            </td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td style={{ backgroundColor: '#ead980'}}>해외형</td>
                            <td style={{ textAlign: 'left',paddingLeft: '10px' }}><img src={baseball} alt="야구"/> 야구</td>
                            <td style={{ backgroundColor: '#bde8e0'}}>203+990</td>
                            <td> <button className="my-auto btn btn-sm btn-primary w-50">변경</button>
                                <button className="btn btn-sm btn-danger w-50">삭제</button>
                            </td>
                        </tr>
                        <tr>
                            <td>3</td>
                            <td style={{ backgroundColor: 'lightblue'}}>라이브</td>
                            <td style={{ textAlign: 'left',paddingLeft: '10px' }}><img src={baseball} alt="야구"/> 야구</td>
                            <td style={{ backgroundColor: '#bde8e0'}}>204+977</td>
                            <td> <button className="my-auto btn btn-sm btn-primary w-50">변경</button>
                                <button className="btn btn-sm btn-danger w-50">삭제</button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default CombinationBettingListComponent;