import React from 'react';
import CloseBettingListContainer from "../../containers/betting/CloseBettingListContainer";

const CloseBetting = () => {
    return (
        <div>
            <h1>마감배팅</h1>
            <CloseBettingListContainer />
        </div>
    )
}

export default CloseBetting;