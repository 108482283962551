import React from 'react';
import StopMatchListContainer from "../../containers/StopMatchListContainer";

/**
 * 함수형 컴포넌트 스니펫입니다.
 */
const StopMatch = () => {
    return (
        <div>
            <h1>파싱중지</h1>
            <StopMatchListContainer />
        </div>
    );
};

export default StopMatch;