import {call, put} from "redux-saga/effects";
import {fetchAdmin} from "../../../lib/api";
import {failedAdminReducer, setAdminReducer} from "../../reducers/user/AdminReducer";

export function* getAdminSaga(action) {
    console.log("##getAdminSaga##");
    try{
        const res = yield call(fetchAdmin,action.payload.page,action.payload.pageSize);
        yield put(setAdminReducer(res.data))
    }
    catch (error) {
        console.log(error)
        yield put(failedAdminReducer(error))
    }
}
