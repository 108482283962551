import React from 'react';
import MoneyLogMngListContainer from "../../containers/money/MoneyLogMngListContainer";
const MoneyLogMngPage = () => {
    return (
        <div>
            <h1>머니관리</h1>
            <MoneyLogMngListContainer />
        </div>

    )
}

export default MoneyLogMngPage;