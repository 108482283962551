import React, {useEffect} from 'react';
import SportsListApiSettingComponent from "../../components/match/SportsListApiSettingComponent";
import {getSportsApiSettingInfoListReducer} from "../../redux/reducers/match/sportsApiSettingReducer";
import {resetNationApiSettingInfoListReducer} from "../../redux/reducers/match/nationApiSettingReducer";
import {resetLeagueApiSettingInfoListReducer} from "../../redux/reducers/match/leagueApiSettingReducer";
import {resetTeamApiSettingInfoListReducer} from "../../redux/reducers/match/teamApiSettingReducer";
import Pagination from "../../components/common/Pagination";
import {useDispatch, useSelector} from "react-redux";

const SportsListApiSettingContainer = ({getNationInfo}) => {

    const dispatch = useDispatch()
    const initialState = useSelector((state) => state.sportsApiSettingReducer)

    const getSportsList = () => {
        if(initialState.sportsList !== null) {
            dispatch(getSportsApiSettingInfoListReducer({
                sportsPage: initialState.sportsPage,
                sportsPageSize: initialState.sportsPageSize,
            }))
        }
    }
    useEffect(getSportsList, [dispatch])

    const handlePageChange = (page) => {
        dispatch(getSportsApiSettingInfoListReducer({
            sportsPage: page,
            sportsPageSize: initialState.sportsPageSize,
        }))
    }

    const resetSportsList = () => {
        dispatch(getSportsApiSettingInfoListReducer({
            sportsPage: 1,
            sportsPageSize: initialState.sportsPageSize,
        }))
        dispatch(resetNationApiSettingInfoListReducer())//국가목록초기화
        dispatch(resetLeagueApiSettingInfoListReducer())//리그목록초기화
        dispatch(resetTeamApiSettingInfoListReducer())//팀목록초기화
    }

    return (
        <div>
            <div className="container-fluid m-1 p-1">
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <div className="input-group me-1" style={{ display: "flex", alignItems: "center",justifyContent: "flex-end"  }}>
                    <button style={{width: "80px" ,borderRadius: "5px"}} type="submit" className="btn btn-primary" onClick={() => resetSportsList()}>조회</button>
                    <button style={{width: "100px",backgroundColor : "green" ,borderColor : "green",borderRadius: "5px"}} type="submit" className="btn btn-primary mx-1" >가져오기</button>
                    </div>
                </div>
            </div>
            <SportsListApiSettingComponent initialState = {initialState} getNationInfo={getNationInfo} resetSportsList = {resetSportsList}/>
            <div className="d-flex justify-content-center"> {/* text-center 클래스 추가 */}
            <Pagination totalCount={initialState.totalSportsCount} pageSize={initialState.sportsPageSize} currentPage={initialState.sportsPage} onPageChange={handlePageChange} />
            </div>
        </div>
    );
};

export default SportsListApiSettingContainer;