import React, {useEffect, useState} from "react";
import axios from "axios";
import {NumericFormat} from 'react-number-format';
import typeCase from "../../util/typeCase"
import moment from "moment";

const MemberWithdrawLog = ({setMemberWithdrawLog, userNo}) => {

    const [withdrawLog, setWithdrawLog] = useState([])
    const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().add(1, "day").format('YYYY-MM-DD'));
    const [query, setQuery] = useState("");
    const [totalDeposit, setTotalDeposit] = useState(0);
    const [totalWithdraw, setTotalWithdraw] = useState(0);

    const getNo = () => {
        axios.post('/admin/withdraw/get_no', {
            user_no: userNo,
        }).then((res) => {
            setWithdrawLog(res.data.withdrawLog)
            setTotalDeposit(res.data.totalDeposit)
            setTotalWithdraw(res.data.totalWithdraw)
        })

    }
    useEffect(getNo, [])

    const startDateHandler = (e) => {
        setStartDate(e.currentTarget.value)
    }
    const endDateHandler = (e) => {
        setEndDate(e.currentTarget.value)
    }

    const searchHandler = (query, startDate, endDate) => {
        axios.post('/admin/withdraw/query', {
            start_date: startDate,
            end_date: endDate,
            query: query,
            withdrawStateSelect :10
        }).then((res) => {
            if (res.data.withdrawList !== null) {
                setWithdrawLog(res.data.withdrawList)
            } else {
                alert("실패")
            }
        })
    }

    const handleCancel = () => {
        setMemberWithdrawLog(false);
    }

    const withdrawStateColor = (state) => {
        switch(state) {
            case 0 :
                return "bgRequest"
            case 1 :
                return "bgWait"
            case 2 :
                return "bgComplete"
            case 3 :
                return "bgCancel"
            case 10 :
                return "bg-secondary"
            default : return state
        }
    }

    return (
        <div className="memberLog_background" onClick={handleCancel}>
            <div className="log_modal_box" onClick={(event => event.stopPropagation())}>
                <div className="member_Log">
                    <div className="withdrawLog">
                        <div className="title p-2" style={{
                            textAlign: "left",
                            fontSize: "25px"
                        }}>
                            환전내역
                        </div>
                        <div className="d-flex">
                            <div className="col-5 my-auto d-flex ps-3 justify-content-start">
                                <span className="me-2">총 충전금액 :
                                 <NumericFormat value={totalDeposit && totalDeposit[0].userDeposit} displayType={'text'} thousandSeparator={true}/></span>
                                <span className="me-2">총 환전금액 :
                                <NumericFormat value={totalWithdraw && totalWithdraw[0].userWithdraw} displayType={'text'} thousandSeparator={true}/></span>
                                <span style={{fontWeight: "bold"}}>차액 :
                                <NumericFormat value={(totalDeposit && totalDeposit[0].userDeposit) - (totalWithdraw && totalWithdraw[0].userWithdraw)} displayType={'text'} thousandSeparator={true}/></span>
                            </div>
                            <div className="col-7 d-flex justify-content-end">
                                <input type="date" className="form-control mx-1" value={startDate} onChange={startDateHandler} style={{
                                    width: "150px",
                                    height: "40px"
                                }}/>
                                <span className="mx-1 my-auto">~</span>
                                <input type="date" className="form-control mx-1" value={endDate} onChange={endDateHandler} style={{
                                    width: "150px",
                                    height: "40px"
                                }}/>

                                <div className=" justify-content-end me-2" style={{
                                    height: "40px",
                                }}>
                                    <button className="btn btn-secondary" type="button" id="button-addon2"
                                            onClick={() => searchHandler(query, startDate, endDate)}>검색
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className="modalOverflow content p-2">
                            <div className=" ">
                                <table className="table table-bordered table-sm text-center align-middle">
                                    <thead className="table-dark">
                                    <tr>
                                        <th>코드명</th>
                                        <th>상태</th>
                                        <th>등급</th>
                                        <th>닉네임</th>
                                        <th>예금주</th>
                                        <th>환전금액</th>
                                        <th style={{width: "7%"}}>요청시간</th>
                                        <th style={{width: "7%"}}>처리시간</th>
                                        <th>처리상태</th>
                                        <th>환전구분</th>
                                    </tr>
                                    </thead>
                                    {withdrawLog.map((withdraw,index) => (
                                        <tbody key={withdraw.no}>
                                            <tr className={withdrawStateColor(withdraw.withdraw_state)}>
                                                <td>{typeCase.userType(withdraw.user.user_type)}</td>
                                                <td>{typeCase.userState(withdraw.user.user_state)}</td>
                                                <td>{typeCase.levelState(withdraw.user.level)}</td>
                                                <td>{withdraw.user.nickname}</td>
                                                <td>{withdraw.user.name}</td>
                                                <td><NumericFormat value={withdraw.withdraw_money} displayType={'text'} thousandSeparator={true} /></td>
                                                <td>{moment(withdraw.createdAt).format("YYYY-MM-DD HH:mm")}</td>
                                                <td>{moment(withdraw.updatedAt).format("YYYY-MM-DD HH:mm")}</td>
                                                <td>{typeCase.withdrawState(withdraw.withdraw_state)}</td>
                                                <td>{typeCase.withdrawType(withdraw.withdraw_type)}</td>

                                            </tr>
                                        </tbody>
                                    ))}
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="member_Log_close" onClick={handleCancel}>
                        <button className="btn btn-primary">확인</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MemberWithdrawLog;