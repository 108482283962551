import React, {useEffect, useState} from 'react';
import moment from "moment";
import {Link} from "react-router-dom";
import axios from "axios";
import {useSelector} from "react-redux";
import typeCase from "../../util/typeCase"

const Memo = ({memo, onRefresh}) => {

    const [memoList, setMemoList] = useState([]);
    const [memoNos, setMemoNos] = useState([]);
    const [recommendCode, setRecommendCode] = useState("");
    const [status, setStatus] = useState("")
    const [level, setLevel] = useState("")
    const [id, setId] = useState("");
    const [title, setTitle] = useState([]);
    const [cont, setCont] = useState([]);
    const [saveForm, setSaveForm] = useState(0)
    const [memoItem, setMemoItem] = useState([])
    const [loadItem, setLoadItem] = useState([])
    const [itemTitle, setItemTitle] = useState([]);
    const [itemCont, setItemCont] = useState([]);

    const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().add(1, "day").format('YYYY-MM-DD'));
    const [query, setQuery] = useState("");

    const userInfo = useSelector((state) => state.userInfo);

    const getMemoList = () => {
        axios.post('/admin/memo/get_memo_list', {
            adminNo: userInfo.userNo
        }).then((res) => {
            if(res.data.memoList !== null) {
                setMemoList(res.data.memoList)
                setMemoItem(res.data.memoItem)
                //console.log(res.data.memoList)
            }
        })
    }
    useEffect(getMemoList, [userInfo.userNo])

    const allCheckHandler = (e) => {
        if(memoList.length > 0 && e.currentTarget.value) {
            const checks = [];
            for(let i = 0; i < memoList.length; i++) {
                checks.push(memoList[i].no)
                //console.log(memoList[i].no)
            }
            setMemoNos(e.target.checked? checks : [])
        }
    }
    const checkHandler = (checked, no) => {
        let memoNos2 = []
        if (checked) {
            memoNos2 = [...memoNos, no]
            setMemoNos([...memoNos, no])
        } else {
            memoNos2 = memoNos.filter((id) => id !== no)
            setMemoNos(memoNos.filter((id) => id !== no))
        }
        let totalCheck = 0;
        for (let i = 0; i < memoNos2.length; i++) {
            totalCheck += memoList.filter((id) => id.no === memoNos2[i])[0].no
        }
        //console.log(totalCheck)
    }
    const codeSelectHandler = (e) =>{
        setRecommendCode(e.currentTarget.value);
    }
    const codeSelectList = [
        {value : "", name : "코드별"},
        {value : "test", name : "본사"},
        {value : "test2", name : "파트너"},
    ]
    const statusHandler = (e) =>{
        setStatus(e.currentTarget.value);
    }
    const statusList = [
        {value : "", name : "성향별"},
        {value : "0", name : "대기"},
        {value : "1", name : "요주의"},
        {value : "2", name : "신규"},
        {value : "3", name : "정상"},
        {value : "4", name : "성향"},
        {value : "5", name : "악성"},
        {value : "6", name : "미이용"},
        {value : "7", name : "휴면"},
        {value : "8", name : "탈퇴"},
        {value : "9", name : "졸업"},
        {value : "10", name : "블랙"},
    ]
    const onLevelHandler = (e) => {
        setLevel(e.currentTarget.value);
    }
    const levelList = [
        {value : "", name : "레벨별"},
        {value : "0", name : "1레벨"},
        {value : "1", name : "2레벨"},
        {value : "2", name : "3레벨"},
        {value : "3", name : "4레벨"},
        {value : "4", name : "5레벨"},
        {value : "5", name : "VIP"},
    ]

    const onSaveFormHandler = (e) => {
        setSaveForm(e.currentTarget.value);
        axios.post('/admin/memo/load_cont', {
            no: e.currentTarget.value,
        }).then((res) => {
            if (res.data.loadCont !== null) {
                setLoadItem(res.data.loadCont)
                //console.log(res.data.loadCont)
                setCont(res.data.loadCont[0].cont)
            }
        })
    }

    const onIdHandler = (e) =>{
        setId(e.currentTarget.value);
    }
    const onTitleHandler = (e) =>{
        setTitle(e.currentTarget.value);
    }
    const onContHandler = (e) =>{
        setCont(e.currentTarget.value);
    }
    const onItemTitleHandler = (e) =>{
        setItemTitle(e.currentTarget.value);
    }
    const onItemContHandler = (e) =>{
        setItemCont(e.currentTarget.value);
    }

    const memoInsert = (e) => {



        if (window.confirm("등록 하시겠습니까?")) {
            axios.post('/admin/memo/insert', {
                recommend_code: recommendCode,
                user_state: status,
                level: level,
                id: id,
                title: title,
                cont: cont,
                admin_id: userInfo.id,
                admin_no: userInfo.userNo
            }).then((res) => {
                if(res.data.isInserted) {
                    alert("등록완료")
                } else {
                    alert("등록실패")
                }
                getMemoList()
                onRefresh()
                setId("")
                setTitle([])
                setCont([])
                setSaveForm(0)

                document.getElementById("button-action1").click();
            })
        }



    }
    const memoDelete = (e) => {
        if(memoNos.length > 0) {

            if (window.confirm("삭제 하시겠습니까?")) {
                axios.post('/admin/memo/memo_delete', {
                    memoNos: memoNos
                }).then((res) => {
                    alert(res.data.isDeleted? "삭제완료" : "삭제실패")
                    getMemoList()
                    onRefresh()
                })
            }


        }
        else {
            alert('선택해주세요')
        }
    }

    const dateDelete = (e) => {
        if (window.confirm("삭제 하시겠습니까?")) {
            axios.post('/admin/memo/date_delete', {
                start_date: startDate,
                end_date: endDate
            }).then((res) => {
                alert(res.data.isDeleted? "삭제완료" : "삭제실패")
                getMemoList()
                onRefresh()
            })
        }
    }

    const memoItemInsert = (e) => {

        if( startDate.length < 1 )
        {
            alert('차단일시 필수값입니다.');
            return;
        }


        if (window.confirm("등록 하시겠습니까?")) {
            axios.post('/admin/memo/insert_item', {
                title: itemTitle,
                cont: itemCont,
                admin_no: userInfo.userNo
            }).then((res) => {
                if(res.data.isInserted) {
                    alert("등록완료")
                } else {
                    alert("등록실패")
                }
                getMemoList()
                onRefresh()
                setItemTitle([])
                setItemCont([])

                document.getElementById("button-action2").click();

            })
        }

    }


    const startDateHandler = (e) => {
        setStartDate(e.currentTarget.value)
    }
    const endDateHandler = (e) => {
        setEndDate(e.currentTarget.value)
    }
    const queryHandler = (e) => {
        setQuery(e.currentTarget.value)
    }
    const inputPress = (e) => {
        if(e.key === "Enter") {
            searchHandler(query, startDate, endDate)
        }
    }
    const searchHandler = (query, startDate, endDate) => {
        axios.post('/admin/memo/query', {
            start_date: startDate,
            end_date: endDate,
            query: query,
        }).then((res) => {
            if (res.data.memoList !== null) {
                setMemoList(res.data.memoList)
            } else {
                alert("실패")
            }
        })
    }

    function partnerType (user_type) {
        switch (user_type) {
            case 0 : return "본사"
            case 1 : return "파트너"
            default : return user_type
        }
    }
    function receiveStatus (receive) {
        switch (receive) {
            case 0 : return "읽지않음"
            case 1 : return "읽음"
            default : return receive
        }
    }
    function getStatus(user_state) {
        switch (user_state) {
            case 0 : return "대기"
            case 0 : return "요주의"
            case 1 : return "신규"
            case 2 : return "정상"
            case 3 : return "성향"
            case 4 : return "악성"
            case 5 : return "미이용"
            case 6 : return "휴면"
            case 7 : return "탈퇴"
            case 8 : return "졸업"
            case 9 : return "블랙"
            default : return user_state
        }
    }
    function getLevel(level) {
        switch (level) {
            case 0 : return "1레벨"
            case 1 : return "2레벨"
            case 2 : return "3레벨"
            case 3 : return "4레벨"
            case 4 : return "5레벨"
            case 5 : return "VIP"
            default : return level
        }
    }

    return (
        <div className="m-2">

            <div className="card-body">
                <div>
                    <div className="container-fluid">
                        <div className="row bg-light align-items-center" >

                            <div className="col align-items-center justify-content-end d-flex" style={{
                                height: "50px"
                            }}>

                                <input type="date" className="form-control mx-1" value={startDate} onChange={startDateHandler} style={{
                                    width: "150px",
                                    height: "40px"
                                }}/>
                                <span className="mx-1">~</span>
                                <input type="date" className="form-control mx-1" value={endDate} onChange={endDateHandler} style={{
                                    width: "150px",
                                    height: "40px"
                                }}/>


                                {userInfo.level === 2?
                                    <button className="btn btn-danger" type="button" onClick={dateDelete}>기간삭제</button>
                                    : <></>}
                            </div>

                        </div>


                    </div>
                    <div className="my-2">
                        <table className="table table-bordered table-sm text-center align-middle memo">
                            <thead className="table-dark">
                            <tr>
                                <th style={{width: "3%"}}>
                                    <input className="form-check-input" type="checkbox" checked={memoList.length === memoNos.length}
                                           onChange={allCheckHandler} id="flexCheckDefault"/>
                                </th>
                                <th style={{width: "5%"}}>파트너</th>
                                <th style={{width: "10%"}}>코드명</th>
                                <th style={{width: "5%"}}>상태</th>
                                <th style={{width: "5%"}}>등급</th>
                                <th style={{width: "15%"}}>받는사람</th>
                                <th>제목</th>
                                <th style={{width: "5%"}}>수신</th>
                                <th style={{width: "13%"}}>보낸시간</th>
                                <th style={{width: "7%"}}>보낸사람</th>
                            </tr>
                            </thead>

                            {!memo.length && (
                                <tbody key={memo.no}>
                                <tr>
                                    <td colSpan="10">목록이 비어있습니다.</td>
                                </tr>
                                </tbody>
                            )}

                            {memo.map((memo, index) => (
                                <tbody key={memo.no}>
                                <tr>
                                    <td>
                                        <input className="form-check-input" type="checkbox"
                                               checked={memoNos.filter((no) => no === memo.no).length > 0}
                                               onChange={(e) => {checkHandler(e.currentTarget.checked, memo.no)}}
                                        />
                                    </td>
                                    <td>{partnerType(memo.user.user_type)}</td>
                                    <td>{typeCase.userType(memo.recommend_code)}</td>
                                    <td>{typeCase.userState(memo.user.user_state)}</td>
                                    <td>{typeCase.levelState(memo.user.level)}</td>
                                    <td>
                                        {memo.id}
                                        {"("+memo.nickname+")"}
                                    </td>
                                    <td>
                                        <Link className="nav-link" to={"#collapse" + index} data-bs-toggle="collapse"
                                              aria-expanded="false" aria-controls={"#collapse" + index}>
                                            {memo.title}
                                        </Link>
                                    </td>
                                    <td>{receiveStatus(memo.receive)}</td>
                                    <td>
                                        {moment(memo.createdAt).format("YYYY-MM-DD HH:mm:ss")}
                                    </td>
                                    <td>{memo.admin_id}</td>

                                </tr>
                                <tr className="collapse border border-secondary" key={index} id={"collapse" + index}>
                                    <td colSpan="10">
                                        <div dangerouslySetInnerHTML={ {__html:memo.cont}}></div>
                                    </td>
                                </tr>
                                </tbody>
                            ))}


                        </table>

                    </div>
                    <div className="d-flex">
                        <button className="btn btn-outline-secondary me-2" type="button"
                                data-bs-toggle="collapse" data-bs-target="#addCollapse"
                                aria-expanded="false" aria-controls="addCollapse" id="button-action1">쪽지발송
                        </button>
                        {userInfo.level === 2?
                            <button className="btn btn-outline-secondary me-2" type="button"
                                    data-bs-toggle="collapse" data-bs-target="#addSentenceCollapse"
                                    aria-expanded="false" aria-controls="addSentenceCollapse" id="button-action2">자동문구추가
                            </button>
                            :<></>
                        }
                        <button className="btn btn-outline-secondary me-4" type="button"
                                id="button-addon2" onClick={memoDelete}>선택 삭제
                        </button>
                    </div>
                    <div id="addSentenceCollapse" className="add collapse">
                        <div className="my-3 d-flex">
                            <label className="form-label mt-auto" style={{
                                width: "6%",
                            }}>문구제목</label>
                            <input type="text" maxLength="100" className="form-control border border-secondary" value={itemTitle} onChange={onItemTitleHandler}/>
                        </div>
                        <div className="d-flex">
                            <label className="form-label my-auto" style={{
                                width: "6%",
                            }}>문구내용</label>
                            <textarea className="form-control border border-secondary" maxLength="2000" value={itemCont}  rows="4" onChange={onItemContHandler}></textarea>
                        </div>
                        <div className="mt-2 text-center">
                            <button className="btn btn-outline-secondary" type="button" id="button-addon2"
                                    onClick={memoItemInsert}
                            >내용등록
                            </button>
                        </div>
                    </div>
                    <div id="addCollapse" className="add collapse">
                        <div className="my-3 d-flex">
                        </div>
                        <div className="my-3 d-flex">
                            <select className="form-select border border-secondary"  onChange={codeSelectHandler} value={recommendCode}>
                                {codeSelectList.map((item, index) => (
                                    <option key={item.value} value={item.value}>{item.name}</option>
                                ))}
                            </select>
                            <select className="form-select border border-secondary"  onChange={statusHandler} value={status}>
                                {statusList.map((item, index) => (
                                    <option key={item.value} value={item.value}>{item.name}</option>
                                ))}
                            </select>
                            <select className="form-select border border-secondary"  onChange={onLevelHandler} value={level}>
                                {levelList.map((item, index) => (
                                    <option key={item.value} value={item.value}>{item.name}</option>
                                ))}
                            </select>
                        </div>
                        <div className="my-3 d-flex">
                            <label className="form-label mt-auto" style={{
                                width: "6%",
                            }}>아이디</label>
                            <input type="text" maxLength="12" className="form-control border border-secondary" value={id} onChange={onIdHandler}/>
                        </div>
                        <div className="my-3 d-flex">
                            <label className="form-label mt-auto" style={{
                                width: "6%",
                            }}>제목</label>
                            <input type="text" maxLength="200" className="form-control border border-secondary" value={title} onChange={onTitleHandler}/>
                        </div>
                        <div className="d-flex">
                            <label className="form-label mt-auto" style={{
                                width: "5.7%",
                            }}>저장된문구</label>
                            <div style={{width: "95%"}}>
                                <select className="form-select border border-secondary"
                                        onChange={onSaveFormHandler} value={saveForm}>
                                    <option key={0} value={0}>---문구 선택---</option>
                                    {memoItem.map((item, index) => (
                                        <option key={item.no} value={item.no}>{item.title}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="my-3 d-flex">
                            <label className="form-label my-auto" style={{
                                width: "6%",
                            }}>내용</label>
                            <textarea className="form-control border border-secondary" maxLength="2000" value={cont}  rows="4" onChange={onContHandler}></textarea>
                        </div>
                        <div className="text-center">
                            <button className="btn btn-outline-secondary" type="button" id="button-addon2"
                                    onClick={memoInsert}
                            >내용등록
                            </button>
                        </div>
                    </div>

                </div>
            </div>

        </div>

    )
}

export default Memo;