import { createSlice } from "@reduxjs/toolkit";

export const settingMarketSlice = createSlice({
    name: 'settingMarket',
    initialState: {
        isLoading: false,
        settingMarkets: [],
        error: null,
        isConnect: false,
        tasks: [],
        is_active: 0,
        page: 1,
        pageSize: 100,
        totalCount: 0,
        sport: 0,
        sport_name: "",
        sports: [],
        query: "",
        checkedInputs: [],
        nameInputs: [],
        updateData: [],
    },
    reducers: {
        getSettingMarket(state, action) {
            state.isLoading = true;
            state.is_active = action.payload.is_active;
            state.page = action.payload.pageSize === 999? 1: action.payload.page;
            state.pageSize = action.payload.pageSize;
            state.sport = action.payload.sport;
            state.sport_name = action.payload.sport_name;
            state.query = action.payload.query
        },
        setSettingMarket(state, action) {
            if((state.page -1) * state.pageSize > action.payload.totalCount) {
                state.page = 1;
            }

            state.isLoading = false;
            state.settingMarkets = action.payload.result;
            state.sports = action.payload.sports;
            state.totalCount = action.payload.totalCount;
            const checks = [];
            const inputs = [];
            for(const r of action.payload.result) {
                if(r.is_active) {
                    checks.push(r.id);
                }
                if(r.name_kor) {
                    inputs.push({id: r.id, name_kor: r.name_kor})
                }
            }
            state.checkedInputs = checks;
            state.nameInputs = inputs;
        },
        setCheckedInputs(state, action) {
            state.checkedInputs = action.payload;
        },
        setUpdateData(state, action) {
            state.updateData = action.payload;
        },
        setNameInputs(state, action) {
            state.nameInputs = action.payload;
        },
        failedGetSettingMarket(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        waitSettingMarketTask(state) {
            state.isConnect = true
        },
        pushSettingMarketTask(state, action) {
            state.tasks.push(action.payload)
        },
        changeSettingMarket(state, action) {
            //변경된 인자값의 인덱스넘버를 찾아야된다.
            const changedItem = action.payload
            const beforeArray = state.settingMarkets.filter((item) => item.id === changedItem.id)
            //찾은후 변경사항업데이트
            const index = state.settingMarkets.indexOf(beforeArray[0])
            if(index >= 0) {
                state.settingMarkets[index] = changedItem
            }
            state.tasks = []
        }
    },
});

export const { getSettingMarket, setSettingMarket, setCheckedInputs, setUpdateData, setNameInputs, failedGetSettingMarket, waitSettingMarketTask, pushSettingMarketTask, changeSettingMarket } = settingMarketSlice.actions;

export default settingMarketSlice.reducer;