import React from 'react';
import CloseMatchListContainer from "../../containers/CloseMatchListContainer";

/**
 * 함수형 컴포넌트 스니펫입니다.
 */
const CloseMatch = () => {
    return (
        <div>
            <h1>경기마감</h1>
            <CloseMatchListContainer />
        </div>
    );
};

export default CloseMatch;