import React, {useState} from 'react';
import {Outlet, useNavigate} from "react-router-dom";

const Money = () => {

    const navigate = useNavigate();
    const [activeNav, setActiveNav] = useState();
    const ontoggle = (index, address) => {
        setActiveNav(index)
        navigate(address)
    }
    const menuArr = [
        {name:"충전관리", address:"/money/deposit"},
        {name:"충전완료", address:"/money/deposit_complete"},
        {name:"충전문자", address:"/money/deposit_msg"},
        {name:"환전관리", address:"/money/WithdrawMngPage"},
        {name:"환전완료", address:"/money/WithdrawCompletePage"},
        {name:"머니관리", address:"/money/MoneyLogMngPage"},
        {name:"포인트관리", address:"/money/PointLogMngPage"},
    ]

    return (
        <div>
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
                <div className="collapse navbar-collapse" style={{
                    marginLeft: "290px",
                    height: "40px"
                }}>
                    <ul className="navbar-nav text-center">
                        {menuArr.map((menu,index) => (
                            <li key={index} className="mx-2" onClick={() => ontoggle(index, menu.address)}>
                                <div className={activeNav === index ? "nav-item active" : "nav-item"} style={{cursor: "pointer"}}>
                                    {menu.name}
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>

            </nav>
            <div style={{
                paddingLeft: "10px"
            }}>
                <Outlet />
            </div>

        </div>

    )
}

export default Money;