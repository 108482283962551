import {call, put} from "redux-saga/effects";
import {fetchJoinList} from "../../../lib/api";
import {failedJoinListReducer, setJoinListReducer} from "../../reducers/user/JoinListReducer";

export function* getJoinListSaga(action) {
    console.log("##getJoinListSaga##");
    try{
        const res = yield call(fetchJoinList,action.payload.year,action.payload.month,action.payload.date);
        yield put(setJoinListReducer(res.data))
    }
    catch (error) {
        console.log(error)
        yield put(failedJoinListReducer(error))
    }
}
