import {useDispatch, useSelector} from "react-redux"
import React, {useEffect, useState} from "react"

import Pagination from "../../components/common/Pagination";
import {getDeposit} from "../../redux/reducers/money/deposit";
import DepositList from "../../components/money/Deposit";
import {getDepositComplete} from "../../redux/reducers/money/depositComplete";
import axios from "axios";

const DepositListContainer = () => {
    // useDispatch: 컴포넌트 내부에서 스토어의 내장 함수 dispatch를 사용할 수 있게 해주는 hook 이다.
    // useSelector: connect 함수를 대신하여 스토어 상태를 조회
    const dispatch = useDispatch()
    const initialState = useSelector((state) => state.deposit)
    const [searchQuery, setSearchQuery] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    // 브라우저 상에 컴포넌트가 나타날 때 상품 목록을 조회하는 함수를 실행
    useEffect(() => {
        dispatch(getDeposit({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
        }))
    }, [dispatch])

    const handlePageChange = (page) => {
        dispatch(getDeposit({
            page: page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
        }))
    }

    const changeQuery = (value) => {
        setSearchQuery(value)
    }

    const inputPress = (e) => {
        if(e.key === "Enter") {
            onSearch(e.target.value)
        }
    }
    const onSearch = (query) => {
        setSearchQuery(query)
        dispatch(getDeposit({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: query,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
        }))
    }
    const onClickSearch = (e) => {

        dispatch(getDeposit({
            page: initialState.pages? initialState.pages : 1,
            pageSize: initialState.pageSizes? initialState.pageSizes : 5,
            //page: 2,
            //pageSize: 3,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
        }))
    }

    const onRefresh = () => {
        dispatch(getDeposit({
            page: initialState.page,
            pageSize: initialState.pageSize,
            order: initialState.order,
            query: searchQuery,
            startDate: startDate? startDate : initialState.startDate,
            endDate: endDate? endDate : initialState.endDate,
        }))
    }



    const startIndex = ((initialState.page - 1) * initialState.pageSize) + 1;
    const currentMaxCount = initialState.totalCount < (startIndex + initialState.pageSize - 1)? initialState.totalCount : (startIndex + initialState.pageSize - 1)



    return <>
        <p className="h5 ps-2">{ startIndex + "~" + currentMaxCount + " / " + initialState.totalCount}</p>
        <div className="container-fluid p-0 mx-1" style={{
            display: "flex"
        }}>
            <input style={{
                width: "220px"
            }} className="form-control" type="date" id="start_date" value={startDate? startDate : initialState.startDate} onChange={ event => {
                setStartDate(event.target.value)
            }}/>
            <big className="mx-2">~</big>
            <input style={{
                width: "220px"
            }} className="form-control" type="date" id="end_date" value={endDate? endDate : initialState.endDate} onChange={ event => {
                setEndDate(event.target.value)
            }}/>
        </div>
        <div className="container-fluid m-1 p-0" style={{
            display: "flex"
        }}>
            <select
                className="form-select me-1"
                defaultValue={100}
                style={{
                    width: "100px"
                }}
                value={initialState.pageSize === "999"? "모두" : initialState.pageSize}
                onChange={(e) => {
                    dispatch(getDeposit({
                        page: initialState.page,
                        pageSize: parseInt(e.target.value),
                        order: initialState.order,
                        query: searchQuery,
                        startDate: startDate? startDate : initialState.startDate,
                        endDate: endDate? endDate : initialState.endDate,
                    }))
                }}
            >
                <option value={50}>50</option>
                <option value={100}>100</option>
                <option value={200}>200</option>
                <option value={999}>모두</option>
            </select>
            <select
                className="form-select me-1"
                defaultValue={0}
                style={{
                    width: "200px"
                }}
                value={initialState.order === 0? 0 : initialState.order}
                onChange={e =>
                    dispatch(getDeposit({
                        page: initialState.page,
                        pageSize: initialState.pageSize,
                        order: parseInt(e.target.value),
                        query: searchQuery,
                        startDate: startDate? startDate : initialState.startDate,
                        endDate: endDate? endDate : initialState.endDate,
                    }))
                }
            >
                <option value={0}>요청시간순</option>
                <option value={1}>처리시간순</option>
            </select>
            <div className="input-group me-1" style={{
                display: "flex",
                width: "300px"
            }}>
                <input style={{
                    width: "220px"
                }} type="text" className="form-control" id="search_edit" placeholder="닉네임,ID를 입력하세요." onBlur={(e) => {
                    changeQuery(e.target.value)
                }} onKeyDown={inputPress} />
                <button style={{
                    width: "80px"
                }} type="submit" className="btn btn-primary" onClick={onClickSearch}>검색</button>
            </div>
        </div>
        <DepositList deposit={initialState.deposit} onRefresh={onRefresh} />
        <Pagination totalCount={initialState.totalCount} pageSize={initialState.pageSize} currentPage={initialState.page} onPageChange={handlePageChange}/>
    </>





}
export default DepositListContainer