import React, { useState, useEffect } from "react";
const Toast = (props) => {
    useEffect(() => {
        let timer = setTimeout(() => {
            props.setToastState(false);
        }, 1000);
        return () => { clearTimeout(timer) }
    }, []);
    return (
        <div className="toast_alert">
            <div className="my-auto">복사</div>
        </div>
    );
}
export default Toast