import {call, put, take,} from "redux-saga/effects";
import {fetchSportsMarketListSettingPage} from "../../../lib/api";
import {failedGetSportMarketListSettingPage, setSportMarketListSettingPage} from "../../reducers/match/sportMarketListSettingPage";

export function* getSportsMarketListSettingPageSaga(action) {


    try{
        const res = yield call(
            fetchSportsMarketListSettingPage,
            action.payload.page, action.payload.pageSize, action.payload.query,
            action.payload.order, action.payload.startDate, action.payload.endDate,
            action.payload.sports_cd
        )

        //alert("res.data cjw ::: "+JSON.stringify(res.data));

        yield put(setSportMarketListSettingPage(res.data))
    }
    catch (error) {
        console.log("ERROR >>>>>>>>>>>>>>>>>>>>>>>>> "+error)
        yield put(failedGetSportMarketListSettingPage(error))
    }
}