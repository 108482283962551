import {call, put} from "redux-saga/effects";
import {setLeagueApiSettingInfoListReducer,failedGetLeagueApiSettingInfoListReducer}  from "../../reducers/match/leagueApiSettingReducer";
import {fetchLeagueApiSettingInfoList} from "../../../lib/api";

//리그 API설정리스트
export function* getLeagueApiSettingInfoListSaga(action) {
    try{
        console.log("##getLeagueApiSettingInfoListSaga#")
        const res = yield call(fetchLeagueApiSettingInfoList,action.payload.leaguePage,action.payload.leaguePageSize,action.payload.nationNo,action.payload.leagueUseYn,action.payload.leagueNm);
        yield put(setLeagueApiSettingInfoListReducer(res.data))
    }
    catch (error) {
        console.log(error)
        yield put(failedGetLeagueApiSettingInfoListReducer(error))
    }
}