import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {getAgentListReducer,setPartnerNos} from "../../redux/reducers/agent/AgentListReducer";


const AgentListComponent = ({initialState}) => {
    const dispatch = useDispatch()


    const allCheckHandler = (e) => {
        if(initialState.partnerList.length > 0 && e.currentTarget.value) {
            const checks = [];
            let total = 0;
            for(let i = 0; i < initialState.partnerList.length; i++) {
                checks.push(initialState.partnerList[i].no)
            }
            dispatch(setPartnerNos(e.target.checked? checks : []))
        }
    }

    const checkHandler = (checked, no) => {
        let partnerNos2 = []
        if(checked) {
            partnerNos2 = [...initialState.partnerNos, no]
            dispatch(setPartnerNos([...initialState.partnerNos, no]))
        }
        else {
            partnerNos2 = initialState.partnerNos.filter((id) => id !== no)
            dispatch(setPartnerNos(initialState.partnerNos.filter((id) => id !== no)))
        }
        let total = 0;
        for(let i = 0; i < partnerNos2.length; i++) {
            total += initialState.partnerList.filter((id) => id.no === partnerNos2[i])[0]
        }
        //console.log(total)
    }

    const partnerTypeBgColor = (state) => {
        switch(state) {
            case 0 :
                return "bgComplete"
            case 1 :
                return "bgRequest"
            case 2 :
                return "bgWait"
            case 3 :
                return "bgCancel"
            default : return state
        }
    }


    return (
        <div className="agentt1">
            <table className="table table-bordered table-sm text-center align-middle">
                <thead className="table-dark">
                <tr>
                    <th>
                        <input className="form-check-input" type="checkbox" checked={initialState.totalCount > 0 && initialState.partnerList.length === initialState.partnerNos.length} onChange={allCheckHandler}/>
                    </th>
                    <th>구분</th>
                    <th>회원수</th>
                    <th>파트너ID</th>
                    <th>매장명</th>
                    <th>매장코드</th>
                    <th>입출금(%)</th>
                    <th>롤링(%)</th>
                    <th>>스포츠%(입 | 롤)</th>
                    <th>실시간%(입 | 롤)</th>
                    <th>미니게임%(입 | 롤)</th>
                    <th>카지노%(입 | 롤)</th>
                    <th>배당금(수익)</th>
                    <th>배당금(배팅)</th>
                </tr>
                </thead>

                {initialState.totalCount > 0 && initialState.partnerList.map((partner, index) => (
                    <tbody key={partner.no}>
                    <tr className={partnerTypeBgColor(partner.user_type)}>
                        <td>
                            <input className="form-check-input" type="checkbox"
                                   checked={initialState.partnerNos.filter((no) => no === partner.no).length > 0}
                                   onChange={(e) => {checkHandler(e.currentTarget.checked, partner.no)}}
                            />
                        </td>
                        <td style={{fontWeight: "bold"}}>{partner.cd_nm}</td>
                        <td>{partner.user_cnt}</td>
                        <td>{partner.id}</td>
                        <td>
                            <Link className="nav-link" to="#collapse1" data-bs-toggle="collapse"
                                  aria-expanded="false" aria-controls="collapse1">
                                {partner.store_name}
                            </Link>
                        </td>
                        <td>{partner.store_code}</td>
                        <td>{partner.dw_rate}</td>
                        <td>{partner.rolling_rate}</td>
                        <td>{partner.sport_rate} | {partner.sport_rolling_rate}</td>
                        <td>{partner.live_rate} | {partner.live_rolling_rate}</td>
                        <td>{partner.miniGame_rate} | {partner.miniGame_rolling_rate}</td>
                        <td>{partner.casino_rate} | {partner.casino_rolling_rate}</td>
                        <td>{partner.revenue_money}</td>
                        <td>{partner.all_bet_money}</td>
                    </tr>
                    </tbody>
                ))}
            </table>
        </div>

    )
}

export default AgentListComponent;