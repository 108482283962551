import {call, put, take,} from "redux-saga/effects";
import {fetchMarket} from "../../../lib/api";
import {changeMarket, failedGetMarket, pushMarketTask, setMarket} from "../../reducers/match/market";
import {closeChannel, createSocketChannel} from "../../channel/createSocketChannel";
import socket from "../../../config/socket";

export function* getMarketSaga(action) {
    try {
        const res = yield call(fetchMarket, action.payload.is_active, action.payload.page, action.payload.pageSize, action.payload.query);
        yield put(setMarket(res.data));
    } catch(error) {
        console.log(error)
        yield put(failedGetMarket(error));
    }
}

export function* waitMarketTaskSaga() {
    let channel;
    try {
        channel = yield call(createSocketChannel, "market");
        while(true) {
            const task = yield take(channel);
            yield onTaskSaga(task)
        }
    } catch (e) {
        console.log(e, "error");
    } finally {
        socket.close();
        closeChannel(channel)
    }
}

function* onTaskSaga(task) {
    yield put(pushMarketTask(task));
    yield put(changeMarket(task))
}

export function* changeMarketSaga() {
    //yield put(changeSport(changedItem))
}
